<template>
  <floating-action-button color="#339564" icon="add" :position="position" @click="gotoNew" />
</template>

<script>
import FloatingActionButton from "src/app-components/FloatingActionButton";

export default {
  components: {
    FloatingActionButton
  },
  props: {
      position: {
          type: Number,
          default: 0
      }
  },
  data() {
    return {
    };
  },

  created() {},

  methods: {
    gotoNew() {
      this.$router.push({
        path: "/bookmarks/index?createFromProduct=1&event=1",
        params: {}
      });
    }
  }
};
</script>