<template>
  <div>
    <Pivot :api="apiFunction" />
  </div>
</template>

<script>
import api from "src/api";
import { mapMutations } from "vuex";
import Pivot from "src/app-components/Pivot";
export default {
  components: {
    Pivot,
  },

  mounted() {
    this.setTopNavbarTitle(this.$t("Campaign stats"));
  },

  methods: {
    async apiFunction(query) {
      const pivotData = await api.getCampaignReportData(query);
      return pivotData;
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
