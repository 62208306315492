<script>
import api from "src/api";
import ConsolidatedCampaign from "./ConsolidatedCampaign";
import _ from 'lodash';
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "Ping",

    extends: ConsolidatedCampaign,

    created() {
        this.tableCols = [
            { label: "Campaign", hasLabel: true,  minWidth: 150  },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%Active Rate", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Blacklisted", label: "Blacklisted", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
        ];
        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            return scenario.scenario.sat_call_to_action_id == null;
        });
    },

    methods: {

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "campaign_id ASC",
            ]
        },

        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        setWhere (query) {
            query.ql.where["scenario_id"] = 11; // Only ping scenario
        }
    }
}
</script>