<template>
  <div>
    <title-box
      componentName="Integration"
      :id="numberNodeID"
      :name="dataNode.name"
      @setNewName="setNewName($event)"
    />

    <div class="options">
      <ul>
        <li
          v-for="option in dataNode.options"
          :key="option.text"
          :class="option.type"
        >
          {{ option.text }}
        </li>
      </ul>
    </div>

    <template v-for="(opt, key) in optionsPopover">
      <create-element-popover
        :key="key"
        :output="key + 1"
        :optionsLength="dataNode.options.length"
        :numberNodeID="numberNodeID"
        v-if="
          dataNode.connections.findIndex((c) => c.idOption === opt.id) === -1
        "
      />
    </template>
  </div>
</template>

<script>
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "Integration",
    mixins: [ChatbotComponentsMixin],

    data() {
      return {
        dataNode: {
          integration: {
            url: "",
            method: "GET",
            params: [],
            headers: [],
            body: "{}",
            // body: "{\n\t\n}",
            response: [],
          },
          options: [
            {
              id: null,
              text: "Success",
              type: "reply",
              trainingPhrases: [],
            },
            {
              id: null,
              text: "Fallback",
              type: "fallback",
              message: "",
            },
          ],
        },
      };
    },

    mounted() {
      setTimeout(() => {
        this.setIdOptions();
      }, 400)
    },

    methods: {
      setIdOptions() {
        if(!this.dataNode.options[0].id) {
          setTimeout(() => {
            this.dataNode.options[0].id = Date.now();
          }, 2);
        }
        
        if(!this.dataNode.options[1].id) {
          setTimeout(() => {
            this.dataNode.options[1].id = Date.now();
          }, 4);
        }
      },
    },

    computed: {
      optionsPopover() {
        if(this.dataNode) {
          return this.dataNode.options;
        }
        return [];
      }
    }
  };
</script>

<style scoped>
  .wrapperCanvas .variable {
    font-size: 11px;
    font-weight: bold;
    background: #eee;
  }
  
  .wrapperCanvas.dark .variable {
    background: #555;
    color: #ddd;
  }
</style>
<style>
  .wrapperCanvas .drawflow .drawflow-node.DataStorage.selected {
    -webkit-box-shadow: 0 2px 20px 2px #909399;
    box-shadow: 0 2px 20px 2px #909399;
  }
  
  .wrapperCanvas.dark .drawflow .drawflow-node.DataStorage.selected {
    -webkit-box-shadow: 0 2px 20px 2px #BB86FC;
    box-shadow: 0 2px 20px 2px #BB86FC;
  }
</style>
