<template>
    <div class="row justify-content-center">
        <div class="col-sm-12">

        </div>
    </div>
</template>

<script>
    export default {

    }
</script>