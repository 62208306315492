<template>
    <modal :show-close="!working" :show="show" headerClasses="justify-content-center" @close="onCancel">
        <h4 slot="header" class="title title-up">{{ $t("Select file") }}</h4>
        <FileType :is-editable="!working" v-model="fileType" />
        <fg-input :label="$t('File')" :error="getError('file')">
            <BFormFile
                :disabled="working"
                name="file"
                v-validate="'required'"
                :placeholder="$t('Select file')"
                v-model="file"
                accept=".csv, .xls, .xlsx, .txt, .zip"
            />
        </fg-input>
        <Spinner :display="working" />
        <div class="text-center" v-if="uploadData">
            <i class="fa fa-circle text-success"></i> {{ $t("Valids MSISDN") }}  {{ uploadData._count_success || 0 }}<br />
            <i class="fa fa-circle text-danger"></i> {{ $t("Invalids MSISDN") }} {{ uploadData._count_error || 0 }}
        </div>
        <template slot="footer">
            <button :disabled="working" type="button" class="btn btn-dark" @click="onCancel"><i class="fa fa-ban"></i> {{ $t('Cancel') }}</button>
            <button :disabled="working" type="button" class="btn btn-success mr-3" @click="onSave"><i class="fa fa-check"></i>
                {{ uploadDone ? $t('Done') : $t('Upload') }}
            </button>
        </template>
    </modal>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";
    import FileType from "src/app-components/form-elements/FileType"
    import { BFormFile } from 'bootstrap-vue';
    import api from 'src/api'
    import Spinner from "src/app-components/Spinner";

    export default {
        name: 'file-upload',

        provide() {
            return {
                parentValidator: this.$validator,
                parentErrors: this.errors
            };
        },

        props: {
            show: Boolean,
        },

        components: {
            Modal,
            FileType,
            BFormFile,
            Spinner
        },

        data() {
            return {
                fileType: null,
                file: null,
                working: false,
                uploadData: null,
                uploadDone: false
            }
        },

        watch: {
            show () {
                if (this.show) {
                    this.fileType = null;
                    this.file = null;
                    this.uploadData = null;
                    this.uploadDone = false;
                    this.$validator.reset()
                }
            }
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            async onSave() {
                if (this.uploadDone) {
                    this.$emit("modal-save", this.uploadData)
                } else {
                    const valid = await this.$validator.validateAll()
                    if (!valid) return;
                    await this.processUpload()
                    this.uploadDone = true
                }
            },

            onCancel() {
                this.$emit("modal-cancel");
            },

            async processUpload() {
                this.working = true;
                const uploaded = await this.uploadFile();
                //await this.waitForFileToBeProcessed(uploaded.data.id);
                //this.uploadData = (await api.getUpload(uploaded.data.id)).data
                this.working = false;
            },

            async uploadFile () {
                const formData = new FormData();
                formData.append("attachment", this.file);
                const res = await api.uploadFile(formData, this.fileType);
                return res;
            },

            async waitForFileToBeProcessed (id) {
                let upload;
                do {
                    await this.sleep(1000);
                    upload = await api.getUpload(id);
                    this.uploadData = { ...this.uploadData, ...upload.data }
                    console.log("Current status " + upload.data.processed);
                } while (upload.data.processed != 1);
            },

            sleep(ms) {
                return new Promise(function(resolve) {
                    setTimeout(resolve, ms);
                });
            },
        }
    }
</script>

<style scoped>
</style>