<template>
<div class="login-wrapper">

    <loading-cover :visible="loading" />
    
    <div class="vertical-center">
        <div class="logo-row"><div src="/images/logind.png" class="img-logo"></div>
    </div>

    <div>
        <form id="w0" class="form-vertical kv-form-bs4" action="/site/login" method="post" role="form">
    <input type="hidden" name="_csrf" value="-nksMYwGktH9Bu40DhA1aAFAtvdS-SQMly_AAUb-lqnICQFZwk3w4LFfv1BZcXkKMjCOqDvNfm7CYbQxK7jd4A=="><div class="form-class d-flex align-items-start row">
        
        <div class="column_input col-sm-4">     
            <div class="form-group highlight-addon field-loginform-username required">
                <label for="loginform-username">Usuario</label>
                <input type="text"
                    id="loginform-username"
                    class="field_form form-control"
                    name="username"
                    placeholder="Enter username..."
                    aria-required="true"
                    v-model="username"
                />
                <div class="help-block invalid-feedback"></div>
            </div>
        </div>

        <div class="column_input col-sm-4">
            <div class="form-group highlight-addon field-loginform-password required">
                <label for="loginform-password">Contraseña</label>
                <input type="password"
                    id="loginform-password"
                    class="field_form form-control"
                    name="password"
                    placeholder="Enter password..."
                    aria-required="true"
                    v-model="password"
                />
                <div class="help-block invalid-feedback"></div>
            </div>

        </div>

        <div class="column_login col-sm-4">

            <div class="form-group">
                <label style="visibility: hidden; width: 100%;">sarasa</label>
                <button type="submit" class="btn btn-primary button_login" @click="handleSubmit">Log in</button>
            </div>
        </div>

    </div>

    </form>	</div>
    </div>
</div>
</template>
<script>
import Spinner from "src/components/UIComponents/Spinner";
import LoadingCover from "@/components/LoadingCover.vue";
export default {
    components: {
        Spinner,
        LoadingCover
    },
    data(){
        return {
            username: "",
            password: "",
            loading: false,
        }
    },
    methods: {
        async handleSubmit(event){
            event.preventDefault();
            this.loading = true;
            try {
              await this.$auth.login(this.username, this.password);
            } catch (error) {
              console.log("login error", error)
              this.$notify({
                title: this.$t("An error occurred while logging in"),
                message: this.$t("Please check your credentials and try again"),
                icon: "fa fa-info",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "danger",
                duration: 10000,
              });
            }

            this.loading = false;
        }
    }
}
</script>
<style>
.login-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url('/images/login-2020-desktop.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.vertical-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.column_input {
    font-size: large;
}
.column_input label{
    color: white;
}
.logo-row {
    padding-bottom: 40px;
}
.img-logo {
    width: 320px;
    background: url('../images/logind.png') no-repeat center center;
    background-size: 100%;
    height: 100px;
}
/*.btn[type=submit] {
    padding: 0 !important;
}*/
.button_login {
    margin: 0 !important;
}
</style>
