<template>
  <div>
{{ $t('HOLA') }}
  </div>
</template>

<script>
export default {
  created() {

  }
};
</script>

<style>
</style>
