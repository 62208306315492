<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t(label) }}</label>
        <el-select
          size="large"
          :name="name"
          multiple
          allow-create
          default-first-option
          :filterable="true"
          :clearable="true"
          v-validate="required ? 'required' : null"
          @input="$emit('input', $event)"
          :error="getError(name)"
          :value="local"
          :placeholder="''">
          <el-option v-for="option in options"
            :value="option.value"
            :label="option.label"
            :key="option.name">
           </el-option>
        </el-select>
      </div>
      <div v-show="errors.has(name)"
        class="text-danger invalid-feedback"
        style="display: block;">
        {{ errors.first(name) }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  name: "events",

  props: {
    value: null,
    required: { type: Boolean, default: false },
    label:    { type: String, default: "Events" },
    name:     { type: String, default: "events" }
  },

  async created() {
    this.$validator = this.parentValidator;
    const events = await api.getApiEvents();

    this.options = events.data.map((event) => {
        return { value: event.name, label: event.name }
    });
  },

  computed: {
    local() {
      return this.value ? this.value : "";
    }
  },

  data() {
    return {
      options: []
    };
  }
};
</script>