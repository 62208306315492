<template>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div
					class="card-body"
					v-loading="$asyncComputed.tableData.updating"
					:element-loading-text="$t('Loading')"
					style="min-height: 50vh"
				>

					<div class="row">
						<div
							class="col-md-6 mt-2 pagination-info"
							v-if="tableDataPersistent !== null"
						>
							<p class="category mb-1">
								{{ tableDataPersistent.metadata.total | formatNumber }}
								{{ $t("entries") }}
							</p>
							
              <p class="category">
								{{ $t("Last update") }} {{ lastUpdate }}
							</p>

						</div>
						<div
							class="col-md-6 mt-2 d-flex align-items-end justify-content-end"
							v-if="tableDataPersistent"
						>
              <div class="form-group has-label" style="max-width: 150px">
                <label>{{ $t("Number of records") }}</label>
                <el-select size="mini" name="limit" v-model="limit">
                  <el-option
                    v-for="option in optionsLimit"
                    :value="option"
                    :key="option"
                  ></el-option>
                </el-select>
              </div>
              <p-pagination
								:page-count="tableDataPersistent.metadata.size"
								class="pull-right"
								v-model="search.defaultPagination"
							>
							</p-pagination>
						</div>

						<div class="col-sm-12 mt-2">
							<el-table
								:key="tableRenderId"
								v-if="tableDataPersistent"
								:data="tableDataWithoutDeleted.data"
								style="width: 100%"
							>
								<el-table-column
									:label="$t('ID')"
									:width="
										fitColumnToContent('id', $t('ID'), 60)
									"
								>
                  <template slot-scope="scope">
                    <!-- {{ scope.row.id | formatNumber }} -->
                    {{ scope.row.id }}
                  </template>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											'account.name',
											$t('Account'),
											60
										)
									"
									v-if="accountsSelected !== 1"
									prop="account.name"
									:label="$t('Account')"
								>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											(scope) =>
												formatType(
													null,
													null,
													scope.row.type
												),
											$t('Type'),
											60
										)
									"
									:formatter="formatType"
									prop="type"
									:label="$t('Type')"
								>
								</el-table-column>

								<el-table-column
									prop="name"
									:min-width="
										fitColumnToContent(
											'name',
											$t('Name'),
											60
										)
									"
									:label="$t('Name')"
								>
									<b slot-scope="scope">{{
										scope.row.name
									}}</b>
								</el-table-column>

								<el-table-column
									prop="channel.name"
									:min-width="
										fitColumnToContent(
											'channel.name',
											$t('Channel'),
											60
										)
									"
									:label="$t('Channel')"
								>
									<template slot-scope="scope">
										<span
											class="badge w-75 badge-pill"
											:class="
												getChannelClass(
													scope.row.channel.id
												)
											"
											>{{ scope.row.channel.name }}</span
										>
									</template>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											(scope) =>
												generateStartDateTime(scope),
											$t('Start'),
											60
										)
									"
									prop="start_datetime"
									:formatter="formatDatetime"
									:label="$t('Start')"
								>
									<template slot-scope="scope">
										{{
											generateStartDateTime(scope) || "-"
										}}
									</template>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											(scope) =>
												generateFinishDateTime(scope),
											$t('Finish'),
											60
										)
									"
									:formatter="formatDatetime"
									:label="$t('Finish')"
								>
									<template slot-scope="scope">
										{{
											generateFinishDateTime(scope) || "-"
										}}
									</template>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											'target_base',
											$t('Target Base'),
											60
										)
									"
									:label="$t('Target Base')"
								>
                  <template slot-scope="scope">
                    {{ scope.row.target_base | formatNumber }}
                  </template>
								</el-table-column>

								<el-table-column
									:width="
										fitColumnToContent(
											'status.name',
											$t('Status'),
											60
										)
									"
									prop="status.name"
									:label="$t('Status')"
									:formatter="formatTranslate"
								>
									<template slot-scope="scope">
										<span
											class="badge w-75 badge-pill"
											:class="
												scope.row.status.name.toLowerCase()
											"
											>{{ scope.row.status.name }}</span
										>
									</template>
								</el-table-column>

								<el-table-column
									:min-width="270"
									class-name="action-buttons td-actions"
									align="left"
									:label="$t('Actions')"
								>
									<template slot-scope="scope">
										<p-button
											type="info"
											size="sm"
											icon
											@click="handleEdit(scope.row)"
										>
											<el-tooltip
												class="item"
												effect="dark"
												:content="$t('Edit')"
												placement="top"
											>
												<i class="fa fa-edit"></i>
											</el-tooltip>
										</p-button>

										<p-button
											v-if="canBeCopied(scope.row.type)"
											type="dribbble"
											size="sm"
											icon
											@click="handleCopy(scope.row)"
										>
											<el-tooltip
												class="item"
												effect="dark"
												:content="$t('Clone')"
												placement="top"
											>
												<i class="fa fa-clone"></i>
											</el-tooltip>
										</p-button>

										<CampaignPlay
											v-if="
												states.play.includes(
													scope.row.status_id
												)
											"
											:id="scope.row.id"
											@played="
												onPlayed($event, scope.row)
											"
										/>

										<CampaignPause
											v-if="
												states.pause.includes(
													scope.row.status_id
												)
											"
											:id="scope.row.id"
											@paused="
												onPaused($event, scope.row)
											"
										/>

										<CampaignResume
											v-if="
												states.resume.includes(
													scope.row.status_id
												)
											"
											:id="scope.row.id"
											@resumed="
												onResumed($event, scope.row)
											"
										/>

										<CampaignDelete
											v-if="
												states.delete.includes(
													scope.row.status_id
												)
											"
											:id="scope.row.id"
											@deleted="
												onDeleted($event, scope.row)
											"
											:campaign-type="scope.row.type"
										/>

										<CampaignCancel
											v-if="
												states.cancel.includes(
													scope.row.status_id
												)
											"
											:id="scope.row.id"
											@canceled="
												onCanceled($event, scope.row)
											"
										/>

										<CampaignRetry
											v-if="
												scope.row.type !=
													'PERFORMANCE' &&
													scope.row.channel.id !== 6
											"
											@click="handleRetry(scope.row)"
										/>

										<CampaignStats
											v-if="viewButtonStatus.includes(scope.row.status_id)"
											:campaign-data="scope.row"
										/>
										<!-- <CampaignStats
											v-if="scope.row.status.name.toLowerCase() === 'finished' || scope.row.status_id === 16"
											:campaign-data="scope.row"
										/> -->
									</template>
								</el-table-column>
							</el-table>
						</div>

						<div
							class="col-md-6 mt-4 pagination-info"
							v-if="tableDataPersistent"
						>
							<p class="category">
								{{ tableDataPersistent.metadata.total | formatNumber }}
								{{ $t("entries") }}
							</p>
						</div>
						<div
							class="col-md-6 mt-4"
							v-if="tableDataPersistent"
						>
							<p-pagination
								:page-count="tableDataPersistent.metadata.size"
								class="pull-right"
								v-model="search.defaultPagination"
							>
							</p-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Slide @closeMenu="filterOpen = false" :isOpen="filterOpen">
			<Filters @filterUpdated="filterUpdated($event)" />
		</Slide>

		<fabFilter
			:position="0"
			@click="filterOpen = true"
			v-click-outside="clickOutside"
		/>

		<fabGoToCampaignManager :position="1" />

		<CopyCampaign
			v-if="showCopyModal"
			@copied="onCampaignCopied($event)"
			@cancelled="showCopyModal = false"
			:show="showCopyModal"
			:id="this.copyData.id"
			:name="this.copyData.name"
		/>
	</div>
</template>

<script>
import api from "src/api";
import Pagination from "src/components/UIComponents/Pagination";
import Slide from "src/custom-components/UIComponents/FilterSideBar";
import Filters from "./filters";
import FabFilter from "src/app-components/FabFilter";
import FabGoToCampaignManager from "src/app-components/FabGoToCampaignManager";
import FilterMixin from "src/app-components/FilterMixin";
// import Spinner from "src/app-components/Spinner";
import { QueryBuilder } from "x-query-builder";
import { flow, pick } from "lodash";
import moment from "moment";
import { mapMutations } from "vuex";
import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";

import CopyCampaign from "src/app-components/modal-components/CopyCampaign";

import CampaignDelete from "src/app-components/buttons-components/CampaignDelete";
import CampaignCancel from "src/app-components/buttons-components/CampaignCancel";
import CampaignPlay from "src/app-components/buttons-components/CampaignPlay";
import CampaignPause from "src/app-components/buttons-components/CampaignPause";
import CampaignResume from "src/app-components/buttons-components/CampaignResume";
import CampaignRetry from "src/app-components/buttons-components/CampaignRetry";
import CampaignStats from "src/app-components/buttons-components/CampaignStats";

export default {
	components: {
		CampaignResume,
		CampaignDelete,
		CampaignPause,
		CampaignPlay,
		CampaignRetry,
		CampaignStats,
		CampaignCancel,
		CopyCampaign,
		FabFilter,
		FabGoToCampaignManager,
		Filters,
		Slide,
		// Spinner,
		[Pagination.name]: Pagination,
	},

	mixins: [
		FilterMixin(),
		FitColumnToContentMixin({
			tableDataProp: "tableDataWithoutDeleted.data",
		}),
	],

	created() {
		this.setTopNavbarTitle("Campaign planning");
    this.getChannels();
		this.t = setInterval(() => {
			// Update the next tick
			this.tick = new Date().getTime();
		}, 60000);
	},

	beforeDestroy() {
		clearInterval(this.t);
	},

	watch: {
		"$asyncComputed.tableData.success"() {
			if (this.$asyncComputed.tableData.success) {
				this.refreshTable();
			}
		},
	},

	asyncComputed: {
		async tableData() {
			const pageNumber = this.search.defaultPagination;
			const filters = this.search.filter;

			const query = flow([
				() => new QueryBuilder(),

				(builder) => {
					const where = pick(filters, [
						"Campaign.account_scenario_type_id",
						"Campaign.channel_id",
						"Campaign.status_id IN",
						"Campaign.type",
					]);

					if (this.accountsSelected > 0) {
						where["Campaign.account_id IN"] =
							filters["Campaign.account_id IN"];
					}

					if ("Campaign.name" in filters) {
						where["Campaign.name LIKE "] =
							"%" + filters["Campaign.name"] + "%";
					}

					if ("Campaign.start_datetime" in filters) {
						where["Campaign.start_datetime >="] =
							filters["Campaign.start_datetime"][0];
						where["Campaign.start_datetime <="] =
							filters["Campaign.start_datetime"][1];
					}

					return builder.addWhere(where);
				},

				(builder) => {
					return builder.addContain({
						Account: {},
						AccountUserFile: {
							select: [
								"id",
								"name",
								"file_type",
								"_count_success",
							],
						},
						"AccountScenarioType.ScenarioType.ScenarioChannel": {},
						Status: {},
						Parent: {},
						FirstParent: {},
						Channel: {},
					});
				},

				(builder) => {
					const where = pick(filters, ["Bookmark.name"]);

					if ("Bookmark.name" in where) {
						return builder.addMatching({
							Bookmark: {
								where: {
									"Bookmark.name LIKE":
										"%" + where["Bookmark.name"] + "%",
								},
							},
						});
					}

					return builder;
				},

				(builder) => {
					return builder.addPage(pageNumber);
				},

				(builder) => {
					return builder.addLimit(this.limit);
					// return builder.addLimit(10);
				},

				(builder) => {
					return builder.addGroup(["Campaign.id"]);
				},

				(builder) => {
					return builder.addOrder([
						"IFNULL(Campaign.start_datetime, '9999-12-31 23:59:59') DESC",
					]);
				},
			]);

      const ql = query().build;
      if(ql.where['Campaign.account_id IN'] === undefined) return false;

			const campaigns = await api.getCampaigns({
				ql: ql,
				v: this.tick,
			});
      this.tableDataPersistent = {...campaigns};
      this.lastUpdate = moment(this.tick)
        .tz(this.timezone)
        .format("YYYY-MM-DD, h:mm:ss a");

			return this.tableDataPersistent;
			// return campaigns;
		},
	},

	computed: {
		timezone(){
			return this.$store.getters.appUserTimezone;
		},
		accountsSelected() {
      		return this.search.filter["Campaign.account_id IN"] ?
			this.search.filter["Campaign.account_id IN"].length :
			0;
		},
		tableDataWithoutDeleted() {
			return {
				...this.tableDataPersistent,
				data: this.tableDataPersistent.data.filter((c) => c.status_id != 10),
				/* ...this.tableData,
				data: this.tableData.data.filter((c) => c.status_id != 10), */
			};
		},

    viewButtonStatus() {
      if (this.channels.find((channel) => channel.id == 5)) {
        return [5, 6, 7, 8, 16]
      }
      
      return [7, 16]
    },

    optionsLimit() {
      return [10, 25, 50, 100];
    }
	},

	data() {
		return {
			t: null,
			tick: (new Date()).getTime(),
			showCopyModal: false,
			copyData: {
				id: null,
				name: null,
			},
			states: {
				play: [15],
				pause: [5, 12, 13, 14, 19, 29, 30],
				resume: [8],
				delete: [22, 15, 4, 17],
				cancel: [8],
			},
			tableDataReady: false,
			search: {
				filter: {},
				defaultPagination: 1,
			},
      		tableDataPersistent: null,
      		lastUpdate: null,
      		channels: [],
      		limit: 10
		};
	},

	methods: {
		...mapMutations(["setTopNavbarTitle"]),

    async getChannels() {
      const channels = await api.getScenarioChannels();
      this.channels = channels.data;
    },

		clickOutside(e) {
			if (
				this.filterOpen &&
				e.path.filter((element) => element._prevClass == "bm-menu")
					.length == 0
			) {
				this.filterOpen = false;
			}
		},
		getChannelClass(id) {
			//necesitamos hacer esto porque en css no se pueden hacer elementos con clases númericas ni caracteres especiales como S@at push
			switch (id) {
				case 1:
					return "sat";
					break;
				case 2:
					return "sms";
					break;
				case 3:
					return "fsms";
					break;
				default:
					break;
			}
		},

		handleCopy(row) {
			this.copyData.id = row.id;
			this.copyData.name = row.name;
			this.showCopyModal = true;
		},

		canBeCopied(type) {
			const notAllowed = ["PERFORMANCE", "RETRY"];
			return !notAllowed.includes(type);
		},

		onCampaignCopied($event) {
			this.handleEdit($event);
		},

		onPaused($event, $row) {
			this.$set($row.status, "name", $event.data.status);
			this.$set($row, "status_id", $event.data.status_id);
		},

		onPlayed($event, $row) {
			this.$set($row.status, "name", $event.data.status);
			this.$set($row, "status_id", $event.data.status_id);
		},

		onResumed($event, $row) {
			this.$set($row.status, "name", $event.data.status);
			this.$set($row, "status_id", $event.data.status_id);
		},

		onDeleted($event, $row, deleteChildren = true) {
			this.$set($row.status, "name", $event.data.status);
			this.$set($row, "status_id", $event.data.status_id);
			if (!deleteChildren) return;

			// Mark as deleted descendant campaigns
			if ($row.type != "RETRY" /* first parent */) {
				this.tableData.data
					.filter((c) => c.campaign_first_parent_id == $row.id)
					.forEach((c) => {
						this.onDeleted($event, c, false);
					});
			} else {
				this.tableData.data
					.filter(
						(c) =>
							c.campaign_first_parent_id ==
								$row.campaign_first_parent_id && c.id > $row.id
					)
					.forEach((c) => {
						this.onDeleted($event, c, false);
					});
			}
		},

		onCanceled($event, $row) {
			this.$set($row.status, "name", $event.data.status);
			this.$set($row, "status_id", $event.data.status_id);
		},

		handleEdit(row, params = {}, _type = null) {
			const type = _type || row.type;

			if (type === "SINGLE") {
				this.$router.push({
					// name: `CampaignEditFromScratch`,
					name: `CampaignEdit`,
					params: {
						id: row.id,
						...params,
					},
				});
			}

			if (type === "PING") {
				this.$router.push({
					name: `CampaignEditFromPing`,
					params: {
						id: row.id,
						...params,
					},
				});
			}
			
      if (type === "OTA") {
				this.$router.push({
					name: `CampaignEditOta`,
					params: {
						id: row.id,
						...params,
					},
				});
			}

			if (type === "PERFORMANCE") {
				this.$router.push({
					name: `EditPerformanceCampaign`,
					params: {
						id: row.id,
						...params,
					},
				});
			}

			if (type === "RETRY") {
				this.handleEdit(row.firstParent, {
					tab: "Retry",
				});
			}
		},

		handleRetry(row) {
			const type = row.type;
      console.log("type", type)

			if (type === "SINGLE") {
				this.$router.push({
					// name: `CampaignEditFromScratch`,
					name: `CampaignEdit`,
					params: {
						id: row.id,
						tab: "Retry",
					},
				});
			}

			if (type === "PING") {
				this.$router.push({
					name: `CampaignEditFromPing`,
					params: {
						id: row.id,
						tab: "Retry",
					},
				});
			}
			
      if (type === "OTA") {
				this.$router.push({
					name: `CampaignEditOta`,
					params: {
						id: row.id,
						tab: "Retry",
					},
				});
			}

			if (type === "RETRY") {
				this.handleEdit(row);
			}

			if (type === "PERFORMANCE") {
				// TODO implementar cuando sea necesario
			}
		},

		filterUpdated($event) {
			const actives = pick(
				$event,
				Object.keys($event).filter((key) => $event[key] !== null)
			);
			this.search = {
				defaultPagination: 1,
				filter: { ...actives },
			};
		},

		formatType(row, _, value) {
			return value === "SINGLE"
				? "ON DEMAND"
				: value === "PERFORMANCE"
				? "PROGRAMMATIC"
				: value;
		},

		formatTranslate(row, _, value) {
			return this.$t(value);
		},

		formatDatetime(row, _, value) {
			if (value == null) return null;
			const ret = moment(value).format("YYYY-MM-DD HH:mm");
			return ret;
		},

		generateStartDateTime(scope) {
			if (
				typeof scope.row.start_datetime != undefined &&
				scope.row.start_datetime != null
			) {
				return this.formatDatetime(
					null,
					null,
					scope.row.start_datetime
				);
			}
			return null;
		},

		generateFinishDateTime(scope) {
			if (scope.row.finished_datetime) {
				return this.formatDatetime(
					null,
					null,
					scope.row.finished_datetime
				);
			} else if (scope.row.end_datetime) {
				return this.formatDatetime(null, null, scope.row.end_datetime);
			} else {
				return null;
			}
		},
	},
};
</script>

<style scoped>
.fab-main-container {
	z-index: 1000 !important;
}
:deep(.cell > div) {
	display: contents;
}

.badge.1 {
	--acent: orange;
}
</style>
