<template>
    <div class="rcs-message">
        <p class="m-0">{{message}}</p>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: String,
            default: ''
        }
    },

    computed: {
        message(){
            return this.data;
        }
    }
}
</script>

<style lang="scss" scoped>
    .rcs-message{
        border: 1px solid #d6d6d6;
        color: #252422;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 1em;
        font-size: 67%;
        text-align: left;
        background-color: white;
        max-width: 80%;
        width: fit-content;
        
        p {
            white-space: break-spaces;
        }
    }
</style>