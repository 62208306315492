<template>
  <div>
    <div class="row">
        <div class="col">
            <TopFilters @filterUpdated="topFilterUpdated" />
        </div>
        <div class="col-auto align-self-center">
            <download-excel
                    v-if="!loading"
                    :data="exportData"
                    :name="excelFileName+'.xls'"
                    :title="excelTitle"
                    :worksheet="'Report'">
                <p-button type="success" size="sm">Download as excel</p-button>
            </download-excel>
        </div>
    </div>
    <el-table v-if="!loading" :data="tableData" :show-summary="true" sum-text="Total">
        <el-table-column label="Date" property="date"></el-table-column>
        <el-table-column label="Operator" property="operatorParsed"></el-table-column>
        <el-table-column label="Provider" property="providerParsed"></el-table-column>
        <el-table-column label="Sent SMS" property="sentSms"></el-table-column>
        <el-table-column label="Receive SMS" property="receiveSms"></el-table-column>
        <el-table-column label="Receive DLR" property="receiveDlr"></el-table-column>
        <el-table-column label="FAILED Send SMS" property="failedSendSms"></el-table-column>
    </el-table>
    <Spinner v-else :display="true"></Spinner>
    <Slide
            v-if="active"
            @closeMenu="filterOpen = false"
            :isOpen="filterOpen"
    >
      <SideBarFilters @filterUpdated="sideBarFilterUpdated" />
    </Slide>
    <fabFilterBookmark @click="filterOpen = true" v-click-outside="clickOutside"/>
  </div>
</template>

<script>
  import {Table, TableColumn} from 'element-ui'
  import SideBarFilters from 'src/views/smpp-gateway/statistics/side-bar-filters'
  import TopFilters from 'src/views/smpp-gateway/statistics/top-filters'
  import Slide from "src/custom-components/UIComponents/FilterSideBar";
  import FabFilterBookmark from "src/app-components/FabFilter";
  import FilterMixin from "src/app-components/FilterMixin";
  import api from 'src/api'
  import moment from 'moment'
  import JsonExcel from 'vue-json-excel'
  import _ from 'lodash'
  import Spinner from "src/app-components/Spinner";

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      fabFilterBookmark: FabFilterBookmark,
      SideBarFilters,
      TopFilters,
      Slide,
      "download-excel": JsonExcel,
      Spinner
    },
    mixins: [
      FilterMixin(),
    ],
    mounted () {
      this.remoteFilters = {
          startDate: moment().startOf('day').format(),
          endDate: moment().endOf('day').format()
      };
    },
    props: {
      active: {
        type: Boolean,
        default: true,
      }
    },
    watch: {
      active () {
        this.filterOpen = false;
      },
      remoteFilters () {
        if (this.remoteFilters.startDate == null || this.remoteFilters.endDate == null) {
            this.statsData = null;
            return;
        }
        this.loadStats(this.remoteFilters);
      }
    },
    computed: {
      tableData () {
        if (this.statsData == null) return [];
        return [ ...this.statsData.data.byProvider.buckets.reduce(this.providerReducer(), []).filter((row) => {
            if (!this.localFilters.provider) return true;
            return this.localFilters.provider.name == row.providerParsed;
        }) ].sort(this.sortByDate);
      },
      exportData () {
          return [...this.tableData, {
              date: "Total",
              operatorParsed: "",
              providerParsed: "",
              sentSms: this.calculateTotalForReport("sentSms"),
              receiveSms: this.calculateTotalForReport("receiveSms"),
              receiveDlr: this.calculateTotalForReport("receiveDlr"),
              failedSendSms: this.calculateTotalForReport("failedSendSms")
          }].map(e => ({
              "Date": e.date,
              "Operator": e.operatorParsed,
              "Provider": e.providerParsed,
              'Sent SMS': e.sentSms,
              'Receive SMS': e.receiveSms,
              'Receive DLR': e.receiveDlr,
              'FAILED Send SMS': e.failedSendSms
          }));
      },
      excelFileName () {
          const fromDate = moment(this.remoteFilters.startDate).format("YYYY-MM-DD");
          const toDate = moment(this.remoteFilters.endDate).format("YYYY-MM-DD");
          return `Dynamo_SMPP_Gateway_Report_${fromDate}_to_${toDate}`
      },
      excelTitle () {
          const fromDate = moment(this.remoteFilters.startDate).format("YYYY-MM-DD");
          const toDate = moment(this.remoteFilters.endDate).format("YYYY-MM-DD");
          return `Dynamo SMPP Gateway Report: ${fromDate} to ${toDate}`
      },
      loading () {
          return this.loadingCount > 0;
      }
    },
    methods: {
      clickOutside(e){
        if (this.filterOpen && e.path.filter(element => element._prevClass == "bm-menu").length == 0) {
          this.filterOpen = false;
        };
      },
      providerReducer () {
          const self = this;
          return function (accumulator, provider) {
              const smscParsed = provider.key.split(';');
              const dates = self.generateDateRange(self.remoteFilters.startDate, self.remoteFilters.endDate);
              accumulator.push(...dates.map(date => ({
                  date,
                  operatorParsed: smscParsed[0],
                  providerParsed: smscParsed[1],
                  sentSms: self.findColumnValue(provider, "Sent SMS", date),
                  receiveSms: self.findColumnValue(provider, "Receive SMS", date),
                  receiveDlr: self.findColumnValue(provider, "Receive DLR", date),
                  failedSendSms: self.findColumnValue(provider, "FAILED Send SMS", date),
              })));
              return accumulator;
          }
      },
      generateDateRange(initialDateStr, endDateStr) {
          const initialDateUnix = moment(initialDateStr).unix();
          const endDateUnix = moment(endDateStr).unix();
          return _.range(initialDateUnix, endDateUnix, 24 * 60 * 60).map(e => moment.unix(e).format("YYYY-MM-DD"));
      },
      calculateTotalForReport(action) {
          return this.tableData.reduce(function(total, currentValue, currentIndex, arr){
              return currentValue[action] + total;
          }, 0);
      },
      sortByDate (o1,o2) {
          o1 = moment(o1.date).unix();
          o2 = moment(o2.date).unix();
          if (o1 < o2) return -1;
          else if(o1 > o2) return 1;
          else return 0;
      },
      findColumnValue (smsc, column, date) {
        let value = smsc.byAction.buckets.find(b => b.key == column);
        if (value) {
            value = value.byDate.buckets.find(b => b.key == date)
            if (value) {
                //return value.doc_count;
                return value.mtcountsum.value;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
      },
      sideBarFilterUpdated (filters) {
        this.localFilters = filters;
      },
      topFilterUpdated (filters) {
        this.remoteFilters = filters;
      },
      async loadStats (filters) {
        filters = _.cloneDeep(filters);

        const dateFromStr = moment(filters.startDate).format("YYYY-MM-DD HH:mm:ss");
        const dateToStr = moment(filters.endDate).format("YYYY-MM-DD HH:mm:ss");
        const dateFrom = moment.tz(dateFromStr, this.$store.getters.appUserTimezone);
        const dateTo = moment.tz(dateToStr, this.$store.getters.appUserTimezone);
        const tzOffset = dateFrom.utcOffset();
        filters.startDate = dateFrom.format();
        filters.endDate = dateTo.format();

        try {
            this.loadingCount++;
            this.statsData = await api.getSmppGatewayStatistics({ ...filters, timezone: tzOffset });
        } finally {
            this.loadingCount--;
        }
      },
    },
    data () {
      return {
        statsData: null,
        localFilters: {},
        remoteFilters: {},
        loadingCount: 0,
      }
    }
  };
</script>
<style>
</style>
