<template>
  <div>
    <el-table-draggable v-if="callToActions.length > 0" @drop="handleRowDrop">
      <el-table :data="value">
        <el-table-column :label="'#'" type="index" />
        <el-table-column align="center" prop="menuTitle">
          <template slot="header" slot-scope="scope">
            <div class="text-center">
              {{ $t("Entry text") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header" slot-scope="scope">
            <div class="text-center">
              {{ $t("Call to action") }}
            </div>
          </template>
          <template slot-scope="scope">
            {{
              $t(
                callToActions.find((c) => c.id == scope.row.callToActionId).name
              )
            }}
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header" slot-scope="scope">
            <div class="text-center">
              {{ $t("Info") }}
            </div>
          </template>
          <template slot-scope="scope">
            <span
              v-html="
                callToActionConfigAsString(
                  scope.row.callToActionId,
                  scope.row.callToActionConfig
                )
              "
            />
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot="header" slot-scope="scope">
            <div class="text-center">
              {{ $t("Actions") }}
            </div>
          </template>
          <template slot-scope="scope">
            <p-button
              type="warning"
              class="btn-sm"
              @click="$emit('edit', scope.$index)"
            >
              <i class="nc-icon nc-ruler-pencil"></i>
            </p-button>
            <p-button type="danger" class="btn-sm" @click="handleDelete(scope)">
              <i class="nc-icon nc-basket"></i>
            </p-button>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
    <div style="font-size: 0.8em" class="mt-3 text-center text-muted">
      <i class="fa fa-info-circle mr-2"></i>
      {{ $t("Drag and drop rows to change position") }}
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import lodash from "lodash";
import swal from "sweetalert2";
import api from "src/api";
import ElTableDraggable from "element-ui-el-table-draggable";

export default {
  props: {
    value: Array,
  },

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ElTableDraggable,
  },

  data() {
    return {
      callToActions: [],
    };
  },

  async mounted() {
    this.callToActions = (await api.getSatCallToActions()).data;
  },
  methods: {
    async handleDelete(scope) {
      const confirmResult = await swal({
        title: "Delete?",
        //text,
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
        allowOutsideClick: false,
      });
      if (!confirmResult.value) return;
      this.$emit("deleted", scope.$index);
    },
    callToActionConfigAsString(callToActionId, callToActionConfig) {
      const variables = (
        "variables" in callToActionConfig ?
        callToActionConfig.variables :
        Object.values(callToActionConfig)
      );
      return `<ul>` +
      
        variables.map(v => {
          let variableObj = v.variable || v.variable_id;
          let value = (
            callToActionId == 1 ? // Launch Browser
            `<a href="${v.value}">${v.value}</a>` :
            v.value
          );

          return `<li>` + this.$t(variableObj.tooltip.toUpperCase()) + ": " + value + `</li>`;
        })
        .join("") + `</ul>`;
    },
    handleRowDrop(data) {
      this.$emit("reordered", lodash.cloneDeep(data.list));
    },
  },
};
</script>