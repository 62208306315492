import { render, staticRenderFns } from "./CreateElementButtons.vue?vue&type=template&id=77e2e26b&scoped=true"
import script from "./CreateElementButtons.vue?vue&type=script&lang=js"
export * from "./CreateElementButtons.vue?vue&type=script&lang=js"
import style0 from "./CreateElementButtons.vue?vue&type=style&index=0&id=77e2e26b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e2e26b",
  null
  
)

export default component.exports