<template>
  <div class="input-number-segmentation mr-2 w-50">
    <el-input-number
      v-model="valueModel"
      :controls="false"
      :placeholder="$t(placeholder)"
      :disabled="disabled"
      @input="handleValueParamsChange($event)"
    ></el-input-number>
  </div>
</template>

<script>
import { InputNumber } from "element-ui";
export default {
  name: "SelectSegmentation",
  props: {
    value: {
      default: null,
    },

    placeholder: {
      default: '',
    },

    param: {
      default: '',
    },
    
    default: {
      default: 1,
    },
    
    disabled: {
      default: false,
    },
  },

  components: {
    [InputNumber.name]: InputNumber,
  },

  watch: {
    valueModel() {
      if(!this.valueModel) {
        this.valueModel = this.default;
        this.handleValueParamsChange(this.valueModel);
      }
    },
  },

  data() {
    return {
      valueModel: 1,
    };
  },

  created() {
    if(this.value !== null) {
      this.valueModel = this.value;
    }else {
      this.valueModel = this.default;
      this.handleValueParamsChange(this.valueModel);
    }
  },

  methods: {
    handleValueParamsChange(value) {
      this.$emit("valueParamsChange", {
        value,
        param: this.param,
      });
    },
  },
};
</script>

<style scoped>
.input-number-segmentation {
  width: 80px;
}

.input-number-segmentation > * {
  width: 100%;
}
</style>
