<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="optionsComponent.tooltip"
    placement="top"
    :disabled="optionsComponent.tooltip === ''"
    :open-delay="1000"
  >
    <div class="title-box" :class="optionsComponent.class">
      <el-input size="mini" v-model="nodeName" maxlength="20" @blur="setNewName" title="Edit name"></el-input>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <i class="el-icon-more text-white"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="p-0">
          <el-dropdown-item v-for="opt in optionsDropdown" :key="opt.label" class="px-3">
            <span @click="emitEvent(opt.event)">{{ opt.label }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-tooltip>
</template>

<script>
  import { EventBus } from "src/eventBus.js";
  import { Input, Button, Tooltip, Dropdown, DropdownMenu, DropdownItem } from "element-ui";
  export default {
    name: "TitleBox",
    props: ["id", "name", "componentName"],
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },

    data() {
      return {
        nodeName: "",
      };
    },

    watch: {
      name() {
        if(this.name !== this.nodeName) {
          this.setNodeName();
        }
      },
    },

    created() {
      this.setNodeName();
    },

    methods: {
      emitEvent(event) {
        EventBus.$emit(event, this.id);
      },
    
      setNodeName() {
        this.nodeName = `${this.name}`;
      },

      setNewName() {
        if(this.nodeName === "") {
          this.setNodeName();
        }else {
          this.$emit("setNewName", this.nodeName)
        }
      },
    },

    computed: {
      optionsComponent() {
        let options = {
          Message: {
            tooltip: "Message",
            class: "success",
          },
          MessageWithOptions: {
            tooltip: "Message with options",
            class: "primary",
          },
          DataStorage: {
            tooltip: "Data storage",
            class: "info",
          },
          Condition: {
            tooltip: "Condition",
            class: "danger",
          },
          Integration: {
            tooltip: "Integration",
            class: "warning",
          },
        };

        return options[this.componentName]
          ? options[this.componentName]
          : {
              tooltip: "",
              class: "",
            };
      },

      optionsDropdown() {
        return [
          {
            label: "Configuration",
            event: "activeOptions",
          },
          {
            label: "Clone node",
            event: "cloneNodeId",
          },
          {
            label: "Delete node",
            event: "removeNodeId",
          },
        ];
      },
    },
  };
</script>
<style scoped>
  .wrapperCanvas .drawflow .title-box {
    background: #409eff;
    font-weight: bold;
    color: white;
    border-bottom: 1px solid #e9e9e9;
    border-radius: 4px 4px 0px 0px;
    padding: 0 10px;

    height: 30px;
    line-height: 30px;
    font-size: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrapperCanvas .el-dropdown-link i {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
  }

  .wrapperCanvas .drawflow .title-box.success {
    background: #85ce61;
  }

  .wrapperCanvas .drawflow .title-box.primary {
    background: #409eff;
  }

  .wrapperCanvas .drawflow .title-box.info {
    background: #909399;
  }

  .wrapperCanvas .drawflow .title-box.danger {
    background: #f56c6c;
  }

  .wrapperCanvas .drawflow .title-box.warning {
    background: #e6a23c;
  }

  .wrapperCanvas .drawflow .title-box .more-options {
    color: white;
  }

  .wrapperCanvas.dark .drawflow .title-box.success {
    background: #03dac6;
  }

  .wrapperCanvas.dark .drawflow .title-box.primary {
    background: #6200ee;
  }

  .wrapperCanvas.dark .drawflow .title-box.info {
    background: #bb86fc;
  }

  .wrapperCanvas.dark .drawflow .title-box.danger {
    background: #cf6679;
  }

  .wrapperCanvas .drawflow .title-box.warning {
    background: #e6a23c;
  }
</style>

<style>
.title-box .el-input input.el-input__inner {
  border: 0;
  background: transparent!important;
  padding: 0;
  color: white;
  font-weight: bold;
}
</style>
