<template>
  <el-tabs v-model="activeTab" class="form-integration" v-if="integrationData">
    <el-tab-pane label="URL" name="url">
      <div class="d-flex justify-content-between align-items-center">
        <div class="pr-2" style="margin-bottom: 25px;">
          <span class="label">Method</span>
          <el-select v-model="integrationData.method" @change="updateData">
            <el-option value="GET"></el-option>
            <el-option value="POST"></el-option>
          </el-select>
        </div>
        <div class="w-100">
          <span class="label">URL</span>
          <input-actions
            :classInput="classInputURL"
            :variables="variables"
            :value="integrationData.url"
            :onlyVariables="true"
            :noAutosize="true"
            @updateValue="updateItem('url', $event)"
          />
        </div>
      </div>
    </el-tab-pane>

    <el-tab-pane label="Params" name="params">
      <form-key-value
        :list="integrationData.params"
        :variables="variables"
        @updateData="setValue('params', $event)"
      />
    </el-tab-pane>

    <el-tab-pane label="Headers" name="headers">
      <form-key-value
        :list="integrationData.headers"
        @updateData="setValue('headers', $event)"
      />
    </el-tab-pane>

    <el-tab-pane label="Body" name="body">
      <div>
        <json-editor
          mode="code"
          :json="jsonBody"
          :classDiv="`body-${idOption}`"
          @updateJson="updateItem('body', JSON.stringify($event))"
        />
      </div>
    </el-tab-pane>

    <el-tab-pane label="Response" name="response">
      <form-key-value
        :list="integrationData.response"
        :variables="variables"
        :isResponse="true"
        @updateData="setValue('response', $event)"
      />
    </el-tab-pane>

    <el-tab-pane label="Test" name="test">
      <div>
        <div v-if="params.variables.length">
          <span class="label">Params</span>
          <template v-for="(param, key) in params.variables">
            <el-input
              :key="key"
              size="mini"
              class="mb-2"
              :value="dataVariable(param).value"
              v-model="testParams[param]"
              :type="dataVariable(param).type"
              v-if="dataVariable(param).editable"
            >
              <template slot="prepend">{{ param }}</template>
            </el-input>
          </template>
        </div>

        <div v-if="variablesBody.length">
          <span class="label">Body</span>
          <template v-for="(variable, key) in variablesBody">
            <el-input
              :key="key"
              size="mini"
              class="mb-2"
              :value="dataVariable(variable).value"
              v-model="testBody[variable]"
              :type="dataVariable(variable).type"
              v-if="dataVariable(variable).editable"
            >
              <template slot="prepend">{{ variable }}</template>
            </el-input>
          </template>
        </div>

        <el-button @click="testFetchApi" :disabled="loadingTest" class="mb-2">
          <i v-if="loadingTest" class="el-icon-loading"></i>
          <span v-else>TEST</span>
        </el-button>

        <br />
        <json-editor
          mode="view"
          :json="resTest"
          :classDiv="`test-${idOption}`"
          v-if="resTest"
        />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import { clone } from "lodash";
  import { Button, Input, Select, Tabs, TabPane, Tag } from "element-ui";

  import FormKeyValue from "src/app-components/chatbot/elements/FormKeyValue";
  import InputActions from "src/app-components/chatbot/elements/InputActions";

  import JsonEditor from "src/app-components/chatbot/elements/JsonEditor";

  export default {
    name: "FormIntegration",
    props: {
      integration: {
        type: Object,
        default: () => {},
      },
      variables: {
        type: Array,
        default: () => [],
      },
      idOption: {
        type: Number,
        default: () => 0,
      },
      validate: {
        type: Boolean,
        default: () => false,
      },
    },

    components: {
      "json-editor": JsonEditor,
      [Button.name]: Button,
      [Input.name]: Input,
      [Select.name]: Select,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Tag.name]: Tag,
      "form-key-value": FormKeyValue,
      "input-actions": InputActions,
    },

    data() {
      return {
        activeTab: "url",

        integrationData: null,

        resTest: null,

        loadingTest: false,

        testParams: {},
        testBody: {},

        regexURL: new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi),
      };
    },

    mounted() {
      this.integrationData = clone(this.integration);
    },

    watch: {
      valid() {
        this.$emit("updateValid", this.valid);
      }
    },

    methods: {
      changeBody(body) {
        this.integrationData.body = body;
        this.updateData();
      },

      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.integrationData);
          this.$emit("updateValid", this.valid);
        }, 1);
      },

      setValue(item, value) {
        if (this.integrationData[item]) {
          this.integrationData[item] = clone(value);

          setTimeout(() => {
            this.updateData();
          }, 1);
        }
      },

      updateItem(item, value) {
        if(this.integrationData[item] !== undefined) {
          this.integrationData[item] = value;
          this.updateData();
        }
      },

      async testFetchApi() {
        if (this.integrationData.url !== "") {
          this.resTest = null;
          this.loadingTest = true;
          let variablesBody = this.variablesBody;
          let params = "";
          let headers = {};
          let body = `${this.integrationData.body}`;

          this.integrationData.headers.forEach((h) => {
            headers[h.key] = h.value;
          });

          this.integrationData.params.forEach((p) => {
            var key = p.key;
            var value = p.value;

            this.params.variables.forEach((v) => {
              if (this.testParams[v]) {
                value = value.replaceAll(`{{${v}}}`, this.testParams[v]);
              }
            });

            if (params === "") {
              params += `?${key}=${value}`;
            } else {
              params += `&${key}=${value}`;
            }
          });

          variablesBody.forEach((v) => {
            if (this.testBody[v]) {
              body = body.replaceAll(`{{${v}}}`, this.testBody[v]);
            }
          });

          let res = await this.testIntegration({
            url: this.integrationData.url + params,
            method: this.integrationData.method,
            headers,
            body: body ? body : {},
          });

          this.resTest = res;
          this.loadingTest = false;
        }
      },

      async testIntegration({ url, method, headers, body }) {
        let params = { method, headers };
        if (method.toLowerCase() === "post") {
          params.body = body;
        }

        try {
          const response = await fetch(url, params);
          return response.json();
        } catch (error) {
          return error;
        }
      },

      dataVariable(variable) {
        let res = this.variables.find((v) => v.name === variable);
        return res ? res : {};
      },
    },

    computed: {
      regexVariable() {
        return /\{\{(\w+)\}\}/g;
      },

      params() {
        let params = "";
        let variables = [];
        if (this.integrationData.params) {
          this.integrationData.params.forEach((p) => {
            if (params === "") {
              params += `?${p.key}=${p.value}`;
            } else {
              params += `&${p.key}=${p.value}`;
            }
            let res = p.value.match(this.regexVariable);
            if (res) {
              res = res.map((c) => c.match(/\w+/)[0]);
              variables = [...variables, ...res];
            }
          });
        }

        return {
          params,
          variables,
        };
      },

      variablesBody() {
        if (this.integrationData) {
          if (this.integrationData.body) {
            let res = this.integrationData.body.match(this.regexVariable);
            if (res) {
              return res.map((c) => c.match(/\w+/)[0]);
            }
          }
        }

        return [];
      },

      jsonBody() {
        if (this.integrationData) {
          if (this.integrationData.body) {
            return JSON.parse(this.integrationData.body);
          }
        }
        return {};
      },

      valid() {
        if(this.integrationData) {
          if(this.integrationData.url) {
            if(this.integrationData.url.match(this.regexURL)) {
              return true;
            }
          }
        }
        return false;
      },

      classInputURL() {
        if(this.validate) {
          if(!this.valid) {
            return "invalid";
          }
        }
        return "";
      }
    },
  };
</script>

<style>
  .form-integration .el-input-group__append {
    padding: 0;
  }

  .form-integration .el-input-group__append span {
    padding: 0 15px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
</style>
