<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t(label) }}</label>
        <el-select
          size="large"
          :name="name"
          multiple
          allow-create
          default-first-option
          :filterable="true"
          :clearable="true"
          v-validate="required ? 'required' : null"
          @input="$emit('input', $event)"
          :error="getError(name)"
          :value="local"
          :placeholder="''"
        >
          <el-option
            v-for="option in options"
            :value="option.value"
            :label="option.label"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
      <div
        v-show="errors.has(name)"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first(name) }}</div>
    </div>


    <!--
    <div class="col-md-12">
      <fg-input
        :value="local"
        type="text"
        @input="$emit('input', $event == '' ? null : $event)"
        :name="name"
        :label="$t(label)"
        v-validate="required ? 'required' : null"
        :error="getError(name)"
      ></fg-input>
    </div>
    -->
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  name: "service-name",

  props: {
    value: null,
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Service Name"
    },
    name: {
      type: String,
      default: "service-name"
    }
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    local() {
      return this.value ? this.value : "";
    }
  },

  data() {
    return {
      options: []
    };
  }
};
</script>

<style>
</style>
