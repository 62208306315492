<template>
  <div class="card">
    <DateRangePicker
      :auto-translate-shortcuts="true"
      class="col-lg-4 p-0 m-0 w-50 mt-2"
      @date-range-changed="getNewData($event)"
    />

    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t(column.label)" v-for="column in columnsTable" :key="column.label">
        <template slot-scope="scope">
          <span>{{ scope.row[column.param] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import api from "src/api";
  import moment from "moment";
  import { mapMutations } from "vuex";
  import { Table } from "element-ui";
  import DateRangePickerFormComponent from "src/app-components/form-elements/DateRangePicker";
  export default {
    name: "AnalyticsIndex",

    components: {
      [Table.name]: Table,
      DateRangePicker: DateRangePickerFormComponent,
    },

    data() {
      return {
        dateRange: {},
        stats: [],
      };
    },

    created() {
      if(!this.$can('Views', 'Chatbots')) this.$router.push({ name: "DashboardIndex" });
      
      this.setTopNavbarTitle("Analytics");
    },

    mounted() {
      this.getChatbotReport();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      getNewData($event) {
        if(!$event) {
          this.getChatbotReport();
          return;
        }

        const from = moment(new Date($event[0])).format("YYYY-MM-DD 00:00:00");
        const to = moment(new Date($event[1])).format("YYYY-MM-DD 23:59:59");

        this.getChatbotReport(from, to);
      },

      async getChatbotReport(from, to) {
        let filters = {
          to: to ? to : moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        };

        if (from) {
          filters.from = from;
        }

        let res = await api.getChatbotReport(filters);
        if(res.data) {
          this.stats = res.data;
        }
        console.log("getChatbotReport", res);
      },
    },

    computed: {
      tableData() {
        return this.stats ? this.stats : [];
      },

      columnsTable() {
        return [
          {
            label: "Flow name",
            param: "ChatbotFlowName",
            prepend: "",
            append: "",
          },
          {
            label: "Agent name",
            param: "AgentName",
            prepend: "",
            append: "",
          },
          {
            label: "Sessions opened",
            param: "SessionsOpened",
            prepend: "",
            append: "",
          },
          {
            label: "Sessions closed by user",
            param: "SessionsClosedByUser",
            prepend: "",
            append: "",
          },
          /* {
            label: "Sessions closed by timeout",
            param: "SessionsClosedByTimeout",
            prepend: "",
            append: "",
          }, */
          {
            label: "Messages received",
            param: "MessagesReceived",
            prepend: "",
            append: "",
          },
          {
            label: "Messages sent",
            param: "MessagesSent",
            prepend: "",
            append: "",
          },
          {
            label: "Messages sent fallback",
            param: "MessagesSentFallback",
            prepend: "",
            append: "",
          },
          {
            label: "Messages sent timeout",
            param: "MessagesSentTimeout",
            prepend: "",
            append: "",
          },
          {
            label: "Messages sent insistence",
            param: "MessagesSentInsistence",
            prepend: "",
            append: "",
          },
          /* {
            label: "New users",
            param: "NewUsers",
            prepend: "",
            append: "",
          },
          {
            label: "Recurrent users",
            param: "RecurrentUsers",
            prepend: "",
            append: "",
          },
          {
            label: "Total session time",
            param: "TotalSessionTime",
            prepend: "",
            append: "",
          }, */
        ]
      }
    },
  };
</script>