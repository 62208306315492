<template>
  <div class="card p-3 gbm-agent-form">
    <div class="d-md-flex justify-content-between">
      <div class="mb-3" :class="validate && agent.name === '' ? 'error' : ''">
        <span>Name*</span>
        <el-input v-model="agent.name"></el-input>
      </div>

      <div class="mb-3" :class="validate && agent.token === '' ? 'error' : ''">
        <span>Token*</span>
        <el-input v-model="agent.token" @input="validateInput('token')"></el-input>
      </div>
    </div>

    <div class="d-md-flex justify-content-between">
      <div class="mb-3" :class="validate && agent.phone_number === '' ? 'error' : ''">
        <span>Phone Number*</span>
        <el-input v-model="agent.phone_number" @input="validateInput('phone_number')"></el-input>
      </div>

      <div class="mb-3" :class="validate && agent.phone_id === '' ? 'error' : ''">
        <span>Phone ID*</span>
        <el-input v-model="agent.phone_id" @input="validateInput('phone_id')"></el-input>
      </div>
    </div>
    <div class="mb-3 w-100" :class="validate && agent.WABA_id === '' ? 'error' : ''">
      <span>WABA ID*</span>
      <el-input v-model="agent.WABA_id" @input="validateInput('WABA_id')"></el-input>
    </div>

    <el-button type="primary" @click="createAgentWPP" :disabled="loading">
      <i class="el-icon-loading" v-if="loading"></i>
      <span v-else>Save</span>
    </el-button>
    <el-button type="text" @click="cancel" :disabled="loading" class="mt-2 pb-0"> Cancel </el-button>
  </div>
</template>

<script>
  import sio from "@/sio";
  import api from "src/api";
  import { mapMutations } from "vuex";
  import { Input, Button } from "element-ui";
  export default {
    name: "FormWPP",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
    },

    data() {
      return {
        agent: {
          name: "",
          token: "",
          phone_id: "",
          phone_number: "",
          WABA_id: "",
        },

        loading: false,
        validate: false,
      };
    },

    created() {
      if(!this.$can('Views', 'Services')) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle("Create Service Whatsapp");

      sio.onAny((event, obj) => {
        console.log("agent", obj);
        if (obj.metadata) {
          if (obj.metadata.service) {
            this.loading = false;
            if (obj.metadata.service.status) {
              this.agent = {
                name: "",
                token: "",
                phone_id: "",
                phone_number: "",
                WABA_id: "",
              };
              this.cancel();
            }
          }
        }
      });
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      cancel() {
        this.$router.push({ name: "ServicesIndex" });
      },

      async createAgentWPP() {
        if (this.isValid) {
          this.loading = true;
          await api.createService({
            channel_id: 8,
            chatbot_params: this.agent,
          });
        } else {
          this.validate = true;
          setTimeout(() => {
            this.validate = false;
          }, 3000);
        }
      },

      validateInput(value) {
        if(this.agent[value]) {
          if(value !== "token") {
            const regex = /[^0-9]/g;
            this.agent[value] = this.agent[value].replace(regex, "");
          }else {
            this.agent[value] = this.agent[value].replaceAll(" ", "");
          }
        }
      },
    },

    computed: {
      isValid() {
        if (
          this.agent.name === "" ||
          this.agent.token === "" ||
          this.agent.phone_id === "" ||
          this.agent.phone_number === "" ||
          this.agent.WABA_id === ""
        ) {
          return false;
        }
        return true;
      },
    },
  };
</script>

<style scoped>
  .gbm-agent-form .error {
    color: red;
  }
  .gbm-agent-form {
    max-width: 800px;
    margin: 0 auto;
  }

  .gbm-agent-form .d-md-flex > div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .gbm-agent-form .d-md-flex > div {
      width: 49%;
    }

    .gbm-agent-form .d-md-flex.time > div {
      width: 24%;
    }
  }
</style>

<style>
  .gbm-agent-form .error .el-input__inner,
  .gbm-agent-form .error .el-textarea__inner {
    border-color: red;
    color: red;
  }
</style>
