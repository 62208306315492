var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outbound-index"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-3 pt-3"},[_c('h1',{staticClass:"h6 mb-0"},[_vm._v(_vm._s(_vm.$t("Campaigns")))]),_c('div',{staticClass:"d-flex justify-content-between align-items-center container-search"},[_c('el-input',{staticClass:"mr-3",attrs:{"placeholder":_vm.$t('Search'),"suffix-icon":"el-icon-search","size":"small"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.modalCreate = true}}},[_vm._v(_vm._s(_vm.$t("Create campaign")))])],1)]),_c('el-table',{staticClass:"w-100",attrs:{"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"label":"ID","prop":"id","width":"75"}}),_c('el-table-column',{attrs:{"label":"Name","prop":"name"}}),_c('el-table-column',{attrs:{"label":"Template","prop":"template_name"}}),_c('el-table-column',{attrs:{"label":"Channel"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.getService(scope.row.service_id).channel_id === 8 || _vm.setChannelName))])]}}])}),_c('el-table-column',{attrs:{"label":"Service"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.getService(scope.row.service_id).agent_options
                ? _vm.getService(scope.row.service_id).agent_options.phone
                : '',"disabled":!_vm.getService(scope.row.service_id).agent_options,"placement":"top"}},[_c('span',[_vm._v(_vm._s(_vm.getService(scope.row.service_id).name))])])]}}])}),_c('el-table-column',{attrs:{"label":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.start_date ? new Date(scope.row.start_date).toLocaleString("es-ES", _vm.optionsDate) : "-"))])]}}])}),_c('el-table-column',{attrs:{"label":"Target"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":`Date range: ${scope.row.date_range}`,"placement":"top","disabled":_vm.getService(scope.row.service_id).channel_id === 8}},[_c('span',[_vm._v(_vm._s(scope.row.audience))])])]}}])}),_c('el-table-column',{attrs:{"label":"Status","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"status",class:_vm.statusName(scope.row.status).toLowerCase()},[_vm._v(" "+_vm._s(_vm.statusName(scope.row.status))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Actions","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.buttonsActions),function(btn){return _c('el-tooltip',{key:btn.tooltip,staticClass:"item",attrs:{"effect":"dark","content":_vm.$t(btn.tooltip),"placement":"top"}},[(
                _vm.checkViewAction({
                  status: scope.row.status,
                  tooltipAction: btn.tooltip,
                  channel_id: _vm.getService(scope.row.service_id).channel_id,
                })
              )?_c('el-button',{staticClass:"icon-action py-0",attrs:{"type":"text"},on:{"click":function($event){return btn.action(scope.row.id)}}},[_c('i',{class:btn.icon})]):_vm._e()],1)})}}])})],1)],1),_c('el-dialog',{attrs:{"title":_vm.campaignStats ? _vm.campaignStats.name : '',"visible":_vm.dialogStatusVisible,"width":"320px"},on:{"update:visible":function($event){_vm.dialogStatusVisible=$event},"closed":_vm.closeDialogStats}},[(_vm.loadingStats)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}})]):_c('el-table',{staticClass:"w-100",attrs:{"data":_vm.tableStats,"size":"small","stripe":"","header-cell-class-name":() => 'py-1'}},[_c('el-table-column',{attrs:{"label":"Status","prop":"status"}}),_c('el-table-column',{attrs:{"label":"Messages","prop":"value"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small","type":"info"},on:{"click":function($event){_vm.dialogStatusVisible = false}}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.exportToExcel}},[_vm._v(_vm._s(_vm.$t("Download report")))])],1)],1),_c('el-dialog',{staticClass:"confirm-dialog",attrs:{"width":"30%","visible":_vm.modalCreate,"title":"Select channel"},on:{"update:visible":function($event){_vm.modalCreate=$event}}},[_c('el-select',{staticClass:"d-block my-2 mx-4",attrs:{"placeholder":"Select channel"},model:{value:(_vm.idChannelCreate),callback:function ($$v) {_vm.idChannelCreate=$$v},expression:"idChannelCreate"}},_vm._l((_vm.channels),function(channel){return _c('el-option',{key:channel.id,attrs:{"value":channel.id,"label":channel.name}})}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini","type":"primary","disabled":!_vm.idChannelCreate},on:{"click":_vm.formView}},[_vm._v(" Create ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }