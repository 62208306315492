<script>
import api from "src/api";
import Report from "./Report";
import { excelFormatPercentage, excelFormatNumber } from "./excel_formats.js";

export default {
  name: "RcsCampaignReport",

  extends: Report,

  created() {
    this.tableCols = [
      { prop: "Campaign", label: "Campaign", minWidth: 100 },
      {
        prop: "Audience",
        label: "Audience",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 140,
      },
      {
        prop: "Requests",
        label: "Requests",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 110,
      },
      {
        prop: "Sent",
        label: "Sent",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 130,
      },
      {
        prop: "Delivered",
        label: "Delivered",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 130,
      },
      {
        prop: "DeliveredRate",
        label: "Delivered %",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
        width: 130,
      },
      {
        prop: "Read",
        label: "Read",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 110,
      },
      {
        prop: "ReadRate",
        label: "Read %",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
        width: 130,
      },
      {
        prop: "Incompatible",
        label: "Incompatible",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 130,
      },
      {
        prop: "TotalClicks",
        label: "Clicks",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 110,
      },
      {
        prop: "ClicksRate",
        label: "Clicks %",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
        width: 130,
      },
      {
        prop: "UniqueClicks",
        label: "Unique Clicks",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 130,
      },
    ];
    this.customTableKeyCols = [{ prop: "Rcs", label: "Rcs" }];
  },

  methods: {
    /**
     * @Override
     */
    filterProp() {
      return "Campaign";
    },
    /**
     * @Override
     */
    tableDimensions() {
      return ["id ASC"];
    },

    /**
     * @Override
     */
    tableRowClassName({ row, rowIndex }) {
      if (row.id === null) {
        return "success-row";
      }

      return "";
    },

    prepareFilter() {
      const filter = {
        date_from: this.filters["campaign_start_datetime >="],
        date_to: this.filters["campaign_start_datetime <="],
        group_by: this.dimensions.map((dimension) => dimension.groupingProp).join(","),
      };
      if (this.campaignId) {
        filter.campaign_id = this.campaignId;
      }
      return filter;
    },

    async getData(query) {
      const filters = this.prepareFilter();
      const response = await api.getRcsCampaignPivot(filters);
      this.tableData = response.data;
    },
  },
};
</script>
