<template>
  <div>
    <h6 class="w-100 text-left my-1">
      {{ $t(title) }}
      <small v-if="suggestions.length == max" class="float-right">{{
        $t("Limit reached")
      }}</small>
    </h6>

    <div
      ref="actions"
      class="my-1 d-flex w-100 justify-content-start suggestions"
      v-if="suggestions.length < max && isEditable"
    >
      <div>
        <el-button
          :class="[component_type == cta.component_name ? 'is-active' : '']"
          v-for="cta in SUGGESTIONS_TYPE"
          :key="cta.name"
          round
          size="mini"
          :icon="`${cta.icon} pr-2`"
          @click="changeComponentToFlow(cta.component_name)"
          :disabled="suggestions.length === max && !editing_prop"
        >
          {{ $t(cta.name) }}
        </el-button>
      </div>
    </div>

    <component
      :is="component_type"
      :editing_prop="suggestion_to_edit"
      @submit="newSuggestion($event)"
      @edit="editExisting($event)"
      class="animated fadeIn"
    ></component>

    <el-row class="bg-light border animated fadeIn">
      <div class="col-12">
        <!-- Tabla de sugerencias -->
        <el-table class="w-100" :data="suggestions">
          <el-table-column
            :label="$t('Type')"
            :width="fitColumnToContent('action', 'share location', 60)"
            class-name="text-left"
          >
            <template slot-scope="scope" class="text-left">
              <i :class="getSuggestionTypeOf(scope.row).icon" class="pr-2"></i
              >{{ getSuggestionTypeOf(scope.row).name }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Visible tag')" class-name="text-left">
            <template slot-scope="scope" class="text-left">
              <span class="chip text-truncate">{{
                scope.row[Object.keys(scope.row)[0]].text
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="80" class-name="text-right position-relative">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="info"
                class="py-1 px-1"
                circle
                @click="editSuggestion(scope)"
                icon="el-icon-edit-outline"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column width="80" class-name="text-right position-relative">
            <template slot-scope="scope">
              <el-button
                v-if="isEditable"
                size="mini"
                type="danger"
                class="py-1 px-1"
                circle
                @click="removeSuggestion(scope.$index)"
                icon="el-icon-delete"
              ></el-button>
            </template>

            <template slot="header" slot-scope="scope">
              <span class="badge badge-outline salmon float-right">{{
                `${suggestions.length} / ${max}`
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
  </div>
</template>

<script>
import { Input, Button, Row, Table, TableColumn } from "element-ui";
import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";
import { MessageBox } from "element-ui";

import OpenURL from "src/app-components/rcs/suggestions-forms/OpenURL";
import Dial from "src/app-components/rcs/suggestions-forms/Dial";
import ViewLocation from "src/app-components/rcs/suggestions-forms/ViewLocation";
import ClickSMS from "src/app-components/rcs/suggestions-forms/ClickSMS";
import CalendarEvent from "src/app-components/rcs/suggestions-forms/CalendarEvent";
import ShareLocation from "src/app-components/rcs/suggestions-forms/ShareLocation";
import Reply from "src/app-components/rcs/suggestions-forms/Reply";

const uniqid = require("uniqid");

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [Row.name]: Row,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    OpenURL,
    Dial,
    ViewLocation,
    ClickSMS,
    CalendarEvent,
    ShareLocation,
    Reply,
  },

  props: {
    title: {
      type: String,
      default: "Suggestions",
    },

    value: {
      type: Array,
      default: () => [],
    },

    max: {
      type: Number,
      default: 4,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [FitColumnToContentMixin({ tableDataProp: "suggestions" })],

  data() {
    const SUGGESTIONS_TYPE = [
      { property: "reply", name: "Reply", icon: "fa fa-reply", component_name: "Reply" },
      {
        property: "openUrlAction",
        name: "Open URL",
        icon: "fa fa-chrome",
        component_name: "OpenURL",
      },
      {
        property: "dialAction",
        name: "Dial",
        icon: "fa fa-phone",
        component_name: "Dial",
      },
      {
        property: "createCalendarEventAction",
        name: "Calendar Event",
        icon: "fa fa-calendar",
        component_name: "CalendarEvent",
      },
      {
        property: "viewLocationAction",
        name: "View location",
        icon: "fa fa-map",
        component_name: "ViewLocation",
      },
      {
        property: "shareLocationAction",
        name: "Share location",
        icon: "fa fa-map-marker",
        component_name: "ShareLocation",
      },
    ];
    return {
      SUGGESTIONS_TYPE,
      component_type: "",
      component_id: null,
      suggestion_to_edit: null,
      indexToEdit: null,
    };
  },

  computed: {
    suggestions() {
      return this.value;
    },
  },

  methods: {
    attachPostback(suggestion) {
      const key = Object.keys(suggestion)[0];
      suggestion[
        key
      ].postbackData = `${this.component_type.toLowerCase()}_${uniqid()}_{CREATIVE_ID}_{CAMPAIGN_ID}`;
      return suggestion;
    },
    changeComponentToFlow(name) {
      this.suggestion_to_edit = null;
      this.indexToEdit = null;
      if (this.component_type === name) {
        this.component_type = "";
        return;
      }
      this.changeComponentTo(name);
    },
    changeComponentTo(name) {
      this.component_type = this.component_type == name ? null : name;
    },
    newSuggestion(suggestion) {
      if (this.suggestions.length < this.max) {
        this.$emit("input", this.attachPostback(suggestion));
      }
    },
    editExisting(suggestion) {
      this.$emit("edit", {
        suggestion: this.attachPostback(suggestion),
        index: this.indexToEdit,
      });
      this.suggestion_to_edit = null;
    },
    removeSuggestion(index) {
      if (index > -1) {
        MessageBox.confirm(
          this.$t("This will delete the suggestion. Continue ?"),
          this.$t("Warning"),
          {
            confirmButtonText: this.$t("Confirm"),
            cancelButtonText: this.$t("Cancel"),
            type: "warning",
          }
        )
          .then(() => {
            this.$emit("remove", index);
          })
          .catch(() => null);
      }
    },
    editSuggestion({ row, $index }) {
      const component_name = this.getSuggestionTypeOf(row).component_name;
      this.suggestion_to_edit = { ...this.value[$index] };
      this.indexToEdit = $index;
      this.changeComponentTo(component_name);
    },
    getSuggestionTypeOf(suggestion) {
      const KEY = Object.keys(suggestion)[0];
      if (KEY == "reply") {
        return this.SUGGESTIONS_TYPE.find((element) => element.property == KEY);
      }
      return this.SUGGESTIONS_TYPE.find((element) => element.property in suggestion[KEY]);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(th),
:deep(td) {
  padding: 5px !important;
  height: 34px !important;
}
:deep(.el-table .cell) {
  line-height: 26px;
}
:deep(.el-table__empty-block) {
  min-height: 0;
  .el-table__empty-text {
    line-height: 34px;
  }
}
.chip {
  padding: 5px 15px;
  background: #fff;
  color: #606266;
  border: 1px solid #d7d7d7;
  border-radius: 1rem;
  font-weight: 600;
}
.suggestions {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 5px;
  position: relative;

  .btn-scroll {
    position: absolute;
    display: none;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #c6c6c691;
    background-color: #ebeff2;
  }

  background-image: 
	
    /* Shadows */ linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    /* Shadow covers */
      linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

:deep(.el-button.is-active) {
  background-color: #ecf5ff;
  position: relative;
  &::after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f0d7";
    color: var(--info);
    position: absolute;
    bottom: -10px;
    left: 47%;
  }
}

:deep(.form-group) {
  margin: 0;
}
</style>
