<script>
import { cloneDeep } from "lodash";
import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
import api from "src/api";

export default {
  name: "scenario-call-to-action-elements",
  extends: ScenarioRequiredElements,

  props: {
    width: {
      type: String,
      default: "col-md-6",
    },
  },

  computed: {
    schemas() {
      return this.schema;
    },
  },

  methods: {
    async getSchema() {
      const scenarioType = await api.getScenarioType(this.scenario);
      const variables = scenarioType.data.scenario.variables;
      this.schema = variables.filter(
        (schema) =>
          schema.is_call_to_action === true || schema.variable_group_id === 4
      );
      /**
       *  ToDO, move to API
       */
      this.schema.map(
        (schema) =>
          (schema.type.web_component =
            schema.type.id === 6 ? "TextBrowser" : schema.type.web_component)
      );
    },

    getValue(index, id, field) {
      if (this.value.variables) {
        let value = null;
        this.value.variables.forEach(variable => {
          if(variable.variable) {
            if(variable.variable.name === "rcs-agent") {
              value = parseInt(variable.value);
            }else if(variable.variable.name === field.name) {
              value = variable.value;
            }
          }
        });
        return value;
      }

      return this.value.value ? this.value.value : "";
    },

    updateForm(id, value, index) {
      let newObj = cloneDeep(this.local);
      let countEdit = 0;
      newObj.variables.forEach(variable => {
        if(variable.variable) {
          if(variable.variable.name === 'rcs-agent' || variable.variable.name === id.name) {
            variable.value = value;
            countEdit++;
          }
        }
      });

      if(countEdit === 0) {
        let variable_id = id.id;
        if(id.final_variables.length) {
          if(id.final_variables[0].variable_id) {
            variable_id = id.final_variables[0].variable_id;
          }
        }
        newObj.variables.push({
          value,
          variable: id,
          variable_id,
        })
      }

      this.$emit("input", newObj);
    },
  },
};
</script>
