<template>
  <div>
    <div class="row">
      <div class="col-sm-6 col-xl-3">
        <circle-chart-card :percentage="parseFloat(dashboardData.circleStats.acceptance.percentage.toFixed(2))"
                           :title="$t(dashboardData.circleStats.acceptance.title)"
                           :description="`${$t('Today')}: ${dashboardData.circleStats.acceptance.dailyValue} | ${$t('Month')}: ${dashboardData.circleStats.acceptance.monthlyValue}`"
                           :color="dashboardData.circleStats.acceptance.color"
                           v-if="dashboardData.circleStats.acceptance.percentage !== null">
          <template slot="footer">
            <hr>
            <div class="stats">
              <i :class="dashboardData.circleStats.acceptance.footerIcon"></i> {{ this.$t(dashboardData.circleStats.acceptance.footerText) }}
            </div>
          </template>
        </circle-chart-card>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="col-12">
          <stats-doughnut-card :type="dashboardData.doughnutStats.revenue.type"
                               :icon="dashboardData.doughnutStats.revenue.icon"
                               :small-title="$t(dashboardData.doughnutStats.revenue.title)"
                               :percentage="dashboardData.doughnutStats.revenue.percentage ? parseFloat(dashboardData.doughnutStats.revenue.percentage.toFixed(2)) : 0"
                               :subtitleToday="dashboardData.doughnutStats.revenue.dailyValue"
                               :subtitleMonth="dashboardData.doughnutStats.revenue.monthlyValue">
            <div class="stats" slot="footer">
              <i :class="dashboardData.doughnutStats.revenue.footerIcon"></i>
              {{this.$t(dashboardData.doughnutStats.revenue.footerText)}}
            </div>
          </stats-doughnut-card>
        </div>
        <div v-for="stats in dashboardData.stats" class="col-12">
          <stats-card :type="stats.type"
                      :icon="stats.icon"
                      :small-title="$t(stats.title)"
                      :title="stats.value | formatNumber">
            <div class="stats" slot="footer">
              <i :class="stats.footerIcon"></i>
              {{ $t(stats.footerText) }}
              <span v-if="stats.footerLink"> | <a :href="stats.footerHref" :class="`text-${stats.footerType}`">{{ $t(stats.footerLink) }}</a></span>
            </div>
          </stats-card>
        </div>
      </div>
      <div v-for="list in dashboardData.lists" class="col-sm-6 col-xl-3">
        <card-list v-model="list.value"
                   type="primary"
                   icon="nc-icon nc-diamond"
                   :small-title="$t(list.smallTitle)"
                   :title="$t(list.title)"
                   :formatters="list.formatters"
                   :column-titles="[$t('Template'), $t('AR%')]"/>
      </div>
      <div class="col-sm-6 col-xl-3">
        <circle-chart-card :percentage="parseFloat(dashboardData.circleStats.deliveries.percentage.toFixed(2))"
                           :title="$t(dashboardData.circleStats.deliveries.title)"
                           :description="`${$t('Today')}: ${dashboardData.circleStats.deliveries.dailyValue} | ${$t('Month')}: ${dashboardData.circleStats.deliveries.monthlyValue}`"
                           :color="dashboardData.circleStats.deliveries.color"
                           v-if="dashboardData.circleStats.deliveries.percentage !== null">
          <template slot="footer">
            <hr>
            <div class="stats">
              <i :class="dashboardData.circleStats.deliveries.footerIcon"></i> {{ this.$t(dashboardData.circleStats.deliveries.footerText) }}
            </div>
          </template>
        </circle-chart-card>
      </div>
    </div>
    <LineChart v-model="dashboardData.charts.lineChart" :type.sync="dashboardData.charts.lineChart.type" />
  </div>
</template>
<script>
  import _ from "lodash";
  import Vue from 'vue';
  import { cloneDeep } from "lodash";
  import moment from 'moment'
  import { mapMutations } from "vuex";
  import api from "src/api";
  import CircleChartCard from "src/custom-components/UIComponents/Cards/CircleChartCard.vue";
  import StatsDoughnutCard from "src/custom-components/UIComponents/Cards/StatsDoughnutCard.vue";
  import { StatsCard, ChartCard, Badge } from "src/components/UIComponents";
  import Loading from "src/components/Dashboard/Layout/LoadingMainPanel.vue";
  import CardList from "src/app-components/dashboard/CardList";
  import LineChart from "src/app-components/dashboard/LineChart";

  export default {
    components: {
      LineChart,
      CardList,
      StatsCard,
      ChartCard,
      CircleChartCard,
      StatsDoughnutCard,
      Badge
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      toCamelCase(action) {
        const cc = _.camelCase(action);

        return cc.charAt(0).toUpperCase() + cc.slice(1);
      },
    },

    computed: {
      dashboardData() {
        const dashboard = this.dashboard;

        Object.keys(this.dashboard).map(value =>
          Object.keys(this.dashboard[value]).map(element => // Quita los elementos que no se deben mostrar en el dashboard
            this.$can(this.toCamelCase(this.dashboard[value][element].can), 'Dashboard') || delete dashboard[value][element]));

        return dashboard;
      },

      totalByMonth() {
        return this.dashboard.charts.lineChart.datasets.map(chart => chart.data.reduce((a, b) => a + b))
      }
    },

    async created() {
      this.setTopNavbarTitle("Dashboard");

      Object.keys(this.dashboardData.circleStats).map(async circle => {
        const {today, month, _count} = (await api.getDashboard(this.dashboard.circleStats[circle].can)).data;
        this.dashboard.circleStats[circle].dailyValue = Vue.filter('formatNumber')(today._count) || 0;
        this.dashboard.circleStats[circle].monthlyValue = Vue.filter('formatNumber')(month._count) || 0;
        this.dashboard.circleStats[circle].percentage = _count;
      });

      Object.keys(this.dashboardData.doughnutStats).map(async doughnut => {
        const {today, month, _count} = (await api.getDashboard(this.dashboard.doughnutStats[doughnut].can)).data;
        this.dashboard.doughnutStats[doughnut].dailyValue = today._count || 0;
        this.dashboard.doughnutStats[doughnut].monthlyValue = month._count || 0;
        this.dashboard.doughnutStats[doughnut].percentage = _count;
      });

      Object.keys(this.dashboardData.stats).map(async stat => this.dashboard.stats[stat].value = ((await api.getDashboard(this.dashboard.stats[stat].can)).data._count || 0).toString());

      Object.keys(this.dashboardData.lists).map(async list => this.dashboard.lists[list].value = (await api.getDashboard(this.dashboard.lists[list].can)).data);
    },

    data() {
      return {
        dashboard: {
          charts: {
            lineChart: {
              can: 'live',
              selected: 'acceptance',
              type: 'Graphic',
              chartReady: false,
              labels: [...Array(new Date().getDate()).keys()].map(day => day + 1),
              datasets: [{
                label: 'This Month',
                backgroundColor: 'transparent',
                borderColor: '#6bd098',
                data: [0]
              }, {
                label: 'Last Month',
                backgroundColor: 'transparent',
                borderColor: '#ef816f',
                data: [0]
              }],
              options: [{
                id: 'acceptance',
                name: 'Acceptances'
              },{
                id: 'delivery',
                name: 'Deliveries'
              },{
                id: 'revenue',
                name: 'Revenue'
              },{
                id: 'sent',
                name: 'Sent'
              },{
                id: 'acceptance-rate',
                name: '% Acceptance',
                isPercent: true,
              },{
                id: 'delivery-rate',
                name: '% Delivery',
                isPercent: true,
              },
              ],
              extraOptions: {
                tooltips: {
                  enabled: true
                },
                scales: {
                  yAxes: [{
                    ticks: {
                      fontColor: "#9f9f9f",
                      beginAtZero: false,
                      maxTicksLimit: 5,
                    },
                    gridLines: {
                      drawBorder: false,
                      borderDash: [8, 5],
                      zeroLineColor: "transparent",
                      color: '#9f9f9f'
                    }

                  }],
                  xAxes: [{
                    barPercentage: 1.6,
                    gridLines: {
                      drawBorder: false,
                      borderDash: [8, 5],
                      color: '#9f9f9f',
                      zeroLineColor: "transparent"
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9f9f9f"
                    }
                  }]
                }
              }
            }
          },
          circleStats: {
            acceptance: {
              can: 'acceptances',
              color: '#6bd098',
              type: "success",
              icon: "nc-icon nc-check-2",
              isCurrency: false,
              title: "Acceptances",
              dailyValue: 'Calculating...',
              monthlyValue: 'Calculating...',
              percentage: null,
              footerText: "Monthly Acceptance Rate",
              footerIcon: "fa fa-circle text-success",
              footerLink: null
            },
            deliveries: {
              can: 'deliveries',
              color: '#ef8157',
              type: "danger",
              icon: "nc-icon nc-send",
              isCurrency: true,
              title: "Deliveries",
              dailyValue: 'Calculating...',
              monthlyValue: 'Calculating...',
              percentage: null,
              footerText: "Monthly Delivery Rate",
              footerIcon: "fa fa-circle text-danger",
              footerLink: null
            },
          },
          doughnutStats: {
            revenue: {
              can: 'revenue',
              type: "success",
              icon: "nc-icon nc-money-coins",
              isCurrency: true,
              title: "Revenue",
              dailyValue: 'Calculating...',
              monthlyValue: 'Calculating...',
              percentage: null,
              footerText: "Now",
              footerIcon: "nc-icon nc-refresh-69",
              footerLink: null
            },
          },
          lists: {
            productTop: {
              can: 'product-top',
              value: null,
              smallTitle: 'Templates',
              title: 'Top 5',
              formatters: {
                _count: {
                  function: Vue.filter("formatPercentage"),
                  params: [ 2 ]
                }
              }
            },
          },
          stats: {
            live: {
              can: 'live',
              type: "warning",
              icon: "nc-icon nc-spaceship",
              isCurrency: false,
              title: "Live Campaigns",
              value: null,
              footerText: 'Now',
              footerIcon: "nc-icon nc-refresh-69",
              footerLink: 'Go to Live Campaign',
              footerHref: '/#/dashboard/live',
              footerType: 'warning',
            },
            target: {
              can: 'target',
              type: "primary",
              icon: "icon icon-network",
              isCurrency: false,
              title: "Today Target",
              value: null,
              footerText: null,
              footerIcon: "fa fa-calendar",
              footerLink: 'Go to Planning',
              footerHref: '/#/campaigns/planning',
              footerType: 'primary',
            },
            capacity: {
              can: 'capacity',
              type: "info",
              icon: "icon icon-check-double",
              isCurrency: false,
              title: "Acceptances",
              value: null,
              footerText: "Now",
              footerIcon: "nc-icon nc-refresh-69",
              footerLink: null,
              footerHref: null,
              footerType: null,
            },
            programmed: {
              can: 'programmed-day',
              type: "success",
              icon: "nc-icon nc-money-coins",
              isCurrency: true,
              title: "Revenue",
              value: null,
              footerText: "Now",
              footerIcon: "nc-icon nc-refresh-69",
              footerLink: null,
              footerHref: null,
              footerType: null,
            },
          },
        }
      };
    }
  };
</script>
<style scoped>
  :deep(.el-radio-button__inner) {
    color: #51cbce;
    background: transparent;
    border: 2px solid #51cbce!important;
  }

  :deep(.el-radio-group .el-radio-button + .el-radio-button) {
    margin-left: -2px!important;
  }

  :deep(.el-radio-button__inner:hover) {
    color: #FFF;
    background: #51cbce;
  }

  :deep(.el-radio-button__orig-radio:checked+.el-radio-button__inner) {
    background: #51cbce;
    box-shadow: -1px 0 0 0 #51cbce;
  }
</style>
