<template>
  <Form @submit="onSave">
      <p>{{ $t("Define a maximum of effective deliveries per user per period of time") }}</p>
      <div class="row">
        <div class="col-12 mb-2">
          <AntiSpamType
            @change="form.anti_spam_type = $event"
            :value="form.anti_spam_type"
            :period="form.per_days"
          />
          <!-- <small>
            <span class="badge tiny default badge-outline mr-1">{{$t('Note')}}</span>
            <span v-if="form.anti_spam_type == 'MONTHLY'" class="text-muted">{{`${$t('Deliveries in')} ${$t(CURRENTMONTH)}`}}</span>
            <span v-else class="text-muted">{{`${$t('Deliveries since the last')} ${form.per_days} ${$t('days')}.`}}</span>
          </small> -->
        </div>
        <div class="col-6 col-md-12 col-xl-6">
          <EffectiveDeliveries v-model.number="form.amount_of_impressions" />
        </div>
        <div class="col-6 col-md-12 col-xl-6">
          <TimeFrame v-model.number="form.per_days"/>
          <!-- <TimeFrame v-model.number="form.per_days" :isDisabled="form.anti_spam_type == 'MONTHLY'"/> -->
        </div>
        <div class="col-12">
          <ExceptEvents v-model="form.exclude_events" />
        </div>
      </div>
  </Form>
</template>

<script>
  import TimeFrame from "src/app-components/form-elements/TimeFrame"
  import EffectiveDeliveries from "src/app-components/form-elements/EffectiveDeliveries"
  import ExceptEvents from "src/app-components/form-elements/ExceptEvents"
  import AntiSpamType from "src/app-components/form-elements/AntiSpamType"
  import Form from "src/app-components/Form"

  import api from 'src/api'
  import { updatableForm } from 'src/app-components/FormMixin'

  // import moment from "moment";
  

  export default {
    components: {
      EffectiveDeliveries,
      TimeFrame,
      ExceptEvents,
      AntiSpamType,
      Form
    },
    mixins: [
      updatableForm
    ],
    provide() {
      return {
          parentValidator: this.$validator,
          getError: this.getError
      };
    },
    data () {
      // const CURRENTMONTH = moment().format("MMMM");

      return {
        // CURRENTMONTH,
        form: {
          per_days: 0,
          amount_of_impressions: 0,
          anti_spam_type: "MONTHLY",
          exclude_events: false,
        },
      }
    },
    methods: {
      async onSave () {
        await api.updateAccount(this.$store.getters.appUser.selected_account_id, {
          per_days: this.form.per_days,
          amount_of_impressions: this.form.amount_of_impressions,
          exclude_events: this.form.exclude_events ? 1 : 0,
          anti_spam_type: this.form.anti_spam_type
        });
        this.$notify({
          message: this.$t("Anti-Spam updated succesfully!"),
          type: 'success'
        });
      },
      getError(fieldName) {
          return this.errors.first(fieldName);
      },
    },
    watch: {
      form: {
        handler (nform, oform) {
          if (isNaN(nform.per_days) || nform.per_days == "") {
            this.form.per_days = 0;
            this.$validator.validate('time-frame')
          }
          if (isNaN(nform.amount_of_impressions) || nform.amount_of_impressions == "") {
            this.form.amount_of_impressions = 0
            this.$validator.validate('effective-deliveries')
          } 
        },
        deep: true
      }
    },
  };
</script>
<style>
</style>