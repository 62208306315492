<template>
    <div>
      <MsisdnField
          v-model="filters.msisdn"
      />
    </div>
</template>

<script>
  import MsisdnField from "src/app-components/form-elements/Msisdn";
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";

  export default {
    components: {
      MsisdnField
    },

    mixins: [
      FilterPanelMixin({ msisdn:"" })
    ],

    methods: {
      prepareFilters (filters) {
        return {
          msisdn: filters.msisdn
        };
      },
    },
  };
</script>
<style scoped>
</style>