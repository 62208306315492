import { cloneDeep, forEach, merge, mergeWith } from "lodash";
import { flat } from "./../util.js";

/**
 * This mixins gives you default methods in order to work with forms.
 *
 */
const defaultMixin = {
	methods: {
		mergeForm($event) {
      const form = cloneDeep(this.form);
      const data = cloneDeep($event);
      
      if(data.variables) {
        data.variables.forEach(v => {
          let index = form.variables.findIndex(variable => variable.variable_id == v.variable_id);
          
          index > -1 
          ? form.variables[index].value = v.value 
          : form.variables.push(v);
        });
        
        delete data.variables;
      }
      
      return merge(form, data);
		},

		getError(fieldName) {
			return this.errors.first(fieldName);
		},
	},
};

const updatableForm = {
	props: {
		entity: {
			type: [Object, Array],
			required: false,
		},
	},

	watch: {
		form: {
			deep: true,
			handler(val, oval) {
				this.updatedModel(val);
			},
		},

		entity: {
			deep: true,
			handler(nv, ov) {
				this.patchModel(nv);
				//console.log("ENTITY UPDATED, OV " + JSON.stringify(ov, null, 2) + " NV: "  + JSON.stringify(nv, null, 2));
			},
		},
	},
	methods: {
		patchModel(data) {
			if (typeof data != "undefined") {
				if (data !== null) {
					/*if(!Array.isArray(this.form) && !Array.isArray(data)){
            mergeWith(this.form, flat(data), (objValue, srcValue) => {
              if (Array.isArray(objValue)) {
                  return srcValue;
              }
            });
          } else {*/
					this.form = cloneDeep(data);
					//}
					// this.form = merge(this.form, cloneDeep(data));
					this.afterModelPatched();
					this.$emit("modelPatched", this.form);
				}
			}
		},

		afterModelPatched: function() {},

		updatedModel: function(data) {
			//console.log("NOTIFYNG UPDATE MODEL WITH DATA: " + JSON.stringify(data))
			this.$emit("modelUpdated", cloneDeep(data));
		},
	},
};

const updatableInput = {
	watch: {
		inputVal(val) {
			this.$emit("input", val);
		},
	},
};

export { updatableInput, updatableForm, defaultMixin };
