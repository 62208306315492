<script>
import api from "src/api";
import Report from "./Report";

export default {
    name: "Live",

    extends: Report,

    created() {
        this.tableCols = [
            { label: "Campaign", hasLabel: true,  minWidth: 250 },
            { prop: "Audience", label: "Filtered Audience", formatter: this.$options.filters.formatterNumber, animated: true },
            { prop: "Sent_Ping", label: "Sent Ping", animated: true },
            { prop: "Actives", label: "Actives", animated: true },
            { prop: "Actives_Rate", label: "%Active", formatter: this.$options.filters.formatPercentage, animated: true },
            { prop: "Sent", label: "Sent", animated: true },
            { prop: "Delivered", label: "Delivered", animated: true },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage },
            { prop: "Acceptances", label: "Acceptances", minWidth: 120, animated: true },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage },
            { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage },
            { prop: "status", label: "Status" },
            { prop: "Blacklisted", label: "Blacklisted", formatter: this.formatterNumber,  minWidth: 120 },
            { prop: "Revenue", label: "Revenue", formatter: this.formatterNumber },
            { prop: "outrate", label: "Outbound rate (mt/s)", animated: false },
        ];
        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
        ];
        this.emitLoading = false;
    },

    computed: {
        filteredData() {
            if (this.search !== null && this.search != "") {
                return this.tableData.filter((data) => {
                if (data[this.filterProp()] == null) return true;
                return data[this.filterProp()]
                    .toLowerCase()
                    .includes(this.search.toLowerCase());
                });
            }

            return this.tableData;
        },
    },
    
    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "campaign_name ASC",
            ]
        },

        /**
         * @Override
         */
        tableRowClassName({row, rowIndex}) {
            if(!row.Campaign) {
                return 'success-row';
            }
            
            return '';
        },

        /**
         * @Override
         */
        async getData(query) {
            const response = await api.getCampaignPivotLive();
            this.tableData = response.data;
        },
    },

    mounted(){
        this.timer = setInterval(this.getDataChild, 5000);
        this.totalsFromFront = true;
    },

    beforeDestroy(){
        clearInterval(this.timer);
    }
    
}
</script>