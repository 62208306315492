<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-8">
              <el-radio-group v-model="value.selected" size="medium" @handleChange="fillChart">
                <el-radio-button v-for="(option, key) in value.options"
                                 :key="key"
                                 :label="option.id">
                  {{ $t(option.name) }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="col-12 col-md-4">
              <el-radio-group :value="value.type" @input="(val) => $emit('update:type', val)" size="medium" class="pull-right">
                <el-radio-button label="Graphic">{{ $t('Graphic') }}</el-radio-button>
                <el-radio-button label="Total">{{ $t('Total') }}</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4" v-if="isChartReady">
          <div v-if="value.type === 'Graphic'">
            <line-chart :labels="value.labels"
                        :datasets="value.datasets"
                        :extra-options="value.extraOptions"
                        :height="75"/>
            <div class="row mt-3 mb-3">
              <div class="col-12">
                <div class="legend">
                  <i class="fa fa-circle text-success"></i> {{ $t('This month') }}
                  <i class="fa fa-circle text-danger"></i> {{ $t('Last month') }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-3">
              </div>
              <div class="col-6 text-center">
                <h5>From day 1 to day {{ new Date().getDate() }}</h5>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-lg-3"></div>
              <div class="col-12 col-sm-6 col-lg-3 text-center" v-for="(i, k) in 2" :key="i">
                <div class="card card-stats" style="border: 1px solid #bbbbbb;">
                  <div class="card-body">
                    <div class="row" style="height: 150px; top: 50px;position: relative;">
                      <div class="col-12">
                        <div class="numbers text-center">
                          <p class="card-title">{{ twoDecimalPlacesIfNeeded(totalByMonth[k] || 0) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    {{ value.datasets[k].label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2" v-else>
          <div class="alert alert-primary">Fetching data...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { RadioGroup, RadioButton, Radio } from "element-ui";
  import api from "src/api";
  import LineChart from 'src/components/UIComponents/Charts/LineChart';

  export default {
    props: {
      value: Object,
    },

    components: {
      LineChart,
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
      [RadioButton.name]: RadioButton,
    },

    created() {
      this.fillChart();
    },

    computed: {
      isChartReady() {
        return this.value.chartReady;
      },
      totalByMonth() {
        if (this.selected.id == "acceptance-rate") {
          return [ this.thisMonthAcceptanceRate, this.lastMonthAcceptanceRate ];
        } else if (this.selected.id == "delivery-rate") {
          return [ this.thisMonthDeliveryRate, this.lastMonthDeliveryRate ];
        }
        return this.value.datasets.map(chart => {
          return chart.data.reduce((a, b) => a + b);
        })
      },
      selected () {
        return this.value.options.find(o => o.id == this.value.selected)
      }
    },

    data () {
      return {
        lastMonthAcceptanceRate: 0,
        thisMonthAcceptanceRate: 0,
        lastMonthDeliveryRate: 0,
        thisMonthDeliveryRate: 0,
      }
    },

    methods: {
      async fillChart() {
        this.value.chartReady = false;
        const selectedKpi = this.value.selected;
        let data = this.$store.getters.dashboard.charts.filter(chart => chart.id === selectedKpi).shift();

        if (! data) {
          data = {
            id: selectedKpi,
            thisMonth: [],
            lastMonth: []
          };
          const stats = await api.getDashboard('vs-last-month/' + selectedKpi);

          if (stats.data.thisMonthAcceptanceRate) this.thisMonthAcceptanceRate = stats.data.thisMonthAcceptanceRate;
          if (stats.data.lastMonthAcceptanceRate) this.lastMonthAcceptanceRate = stats.data.lastMonthAcceptanceRate;
          if (stats.data.thisMonthDeliveryRate) this.thisMonthDeliveryRate = stats.data.thisMonthDeliveryRate;
          if (stats.data.lastMonthDeliveryRate) this.lastMonthDeliveryRate = stats.data.lastMonthDeliveryRate;

          this.value.options.map(option => {
            if (option.id === selectedKpi) {
              Object.keys(stats.data.thisMonth).map(key => data.thisMonth.push(stats.data.thisMonth[key]));
              Object.keys(stats.data.lastMonth).map(key => data.lastMonth.push(stats.data.lastMonth[key]));
            }
          });

          this.$store.getters.dashboard.charts.push(data);
        }

        this.value.datasets[0].data = data.thisMonth;
        this.value.datasets[1].data = data.lastMonth;
        setTimeout(() => { // It doesn' t work without this!
          this.value.chartReady = true;
        }, 1)
      },
      twoDecimalPlacesIfNeeded (value) {
        const valueNo = Number(value);
        const valueFloor = Math.floor(valueNo);
        const valueCeil = Math.ceil(valueNo);
        let result;
        if (valueFloor < valueNo && valueNo < valueCeil) {
          result = valueNo.toFixed(2);
        } else {
          result = valueNo;
        }
        if (this.selected.isPercent) {
          result += "%";
        }
        return result;
      }
    }
  }
</script>