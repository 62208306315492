<template>
  <div
    class="row align-items-end my-2"
    :class="[errors.any(scope) ? 'col-with-errors' : '']"
  >
    <div class="col text-left">
      <label>{{ $t("Text") }}</label>
      <fg-input :error="errors.first('text', scope)">
        <el-input
          size="mini"
          v-model="reply.text"
          name="text"
          :data-vv-scope="scope"
          v-validate="'required'"
          :maxlength="25"
          show-word-limit
        ></el-input>
      </fg-input>
    </div>
    <!-- <div class="col text-left">
            <label>{{$t('Postback KW')}}</label>
            <el-input size="mini" v-model="a.postback_kw"></el-input>
        </div> -->
    <div style="display: none" class="col-auto text-left">
      <label>{{ $t("Connection") }}</label>
      <div class="w-100">
        <el-button-group>
          <el-button size="mini" type="success" plain>Escenario</el-button>
          <el-button size="mini" type="warning" plain>Conversacion</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="text-left col-auto">
      <el-button size="mini" type="primary" @click="submit">
        {{ $t("Confirm") }}
      </el-button>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { Input, Button, ButtonGroup } from "element-ui";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
  },

  data() {
    const scope = `reply-${uniqid()}`;
    return {
      scope,
      reply: {
        text: "",
        postbackData: "",
      },
    };
  },

  props: {
    editing_prop: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    if (this.editing_prop) {
      if (this.editing_prop.reply) {
        this.reply = this.editing_prop.reply;
      }
    } else {
      this.reply = {
        text: "",
        postbackData: "",
      };
    }
  },

  methods: {
    async submit() {
      if (await this.$validator.validate(`${this.scope}.*`)) {
        if (this.editing_prop) {
          this.$emit("edit", { reply: this.reply });
        } else {
          this.$emit("submit", { reply: this.reply });
        }
        this.clear();
      }
    },

    clear() {
      this.reply = {
        text: "",
        postbackData: "",
      };
      this.$validator.reset(this.scope);
    },
  },
};
</script>
