<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

// Diccionario campaign_type
const CTYPE_DICT = {
    "SINGLE": "ON DEMAND",
    "PERFORMANCE": "PROGRAMMATIC",
    "SECOND_ACTION": "SECOND ACTION",
};

export default {
    name: "Performance",

    extends: Report,

    data() {
      return {
        groupFields: null,
      };
    },

    created() {
        this.setTableCols();

        this.customTableKeyCols = [
            //{ prop: "Product", label: "Template" },
            { prop: "Template", label: "Template" },
            // { prop: "Type", label: "Type" }
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            // Not Lauch browser and not ping
            return scenario.scenario.sat_call_to_action_id != 1 && scenario.scenario.sat_call_to_action_id != null;
        });
    },

    watch: {
      tableData() {
        this.setTableCols();
      }
    },
    
    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Template";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "product_name ASC",
                "campaign_type ASC",
            ]
        },

        setTableCols() {
          this.tableCols = [
            { label: "Template", hasLabel: true, minWidth: 200 },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Revenue", label: "Revenue", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
          ];

          if(this.hasValidAcceptances()) {
            this.tableCols.splice(7, 0, { 
              prop: "Valid_Acceptances", 
              label: "Subscribed", 
              formatter: this.formatterNumber, 
              exportFormatter: Number, 
              excelFormat: excelFormatNumber 
            })
            this.tableCols.splice(8, 0, { 
              prop: "Subscription_rate", 
              label: "%SR", 
              formatter: this.formatterPercentage, 
              exportFormatter: Number, 
              excelFormat: excelFormatPercentage 
            })
          }
        },

        hasValidAcceptances() {
          let res = false;
          if(this.tableData) {
            this.tableData.forEach(value => {
              if(value.Valid_Acceptances) res = true;
            })
          }

          return res;
        },

        async getData(query) {
            const response = await api.getByTemplateReport(this.prepareFilter());
            this.groupFields = response.groupFields;
            let pkey = 1;
            this.tableData = response.data.map(row => ({
                Pkey: pkey++, //`${row.template_id || 'null'}-${row.Type || 'null'}`,
                ...row,
                Subscription_rate: row.Valid_Acceptances ? row.Valid_Acceptances / row.Delivered : 0
            })); 
            return;
        },

        prepareFilter(){
            const filter = {
                from: this.filters["campaign_start_datetime >="],
                to: this.filters["campaign_start_datetime <="],
            }

            if(this.dimensions.length > 0){
                filter.groupby = this.dimensions.map(dimension => dimension.groupingProp).join(',')
            }

            return filter;
        },
    }
}
</script>