<script>
import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
import api from "src/api";
import { cloneDeep } from "lodash";

export default {
  name: "scenario-optional-elements",
  extends: ScenarioRequiredElements,

  props: {
    width: {
      type: String,
      default: "col-md-3",
    },
  },

  computed: {
    schemas() {
      return this.schema;
    },
  },

  methods: {
    async getSchema() {
      const scenarioType = await api.getScenarioType(this.scenario);
      /* const scenarioType = await api.getScenarioType(this.scenario, {
        variable_group: "bookmark-group",
        remove_invisible: 0,
      }); */
      const noIncludes = [1, 114]
      const variables = scenarioType.data.scenario.variables;

      this.schema = variables.filter(schema => !schema.is_call_to_action && !schema.required && schema.creative_field === null && !noIncludes.includes(schema.id));
    },

    getValue(index, id, field) {
      if (this.value.variables) {
        let value = null;
        this.value.variables.forEach(variable => {
          if(variable.variable) {
            if(variable.variable.name === field.name) {
              value = variable.value;
            }
          }
        });
        return value;
      }

      return this.value.value ? this.value.value : "";
    },

    updateForm(id, value, index) {
      let newObj = cloneDeep(this.local);
      let countEdit = 0;
      newObj.variables.forEach(variable => {
        if(variable.variable) {
          if(variable.variable.name === id.name) {
            variable.value = value;
            countEdit++;
          }
        }
      });

      if(countEdit === 0) {
        let variable_id = id.id;
        if(id.final_variables.length) {
          if(id.final_variables[0].variable_id) {
            variable_id = id.final_variables[0].variable_id;
          }
        }
        newObj.variables.push({
          value,
          variable: id,
          variable_id,
        })
      }

      this.$emit("input", newObj);
    },
  },
};
</script>
