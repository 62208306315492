<template>
  <div>
    <div>
      <span class="label">Variable name</span>
      <el-select
        v-model="storageData.variable"
        placeholder="Select variable"
        clearable
        @change="updateData"
        class="w-100"
        size="small"
        no-data-text="No variables created"
        :class="validate && storageData.variable === '' ? 'invalid' : ''"
      >
        <el-option
          v-for="variable in dataVariables"
          :key="variable.name"
          :label="$t(variable.name)"
          :value="variable.name"
        >
        </el-option>
      </el-select>
    </div>

    <div class="my-2" v-if="storageData.variable !== ''">
      <span class="label">Validation</span>
      <el-select
        v-model="storageData.validation"
        placeholder="Select a validation"
        :clearable="typeVariableSelected !== 'number'"
        @change="updateData"
        class="w-100"
        size="small"
      >
        <el-option
          v-for="validation in validations"
          :key="validation.value"
          :label="$t(validation.label)"
          :value="validation.value"
        >
        </el-option>
      </el-select>
    </div>

    <div v-if="storageData.validation !== ''">
      <span class="label">Fallback message *</span>
      <input-actions
        :variables="variables"
        :value="storageData.fallback"
        :class="validate && storageData.fallback === '' ? 'invalid' : ''"
        @updateValue="updateValueFallback($event)"
      />
    </div>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import { Select, Option, Input, Autocomplete } from "element-ui";
  import InputActions from "src/app-components/chatbot/elements/InputActions";
  export default {
    name: "FormStorage",
    props: ["storage", "variables", "validate"],

    components: {
      [Autocomplete.name]: Autocomplete,
      [Select.name]: Select,
      [Option.name]: Option,
      [Input.name]: Input,
      "input-actions": InputActions,
    },

    data() {
      return {
        storageData: {
          variable: "",
          validation: "",
          fallback: "",
        },
      };
    },

    watch: {
      "storageData.variable"() {
        let validation = this.validations.find(
          (v) => v.value === this.storageData.validation
        );
        if (this.storageData.variable === "" || !validation) {
          this.storageData.validation = "";
          this.storageData.fallback = "";
        }

        this.storageData.validation =
          this.typeVariableSelected === "number" ? "number" : "string";
      },
    },

    mounted() {
      this.storageData = clone(this.storage);

      setTimeout(() => {
        this.$emit("updateValid", this.valid);
      }, 5);
    },

    methods: {
      updateValueFallback(message) {
        if (this.storageData) {
          this.storageData.fallback = message;
          this.updateData();
        }
      },

      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.storageData);
          this.$emit("updateValid", this.valid);
        }, 1);
      },
    },

    computed: {
      dataVariables() {
        return this.variables.filter((v) => v.editable);
      },

      typeVariableSelected() {
        if (this.storageData) {
          if (this.storageData.variable) {
            let item = this.variables.find(
              (v) => v.name === this.storageData.variable
            );

            if (item) return item.type;
          }
        }

        return null;
      },

      validations() {
        const validations = [
          {
            label: "String",
            value: "string",
            type: "string",
          },
          /* {
            label: "Email",
            value: "email",
            type: "string",
          }, */
          {
            label: "Number",
            value: "number",
            type: "number",
          },
          /* {
            label: "ID",
            value: "id",
            type: "number",
          },
          {
            label: "Phone",
            value: "phone",
            type: "number",
          }, */
        ];

        return this.typeVariableSelected
          ? validations.filter((v) => v.type === this.typeVariableSelected)
          : validations;
      },

      valid() {
        if (this.storageData.variable === "") return false;
        if (this.storageData.fallback === "") return false;

        return true;
      },
    },
  };
</script>
