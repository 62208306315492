<template>
  <div>
    <Filters
      v-if="showFilters"
      :initial-report="this.$route.params.initialReport"
      @filterUpdated="onFilterUpdated"
      @groupChanged="onGroupChanged"
      @reportChanged="onReportChanged"
      :show-group-by-select="showGroupBySelect"
    />

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title"></h4>
            <div class="row" style="width: 100%" v-if="selectedReport && selectedReport !== 'ConvertionsReport'">
              <div class="col-2 col-sm-2 col-md-2 col-lg-6"></div>

              <div
                class="col-6 col-sm-6 col-md-6 col-lg-4"
                style="top: 10px"
              >
                <fg-input
                  style="border-radius: 1rem"
                  v-model="search"
                  :placeholder="$t('type your text here...')"
                />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-2"
                style=""
              >
                <drop-down>
                  <p-button
                    slot="title"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    type="primary"
                    block
                    round
                  >
                    {{ $t("Export As") }}
                  </p-button>
                  <a class="dropdown-item" @click="exportToExcel" href="#">
                    <i class="fa fa-file-excel-o"></i> Excel
                  </a>
                </drop-down>
              </div>
            </div>
            <Spinner :display="loading" />
          </div>
          <div class="card-body">
            <!-- Tabla -->
            <component
              v-if="selectedReport"
              :is="selectedReport"
              :search="search"
              :campaignId="this.$route.params.campaignId"
              @allowedScenariosFilter="onAllowedScenariosFilter"
              @allowEventFilter="onAllowEventFilter"
              @loading-data="onLoadingData"
              @report-loaded="onReportLoaded"
              @set-up-filters="onSetUpFilters"
              :dimensions="dimensions"
              :filters="filters"
            ></component>

            <h5 class="text-center text-muted my-3 animated fadeInUp" v-else>
              <i class="fa fa-hand-o-up mr-2" aria-hidden="true"></i
              >{{ $t("Please, select report type and a date to continue.") }}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <Slide @closeMenu="filterOpen = false" :isOpen="filterOpen">
      <SideFilters
        @filterUpdated="onSideFilterUpdated"
        :allowEventFilter="allowEventFilter"
        :allowedScenariosFilter="allowedScenariosFilter"
      />
    </Slide>

    <fabFilter
      v-if="showFilters"
      icon="filter_alt"
      :position="0"
      @click="filterOpen = true"
      v-click-outside="clickOutside"
    />
  </div>
</template>

<script>
import FabFilter from "src/app-components/FabFilter";
import Filters from "src/app-components/PivotFilters";
import SideFilters from "src/app-components/PivotSideFilters";
import {
  Advertiser,
  Campaign,
  Creatives,
  Event,
  Live,
  Performance,
  Programmatic,
  Ping,
  ConsolidatedCampaign,
  SatMultimedia,
  TimBrasilByName,
  TimBrasilByCreatives,
  RcsCampaignReport,
  OptAgentReport,
  InteractionsReport,
  ConvertionsReport,
} from "src/app-components/reports-components";
import OtaCampaignReport from "src/app-components/reports-components/OtaCampaignReport";
import ProgrammaticFull from "src/app-components/reports-components/ProgrammaticFull";
import JsonExcel from "vue-json-excel";
import Slide from "src/custom-components/UIComponents/FilterSideBar";
import Spinner from "src/app-components/Spinner";
import moment from "moment";
import * as Export2Excel from "./Export2Excel";
import FilterMixin from "src/app-components/FilterMixin";
import _ from "lodash";

export default {
  components: {
    [Programmatic.name]: Programmatic,
    [ProgrammaticFull.name]: ProgrammaticFull,
    [Advertiser.name]: Advertiser,
    [RcsCampaignReport.name]: RcsCampaignReport,
    [OptAgentReport.name]: OptAgentReport,
    [Campaign.name]: Campaign,
    [Creatives.name]: Creatives,
    [Event.name]: Event,
    [Performance.name]: Performance,
    [Ping.name]: Ping,
    [Live.name]: Live,
    [SatMultimedia.name]: SatMultimedia,
    [ConsolidatedCampaign.name]: ConsolidatedCampaign,
    [TimBrasilByName.name]: TimBrasilByName,
    [TimBrasilByCreatives.name]: TimBrasilByCreatives,
    [InteractionsReport.name]: InteractionsReport,
    [ConvertionsReport.name]: ConvertionsReport,
    [OtaCampaignReport.name]: OtaCampaignReport,
    FabFilter,
    Filters,
    SideFilters,
    Spinner,
    Slide,
    "download-excel": JsonExcel,
  },

  mixins: [FilterMixin()],

  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },

    withReport: {
      type: String,
      default: null,
    },
  },

  data() {
    let filters;
    const campaignId = this.$route.params.firstParentId || this.$route.params.campaignId;
    if (campaignId) {
      filters = {
        OR: [
          { ["campaign_id ="]: campaignId },
          { ["campaign_first_parent_id ="]: campaignId },
        ],
      };
    } else {
      filters = {
        ["campaign_start_datetime >="]: moment
          .utc()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        ["campaign_start_datetime <="]: moment
          .utc()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
    }
    return {
      search: "",
      loading: false,
      allowedScenariosFilter: null,
      allowEventFilter: false,

      filters,
      dimensions: [],
      exportData: [],
      exportExcelFormats: [],
      selectedReport: this.withReport,
      selectedReportName: "",
      showGroupBySelect: true,
    };
  },

  computed: {
    excelFileName() {
      const date = moment().format("YYYYMMDD");
      const report = this.selectedReportName;
      const account = this.$store.getters.appUser.account.name;
      return `${date}_${report}_${account}`;
    },
    excelTitle() {
      return "Stats";
    },
  },

  methods: {
    clickOutside(e) {
      if (
        this.filterOpen &&
        e.path.filter((element) => element._prevClass == "bm-menu").length == 0
      ) {
        this.filterOpen = false;
      }
    },

    onAllowEventFilter($event) {
      this.allowEventFilter = $event;
    },

    onAllowedScenariosFilter($event) {
      //console.log('Catched allowed scenarios filter: ', $event);
      this.allowedScenariosFilter = $event;
    },

    async exportToExcel() {
      //const Export2Excel = await import('./Export2Excel')
      const tHeader = Object.keys(this.exportData[0]);
      const data = this.exportData.map((ed) => Object.values(ed));
      const fillCount = tHeader.length - this.exportExcelFormats.length;
      let formats = this.exportExcelFormats;
      if (fillCount > 0) {
        formats = [..._.range(fillCount).map((x) => null), ...formats];
      }
      Export2Excel.export_json_to_excel({
        header: tHeader,
        data,
        formats,
        filename: this.excelFileName,
        autoWidth: true,
        bookType: "xlsx",
      });
    },
    onLoadingData($event) {
      this.loading = $event;
    },

    onReportChanged($event) {
      this.allowEventFilter = false;
      this.showGroupBySelect = true;
      this.selectedReport = $event.component;
      //console.log("selected report: ", this.selectedReport);
      this.selectedReportName = $event.name;
    },

    onFilterUpdated($event) {
      this.filters = $event;
    },

    onSideFilterUpdated($event) {
      if (
        "Campaign.account_id IN" in $event &&
        $event["Campaign.account_id IN"].length > 0
      ) {
        this.$set(this.filters, "account_id IN", $event["Campaign.account_id IN"]);
      } else {
        this.$delete(this.filters, "account_id IN");
      }

      if ("Campaign.channel_id" in $event) {
        this.$set(this.filters, "channel_id", $event["Campaign.channel_id"]);
      } else {
        this.$set(this.filters, "channel_id", null);
      }

      if ("Campaign.account_scenario_type_id" in $event) {
        this.$set(
          this.filters,
          "scenario_id",
          $event["Campaign.account_scenario_type_id"]
        );
      } else {
        this.$set(this.filters, "scenario_id", null);
      }

      this.onFilterUpdated(this.filters);
    },

    onGroupChanged($event) {
      this.dimensions = $event;
    },

    onReportLoaded(data) {
      this.exportData = data.rows.map((row) => {
        return Object.keys(row).reduce((res, curr) => {
          const col =
            data.cols.find((c) => c.label == curr) ||
            data.keyCols.find((c) => c.label == curr);
          const formatter =
            col &&
            (col.exportFormatter ||
              (col.formatter && ((x) => col.formatter(null, null, x, null))));
          return {
            ...res,
            [curr]: formatter ? formatter(row[curr]) : row[curr],
          };
        }, {});
      });
      this.exportExcelFormats = data.cols.map((c) => c.excelFormat || null);
    },

    onSetUpFilters({ showGroupBySelect }) {
      this.showGroupBySelect = showGroupBySelect;
    },
  },
};
</script>

<style scoped>
.card {
  min-height: 150px;
}

:deep(.form-group > input) {
  border-radius: 1rem;
  height: 38px;
}
</style>
