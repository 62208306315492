<template>
  <div class="row">
    <div class="col-md-1" style="margin-top: 15px">
      <p-checkbox v-model="form.active"></p-checkbox>
    </div>

    <div class="col-md-2">
      <CSelect
        v-model="form.trigger"
        :options="triggers"
        label="When the user"
        :name="d() + '-trigger'"
        :disabled="isDisabled"
      />
    </div>

    <div class="col-md-2">
      <CSelect
        v-model="form.screen"
        :options="screens"
        label="Screen"
        :name="d() + '-screen'"
        :disabled="isDisabled"
      />
    </div>

    <div class="col-md-2">
      <CSelect
        v-model="form.action"
        :options="actions"
        label="Action"
        :name="d() + 'action'"
        :disabled="isDisabled"
      />
    </div>

    <div class="col-md-2">
      <label>{{ $t("Gap-time") }}</label>
      <fg-input :error="getError(d() + '-accept-gap-time')">
        <el-time-select
          :disabled="isDisabled"
          :name="d() + '-accept-gap-time'"
          v-model="form.delay"
          :picker-options="datepickerOptions"
          v-validate="!isDisabled ? `required` : null"
          :placeholder="$t('Select time')"
        >
        </el-time-select>
      </fg-input>
    </div>

    <div
      v-if="form.action === 'CROSS_SELLING' || form.action === 'CHANGE_CREATIVE'"
      class="col-2"
      style="margin-top: 15px"
    >
      <!-- <router-link tag="div" class="item" :to="dUrl"> -->
      <p-button type="success" :disabled="isDisabled" @click="showModal()">
        <span> <i class="fa fa-plus"></i> {{ $t(btnLabel) }} </span>
      </p-button>
      <!-- </router-link> -->
    </div>
    <modal :name="'modal-' + id" height="auto" :scrollable="true" :classes="modalClasses">
      <div class="modal-content bookmark-modal">
        <div class="modal-body">
          <div v-if="bookmarks === null">
            <loading :active="true" :is-full-page="false"></loading>
          </div>
          <div v-else-if="bookmarks.length === 1">
            <card-bookmark
              :bookmark="bookmarks[0]"
              :actions="['select']"
              :showOnlyApprovedCreativities="true"
              @selected="setBookmarkAndCreative"
            >
            </card-bookmark>
          </div>
          <div v-else class="row">
            <div
              class="col-md-4"
              v-for="bookmark in bookmarks"
              :key="'bookmark-' + bookmark.id"
            >
              <card-bookmark
                :bookmark="bookmark"
                :actions="['select']"
                ref="cardBookmark"
                :showOnlyApprovedCreativities="true"
                @selected="setBookmarkAndCreative"
              >
              </card-bookmark>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
//import { EventBus } from "src/eventBus"
import { modalUrl } from "./url.js";
import CSelect from "./Select";
import { updatableForm } from "src/app-components/FormMixin";
import { TimeSelect } from "element-ui";
import BookmarkModal from "src/app-components/BookmarkModal";
import CardBookmark from "../CardBookmark";

export default {
  name: "BaseFlow",

  inject: ["parentValidator"],

  mixins: [updatableForm],

  components: {
    [TimeSelect.name]: TimeSelect,
    [CSelect.name]: CSelect,
    CardBookmark,
  },

  mounted() {
    this.$store.dispatch("loadBookmarks");
  },

  props: {
    id: { type: String, required: true },
    campaignBookmarkId: { type: Number, required: true },
    selectedCreative: { type: Object, required: false },
    actions: { type: Array, default: [] },
    screens: { type: Array, default: [] },
    triggers: { type: Array, default: [] },
    entity: { type: Object, required: false, default: () => {} },
  },

  data() {
    return {
      form: this.entity
        ? JSON.parse(JSON.stringify(this.entity))
        : this.getDefaultModel(),
    };
  },

  async created() {
    this.$validator = this.parentValidator;

    /*EventBus.$on(this.listening(), ($event) => {
            this.form.product = $event
        });*/
  },

  watch: {
    "form.delay": {
      immediate: true,
      deep: true,
      handler(value) {
        if (value == undefined || !Number.isInteger(value)) return;

        let hour = String(Math.floor(value / 60)).padStart(2, "0");
        let min = String(value % 60).padStart(2, "0");
        this.form.delay = hour + ":" + min;
      },
    },

    entity: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.patchModel(nv);
      },
    },
    form: {
      deep: true,
      handler(newVal, oldVal) {
        //console.log("form changed. newVal: ", JSON.stringify(newVal));
        //console.log("form changed. oldVal: ", JSON.stringify(oldVal));
        if (oldVal && newVal.action !== oldVal.action) {
          this.setBookmarkAndCreative(null);
          this.updateBookmarkAndCreativeForRepeatScreen();
        }
        this.updatedModel(newVal);
      },
    },
    campaignBookmark: {
      immediate: true,
      handler() {
        this.updateBookmarkAndCreativeForRepeatScreen();
      },
    },
    selectedCreative: {
      immediate: true,
      handler() {
        this.updateBookmarkAndCreativeForRepeatScreen();
      },
    },
  },

  computed: {
    datepickerOptions() {
      return {
        start: "00:30",
        step: "00:10",
        end: "02:00",
      };
    },

    dUrl() {
      return modalUrl(this.$router.currentRoute.path, this.d());
    },

    isDisabled() {
      return this.form.active === false;
    },

    btnLabel() {
      if (this.selectedBookmark) {
        return this.selectedBookmark.name;
      }

      if (this.form.action === "CHANGE_CREATIVE") {
        return "Change creative";
      }

      return "Add Template";
    },

    selectedBookmark() {
      const val =
        this.form.bookmark_id !== null
          ? this.$store.getters.getBookmarkById(this.form.bookmark_id)
          : null;

      return val;
    },

    campaignBookmark() {
      return this.$store.getters.getBookmarkById(this.campaignBookmarkId);
    },

    //bookmarks to show on modal
    bookmarks() {
      if (this.form.action === "CHANGE_CREATIVE") {
        return [this.campaignBookmark];
      }

      const bookmarksFromStore = this.$store.getters.activeBookmarks.filter(
        (bookmark) => bookmark.id !== this.campaignBookmarkId
      );
      const filteredbookmarks = bookmarksFromStore.filter((x) => {
        return (x.status === "APPROVED");
      });

      return filteredbookmarks;
    },

    modalClasses() {
      return this.bookmarks.length > 1
        ? "modal-dialog modal-lg modal-xl bookmark-modal"
        : "modal-dialog modal-sm bookmark-modal";
    },
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    showModal() {
      this.$modal.show(`modal-${this.id}`);
    },
    filterCreativites(creativity) {},
    setBookmarkAndCreative(selection) {
      this.$set(
        this.form,
        "bookmark_id",
        selection && selection.bookmark ? selection.bookmark.id : this.campaignBookmarkId
      );
      this.form.product = selection;
      this.$modal.hide(`modal-${this.id}`);
    },
    updateBookmarkAndCreativeForRepeatScreen() {
      if (
        this.form.action == "REPEAT_SCREEN" &&
        this.campaignBookmark != null &&
        this.selectedCreative != null &&
        typeof this.campaignBookmark !== "undefined" &&
        typeof this.selectedCreative !== "undefined"
      ) {
        const selection = {
          bookmark: this.campaignBookmark,
          creativity: { hash: this.selectedCreative.bookmark_perf_text_hash },
        };
        console.log(
          "REPEAT_SCREEN",
          "Bookmark",
          selection.bookmark.id,
          "Creative",
          selection.creativity.hash
        );
        this.setBookmarkAndCreative(selection);
      }
    },
    getDefaultModel() {
      return null;
    },
  },
};
</script>

<style scoped>
input[type="text"]:disabled {
  background: #e3e3e3 !important;
}
.btn span {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
</style>
