<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <card style="height: 100%;">
        <div class="card-header">
          <h4 class="card-title">{{ $t("User Affinity") }}</h4>
        </div>
        <div class="card-body">
          <div>
            <template v-for="(range, key) in affinity">
              <RangeSlider
                :marginTop="key !== 0"
                :title="range.title"
                :initialValue="range.value"
                :id="range.id"
                @updateValue="onChange"
                v-if="range.title !== 'UNDETECTABLE'"
              />
            </template>
          </div>
        </div>
      </card>
    </div>
    <div class="col-12 col-md-6">
      <card style="height: 100%;">
        <div class="card-header">
          <h4 class="card-title">{{ $t("User Quality") }}</h4>
        </div>
        <div class="card-body">
          <div>
            <template v-for="(range, key) in quality">
              <RangeSlider
                :marginTop="key !== 0"
                :title="range.title"
                :initialValue="range.value"
                :id="range.id"
                @updateValue="onChange"
                v-if="range.title !== 'NEW USER'"
              />
            </template>
          </div>
        </div>
      </card>
    </div>

    <div class="col-12 d-flex justify-content-end pt-4">
      <p-button type="info" @click="saveData">{{ $t("Save") }}</p-button>
    </div>
  </div>
</template>

<script>
import api from 'src/api';
import { Card } from "src/components/UIComponents";
import RangeSlider from "src/app-components/account-configuration/RangeSlider";
export default {
  name: "SegmentationRules",

  components: {
    Card,
    RangeSlider,
  },

  data() {
    return {
      account: null,
      segmentationRules: [],
    };
  },

  async mounted () {
    this.segmentationRules = (await api.getRulesSegmentation()).data;
  },

  methods: {
    onChange(range) {
      let index = this.segmentationRules.findIndex(rule => rule.id === range.id);
      if (index !== -1) {
        this.segmentationRules[index].min = range.value[0];
        this.segmentationRules[index].max = range.value[1];
      }
    },

    async saveData() {
      console.log(this.segmentationRules);
      let res = await api.updateRulesSegmentation(this.segmentationRules);

      this.$notify({
        title: this.$t("Updated segmentation rules"),
        icon: "fa fa-check",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        duration: 10000,
      });
    }
  },

  computed: {
    affinity() {
      let affinity = this.segmentationRules.filter(
        (segmentation) => segmentation.query_type_id === 1
      );

      return affinity.map(el => {
        return {
          id: el.id,
          title: el.name,
          value: [el.min, el.max],
        };
      });
    },

    quality() {
      let quality = this.segmentationRules.filter(
        (segmentation) => segmentation.query_type_id === 2
      );

      return quality.map(el => {
        return {
          id: el.id,
          title: el.name,
          value: [el.min, el.max],
        };
      });
    },
  },
};
</script>
<style></style>
