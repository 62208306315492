import axios from "axios";
import { head, merge } from "lodash";
import { view as BookmarkViewTransformer } from "src/response-transformers/bookmark";
import { view as ProductAssignmentTransformer } from "src/response-transformers/product-assignment";
import { EventBus } from "src/eventBus.js";

const errorHandler = (error) => {
	const { request, response } = error;
	if (response && response.data) {
		EventBus.$emit("axios-request-error", {
			...response.data,
			type:
				response.status >= 500 && response.status <= 599
					? "danger"
					: "warning",
		});
	} else if (request) {
		EventBus.$emit("axios-request-error", {
			status: false,
			errors: "Internal server error",
		});
	}

	return Promise.reject(error);
};

const successHandler = (response) => {
	// By, code 200 with errors
	/**
  if(response.data.status === false) {
	EventBus.$emit("axios-request-error", response.data);
	Promise.reject(response);
  }
  */

	return response;
};

const client = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	json: true,
	crossDomain: true,
});

client.interceptors.response.use(successHandler, errorHandler);

const toQueryParams = (params, encode = false) => {
	return Object.keys(params)
		.map((key) => {
			const typeOfKey = typeof params[key];
			const val =
				typeOfKey == "object"
					? JSON.stringify(params[key])
					: params[key];
			// return key + "=" + encodeURIComponent(val);
			return encode
				? key + "=" + encodeURIComponent(val)
				: key + "=" + val;
		})
		.join("&");
};

const cancellationTokens = {};

var jwt = "";
var selectedAccountId = null;

export default {
	setToken(token) {
		jwt = token;
	},
	setSelectedAccountId(accountId) {
		selectedAccountId = accountId;
	},
	execute(
		method,
		resource,
		data,
		overrides = {},
		cancellationId = null
	) {
		if (cancellationId && cancellationTokens[cancellationId]) {
			cancellationTokens[cancellationId].cancel();
		}

		const config = {
			method,
			url: resource,
			data,
			handlerEnabled: true,
			headers: this.getHeaders(),
			cancelToken: new axios.CancelToken((c) => {
				if (cancellationId) {
					cancellationTokens[cancellationId] = { cancel: c };
				}
			}),
			returnData: true,
		};

		const params = merge(config, overrides);

		return client(params).then((res) =>
			config.returnData ? res.data : res
		);
	},

	getBaseUrl() {
		return process.env.VUE_APP_API_URL;
	},

	// Common methods
	getHeaders() {
		const headers = {
			Authorization: `Bearer ${jwt}`
		}

		if (selectedAccountId) {
			headers["X-Dyn-User-Account"] = selectedAccountId;
		}

		return headers;
	},

	// *****************************************************************************************************************
	// AUTH
	// *****************************************************************************************************************
	getAuth() {
		return this.execute("GET", "/auth");
	},

	// Generic GET index for any resource
	get(resource, id = null) {
		let url = resource;
		if (id !== null) {
			url += `/${id}`;
		}

		return this.execute("GET", url);
	},

	post(resource, data) {
		return this.execute("POST", resource, data);
	},

	put(resource, data) {
		return this.execute("PUT", resource, data);
	},

	delete(resource) {
		return this.execute("DELETE", resource);
	},

	// RCS Agents
	getRcsAgents() {
		return this.execute("GET", "/rcs/agents");
	},

	viewAgent(id) {
		const path = "/rcs/agents";
		return this.execute("GET", `${path}/${id}`);
	},

	postAgent(agentData) {
		return this.execute("POST", `/rcs/agents`, agentData);
	},

	putAgent(id, agentData) {
		return this.execute("PUT", `/rcs/agents/${id}`, agentData);
	},

	// *****************************************************************************************************************
	// TEMPLATES
	// *****************************************************************************************************************
	getTemplates(queryParams) {
		let path = "/templates";

		if (queryParams) {
			const strQueryParams = toQueryParams(queryParams, true);
			path += "?" + strQueryParams;
		}

		return this.execute("GET", path, null, {});
	},

	getTemplate(id) {
		return this.execute("get", "/templates/" + id, null);
	},

	updateTemplate(id, data) {
		return this.execute("put", "/templates/" + id, data);
	},

	createTemplate(data) {
		return this.execute("post", "/templates", data);
	},

	// *****************************************************************************************************************
	// BOOKMARKS
	// *****************************************************************************************************************
	validateBookmarkName(data) {
		return this.execute("post", "/bookmarks/validate/name", data);
	},

	getBookmarks(queryParams) {
		let path = "/bookmarks";

		if (queryParams) {
			const strQueryParams = toQueryParams(queryParams);
			path += "?" + encodeURI(strQueryParams);
		}

		return this.execute("GET", path, null, {}, "getBookmarks");
	},

	getBookmarkServices() {
		return this.execute("GET", "/bookmarks/services");
	},

	getBookmark(id) {
		return this.execute("get", "/bookmarks/" + id, null, {
			transformResponse: [].concat(
				axios.defaults.transformResponse,
				BookmarkViewTransformer
			),
		});
	},

	createBookmark(data) {
		return this.execute("post", "/bookmarks", data);
	},

	updateBookmark(id, data) {
		return this.execute("put", "/bookmarks/" + id, data);
	},

	deleteBookmark(id) {
		return this.execute("delete", `/bookmarks/${id}`);
	},

	archiveBookmark(id) {
		return this.updateBookmark(id, {
			archived: true,
		});
	},

	restoreBookmark(id) {
		return this.updateBookmark(id, {
			archived: false,
		});
	},

	// *****************************************************************************************************************
	// CAMPAIGNS
	// *****************************************************************************************************************
	getCampaigns(queryParams) {
		let path = "/campaigns";
		if (queryParams) {
			path += "?" + toQueryParams(queryParams, true);
			// path += "?" + encodeURI(toQueryParams(queryParams));
		}


		return this.execute("GET", path);
	},

	validateCampaignName(data) {
		return this.execute("post", "/campaigns/validate/name", data);
	},

	createCampaign(data) {
		return this.execute("post", "/campaigns", data);
	},

	createCampaignRetry(id, data) {
		return this.execute("post", `/campaigns/${id}/retry`, data);
	},

	deleteCampaignRetry(id) {
		return this.deleteCampaign(id);
	},

	updateCampaignRetry(id, data) {
		return this.execute("put", `/campaigns/${id}/retry`, data);
	},

	createPingCampaign(data) {
		return this.execute("post", "/ping/campaigns", data);
	},

	getCampaign(id) {
		return this.execute("get", `/campaigns/${id}`, null, {});
	},

	startCampaign(id) {
		return this.execute("put", "/campaigns/" + id + "/start");
	},

	pauseCampaign(id) {
		return this.execute("put", "/campaigns/" + id + "/pause");
	},

	resumeCampaign(id) {
		return this.execute("put", "/campaigns/" + id + "/resume");
	},

	deleteCampaign(id) {
		return this.execute("delete", "/campaigns/" + id);
	},

	cancelCampaign(id) {
		return this.execute("put", "/campaigns/" + id + "/cancel");
	},

	copyCampaign(id, data) {
		return this.execute("post", "/campaigns/" + id + "/copy", data);
	},

	testCampaign(data) {
		return this.execute("post", "/campaigns/test", data);
	},

	getPingCampaign(id) {
		return this.execute("get", `/ping/campaigns/${id}`, null, {});
	},

	updatePingCampaign(id, data) {
		return this.execute("put", `/ping/campaigns/${id}`, data);
	},

	getRcsCampaignPivot(filters) {
		let path =
			"/rcs/reports/campaign?" + new URLSearchParams(filters).toString();

		return this.execute("GET", path);
	},

	getOptPivot(filters) {
		let path =
			"/rcs/reports/opt?" + new URLSearchParams(filters).toString();

		return this.execute("GET", path);
	},

	getCampaignPivot(queryParams) {
		let path = "/campaigns/pivot";

		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	getConvertionsPivot(filters) {
		let path =
			"/rcs/reports/convertions?" +
			new URLSearchParams(filters).toString();

		return this.execute("GET", path);
	},

	getCampaignPivotAdvertiser(queryParams) {
		return this.getCampaignPivot(queryParams);
	},

	getCampaignPivotConsolidated(queryParams) {
		let path = "/campaigns/pivot/consolidated";

		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	getTimBrasilByNameReport(queryParams) {
		let path = "/campaigns/pivot/tim-brasil-by-name";

		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	getTimBrasilByCreativesReport(queryParams) {
		let path = "/campaigns/pivot/tim-brasil-by-creatives";

		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	getCampaignPivotLive() {
		return this.execute("GET", "/campaigns/pivot/live");
	},

	getStatsProgrammatic(queryParams) {
		let path = "/stats/programmatic";

		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	getCampaignPivotPerformance(queryParams) {
		return this.getCampaignPivot(queryParams);
	},

	getCampaignPivotCreative(queryParams) {
		return this.getCampaignPivot(queryParams);
	},

	getEventReport(filters) {
		let path =
			"/api-event/report?" + new URLSearchParams(filters).toString();
		return this.execute("GET", path);
	},

	getByTemplateReport(filters) {
		let path = "/stats/by-template?" + new URLSearchParams(filters).toString();
		return this.execute("GET", path);
	},

	updateCampaign(id, data) {
		return this.execute("put", `/campaigns/${id}`, data);
	},

	// *****************************************************************************************************************
	// DASHBOARD
	// *****************************************************************************************************************
	getDashboard(name) {
		return this.execute("GET", "/dashboard/" + name);
	},

	// *****************************************************************************************************************
	// EVENTS
	// *****************************************************************************************************************
	getApiEvents(queryParams) {
		let path = "/api-event/index";

		if (queryParams) {
			const strQueryParams = toQueryParams(queryParams);
			path += "?" + encodeURI(strQueryParams);
		}

		return this.execute("GET", path, null, {}, "getApiEvents");
	},

	getApiEvent(id) {
		return this.execute("get", "/api-event/" + id, null, {});
	},

	deleteApiEvent(id) {
		return this.execute("delete", "/api-event/" + id);
	},

	adaptApiEventData(data) {
		if (data.bookmark) {
			data.bookmarks = [data.bookmark];
			delete data.bookmark;
			data.bookmarks[0].creatives = data.bookmarks[0].creatives.map(
				(c) => {
					return Object.keys(c).map((key) => {
						return c[key];
					});
				}
			);
		}
	},

	async createApiEvent(data, newBookmark = false) {
		if (newBookmark) {
			const bookmark = (await this.createTemplate(data.bookmark)).data;
			data.templateCreatives.forEach((bc) => (bc.bookmark_id = bookmark.id));
			data.bookmark = bookmark;
		}

		if (data.bookmark) {
			delete data.bookmark;
		}

		// this.adaptApiEventData(data);
		return this.execute("post", "/api-event", data);
	},

	async createApiEventFromScratch(data) {
		/*
			TODO Had to implement this workaround because API does not work for from-scratch flow.
			This is a known bug that was never fixed and I was not able to fix it right now.
		*/
		const bookmark = (await this.createBookmark(data.bookmark)).data;
		// data.bookmarkCreatives.forEach((bc) => (bc.bookmark_id = bookmark.id));
		data.templateCreatives.forEach((bc) => (bc.bookmark_id = bookmark.id));
		data.bookmark = bookmark;
		// this.adaptApiEventData(data);
		const res = await this.execute("post", "/api-event", data);
		return res;
	},

	updateApiEvent(id, data) {
		// this.adaptApiEventData(data);
		if (data.bookmark) {
			delete data.bookmark;
		}

		if (data.templateCreatives) {
			if (data.templateCreatives[0].template) {
				delete data.templateCreatives[0].template;
			}
		}
		return this.execute("put", "/api-event/" + id, data);
	},

	validateEventName(data) {
		return this.execute("post", "/api-event/validate/name", data);
	},

	// *****************************************************************************************************************
	// PERFORMANCE CAMPAIGNS
	// *****************************************************************************************************************
	createPerformanceCampaign(data) {
		return this.execute("post", "/performance/campaigns", data);
	},

	getPerformanceCampaigns() {
		return this.execute("get", "/performance/campaigns");
	},

	getPerformanceCampaign(id) {
		return this.execute("get", "/performance/campaigns/" + id);
	},

	updatePerformanceCampaign(id, data) {
		return this.execute("put", "/performance/campaigns/" + id, data);
	},

	startPerformanceCampaign(id) {
		return this.execute("put", "/performance/campaigns/" + id + "/start");
	},

	getAccountPivotReports(accountId) {
		return this.execute("GET", "/account/pivot/reports", null);
	},

	// *****************************************************************************************************************
	// PERFORMANCE CAMPAIGN TYPES
	// *****************************************************************************************************************
	getPerformaceCampaignTypes() {
		const path = "/performance/campaign-types";
		return this.execute("GET", path);
	},

	getPerformaceCampaignType(id) {
		return this.execute("GET", "/performance/campaign-types/" + id);
	},

	// *****************************************************************************************************************
	// PLATFORM CATEGORIES
	// *****************************************************************************************************************
	getPlatformCategories() {
		return this.execute("GET", "/platform/categories");
	},

	/**
	 * Gets the available categories, based on the existing bookmarks in the account
	 * @returns
	 */
	getBookmarkCategories() {
		return this.execute("GET", "/bookmarks/categories");
	},

	// *****************************************************************************************************************
	// PLATFORM SUBCATEGORIES
	// *****************************************************************************************************************
	getPlatformSubCategories(categoryId) {
		const path =
			"/platform/subcategories?" +
			toQueryParams({ category_id: categoryId });
		return this.execute("GET", path);
	},

	getPlatformSubCategoriesThreaded() {
		const path =
			"/platform/subcategories?" + toQueryParams({ threaded: true });
		return this.execute("GET", path);
	},

	getPlatformCommercialModels() {
		return this.execute("GET", "/platform/commercial-models");
	},

	getScenarioChannels() {
		return this.execute("get", "/scenarios/channels");
	},

	getTemplatesSubcategories() {
		return this.execute("get", "/templates/subcategories");
	},

	// *****************************************************************************************************************
	// PRODUCT ASSIGNMENT
	// *****************************************************************************************************************
	getProductAssignmentCalculate(id, data) {
		return this.execute(
			"post",
			`/segmentation-tool/lists/${id}/calculate`,
			data
		);
	},

	getProductAssignments() {
		return this.execute("get", "/product-assignments");
	},

	getProductAssignment(id) {
		return this.execute("get", `/product-assignments/${id}`, null, {
			transformResponse: [].concat(
				axios.defaults.transformResponse,
				ProductAssignmentTransformer
			),
		});
	},

	createProductAssignment(data) {
		return this.execute("post", "/product-assignments", data);
	},

	updateProductAssignation(id, data) {
		return this.execute("put", `/product-assignments/${id}`, data);
	},

	deleteProductAssignment(id) {
		return this.execute("delete", `/product-assignments/${id}`);
	},

	// *****************************************************************************************************************
	// SCENARIO TYPE
	// *****************************************************************************************************************

	getScenarioTypes(queryParams) {
		let path = "/scenarios/types";

		if (queryParams) {
			path += "?" + toQueryParams(queryParams);
		}

		return this.execute("get", path);
	},

	getScenarioType(id, queryParams = null) {
		let path = "/scenarios/types/" + id;

		if (queryParams) {
			path += "?" + toQueryParams(queryParams);
		}

		return this.execute("get", path);
	},

	getScenarioTypeFlow(id) {
		const path = "/scenarios/types/" + id + "/flow";
		return this.execute("get", path);
	},

	// *****************************************************************************************************************
	// SEGMENTATION LISTS
	// *****************************************************************************************************************

	getSegmentationList(id) {
		return this.execute("get", `/segmentation-tool/lists/${id}`);
	},

	getSegmentationLists(queryParams) {
		let path = "/segmentation-tool/lists";
		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	createSegmentationList(data) {
		return this.execute("POST", "/segmentation-tool/lists", data);
	},

	createSegmentationListPipeline(listId, data) {
		return this.execute(
			"POST",
			`/segmentation-tool/lists/${listId}/pipelines`,
			data
		);
	},

	getSegmentationListPipeline(id, listId = 0) {
		return this.execute(
			"GET",
			`/segmentation-tool/lists/${listId}/pipelines/${id}`
		);
	},

	// ************************************************************************************************************************
	// User reports
	// ************************************************************************************************************************
	getUserReports() {
		return this.execute("get", "/users/reports");
	},

	createUserReport(data) {
		return this.execute("post", "/users/reports", data);
	},

	deleteUserReport(id) {
		return this.execute("delete", "/users/reports/" + id);
	},

	// ************************************************************************************************************************
	// Uploads
	// ************************************************************************************************************************
	uploadFile(formData, type) {
		return this.execute("post", "/uploads?file_type=" + type, formData);
	},

	downloadFile(id, returnData = true) {
		const url = Number.isInteger(id) ? `/download/${id}` : id;

		return this.execute("get", url, null, {
			responseType: "blob",
			returnData,
		});
	},

	getUpload(id) {
		return this.execute("get", "/uploads/" + id);
	},

	deleteUpload(id) {
		return this.execute("delete", "/uploads/" + id);
	},

	getVariables() {
		return this.execute("get", "/variables");
	},

	// *****************************************************************************************************************
	// CREATIVITIES
	// *****************************************************************************************************************

	updateCreativity(idBookmark, idCreativity, data) {
		return this.execute(
			"put",
			`/bookmarks/${idBookmark}/perftext/${idCreativity}`,
			data
		);
	},

	// *****************************************************************************************************************
	// ACCOUNT
	// *****************************************************************************************************************
	getAccountTestingLines() {
		return this.execute("GET", "/account/testing-lines");
	},

	createAccountTestingLines(data) {
		return this.execute("POST", "/account/testing-lines", data);
	},

	getAccountUsers() {
		const path = `/account/users`;
		return this.execute("GET", path);
	},

	getAccountAdvertisers(accountId, withBookmarks = true) {
		var path = `/account/${accountId}/advertisers`;
		if (withBookmarks) {
			path += `?with_bookmarks=true`;
		}

		return this.execute("GET", path);
	},

	getAccounts() {
		return this.execute("GET", "/account");
	},

	getAccount(id) {
		return this.execute("GET", "/account/" + id);
	},

	updateAccount(id, data) {
		return this.execute("PUT", "/account/" + id, data);
	},

	// *****************************************************************************************************************
	// TRANSLATIONS
	// *****************************************************************************************************************
	getTransalations(language) {
		return this.execute("GET", `/platform/translations/${language}`);
	},

	// *****************************************************************************************************************
	// Sat Call To Action
	// *****************************************************************************************************************

	getSatCallToActions() {
		return this.execute("GET", `/sat-call-to-action`);
	},

	getSatCallToAction(id) {
		return this.execute("GET", `/sat-call-to-action/${id}`);
	},

	// *****************************************************************************************************************
	// SMPP GATEWAY
	// *****************************************************************************************************************

	getSmppGatewayStatistics(filters) {
		return this.execute(
			"GET",
			`/smpp-gateway/access-log`,
			null,
			{ params: { ...filters, dateField: "@timestamp" } },
			"getSmppGatewayStatistics"
		);
	},

	getSmppGatewayAccessLogForMsisdn(msisdn, params) {
		return this.execute(
			"GET",
			`/smpp-gateway/access-log/${encodeURIComponent(msisdn)}`,
			null,
			{ params },
			"getSmppGatewayAccessLogForMsisdn"
		);
	},

	getSmppGatewayProviders() {
		return this.execute("GET", `/smpp-gateway/providers`);
	},

	// *****************************************************************************************************************
	// SMSC TESTER
	// *****************************************************************************************************************

	sendSms(parameters) {
		return this.execute(
			"POST",
			`/smsc-tester/sender`,
			parameters,
			{},
			"sendSms"
		);
	},

	// *****************************************************************************************************************
	// SVG
	// *****************************************************************************************************************

	getSvg(name = null) {
		return this.execute("GET", `/svg` + (name ? `/${name}` : ``));
	},

	//**********************************************************************************************/
	// MT
	//**********************************************************************************************/
	getMt(data) {
		return this.execute("POST", "/mt/count", data);
	},

	// CRM Events
	//**********************************************************************************************/

	getCrmEventsIndex(queryParams) {
		let path = "/crm/events/query";
		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	createCrmEventsQuery(data) {
		return this.execute("POST", "/crm/events/query", data);
	},

	//**********************************************************************************************/
	// CRM Campaigns
	//**********************************************************************************************/

	getFilteredCampaigns(filters) {
		let path =
			"/crm/campaign/filtered?" + new URLSearchParams(filters).toString();

		return this.execute("GET", path);
	},

	getCrmCampaignsIndex(queryParams) {
		let path = "/crm/campaign/query";
		if (queryParams) {
			path += "?" + encodeURI(toQueryParams(queryParams));
		}

		return this.execute("GET", path);
	},

	createCrmCampaignsQuery(data) {
		return this.execute("POST", "/crm/campaign/query", data);
	},

	//**********************************************************************************************/
	// Customer Service
	//**********************************************************************************************/

	getCustomerService(array) {
		const path = "/customer-service/search?msisdn=" + array.join(",");
		return this.execute("GET", path);
	},

	//**********************************************************************************************/
	// Status Connections
	//**********************************************************************************************/

	getStatusConnections() {
		return this.execute("GET", '/connection/status');
	},

	actionsConnections(id, smsc, action = 'start') {
		let path = `/connection/${id}/${action}-smsc?smsc=${encodeURIComponent(smsc)}`;
		return this.execute("GET", path);
	},

	//**********************************************************************************************/
	// Subscription Services
	//**********************************************************************************************/

	getSubscriptionServices() {
		return this.execute("GET", '/subscriptions');
	},

	updateSubscriptionServices(id, data) {
		return this.execute("put", "/subscriptions/" + id, data);
	},

	createSubscriptionServices(data) {
		return this.execute("post", "/subscriptions", data);
	},

	deleteSubscriptionServices(id) {
		return this.execute("delete", "/subscriptions/" + id);
	},

	validateNameSubscriptionServices(data) {
		return this.execute("post", "/subscriptions/name", data);
	},

	//**********************************************************************************************/
	// Segmentation
	//**********************************************************************************************/

	runSegmentations(id) {
		return this.execute("GET", `/audience/segmentation-pipeline/${id}/run`);
	},

	getSegmentations() {
		return this.execute("GET", '/audience/segmentation-pipeline');
	},

	getSegmentation(id) {
		return this.execute("GET", "/audience/segmentation-pipeline/" + id);
	},

	updateSegmentation(id, data) {
		return this.execute("put", "/audience/segmentation-pipeline/" + id, data);
	},

	createSegmentation(data) {
		return this.execute("post", "/audience/segmentation-pipeline", data);
	},

	validateNameSegmentation(name) {
		return this.execute("post", "/audience/segmentation-pipeline/name", { name });
	},

	deleteSegmentation(id) {
		return this.execute("delete", "/audience/segmentation-pipeline/" + id);
	},

	downloadAllSegmentation(id) {
		return this.execute("GET", `/audience/segmentation-pipeline/${id}/download`);
	},

	downloadFileSegmentation(id) {
		return this.execute("GET", `/audience/segmentation-pipeline/files/${id}/download`);
	},

	splitSegmentation(id, lines) {
		return this.execute("GET", `/audience/segmentation-pipeline/${id}/split?lines=${lines}`);
	},

	statusNewFilesSegmentation(id, status) {
		return this.execute("PUT", `/audience/segmentation-pipeline/${id}/newfiles`, { status });
	},

	getRulesSegmentation() {
		return this.execute("GET", `/audience/segmentation-pipeline/query-rules`);
	},

	updateRulesSegmentation(data) {
		return this.execute("PUT", `/audience/segmentation-pipeline/query-rules`, data);
	},



	//**********************************************************************************************/
	// Agents FlowBuilder
	//**********************************************************************************************/
	getFlows() {
		return this.execute("GET", '/flow');
	},
	
  getFlowsWithNodes() {
		return this.execute("GET", '/flow/nodes');
	},

	getFlow(id) {
		return this.execute("GET", "/flow/" + id);
	},

	updateFlow(id, data) {
		return this.execute("put", "/flow/" + id, data);
	},

	createFlow(name) {
		return this.execute("post", "/flow", { name });
	},

	deleteFlow(id) {
		return this.execute("delete", "/flow/" + id);
	},

	getConversations() {
		return this.execute("GET", "/conversations/index");
	},

	getConversationsInfo(id) {
		return this.execute("GET", "/conversations/" + id);
	},
	
  // Chatbot Stats
  getChatbotReport(filters) {
    let path =
			"/stats/chatbot-report?" + new URLSearchParams(filters).toString();

		return this.execute("GET", path);
	},


	// Services
	createService(data) {
		return this.execute("post", "/services", data);
	},

  getServices() {
		return this.execute("get", "/services");
	},

  deleteService(id) {
		return this.execute("delete", "/services/" + id);
	},

  getService(id) {
		return this.execute("get", "/services/" + id);
	},

  updateService(id, value) {
		return this.execute("put", "/services/" + id, value);
	},

	getBrandsGBM() {
		return this.execute("get", "/services/gbm/brands");
	},
  
  
  // Chatbot Dictionary
  getDictionary(page = 1) {
		return this.execute("get", `/dictionary?_page=${page}&_limit=10`);
	},
  
  
  
  // Templates Whatsapp
  /* getTemplatesWhatsapp(service_id) {
		return this.execute("get", `/services/wpp/templates/${service_id}`);
	}, */
  
  createConnectionTemplatesWhatsapp(data) {
    return this.execute("post", `/services/wpp/templates`, data);
	},
  
  editConnectionTemplatesWhatsapp(data) {
		return this.execute("put", `/services/wpp/templates/${data.id}`, data);
	},

  
  addConnectionTemplates(id, data) {
		return this.execute("put", `/chatbot/template/${id}`, data);
	},

  getChatbotTemplate() {
		return this.execute("get", `/chatbot/template`);
	},
  
  getChatbotTemplateByService(id) {
		return this.execute("get", `/chatbot/template/service/${id}`);
	},
  
  createChatbotTemplate(data) {
		return this.execute("post", `/chatbot/template`, data);
	},

  deleteChatbotTemplate(id) {
		return this.execute("delete", `/chatbot/template/${id}`);
	},
  
  
  // Campaigns Outbound
  getCampaignsOutbound() {
		return this.execute("get", `/chatbot/campaign`);
	},
  
  getCampaignOutbound(id) {
		return this.execute("get", `/chatbot/campaign/${id}`);
	},
  
  validateNameCampaignOutbound(name) {
		return this.execute("get", `/chatbot/campaign/validate?name=${name}`);
	},
  
  createCampaignsOutbound(data) {
		return this.execute("post", `/chatbot/campaign`, data);
	},
  
  editCampaignsOutbound(data) {
		return this.execute("put", `/chatbot/campaign/${data.id}`, data);
	},
  
  toggleStatusCampaignsOutbound(id) {
		return this.execute("get", `/chatbot/campaign/${id}/togglestatus`);
	},
  
  calculateGbmAudience(service_id, days) {
		return this.execute("get", `/chatbot/campaign/calculate-audience/${service_id}?days=${days}`);
	},
  
  statsCampaignsOutbound(id) {
		return this.execute("get", `/chatbot/campaign/${id}/stats`);
	},

  deleteCampaignsOutbound(id) {
		return this.execute("delete", `/chatbot/campaign/${id}`);
	},


  
  // Api Template
  getApiTemplate() {
		return this.execute("get", `/services/tokens`);
	},
  
  createApiTemplate(data) {
		return this.execute("post", `/services/tokens`, data);
	},
  
  editApiTemplate(data) {
		return this.execute("put", `/services/tokens/${data.id}`, data);
	},

  deleteApiTemplate(id) {
		return this.execute("delete", `/services/tokens/${id}`);
	},
  
  regenerateApiTemplate(id) {
		return this.execute("patch", `/services/tokens/${id}`);
	},

	//**********************************************************************************************/
	// Profile
	//**********************************************************************************************/

	changePassword(data) {
		return this.execute("PUT", `/profile/password`, data);
	},

	getProfile() {
		return this.execute("GET", `/profile`);
	},

	updateProfile(data) {
		return this.execute("PUT", `/profile`, data);
	},
};
