<template>
    <div class="rcs-card" :class="[heightClass, orientationCardClass, thumbnailImageAlignmentClass, !hasMedia ? 'noMedia' : '']">
        <div class="header-media" v-if="hasMedia">
            <video v-if="isVideo" controls>
                <source v-bind:src="fileUrl" type="video/mp4">
            </video>
            <div v-else class="media" :style="backgroundImage"></div>
        </div>
        <div class="d-flex flex-column">
            <div class="content" v-if="dataAdapter.title || dataAdapter.description" :class="[hasSuggestions ? 'mb-0' : '' , heightClass.includes('tall') ? 'truncate' : '']">
                <p class="title">{{dataAdapter.title}}</p>
                <span class="text">{{dataAdapter.description}}</span>
            </div>
            <div class="suggestions-container" v-if="showSuggestions || !carouselTruncation">
                <a v-for="(text, index) in suggestions" :key="index" href="javascript:void(0)" class="suggestion">{{text}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { Card } from "element-ui";

const MEDIA_HEIGHT_CLASS = new Map();

export default {
    components: {
        [Card.name]: Card,
    },

    props:{
        data:{
            type: Object,
            default: () => {}
        },

        carouselTruncation : {
            type: Boolean,
            default: false
        }
    },

    beforeCreate() {
        // MEDIA_HEIGHT_CLASS.set( 'HEIGHT_UNSPECIFIED', 'short-height');
        MEDIA_HEIGHT_CLASS.set( 'SHORT', 'short-height');
        MEDIA_HEIGHT_CLASS.set( 'MEDIUM', 'medium-height');
        MEDIA_HEIGHT_CLASS.set( 'TALL', 'tall-height');
    },

    data() {
        const VIDEO_EXTENTIONS = ['mp4', 'm4v', 'mpg', 'mpeg', 'webm']
        return {
            VIDEO_EXTENTIONS
        }
    },

    computed:{

        dataAdapter(){
            return 'richCard' in this.data ? this.data.richCard.standaloneCard.cardContent : this.data
        },

        suggestions(){
            return this.dataAdapter.suggestions.map( (element) => {
                return element[Object.keys(element)[0]].text
            })
        },

        showSuggestions(){
            if (this.heightClass.includes('tall')) {
                return this.dataAdapter.title == "" && this.dataAdapter.description == ""
            }
            return this.hasSuggestions
        },

        heightClass(){
            // console.debug(this.dataAdapter.media.height);
            return this.hasMedia ? MEDIA_HEIGHT_CLASS.get(this.dataAdapter.media.height) : ''
        },

        orientationCardClass(){
            return 'richCard' in this.data ? this.data.richCard.standaloneCard.cardOrientation.toLowerCase() : '';
        },

        thumbnailImageAlignmentClass(){
            return 'richCard' in this.data ? this.data.richCard.standaloneCard.thumbnailImageAlignment.toLowerCase() : '';
        },

        hasSuggestions(){
            return this.suggestions.length > 0
        },

        hasMedia(){
            return 'media' in this.dataAdapter
        },

        backgroundImage(){
            return `background-image: url(${this.dataAdapter.media.contentInfo.fileUrl})`
        },

        isVideo(){
            if (this.hasMedia) {
                for (let index = 0; index < this.VIDEO_EXTENTIONS.length; index++) {
                    if (this.dataAdapter.media.contentInfo.fileUrl.includes(this.VIDEO_EXTENTIONS[index])) {
                        return true
                    }
                }
            }
            return false
        },

        fileUrl(){
            return this.dataAdapter.media.contentInfo.fileUrl
        },
    },

    methods: {
    },
}
</script>

<style lang="scss" scoped>

    * {
        // transition: max-height, min-height, height .5s ease-in-out;
        // transition-property: max-height, min-height, height, max-width, min-width, width, grid-template-columns;
        // transition-duration: .25s;
        // transition-timing-function: ease-in-out;
    }
    :root{
        //Variables
        --card-height: 270px;
        --header-height: 90px;
        --grid-columns: 1;
    }

    .truncate {
        overflow: hidden;
        margin-bottom: 5px;
    }

    .rcs-card{
        width: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        overflow: hidden;
        background: var(--white);
        border: 1px solid #d6d6d6;
        border-radius: 5px;

        position: relative;

        &.noMedia {
            grid-template-rows: 1fr !important;
        }
    }

    .header-media{
        display: inline-flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        max-height: var(--header-height);
        min-height: var(--header-height);
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        background: black;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .media, video{
        background-color: white;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    video {
        object-fit: cover;
    }

    .content{
        text-align: left;
        padding: 0 12px;
        margin: 7px 0;
        width: 100%;
        height: 100%;
        // min-height: 84px;
        // max-height: 250.50px;

        .title {
            font-size: 77%;
            display: block;
            margin-bottom: 5px;
        }
        .text{
            font-size: 67%;
            display: block;
            // margin-bottom: 5px;
            white-space: break-spaces;
        }
    }

    .suggestions-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0 12px;
        margin-top: 7px;
        align-self: end;
        // position: absolute;
        width: 100%;
        // bottom: 0;
        background-color: white;

        .suggestion + .suggestion{
            border-top: 1px solid #d6d6d6;
        }

        &:first-child{
            margin-top: auto !important;
        }
    }

    .suggestion{
        // border: 1px solid #d6d6d6;
        font-size: 80%;
        padding: 10px 0;
        color: var(--info);
        font-weight: 800;
        width: 100%;
        text-align: center;
        &:hover{
            text-decoration: none;
        }
    }

    .short-height {
        --card-height: 270px;
        --header-height: 90px;
    }
    .medium-height {
        --card-height: 320px;
        --header-height: 126px;
    }
    .tall-height {
        --card-height: 400px;
        --header-height: 198px;
    }
    .horizontal{
        grid-template-columns: 30% 1fr;
        max-height: var(--card-height);
        min-height: 84px;
        --header-height: 100%;

        .header-media{
            border-radius: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &.noMedia {
            grid-template-columns: 100% !important;
        }

        &.right {
            grid-template-columns: 1fr 30%;
            .header-media {
                grid-column: 2;
                grid-row: 1;
                border-radius: 0;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

    }
</style>