<template>
  <div v-if="conversation">
    <Conversation :conversation="conversation" @goBack="goToConversations" />

    <floating-action-button
      color="#339564"
      icon="download"
      :position="0"
      @click="exportToExcel"
      v-if="conversation.messages"
    />
  </div> 
</template>

<script>
  import api from "src/api";
  import moment from "moment";
  import { mapMutations } from "vuex";
  import * as Export2Excel from "src/app-components/Export2Excel";
  import FloatingActionButton from "src/app-components/FloatingActionButton";
  import Conversation from "src/app-components/chatbot/conversations/Conversation";
  export default {
    name: "ConversationsInfo",

    components: {
      FloatingActionButton,
      Conversation
    },

    data() {
      return {
        conversation: {
          messages: [],
          user: {},
        },
      };
    },

    created() {
      this.setTopNavbarTitle("Conversation");
    },

    mounted() {
      this.getConversationsInfo();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getConversationsInfo() {
        let res = await api.getConversationsInfo(this.$route.params.id);
        if(res.status) {
          if(res.data) {
            if(res.data.length) {
              this.conversation.user = res.data[0].user;

              res.data[0].sessions.forEach(s => {
                this.conversation.messages = this.conversation.messages.concat(s.messages);
              })
            }
          }
        }
      },

      goToConversations() {
        this.$router.push({ name: "ConversationsIndex" });
      },

      async exportToExcel() {
        const tHeader = ["Id", "Origin", "Destination", "Message", "Timestamp"];
        const data = this.conversation.messages.map((e) => {
          return [
            e.messageId,
            e.origin,
            e.destination,
            e.message,
            this.formatDate(e.timestamp),
          ];
        });

        let formats = [];
        Export2Excel.export_json_to_excel({
          header: tHeader,
          data,
          formats,
          filename: this.excelFileName,
          autoWidth: true,
          bookType: "xlsx",
        });
      },

      formatDate(date) {
        if (date.$date) {
          if (date.$date.$numberLong) {
            return moment(parseInt(date.$date.$numberLong)).format(
              "DD/MM/YY HH:mm"
            );
          }
        }
        return "";
      },
    },

    computed: {
      excelFileName() {
        return `Dynamo_Conversation_${this.conversation.conversationId}`;
      },
    },
  };
</script>