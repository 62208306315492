<template>
  <el-select
    :value="value"
    placeholder="Select"
    size="large"
    :disabled="disabled"
    class="error-input"
    :filterable="isSearch"
    :filter-method="filterMethod"
    clearable
    @input="handleDataChange($event)"
    :placeholder="$t(placeholder)"
    value-key="id"
    ref="selectPipeline"
    @visible-change="handleVisibleChange"
  >
    <template v-for="option in options">
      <el-option
        :value="option.id"
        :label="$t(option.name)"
        :key="option.id"
        v-if="option.id === 'FILTERS'"
        disabled
      >
        <el-button-group v-if="option.id">
          <template v-for="button in buttonGroups">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(button.name)"
              placement="top"
              :key="button.id"
            >
              <el-button
                size="mini"
                :type="button.id === groupSelected ? 'primary' : ''"
                :icon="button.icon"
                @click="selectGroup(button.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-button-group>
      </el-option>

      <el-option
        :value="option.id"
        :label="$t(option.name)"
        v-else
        v-show="option.view || groupSelected === 'SEARCH'"
      ></el-option>
    </template>
  </el-select>
</template>

<script>
import { Button, ButtonGroup } from "element-ui";
import OptionsTypeMixin from "./OptionsTypeMixin";
export default {
  name: "SelectGroupSegmentation",
  props: ["value", "disabled", "action", "includesAudience"],
  mixins: [OptionsTypeMixin],
  components: {
    [Button.name]: Button,
    [ButtonGroup.name]: ButtonGroup,
  },

  data() {
    return {
      groupSelected: "AUDIENCE",
      filter: "",
    };
  },

  created() {
    this.setValueGroup();
  },

  watch: {
    value() {
      if (this.value) {
        this.optionsType.forEach((option) => {
          if (option.id === this.value) {
            this.groupSelected = option.group;
          }
        });
      }
    },
  },

  methods: {
    setValueGroup() {
      if(this.value) {
        const { group } = this.optionsType.find(option => option.id === this.value);
        this.groupSelected = group;
      }else if (!this.isAdd || !this.includesAudience) {
        this.groupSelected = "USER";
      }
    },

    handleVisibleChange(visible) {
      if (!visible) {
        if (this.filter === this.value) {
          this.$emit("input", null);
          this.filter = "";
        }
      }
    },

    handleDataChange(value) {
      this.filter = "";
      this.$emit("input", value);

      if (value === "DELIVERIES_IN_CURRENT_MONTH") {
        this.$emit("valueParamsChange", {
          param: "days",
          value: new Date().getDate(),
        });
      }
    },

    filterMethod(query) {
      this.filter = query;
      this.$emit("input", query);
    },

    selectGroup(id) {
      this.groupSelected = id;
      if (id === "SEARCH") {
        this.$refs.selectPipeline.focus();
      }
    },
  },

  computed: {
    isAdd() {
      return this.action === "ADD";
    },

    isSearch() {
      return this.groupSelected === "SEARCH";
    },

    placeholder() {
      return this.isAdd ? "Select audience" : "Select filter";
    },

    buttonGroups() {
      let groups = [
        {
          id: "USER",
          name: "User",
          icon: "el-icon-user-solid",
        },
        {
          id: "SUBSCRIPTIONS",
          name: "Subscriptions",
          icon: "el-icon-star-on",
        },
        {
          id: "EVENTS",
          name: "Events",
          icon: "el-icon-s-data",
        },
        {
          id: "SEARCH",
          name: "Search",
          icon: "el-icon-search",
        },
      ];

      if(this.includesAudience || this.index === 0) {
        groups.unshift(
          {
            id: "AUDIENCE",
            name: "Upload",
            icon: "el-icon-upload",
          }
        );
      }

      return groups;
    },

    options() {
      const filters = [
        {
          id: "FILTERS",
          name: "",
        },
      ];

      if (this.isSearch) {
        return [
          ...filters,
          ...this.optionsType.filter((option) =>
            option.name.toLowerCase().includes(this.filter.toLowerCase())
          ),
        ];
      }

      return [
        ...filters,
        ...this.optionsType.map((option) => {
          return {
            ...option,
            view: option.group === this.groupSelected,
          };
        }),
      ];
    },
  },
};
</script>
