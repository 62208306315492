<template>
  <div class="form-group has-label">
    <label>{{ $t(label) }}</label>
    <fg-input :required="required" :error="getError(name)">
      <el-select
        :name="name"
        :placeholder="$t('Select')"
        :multiple="multiple"
        :size="size"
        :disabled="disabled"
        filterable
        clearable
        collapse-tags
        v-model="selected"
        @change="handleChange"
      >
        <el-option v-for="c in channels" :label="c.name" :value="c.id" :key="c.id">{{
          $t(c.name)
        }}</el-option>
      </el-select>
    </fg-input>
  </div>
</template>

<script>
export default {
  name: "channel-dropdown",
  props: {
    name: {
      type: String,
      required: false,
      default: "channel",
    },
    label: {
      type: String,
      required: false,
      default: "Channel",
    },
    value: [Number, Array, Object],
    isEditable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    allowedChannels: {
      type: Array,
      require: false,
      default: [],
    },
  },

  //async created() {
  //  this.$validator = this.parentValidator;
  //  this.getChannels();
  //},

  computed: {
    local() {
      return this.value ? this.value : null;
    },
  },

  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selected = newVal;
      }
    },
  },

  data() {
    return {
      selected: null,
    };
  },

  computed: {
    channels() {
      let channels = this.$store.state.channels;
      if (channels && this.allowedChannels.length > 0) {
        return channels.filter((x) => this.allowedChannels.includes(x.id));
      }
      return channels;
    },
  },

  methods: {
    onClear($event) {
      this.$emit("clear", $event);
    },

    handleChange() {
      this.$emit("input", this.selected);
    },

    getError(fieldName) {
      return this.$t(this.errors.first(fieldName));
    },

    // async getChannels() {
    //   const channels = await api.getScenarioChannels();
    //   this.channels = channels.data;
    // }
  },
};
</script>

<style></style>
