<template>
    <div>
        <div class="row">
            <div class="col-12 col-md text-left">
                <h6>{{ $t("Free Content Card configuration") }}</h6>
            </div>
        </div>

        <div class="row">
            <div class="col col-md-6">
                <label class="text-left w-100">{{$t('Free content options')}}</label>
                <ContentSelector ref="contentSelector" v-model="freeContentType" :disabled="disableSelect"></ContentSelector>
            </div>
            <div class="col-12">
                <component class="bg-light mt-3 p-3 animated fadeIn" v-if="freeContentType" :is="freeContentType" @input="onSubmitContent"></component>
                <p v-if="freeContentType" class="m-0"><i class="fa fa-caret-down fa-2x text-info" aria-hidden="true"></i></p>
            </div>
            <div class="col-12 mt-2">
                <label class="text-left w-100">{{$t('Content')}} <span class="badge badge-outline salmon float-right">{{ `${contentMessage.freeContent.length} / ${max}` }}</span></label>
                <table class="table table-hover table-bordered bg-light m-0">
                    <draggable v-model="contentMessage.freeContent" tag="tbody">
                        <tr v-for="(content, index) in contentMap" :key="index" class="animated fadeIn" colspan="">
                            <div class="row p-2">
                                <div class="col-auto d-flex align-items-center">
                                    <span class="badge badge-outline" v-if="content.media"><i class="fa fa-play-circle" aria-hidden="true"></i> {{$t('Media')}}</span>
                                    <span class="badge badge-outline" v-else><i class="fa fa-file-text-o" aria-hidden="true"></i> {{$t('Text')}}</span>
                                </div>
                                <div class="col text-left d-flex align-items-center overflow-hidden">
                                    <div v-if="content.media" class="preview">
                                        <MediaContentPreview :data="content.value"></MediaContentPreview>
                                    </div>
                                    <small v-else class="line-clamp" :title="content.value">{{content.value}}</small>
                                </div>
                                <div class="col-auto d-flex align-items-center">
                                    <el-button @click="remove(index)" type="danger" circle size="mini" icon="el-icon-delete"></el-button>
                                </div>
                            </div>
                        </tr>
                        <tr v-if="contentMap.length == 0" colspan="">
                            <h6 class="my-2 text-muted">{{$t('Empty')}}</h6>
                        </tr>
                    </draggable>
                </table>
            </div>
            <div class="col">
                <fg-input type="hidden" v-model="contentMessage.freeContent.length" :error="errors.first('minContents', scope)" v-validate="'minContents'" :data-vv-scope="scope" name="minContents"></fg-input>
            </div>
        </div>

        <!-- <div class="col-12 col-md-6">
            <SMSCompatibles class="mt-3" v-model="richCard.sms_compatible"></SMSCompatibles>
        </div> -->
    </div>
</template>

<script>
import { MessageBox, Button, Table, TableColumn } from "element-ui";
import draggable from 'vuedraggable'

import SMSCompatibles from 'src/app-components/rcs/SMSCompatibles';
import ContentSelector from 'src/app-components/rcs/FreeContentSelector';
import MediaContent from 'src/app-components/rcs/free-content-types/MediaContent';
import MediaContentPreview from 'src/app-components/rcs/preview-elements/MediaContentPreview';
import TextContent from 'src/app-components/rcs/free-content-types/TextContent';

import VModelMixin from 'src/mixins/v-model-mixin.js';

export default {

    components: {
        SMSCompatibles,
        ContentSelector,
        MediaContent,
        MediaContentPreview,
        TextContent,
        draggable,
        [Button.name]: Button,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },

    props: {
        max: {
            type: Number,
            default: 3
        }
    },

    inject: {
        parentValidator: 'parentValidator'
    },

    mixins: [
        VModelMixin('contentMessage')
    ],

    data() {
        const VIDEO_EXTENTIONS = ['mp4', 'm4v', 'mpg', 'mpeg', 'webm']
        return {
            VIDEO_EXTENTIONS,
            scope: 'freeContent',
            contentMessage: {
                freeContent: []
            },
            freeContentType: null
        }
    },

    computed: {
        contentMap(){
            // Esta computada tiene unicamente fines visuales
            return this.contentMessage.freeContent.map( element => {
                let content = { value: '' , media: false };
                const keys = Object.keys(element.contentMessage);

                if (keys.includes('text')) {
                    content.value = element.contentMessage.text
                } else {
                    content.value = element.contentMessage[keys[0]].fileUrl;
                    content.media = true;
                }
                return content
            })
        },

        disableSelect(){
            return this.contentMessage.freeContent.length >= this.max
        }
    },

    created() {
        this.$validator = this.parentValidator

        // At least one content
        this.$validator.extend('minContents', {
            validate: (count) => {
                return count > 0 && count < 4
            },
            getMessage: () => this.$t('You need at least 1 content and 3 as much.')
        });
    },

    methods: {
        contentFormatter(content){
            if (Object.keys(content).includes('text')) {
                return content.text
            }
            return "Es otra cosa"
        },

        onSubmitContent($event){
            // this.freeContentType = $event
            this.contentMessage.freeContent.push($event)
            this.$refs.contentSelector.clear()
        },

        remove(index){
            if (index > -1) {
                MessageBox.confirm(this.$t('This will delete the content permanently. Continue ?') , this.$t('Warning'), {
                    confirmButtonText: this.$t('Confirm'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then( () => {
                    this.contentMessage.freeContent.splice(index, 1);
                }).catch( () => null )
            }
        }
    },
    
}
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    padding: 0;
}

:deep(.preview) {
    img, video {
        max-height: 150px;
    }
}

.badge {
    min-width: 70px;
}


:deep(th) {
    text-align: left;
    height: 36px !important;
    padding: 12px 7px !important;
}

:deep(td) {
    padding: 5px 7px !important
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
}

.overflow-hidden{
    overflow: hidden;
}
</style>