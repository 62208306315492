<template>
    <div>
        <form @submit.prevent="submit">
            <!-- <SectionHeader text="Filtros" /> -->
            <div class="card d-flex flex-md-row flex-sm-column" :class="{'animated' : errors.all(scope).length > 0}" style="overflow-x: hidden">
                <!-- Form -->
                <div class="card-body flex-grow-1">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="mt-0">{{$t('Create query')}}</h4>
                        </div>
                        <div class="col-md-4 col-lg-3 col-xl-3">
                            <DateRangePicker
                                :auto-translate-shortcuts="true"
                                @date-range-changed="dateRange = $event"
                                ref="daterange"
                                v-validate="{ event_date_range: queryRequest.events.length == 0 && dateRange.length == 0}"
                                :data-vv-scope="scope"
                                name="daterange"
                                :error="getError(`${scope}.daterange`)"
                            />
                        </div>
                        <div class="col-md-3 col-lg-4 col-xl-4">
                            <div class="form-group has-label">
                                <label>{{$t('Events')}}</label>
                                <fg-input :error="getError(`${scope}.events`)">
                                    <el-select
                                        multiple
                                        filterable
                                        collapse-tags
                                        clearable
                                        size="large"
                                        :placeholder="$t('Select')"
                                        v-model="queryRequest.events"
                                        v-validate="{ required: dateRange.length == 0 || queryRequest.status.length == 0}"
                                        :data-vv-scope="scope"
                                        name="events"
                                    >
                                        <el-option
                                            v-for="o in events"
                                            :value="o.id"
                                            :key="o.name"
                                            :label="$t(o.name)"
                                        >{{o.name}}</el-option>
                                    </el-select>
                                </fg-input>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-5 col-xl-5">
                            <div class="form-group has-label">
                                <label>{{$t('Status')}}</label>
                                <fg-input :error="getError(`${scope}.status`)">
                                    <el-select
                                        filterable
                                        multiple
                                        clearable
                                        size="large"
                                        :placeholder="$t('Select')"
                                        v-model="queryRequest.status"
                                        v-validate="{ event_status: queryRequest.events.length == 0 && queryRequest.status == 0}"
                                        :data-vv-scope="scope"
                                        name="status"
                                    >
                                        <el-option
                                            v-for="o in eventStatus"
                                            :value="o.id"
                                            :key="o.name"
                                            :label="$t(o.name)"
                                        >{{o.name}}</el-option>
                                    </el-select>
                                </fg-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a href="javascript:void(0)" @click="clearForm">
                            <i class="fa fa-times" aria-hidden="true"></i> {{$t('Clear form')}}
                        </a>
                    </div>
                </div>
                <!-- Submit button -->
                <p-button class="m-0 vertical-btn" type="primary" nativeType="submit">
                    <i class="fa fa-check fa-lg"></i>
                </p-button>
            </div>
        </form>

        <div class="row">
            <div class="col-12">
                <div class="card" v-loading="loading" :element-loading-text="$t('Loading')">
                    <div class="card-body">
                        <CrmEventsTable
                            :tableData="indexData"
                            :events="events"
                            :eventStatus="eventStatus"
                            @copyquery="copyQuery($event)"
                        ></CrmEventsTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionHeader from "src/app-components/form-elements/SectionHeader";
import { DatePicker, TimeSelect, Input } from "element-ui";
import { EventBus } from "src/eventBus";

import DateRangePickerFormComponent from "src/app-components/form-elements/DateRangePicker";
import CrmEventsTable from "src/app-components/crm/events/CrmEventsTable";

import moment from "moment";
// import { cloneDeep } from "lodash";
import api from "src/api";
import { CrmMixin, EventsValidations } from "src/app-components/crm/CrmMixin";

export default {
    props: ["events"],
    
    components: {
        SectionHeader,
        CrmEventsTable,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Input.name]: Input,
        DateRangePicker: DateRangePickerFormComponent,
    },

    mixins: [ CrmMixin, EventsValidations ],

    data() {
        const scope = "events";

        return {
            // Misc
            scope,
            loading: false,
            // Filtros de busqueda seleccionados
            dateRange: [],
            // Objetos de datos
            // events: [],
            eventStatus: [
                {
                    name: "Request",
                    id: 0,
                },
                {
                    name: "Sent",
                    id: 2,
                },
                {
                    name: "Delivered",
                    id: 3,
                },
                {
                    name: "Accepted",
                    id: 4,
                },
                { name: "Browser Launched" , id: 24 },
            ],
            indexData: [],
            queryResponse: [],
            queryRequest: {
                events: [],
                status: [],
                date_from: "",
                date_to: "",
            },
        };
    },

    beforeMount() {
        // this.$validator.reset();
        EventBus.$on("crm-eventos-lambda", async (obj) => {
            console.log(obj);
            this.getCrmEventsIndex();
        });
    },

    beforeDestroy() {
        EventBus.$off("crm-eventos-lambda");
    },

    async mounted() {
        // this.getEvents(); // Completa el select de eventos
        this.getCrmEventsIndex(); // Obtiene todas las tareas
    },

    watch: {
        dateRange: function (val) {
            if (val == null) {
                this.dateRange = []
            }
        },
    },

    watch: {
        dateRange: function (val) {
            if (val == null) this.dateRange = []
        }
    },

    computed: {
        mandatoryFields: function () {
            return  [
                { name : 'events'    , value : this.queryRequest.events, mandatory : true , rules: 'required' },
                { name : 'status'    , value : this.queryRequest.status, mandatory : false, rules: 'event_status' },
                { name : 'daterange' , value : this.dateRange          , mandatory : false, rules: 'event_date_range' }
            ]
        }
    },

    methods: {

        /* async getEvents() {
            // Obtiene todos los eventos
            const res = await api.getApiEvents();
            this.events = res.data;
        }, */

        async getCrmEventsIndex() {
            // Obtiene todas las tareas
            this.loading = true;
            const res = await api.getCrmEventsIndex();
            this.indexData = res.data;
            this.loading = false;
        },

        prepareDates() {
            if (this.dateRange != 0 && this.dateRange != null) {
                this.queryRequest.date_from = moment(new Date(this.dateRange[0])).format("YYYY-MM-DD 00:00");
                this.queryRequest.date_to = moment(new Date(this.dateRange[1])).format("YYYY-MM-DD 23:59:59:999");
            } else {
                this.queryRequest.date_from = "",
                this.queryRequest.date_to = ""
            }
        },

        async postQuery() {
            const data = {
                function_id: 2,
                user_query: JSON.parse(JSON.stringify(this.queryRequest)),
            };
            const res = await api.createCrmEventsQuery(data);
            this.indexData.push(res.data);
        },

        async submit() {
            this.loading = true;
            const isValid = await this.validateBeforeSubmit(this.scope, this.mandatoryFields, 1);
            
            try {
                if (isValid) {
                    this.prepareDates();
                    await this.postQuery();
                    this.clearForm();
                }
                this.notification(isValid);
            } finally {
                this.loading = false;
            }
            
            // console.log("ERRORS",await this.$validator.errors.collect(null, this.scope));
        },

        copyQuery(query) {
            this.clearForm();

            this.copyFields(query.user_query, this.queryRequest);
            
            this.$notify({
                title: this.$t("Query ID: " + query.id),
                message: this.$t("Query params have been copied."),
                icon: "fa fa-copy",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "info",
            });
        },

        // Override
        copyDateRange(user_query) {
            // const validDateTime = moment(user_query.fecha_desde).isValid() && moment(user_query.fecha_hasta).isValid();
            const validDateTime = moment(user_query.date_from).isValid() && moment(user_query.date_to.slice(0, -2)).isValid();
            console.log("VALID",validDateTime);
            this.dateRange = [];
            if (validDateTime) {
                this.dateRange[0] = user_query.date_from;
                this.dateRange[1] = user_query.date_to.slice(0, -2);
            } else {
                this.queryRequest.date_from = "";
                this.queryRequest.date_to = "";
            }
            this.$refs.daterange.setDaterange(this.dateRange);
        },

        clearForm() {
            this.dateRange = [];
            this.$refs.daterange.setDaterange(null);
            this.clearFields(this.queryRequest, this.scope, this.$validator.id)
        },
    },
};
</script>