<template>
    <Slide
            disableOutsideClick
            @closeMenu="$emit('closeMenu')"
            right
            :isOpen="isOpen"
            :burgerIcon="false"
            :crossIcon="true">
        <div style="display: block;">
            <!-- Este div adicional es para evitar algunos estilos que no nos ayudan -->
            <slot></slot>
        </div>
    </Slide>
</template>
<script>
    import BurgerMenu from "src/custom-components/UIComponents/BurgerMenu";
    export default {
        components: {
            "Slide": BurgerMenu.Slide,
        },
        props: {
            isOpen: Boolean
        }
    }
</script>
<style scoped>
    :deep(label) {
        font-size: 0.6571em;
    }
</style>
