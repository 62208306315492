<template>
  <card>
    <div class="card-header">
      <div class="row">
        <div class="col-auto align-self-center">
          <i :class="`nc-icon nc-refresh-69 ${refreshing ? 'rotating' : ''}`" style="font-size: 2em"></i>
        </div>
        <div class="col">
          <h4 class="card-title">Events</h4>
        </div>
        <div class="col-auto align-self-center">
          <div class="text-right"><p-button type="danger" size="sm" @click="clear">Clear</p-button></div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-table :data="tableData">
        <el-table-column label="Date" property="date"></el-table-column>
        <el-table-column label="Time" property="time"></el-table-column>
        <el-table-column label="Action" property="action"></el-table-column>
        <el-table-column label="From" property="from"></el-table-column>
        <el-table-column label="To" property="to"></el-table-column>
        <el-table-column label="Message">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.msg">
              <i class="fa fa-search"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="UDH" property="udh"></el-table-column>
      </el-table>
    </div>
  </card>
</template>

<script>
  import {Table, TableColumn} from 'element-ui'
  import {Card} from 'src/components/UIComponents'
  import api from 'src/api'
  import {Tooltip} from 'element-ui'

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Card,
      [Tooltip.name]: Tooltip
    },
    mounted () {
    },
    props: {
      request: null
    },
    watch: {
      request () {
        this.startRefreshLoop();
      }
    },
    methods: {
      clear () {
        this.tableData = [];
      },
      startRefreshLoop () {
        window.clearInterval(this.timer);
        this.timer = window.setInterval(async () => {
          if (!this.refreshing) {
            this.refreshing = true;
            try {
              await this.refresh();
            } finally {
              this.refreshing = false;
            }
          }
        }, 3000);
      },
      async refresh () {
        const response = await api.getSmppGatewayAccessLogForMsisdn(this.request.msisdn, { page: 0, use_selected_account: 1, index: "KannelAccessLogIndex" });
        this.tableData = response.data.data;
      }
    },
    data () {
      return {
        tableData: [],
        timer: null,
        refreshing: false
      }
    }
  };
</script>

<!-- use Spinner.vue (?) -->
<style scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
</style>