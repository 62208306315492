<template>
    <div class="w-100 wrapper-items">
        <ul class="items" :class="[widthClass, heightClass]">
            <li
                class="item"
                v-for="(content, index) in data.richCard.carouselCard.cardContents"
                :key="index"
            >
                <RichCardPreview
                    :class="idClass"
                    :data="content"
                    :carouselTruncation="true"
                ></RichCardPreview>
            </li>
        </ul>
    </div>
</template>

<script>
import RichCardPreview from "src/app-components/rcs/preview-elements/RichCardPreview.vue";
import Slider from "src/utils/slider-carousel.js";

const uniqid = require('uniqid');

export default {
    components: {
        RichCardPreview,
    },

    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },

    mixins: [Slider()],

    data() {
        return {
            idClass: uniqid('carousel-')
        };
    },

    computed: {
        preview(){
            this.data
        },
        cardContents(){
            return this.data.richCard.carouselCard.cardContents
        },
        widthClass() {
            return `${this.data.richCard.carouselCard.cardWidth.toLowerCase()}-width`;
        },
        heightClass() {
            for (let index = 0; index < this.cardContents.length; index++) {
                const element = this.cardContents[index];
                if ('media' in element) {
                    return `${element.media.height.toLowerCase()}-height`
                }
            }
            return ''
        }
    },

    watch: {
        cardContents: {
            immediate: true,
            deep: true,
            handler() {
                setTimeout(() => {
                    //Grab divs with the class name 'match-height'
                    var getDivs = document.getElementsByClassName(this.idClass);
                    for (var i = 0; i < getDivs.length; i++) {
                        getDivs[i].style.height = 'inherit';
                    }

                    //Find out how my divs there are with the class 'match-height' 
                    var arrayLength = getDivs.length;
                    var heights = [];

                    //Create a loop that iterates through the getDivs variable and pushes the heights of the divs into an empty array
                    for (var i = 0; i < arrayLength; i++) {
                        heights.push(getDivs[i].style.offsetHeight);
                    }

                    //Find the largest of the divs
                    function getHighest() {
                        return Math.max(...heights);
                    }

                    //Set a variable equal to the tallest div
                    var tallest = getHighest();

                    //Iterate through getDivs and set all their height style equal to the tallest variable
                    for (var i = 0; i < getDivs.length; i++) {
                        getDivs[i].style.height = tallest + "px";
                    }
                }, 100);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
:root {
    --card-width: 120px;
    --max-card-height: 264px
}

.small-width {
    --card-width: 120px;
}

.medium-width {
    --card-width: 174px;
}

:deep(.rcs-card),
:deep(.content) {
    max-width: var(--card-width);
}

:deep(.tall-height) {
    
    .title, .text {
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 5;
    }
    .rcs-card:not(.noMedia){
        .title, .text {
            white-space: nowrap;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical; 
        }
    }
}

:deep(.suggestion) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

.wrapper-items {
    position: relative;
}

.items {
    position: relative;
    width: 100%;
    overflow-x: auto;
    // white-space: nowrap;
    cursor: pointer;
    padding-bottom: 5px;
    display: flex;
    place-items: flex-end;

    &.active {
        cursor: grab;
    }

    &.tall-height {
        max-height: 264px;
    }
}

:deep(.rcs-card) {
    height: 100%;
    max-height: 260px;
}

.item {
    display: inline-block;
    margin-right: 15px;
    user-select: none;
    min-width: var(--card-width);
    max-width: var(--card-width);

    &:last-child {
        margin-right: 0;
    }
}
</style>