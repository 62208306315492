<template>
  <div
    class="row align-items-end my-2"
    :class="[errors.any(scope) ? 'col-with-errors' : '']"
  >
    <div class="text-left col">
      <label>{{ $t("Text") }}</label>
      <fg-input :error="errors.first('text', scope)">
        <el-input
          size="mini"
          v-model="action.text"
          name="text"
          :data-vv-scope="scope"
          v-validate="'required'"
          :maxlength="25"
          show-word-limit
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col">
      <label>{{ $t("Phone Number") }}</label>
      <fg-input :error="errors.first('tel', scope)">
        <el-input
          size="mini"
          v-model="action.dialAction.phoneNumber"
          name="tel"
          :data-vv-scope="scope"
          data-vv-as="phone number"
          v-validate="{ required: true, regex: phone_regex }"
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col-auto">
      <el-button size="mini" type="primary" @click="submit">{{
        $t("Confirm")
      }}</el-button>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { Input, Button } from "element-ui";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
  },

  data() {
    const scope = `dial-${uniqid()}`;
    const phone_regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return {
      scope,
      phone_regex,
      action: {
        text: "",
        postbackData: "",
        dialAction: {
          phoneNumber: "",
        },
      },
    };
  },
  props: {
    editing_prop: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (this.editing_prop) {
      if (this.editing_prop.action) {
        this.action = this.editing_prop.action;
      }
    } else {
      this.action = {
        text: "",
        postbackData: "",
        dialAction: {
          phoneNumber: "",
        },
      };
    }
  },

  methods: {
    async submit() {
      if (await this.$validator.validate(`${this.scope}.*`)) {
        if (this.editing_prop) {
          this.$emit("edit", { action: this.action });
        } else {
          this.$emit("submit", { action: this.action });
        }
        this.clear();
      }
    },

    clear() {
      this.action = {
        text: "",
        postbackData: "",
        dialAction: {
          phoneNumber: "",
        },
      };
      this.$validator.reset(this.scope);
    },
  },
};
</script>
