<template>
  <el-dialog
    top="5vh"
    width="65%"
    :title="name"
    :show-close="false"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="clearData"
  >

  <div v-if="this.nodeOptions && this.typeNode === 'Integration'" >
    <form-integration 
      :integration="integration" 
      :variables="variables" 
      :idOption="nodeOptions.id" 
      :validate="validate"
      @updateValid="validated.integration = $event"
      @updateData="updateData($event, 'integration')" 
    />
  </div>

  <el-tabs v-model="activeName" v-if="this.nodeOptions && this.typeNode !== 'Integration'">
    <el-tab-pane label="Messages" name="messages" v-if="tabsOptions.messages" class="invalid-tab">
      <form-messages 
        :messages="messages"
        :limit="limitMessages" 
        :variables="variables"
        :validate="validate"
        @updateValid="validated.messages = $event"
        @updateData="updateData($event, 'messages')" 
        @updateConnectionNodes="updateConnectionNodes"
      />
    </el-tab-pane>
    
    <el-tab-pane label="Options" name="options" v-if="tabsOptions.options">
      <form-options 
        :infoFallback="infoFallback"
        :options="options"
        :typeOfOptions="typeOfOptions"
        :variables="variables"
        :validate="validate"
        @updateValid="validated.options = $event"
        @updateDataOptions="updateData($event, 'options')" 
        @updateDataType="updateData($event, 'typeOfOptions')" 
        @createOutput="createOutputCount++"
        @deleteOutput="setDeleteOutput($event)"
        @positionOptionsEdited="positionOptionsEdited = true"
      />
    </el-tab-pane>
    
    <el-tab-pane label="Conditions" name="conditions" v-if="tabsOptions.conditions">
      <form-condition 
        :infoFallback="infoFallback"
        :options="options"
        :variables="variables"
        @updateData="updateData($event, 'options')" 
        @createOutput="createOutputCount++"
        @deleteOutput="setDeleteOutput($event)"
        @positionOptionsEdited="positionOptionsEdited = true"
      />
    </el-tab-pane>
    
    <el-tab-pane label="Data storage" name="datastorage" v-if="tabsOptions.dataStorage">
      <form-storage 
        :storage="storage" 
        :variables="variables"
        :validate="validate"
        @updateValid="validated.dataStorage = $event"
        @updateData="updateData($event, 'storage')" 
      />
    </el-tab-pane>
    
    <el-tab-pane label="Global scope" name="globalscope" v-if="tabsOptions.globalScope">
      <form-global-scope
        :globalScope="global_scope"
        @updateData="updateData($event, 'global_scope')" 
      />
    </el-tab-pane>
  </el-tabs>


    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
      <el-button size="small" type="primary" @click="saveData">{{ $t("Save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { clone } from "lodash";
  import { EventBus } from "src/eventBus.js";
  import { Dialog, Button, Tabs, TabPane } from "element-ui";
  import FormMessages from "src/app-components/chatbot/configuration/components/FormMessages";
  import FormOptions from "src/app-components/chatbot/configuration/components/FormOptions";
  import FormStorage from "src/app-components/chatbot/configuration/components/FormStorage";
  import FormConditions from "src/app-components/chatbot/configuration/components/FormConditions";
  import FormGlobalScope from "src/app-components/chatbot/configuration/components/FormGlobalScope";

  import FormIntegration from "src/app-components/chatbot/configuration/components/FormIntegration";

  export default {
    name: "ModalOptions",
    props: ["editor", "variables"],
    components: {
      [Dialog.name]: Dialog,
      [Button.name]: Button,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      "form-messages": FormMessages,
      "form-options": FormOptions,
      "form-storage": FormStorage,
      "form-condition": FormConditions,
      "form-global-scope": FormGlobalScope,

      "form-integration": FormIntegration,
    },

    data() {
      return {
        dialogVisible: false,
        nodeOptions: null,

        activeName: "messages",

        createOutputCount: 0,
        deleteOutputList: [],

        positionOptionsEdited: false,

        validate: false,
        validated: {
          messages: false,
          options: false,
          dataStorage: false,
          integration: false,
        }
      };
    },

    mounted() {
      EventBus.$on("activeOptions", (id) => {
        this.nodeOptions = clone(this.editor.getNodeFromId(id));

        this.dialogVisible = true;
      });
    },

    methods: {
      clearData() {
        this.nodeOptions = null;
        this.createOutputCount = 0;
        this.deleteOutputList = [];
        this.activeName = "messages";
        this.positionOptionsEdited = false;
      },

      updateData(data, param) {
        this.nodeOptions.data[param] = clone(data);
      },

      invalidTabs(set = false) {
        let messages = document.querySelector("#tab-messages");
        let options = document.querySelector("#tab-options");
        let dataStorage = document.querySelector("#tab-datastorage");
        let integration = document.querySelector("#tab-integration");

        if(messages) {
          if(set) {
            if(!this.validated.messages) messages.classList.add("invalid");
          }else {
            if(messages.classList.contains("invalid")) messages.classList.remove("invalid");
          }
        }
        if(options) {
          if(set) {
            if(!this.validated.options) options.classList.add("invalid");
          }else {
            if(options.classList.contains("invalid")) options.classList.remove("invalid");
          }
        }
        if(dataStorage) {
          if(set) {
            if(!this.validated.dataStorage) dataStorage.classList.add("invalid");
          }else {
            if(dataStorage.classList.contains("invalid")) dataStorage.classList.remove("invalid");
          }
        }
        if(integration) {
          if(set) {
            if(!this.validated.integration) integration.classList.add("invalid");
          }else {
            if(integration.classList.contains("invalid")) integration.classList.remove("invalid");
          }
        }
      },

      saveData() {
        if(!this.isValid) {
          this.validate = true;
          setTimeout(() => {
            this.invalidTabs(true);
          }, 50);

          setTimeout(() => {
            this.validate = false;
            this.invalidTabs(false);
          }, 5000);
          return;
        }


        

        let connections = clone(this.editor.getNodeFromId(this.nodeOptions.id).data.connections);
        if(connections) {
          this.nodeOptions.data.connections = connections;


          if(this.nodeOptions.data.options) {
            let options = this.nodeOptions.data.options;
            if(options.length) {
              if(options[options.length - 1].message !== "") {
                let index = connections.findIndex(c => c.idOption === options[options.length - 1].id);
                if(index !== -1) {
                  this.nodeOptions.data.connections.splice(index, 1);
                  this.positionOptionsEdited = true;
                }
              }
            }
          }
        }

        EventBus.$emit("changeNodeData", {
          id: `node-${this.nodeOptions.id}`,
          data: this.nodeOptions.data,
          positionOptionsEdited: this.positionOptionsEdited,
        });

        EventBus.$emit("updateDataComponent", { 
          id: this.nodeOptions.id, 
          data: clone(this.nodeOptions),
        });


       /*  if(this.deleteOutputList.length) {
          this.deleteOutputList.forEach(output => {
            this.deleteOutput(output);
          })
        } */

        if(this.createOutputCount) {
          for (let i = 0; i < this.createOutputCount; i++) {
            this.createOutput();
          }
        }

        if(this.deleteOutputList.length) {
          this.deleteOutputList.forEach(output => {
            this.deleteOutput(output);
          })
        }

        this.dialogVisible = false;
      },

      createOutput() {
        this.editor.addNodeOutput(this.nodeOptions.id);
      },
      
      deleteOutput(output) {
        try {
          this.editor.removeNodeOutput(this.nodeOptions.id, output);
        } catch (error) {
          console.log("cachai")
        }
      },

      setDeleteOutput(output) {
        this.deleteOutputList.push(output);
      },
      
      updateConnectionNodes() {
        EventBus.$emit("updateConnectionNodes", this.nodeOptions.id);
      },
    },

    computed: {
      infoFallback() {
        return "If you configure a fallback message, the message will be sent automatically, and then the user will be asked for the information again";
      },

      name() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.name;
        }

        return "";
      },
      
      messages() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.messages;
        }

        return [];
      },
      
      options() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.options;
        }

        return [];
      },
      
      integration() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.integration;
        }

        return {};
      },
      
      typeOfOptions() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.typeOfOptions;
        }

        return "";
      },
      
      storage() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.storage;
        }

        return {};
      },
      
      global_scope() {
        if(this.nodeOptions) {
          return this.nodeOptions.data.global_scope;
        }

        return [];
      },
      
      typeNode() {
        if(this.nodeOptions) {
          return this.nodeOptions.class;
        }

        return null;
      },

      limitMessages() {
        if(this.typeNode !== "Message") {
          return 1
        };
        return 3;
      },

      isValid() {
        if(this.tabsOptions.messages) {
          if(!this.validated.messages) return false;
        }
        if(this.tabsOptions.options) {
          if(!this.validated.options) return false;
        }
        if(this.tabsOptions.dataStorage) {
          if(!this.validated.dataStorage) return false;
        }
        if(this.tabsOptions.integration) {
          if(!this.validated.integration) return false;
        }

        return true;
      },

      tabsOptions() {
        let tabs = {
          messages: true,
          options: false,
          dataStorage: false,
          conditions: false,
          globalScope: true,
          integration: false,
        }

        switch(this.typeNode) {
          case "MessageWithOptions":
            tabs.options = true;
            break;
          case "DataStorage":
            tabs.dataStorage = true;
            tabs.globalScope = false;
            break;
          case "Condition":
            this.activeName = "conditions";
            tabs.messages = false;
            tabs.conditions = true;
            tabs.globalScope = false;
            break;
          case "Integration":
            this.activeName = "integration";
            tabs.integration = true;
            tabs.messages = false;
            tabs.globalScope = false;
            break;
        }

        return tabs;
      }
    }
  };
</script>