<template>
    <scenario-call-to-action-elements
            v-show="callToActionFieldsCount > 0"
            v-model="local"
            :scenario="scenarioId"
            :disabled="! isEditable"
            width="col-md-6"
            @schema-changed="onCampaignSchemaChanges"
    >
        <template slot="title">
            <!--
            <h5 class="text-left" style="font-size: 1rem">{{ $t('Call to action') }}</h5>
            -->
            <SectionHeader style="visibility: hidden" text="Call to action" />
        </template>
    </scenario-call-to-action-elements>
</template>

<script>
    import { Collapse } from 'src/components/UIComponents'
    import CollapseItem from "src/custom-components/UIComponents/CollapseItem";
    import ScenarioRequiredElements from 'src/app-components/form-elements/generators/ScenarioRequiredElements'
    import ScenarioCreativitiesElements from 'src/app-components/form-elements/generators/ScenarioCreativitiesElements'
    import ScenarioCallToActionElements from 'src/app-components/form-elements/generators/ScenarioCallToActionElements'
    import ScenarioOptionalElements from 'src/app-components/form-elements/generators/ScenarioOptionalElements'
    import SectionHeader from "src/app-components/form-elements/SectionHeader";

    export default {
        name: 'campaign-flow-call-to-action',

        props: {
            value: Object,
            scenarioId: Number,
            isEditable: {
                type: Boolean,
                default: true
            }
        },

        components: {
            Collapse,
            CollapseItem,
            [ScenarioCreativitiesElements.name]: ScenarioCreativitiesElements,
            [ScenarioRequiredElements.name]: ScenarioRequiredElements,
            [ScenarioOptionalElements.name]: ScenarioOptionalElements,
            [ScenarioCallToActionElements.name]: ScenarioCallToActionElements,
            SectionHeader
        },

        data() {
            return {
                retrieved_fields: {
                    'scenario-call-to-action-elements': {
                        counted: 0
                    },
                },
            }
        },

        computed: {
            local: {
                get() {
                    return this.value ? this.value : {};
                },

                set(nv, ov) {
                    this.$emit("input", nv)
                }
            },

            callToActionFieldsCount() {
                return this.retrieved_fields['scenario-call-to-action-elements'].counted;
            },
        },

        methods: {
            onCampaignSchemaChanges(component, length) {
                if (component === undefined || length === undefined) {
                    return;
                }

                this.retrieved_fields[component].counted = length;
            }
        }
    }
</script>

<style scoped>
    :deep(a:hover) {
        text-decoration: unset;
    }

    :deep(.card-collapse .card .card-header::after) {
        background-color: unset;
    }

    :deep(.card-collapse .card .card-body) {
        padding: unset;
    }
</style>