<template>
    <div>
        <div class="row">
            <div class="col-12 col-md text-left">
                <h6>{{ $t("Rich Card configuration") }}</h6>
            </div>
        </div>
        <CardContent 
        :isEditable="isEditable" 
        v-model="contentMessage.richCard.standaloneCard.cardContent" 
        :orientation="contentMessage.richCard.standaloneCard.cardOrientation">

            <template slot="properties">
                <div class="row mt-1">
                    <div class="col-12 col-md-6">
                        <label class="w-100 text-left">{{$t('Card orientation')}}</label>
                        <el-select v-model="contentMessage.richCard.standaloneCard.cardOrientation" :placeholder="$t('Select orientation')" class="w-100" size="mini">
                            <el-option
                                v-for="option in CARD_ORIENTATION"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-12 col-md-6" v-if="contentMessage.richCard.standaloneCard.cardOrientation == 'HORIZONTAL'">
                        <label class="w-100 text-left">{{$t('Image alignment')}}</label>
                        <el-select v-model="contentMessage.richCard.standaloneCard.thumbnailImageAlignment" :placeholder="$t('Select alignment')" class="w-100" size="mini">
                            <el-option
                                v-for="option in IMAGE_ALIGNMENT"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </template>

        </CardContent>
    </div>
</template>

<script>
import CardContent from 'src/app-components/rcs/CardContent';
import VModelMixin from 'src/mixins/v-model-mixin.js'

export default {
    name: 'RichCard',

    props:{
        isEditable: {
            type: Boolean,
            default: true
        }
    },

    inject: {
        parentValidator: 'parentValidator'
    },

    components: {
        CardContent
    },
    
    mixins: [
        VModelMixin('contentMessage')
    ],

    data() {
        const CARD_ORIENTATION = [
            // { label : "Unspecified" , value : "CARD_ORIENTATION_UNSPECIFIED"},
            { label : "Horizontal" , value : "HORIZONTAL" },
            { label : "Vertical"   , value : "VERTICAL"   }
        ];
        const IMAGE_ALIGNMENT = [
            { label : "Left"  , value : "LEFT"  },
            { label : "Right" , value : "RIGHT" }
        ];
        return {
            CARD_ORIENTATION,
            IMAGE_ALIGNMENT,
            contentMessage: {
                richCard: {
                    standaloneCard: {
                        cardOrientation: 'VERTICAL',
                        thumbnailImageAlignment: 'LEFT',
                        cardContent: {
                            title: '',
                            description: '',
                            // media: {
                            //     height: 'SHORT',
                            //     contentInfo: {
                            //         fileUrl: '',
                            //         thumbnailUrl: '',
                            //         forceRefresh: false
                            //     }
                            // },
                            suggestions: []
                        }
                    }
                }
            },
        }
    },

    mounted() {
        this.$validator = this.parentValidator
    },

    methods: {
    },
}
</script>