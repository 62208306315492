<template>
  <Form @submit="onSave">
      <p>{{ $t("Define the days and hours allowed to send any campaign") }}</p>
      <BlackoutSliders v-if="blackoutData != null" v-model="blackoutData" />
      <div class="row justify-content-end">
        <div class="col-auto">
          <ExceptEvents v-model="exceptEvents" />
        </div>
        <div class="col-auto">
          <ExceptPing v-model="exceptPing" />
        </div>
      </div>
  </Form>
</template>

<script>
  import BlackoutSliders from "src/app-components/BlackoutSliders"
  import ExceptPing from "src/app-components/form-elements/ExceptPing"
  import ExceptEvents from "src/app-components/form-elements/ExceptEvents"
  import Form from "src/app-components/Form"
  import api from 'src/api'
  import moment from 'moment'
  import lodash from 'lodash'
  import { updatableForm } from 'src/app-components/FormMixin'

  export default {
    components: {
      BlackoutSliders,
      Form,
      ExceptPing,
      ExceptEvents
    },
    mixins: [
      updatableForm
    ],
    provide() {
        return {
            parentValidator: this.$validator,
        };
    },
    watch: {
      entity () {
        this.blackoutData = this.blackoutApiToFrontend(this.entity.accountBlackoutTimes)
        this.exceptPing = this.entity.accountBlackoutTimes.some(b => b.except_ping == 1)
        this.exceptEvents = this.entity.accountBlackoutTimes.some(b => b.except_events == 1)
      }
    },
    methods: {
      async onSave () {
        const accountBlackoutTimes = this.blackoutFrontendToApi(this.blackoutData);
        await api.updateAccount(this.$store.getters.appUser.selected_account_id, {
          accountBlackoutTimes
        })
        this.$notify({
          message: this.$t("Blackout updated succesfully!"),
          type: 'success'
        });
      },
      dayIntToStr(i) {
        switch (i) {
          case 0: return "sun"
          case 1: return "mon"
          case 2: return "tue"
          case 3: return "wed"
          case 4: return "thu"
          case 5: return "fri"
          case 6: return "sat"
        }
      },
      dateTimeStrToMinutes (str) {
        return this.roundToNearestHalfHour(moment.utc(`1970-01-01 ${str}`).unix() / 60)
      },
      minutesToDateTimeStr (min, sub = 0) {
        return moment.unix(min * 60).utc().subtract(sub).format("HH:mm:ss");
      },
      roundToNearestHalfHour (minutes) {
        return Math.round(minutes / 15) * 15
      },
      blackoutApiToFrontend (input) {
        const initial = {
          mon: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          tue: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          wed: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          thu: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          fri: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          sat: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
          sun: {
            range1: { start: 0, end: 0 },
            range2: { start: 1440, end: 1440 },
          },
        };
        const isSet = {
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        };
        return input.reduce((output, blackoutTime) => {
          const newOutput = lodash.cloneDeep(output)
          const dayProp = this.dayIntToStr(blackoutTime.dayofweek)
          const rangeObj = { start: this.dateTimeStrToMinutes(blackoutTime.from), end: this.dateTimeStrToMinutes(blackoutTime.to) }
          if (!isSet[dayProp]) {
            newOutput[dayProp].range1 = rangeObj
            isSet[dayProp] = true
          } else {
            const newRangeAfterLastRange = rangeObj.start >= newOutput[dayProp].range1.end
            if (newRangeAfterLastRange) {
              newOutput[dayProp].range2 = rangeObj
            } else {
              newOutput[dayProp].range2 = newOutput[dayProp].range1
              newOutput[dayProp].range1 = rangeObj
            }
          }
          return newOutput
        }, initial)
      },
      blackoutFrontendToApi (input) {
        const rangeToApi = (day, rangeProp) => {
          const dayStr = this.dayIntToStr(day);
          if (input[dayStr][rangeProp].start == input[dayStr][rangeProp].end) {
            return []
          }
          return [{
              dayofweek: day,
              from: this.minutesToDateTimeStr(input[dayStr][rangeProp].start),
              to: this.minutesToDateTimeStr(input[dayStr][rangeProp].end, 1),
              except_ping: this.exceptPing ? 1 : 0,
              except_events: this.exceptEvents ? 1 : 0,
            }]
        }
        return lodash.range(0, 7).reduce(( output, day ) => {
          return [
            ...output,
            ...rangeToApi(day, 'range1'),
            ...rangeToApi(day, 'range2'),
          ]
        }, [])
      }
    },
    data () {
      return {
        blackoutData: null,
        exceptPing: false,
        exceptEvents: false
      }
    },
  };
</script>
<style>
</style>