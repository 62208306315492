<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group has-label">
          <label>{{ $t("With ping?") }}</label>
          <br />
          <p-switch
            v-model="local.value"
            type="primary"
            on-text="ON"
            off-text="OFF"
            @input="onInput($event)"
            :disabled="disabled"
          ></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, tap, set } from "lodash";
import PSwitch from "src/custom-components/UIComponents/Switch";

export default {
  inject: ["getError", "parentValidator"],

  components: {
    [PSwitch.name]: PSwitch,
  },

  props: {
    value: {
      type: Object,
    },

    force: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    force(newVal, oldValue) {
      console.log('local value', this.local.value)
      this.onInput(newVal);
    },
  },

  computed: {
    local() {
      const id = 77;
      let defaultValue = {
        variable_id: id,
        value: false,
        variable: {
          name: "active-ping",
        },
      };
      if (!this.variables) return defaultValue;

      let value = this.variables.find((variable) => {
        if (variable.variable) {
          return variable.variable.name === "active-ping";
          // return variable.variable_id === 77;
        }
      });

      return !value ? defaultValue : value;
    },
  },

  methods: {
    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };
      this.$emit("input", input);
    },
  },
};
</script>
