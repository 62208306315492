<script>
import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
import api from "src/api";
import { cloneDeep } from "lodash";

export default {
  name: "scenario-creativities-elements",
  extends: ScenarioRequiredElements,

  props: {
    width: {
      type: String,
      default: "col-md-4",
    },
  },

  computed: {
    schemas() {
      return this.schema;
    },
  },

  methods: {
    async getSchema() {
      const scenarioType = await api.getScenarioType(this.scenario);
      this.schema = scenarioType.data.scenario.creativities;
    },

    updateForm(field, value, index) {
      const newValue = cloneDeep(this.local);

      if (newValue.variables) {
        if (newValue.variables[index]) {
          newValue.variables[index].value = value;
        }else {
          // newValue.variables[index] = { value };
          newValue.variables[index] = { 
          value,
          creative_field: field.creative_field,
          variable: { ...field }, 
        };
        }
      } else {
        newValue.variables = [];
        newValue.variables[index] = { 
          value,
          creative_field: field.creative_field,
          variable: { ...field }, 
        };
      }

      this.$emit("input", newValue);
    },
  },
};
</script>
