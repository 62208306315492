<script>
import api from "src/api";
import ConsolidatedCampaign from "./ConsolidatedCampaign";
import _ from 'lodash';
import Constants from 'src/views/campaigns/constants'
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "SatMultimedia",

    extends: ConsolidatedCampaign,

    created() {
        this.setTableCols();
        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            return scenario.scenario.sat_call_to_action_id == 1
        });
    },

    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        setWhere (query) {
            query.ql.where["campaign_type NOT IN"] = ["TEST", "EVENT"];
            query.ql.where["scenario_id IN"] = Constants.ScenarioTypesWithLaunchBrowser;
        },

        /**
         * @Override
         */
        setTableCols() {
          this.tableCols = [
            { label: "Campaign", hasLabel: true,  minWidth: 250 },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent_Ping", label: "Sent Ping", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives_Rate", label: "%Active", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Lb_Open", label: "Views", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Lb_Open_Rate", label: "%View", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Blacklisted", label: "Blacklisted", formatter: this.formatterNumber,  minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "URL", label: "URL", isHtml: true, width: 200, exportFormatter: this.formatterUrl },
          ];
        },

        formatterUrl (html) {
            const el = document.createElement( 'html' );
            el.innerHTML = html;
            
            const body = [ ...el.getElementsByTagName("body") ][0]
            const anchor = [ ...body.childNodes ][0]

            if (anchor.nodeType == 1 /* ELEMENT_NODE */) {
                return anchor.getAttribute("href")
            }

            return ""
        }
    }
}
</script>
