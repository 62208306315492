<template>
    <div>
        <div class="row">

            <div class="col-12">
                <b>{{$t('Output options')}}</b>
            </div>

            <div class="col-auto">
                <div class="form-group has-label">
                    <label>{{$t("Show unique MSISDNs")}}</label>
                    <br />
                    <p-switch
                        type="primary"
                        v-model="outputOptions.dump_unique"
                        :on-text="$t('YES')"
                        :off-text="$t('NO')"
                        @input="outputOptions.dump_unique ? outputOptions.split_campaign = false : ''"
                    ></p-switch>
                </div>
            </div>
            <div class="col-auto">
                <div class="form-group has-label">
                    <label>{{$t("Split campaign")}}</label>
                    <br />
                    <p-switch
                        :disabled="outputOptions.dump_unique"
                        type="primary"
                        v-model="outputOptions.split_campaign"
                        :on-text="$t('YES')"
                        :off-text="$t('NO')"
                    >
                    <i class="fa fa-ban animated fadeIn" style="color:#f56c6c;" slot="off" v-if="outputOptions.dump_unique"></i>
                    </p-switch>
                </div>
            </div>
            <div class="col-auto">
                <div class="form-group has-label">
                    <label>{{$t("Send me an email when finish")}}</label>
                    <br />
                    <p-switch
                        type="primary"
                        v-model="outputOptions.send_email"
                        :on-text="$t('YES')"
                        :off-text="$t('NO')"
                    ></p-switch>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group has-label">
                    <label>{{$t("Where to look?")}}</label>
                    <br/>
                    <el-select
                        v-model="outputOptions.campaign_data"
                        size="mini"
                        placeholder="Select"
                        :multiple="true"
                    >
                        <el-option v-for="o in campaign_data_options"
                            :disabled="o.disabled"
                            :value="o.value"
                            :label="o.label"
                            :key="o.value"
                        >
                            {{o.label}}
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PSwitch from "src/custom-components/UIComponents/Switch";
import createVModelMixin from "src/mixins/v-model-mixin.js";

export default {
    components: {
        [PSwitch.name]: PSwitch,
    },

    mixins: [
        createVModelMixin("outputOptions"),
    ],

    props: {
        channel: {
            type: Number|Object,
            default: null
        },
        isRcs: {
            type: Boolean,
            default: () => false,
        },
    },

    data(){
        return {
            outputOptions: {
                dump_unique: false,
                split_campaign: false,
                send_email: true,
                campaign_data: [ "dump_target" ]
            }
        }
    },

    computed: {
      campaign_data_options() {
        return [
          //{ label : this.$t("Show all data") , value : 0 },
          { label : this.$t("Campaign data")     , value : "dump_target" },
          { label : this.$t("Second Action data"), value : "dump_sa" },
          { label : this.$t("Fallback data"), value : "dump_fallback", disabled: this.isRcs ? false : true},
        ]
      }
    }
};
</script>