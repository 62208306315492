/**
 * Sidebar menu
 * 
 * Para controlar la visibilidad de cualquier entrada del menu, debemos definir
 * la propiedad "visible", la cual puede ser tener una valor booleano directamente
 * (ej: "visible": true), o puede ser una funcion que debe recibir otra funcion que
 * permita determinar si el usuario tiene un determinado permiso. Tipicamente usaremos
 * el visible de esta forma, y le pasaremos la funcion $can del plugin de abililties.
 */
export default [
	{
		"internal": true,
		"name": "Create campaign",
		"path": "/campaigns-manager/index",
		"icon": "dyn dyn-e-add-1",
		"menu": false,
		"active": false,
		"children": [],
		"visible": userCan => userCan('Index', 'CampaignManager'),
	},
	{
		"internal": false,
		"name": "My campaigns",
		"visible": userCan => (
			userCan('Planning', 'Campaigns') ||
			userCan('Index', 'Bookmarks') ||
			userCan('Planning', 'ApiEvents') ||
			userCan('Requests', 'Campaign')
		),
		"path": "#",
		"icon": "dyn dyn-spaceship",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Planning",
				"visible": userCan => userCan('Planning', 'Campaigns'),
				"path": "/#/campaigns/planning",
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Templates",
				"visible": userCan => userCan('Index', 'Bookmarks'),
				"path": "/#/bookmarks/index",
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Events",
				"visible": userCan => userCan('Planning', 'ApiEvents'),
				"path": "/#/api-events/planning",
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			/*{
				"internal": false,
				"name": "Requests",
				"visible": userCan => userCan('Requests', 'Campaign'),
				"path": "/campaign/requests",
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			}*/
		]
	},
	{
		"internal": false,
		"name": "Metrics",
		"visible": userCan => (
			userCan('Live', 'Dashboard') ||
			userCan('Pivot', 'Campaigns') ||
			userCan('Index', 'Dashboard')
		),
		"path": "#",
		"icon": "dyn dyn-statistics-10",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Dashboard",
				"path": "/#/dashboard/index",
				"visible": userCan => userCan('Index', 'Dashboard'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Live Campaigns",
				"path": "/#/dashboard/live",
				"visible": userCan => userCan('Live', 'Dashboard'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Stats",
				"path": "/#/campaigns/pivot",
				"visible": userCan => userCan('Pivot', 'Campaigns'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			}
		]
	},
	{
		"internal": false,
		"name": "Audience",
		"visible": userCan => (
			userCan('Index', 'Crm') ||
			userCan('Index', 'CustomerService') ||
			userCan('Index', 'SegmentationTool') ||
			userCan('CreateFromPing', 'Campaigns')
		),
		"path": "#",
		"icon": "dyn dyn-goal-65-2",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "CRM",
				"path": "/#/crm/index",
				"visible": userCan => userCan('Index', 'Crm'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Customer service",
				"path": "/#/customer-service/index",
				"visible": userCan => userCan('Index', 'CustomerService'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Ping campaigns",
				"path": "/#/campaigns/create-from-ping/add",
				"visible": userCan => userCan('CreateFromPing', 'Campaigns'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Services",
				"path": "/#/audience/services",
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Segmentation",
				"path": "/#/segmentation",
				"visible": userCan => userCan('Index', 'SegmentationTool'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			}
		]
	},
	{
		"internal": false,
		"name": "Settings",
		"visible": userCan => (
			userCan('Configuration', 'Account') ||
			userCan('Status', 'Connection') ||
			userCan('Agents', 'Rcs')
		),
		"path": "#",
		"icon": "dyn dyn-candlestick-chart-10",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Account configuration",
				"path": "#/account-configuration/index",
				"visible": userCan => userCan('Configuration', 'Account'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Connections",
				"path": "/#/connection/status",
				"visible": userCan => userCan('Status', 'Connection'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "RCS Agents",
				"path": "/#/rcs/agents",
				"visible": userCan => userCan('Agents', 'Rcs'),
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			/*{
				"internal": false,
				"name": "Dynamo Tools",
				"path": "#",
				"visible": userCan => (
					userCan('tim/segmentation') ||
					userCan('translation-source-message/index') ||
					userCan('device-detection/admin-tools') ||
					userCan('device-crm/search') ||
					userCan('loci-tools')
				),
				"icon": null,
				"menu": true,
				"active": false,
				"children": [
					{
						"internal": false,
						"name": "Tim Segmentation",
						"path": "/tim/segmentation",
						"icon": null,
						"menu": false,
						"active": false,
						"children": []
					}
				]
			}*/
		]
	},


	{
		"internal": false,
		"name": "Chatbots",
    "visible": userCan => userCan('Views', 'Chatbots'),
		"path": "#",
		"icon": "dyn dyn-robot",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Flows",
				"path": "#/chatbots/flows",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Analytics",
				"path": "#/chatbots/analytics",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Conversations",
				"path": "#/chatbots/conversations",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Dictionary",
				"path": "#/chatbots/dictionary",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
		]
	},
  {
		"internal": false,
		"name": "Services",
		"visible": userCan => userCan('Views', 'Services'),
		"path": "#",
		"icon": "dyn dyn-explore-user-1",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Services",
				"path": "#/services",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			}
		]
	},
  {
		"internal": false,
		"name": "Broadcast",
    "visible": userCan => userCan('Views', 'Broadcast'),
		"path": "#",
		"icon": "dyn dyn-explore-user-1",
		"menu": true,
		"active": false,
		"children": [
			{
				"internal": false,
				"name": "Templates",
				"path": "#/broadcast/templates",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Campaigns",
				"path": "#/broadcast/campaigns",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Integration",
				"path": "#/broadcast/integration",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
			{
				"internal": false,
				"name": "Analytics",
				"path": "#/broadcast/analytics",
				"visible": true,
				"icon": null,
				"menu": false,
				"active": false,
				"children": []
			},
		]
	},
];