const VeeMessages = {
  dictionary: {
    en: {
      messages:{
        required: () => 'Mandatory field',
        url: () => 'Invalid URL'
      },
      custom: {
        account_scenario_type_id: {
          required: "Please select a scenario type"
        },
        number_success_entries: {
          required: "Please select at least one target base with valid entries for your campaign"
        },
        performance_campaign_type_id: {
          required: "Please select a strategy"
        },
        switch: {
          oneChecked: "Please check a Default option"
        },
        addCreativity: {
          addCreativity: "You have not added the minimum amount of creatives yet"
        },
        addSecondAction: {
          addSecondAction: "Please add at least one second action for your campaign"
        },
        start_datetime: {
          start_date: "Start time cannot be configured before than the current time"
        },
        end_datetime: {
          end_date: "End time cannot be configured before than the start time"
        }
      }
    }
  }
};

export { VeeMessages };
