<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ $t(title) }}</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4 col-md-offset-8">
              <bookmark-name v-model="form.name" :isUnique="false" />
            </div>
            <div class="col-12 mt-3">
              <draggable-list v-model="form.subcategories" :data="subcategories" :titles="[$t('Sub-Category'), $t('Template')]" />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <p-button type="dark" @click="goBack" class="pull-left">Cancel</p-button>
          <p-button type="success" @click="validate" class="pull-right">Save</p-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { cloneDeep } from 'lodash';
  import api from "src/api";
  import BookmarkName from 'src/app-components/form-elements/BookmarkName';
  import { defaultMixin } from 'src/app-components/FormMixin';
  import DraggableList from "src/app-components/form-elements/DraggableList";

  export default {
    props: {
      form: {
        type: Object,
        default: () => ({
          name: null,
          subcategories: []
        })
      },

      title: {
        type: String,
        required: false,
        default: "Create Template Assignation"
      },
    },

    components: {
      BookmarkName,
      DraggableList,
      BookmarkName
    },

    mixins: [
      defaultMixin,
    ],

    provide() {
      return {
        parentValidator: this.$validator,
        getError: this.getError,
      };
    },

    async created() {
      const response = await api.getPlatformSubCategoriesThreaded();
      response.data.map(category => {
        this.subcategories.push({
          key: category.id,
          label: category.name
        });
      });
    },

    data() {
      return {
        subcategories: []
      }
    },

    methods: {
      goBack() {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
      },

      async validate() {
        const form = cloneDeep(this.form);
        form.subcategories = form.subcategories.map(subcategory => { return {'id': subcategory }});
        if (await this.$validator.validateAll()) this.$emit("submit", form);
      },
    }
  }
</script>