<template>
  <div class="goto-container">
    Back to Node {{ dataNode.goto }}
  </div>
</template>

<script>
  import { EventBus } from "src/eventBus.js";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";
  export default {
    name: "GoTo",
    mixins: [ChatbotComponentsMixin],
    data() {
      return {
        dataNode: {
          goto: null,
        },
      };
    },

    mounted() {
      setTimeout(() => {
        if(this.nodeID) {
          let node = document.querySelector(`#${this.nodeID} .goto-container`);
          node.addEventListener("mouseover", (event) => {
            EventBus.$emit("mouseoverPopover", this.dataNode.goto);
          });
          
          node.addEventListener("mouseout", (event) => {
            EventBus.$emit("mouseoutPopover", this.dataNode.goto);
          });
        }
      }, 5);
    }
  };
</script>

<style>
.wrapperCanvas .drawflow .drawflow-node.GoTo,
.wrapperCanvas.dark .drawflow .drawflow-node.GoTo {
  width: 0;
  border: 0;
  box-shadow: 0 2px 15px 2px transparent;
}

.wrapperCanvas .drawflow .drawflow-node.GoTo .goto-container {
  position: absolute;
  z-index: 2;
  font-size: 10px;
  font-weight: bold;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  top: -22px;
  left: -11px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.wrapperCanvas .drawflow .drawflow-node.GoTo .input {
  width: 70px;
  height: 70px;
  transition: box-shadow linear .18s;
}

.wrapperCanvas .drawflow .drawflow-node.GoTo .input:hover {
  background: white;
}

.wrapperCanvas.dark .drawflow .drawflow-node.GoTo .input,
.wrapperCanvas.dark .drawflow .drawflow-node.GoTo .input:hover {
  background: #555;
  border-color: #353535;
}

.wrapperCanvas.dark .drawflow .drawflow-node.GoTo .goto-container {
  color: #ddd;
}

.wrapperCanvas .drawflow .drawflow-node.GoTo.selected .input {
    -webkit-box-shadow: 0 2px 12px 2px #909399;
    box-shadow: 0 2px 12px 2px #909399;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.GoTo.selected .input {
    -webkit-box-shadow: 0 2px 12px 2px #666;
    box-shadow: 0 2px 12px 2px #666;
  }
</style>
