export default (scope = "") => {

    const workingPropName = `working${scope}`
    const setWorkingMethodName = `setWorking${scope}`

    return {
        data () {
            return {
                [workingPropName]: false
            }
        },
        methods: {
            [setWorkingMethodName] (value) {
                this[workingPropName] = value
            }
        }
    };

};
