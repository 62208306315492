<template>
  <div>
    <el-table :data="tableData">
        <el-table-column label="Date" property="date"></el-table-column>
        <el-table-column label="Time" property="time"></el-table-column>
        <el-table-column label="Action" property="action"></el-table-column>
        <el-table-column label="From" property="from"></el-table-column>
        <el-table-column label="To" property="to"></el-table-column>
        <el-table-column label="SMSC" property="smsc"></el-table-column>
        <!-- <el-table-column label="Message" property="message"></el-table-column> -->
    </el-table>
    <div class="row">
      <div class="col">
        <p-pagination class="pull-right mt-4 mb-0" :page-count="pageCount" v-model="page"></p-pagination>
      </div>
    </div>
    <Slide
            v-if="active"
            @closeMenu="filterOpen = false"
            :isOpen="filterOpen"
    >
      <Filters @filterUpdated="filterUpdated" />
    </Slide>
    <fabFilterBookmark @click="filterOpen = true" v-click-outside="clickOutside"/>
  </div>
</template>

<script>
  import {Table, TableColumn} from 'element-ui'
  import Filters from 'src/views/smpp-gateway/customer-service/filters'
  import Slide from "src/custom-components/UIComponents/FilterSideBar";
  import FabFilterBookmark from "src/app-components/FabFilter";
  import api from 'src/api'
  import FilterMixin from "src/app-components/FilterMixin";
  import {Pagination} from 'src/components/UIComponents'

  export default {
    components: {
      fabFilterBookmark: FabFilterBookmark,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Filters,
      Slide,
      [Pagination.name]: Pagination,
    },
    mixins: [
      FilterMixin(),
    ],
    async mounted () {
    },
    props: {
      active: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      tableData () {
        if (this.customerData == null) return [];
        return this.customerData.data.data.map(e => ({
          to: e.to,
          date: e.date,
          message: e.msg,
          action: e.action,
          time: e.time,
          smsc: e.SMSC,
          from: e.from,
        }));
      },
      pageCount () {
        if (this.customerData == null) return 0;
        return Math.ceil(this.customerData.data.total / this.customerData.data.pageSize);
      },
    },
    watch: {
      page () {
        this.loadData();
      },
      active () {
        this.filterOpen = false;
      }
    },
    methods: {
      clickOutside(e){
        if (this.filterOpen && e.path.filter(element => element._prevClass == "bm-menu").length == 0) {
          this.filterOpen = false;
        };
      },
      filterUpdated (filters) {
        this.filters = filters;
        this.page = 1;
        this.loadData();
      },
      loadData () {
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(async () => {
          try {
            this.customerData = this.filters.msisdn !== "" ? await api.getSmppGatewayAccessLogForMsisdn(this.filters.msisdn, { page: this.page - 1, index: "SmppGatewayAccessLogIndex" }) : null;
          } catch (e) {
            // Catcheamos error producido por cancelación de Axios (es normal)
          }
        }, 500);
      },
    },
    data () {
      return {
        customerData: null,
        timer: null,
        page: 1,
        filters: null,
      }
    }
  };
</script>
<style>
  .fab-main-container {
    z-index: 1000 !important;
  }
</style>