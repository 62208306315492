<template>
  <div>
    <SectionHeader text="Flow" />

    <div class="row">
      <div class="col-md-5">
        <channels v-model="form.channel_id" :isEditable="!channelDisabled" />
      </div>

      <div class="col-md-7">
        <scenarios
          :disabled="!form.channel_id || scenarioDisabled"
          :noValidation="false"
          :channel="form.channel_id"
          :filterBy="filterScenarioBy"
          @change="notifyScenarioTypeChange"
          v-model="form.account_scenario_type_id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { updatableForm } from "src/app-components/FormMixin";

import Channels from "src/app-components/form-elements/Channels";
import Scenarios from "src/app-components/form-elements/Scenarios";
import SectionHeader from "src/app-components/form-elements/SectionHeader";

export default {
  mixins: [updatableForm],

  components: {
    Channels,
    Scenarios,
    SectionHeader,
  },

  props: {
    filterScenarioBy: {
      type: Function,
      required: false,
    },

    channelId: {
      type: Number,
      required: false,
      default: null,
    },

    channelDisabled: {
      type: Boolean,
      default: false,
    },

    scenarioDisabled: {
      type: Boolean,
      default: false,
    },

    scenarioId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  watch: {
    scenarioId(newValue) {
      this.form.account_scenario_type_id = newValue;
    },
  },

  data() {
    return {
      form: {
        account_scenario_type_id: this.scenarioId,
        channel_id: this.channelId,
      },
    };
  },
  methods: {
    notifyScenarioTypeChange(creativitiesNotAllowed) {
      this.$emit("scenarioTypeChange", creativitiesNotAllowed);
    },
  },
};
</script>

<style></style>
