<template>
  <div class="template-test-view pb-1">
    <div class="template-header" v-if="template.header_format && template.header_value">
      <p v-if="template.header_format.toLowerCase() === 'text'" class="mt-1">{{ template.header_value }}</p>
      <img class="w-100" v-if="template.header_format.toLowerCase() === 'image'" :src="template.header_value" />
      <video :src="template.header_value" v-if="template.header_format.toLowerCase() === 'video'"></video>
      <p v-if="template.header_format.toLowerCase() === 'file'" class="mt-1">File: <a :href="template.header_value" target="_blank">{{ template.header_value }}</a></p>
    </div>
    <div class="template-body my-1" v-if="template.body">
      <div v-html="textMarked(template.body)"></div>
    </div>
    <div class="template-footer" v-if="template.footer">
      <p>{{ template.footer }}</p>
    </div>
    <div class="template-buttons" v-if="template.buttons.length">
      
      <el-button 
        size="small" 
        class="mx-0 my-2 w-100 d-block"
        v-for="(btn, key) in template.buttons"
        :key="key"
      >
        <span v-if="btn.type === 'QUICK_REPLY'">{{btn.text ? btn.text : `Button ${key + 1}`}}</span>
        
        <div class="d-flex align-items-center justify-content-between" v-else>
          <div style="width: 12px;"></div>
          {{btn.text ? btn.text : `Button ${key + 1}`}}
          <el-tooltip 
            class="item" 
            effect="dark" 
            :content="btn.type === 'PHONE_NUMBER' ? `${btn.country}${btn.phone_number}` : btn.url" 
            placement="top"
          >
            <i :class="btn.type === 'PHONE_NUMBER' ? 'el-icon-phone' : 'el-icon-link'" style="width: 12px;"></i>
          </el-tooltip>
        </div>
      </el-button>

    </div>
  </div>
</template>

<script>
  import { marked } from "marked";
  import { Button } from "element-ui";
  export default {
    name: "ViewTemplate",
    props: ["template"],

    components: {
      [Button.name]: Button,
    },

    // data() {
    // return {};
    // },

    // watch: {},

    // created() {},

    // mounted() {},

    methods: {
      textMarked(string) {
        if (!string) return "";

        return marked.parse(string);
      },
    },

    computed: {},
  };
</script>
<style scoped>
  .template-test-view {
    /* border: 1px solid #dcdfe6; */
    border-radius: 4px;
    /* padding: 8px; */
    background: white;
  }

  .template-header p {
    font-weight: bold;
    font-size: 16px;
  }
  .template-body p {
    /* font-size: 14px; */
    /* margin: 5px 0; */
  }
  .template-footer p {
    font-size: 12px;
    color: #999;
  }

  .template-buttons {
    padding: 0 10px;
  }
</style>

<style>
  .template-test-view p {
    margin-bottom: 0;
    padding: 0 10px;
  }
</style>
