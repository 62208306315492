<template>
  <div class="row">
    <div class="col-md-2">
      <fg-input
        name="percents"
        label="Min. CR%"
        v-validate="'required'"
        v-model="model.percents"
        @input="notifyToParent()"
        :error="getError('percents')"
      ></fg-input>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["parentValidator"],

  created() {
    this.$validator = this.parentValidator;
  },

  data() {
    return {
      model: {
        percents: null
      }
    };
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    notifyToParent() {
      this.$emit("updated", this.model);
    }
  }
};
</script>