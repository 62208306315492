<template>
  <div class="form-condition">
    <span class="label">{{ $t("New condition") }}</span>
    <div
      class="d-flex justify-content-between new-option"
      @keypress.enter="addOption"
    >
      <el-select
        placeholder="Variable"
        class="w-100"
        v-model="newOption.variable"
        no-data-text="No variables created"
      >
        <el-option
          v-for="variable in variableOptions"
          :key="variable"
          :value="variable"
        >
        </el-option>
      </el-select>

      <el-select
        placeholder="Type condition"
        class="w-100 mx-2"
        v-model="newOption.typeCondition"
      >
        <el-option
          v-for="(condition, key) in typeCondition(false)"
          :key="key"
          :label="condition.label"
          :value="condition.value"
        >
        </el-option>
      </el-select>

      <el-input
        placeholder="Condition"
        v-model="newOption.condition"
        :disabled="fullOptions"
        class="w-100 new-condition"
        :type="typeVariableSelected"
      >
        <template slot="append">
          <span
            :class="!fullOptions ? 'pointer' : ''"
            @click="addOption"
          >
            <i class="el-icon-plus"></i>
          </span>
        </template>
      </el-input>
    </div>

    <div v-if="optionsListTable.length">
      <el-table :data="optionsListTable" style="width: 100%" size="minsmall">
        <el-table-column label="" width="40px">
          <template slot-scope="scope">
              <div class="d-flex mr-2" style="flex-wrap: wrap">
                <el-button
                  type="text"
                  icon="el-icon-arrow-up"
                  @click.stop="move('up', scope.$index)"
                  size="mini"
                  class="m-0 p-0"
                >
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-arrow-down"
                  @click.stop="move('down', scope.$index)"
                  size="mini"
                  class="m-0 p-0"
                >
                </el-button>
              </div>
          </template>
        </el-table-column>
        
        <el-table-column label="Variable">
          <template slot-scope="scope">
            <span v-if="editItem !== scope.$index">{{
              scope.row.variable
            }}</span>

            <el-select
              v-else
              size="small"
              placeholder="Variable"
              class="w-100"
              v-model="editItemOption.variable"
            >
              <el-option
                v-for="variable in variableOptions"
                :key="variable"
                :value="variable"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="Type condition">
          <template slot-scope="scope">
            <span v-if="editItem !== scope.$index">{{
              scope.row.typeCondition
            }}</span>
            <el-select
              v-else
              size="small"
              placeholder="Type condition"
              class="w-100"
              v-model="editItemOption.typeCondition"
            >
              <el-option
                v-for="(condition, key) in typeCondition(true)"
                :key="key"
                :label="condition.label"
                :value="condition.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="Condition">
          <template slot-scope="scope">
            <span v-if="editItem !== scope.$index">{{
              scope.row.condition
            }}</span>
            <el-input
              v-else
              size="small"
              placeholder="Condition"
              v-model="editItemOption.condition"
              class="w-100"
              :type="typeVariableEditSelected === 'number' ? 'number' : 'text'"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="Actions">
          <template slot-scope="scope">
            <div v-if="editItem !== scope.$index">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click="editOption(scope.$index)"
              >
              </el-button>

              <el-button
                type="text"
                icon="el-icon-delete"
                size="small"
                @click="deleteOption(scope.$index)"
              >
              </el-button>
            </div>
            <div v-else>
              <el-button
                type="text"
                icon="el-icon-check"
                size="small"
                @click="checkEdit"
              >
              </el-button>

              <el-button
                type="text"
                icon="el-icon-close"
                size="small"
                @click="closeEdit"
              >
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import InputActions from "src/app-components/chatbot/elements/InputActions";
  import {
    Button,
    Input,
    Tag,
    Table,
    TableColumn,
    Select,
    Collapse,
    CollapseItem,
  } from "element-ui";
  export default {
    name: "FormConditions",
    props: {
      variables: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => [],
      },
      infoFallback: {
        type: String,
        default: () => "",
      },
    },

    components: {
      [Tag.name]: Tag,
      [Table.name]: Table,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Collapse.name]: Collapse,
      [TableColumn.name]: TableColumn,
      [CollapseItem.name]: CollapseItem,
      "input-actions": InputActions,
    },

    data() {
      return {
        optionsList: [],
        newOption: {
          typeCondition: "",
          variable: "",
          condition: "",
        },

        editItem: null,

        editItemOption: null,
      };
    },

    watch: {
      typeVariableSelected() {
        this.newOption.typeCondition = "";
        this.newOption.condition = "";
      },

      typeVariableEditSelected(new_value, old_value) {
        if(!old_value) return
        if (this.editItemOption) {
          this.editItemOption.typeCondition = "";
          this.editItemOption.condition = "";
        }
      },
    },

    mounted() {
      this.optionsList = clone(this.options);
    },

    methods: {
      move(action, key) {
        let options = clone(this.optionsList);
        let item = options.splice(key, 1);
        if (action === "up") {
          if (key > 0) {
            options.splice(key - 1, 0, item[0]);
          } else {
            return;
          }
        } else {
          if (key < (options.length - 1)) {
            options.splice(key + 1, 0, item[0]);
          } else {
            return;
          }
        }

        this.optionsList = clone(options);

        this.updateData();
        this.$emit("positionOptionsEdited");
      },

      updateValueFallback(message) {
        if (this.optionsList[0]) {
          this.optionsList[0].message = message;
          this.updateData();
        }
      },

      addOption() {
        if (this.optionsList && this.newOptionCompleteData) {
          if (this.fullOptions) {
            this.newOption = {
              typeCondition: "",
              variable: "",
              condition: "",
            };

            return;
          }

          let newItem = {
            id: Date.now(),
            type: "condition",

            typeCondition: this.newOption.typeCondition,
            variable: this.newOption.variable,
            condition: this.newOption.condition,
          };

          this.optionsList.splice((this.optionsList.length - 1), 0, newItem);

          this.$emit("positionOptionsEdited");
          this.updateData();
          this.createOutput();

          this.newOption = {
            typeCondition: "",
            variable: "",
            condition: "",
          };
        }
      },

      editOption(key) {
        this.editItemOption = clone(this.optionsList[key]);
        this.editItem = key;
      },

      checkEdit() {
        // let index = this.editItem + 1;
        let index = this.editItem;
        this.optionsList.splice(index, 1);
        this.optionsList.splice(index, 0, clone(this.editItemOption));
        this.updateData();
        this.closeEdit();
      },

      closeEdit() {
        this.editItem = null;
        this.editItemOption = null;
      },

      deleteOption(key) {
        if (this.optionsList[key]) {
          console.log(this.optionsList[key]);
          if (this.optionsList[key].type !== "fallback") {
            let outputs = this.outputsIdFormat(this.optionsList);
            let outputClass = outputs.indexOf(key);
            if (outputClass !== -1) {
              this.deleteOutput(`output_${outputClass + 1}`);
            }

            this.optionsList.splice(key, 1)[0];

            this.$emit("positionOptionsEdited");
            this.updateData();
          }
        }
      },

      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.optionsList);
        }, 1);
      },

      createOutput() {
        this.$emit("createOutput");
      },

      deleteOutput(output) {
        this.$emit("deleteOutput", output);
      },

      outputsIdFormat(options = null) {
        let keys = [];
        if (options) {
          options.forEach((o, index) => {
            if (o.type === "condition") {
              keys.push(index);
            }
          });
        }

        return keys;
      },

      typeCondition(isEdit = false) {
        const conditions = [
          {
            value: ">",
            label: "Greater than",
          },
          {
            value: ">=",
            label: "Greater than or equal",
          },
          {
            value: "==",
            label: "Equal",
            viewString: true,
          },
          {
            value: "!=",
            label: "Unequal",
            viewString: true,
          },
          {
            value: "<=",
            label: "Less than or equal",
          },
          {
            value: "<",
            label: "Less than",
          },
        ];

        let selected = isEdit
          ? this.typeVariableEditSelected
          : this.typeVariableSelected;

        return selected
          ? conditions.filter((c) => {
              if (selected === "string") {
                return c.viewString;
              }
              return true;
            })
          : conditions;
      },
    },

    computed: {
      fullOptions() {
        if (!this.optionsList) return true;
        return this.optionsList.length >= 10;
      },

      variableOptions() {
        if (!this.variables) return [];

        return this.variables.map((v) => v.name);
      },

      typeVariableSelected() {
        if (this.newOption) {
          if (this.newOption.variable) {
            let item = this.variables.find(
              (v) => v.name === this.newOption.variable
            );

            if (item) return item.type;
          }
        }

        return null;
      },

      typeVariableEditSelected() {
        if (this.editItemOption) {
          if (this.editItemOption.variable) {
            let item = this.variables.find(
              (v) => v.name === this.editItemOption.variable
            );

            if (item) return item.type;
          }
        }

        return null;
      },

      newOptionCompleteData() {
        return (
          this.newOption.typeCondition !== "" &&
          this.newOption.variable !== "" &&
          this.newOption.condition !== ""
        );
      },

      optionsListTable() {
        let options = clone(this.optionsList);
        options.pop();
        return options;
      },
    },
  };
</script>

<style scoped>
  .new-option > div:first-of-type {
    width: 30%;
  }

  .new-option > div:last-of-type {
    width: calc(70% - 8px);
  }
</style>

<style>
  .form-condition
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: transparent;
  }

  .el-table::before {
    height: 0;
  }
  .wrapperCanvas.dark .el-table td.el-table__cell,
  .wrapperCanvas.dark .el-table th.el-table__cell.is-leaf {
    border-color: #555;
    color: #ddd;
  }

  .wrapperCanvas .new-condition .el-input-group__append {
    padding: 0;
  }

  .wrapperCanvas .new-condition .el-input-group__append span {
    padding: 0 15px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
