<template>
  <el-table :data="value" style="width: 100%">
    <el-table-column type="index" width="50" align="center" />

    <el-table-column :label="$t('Name')" prop="name" />

    <el-table-column :label="$t('Subcategories')" align="center">
      <template slot-scope="scope">
        <el-popover trigger="hover" placement="right">
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-center">{{ $t('Name') }}</li>
            <li v-for="(subcategory, k) in scope.row.subcategories" :key="k" class="list-group-item">{{ subcategory.name }}</li>
          </ul>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium" type="warning">{{ $t('SHOW') }}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Actions')" align="center">
      <template slot-scope="scope">
        <p-button type="warning" size="sm" icon @click="handleEdit(scope.row.id)">
          <i class="fa fa-edit"></i>
        </p-button>
        <p-button type="danger" size="sm" icon @click="handleDelete(scope.row.id, scope.row.name)">
          <i class="fa fa-remove"></i>
        </p-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import { Table, Popover, Tag } from 'element-ui'
  import swal from 'sweetalert2'
  import api from "src/api";
  import { Button } from 'src/components/UIComponents'

  export default {
    props: {
      value: Array
    },

    components: {
      [Table.name]: Table,
      [Popover.name]: Popover,
      [Tag.name]: Tag,
      [Button.name]: Button
    },

    methods: {
      handleEdit(id) {
        this.$router.push({name: 'ProductAssignationEdit', params: { id: id }})
      },

      handleDelete(id, productName) {
        swal({
          title: `${this.$t('You are going to delete')} ${productName.toUpperCase()}`,
          text: this.$t("You won't be able to revert this!"),
          type: 'error',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return api.deleteProductAssignment(id)
              .then(response => {
                if (res.status !== true) throw new Error(response.statusText);

                return response.data
              })
              .catch(error => {
                // Manejar excepción
              })
          }
        }).then((action) => {
          if (action.value === true) {
            this.$emit('delete', id);
            swal({
              title: this.$t('Deleted!'),
              text: this.$t('The template has been deleted.'),
              type: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            })
          }
        })
      }
    }
  }
</script>