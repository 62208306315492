<template>
  <div>
    <form-campaign
        :tabbed-form-save-button-disabled="workingSave"
        @submit="onSubmit" 
        :initialBookmarkData="initialBookmarkData"
        :bookmarkProps="{ isEditable: isEditable }"
        :initialCampaignData="initialCampaignData"
        :initialTargetData="initialTargetData"
        :initialRetryData="initialRetryData"
        :initialSActiontData="initialSActionData"
        :initial-tab="$route.params.tab"
        :disabled="disabled">
    </form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from 'src/app-components/FormApiEvent';
import constants from 'src/views/campaigns/constants'
import RetryCrudMixin from 'src/app-components/RetryCrudMixin';
import { mapMutations } from "vuex";
import { cloneDeep } from 'lodash';
import WorkingMixin from 'src/app-components/WorkingMixin';

export default {
  components: {
    FormCampaign
  },

  mixins: [
    RetryCrudMixin(),
    WorkingMixin("Save")
  ],

  async created() {
      if ("id" in this.$route.params) {
        const { data } = await api.getApiEvent(this.$route.params.id)
        data.variables = this.fixActiveCapValue(data.variables);
        data.manual_start = data.start_datetime != null || data.end_datetime != null

        this.initialTargetData   = data.files;
        this.initialBookmarkData = data.templateCreatives[0].template;
        this.initialCampaignData = data;
        this.initialSActionData  = data.second_actions;
      }
    },

    computed: {
      isEditable() {
        const hasId = ("id" in this.initialBookmarkData);
        return !hasId && !this.disabled;
      },
      disabled () {
        return ! constants.StatusIdCanEditCampaign.includes(this.initialCampaignData.status_id)
      }
    },

    mounted () {
      this.setTopNavbarTitle(this.$t("Edit an external event"));
    },

    data() {
        return {
            initialBookmarkData: {},
            initialCampaignData: {},
            initialTargetData: [],
            initialSActionData: [],
            initialRetryData: {}
        }
    },

  methods: {
    async onSubmit($event) {
      try {
        this.setWorkingSave(true)
        await api.updateApiEvent($event.id, $event);
        this.$router.push({ path: `/api-events/planning` });
      } finally {
        this.setWorkingSave(false)
      }
    },

    fixActiveCapValue(data) {
      let variables = cloneDeep(data);
      let index = variables.findIndex(v => v.variable.name == 'active-cap');
      if(index != -1) {
        if(variables[index].value == 1) {
          variables[index].value = true;
        }
        if(variables[index].value == 0 || variables[index].value == '') {
          variables[index].value = false;
        }
      }

      return variables;
    },

    ...mapMutations(["setTopNavbarTitle"]),
  }
}
</script>

<style>

</style>
