<template>
    <div :class="[isVideo ? 'video' : '', messageMode ? 'rcs-media' : '']">
        <video v-if="isVideo" controls>
            <source :src="media">
        </video>
        <img v-else :src="media" alt="">
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: '',
            required: false,
        },
        messageMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            VIDEO_EXTENTIONS : ['.mp4', '.m4v', '.mpg', '.mpeg', '.webm']
        }
    },

    computed: {
        media(){
            return this.data;
        },
        fileName() {
            return this.name
        },
        isVideo(){
            for (let index = 0; index < this.VIDEO_EXTENTIONS.length; index++) {
                if (this.media.includes(this.VIDEO_EXTENTIONS[index]) || this.fileName.includes(this.VIDEO_EXTENTIONS[index])) {
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
    .rcs-media{
        max-width: 35%;

        &.video {
            max-width: 70%;
        }
        video, img {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        video {
            width: 100%;
        }
    }
    video, img {
        height: 100%;
        // width: 100%;
    }

    video {
        background-color: black;
    }

</style>