import _ from 'lodash'
import calculateSize from 'calculate-size'

export default ({ tableDataProp }) => {

    return {
        methods: {
            fitColumnToContent (columnDataProp, columnName, extraWidth = 110) {
                let input = _.get(this, tableDataProp);
                if (typeof(columnDataProp) === "string") {
                    input = input.map(e => _.get(e, columnDataProp));
                } else if (typeof(columnDataProp) === "function") {
                    input = input.map(e => columnDataProp({ row: e }));
                } else {
                    throw new Error("Dynamo");
                }
                input = input.map(e => calculateSize((e || "").toString(), {
                    font: 'Montserrat',
                    fontWeight: '400',
                    fontSize: '10px'
                 }).width)
                const max = Math.max(...input, calculateSize(columnName, {
                    font: 'Montserrat',
                    fontWeight: '700',
                    fontSize: '11px'
                 }).width)
                return max + extraWidth
            },
            refreshTable() {
                this.tableRenderId = Date.now();
            }
        },
        data () {
            return {
                tableRenderId: Date.now()
            }
        },
    }
}