<template>
  <div class="col-12 mb-3">
    <label>{{ $t(field.tooltip) }}</label>
    <input type="hidden" name="validUrl" :value="enteredUrl" v-validate="field.required ? 'required|url: {require_protocol: true}' : ''">
    <el-input :name="field.name"
              @input="$emit('input', enteredUrl)"
              @focus="focused = true"
              @blur="focused = false"
              v-model="url.domain"
              :error="getError(field.name)"
              :disabled="disabled"
              :class="{'has-danger': error, 'has-success': value && ! error && focused}"
              class="input-with-select">
      <el-select v-model="url.protocol" slot="prepend" @change="$emit('input', enteredUrl)" :disabled="disabled">
        <el-option label="http://" value="http://"></el-option>
        <el-option label="https://" value="https://"></el-option>
      </el-select>
    </el-input>
    <div class="col-12" v-if="error">
      <div class="text-danger invalid-feedback" style="display: block;">{{ error }}</div>
    </div>
  </div>
</template>
<script>
  import { Input, Select, Option } from "element-ui";

  export default {
    inject: ["getError", "parentValidator"],

    props: ["value", "field", "width", "disabled"],

    components: {
      [Input.name]: Input,
      [Option.name]: Option,
      [Select.name]: Select,
    },

    computed: {
      enteredUrl() {
        return Object.values(this.url).reduce((a, b) => a + b);
      },

      error() {
        return this.getError('validUrl');
      }
    },

    data() {
      return {
        url: {
          protocol: "http://",
          domain: ""
        },
        focused: false
      };
    },

    created() {
      this.$validator = this.parentValidator;
      if(this.value) {
        if (this.value.trim().length !== 0) this.fillUrl();
      }
    },

    methods: {
      fillUrl() {
        const endProtocol = this.value.indexOf('://') + 3; // length of '://'
        this.url.protocol = this.value.substring(0, endProtocol);
        this.url.domain = this.value.substring(endProtocol);

      }
    }
  };
</script>

<style scoped>
  :deep(.input-with-select .el-input-group__prepend) {
    width: 30%;
  }

  :deep(.el-input-group__prepend .el-select:hover) {
    border-top: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
  }

  :deep(.has-danger .el-input-group__prepend .el-select:hover) {
    border-top: 1px solid #ef8157;
    border-bottom: 1px solid #ef8157;
  }

  :deep(input[type=text].el-input__inner),
  input[type=text].el-input__inner:hover {
    background-color: #f5f7fa;
    border-top: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
  }

  :deep(.el-input__inner:focus) {
    border-color: #dcdfe6;
  }

  .has-danger {
    background-color: #FFC0A4;
    border: 1px solid #ef8157;
    color: #ef8157;
  }

  .has-success {
    border: 1px solid #6bd098;
    color: #6bd098;
  }
</style>