<template>
    <div>
        <div class="row" v-if="$can('Filter', 'Account')">
            <div class="col-md-12">
                <Accounts v-model="filters.accounts" :no-validation="true" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <CampaignName :required="false" 
                    :is-unique="false" 
                    v-model="filters.campaign_name" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <BookmarkName :required="false" :is-unique="false" v-model="filters.name" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <CampaignStatus v-model="filters.status_id" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <CampaignType v-model="filters.type" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <DateRangePicker @date-range-changed="filters.dateRange = $event"
                                 :shortcuts="dateShortcuts" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <Channels v-model="filters.channel_id"
                    @clear="onClear"
                    :noValidation="true"
                    :clearable="true" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <Scenarios :disabled="!filters.channel_id"
                    :noValidation="true"
                    :clearable="true"
                    :channel="filters.channel_id"
                    v-model="filters.account_scenario_type_id" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import BookmarkNameComponent from "src/app-components/form-elements/BookmarkName";
    import CampaignName from "src/app-components/form-elements/CampaignName";
    import CampaignStatus from "src/app-components/form-elements/CampaignStatus";
    import CampaignType from "src/app-components/form-elements/CampaignType";
    import FilterPanelMixin from "src/app-components/FilterPanelMixin";
    import DateRangePicker from "src/app-components/form-elements/DateRangePicker";
    import { CurrentMonth, Today, CurrentWeek, Yesterday } from "src/app-components/DateRangePresets.js"
    import Accounts from "src/app-components/form-elements/Accounts";
    import Channels from "src/app-components/form-elements/Channels";
    import Scenarios from "src/app-components/form-elements/Scenarios";

    const initialDateRangeValue = [ moment().startOf('day'), moment().endOf('day') ];

    export default {
        components: {
            Accounts,
            BookmarkName: BookmarkNameComponent,
            CampaignName,
            CampaignStatus,
            Channels,
            CampaignType,
            DateRangePicker,
            Scenarios
        },

        mixins: [
            FilterPanelMixin({
                accounts: [],
                account_scenario_type_id: null,
                channel_id: null,
                name: null,
                campaign_name: null,
                status_id: null,
                type: null,
                dateRange: [],
            })
        ],

        mounted() {
            this.filters.accounts = this.filters.accounts.filter(accountId => accountId !== this.$auth.selectedAccountId);
            this.filters.accounts.push(this.$auth.selectedAccountId);
        },

        watch: {
          'filters.status_id': function(newValue) {
              if(newValue === '') this.filters.status_id = null;
          },
          'filters.type': function(newValue) {
              if(newValue === '') this.filters.type = null;
          },
          'filters.channel_id': function(newValue) {
              if(newValue === '') this.filters.channel_id = null;
          },
        },

        data() {
            return {
                dateShortcuts: [
                    {
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', Today());
                        }
                    },
                    {
                        text: 'Yesterday',
                        onClick(picker) {
                            picker.$emit('pick', Yesterday());
                        }
                    },
                    {
                        text: 'Last week',
                        onClick(picker) {
                            picker.$emit('pick', CurrentWeek());
                        }
                    }, {
                        text: 'Month',
                        onClick(picker) {
                            picker.$emit('pick', CurrentMonth());
                        }
                    }
                ],

                initialDateRangeValue,
                defaultStatusFilter: [
                    4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16,
                    17, 18, 19, 20, 21, 22, 23, 24, 25, 29, 30, 31, 32, 33, 34, 35 
                ]
            }
        },

        methods: {
            onClear() {
                this.filters.account_scenario_type_id = null
            },

            prepareFilters (filters) {
                return {
                    "Bookmark.name": this.fixCleared(this.filters.name),
                    "Campaign.name": this.fixCleared(this.filters.campaign_name),
                    "Campaign.channel_id": this.filters.channel_id,
                    "Campaign.account_id IN": this.filters.accounts,
                    "Campaign.status_id IN": this.filters.status_id !== null ? [ this.filters.status_id ] : this.defaultStatusFilter,
                    "Campaign.type": this.filters.type,
                    "Campaign.start_datetime": this.filters.dateRange,
                    "Campaign.account_scenario_type_id": this.filters.account_scenario_type_id,
                };
            },
        },
    };
</script>
<style scoped>
</style>