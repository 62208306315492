<template>
  <div class="nav-canvas">
    <div class="d-flex align-items-center">
      <el-tooltip class="item" effect="dark" content="Back" placement="bottom">
        <el-button type="text" @click="backToFlows">
          <i class="fa fa-angle-left"></i>
        </el-button>
      </el-tooltip>

      <h1 v-if="name">{{ name }}</h1>
    </div>
    <div>
      <el-switch v-model="darkMode"></el-switch>

      <el-tooltip
        class="item"
        effect="dark"
        :content="opt.tooltip"
        placement="bottom"
        :open-delay="250"
        v-for="(opt, key) in optionNav"
        :key="key"
      >
        <el-button
          type="text"
          @click="emit(opt.action)"
          style="width: 18px"
          class="ml-3"
          :disabled="opt.icon === 'el-icon-loading'"
        >
          <i class="fa" :class="opt.icon"></i>
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
  import { Switch, Button, Tooltip } from "element-ui";

  export default {
    name: "NavbarFlow",
    props: ["name", "saving", "lockCanvas"],

    components: {
      [Switch.name]: Switch,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
    },

    data() {
      return {
        darkMode: false,
        activeChangeDarkMode: false,
      };
    },

    watch: {
      darkMode() {
        if (this.darkMode) {
          localStorage.setItem("darkMode", this.darkMode);
          if(document.body) {
            document.body.classList.add("dark");
          }
        } else {
          localStorage.removeItem("darkMode");
          if(document.body) {
            if(document.body.classList.contains("dark")) {
              document.body.classList.remove("dark")
            }
          }
        }

        this.$emit("changeDarkMode", this.darkMode);
      },
    },

    mounted() {
      if (localStorage.getItem("darkMode") === "true") {
        this.darkMode = true;
      }
    },

    methods: {
      backToFlows() {
        this.$router.push({ name: "FlowIndex" });
      },
      
      emit(action) {
        this.$emit(action);
      },
    },

    computed: {
      optionNav() {
        return [
          {
            action: "clear",
            icon: "fa-trash",
            tooltip: "Clear",
          },
          /* {
            action: "saveDataTest",
            icon: "el-icon-plus",
            tooltip: "Save Test",
          }, */
          {
            action: "saveData",
            icon: this.saving ? "el-icon-loading" : "fa-save",
            tooltip: this.saving ? "Saving" : "Save",
          },
          {
            action: "changeLockCanvas",
            icon: !this.lockCanvas ? "fa-unlock-alt" : "fa-lock",
            tooltip: this.lockCanvas ? "Unlock" : "Lock",
          },
          {
            action: "openConfig",
            icon: "fa-cog",
            tooltip: "Configuration",
          },
        ];
      },
    },
  };
</script>
