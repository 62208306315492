import { render, staticRenderFns } from "./FlowConfiguration.vue?vue&type=template&id=74aaa21c&scoped=true"
import script from "./FlowConfiguration.vue?vue&type=script&lang=js"
export * from "./FlowConfiguration.vue?vue&type=script&lang=js"
import style0 from "./FlowConfiguration.vue?vue&type=style&index=0&id=74aaa21c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74aaa21c",
  null
  
)

export default component.exports