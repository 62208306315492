export default (fields) => {
  return {
    data () {
      let newFields;
      if (typeof(fields) === "function") {
        newFields = fields(this);
      } else {
        newFields = fields;
      }
      return {
        filters: { ...newFields },
        timeout: null,
      }
    },
    provide() {
        return {
            parentValidator: this.$validator,
            getError: this.getError
        };
    },
    methods: {
      getError(fieldName) {
          return this.errors.first(fieldName);
      },

      fixCleared (value) {
        return value === "" ? null : value
      }
    },
    watch: {
      filters: {
        handler () {
          const preparedFilters = this.prepareFilters(this.filters);

          if(this.timeout !== null) {
            clearTimeout(this.timeout);
          }
          
          this.timeout = setTimeout(() => {
            this.$emit('filterUpdated', preparedFilters);
          }, 500);

          // this.$emit('filterUpdated', preparedFilters);
        },
        deep: true
      }
    }
  };
};