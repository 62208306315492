<script>
import api from "src/api";
import CampaignReport from "./Campaign";
import _ from 'lodash';

export default {
    name: "ConsolidatedCampaign",

    extends: CampaignReport,

    created() {
        
        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
            { prop: "saId", label: "Second Action ID", exportable: false}
        ];
    },

    methods: {

        tableDimensions() {
            return [
                "campaign_id ASC",
                "second_action_id ASC",
            ]
        },

        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        async getData(query) {
            this.$emit("set-up-filters", {
                showGroupBySelect: false
            });
            
            this.setWhere(query)
            const response = await api.getCampaignPivotConsolidated(query);

            let currentRowKey = 1;

            this.tableData = response.data.report_etl_total.map(ret => {
                let children;
                if(ret.Campaign) {
                    children = response.data.report_etl.filter(re => (
                        re.CampaignId !== null &&
                        re.saId !== null &&
                        (re.FirstParent == ret.CampaignId || re.CampaignId == ret.CampaignId)
                    )).map(retry => {
                        if (retry.Type == "SECOND_ACTION") {
                            retry.Campaign += " - SA"
                        }
                        return {
                            ...retry,
                            label: retry.Campaign
                        }
                    });
                
                }else{
                    children = [];
                }
                
                
                return {
                    ...ret,
                    label: ret.Campaign,
                    Pkey: currentRowKey++,
                    children   ,
                    URL: ret.URL && `<a target="_blank" href="${ret.URL}">${this.sanitizeUrl(ret.URL)}</a>`      
                }
            })
        },

        sanitizeUrl (url) {
            if (url.length > 15) {
                return url.substr(0, 15) + "..."
            } else {
                return url
            }
        },

        setWhere (query) {
            query.ql.where["campaign_type NOT IN"] = ["PING", "TEST", "EVENT"];
            query.ql.where["scenario_id <>"] = 11; // NOT ping
        },

        /**
         * @Override
         */
        transformDataToFrontendFormat (dimensions) {
            // Do nothing
        },

        getLabel (re) {
            let label = re.Campaign
            if (re.Type == "SECOND_ACTION") {
                label += " - SA"
            }
            return label
        },
    }
}
</script>