<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="row"
        v-if="$asyncComputed.tableData.success && tableData.metadata.total <= 0"
      >
        <div class="col-12">
          <div class="alert alert-warning text-center">
            {{
              $t(
                "You don't have any events yet. Please, create an event by clicking on the + button below!"
              )
            }}
          </div>
        </div>
      </div>
      <div
        class="card"
        v-if="$asyncComputed.tableData.success && tableData.metadata.total > 0"
      >
        <!--
        <div class="card-header">
          <h4 class="card-title">{{ $t("Planning local") }}</h4>
        </div>
        -->
        <div class="card-body">
          <Spinner :display="$asyncComputed.tableData.updating" />

          <div class="row">
            <div class="col-sm-12 mt-2">
              <el-table
                :key="tableRenderId"
                v-if="$asyncComputed.tableData.success"
                :data="tableDataWithoutDeleted.data"
                style="width: 100%;"
              >
                <el-table-column
                  prop="name"
                  :label="$t('ID')"
                  :min-width="fitColumnToContent('name', $t('ID'), 60)"
                >
                </el-table-column>
                <el-table-column
                  prop="description"
                  :label="$t('Description')"
                  :width="
                    fitColumnToContent('description', $t('Description'), 60)
                  "
                >
                </el-table-column>
                <el-table-column
                  :label="$t('Template')"
                  :width="
                    fitColumnToContent('bookmarks[0].name', $t('Template'), 60)
                  "
                >
                  <template slot-scope="scope">
                    <a v-popover="{ name: 'event-' + scope.row.id }" href="#" v-if="scope.row.templateCreatives.length">
                      {{
                        scope.row.templateCreatives.length > 0
                          ? scope.row.templateCreatives[0].template.name
                          : ""
                      }}
                      <!-- {{
                        scope.row.bookmarks.length > 0
                          ? scope.row.bookmarks[0].name
                          : ""
                      }} -->
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Status')"
                  :width="
                    fitColumnToContent(
                      (scope) => statusIdToString(scope.row.status_id),
                      $t('Status'),
                      60
                    )
                  "
                >
                  <template slot-scope="scope">
                    {{ statusIdToString(scope.row.status_id) }}
                  </template>
                </el-table-column>

                <el-table-column
                  :min-width="270"
                  class-name="action-buttons td-actions"
                  align="left"
                  :label="$t('Actions')"
                >
                  <template slot-scope="scope">
                    <p-button
                      type="info"
                      size="sm"
                      icon
                      @click="handleEdit(scope.row)"
                    >
                      <i class="fa fa-edit"></i>
                    </p-button>

                    <CampaignPlay
                      v-if="scope.row.status_id == 0"
                      :id="scope.row.id"
                      @activated="onActivated($event, scope.row)"
                    />

                    <CampaignPause
                      v-if="scope.row.status_id == 1"
                      :id="scope.row.id"
                      @deactivated="onDeactivated($event, scope.row)"
                    />

                    <CampaignDelete
                      :id="scope.row.id"
                      @deleted="onDeleted($event, scope.row)"
                      :campaign-type="scope.row.type"
                    />

                    <p-button
                      type="success"
                      size="sm"
                      icon
                      @click="showStats($event, scope.row)"
                    >
                      <i class="nc-icon nc-chart-bar-32"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div
              class="col-md-6 mt-4 pagination-info"
              v-if="$asyncComputed.tableData.success"
            >
              <p class="category">{{ tableData.metadata.total }} entries</p>
            </div>
            <div class="col-md-6 mt-4" v-if="$asyncComputed.tableData.success">
              <p-pagination
                :page-count="tableData.metadata.size"
                class="pull-right"
                v-model="search.defaultPagination"
              >
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Slide @closeMenu="filterOpen = false" :isOpen="filterOpen">
      <Filters @filterUpdated="filterUpdated($event)" />
    </Slide>

    <fabFilter
      :position="0"
      @click="filterOpen = true"
      v-click-outside="clickOutside"
    />

    <fabGoToCampaignManager :position="1" />

    <CopyCampaign
      v-if="showCopyModal"
      @copied="onCampaignCopied($event)"
      @cancelled="showCopyModal = false"
      :show="showCopyModal"
      :id="this.copyData.id"
      :name="this.copyData.name"
    />

    <div v-if="$asyncComputed.tableData.success">
      <Popover
        :key="`event-${event.id}`"
        v-for="event in tableData.data"
        :name="`event-${event.id}`"
        event="hover"
        :width="350"
      >
        <card-bookmark
          v-if="event.templateCreatives.length"
          :bookmark="event.templateCreatives[0].template"
          :activateGoTo="false"
          :selected-creative-hash="
            event.templateCreatives[0]
              ? event.templateCreatives[0].bookmark_creative_hash
              : ''
          "

        />
      </Popover>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import Pagination from "src/components/UIComponents/Pagination";
import Slide from "src/custom-components/UIComponents/FilterSideBar";
import Filters from "./filters";
import FabFilter from "src/app-components/FabFilter";
import FabGoToCampaignManager from "src/app-components/FabGoToCreateApiEventFromProduct";
import FilterMixin from "src/app-components/FilterMixin";
import Spinner from "src/app-components/Spinner";
import { QueryBuilder } from "x-query-builder";
import { flow, pick } from "lodash";
import moment from "moment";
import { mapMutations } from "vuex";
import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";
import CardBookmark from "src/app-components/CardBookmark";
import Popover from "vue-js-popover";

import CopyCampaign from "src/app-components/modal-components/CopyCampaign";

import CampaignDelete from "src/app-components/buttons-components/EventDelete";
import CampaignPlay from "src/app-components/buttons-components/EventActivate";
import CampaignPause from "src/app-components/buttons-components/EventDeactivate";

import Vue from "vue";

Vue.use(Popover);

export default {
  components: {
    CampaignDelete,
    CampaignPause,
    CampaignPlay,
    CopyCampaign,
    FabFilter,
    FabGoToCampaignManager,
    Filters,
    Slide,
    Spinner,
    [Pagination.name]: Pagination,
    CardBookmark,
  },

  mixins: [
    FilterMixin(),
    FitColumnToContentMixin({ tableDataProp: "tableDataWithoutDeleted.data" }),
  ],

  created() {
    this.setTopNavbarTitle("External events");
    /*
      this.t = setInterval(() => {
          // Update the next tick
          this.tick = (new Date).getTime();
      }, 60000);
      */
  },

  beforeDestroy() {
    clearInterval(this.t);
  },

  watch: {
    "$asyncComputed.tableData.success"() {
      if (this.$asyncComputed.tableData.success) {
        this.refreshTable();
      }
    },
  },

  asyncComputed: {
    async tableData() {
      const pageNumber = this.search.defaultPagination;
      const filters = this.search.filter;

      const query = flow([
        () => new QueryBuilder(),

        (builder) => {
          const where = {
            "ApiEvent.deleted": 0,
          };

          if ("ApiEvent.name" in filters) {
            where["ApiEvent.name LIKE"] = "%" + filters["ApiEvent.name"] + "%";
          }

          return builder.addWhere(where);
        },

        (builder) => {
          return builder.addPage(pageNumber);
        },

        (builder) => {
          return builder.addLimit(10);
        },

        (builder) => {
          return builder.addGroup(["ApiEvent.id"]);
        },

        (builder) => {
          return builder.addOrder([
            "IFNULL(ApiEvent.start_datetime, '9999-12-31 23:59:59') DESC",
          ]);
        },
      ]);

      const events = await api.getApiEvents({
        ql: query().build,
        v: this.tick,
      });

      // console.log("Events: ", events);

      return events;
    },
  },

  computed: {
    /* accountsSelected() {
      return this.search.filter["Campaign.account_id IN"].length;
    }, */
    tableDataWithoutDeleted() {
      return {
        ...this.tableData,
        data: this.tableData.data.filter((c) => c.deleted != 1),
      };
    },
  },

  data() {
    return {
      t: null,
      tick: new Date().getTime(),
      showCopyModal: false,
      copyData: {
        id: null,
        name: null,
      },

      states: {
        play: [15],
        pause: [5, 12, 13, 14, 19, 29, 30],
        resume: [8],
        delete: [22, 15, 4, 17],
        cancel: [8],
      },

      tableDataReady: false,
      search: {
        filter: {},
        defaultPagination: 1,
      },
    };
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    clickOutside(e) {
      if (
        this.filterOpen &&
        e.path.filter((element) => element._prevClass == "bm-menu").length == 0
      ) {
        this.filterOpen = false;
      }
    },

    handleCopy(row) {
      this.copyData.id = row.id;
      this.copyData.name = row.name;
      this.showCopyModal = true;
    },

    canBeCopied(type) {
      const notAllowed = ["PERFORMANCE", "RETRY"];
      return !notAllowed.includes(type);
    },

    onCampaignCopied($event) {
      this.handleEdit($event);
    },

    onDeactivated($event, $row) {
      this.$set($row, "status_id", 0);
    },

    onActivated($event, $row) {
      this.$set($row, "status_id", 1);
    },

    onResumed($event, $row) {
      this.$set($row.status, "name", $event.data.status);
      this.$set($row, "status_id", $event.data.status_id);
    },

    onDeleted($event, $row, deleteChildren = true) {
      this.$set($row, "deleted", 1);
      this.tableData.metadata.total--;
    },

    onCanceled($event, $row) {
      this.$set($row.status, "name", $event.data.status);
      this.$set($row, "status_id", $event.data.status_id);
    },

    handleEdit(row, params = {}, _type = null) {
      this.$router.push({
        // name: `EditApiEventFromBookmark`,
        name: `ApiEventEdit`,
        params: {
          id: row.id,
          ...params,
        },
      });
    },

    handleRetry(row) {
      const type = row.type;

      if (type === "SINGLE") {
        this.$router.push({
          // name: `CampaignEditFromScratch`,
          name: `CampaignEdit`,
          params: {
            id: row.id,
            tab: "Retry",
          },
        });
      }

      if (type === "PING") {
        this.$router.push({
          name: `CampaignEditFromPing`,
          params: {
            id: row.id,
            tab: "Retry",
          },
        });
      }

      if (type === "RETRY") {
        this.handleEdit(row);
      }

      if (type === "PERFORMANCE") {
        // TODO implementar cuando sea necesario
      }
    },

    filterUpdated($event) {
      const actives = pick(
        $event,
        Object.keys($event).filter((key) => $event[key] !== null)
      );
      this.search = {
        defaultPagination: 1,
        filter: { ...actives },
      };
    },

    formatType(row, _, value) {
      return value === "SINGLE"
        ? "ON DEMAND"
        : value === "PERFORMANCE"
        ? "PROGRAMMATIC"
        : value;
    },

    formatTranslate(row, _, value) {
      return this.$t(value);
    },

    formatDatetime(row, _, value) {
      if (value == null) return null;
      const ret = moment(value).format("YYYY-MM-DD HH:mm");
      return ret;
    },

    generateFinishDateTime(scope) {
      if (scope.row.finished_datetime) {
        return this.formatDatetime(null, null, scope.row.finished_datetime);
      } else if (scope.row.end_datetime) {
        return this.formatDatetime(null, null, scope.row.end_datetime);
      } else {
        return null;
      }
    },

    statusIdToString(statusId) {
      if (statusId <= 0) return "Disabled";
      return "Enabled";
    },

    showStats($event, $row) {
      this.$router.push({
        name: `CampaignPivot`,
        params: {
          initialReport: 11, //Representa el tipo de reporte, en este caso Evento
          eventID: $row.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.fab-main-container {
  z-index: 1000 !important;
}
:deep(.cell > div) {
  display: contents;
}
[data-popover^="event-"] {
  background: transparent;
  box-shadow: none;
}
</style>
