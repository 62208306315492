<template>
    <div>
        <div class="row" v-loading="loading" :element-loading-text="$t('Loading')">
            <div class="col">

                <div v-if="form.placeholders.length !== 0">
                    <h6 class="text-left">{{$t('Available placeholders')}}</h6>
                    <div v-for="(ph, i) in form.placeholders" :key="i">
                        <hr v-if="i > 0" class="my-1">
                        <PlaceholderRow :inUse="isInUse(ph.name)" :placeholder="ph" :index="i" @delete="deletePlaceholder(ph, i)"></PlaceholderRow>
                    </div>
                </div>

                <div v-else>
                    <h5 class="text-black-50 bg-light my-2 py-3">{{$t('There are no created placeholders yet.')}}</h5>
                </div>

                <div class="row" v-show="!loading">
                    <div class="col">
                        <p-button type="neutral" class="w-100" @click="addNew">{{$t('Add placeholder')}} <i class="el-icon-plus"></i></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";
import { isEmpty, uniq } from 'lodash';

import PlaceholderRow from "src/app-components/PlaceholderRow";

import { updatableForm } from "src/app-components/FormMixin";

export default {
  name: "TabPlaceholders",
    components: {
        PlaceholderRow,
    },

    props:{
        creatives: {
            type: Array,
            default: () => []
        }
    },

    inject: {
        parentValidator: "parentValidator",
    },

    mixins: [updatableForm],

    mounted() {
        this.$validator = this.parentValidator;
        if (isEmpty(this.entity)) this.loading = false;
    },

    data() {
        return {
            loading: true,
            form: {
                placeholders: [],
            },
        };
    },

    watch: {
        'form.placeholders' : {
            handler() {
                this.$emit('valid-alias', this.validateAlias())
            },
            deep: true
        }
    },

    created() {
        this.$validator.extend('ph_unique_name', {
            validate: (value) => {
              return value;
                const FOUND = this.form.placeholders.filter( ph => ph.name.toLowerCase() == value.toLowerCase());
                return {
                    valid: FOUND.length <= 1,
                    data: {
                        required: true,
                        message: `"${value}" ${this.$t('already exists.')}`
                    }
                };
            },
            getMessage: (field, params, data) => data.message,
        });
    },

    methods: {
        addNew() {
            const newPH = this.createPlaceholder();
            this.form.placeholders.push(newPH);
        },

        createPlaceholder(){
            return { name: "", max_length: 1, type: "string", can_be_empty: false, alias: [] };
        },

        async deletePlaceholder(ph, index){
            const res = await swal({
                title: ph.name == "" ? `${this.$t('Delete placeholder')}?` :`${this.$t('Delete placeholder')} "${ph.name}"?`,
                text: this.$t("This may affect some of the saved creativities."),
                showCancelButton: true,
                confirmButtonClass: "el-button el-button--primary",
                cancelButtonClass: "el-button el-button--default",
                confirmButtonText: this.$t("Yes"),
                reverseButtons: true,
                buttonsStyling: false,
                allowOutsideClick: false
            });
            if (!res.value) return;
            //if (index == 0) return;
            if (index > -1) {
                this.form.placeholders.splice(index, 1);
            }
        },

        afterModelPatched(){
            // Solo nos interesa quedarnos con los placeholders.
            const { placeholders } = this.form;
            this.form = { placeholders };
            this.loading = false;
        },

        validateAlias(){
            const ALL_ALIAS = [];
            this.form.placeholders.forEach( (ph, index) => {
                ALL_ALIAS.push(...ph.alias)
            });
            const FILTERED = uniq(ALL_ALIAS);
            const REPEATED = FILTERED.filter(element => ALL_ALIAS.filter(e => e === element).length > 1);
            return REPEATED.length == 0
        },

        isInUse(ph){
            if (this.creatives.length == 0) return false
            
            for (let i = 0; i < this.creatives.length; i++) {
                const keys = Object.keys(this.creatives[i]);
                for (let j = 0; j < keys.length; j++) {
                    if(!this.creatives[i][keys[j]].value) return false;
                    if ( keys[j] !== 'flow-json' && this.findInText( ph, this.creatives[i][keys[j]].value) ) return true
                }
            };

            return false
        },

        findInText(ph, text){
          if (!ph || !text) return false;
            return text.toLowerCase().includes(`{${ph.toLowerCase()}}`)
        }
    },

};
</script>

<style lang="scss" scoped>
:deep(.vue-input-tag-wrapper .input-tag) {
    background-color: var(--info);
    border-color: var(--info);
    color: var(--white);
}

:deep(.vue-input-tag-wrapper .input-tag .remove) {
    color: var(--white);
}

:deep(.btn-neutral:hover) {
    background-color: #f5f7fa !important;
}
</style>