<template>
    <SegmentationStep :disabled="disabled" :current-step="2" :hide-buttons="true">
        <div class="text-muted mb-2 mt-4">
            {{ $t("Exclude already subscribed users or blacklisted users, and define the amount you want for your new target base") }}
        </div>
        <div class="row">
                        <div class="col-md-12">
                            <SegmentationPipieline v-for="(pipeline, index) in this.form.crm_list_pipelines"
                                :key="index" 
                                :entity="pipeline"
                                @added="onAdded"
                                :crm-list-id="crmListId"
                                :index="index"
                                :processed="pipeline.processed" />
                        </div>

                        <!--
                        <div class="col-md-2 align-self-end">
                           <p-button type="dark"
                               @click="addPipeline"> Add another</p-button>
                        </div>
                        -->
                    </div>
    </SegmentationStep>
</template>

<script>
    import { cloneDeep } from 'lodash';

    import TabbedFormMixin from 'src/app-components/TabbedFormMixin';
    import { defaultMixin } from "src/app-components/FormMixin";

    import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
    import TabbedForm from 'src/app-components/TabbedForm';

    import SegmentationListName from "src/app-components/form-elements/SegmentationListName";
    import SegmentationStep from "src/app-components/segmentation-steps/SegmentationStep";
    import SegmentationPipieline from "src/app-components/form-elements/SegmentationPipeline";
    import Spinner from "src/app-components/Spinner";
    import api from "src/api";
    import _ from 'lodash'

    export default {
        mixins: [
            defaultMixin,
            TabbedFormMixin
        ],

        provide() { // Pass to Mixin
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },

        components: {
            [TabPane.name]: TabPane,
            TabbedForm,
            SegmentationListName,
            SegmentationPipieline,
            SegmentationStep,
            Spinner
        },

        props: {
            initialListData: {
                type: Object,
                default: undefined
            },

            initialTab: String,

            disabled: {
                type: Boolean,
                default: false
            },

            crmListId: {
                type: Number,
                default: null
            }
        },

        watch: {
            initialListData: {
                handler () {
                    const pipelines = this.initialListData ? _.cloneDeep(this.initialListData.pipelines) : [];
                    this.form.crm_list_pipelines = [ ...pipelines.map(p => {
                        return {
                            ...p,
                            processed: p.status == "GATHERING_INFORMATION_READY"
                        }
                    }), this.createCrmListPipeline() ];
                },
                immediate: true
            }
        },

        data() {
            return {
                form: {
                    crm_list_pipelines: [this.createCrmListPipeline()]
                },
                working: false
            }
        },

        methods: {
            createCrmListPipeline () {
                return {
                    count: null,
                    service_name: "",
                    processed: false
                };
            },

            getError(fieldName) {
                return this.errors.first(fieldName);
            },

            getTabs() {
                return {
                    configuration: false,
                }
            },

            async waitForListToBeProcessed (id) {
                let segmentationList;
                do {
                    await this.sleep(1000);
                    segmentationList = await api.getSegmentationList(id);
                    console.log("Current status " + segmentationList.data.status);
                } while (segmentationList.data.status != "GATHERING_INFORMATION_READY");
            },

            sleep(ms) {
                return new Promise(function(resolve) {
                    setTimeout(resolve, ms);
                });
            },

            handleRulesUpdated (data) {
                this.form.rules = data;
            },

            onUploaded (res) {
                this.form.account_user_file_id = res.data.id;
            },

            onAdded (index) {
                this.form.crm_list_pipelines[index].processed = true;
                this.form.crm_list_pipelines.push(this.createCrmListPipeline());
            }
        }
    }
</script>

<style>

</style>
