<script>
import Vue from "vue";
import api from "src/api.js";

const kebabCase = string => string.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase();
const registered = {};

export default {
    name: "remote-svg",
    props: {
        name: String
    },
    render : function(createElement){
        var cname = 'svg-'+kebabCase(this.name);
        if(!registered[cname]){
            Vue.component(cname, async (resolve, reject) => {
                const res = await api.getSvg(this.name);
                resolve({template: res.rawxml});
            });
            registered[cname] = true;
        }
        return createElement(
            cname,
            this.$slots.default
        );
    }
};
</script>