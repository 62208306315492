<template>
  <div class="card view-services">
    <div class="card-header">
      <p class="mb-0">{{$t("View Services")}}</p>
    </div>
    <div class="card-body">
      <el-table :data="services" style="width: 100%">
        <el-table-column :label="$t('Name')" width="250px;">
          <template slot-scope="scope">
            <span
              v-if="scope.row.id !== serviceSelected.id"
              class="service-name"
              >{{ scope.row.name }}
            </span>
            <fg-input
              v-else
              type="text"
              name="name"
              v-model="serviceSelected.name"
            />
          </template>
        </el-table-column>

        <el-table-column :label="$t('Alias')">
          <template slot-scope="scope">
            <div class="form-group" style="width: 100%;">
              <input-tag
                class="w-100 form-control"
                :value="getAlias(scope.row.alias)"
                :read-only="scope.row.id !== serviceSelected.id"
                @input="updateAlias"
                v-if="scope.row.id !== serviceSelected.id"
              ></input-tag>

              <input-tag
                class="w-100 form-control"
                v-model="serviceSelected.alias"
                v-else
              ></input-tag>
            </div>
          </template>
        </el-table-column>
        
        <el-table-column label="Last modification" width="250px">
          <template slot-scope="scope">
            <div class="form-group">
              <p>{{ lastModified(scope.row.modified) }}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="" align="right" width="190px;">
          <template slot-scope="scope">
            <template v-for="btn in actions">
              <button
                v-if="
                  (serviceSelected.id === scope.row.id &&
                    btn.view !== 'index') ||
                    (serviceSelected.id !== scope.row.id &&
                      btn.view === 'index')
                "
                class="btn"
                :class="btn.class"
                style="width: 80px;"
                @click="btn.action(scope.row)"
              >
                {{ $t(btn.text) }}
              </button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Button } from "element-ui";
import InputTag from "vue-input-tag";
import swal from "sweetalert2";
export default {
  name: "ViewServices",
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    InputTag,
    [Button.name]: Button,
  },

  data() {
    return {
      serviceSelected: {
        id: null,
        name: "",
        alias: [],
      },
    };
  },

  methods: {
    editService(service) {
      this.serviceSelected.id = service.id;
      this.serviceSelected.name = service.name;
      this.serviceSelected.alias = this.getAlias(service.alias);
    },

    async deleteService({ id }) {
      swal({
        title: this.$t("Delete?"),
        text: this.$t("Are you sure you want to delete this service?"),
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Yes, delete it!"),
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          await api.deleteSubscriptionServices(id);
          this.updateList();
        }
      });
    },

    async updateService(service) {
      let data = { ...this.serviceSelected };
      let aliasList = [];
      this.serviceSelected.alias.forEach((alias) => {
        const index = service.alias.findIndex(
          (sAlias) => sAlias.alias === alias
        );
        index === -1
          ? aliasList.push(alias)
          : aliasList.push(service.alias[index]);
      });

      data.alias = aliasList.length ? [...aliasList] : [...service.alias];
      if(data.name === '') {
        data.name = service.name;
      }
      const res = await api.updateSubscriptionServices(
        this.serviceSelected.id,
        data
      );
      this.updateList();
      this.setAlert(res.status, res.message);
      this.clearService();
    },

    clearService() {
      this.serviceSelected = {
        id: null,
        name: "",
        alias: [],
      };
    },
    getAlias(alias) {
      return alias.map((item) => item.alias);
    },

    updateAlias(alias) {
      this.serviceSelected.alias = [...alias];
    },

    updateName(name) {
      this.serviceSelected.name = name;
    },

    updateList() {
      this.$emit("updateServices");
    },

    setAlert(status, message) {
      const icon = status ? "success" : "error";
      const title = status
        ? "Service updated successfully"
        : "An error occurred";
      const text = status ? "" : message;

      swal.fire({
        icon,
        title: this.$t(title),
        text,
      });
    },

    lastModified(date) {
      if(!date) return date;
      const dateTime = new Date(date);
      return dateTime.toLocaleString();
    },
  },

  computed: {
    actions() {
      return [
        {
          class: "",
          text: "Cancel",
          action: this.clearService,
        },
        {
          class: "btn-info ml-2",
          text: "Save",
          action: this.updateService,
        },
        {
          class: "",
          text: "Delete",
          action: this.deleteService,
          view: "index",
        },
        {
          class: "btn-info ml-2",
          text: "Edit",
          action: this.editService,
          view: "index",
        },
      ];
    },
  },
};
</script>
<style scoped>
.service-name {
  color: #66615b;
  font-size: 14px;
  display: block;
  width: 100%;
  margin-left: 6px;
  margin-bottom: 8px;
}
</style>

<style>
.view-services .form-group .form-control {
  padding: 5px;
}

.view-services .form-group .form-control[disabled],
.view-services .form-group .vue-input-tag-wrapper.read-only {
  background: transparent;
  border: 1px solid transparent;
}

.view-services .form-group .vue-input-tag-wrapper .new-tag {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.view-services .form-group .vue-input-tag-wrapper .input-tag {
  background: #51bcda;
  border-color: #51bcda;
  color: white;
  margin-bottom: 0;
}

.view-services .form-group .vue-input-tag-wrapper .input-tag .remove {
  color: white;
}
</style>
