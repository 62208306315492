import Vue from "vue";
import Vuex from "vuex";
import storage from "./storage";
import { abilityPlugin, ability as appAbility } from "./ability";
import api from 'src/api'

Vue.use(Vuex);

const ability = appAbility;

const store = new Vuex.Store({
  //strict: true,
  plugins: [
    storage({
      storedKeys: ["rules"],
      destroyOn: ["destroySession"]
    }),
    abilityPlugin
  ],

  actions: {
    setTopNavbarTitle({ state }, title) {
      state.topNavbar.title = title;
    },
    loadBookmarks({ state, commit }, force = false) {
      if (state.bookmarks !== null && !force) {
        return Promise.resolve(state.bookmarks);
      }

      const promise = api.getTemplates();

      commit("setBookmarks", promise);

      promise.then(response => {
        if (response.data.length > 0) {
          commit("setBookmarks", response.data);
        }
      }).catch(err => {
        console.error(err);
      });

      return promise;
    }
  },

  state: () => ({
    dashboard: {
      charts: []
    },

    topNavbar: {
      title: "DYNAMO AdServer"
    },
    appUser: null,
    jwt: null,
    user: null,
    isLoadingAuth: true,
    rules: [],
    bookmarks: null,
    currentBookmark: null,
    channels: [],

    channelName: [
      'S@T Push',
      'SMS',
      'Flash SMS',
      'InteracTIM',
      'Over The Air',
      'RCS',
    ]
  }),

  mutations: {
    setAdserverVariables(state, variables) {
      Vue.set(state.adserver, "variables", variables)
    },

    createSession(state, session) {
      state.isLoadingAuth = false;
      state.user = session;
      state.rules = session.rules;
      state.channels = session.channels || [];
    },

    updateUserData(state, newData){
      state.user = {
        ...state.user,
        ...newData
      }
    },

    setLoadingAuth(state, value = true) {
      state.isLoadingAuth = value;
    },

    destroySession(state) {
      state.user = null;
      //state.user.rules = [];
    },

    // Deprecate this. use action instead
    setTopNavbarTitle(state, title) {
      state.topNavbar.title = title;
    },

    setBookmarks(state, bookmarks) {
      Vue.set(state, 'bookmarks', bookmarks);
    },

    setCurrentBookmark(state, bookmark) {
      Vue.set(state, 'currentBookmark', bookmark);
    },

    resetCurrentBookmark(state) {
      Vue.set(state, 'currentBookmark', null);
    }
  },

  getters: {
    adserver: state => state.adserver,
    dashboard: state => state.dashboard,
    topNavbar: state => state.topNavbar,
    appUser: state => state.user,
    jwt: state => state.jwt,
    appUserTimezone: state => {
      return (
        state.user ?
        state.user.account.operator.country.timezone :
        null
      );
    },
    appUserLanguage: state => {
      return (
        state.user ?
        state.user.default_language :
        'en'
      );
    },
    rules: state => state.rules,
    activeBookmarks: state => {
      if (Array.isArray(state.bookmarks)) {
        return state.bookmarks.filter(bookmark => !bookmark.archived);
      }

      return [];
    },
    getBookmarkById: state => id => (Array.isArray(state.bookmarks) ? state.bookmarks : []).find(bookmark => bookmark.id === id),

    getCurrentBookmark: state => {
      return state.currentBookmark
    },

    getChannelName: state => channel_id => {
      if (!channel_id) {
        return null;
      }
      return state.campaignType[channel_id - 1];
    },
    setAppuser: state => user => {
      if (user) {
        state.appUser.email = user.email;
        state.appUser.firstname = user.firstname;
        state.appUser.lastname = user.lastname;
        state.appUser.file_path = user.file_path;
      }
    }
  },
});

export { store, ability };
