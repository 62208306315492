const CrmMixin = {

    $_veeValidate: {
        validator: 'new'
    },

    data() {
        const dict = {
            en: {
                messages: {
                    required: 'This field is required'
                }
            },
        };
        return {
            dict
        }
    },

    created() {
        this.$validator.localize(this.dict);
        console.log(this.$validator);
    },

    methods: {

        async validateBeforeSubmit(scope, mandatoryFields, max_fails = 0) {

            // mandatoryFields debe ser { name , value, mandatory, rules}

            const validations = [];
            await this.asyncForEach(mandatoryFields, async (element) => {
                validations.push(
                    {
                        field: element.name,
                        result: await this.$validator.verify(element.value, element.rules),
                        mandatory: element.mandatory
                    }
                );
            });
            // console.log("VALIDATIONS", validations);

            const FAILED_VALIDATIONS = validations.filter(element => !element.result.valid);
            // console.log("FAILED_VALIDATIONS", FAILED_VALIDATIONS);

            const IMPORTANT_FIELD_EXIST = FAILED_VALIDATIONS.some(element => element.mandatory);
            // console.log("IMPORTANTFIELD", IMPORTANT_FIELD_EXIST);

            // Si la cantidad de campos incompletos es menor a la cantdad permitida && no hay campos importantes sin completar => true
            let okToGo;
            if (scope == 'campaign') {
                okToGo = (FAILED_VALIDATIONS.length <= max_fails && !IMPORTANT_FIELD_EXIST) ? true : false;
            } else if (scope == 'events') {
                okToGo = (FAILED_VALIDATIONS.length <= max_fails || !IMPORTANT_FIELD_EXIST) ? true : false;
            };

            if (!okToGo) {
                FAILED_VALIDATIONS.forEach(element => {
                    this.$validator.validate(`${scope}.${element.field}`);
                });
            }

            return okToGo;
        },

        notification(val) {
            let msg = {};

            msg.title = val ? this.$t("Submitted") : this.$t("Can't submit");
            msg.message = val ? this.$t("The query has been submitted.") : this.$t("Please complete required fields.");
            msg.type = val ? "success" : "danger";
            msg.icon = val ? "fa fa-check" : "fa fa-exclamation";
            msg.horizontalAlign = "right";
            msg.verticalAlign = "top";

            this.$notify(msg);
        },

        async asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        },

        getError(fieldName) {
            return this.$t(this.errors.first(fieldName))
        },

        hasError(fieldName) {
            return this.errors.has(fieldName);
        },

        clearFields(queryRequest, scope, vmId) {
            for (const property in queryRequest) {
                // console.log(property, typeof queryRequest[property]);
                if (Array.isArray(queryRequest[property])) {
                    queryRequest[property] = []
                } else {
                    switch (typeof queryRequest[property]) {
                        case 'number':
                            queryRequest[property] = null
                            break;
                        case 'string':
                            queryRequest[property] = ""
                            break;
                        case 'object':
                            queryRequest[property] = {}
                            break;
                        default:
                            // queryRequest[property] = false
                            break;
                    }
                }
                
            };
            this.$validator.errors.clear(scope);
            this.$validator.reset({ scope, vmId });
        },

        copyFields(user_query, queryRequest) {

            for (const key in user_query) {
                // console.log("KEY : ", key);
                if (queryRequest[key] !== undefined) {
                    // console.log(`OLD VALUE ${queryRequest[key]} ----> NEW VALUE ${user_query[key]}`);
                    queryRequest[key] = user_query[key]
                }
            };
            this.copyDateRange(user_query);
        },

        // Must override if daterange
        copyDateRange() { }
    },
};

const CampaignValidations = {

    created() {

        this.$validator.extend('campaign_date_range', {
            validate: (value = []) => {
                const res = {
                    valid: this.dateRange.length !== 0 || this.queryRequest.campaign_id.length !== 0,
                    data: {
                        required: true,
                        message: `» ${this.$t('One of this fields is required')}`
                    }
                };
                // console.log("campaign_date_range", res);
                return res
            },
            getMessage: (field, params, data) => data.message,
        }, { computesRequired: true });
    },
};

const EventsValidations = {
    created() {
        this.$validator.extend('event_date_range', {
            validate: (value = []) => {
                const res = {
                    valid: this.dateRange.length !== 0 || this.queryRequest.status.length !== 0 && this.queryRequest.events.length !== 0,
                    data: {
                        required: true,
                        message: `» ${this.$t('Only required if no events are selected.')}`
                    }
                };
                // console.log("campaign_date_range", res);
                return res
            },
            getMessage: (field, params, data) => data.message,
        }, { computesRequired: true });

        this.$validator.extend('event_status', {
            validate: (value = []) => {
                const res = {
                    valid: this.dateRange.length !== 0 && this.queryRequest.events.length !== 0 || this.queryRequest.status.length !== 0,
                    data: {
                        required: true,
                        message: `» ${this.$t('Only required if no events are selected.')}`
                    }
                };
                // console.log("campaign_date_range", res);
                return res
            },
            getMessage: (field, params, data) => data.message,
        }, { computesRequired: true });
    },
}

export { CrmMixin, CampaignValidations, EventsValidations };
