<template>
  <div>
    <Pivot :showFilters="false"  withReport="Live" />
  </div>
</template>

<script>
  import { QueryBuilder } from "x-query-builder";
  import { flow } from "lodash";
  import Pivot from "src/app-components/Pivot";
  import moment from 'moment';
  import api from "src/api";
  import { mapMutations } from "vuex";

  export default {
    components: {
      Pivot
    },

    mounted() {
      this.setTopNavbarTitle(this.$t("Live campaigns"));
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),
    }
  };
</script>

<style>
</style>
