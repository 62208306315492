<template>
  <div :class="row_classes">
      <div :class="column_classes">
          <label v-if="label">{{ $t(label) }}</label>
          <fg-input :error="error" :class="formGroupClass">
              <el-date-picker  v-model="value2"
                  :clearable="clearable"
                  :type="inputType"
                  :disabled="disabled"
                  :size="size"
                  :placeholder="$t('Pick a day')"
                  :start-placeholder="$t('Start date')"
                  :end-placeholder="$t('End date')"
                  align="right"
                  :picker-options="enableShortcuts ? pickerOptions : null"
                  @change="change(inputEventName, $event)">
              </el-date-picker>
          </fg-input>
      </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import { DatePicker } from 'element-ui';
  import moment from 'moment';
  import { CurrentMonth, Yesterday } from "src/app-components/DateRangePresets";

  export default {
      components: {
          [DatePicker.name]: DatePicker
      },

      props: {
          enableShortcuts: {
              type: Boolean,
              default: true
          },
          inputType:{
              type: String,
              default: 'daterange'
          },
          placeholder: {
              type: String,
              default: ''
          },
          size: {
              type: String,
              default: 'large'
          },
          column_classes: {
              type: String,
              default: 'col-md-12'
          },
          row_classes: {
              type: String,
              default: 'row'
          },
          autoTranslateShortcuts: {
              type: Boolean,
              default: false
          },
          shortcuts: {
              type: Array,
              default: () => [
              {
                  text: 'Yesterday',
                  onClick(picker) {
                      const [ start, end ] =  Yesterday();
                      picker.$emit('pick', [start, end]);
                  }
              },

              {
                  text: 'Today',
                  onClick(picker) {
                      const start = moment();
                      const end = moment();
                      picker.$emit('pick', [start, end]);
                  }
              }, {
                  text: 'Last week',
                  onClick(picker) {
                      const start = moment().subtract(1, 'week').startOf('week').add(1, 'day');
                      const end = moment().subtract(1, 'week').endOf('week').add(1, 'day');
                      picker.$emit('pick', [start, end]);
                  }
              }, 
              {
                  text: 'This month',
                  onClick(picker) {
                      const [start, end] = CurrentMonth();
                      picker.$emit('pick', [start, end]);
                  }
              },
              {
                  text: 'Last month',
                  onClick(picker) {
                      const start = moment().subtract(1, 'month').startOf('month');
                      const end = moment().subtract(1, 'month').endOf('month');
                      picker.$emit('pick', [start, end]);
                  }
              }, {
                  text: 'Last 3 months',
                  onClick(picker) {
                      const start = moment().subtract(3, 'month').startOf('month');
                      const end = moment().subtract(1, 'month').endOf('month');
                      picker.$emit('pick', [start, end]);
                  }
              }]
          },
          initialValue: {
              type: null,
              default: ''
          },
          label: {
              type: String,
              default: "Date Range"
          },
          error: {
              type: String,
              default: null
          },
          inputEventName: {
              type: String,
              default: "date-range-changed"
          },
          clearable: {
              type: Boolean,
              default: true
          },
          disabled: {
              type: Boolean,
              default: false
          },
          formGroupClass: {
              type: String,
              default: ""
          }
      },

      computed: {
          pickerOptions () {
              if (!this.autoTranslateShortcuts) {
                  return { shortcuts: this.shortcuts };
              }
              return {
                  shortcuts: this.shortcuts.map(s => ({ ...s, text: this.$t(s.text) }))
              };
          }
      },

      data() {
          return {
              value2: this.initialValue,
          }
      },

      methods: {
          change(inputEventName, $event) {
           if ($event) {
             if ($event.length === 2) {
               let start = new Date($event[0]);
               let end = new Date($event[1]);
               if (start.getTime() === end.getTime()) {
                 end.setHours(23);
                 end.setMinutes(59);
                 end.setSeconds(59);
                 $event[1] = end;
               }
             }
           }
         
           this.$emit(inputEventName, $event);
          },
          setDaterange(daterange){
              // console.log(daterange);
              this.value2 = daterange;
          }
      },
  }
</script>

<style scoped>
  .el-date-editor {
      padding: 0px 0px 0px 10px !important;
  }
</style>