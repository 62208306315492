<template>
  <div>
    <form-campaign
      :tabbed-form-save-button-disabled="workingSave"
      :hide-bookmark-info="!isEditable"
      title="Create a new campaign"
      @submit="onSubmit" 
      :initialBookmarkData="initialBookmarkData"
      :bookmarkProps="{ isEditable }">
    </form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from 'src/app-components/FormApiEvent';
import RetryCrudMixin from 'src/app-components/RetryCrudMixin';
import FormatTemplateMixin from "src/app-components/FormatTemplateMixin";
import WorkingMixin from 'src/app-components/WorkingMixin';
import { mapMutations } from "vuex";
import { cloneDeep } from "lodash";

export default {
  components: {
    FormCampaign
  },

  mixins: [
    RetryCrudMixin(),
    WorkingMixin("Save"),
    FormatTemplateMixin,
  ],

  mounted () {
    this.setTopNavbarTitle(this.$t("Create external event"));
  },

  async created() {
      if ("bookmarkId" in this.$route.params) {
        this.bookmarkId = this.$route.params.bookmarkId;
        const response = await api.getTemplate( this.$route.params.bookmarkId)
        this.initialBookmarkData = response.data
      }
    },

    computed: {
      isEditable() {
        return this.bookmarkId === null;
      }
    },

    data() {
        return {
            initialBookmarkData: {},
            bookmarkId: null,
        }
    },

  methods: {
    async onSubmit(data) {
      try {
        this.setWorkingSave(true)
        const editBookmark = this.bookmarkId === null;
        let form = cloneDeep(data);
        if(editBookmark) {
          form.bookmark = this.formatTemplate(form.bookmark);
        }
        const res = await api.createApiEvent(form, editBookmark);
        this.$router.push({ path: `/api-events/planning` });
      } finally {
        this.setWorkingSave(false)
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  }
}
</script>

<style>

</style>
