<template>
  <div>
    <form-campaign-ping
      @submit="onSubmit"
      :tabbed-form-save-button-disabled="workingSave"
      :initialCampaignData="initialCampaignData"
      :initialTargetData="initialTargetData"
      :initialRetryData="initialRetryData"
      :initial-tab="$route.params.tab"
      :disabled="disabled"
    >
    </form-campaign-ping>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaignPing from "src/app-components/FormCampaignPing";
import constants from "src/views/campaigns/constants";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import { mapMutations } from "vuex";
import WorkingMixin from "src/app-components/WorkingMixin";

export default {
  components: {
    FormCampaignPing,
  },

  mixins: [RetryCrudMixin(), WorkingMixin("Save")],

  async created() {
    if ("id" in this.$route.params) {
      const response = await api.getPingCampaign(this.$route.params.id);
      this.initialTargetData = response.data.files;
      this.initialCampaignData = response.data;
      this.initialRetryData = response.data.children[0];
    }
  },

  computed: {
    disabled() {
      return !constants.StatusIdCanEditCampaign.includes(
        this.initialCampaignData.status_id
      );
    },
  },

  mounted() {
    const name =
      this.$route.name === "CampaignEditFromPing"
        ? "Edit a ping campaign"
        : "Edit a OTA campaign";
    this.setTopNavbarTitle(this.$t(name));
  },

  data() {
    return {
      initialCampaignData: {},
      initialTargetData: [],
      initialRetryData: {},
    };
  },

  methods: {
    async onSubmit($event) {
      try {
        this.setWorkingSave(true);
        const id = $event.id;
        await api.updatePingCampaign(id, { ...$event, children: undefined });
        await this.processOperationLog(id, $event.children);
        this.$router.push({ path: `/campaigns/planning` });
      } finally {
        this.setWorkingSave(false);
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
