<template>
    <span>
        <p-button  
            :disabled="working"
            type="danger" 
            :size="size" icon @click="handleRemove()">
            <i v-if="working" class="fa fa-hourglass-end"></i>
            <i v-else class="fa fa-remove"></i>
        </p-button>
    </span>
</template>

<script>
import api from "src/api"
import FileDownload from 'js-file-download'
import Spinner from "src/app-components/Spinner";
import swal from "sweetalert2";

export default {
    components: {
        Spinner
    },
    props: {
        id: {
            type: Number,
        },
        size: {
            type: String,
            default: "sm"
        },
        deleteFile: {
          type: Boolean,
          default: true
        }
    },

    data () {
        return {
            working: false
        }
    },

    methods: {
        async handleRemove() {
            this.working = true;
            const confirmResult = await swal({
                title: this.$t("Delete?"),
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: this.$t("Yes, delete it!"),
                buttonsStyling: false,
                allowOutsideClick: false
            });

            if (!confirmResult.value){
                this.working = false;
                return;
            }



            if(this.deleteFile) {
              await api.deleteUpload(this.id)
            }

            this.$emit("deleted", this.id);
            this.working = false;
        }
    }
}
</script>
