<template>
    <div>
        <DateProgrammable v-model="dateProgrammed"
            :disabled="disabled"
            :showManualStart="false"
            :start-date-time-name="`start_datetime_retry-${name}`"
            :end-date-time-name="`end_datetime_retry-${name}`"
            :requiredEndtime="parentCampaignData.activePing"
            :start-after-date="parentCampaignData.endDateTime"
            :disabled-dates="true"
        />
    </div>
</template>

<script>
    import DateProgrammable from 'src/app-components/form-elements/DateProgrammable'
    import DateProgrammedMixin from 'src/app-components/DateProgrammedMixin'
    import { cloneDeep } from 'lodash'

    export default {
        name: "ProgrammedRetry",
        mixins: [
            DateProgrammedMixin("internalDateProgrammable")
        ],
        components: {
            DateProgrammable
        },
        props: {
            dateProgrammable: {
                type: Object,
            },
            name: {
                type: String,
                default: undefined
            },
            parentCampaignData: Object,
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                internalDateProgrammable: cloneDeep(this.dateProgrammable)
            }
        },
        watch: {
            dateProgrammed () {
                this.$emit("update:dateProgrammable", this.dateProgrammed);
            },
        }
    }
</script>

<style scoped>

</style>
