<template>
    <div>
        <slot></slot>
        <p-button type="info" @click="handleSubmit">{{ $t('Save') }}</p-button>
    </div>
</template>
<script>
    export default {
        inject: ["parentValidator"],
        components: {
        },
        created () {
            this.$validator = this.parentValidator;
        },
        props: {
        },
        methods: {
            async handleSubmit() {
                const isValid = await this.$validator.validateAll();
                if (isValid) this.$emit('submit');
            },
        }
    }
</script>
<style>

</style>
