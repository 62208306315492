<template>
    <div>
        <modal :show.sync="show" headerClasses="justify-content-center">
            <template slot="caller">
                <div class="d-inline-flex">
                    <p-button type="success"
                                @click.native="onShowModal"
                                :disabled="(! scenarioID) || disabled">
                        <i class="fa fa-plus"></i> {{ $t('Add Creativity') }}
                    </p-button>
                </div>
            </template>
            <h4 slot="header" class="title title-up">
                {{ $t('Add a new Creativity') }}</h4>
            <scenario-creativities-elements
                    v-model="storedData"
                    :scenario="scenarioID"
                    width="col-md-12"
                    v-if="show"
            ></scenario-creativities-elements>
            <template slot="footer">
                <div class="row col-12">
                    <div class="col-6">
                        <button type="button" class="btn btn-dark" @click="onCancel">
                            <i class="fa fa-ban"></i> {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-success" @click="onSave">
                            <i class="fa fa-check"></i> {{ $t("OK") }}
                        </button>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";
    import CreativitiesElements from "src/app-components/form-elements/generators/ScenarioCreativitiesElements";

    export default {
        $_veeValidate: {
            validator: 'new'
        },

        provide() {
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },

        name: 'create-creativity-configuration',

        props: {
            scenarioID: Number,
            entity: Object,
            disabled: {
                type: Boolean,
                default: false
            }
        },

        components: {
            Modal,
            [CreativitiesElements.name]: CreativitiesElements,
        },

        watch: {
            entity(value) {
                if (null === value.id) return;

                this.storedData = {...value};
                // this.storedData = value.entity;
                this.onShowModal();
            }
        },

        data() {
            return {
                storedData: this.clearObject(),
                show: false
            }
        },

        methods: {
            getError(fieldName) {
                return this.errors.first(fieldName);
            },

            onShowModal() {
                this.show = true;
            },

            onSave() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        this.show = false;
                        this.$emit("modal-save", this.storedData);
                        this.storedData = this.clearObject();
                    }
                });
            },

            onCancel() {
                this.show = false;
                this.storedData = this.clearObject();
                this.$emit("modal-cancel");
            },

            clearObject() {
                return {};
            }
        }
    }
</script>