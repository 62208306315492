<template>
  <div class="row" style="overflow-x: hidden; position: static" v-if="user">
    <div class="col-12 col-md-5 col-lg-4 col-xl-3">
      <UserPreview :user="user" @updImage="newImg = $event"></UserPreview>
    </div>

    <div class="col-12 col-md-7 col-lg-8 col-xl-9">
      <UserAccount
        :user="user"
        @submit="submit($event)"
        @showModal="showModal"
        :newImg="newImg"
      ></UserAccount>
    </div>

    <ChangePassword ref="password" :user="user"></ChangePassword>
  </div>
</template>

<script>
import { Input } from "element-ui";
import UserPreview from "src/app-components/my-profile/UserPreview";
import UserAccount from "src/app-components/my-profile/UserAccount";
import ChangePassword from "src/app-components/my-profile/ChangePassword";
import api from "src/api";

import { mapMutations } from "vuex";

export default {
  components: {
    UserPreview,
    UserAccount,
    ChangePassword,
    [Input.name]: Input,
  },
  data() {
    return {
      newImg: "",
      initialLanguage: "",
    };
  },
  computed: {
    user(){
      return (
        this.$auth && this.$auth.user ?
        this.$auth.user :
        null
      );
    }
  },
  mounted() {
    this.setTopNavbarTitle(this.$t("My Profile"));
    //this.getUser();
  },
  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    /*async getUser() {
      let user = await api.getProfile();
      this.user = user;
      this.user.account = appUser.account;
      this.user.accounts = appUser.accounts;

      this.initialLanguage = this.user.default_language;
    },*/

    async submit(data) {
      if (this.newImg !== "") {
        data.file_path = this.newImg;
        data.file_name = `img-profile-${new Date().getTime()}.png`;
      }
      let res = await api.updateProfile(data);
      if (res.status) {
        this.$store.commit('updateUserData', res.data);
        this.$notify({
          title: this.$t("Congratulations!"),
          message: this.$t("Changes saved successfully"),
          type: "success",
        });
      }
    },

    showModal() {
      this.$refs.password.showModal();
    },
  },
};
</script>

<style scoped lang="scss">
$Y: 50px;
$image-height: 200px;

:deep(.image) {
  img,
  a {
    border-radius: 50%;
    height: $image-height;
    width: $image-height;
    /* padding: 1rem; */
    margin: auto;
    // border: 0.5em solid #307e99;
    /* box-shadow: 0px 1px 1px 0px black; */
    transform: translateY(-$Y);
    position: absolute;
  }
  img {
    box-shadow: 0px 5px 6px -5px #00000063;
    border: 0.5em solid white;
    object-fit: cover;
  }
  a {
    min-height: $image-height;
    min-width: $image-height;
    display: none;
    font-weight: 900;
    color: var(--gray);
    padding-top: 90px;
    &::before {
      content: "\f030";
      font: normal normal normal 14px/1 FontAwesome;
      display: block;
      clear: both;
    }
  }

  & > div:hover a {
    // backdrop-filter: blur(5px);
    display: block;
    background: #ffffff;
    text-align: center;
    filter: opacity(0.6);
    text-align: center;
    &:hover {
      text-decoration: none;
    }
  }
}

:deep(.card.user-image) {
  transform: translateY($Y);
  margin-bottom: $Y + 10px;
  .image {
    overflow: visible;
    height: $image-height - 56px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 70px;
    top: 20px;
    background-color: #307e99;
    position: absolute;
  }
}

:deep(.card:not(.user-image):hover .card-footer) {
  &.active {
    max-height: 500px;
    transition: max-height 1s ease-in;
  }
}
// /deep/ .card:not(.user-image):hover .card-footer {
//     max-height: 500px;
//     transition: max-height 1s ease-in;
// }

:deep(.card-footer) {
  padding: 0px;
  overflow: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  a {
    padding: 0.75rem 1.25rem;
    width: 100%;
    font-weight: 900;
  }
  & > div {
    display: flex;
    transition: all 0.5s ease;
  }
  &:hover {
    & > div {
      background-color: var(--info);
    }
    a {
      color: white;
      text-decoration: unset;
    }
  }
}
</style>
