export default (field) => {
  return {
    created() {
        console.log("Date programmed mixin created with " + JSON.stringify(field));
    },

    computed: {
        dateProgrammed: {
            get() {
                return {
                    manual_start: this[field].manual_start,
                    start_datetime: this[field].start_datetime,
                    end_datetime: this[field].end_datetime
                }
            },

            set(newValue) {
                this[field][newValue.prop] = newValue.value
            }
        }
    }
  };
};