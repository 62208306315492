<template>
  <div>
    <title-box
      componentName="DataStorage"
      :id="numberNodeID"
      :name="dataNode.name"
      @setNewName="setNewName($event)"
    />

    <div class="p-2">
      <div class="first-message" v-if="firstMessage">
        <p
          v-html="textMarked(firstMessage.text)"
          class="text-response mb-0"
        ></p>
      </div>
      <el-button
        round
        size="mini"
        class="my-1 w-100"
        v-else
        @click="activeOptions"
      >
        {{ $t("Create message") }}
      </el-button>
    </div>

    <p v-if="storage.variable" class="mb-0 py-1 px-2 variable">
      <span></span>
      @{{ storage.variable }}
      <span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="`Validation: ${storage.validation}`"
          placement="top"
          v-if="storage.validation"
        >
          <i class="el-icon-check" size="small"></i>
        </el-tooltip>
      </span>
    </p>

    <create-element-popover 
      output="1"
      :numberNodeID="numberNodeID" 
      v-if="viewPopover" 
    />
  </div>
</template>

<script>
  import { Input, Button } from "element-ui";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "DataStorage",
    mixins: [ChatbotComponentsMixin],
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
    },

    data() {
      return {
        dataNode: {
          messages: [
            {
              type: "text",
              text: "",
            },
          ],

          storage: {
            variable: "",
            validation: "",
            fallback: "",
          },
        },
      };
    },

    computed: {
      viewPopover() {
        if(this.dataNode) {
          if(this.dataNode.connections) {
            return this.dataNode.connections.length === 0
          }
        }
        return false;
      },

      storage() {
        if(this.dataNode) {
          if(this.dataNode.storage) {
            return this.dataNode.storage;
          }
        }

        return {
          variable: "",
          validation: "",
          fallback: "",
        }
      }
    }
  };
</script>

<style scoped>
  .wrapperCanvas .variable {
    font-size: 11px;
    font-weight: bold;
    background: #eee;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wrapperCanvas.dark .variable {
    background: #555;
    color: #ddd;
  }
</style>
<style>
  .wrapperCanvas .drawflow .drawflow-node.DataStorage.selected {
    -webkit-box-shadow: 0 2px 20px 2px #909399;
    box-shadow: 0 2px 20px 2px #909399;
  }
  
  .wrapperCanvas.dark .drawflow .drawflow-node.DataStorage.selected {
    -webkit-box-shadow: 0 2px 20px 2px #BB86FC;
    box-shadow: 0 2px 20px 2px #BB86FC;
  }
</style>
