<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <label>{{ label }}</label>
        <br>
        <p-switch
          @input="update($event)"
          v-model="local.value"
          type="primary"
          off-text="OFF"
          on-text="ON"
        ></p-switch>
      </div>
    </div>
  </div>
</template>

<script>
import PSwitch from "src/components/UIComponents/Switch.vue";
import { cloneDeep, tap, set } from "lodash";

export default {
  components: {
    PSwitch
  },
  props: {
    vname : {
        type : String,
        required : true
    },
    vid : {
        type : Number,
        required : true
    },
    value:{
      type: Object,
      default: () => ({})
    },

    label:{
      type: String
    },

    variables: {
      type: Array,
    },
  },

  computed: {
    local() {
      let defaultValue = {
        variable_id: this.vid,
        value: false,
        variable: {
          name: this.vname,
        }
      };
      if(!this.variables) return defaultValue;

      let value = this.variables.find(variable => {
        if(variable.variable) {
          return variable.variable.name === this.vname;
        }
      });
      return !value ? defaultValue : value;
    }
  },

  methods: {
    createStore(id, value) {
      return {
          variable_id: id,
          value: value
        };
    },

    update(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };
      this.$emit("input", input);
    },

    /* update(key, value) {
      console.log('key, value', key, value);

      this.$emit("input", tap(cloneDeep(this.local), v => set(v, this.vname, { variable_id: this.vid, value:value} )));
    } */
  }
};
</script>