<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Services') }}</label>
        <el-select
          size="large"
          v-validate="'required'"
          name="services"
          :value="local"
          @input="$emit('input', $event)"
          :disabled="disabled"
        >
          <el-option
            v-for="option in services"
            :value="option"
            :label="option"
            :key="option" />
        </el-select>
      </div>

      <div
        v-show="getError('services') != null"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ getError('services') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: String,
    disabled: Boolean
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getBookmarkServices();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  data() {
    return {
      services: []
    };
  },

  methods: {
    async getBookmarkServices() {
        const services = await api.getBookmarkServices();
        this.services = services.data
    }
  }
};
</script>

<style>
</style>
