import { cloneDeep } from "lodash";
const mixin = {
  computed: {
    dataNoSend() {
      return [
        "account_scenario",
        "scenario_type_id",
        "channel_id",
      ];
    },
    
    dataNoSendCommercialModel() {
      return [
        "commercial_model",
        "commercial_model_id",
        "price",
      ];
    },
  },

  methods: {
    formatTemplate(template, deleteCommercialModel = true) {
      const form = cloneDeep(template);

      if (form.creatives) {
        form.creatives.forEach((creative) => {
          if (creative.variables) {
            creative.variables.forEach((variables) => {
              try {
                // Agrego una propiedad a la creatividad con el nombre del campo que utiliza en la DB.
                creative[variables.creative_field] = JSON.parse(
                  variables.value
                );
              } catch (e) {
                creative[variables.creative_field] = variables.value;
              }
            });
            delete creative.variables;
          }
        });
      }

      // Elimino los objetos que no deberían ser modificados
      this.dataNoSend.forEach((data) => {
        if (data in form) {
          delete form[data];
        }
      });
      
      if(deleteCommercialModel) {
        this.dataNoSendCommercialModel.forEach((data) => {
          if (data in form) {
            delete form[data];
          }
        });
      }

      return form;
    },
  },
};

export default mixin;
