<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fg-input :label="$t('Description')"
                          :value="local"
                          :name="name"
                          @input="$emit('input', $event)"
                          v-validate="{ required: required }"
                          :error="getError(name)"
                          type="text"
                          maxlength="20"
                          :disabled="disabled" />
            </div>
        </div>
    </div>
</template>

<script>
    import api from "src/api";

    export default {
        inject: ["getError", "parentValidator"],

        props: {
            value: null,

            isUnique: {
                type: Boolean,
                default: true
            },

            required: {
                type: Boolean,
                default: true
            },

            name: {
                type: String,
                default: "event_description"
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        created() {
            this.$validator = this.parentValidator;


        },

        computed: {
            local() {
                return this.value ? this.value : null;
            }
        }
    }
</script>

<style>

</style>
