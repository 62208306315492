<template>
    <div>
        <div class="col-12">
            <label>{{ $t('Gap-time') }}</label>
            <fg-input :error="getError(name)">
                <el-time-select
                        :name="name"
                        @input="onInput('[time-gap].value', $event)"
                        v-model="timeGap"
                        :picker-options="datepickerOptions"
                        v-validate="required ? `required${validations}` : null"
                        placeholder="Select time">
                </el-time-select>
            </fg-input>
        </div>
    </div>
</template>

<script>
import { TimeSelect } from 'element-ui'
import { cloneDeep, tap, set } from "lodash";
import { Validator } from 'vee-validate';
import moment from 'moment';

export default {
    inject: ["getError", "parentValidator"],

    created() {
        this.$validator = this.parentValidator;

        Validator.extend('minGapTime', this.minValidator, { paramNames: ['min']});
        Validator.extend('maxGapTime', this.maxValidator, { paramNames: ['max']});
    },

    components: {
         [TimeSelect.name]: TimeSelect,
    },

    props: {
        value: {
            type: Object,
            default: function(){
                return {}
            }
        },
        startAt: { type: String, default: "00:00", required: false },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: "time-gap"
        },
        minMinutes: {
            type: Number,
            default: null
        },
        maxMinutes: {
            type: Number,
            default: null
        }
    },

    computed: {
        local() {
            if (this.value) {
                if (typeof this.value["time-gap"] === "undefined") {
                    this.value["time-gap"] = {
                        variable_id: 40,
                        value: ""
                    };
                }

                return this.value;
            }
        },

        timeGap: {
            get() {
                return this.local["time-gap"].value;
            },
            set(value) {
                this.local["time-gap"].value = value;
            }
        },
        
        validations () {
            let newValidations = "";
            if (this.minMinutes) {
                newValidations += `|minGapTime:${this.minMinutes}`;
            }
            if (this.maxMinutes) {
                newValidations += `|maxGapTime:${this.maxMinutes}`;
            }
            return newValidations;
        }
    },

    data() {
        const self = this;
        return {
            datepickerOptions: {
                start: this.startAt,
                step: '00:10',
                end: '02:00'
            },
            gapTimeToMinutes (value) {
                const values = value.split(':').map(Number);
                const minutes = (values[0] || 0) * 60 + (values[1] || 0);
                return minutes;
            },
            minutesToGapTime (value) {
                const hours = Math.floor(value / 60);
                const minutes = value - hours * 60;
                const pad = (e) => e < 10 ? ("0" + e) : e;
                return pad(hours) + ":" + pad(minutes);
            },
            minValidator: {
              getMessage(field, args) {
                  return `Can't be lower than ${self.minutesToGapTime(args[0])}`;
              },
              validate(value, { min }) {
                  return self.gapTimeToMinutes(value) >= min;
              }
            },
            maxValidator: {
              getMessage(field, args) {
                  return `Can't be greater than ${self.minutesToGapTime(args[0])}`;
              },
              validate(value, { max }) {
                  return self.gapTimeToMinutes(value) <= max;
              }
            }
        };
    },

    methods: {
        onInput(key, value) {
            this.$emit("input", tap(cloneDeep(this.local), v => set(v, key, value)));
        }
    }
}
</script>

<style>

</style>
