<template>
  <div>
    <span class="message">Start</span>
  </div>
</template>

<script>
import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";
  export default {
    name: "Start",
    mixins: [ChatbotComponentsMixin],
    data() {
      return {};
    },
  };
</script>

<style>
.wrapperCanvas .drawflow .drawflow-node.Start,
.wrapperCanvas.dark .drawflow .drawflow-node.Start {
  width: 0;
  border: 0;
  box-shadow: 0 2px 15px 2px transparent;
}


.wrapperCanvas .drawflow .drawflow-node.Start .message{
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-left: 11px;
  font-size: 16px;
  font-weight: bold;
}

.wrapperCanvas .drawflow .drawflow-node.Start .output {
  width: 75px;
  height: 75px;
}

.wrapperCanvas .drawflow .drawflow-node.Start .output:hover {
  background: white;
}

.wrapperCanvas .drawflow .drawflow-node.Start .drawflow-delete {
  display: none!important;
}

.wrapperCanvas.dark .drawflow .drawflow-node.Start .output,
.wrapperCanvas.dark .drawflow .drawflow-node.Start .output:hover {
  background: #555;
  border-color: #353535;
}

.wrapperCanvas.dark .drawflow .drawflow-node.Start .message {
  color: #ddd;
}

</style>