<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div class="col-7 col-md-8">
          <slot name="content">
            <div class="numbers">
              <p class="card-category">{{smallTitle}}</p>
              <p class="card-title">{{title}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <el-table
      v-if="headers.length > 0"
      class="table-striped"
      :data="value"
      style="width: 100%">
      <el-table-column type="index" v-if="showTableIndex"/>
      <el-table-column v-for="(header, index) in headers" :label="columnTitles[index]" :key="index">
        <template slot-scope="props">
          <!--
          {{ ! isNaN(props.row[header]) ? numberFormat(props.row[header].toString()) : props.row[header] }}
          -->
          {{ (formatters && formatters[header]) ? formatters[header].function(props.row[header], ...formatters[header].params) : props.row[header] }}
        </template>
      </el-table-column>
    </el-table>
    <div class="row" v-else>
      <div class="col-12">
        <div class="col-12">
          <div class="alert alert-primary">{{ $t('There are not templates to compare yet.') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array,
        description: 'Data list to show'
      },
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      showTableIndex: {
        type: Boolean,
        default: true,
        description: 'Show index of Table'
      },
      formatters: {
        type: Object,
        default: () => {}
      },
      columnTitles: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      headers() {
        if (null === this.value || typeof(this.value) === "undefined") return [];
        if (null === this.value[0] || typeof(this.value[0]) === "undefined") return [];
        return Object.keys(this.value[0]);
      }
    },
    methods: {
      /*
      numberFormat(stringNumber) {
        let value = stringNumber % 1 === 0 ? stringNumber.toString() : parseFloat(stringNumber).toFixed(2).toString();

        return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      */
    },
  };
</script>