<template>
    <SegmentationStep :disabled="disabled || working" @submit="onSubmit" :current-step="1">
        <div class="text-muted mb-2 mt-4">
            {{ $t("Select the original target base that you want to segmentate") }}
        </div>
        <div class="row">
            <div class="col-md-3">
                <SegmentationListName
                    label="Target base name"
                    v-model="form.name"
                    :required="true"
                    :disabled="disabled || working" />
            </div>

            <div class="col-md-4" v-if="isEdit">
                <label> {{ $t("File") }}</label>
                <fg-input name="file"
                    :disabled="true"
                    :value="placeholder"
                ></fg-input>
            </div>

            <div class="col-md-4" v-if="!isEdit">
                <fg-input :label="$t('File')" :error="getError('file')">
                    <BFormFile
                        v-validate="'required'"
                        name="file"
                        :placeholder="placeholder"
                        :disabled="disabled || working"
                        v-model="file"
                        accept=".txt" 
                    />
                </fg-input>
            </div>

            <div class="col-md-1" v-if="isEdit">
                <label> {{ $t("Total") }}</label>
                <fg-input name="_count"
                    :disabled="true"
                    :value="_count"
                ></fg-input>
            </div>
        </div>

        <div v-if="working">
            <div class="row justify-content-center align-items-center mt-5 mb-5">
                <div class="col-auto">
                    <Spinner :display="true" />
                </div>
                <div class="col-auto">
                    {{ $t("Processing list...") }}
                </div>
            </div>
        </div>
    </SegmentationStep>
</template>

<script>
    import { cloneDeep } from 'lodash';

    import TabbedFormMixin from 'src/app-components/TabbedFormMixin';
    import { defaultMixin } from "src/app-components/FormMixin";

    import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
    import TabbedForm from 'src/app-components/TabbedForm';

    import SegmentationListName from "src/app-components/form-elements/SegmentationListName";
    import SegmentationStep from "src/app-components/segmentation-steps/SegmentationStep";
    import Spinner from "src/app-components/Spinner";
    import api from "src/api";

    import { BFormFile } from 'bootstrap-vue';

    export default {
        mixins: [
            defaultMixin,
            TabbedFormMixin
        ],

        provide() { // Pass to Mixin
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },

        components: {
            [TabPane.name]: TabPane,
            TabbedForm,
            SegmentationListName,
            SegmentationStep,
            Spinner,
            BFormFile
        },

        props: {
            initialListData: {
                type: Object,
                default: undefined
            },

            initialTab: String,

            disabled: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            initialListData () {
                this.form.name = this.initialListData.name;
                this.form.account_user_file_id = this.initialListData.account_user_file_id;
                this.placeholder = this.initialListData.file.name;
                this._count =  this.$options.filters.formatNumber(this.initialListData.file._count);
                this.isEdit = true;
            }
        },

        data() {
            return {
                form: {
                    name: "",
                    rules: [],
                    account_user_file_id: null
                },
                _count: null,
                isEdit: false,
                working: false,
                file: null,
                placeholder: "No file chosen"
            }
        },

        methods: {
            getError(fieldName) {
                return this.errors.first(fieldName);
            },

            getTabs() {
                return {
                    configuration: false,
                }
            },

            async onSubmit() {
                const isValid = await this.$validator.validateAll();
                if (!isValid) return;

                this.working = true;
                const uploaded = await this.uploadFile();
                this.form.account_user_file_id = uploaded.data.id;

                let data = cloneDeep(this.form)
                data = {
                    ...data,
                    rules: data.rules.map(e => _.omit(e, 'id'))
                };
                const createResult = await api.createSegmentationList(data);

                await this.waitForListToBeProcessed(createResult.data.id);
                this.working = false;

                this.$emit("submit", createResult);
            },

            async waitForListToBeProcessed (id) {
                let segmentationList;
                do {
                    await this.sleep(1000);
                    segmentationList = await api.getSegmentationList(id);
                    console.log("Current status " + segmentationList.data.status);
                } while (segmentationList.data.status != "GATHERING_INFORMATION_READY");
            },

            async uploadFile () {
                const formData = new FormData();
                formData.append("attachment", this.file);
                const res = await api.uploadFile(formData, "SEGMENTATION_TARGET_BASE");
                return res;
            },

            sleep(ms) {
                return new Promise(function(resolve) {
                    setTimeout(resolve, ms);
                });
            },

            handleRulesUpdated (data) {
                this.form.rules = data;
            },

            onUploaded (res) {
                this.form.account_user_file_id = res.data.id;
            }
        }
    }
</script>

<style>

</style>
