<template>
    <modal :show-close="!working" :show="show" headerClasses="justify-content-center" @close="onCancel">
        <h4 slot="header" class="title title-up">{{ $t("Webhook configuration") }}</h4>

        <!-- components here -->
        <fg-input :label="$t('Name')"
                          v-model="dummy.name"
                          name="name"
                          v-validate="{ required: true }"
                          :error="getError('name')"
                          type="text"
                          :disabled="false" />

        <fg-input :label="$t('URL')"
                          v-model="dummy.url"
                          name="url"
                          v-validate="{ required: true, url: urlValidator }"
                          :error="getError('url')"
                          type="text"
                          :disabled="false" />

        <!-- <WebhookTriggers v-model="dummy.triggers" /> -->
        <fg-select :label="$t('Triggers')"
            :options="triggers"
            :multiple="true"
            v-model="dummy.triggers"
        />

        <WebhookAuth v-model="dummy.auth" />

        <Spinner :display="working" />
        
        <template slot="footer">
            <button :disabled="working" type="button" class="btn btn-dark" @click="onCancel"><i class="fa fa-ban"></i> {{ $t('Cancel') }}</button>
            <button :disabled="working" type="button" class="btn btn-success mr-3" @click="onSave"><i class="fa fa-check"></i>
                {{ $t('Save') }}
            </button>
        </template>
    </modal>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";
    import FGSelect from "src/components/UIComponents/Inputs/FormGroupSelect";
    import WebhookTriggers from "src/app-components/form-elements/WebhookTriggers"
    import WebhookAuth from "src/app-components/WebhookAuth"
    //import { BFormFile } from 'bootstrap-vue';
    import api from 'src/api'
    import Spinner from "src/app-components/Spinner";

    export default {
        name: 'webhook-configuration',

        provide() {
            return {
                parentValidator: this.$validator,
                parentErrors: this.errors
            };
        },

        props: {
            show: Boolean,
            model: Object
        },

        components: {
            Modal,
            WebhookTriggers,
            //BFormFile,
            [FGSelect.name]: FGSelect,
            Spinner,
            WebhookAuth
        },

        data() {
            return {
                working: false,
                triggers: [
                    { id: 1, name: "SENT" }, //XQ NO USARON LOS IDS DE ESTADO!!!!
                    { id: 2, name: "DELIVERED" }, //XQ NO USARON LOS IDS DE ESTADO!!!!
                    { id: 3, name: "ACCEPTED" }, //XQ NO USARON LOS IDS DE ESTADO!!!!
                    { id: 24, name: "Browser Launched" },
                ],
                //modelo editable
                dummy : Object.assign({
                    name: "",
                    url: "",
                    triggers: [],
                    auth: null
                }, this.model || {}),
                urlValidator: {
                    protocols: ['http','https'],
                    require_tld: false,
                    require_protocol: true,
                    require_host: true,
                    require_valid_protocol: true,
                    allow_underscores: true,
                    host_whitelist: false,
                    host_blacklist: false,
                    allow_trailing_dot: true,
                    allow_protocol_relative_urls: false,
                    disallow_auth: true
                }
            };
        },

        mounted(){
            console.debug("dummy: ", this.dummy);
        },

        watch: {
            model(newVal, oldVal) {
                this.$validator.reset()
                let newDummy = Object.assign({
                    name: "",
                    url: "",
                    triggers: [],
                    auth: null,
                }, newVal || {});

                if(newVal && newVal.authType){
                    newDummy.auth = {
                        type : newVal.authType.id,
                        params : newVal.auth_params,
                    };
                }

                this.dummy = newDummy;
                console.debug("new dummy: ", newDummy);
            },
            /*show(){
                if (this.show) {
                    this.$validator.reset();
                }
            }*/
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            async onSave() {
                //const valid = await this.$validator.validateAll()
                //if (!valid) {
                //    console.debug("Can't save: there are validation errors");
                //    return;
                //}
                let data = JSON.parse(JSON.stringify(this.dummy));
                if(data.auth){
                    data.auth_id = data.auth.type;
                    data.auth_params = data.auth.params;
                }
                console.debug("A salvar: ", data);
                //this.working = true;
                //await api.post("/account/webhooks", this.dummy);
                //this.working = false;
                this.$emit("save", data);
            },

            onCancel() {
                this.$emit("cancel");
            },

        }
    }
</script>

<style scoped>
</style>