<script>
import BaseFlow from "./BaseFlow";

export default {
    extends: BaseFlow,

    name: "RejectFlow",

    methods: {
        /**
         * @override
         */
        d() {
            return "reject";
        },

        /**
         * @override
         */
        listening() {
            return "d-selected-reject";
        },
        
        getDefaultModel(){
            return {
                active: false,
                trigger: "REJECTION",
                screen: null,
                action: "REPEAT_SCREEN",
                product: {}
            };
        },
    }    
}
</script>