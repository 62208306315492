<template>
  <div>
    <FormPerformanceCampaign @submit="onSubmit" />
  </div>
</template>

<script>
import FormPerformanceCampaign from "src/app-components/FormPerformanceCampaign";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import api from "src/api";

export default {
  components: {
    FormPerformanceCampaign,
  },

  mixins: [RetryCrudMixin()],

  methods: {
    showNotify() {
      this.$notifications.clear();
      this.$notify({
        message:
          "Congratulations! You have successfully created a programmatic campaign.",
        icon: "fa fa-gift",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "info",
      });
    },

    async onSubmit(data) {
      const retries = data.retries;
      delete data.retries;
      api
        .createPerformanceCampaign(data)
        .then(async (res) => {
          if (res.status == true) {
            if (retries) {
              await this.processOperationLog(res.data.id, retries);
            }
            if (data.manual_start === true) {
              swal({
                title: "You have selected manual start",
                text: `Do you want to start your campaign right away?`,
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, start now!",
                cancelButtonText: "No, maybe later",
                buttonsStyling: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.value) {
                  api.startPerformanceCampaign(res.data.id).then((res) => {
                    if (res.status == true) {
                      this.showNotify();
                      this.$router.push({ path: `/campaigns-manager/index` });
                    }
                  });
                }
              });
            }

            this.showNotify();
            this.$router.push({ path: `/campaigns-manager/index` });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style></style>
