<template>
    <div>
      <BookmarkName :is-unique="false"
                    v-model="filters.name" />

       <Advertisers
          filterable
          v-if="$can('FilterByAdvertiser', 'Bookmarks')"
          clearable
          no-validation
          v-model="filters.advertiser"
      />

      <Categories
          filterable
          clearable
          no-validation
          v-model="filters.category"
      />

      <SubCategories
          filterable
          clearable
          no-validation
          v-model="filters.subcategory"
          :categoryId="filters.category"
      />

      <CommercialModel v-model="filters.commercial_model"
                       :required="false"/>

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="form-group has-label">
            <label>Price range ${{ filters.price[0] }} - ${{ filters.price[1] }}</label>
            <el-slider class="slider-info"
                       v-model="filters.price"
                       :show-tooltip="false"
                       :max="maxPrice"
                       range>
            </el-slider>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import {Slider} from 'element-ui';
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";
  import AdvertisersFormComponent from "src/app-components/form-elements/Advertisers";
  import BookmarkName from "src/app-components/form-elements/BookmarkName";
  import CategoriesFormComponent from "src/app-components/form-elements/Categories";
  import SubCategoriesFormComponent from "src/app-components/form-elements/SubCategories";
  import CommercialModel from "src/app-components/form-elements/CommercialModel";

  export default {
    components: {
      [Slider.name]: Slider,
      Categories: CategoriesFormComponent,
      SubCategories: SubCategoriesFormComponent,
      Advertisers: AdvertisersFormComponent,
      CommercialModel,
      BookmarkName,
    },

    props: {
      maxPrice: {
        type: Number,
        default: 100
      }
    },

    watch: {
      maxPrice(newValue) {
        this.filters.price = [0, newValue];
      }
    },

    mixins: [
      FilterPanelMixin({
        advertiser: null,
        category: null,
        commercial_model: null,
        name: null,
        subcategory: null,
        price: [0, 0]
      })
    ],

    methods: {
      prepareFilters (filters) {
        return {
          "Bookmark.advertiser_id": this.fixCleared(this.filters.advertiser),
          "Bookmark.commercial_model_id": this.fixCleared(this.filters.commercial_model),
          "Bookmark.category_id": this.fixCleared(this.filters.category),
          "Bookmark.name": this.fixCleared(this.filters.name),
          "Bookmark.subcategory_id": this.fixCleared(this.filters.subcategory),
          "Bookmark.price": this.fixCleared(this.filters.price)
        };
      },
    },
  };
</script>

<style scoped>

</style>