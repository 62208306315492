<template>
  <div>
    <form-campaign
      :tabbed-form-save-button-disabled="workingSave"
      :hide-bookmark-info="false"
      title="Create a new event from scratch"
      @submit="onSubmit" 
      :bookmarkProps="{ isEditable: isEditable }">
    </form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from 'src/app-components/FormApiEvent';
import RetryCrudMixin from 'src/app-components/RetryCrudMixin';
import WorkingMixin from 'src/app-components/WorkingMixin';
import { mapMutations } from "vuex";

export default {
  components: {
    FormCampaign
  },

  mixins: [
    RetryCrudMixin(),
    WorkingMixin("Save")
  ],

  mounted () {
    this.setTopNavbarTitle(this.$t("Create external event from scratch"));
  },

    computed: {
      isEditable() {
        return true
      }
    },

    data() {
        return {
        }
    },

  methods: {
    async onSubmit(data) {
      try {
        this.setWorkingSave(true)
        const res = await api.createApiEventFromScratch(data);
        this.$router.push({ path: `/api-events/planning` });
      } finally {
        this.setWorkingSave(false)
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  }
}
</script>

<style>

</style>
