
var observers = [];
var ticker = null;
//TODO: Nice-to-have: que esto sea configurable
const INTERVAL = 100;

const notify = function(){
    if (observers.length > 0) {
        observers.forEach(observer => observer());
      }
};

export default {

    listen(observer){
        observers.push(observer);
        if(observers.length > 0 && ticker == null){
            ticker = setInterval(notify, INTERVAL)
        }
    },

    remove(observer){
        const removeIndex = observers.findIndex(obs => {
            return observer === obs;
        });
      
        if (removeIndex !== -1) {
            observers = observers.slice(removeIndex, 1);
        }
    },

    getInterval(){
        return INTERVAL;
    }
}