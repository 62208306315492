<template>
    <div class="row">
        <div class="col-md-6">
            <GapTime
                    :disabled="disabled"
                    :name="`retry-gap-time-${name}`"
                    :value="internalVariables"
                    @input="handleInputGapTime"
                    :required="true"
                    :min-minutes="30"
                    :max-minutes="72 * 60"
            />
        </div>
        <div class="col-md-6">
            <DateTime
                :disabled="disabled"
                :value="finish"
                :label="$t('Finish')"
                @input="handleInputFinish"
                v-validate="shouldValidateFinish"
                :name="`finish-${name}`"
                :error="getError(`finish-${name}`)"
                :disabled-dates="true"
            />
        </div>
    </div>
</template>

<script>
    import GapTime from 'src/app-components/form-elements/GapTimeText'
    import DateTime from 'src/app-components/form-elements/DateTime'
    import _ from 'lodash'

    export default {
        name: "GapRetry",
        components: {
            GapTime,
            DateTime
        },
        inject: ["getError", "parentValidator"],
        created() {
            this.$validator = this.parentValidator;
        },
        data () {
            return {
                finish: null,
                internalVariables: null
            }
        },
        watch: {
            variables: {
                handler () {
                    this.internalVariables = _.cloneDeep(this.variables);
                },
                immediate: true // FIXME puede que no sea necesario
            },
            dateProgrammed: {
                handler () {
                    this.finish = this.dateProgrammed.end_datetime ? new Date(this.dateProgrammed.end_datetime) : null;
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            shouldValidateFinish () {
                return this.manualStart ? '' : (this.parentCampaignData.activePing === true ? 'required' : '')
            }
        },
        props: {
            variables: null,
            dateProgrammed: null,
            name: {
                type: String,
                default: undefined
            },
            parentCampaignData: Object,
            disabled: {
                type: Boolean,
                default: false
            },
            manualStart: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleInputGapTime (e) {
                this.$emit('update:variables', e)
            },
            handleInputFinish (e) {
                this.finish = e;
                this.$emit('update:dateProgrammed', {
                    manual_start: false,
                    start_datetime: null,
                    end_datetime: this.finish
                })
            }
        }
    }
</script>

<style scoped>

</style>
