<template>
  <card>
    <div class="card-header">
      <div class="float-left">
        <h4 class="card-title">{{ $t("Webhooks") }}</h4>
        <p class="card-category">{{ $t("Define webhooks for events") }}</p>
      </div>
      <div class="float-right">
        <p-button type="info" @click="handleNew">{{ $t('Add webhook') }}</p-button>
      </div>
    </div>
    <div class="card-body">
      <!-- <div class="row">
        <div class="col-6 col-md">
          <p>{{ $t("Define webhooks for events") }}</p>
        </div>
        <div class="col-6 col-md-auto">
          <p-button type="info" @click="handleNew">{{ $t('Add webhook') }}</p-button>
        </div>
      </div> -->

      <!-- <WebhooksTable :data="webhooks" @edit="handleEdit" @delete="handleDelete" /> -->
      <div>
        <el-table :data="webhooks" row-key="id">
          <el-table-column :label="$t('Name')" prop="name" />
          <el-table-column :label="$t('URL')" prop="url" />
          <el-table-column :label="$t('Authentication')">
            <template slot-scope="scope">
              {{ scope.row.authType ? scope.row.authType.name : "None" }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Triggers')">
            <template slot-scope="scope">
              {{ scope.row.triggers.map(t => t.name).join(", ") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Actions')">
            <template slot-scope="scope">
              <p-button type="info" size="sm" icon @click="handleEdit(scope.row)">
                <i class="fa fa-edit"></i>
              </p-button>
              <p-button type="danger" size="sm" icon @click="handleDelete(scope.row)">
                <i class="fa fa-trash"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <WebhookConfiguration :show="showModal" :model="model"
        @cancel="closeModal"
        @save="handleSave"
      />
    </div>
    <loading :is-full-page="true" :active="working"></loading>
    
  </card>
</template>

<script>
  import api from 'src/api'
  import swal from "sweetalert2";
  import {Card} from 'src/components/UIComponents'
  import WebhookConfiguration from 'src/app-components/modal-components/WebhookConfiguration'
  import { updatableForm } from 'src/app-components/FormMixin'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Card,
      WebhookConfiguration,
      Loading
    },
    mixins: [
      updatableForm
    ],
    data () {
      return {
        showModal: false,
        form: {
          accountUserFiles: []
        },
        webhooks: [],
        model: null,
        working: false
      }
    },
    async mounted () {
      this.webhooks = (await api.get('account/webhooks')).data
    },
    methods: {
      closeModal () {
        this.showModal = false;
      },
      handleNew () {
        this.model = null;
        this.showModal = true;
      },
      onConfirmAddFile (uploaded) {
        this.showModal = false;
        this.form.accountUserFiles.push(uploaded)
      },
      handleEdit(obj) {
        console.debug("Handling edit for object: ", obj);
        this.model = obj;
        this.showModal = true;
      },
      async handleDelete(webhook) {
        const confirmResult = await swal({
            title: "Delete?",
            //text,
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            allowOutsideClick: false
        });
        if (!confirmResult.value) return;
        this.working = true;
        const result = await api.delete(`/account/webhooks/${webhook.id}`);
        console.debug("delete result: ", result);
        if(result && result.status){
          this.webhooks.splice(
            this.webhooks.findIndex(el => el.id === webhook.id),
            1
          );
        }
        this.working = false;
      },
      async handleSave(model){
        let result = null;
        //console.log("Modelo a salvar: ", model);
        this.working = true;
        if(typeof model.id === 'undefined'){
          //new record. POST  
          result = await api.post('/account/webhooks', model);
          if(result && result.data){
            this.webhooks.push(result.data);
          }
        } else {
          //existing record. UPDATE
          result = await api.put(`/account/webhooks/${model.id}`, model);
          if(result && result.data){
            this.webhooks.splice(
              this.webhooks.findIndex(el => el.id === model.id),
              1,
              result.data
            );
          }
        }
        this.working = false;
        this.showModal = false;
        console.debug("Result: ", result);
      }
    }
  };
</script>
<style>
</style>