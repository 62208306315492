<template>
  <div>

    <div class="form-group has-label">
        <label>{{ $t('Authentication type') }}</label>
        <el-select :disabled="false"
                    size="large"
                    v-validate="'required'"
                    v-model="model.type">
            <el-option v-for="option in authTypes"
                        :value="option.key"
                        :label="$t(option.label)"
                        :key="option.key"
            ></el-option>
        </el-select>
        <!-- <div v-show="errors.has('authType')"
              class="text-danger invalid-feedback"
              style="display: block;"
        >{{ errors.first('authType') }}</div> -->
    </div>

    <!-- Basic auth params -->
    <template v-if="model.type === 1">
      <fg-input :label="$t('Username')"
                            :value="basicUsername"
                            @input="updateUsername"
                            name="basicUsername"
                            key="basicUsername"
                            v-validate="{ required: true }"
                            :error="getError('basicUsername')"
                            type="text"
                            :disabled="false" />
      <fg-input :label="$t('Password')"
                            :value="basicPassword"
                            @input="updatePassword"
                            name="basicPassword"
                            key="basicPassword"
                            v-validate="{ required: true }"
                            :error="getError('basicPassword')"
                            type="text"
                            :disabled="false" />
    </template>

    <!-- Bearer token params -->
    <template v-if="model.type === 2">
      <fg-input :label="$t('Token')"
                            v-model="bearerToken"
                            name="bearerToken"
                            key="bearerToken"
                            v-validate="{ required: true }"
                            :error="getError('bearerToken')"
                            type="text"
                            :disabled="false" />
    </template>

  </div>
</template>

<script>

  export default {

    inject: ["parentValidator"],

    props: {
      value: Object
    },

    data () {
      return {
        model: {
          type: null, //auth type
          params: null
        },
        authTypes: [
          { key: null, label: "(none)" },
          { key: 1, label: "Basic" },
          { key: 2, label: "Bearer token (JWT)" }
        ],
        //basicUsername: "",
        //basicPassword: "",
        bearerToken: "",
      }
    },

    computed: {
      basicUsername(){
        return (
          this.model.type === 1 && this.model.params ?
          this.model.params.username :
          ""
        )
      },

      basicPassword(){
        return (
          this.model.type === 1 && this.model.params ?
          this.model.params.password :
          ""
        )
      },


    },

    watch: {
      value(newVal, oldVal){
        console.debug("WebhookAuth: new value: ", newVal);
        if(newVal){
          this.model.type = newVal.type;
          this.model.params = newVal.params;
          /*if(newVal.type === 1){
            this.basicUsername = (
              newVal.params && newVal.params.username ?
              newVal.params.username :
              ""
            );
            this.basicPassword = (
              newVal.params && newVal.params.password ?
              newVal.params.password :
              ""
            );
          } else*/ if(newVal.type === 2){
            this.bearerToken = (
              newVal.params && newVal.params.token ?
              newVal.params.token :
              ""
            );
          }
        } else {
          this.model.type = null;
          this.model.params = null;
        }
      },
      "model.type" : function(newVal, oldVal){
        if(newVal === oldVal) return;
        console.debug("'model.type' watch. new value: ", newVal);
        if(newVal === null){
          this.model.params = null;
        } /*else if(newVal === 1){
          this.model.params = {
            username: "",
            password: ""
          };
        } else if(newVal === 2){
          this.model.params = { token: "" };
        }*/
      },
      /*basicUsername(newVal, oldVal){
        if(updateInProgress || this.model.type !== 1) return;
        console.debug("'basicUsername' watch. new value: ", newVal);
        if(!this.model.params){
          this.model.params = {
            username: newVal
          }
        } else {
          this.model.params.username = newVal;
        }

        this.$emit('input', this.model);
      },
      basicPassword(newVal, oldVal){
        console.debug("'basicPassword' watch. new value: ", newVal);
        if(updateInProgress || this.model.type !== 1) return;
        if(!this.model.params){
          this.model.params = {
            password: newVal,
          }
        } else {
          this.model.params.password = newVal;
        }

        this.$emit('input', this.model);
      },*/
      bearerToken(newVal, oldVal){
        console.debug("'bearerToken' watch. new value: ", newVal);
        if(this.model.type !== 2) return;
        if(!this.model.params){
          this.model.params = {
            token: newVal
          }
        } else {
          this.model.params.token = newVal;
        }

        this.$emit('input', this.model);
      }
    },
    
    created() {
        this.$validator = this.parentValidator;
    },

    methods: {
      updateUsername(val){
        if(this.model.type !== 1) return;
        if(!this.model.params){
          this.model.params = {
            username: "",
            password: ""
          };
        }
        this.model.params.username = val;
        this.$emit('input', this.model);
      },
      updatePassword(val){
        if(this.model.type !== 1) return;
        if(!this.model.params){
          this.model.params = {
            username: "",
            password: ""
          };
        }
        this.model.params.password = val;
        this.$emit('input', this.model);
      },
      //deprectaed
      emitValue(){
        let data = {
          type: this.authType,
          params : null
        };

        if(this.authType === 1){
          data.params = {
            username : this.basicUsername,
            password : this.basicPassword
          };
        } else if(this.authType === 2){
          data.params = {
            token: this.bearerToken
          }
        }
        //console.debug("Emiting value: ", data);
        this.$emit('input', data);
      },
      getError (fieldName) {
          return this.errors.first(fieldName)
      },
    },
  }
</script>