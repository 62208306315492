<template>
  <div>
    <form-product-assignation title="Edit Template Assignation" :form="form" @submit="onSubmit" />
  </div>
</template>
<script>
  import api from "src/api";
  import FormProductAssignation from 'src/app-components/FormProductAssignation';

  export default {
    components: {
      FormProductAssignation
    },

    async created() {
      if ("id" in this.$route.params) {
        this.updateId = this.$route.params.id;
        this.getProductAssignation();
      }
    },

    data() {
      return {
        form: {
          name: null,
          subcategories: []
        },
        updateId: null
      }
    },

    methods: {
      async getProductAssignation() {
        this.form = await api.getProductAssignment(this.updateId);
      },

      onSubmit(data) {
        api.updateProductAssignation(this.updateId, data).then(res => {
          this.$router.push({ path: `/product-assignation/index` });
        })
      }
    }
  }
</script>