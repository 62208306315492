<template>
  <div class="row">
    <div class="col-12">
      <div class="row animated fadeIn filters-row">
        <div class="col-12"></div>
        <div class="col-4">
          <label>{{ $t("Filter by Name") }}</label>
          <el-input
            v-model="search.byName"
            size="mini"
            :placeholder="$t('Write a name')"
            addon-right-icon="fa fa-search"
            label="Search"
            prefix-icon="el-icon-search"
            clearable
          />
        </div>
        <div class="col-3">
          <label>{{ $t("Filter by MSISDN") }}</label>
          <el-select
            class="w-100"
            filterable
            clearable
            size="mini"
            :placeholder="$t('Select MSISDN')"
            v-model="search.byMsisdn"
          >
            <el-option v-for="m in selectedMsisdns" :value="m" :key="m" :label="m">{{
              m
            }}</el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="ml-auto flex-shrink-1">
                <drop-down>
                    <p-button slot="title"
                                class="dropdown-toggle m-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                type="primary"
                                size="sm"
                                block outline
                                round>
                        {{$t('Show')}} {{pagination.itemsPerPage}}
                    </p-button>
                    <a class="dropdown-item" @click="pagination.itemsPerPage = 5">5</a>
                    <a class="dropdown-item" @click="pagination.itemsPerPage = 10">10</a>
                    <a class="dropdown-item" @click="pagination.itemsPerPage = 20">20</a>
                </drop-down>
            </div> -->
      <!-- <div class="flex-shrink-1">
                <drop-down>
                    <p-button slot="title"
                                class="dropdown-toggle m-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                type="primary"
                                size="sm"
                                block outline
                                round>
                        {{$t('Export as')}}
                    </p-button>
                    <a class="dropdown-item">Excel</a>
                    <a class="dropdown-item">CVS</a>
                </drop-down>
            </div> -->
    </div>
    <div class="col-12">
      <el-table
        :data="filteredRows"
        class="w100"
        :default-sort="{ prop: 'start_datetime', order: 'descending' }"
      >
        <el-table-column label="MSISDN" width="120" prop="msisdn"></el-table-column>
        <el-table-column
          :label="$t('Start date')"
          width="150"
          prop="start_datetime"
          sortable
        ></el-table-column>

        <el-table-column
          :label="$t('Campaign')"
          prop="campaign"
          width="200"
        ></el-table-column>

        <el-table-column
          prop="channel.name" :label="$t('Channel')"
          width="100"
        >
          <template slot-scope="scope">
            <span
              class="badge w-75 badge-pill"
              :class="getChannelClass(scope.row.channel.id || 1)"
            >{{ scope.row.channel.name }}
            </span>
          </template>
        </el-table-column>
        -->

        <el-table-column :label="$t('Template')" prop="product" width="100"></el-table-column>
        <el-table-column :label="$t('Type')" prop="type"></el-table-column>
        <el-table-column
          :label="$t('Sent')"
          width="150"
          prop="sent"
          sortable
        ></el-table-column>
        <el-table-column
          :label="$t('Delivered')"
          width="150"
          prop="delivered"
          sortable
        ></el-table-column>
        <el-table-column
          :label="$t('Read')"
          width="150"
          prop="read"
          sortable
        ></el-table-column>
        <el-table-column :label="$t('Interactions')" width="200">
          <template slot-scope="scope">
            <ul>
              <li v-for="click in scope.row.interactions">
                <div>{{ click.type }}</div>
                <div>{{ click.text }}</div>
                <div>{{ click.timeStamp }}</div>
              </li>
            </ul>
          </template>
        </el-table-column>

        <!-- Aceptaciones -->
        <el-table-column
          :label="$t('Accepted')"
          width="150"
          prop="accepted"
          sortable
        ></el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Input } from "element-ui";
import Pagination from "src/components/UIComponents/Pagination";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Pagination.name]: Pagination,
  },

  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    selectedMsisdns: {
      type: Array,
      default: () => [],
    },
    // pagination : {
    //     type: Object,
    //     // required: false,
    //     default: () => ({
    //         currentPage: 1,
    //         itemsPerPage: 10,
    //     })
    // }
  },

  data() {
    return {
      search: {
        byName: "",
        byMsisdn: "",
      },
    };
  },
  methods: {
    getChannelClass(id) {
      switch (id) {
        case 1:
          return "sat";
          break;
        case 2:
          return "sms";
          break;
        case 3:
          return "fsms";
          break;
        case 6:
          return "rcs";
        default:
          break;
      }
    },
  },
  computed: {
    filteredRows() {
      return this.tableData
        .filter((row) => {
          // console.log(row);
          const msisdn = row.msisdn.toString();
          const name = row.campaign.toLowerCase();

          return (
            msisdn.includes(
              this.search.byMsisdn !== null ? this.search.byMsisdn.toString() : ""
            ) && name.includes(this.search.byName.toLowerCase())
          );
        })
        .reverse();
    },

    // paginatedRows() {
    //     // Devuelve una pagina del arreglo
    //     return this.dataTable.slice((this.pagination.currentPage - 1) * this.pagination.itemsPerPage, this.pagination.currentPage * this.pagination.itemsPerPage);
    // }
  },
};
</script>
