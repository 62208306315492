<template>
    <div>
        <form-segmentation-list :disabled='true' :initial-list-data="initialListData" @submit="onSubmit"/>
    </div>
</template>
<script>
    import api from "src/api";
    import FormSegmentationList from "../../app-components/FormSegmentationList";
    import _ from 'lodash'

    export default {
        components: {
            FormSegmentationList,
        },

        async mounted () {
            this.initialListData = (await api.getSegmentationList(this.$route.params.id)).data
            /*
            setTimeout(() => {
                this.initialListData = {"id":25,"user_id":114,"name":"Prueba Final 2","account_user_file_id":1,"status":"MY","created":"2019-10-02T18:35:05+00:00","modified":"2019-10-02T18:35:05+00:00","rules":[{"id":11,"crm_list_id":25,"rule_id":1,"date_from":"2019-10-10T00:00:00+00:00","date_to":"2019-10-11T00:00:00+00:00"},{"id":12,"crm_list_id":25,"rule_id":2,"date_from":"2019-11-19T00:00:00+00:00","date_to":"2019-11-20T00:00:00+00:00"}]}
            }, 1000);
            */
        },

        data () {
            return {
                initialListData: {}
            }
        },

        methods: {
            async onSubmit(data) {
                // TODO do update with api
                this.$router.push({ name: `IndexSegmentationTool` });
            }
        }
    }
</script>