<template>
  <div class="broadcast-template-index card p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h6 mb-0">{{ $t("Templates") }}</h1>
      <div class="d-flex justify-content-between align-items-center container-search">
        <el-input :placeholder="$t('Search')" v-model="search" suffix-icon="el-icon-search" size="small"></el-input>

        <el-select size="small" v-model="serviceSelected" class="mx-2 w-100">
          <el-option
            :label="labelOptionService(service)"
            :value="service.id"
            :key="service.id"
            v-for="service in services"
          ></el-option>
        </el-select>

        <el-button type="primary" size="small" @click="modalCreate = true">{{ $t("Create template") }}</el-button>

        <!-- <el-button type="info" size="small">
          <i class="el-icon-download"></i>
          {{ $t("Download CSV") }}
        </el-button> -->
      </div>
    </div>

    <el-table :data="tableData" class="w-100" stripe :empty-text="emptyText">
      <el-table-column label="ID" prop="id" width="80px"></el-table-column>
      <el-table-column label="NAME" prop="name" width="120px"></el-table-column>
      <el-table-column label="CHANNEL" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.channel_id | setChannelName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="CATEGORY" width="110">
        <template slot-scope="scope">
          <span class="bullet-default">
            {{ scope.row.category | capitalize }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Connection" prop="connection" width="100">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top" v-if="scope.row.connection_type">
            <div slot="content" v-html="connectionTooltip(scope.row)"></div>
            <span>
              {{ scope.row.connection_type | capitalize }}
            </span>
          </el-tooltip>
          <span v-else> - </span>
        </template>
      </el-table-column>

      <el-table-column label="STATUS" width="100">
        <template slot-scope="scope">
          <span class="bullet-default w-75" :class="formatStatus(scope.row.status)">
            {{ formatStatus(scope.row.status) | capitalize }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Actions" width="75">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="btn.tooltip"
            placement="top"
            :key="btn.tooltip"
            v-for="btn in buttonsActions"
            v-show="scope.row.status == 2"
          >
            <el-button type="text" class="icon-action py-0" @click="btn.action(scope.row.id)">
              <i :class="btn.icon"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <modal-connection-template
      :flows="flows"
      :open="dialogSetConnection"
      :connection="templateSetConnection"
      @closeModal="templateSetConnection = null"
      @updateConnection="updateConnection($event)"
    />

    <el-dialog width="30%" class="confirm-dialog" :visible.sync="modalCreate" title="Select channel">
      <el-select placeholder="Select channel" v-model="idChannelCreate" class="d-block my-2 mx-4">
        <el-option :value="channel.id" :label="channel.name" :key="channel.id" v-for="channel in channels"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="formView" :disabled="!idChannelCreate"> Create </el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="dialog-template"
      :title="templateSelected ? templateSelected.name : ''"
      :visible.sync="viewTemplate"
      @closeModal="templateSelected = null"
      width="320px"
    >
      <view-template :template="templateSelected" v-if="templateSelected" />
    </el-dialog>
  </div>
</template>

<script>
  import { Input, Button, Switch, Table, TableColumn, Tooltip, Dialog, Select } from "element-ui";
  import api from "src/api";
  import { mapMutations } from "vuex";
  import ViewTemplate from "src/app-components/broadcast/ViewTemplate";
  import ModalConnectionTemplate from "src/app-components/broadcast/ModalConnectionTemplate";
  export default {
    name: "BroadcastTemplatesIndex",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Switch.name]: Switch,
      [Tooltip.name]: Tooltip,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      "modal-connection-template": ModalConnectionTemplate,
      "view-template": ViewTemplate,
    },

    data() {
      return {
        search: "",
        dialogSetConnection: false,

        templateSetConnection: null,

        // templatesApi: null,

        services: [],

        flows: [],

        serviceSelected: null,

        loading: true,

        modalCreate: false,
        idChannelCreate: "",

        templates: null,

        templateSelected: null,
        viewTemplate: false
      };
    },

    watch: {
      serviceSelected() {
        this.loading = true;
        this.templates = [];
        this.getChatbotTemplateByService(this.serviceSelected);
      },
    },

    created() {
      if (!this.$can("Views", "Broadcast")) this.$router.push({ name: "DashboardIndex" });

      this.setTopNavbarTitle("Broadcast");
      this.getServices();
      this.getFlows();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      labelOptionService(service) {
        let res = service.name;
        if(service.agent_options) {
          if(service.agent_options.phone) {
            res += ` (${service.agent_options.phone})`
          }else if(service.agent_options.phone_id) {
            res += ` (${service.agent_options.phone_id})`
          }
        }
        return res;
      },

      formView() {
        let channel = this.channels.find((c) => c.id === this.idChannelCreate);

        if (channel) {
          this.idChannelCreate = null;
          this.$router.push({ name: channel.routeCreate });
          this.modalCreate = false;
        }
      },

      getNameFlow(id) {
        let flow = this.flows.find((f) => f.id === id);
        return flow ? flow.name : id;
      },

      getNameFlowNode(flowId, nodeId) {
        let flow = this.flows.find((f) => f.id === flowId);
        if (flow) {
          let node = flow.nodes.find((n) => n.id === nodeId);
          if (node) return node.name;
        }
        return `Node ${nodeId}`;
      },

      connectionTooltip(template) {
        if (template.connection_type === "flow") {
          if (template.connections.flowId) {
            return this.getNameFlow(template.connections.flowId);
          }
        } else {
          let keys = Object.keys(template.connections);
          var res = "";
          keys.forEach((k, i) => {
            if (template.connections[k]) {
              if (i > 0) {
                res += "<br>";
              }
              res += `${k}: ${this.getNameFlow(template.connections[k].flowId)} - ${this.getNameFlowNode(
                template.connections[k].flowId,
                template.connections[k].nodeId
              )}`;
            }
          });
          return res;
        }

        return "";
      },

      async getFlows() {
        let res = await api.getFlowsWithNodes();
        if (res.status) {
          this.flows = [...res.data];
        }
      },

      async getChatbotTemplateByService(id) {
        let res = await api.getChatbotTemplateByService(id);
        this.templates = res.data ? res.data : [];
        this.loading = false;
      },

      async getServices() {
        let res = await api.getServices();
        if (res.status) {
          this.services = res.data;
          if (this.services.length) {
            this.serviceSelected = this.services[0].id;
          }
        }
      },

      handleInfo(id) {
        let template = this.tableData.find((t) => t.id === id);
        if(template) {
          this.templateSelected = template;
          this.viewTemplate = true;
        }
      },

      async handleDelete(id) {
        let res = await api.deleteChatbotTemplate(id);
        if (res.status) {
          this.getChatbotTemplateByService(this.serviceSelected);
        } else {
          this.$notify({
            title: this.$t("An error has ocurred. Try again."),
            icon: "fa fa-bomb",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        }
      },

      handleConnect(id) {
        let template = this.tableData.find((t) => t.id === id);
        if (template) {
          let template_options = [];
          template.buttons.forEach((btn) => {
            if (btn.type.toLowerCase() === "quick_reply") {
              template_options.push(btn.text.toLowerCase());
            }
          });

          this.templateSetConnection = {
            template_options,
            id: template.id,
            name: template.name,
            validity: template.validity ? template.validity : 24,
            connection_type: template.connection_type ? template.connection_type : "flow",
            connections: template.connections
              ? template.connections
              : {
                  flowId: "",
                  options: {},
                },
          };
        }

        this.dialogSetConnection = !this.dialogSetConnection;
      },

      updateConnection(data) {
        let index = this.templates.findIndex((t) => t.name === data.name);
        if (index !== -1) {
          this.templates[index].connection_type = data.connection_type;
          this.templates[index].connections = data.connections;
          this.templates[index].validity = data.validity;
        }
      },

      formatStatus(status) {
        const statusText = ["pending", "approved", "rejected"];
        return statusText[status - 1];
      },
    },

    computed: {
      channels() {
        return [
          {
            id: 7,
            name: "GBM",
            routeCreate: "BroadcastTemplatesFormGBMCreate",
          },
          {
            id: 8,
            name: "WhatsApp Api Cloud",
            routeCreate: "BroadcastTemplatesFormWPPCreate",
          },
          {
            id: 10,
            name: "WhatsApp BSP",
            routeCreate: "BroadcastTemplatesFormGupshupCreate",
          },
        ];
      },

      tableData() {
        /* if (this.templatesApi) {
          return this.templatesApi.filter(template => template.name.toLowerCase().includes(this.search.toLowerCase()));
        } */
        if (this.templates) {
          return this.templates.filter((template) => template.name.toLowerCase().includes(this.search.toLowerCase()));
          // return this.templates.filter(template => template.name.toLowerCase().includes(this.search.toLowerCase()) && template.service_id === this.serviceSelected);
        }
        return [];
      },

      buttonsActions() {
        return [
          {
            icon: "el-icon-picture",
            action: this.handleInfo,
            tooltip: "View template",
          },
          {
            icon: "el-icon-connection",
            action: this.handleConnect,
            tooltip: "Connect",
          },
          {
            icon: "el-icon-delete",
            action: this.handleDelete,
            tooltip: "Delete",
          },
        ];
      },

      emptyText() {
        return this.loading ? "Loading" : "You don't have templates created";
      },
    },
  };
</script>

<style scoped>
  .container-search {
    max-width: 450px;
  }

  span.bullet-default {
    display: inline-block;
    padding: 0 5px;
    border-radius: 20px;
    font-weight: bold;
    background: #c0c5ce;
    width: 90px;
    text-align: center;
  }

  span.bullet-default.approved {
    background: #ecfdf3;
    color: #027a48;
    font-weight: bold;
  }

  span.bullet-default.pending {
    background: #939392;
    color: white;
  }

  span.bullet-default.rejected {
    background: #c84a4a;
    color: white;
  }

  .icon-action i {
    font-size: 16px;
    color: #303030;
    margin: 0;
  }
</style>
