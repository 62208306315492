<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header text-center">
          <h3 class="card-title">{{ $t("Campaign manager") }}</h3>
          <p class="card-category">
            {{
              $t(
                "Please, select the campaign type you want to create or modify"
              )
            }}
          </p>
        </div>

        <div class="card-body">
          <div class="row justify-content-center">
            <div
              class="col-md-4 text-center"
              v-for="(campaign, index) in campaigns"
              :key="index"
              style="height: 350px; position: relative;"
            >
              <icon-radio
                class="choice-radio"
                name="campaign"
                :icon="campaign.icon"
                :real-value="campaign.id"
                v-model="model.campaign_manager_id"
                title
              ></icon-radio>

              <p>{{ $t(campaign.description) }}</p>

              <div style="position: absolute; bottom: 0; width: 90%">
                <drop-down class="btn-group">
                  <p-button
                    slot-scope="{ isOpen }"
                    slot="title"
                    type="info"
                    round
                    class="dropdown-toggle"
                    :aria-expanded="isOpen"
                    >{{ $t(campaign.name) }} &nbsp;</p-button
                  >

                  <span v-if="campaign.disabled">
                    <a
                      class="dropdown-item"
                      @click="alert"
                      v-for="(action, index) in campaign.actions"
                      :key="index"
                      >{{ $t(action.name) }}</a
                    >
                  </span>

                  <span v-if="!campaign.disabled">
                    <a
                      class="dropdown-item"
                      :href="action.url"
                      v-for="(action, index) in campaign.actions"
                      :key="index"
                      >{{ $t(action.name) }}</a
                    >
                  </span>
                </drop-down>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "src/components/UIComponents/Dropdown";
import IconRadio from "src/app-components/form-elements/Inputs/IconRadio";
import { mapActions } from "vuex";
import swal from "sweetalert2";
import api from "src/api";

const descOnDemand = "DESCRIPTION_ONDEMAND";

const descPerformance = "DESCRIPTION_PROGRAMMATIC";

const descEvent = "DESCRIPTION_EVENT";

export default {
  components: {
    "drop-down": Dropdown,
    "icon-radio": IconRadio,
  },

  data() {
    return {
      model: {},
      channels: [],
    };
  },

  created() {
    this.getChannels();
  },

  methods: {
    alert() {
      swal({
        customClass: "swal-override",
        title:
          "For events, please contact support team: support@dynamo-mobile.com",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success btn-fill",
      });
    },
    async getChannels() {
      const channels = await api.getScenarioChannels();
      this.channels = channels.data;
    },
  },

  computed: {
    campaigns() {
      return [this.onDemand, this.programmatic, this.events];
    },

    onDemand() {
      let actions = [
        {
          name: "New campaign from scratch",
          url: "/#/campaigns/create",
        },
        {
          name: "New from template",
          url: "/#/bookmarks/index?createFromProduct=1",
        },
        {
          name: "New ping campaign",
          url: "/#/campaigns/create-from-ping/add",
        },
      ];

      if (this.channels.find((channel) => channel.id == 5)) {
        actions.push({
          name: "New OTA campaign",
          url: "/#/campaigns/ota/create",
        });
      }
      actions.push({ name: "Planning", url: "/#/campaigns/planning" });

      return {
        id: 1,
        name: "On Demand",
        description: descOnDemand,
        icon: "icon icon-button-power-2",
        actions,
      };
    },
    programmatic() {
      return {
        id: 2,
        name: "PROGRAMMATIC",
        icon: "icon icon-spaceship-2",
        description: descPerformance,
        actions: [
          { name: "New campaign", url: "/#/performance-campaigns/add" },
          { name: "New template", url: "/#/bookmarks/add" },
          { name: "Planning", url: "/#/campaigns/planning" },
        ],
      };
    },
    events() {
      return {
        id: 3,
        name: "Events",
        icon: "icon icon-network-connection",
        disabled: !this.$can("Create", "ApiEvents"),
        description: descEvent,
        actions: [
          {
            name: "New event from scratch",
            url: "/#/api-events/create",
          },
          {
            name: "New from template",
            url: "/#/bookmarks/index?createFromProduct=1&event=1",
          },
        ],
      };
    },
  },
};
</script>
