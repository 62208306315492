<template>
  <div class="card p-3 gbm-agent-form">
    <div class="d-md-flex justify-content-between">
      <div class="mb-3 w-100">
        <el-button class="btn btn-facebook" @click="login" v-if="!fbLoggedName">
          <i class="fa fa-facebook mr-1"></i>
          Login with Facebook
        </el-button>
        <div class="facebook-banner" v-if="!!fbLoggedName">
          <span>Logged in as {{ fbLoggedName }}</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column agents-container" v-if="!!fbLoggedName">
      <div class="agent-checkbox" v-for="newAgent in agents">
        <input type="checkbox" :id="newAgent.page_id" :value="newAgent.page_id" v-model="checkedAgents">
        <label class="checkbox-label" :for="newAgent.page_id"><b>{{newAgent.name}}</b> - {{newAgent.page_id}}</label>
      </div>
    </div>

    <span class="error" v-if="!!error">{{ error }}</span>
    <el-button type="primary" @click="createAgentFB" :disabled="loading || !isValid">
      <i class="el-icon-loading" v-if="loading"></i>
      <span v-else>Save</span>
    </el-button>
    <el-button type="text" @click="cancel" :disabled="loading" class="mt-2 pb-0"> Cancel </el-button>
  </div>
</template>

<script>
import sio from "@/sio";
import api from "src/api";
import { mapMutations } from "vuex";
import { Input, Button } from "element-ui";
export default {
  name: "FormFB",
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
  },

  data() {
    return {
      agents: [],
      checkedAgents: [],
      loading: false,
      validate: false,
      fbLoggedName: null,
      error: null,
    };
  },

  created() {
    if (!this.$can('Views', 'Services')) this.$router.push({ name: "DashboardIndex" });
    this.setTopNavbarTitle("Create Service Facebook");

    sio.onAny((event, obj) => {
      console.log("agent", obj);
      if (obj.metadata) {
        if (obj.metadata.service) {
          this.loading = false;
          if (obj.metadata.service.status) {
            this.agents = [];
            this.cancel();
          }
        }
      }
    });
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    cancel() {
      this.$router.push({ name: "ServicesIndex" });
    },

    async createAgentFB() {
      try {
        this.error = null;
        if (this.isValid) {
          this.loading = true;
          const agentsToCreate = this.agents?.filter(agent => this.checkedAgents.includes(agent.page_id));

          for await (const agent of agentsToCreate) {
            console.log('creating agent', agent);
            await this.addPageSubscriptions(agent);
            await api.createService({
              channel_id: 9,
              chatbot_params: agent,
            });
          }
          console.log('Done');
          this.$router.push({ name: "ServicesIndex" });
        } else {
          this.validate = true;
          setTimeout(() => {
            this.validate = false;
          }, 3000);
        }
      } catch(err) {
        console.log('err on createAgentFB', err);
      }
    },

    async login() {
      const _this = this;
      const {authResponse} = await new Promise((resolve, reject) => {
        FB.login(function(response) {
          // handle the response
          resolve(response);
        }, {scope: 'pages_show_list, pages_messaging, pages_manage_metadata, pages_read_engagement'});
      });
      // Me
      FB.api('/me', function (response) {
        console.log('Successful login for: ' + response.name, response)
        _this.fbLoggedName = response.name;
        // pages I have access to
        FB.api('/me/accounts', function (response) {
          if (response && !response.error) {
            console.log('response data', response)
            response.data?.forEach(page => {
              _this.agents.push({
                name: page.name,
                token: page.access_token,
                page_id: page.id,
                user_token: authResponse.accessToken,
              });
            });

          } else {
            console.error('response.error', response.error)
            _this.error = response.error.message;
          }
        })
      })
    },

    statusChangeCallback(response) {
      // Called with the results from FB.getLoginStatus().
      console.log('statusChangeCallback')
      console.log(response) // The current login status of the person.
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        testAPI()
      } else {
        // Not logged into your webpage or we are unable to tell.
        console.log('Please log into this webpage.');
      }
    },

    checkLoginState() {
      // Called when a person is finished with the Login Button.
      FB.getLoginStatus(function (response) {
        // See the onlogin handler
        statusChangeCallback(response)
      })
    },

    // add webhooks to page subscriptions
    addPageSubscriptions(agent) {
      const _this = this;
      FB.api(
        `/${agent.page_id}/subscribed_apps`,
        'POST',
        {
          subscribed_fields: [
            'messages',
            'messaging_postbacks',
            'message_deliveries',
            'messaging_customer_information',
            'message_reads',
            // any other webhook event: https://developers.facebook.com/docs/messenger-platform/webhook/#events
          ],
          access_token: agent.token,
        },
        function (response) {
          if (response && !response.error) {
            console.log('OK', response);
          } else {
            console.error(response.error);
            _this.error = response.error.message;
          }
        },
      )
    },
  },

  computed: {
    isValid() {
      return this.checkedAgents.length > 0;
    },
  },
};

</script>

<style scoped>
.gbm-agent-form .error {
  color: red;
}

.gbm-agent-form {
  max-width: 800px;
  margin: 0 auto;
}

.gbm-agent-form .d-md-flex>div {
  width: 100%;
}

.btn-facebook {
    background: #3B5998;
    color: #fff;

    &:hover {
        color: #fff;
        opacity: 0.8;
    }
}

.facebook-banner {
  background: #3B5998;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
}

.agents-container {
  margin-bottom: 20px;
}
.agent-checkbox {
  display: flex;
  align-items: center;
  padding: 5px;
}

.checkbox-label {
  margin-bottom: 0px;
  margin-left: 5px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .gbm-agent-form .d-md-flex>div {
    width: 49%;
  }

  .gbm-agent-form .d-md-flex.time>div {
    width: 24%;
  }
}
</style>

<style>
.gbm-agent-form .error .el-input__inner,
.gbm-agent-form .error .el-textarea__inner {
  border-color: red;
  color: red;
}
</style>
