<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <product-assignation v-model="products" @delete="handleDelete" />
      </div>
    </div>
    <floating-action-button color="#339564" icon="add" @click="$router.push({name: 'ProductAssignationAdd'})" />
  </div>
</template>
<script>
  import api from "src/api";
  import ProductAssignation from "src/app-components/table-components/ProductAssignation";
  import FloatingActionButton from "src/app-components/FloatingActionButton";

  export default {
    components: {
      ProductAssignation,
      FloatingActionButton
    },

    async created() {
      const response = await api.getProductAssignments();
      response.data.map(product => {
        this.products.push({
          id: product.id,
          name: product.name,
          subcategories: product.subcategories
        });
      })
    },

    data() {
      return {
        products: []
      }
    },

    methods: {
      handleDelete(id) {
        this.products = this.products.filter(product => product.id !== id);
      }
    }
  }
</script>