<template>
  <div class="modal-configuration">
    <el-dialog
      title="Configuration"
      :visible.sync="dialogVisible"
      width="65%"
      top="5vh"
      @close="close"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-tabs v-model="activeName" v-if="dialogVisible">
        <el-tab-pane label="Conversation" name="conversation">
          <form-conversation
            :conversation="dataConversation"
            :validateInputs="validateInputs"
            @updateData="updateData($event)"
          />
        </el-tab-pane>
        <el-tab-pane label="Variables" name="variables">
          <form-variables
            :variables="variables"
            @updateData="updateData($event)"
          />
        </el-tab-pane>
        
        <el-tab-pane label="Global" name="global">
          <span class="label">Name</span>
          <el-input size="small" v-model="name"></el-input>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
        <el-button size="small" type="primary" @click="validate" :disabled="name === ''">{{ $t("Save") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { cloneDeep } from "lodash";
  import { Dialog, Tabs, TabPane, Button, Input } from "element-ui";
  import FormConversation from "src/app-components/chatbot/configuration/global/FormConversation";
  import FormVariables from "src/app-components/chatbot/configuration/global/FormVariables";

  export default {
    name: "ModalConfiguration",
    props: ["active", "configuration", "nameFlow"],
    components: {
      [Dialog.name]: Dialog,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Button.name]: Button,
      [Input.name]: Input,
      "form-conversation": FormConversation,
      "form-variables": FormVariables,
    },

    data() {
      return {
        dialogVisible: false,
        activeName: "conversation",

        dataConfiguration: {},

        initConfiguration: {},
        validateInputs: false,

        name: "LALALA"
      };
    },

    watch: {
      active() {
        this.dialogVisible = true;
        this.name = `${this.nameFlow}`;

        if (this.configuration) {
          this.dataConfiguration = cloneDeep(this.configuration);
          this.initConfiguration = cloneDeep(this.configuration);
        }
      },
    },

    methods: {
      updateData(data) {
        this.dataConfiguration = cloneDeep({
          ...this.dataConfiguration,
          ...data,
        });
        this.validateInputs = false;
      },

      validate() {
        let valid = true;
        if (this.dataConfiguration.insistence) {
          if (
            !this.dataConfiguration.insistence.message &&
            this.dataConfiguration.insistence.minutes
          ) {
            valid = false;
          }
        }

        if (valid) {
          this.save();
        } else {
          this.validateInputs = true;
        }
      },

      save() {
        if(this.name === "") return;
        this.$emit("updateName", this.name);

        this.initConfiguration = cloneDeep(this.dataConfiguration);
        this.dialogVisible = false;
      },

      close() {
        this.$emit("updateData", this.initConfiguration);
        this.dataConfiguration = {};
        this.validateInputs = false;
      },
    },

    computed: {
      dataConversation() {
        return {
          insistence: this.dataConfiguration.insistence,
          timeout: this.dataConfiguration.timeout,
          closingMessage: this.dataConfiguration.closingMessage,
          globalClosingPhrases: this.dataConfiguration.globalClosingPhrases,
        };
      },

      variables() {
        return this.dataConfiguration.variables;
      },
    },
  };
</script>

<style>
  .closing-phrases .el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa !important;
  }

  .modal-configuration .error .el-input__inner,
  .modal-configuration .error .el-textarea__inner {
    border-color: red;
  }

  .modal-configuration .el-dialog__body {
    padding: 0 20px !important;
  }

  .wrapperCanvas.dark .title-option {
    color: #eee;
  }
</style>
