import api from "src/api";

export default () => {
  return {
    methods: {

      async processOperationLog (parentId, data) {

          if (!data || Array.isArray(data)) {
              console.log("Nothing to do here");
              return;
          }

          const idToBeCreated =
              Object.entries(data.operationsLog)
                  .filter(([appId, status]) => status == "CREATED")
                  .map(([appId, status]) => appId);

          const idToBeDeleted =
              Object.entries(data.operationsLog)
                  .filter(([appId, status]) => status == "DELETED")
                  .reduce((min, [appId, status]) => ((min == null || appId < min) ? appId : min), null);

          const idToBeUpdated =
              Object.entries(data.operationsLog)
                  .filter(([appId, status]) => status == "UPDATED")
                  .map(([appId, status]) => appId);

          const idPristine =
              Object.entries(data.operationsLog)
                  .filter(([appId, status]) => status == "PRISTINE")
                  .map(([appId, status]) => appId);

          const maxRetryId = Math.max(...[ ...idToBeUpdated, ...idPristine, parentId ]);

          const createdRetries =
              idToBeCreated
                  .map(id => data.retries.find(r => r.appId == id))

          const updatedRetries =
              idToBeUpdated
                  .map(id => data.retries.find(r => r.appId == id))
                  .map(r => ({ ...r, id: r.appId }))

          const promises = [];

          if (idToBeDeleted) promises.push(api.deleteCampaignRetry(idToBeDeleted));
          if (createdRetries.length > 0) promises.push(api.createCampaignRetry(maxRetryId, createdRetries));
          if (updatedRetries.length > 0) promises.push(api.updateCampaignRetry(parentId, updatedRetries));

          const res = await Promise.all(promises);

          console.log(res);
      },

    },
  };
};
