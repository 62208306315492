<template>
    <div class="row">
            <div class="col-md-12">
                <div class="form-group has-label">
                    <label>{{ $t('Triggers') }}</label>
                    <el-select :disabled="disabled"
                               size="large"
                               v-validate="'required'"
                               :name="name"
                               multiple
                               :value="value"
                               @input="onChange">
                        <el-option v-for="option in filters"
                                   :value="option.key"
                                   :label="$t(option.label)"
                                   :key="option.key"
                        ></el-option>
                    </el-select>
                    <div v-show="errors.has(name)"
                         class="text-danger invalid-feedback"
                         style="display: block;"
                    >{{ errors.first(name) }}</div>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: "WebhookTriggers",
        components: {
        },
        props: {
            value: Array,
            name: {
                type: String,
                default: "webhookTriggers"
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        inject: ["getError", "parentValidator"],
        created() {
            this.$validator = this.parentValidator;
        },
        data () {
            return {
                filters: [
                    { key: "SENT", label: "Sent"},
                    { key: "DELIVERY", label: "Delivery"},
                    { key: "ACCEPTANCE", label: "Acceptance"},
                ],
            };
        },
        computed: {
            
            
        },
        methods: {
            onChange(val) {
                this.$emit("input", val)
            }
        },
    }
</script>

<style scoped>

</style>
