export default (field) => {
  return {
    created() {
    },

    computed: {
      totalValidEntries() {
        if (this[field].length === 0) {
          return 0;
        }

        return this.sumTotalValid(this[field])
      }
    },

    methods: {
      sumTotalValid(files) {
        const sum = files.map(file => file._count_success).reduce((sum, x) => sum + x, 0);
        return sum
      },

      onFileRemoved(fileToRemove) {
        //console.log(`Procesando borrado de archivo. Actualizando campo '${field}'`);
        //const newFiles = this[field].filter(of => of.id !== file.id);
        const index = this[field].findIndex(file => file.id === fileToRemove.id);
        if (index >= 0) {
          this[field].splice(index, 1);
        }
      },

      onFileProcessed(data) {
        const index = this[field].findIndex(f => f.id === data.id);
        if (index >= 0) {
          this.$set(this[field], index, data)
        } else {
          this[field].push(data);
        }
      }
    }
  };
};