<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 mr-auto ml-auto">
        <!-- :finishButtonText="$t(finishButtonText)" -->
        <wizard
          :finishButtonText="$t('FINISH')"
          :prevButtonText="$t('Previous')"
          :nextButtonText="$t('Next')"
        >
          <template slot="header">
            <h3 class="card-title">{{ $t("Mobile programmatic campaign builder") }}</h3>
            <h3 class="description">
              {{
                $t(
                  "Smart inventory allocation according to different programmatic strategies."
                )
              }}
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')" ref="tab-step1">
            <template slot="label">
              <i class="icon icon-check-list-2"></i>
              {{ $t("Select your templates") }}
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              :scenario-type-id="wizardEntity.account_scenario_type_id"
              :with-bookmarks="wizardEntity.bookmarks"
            >
            </first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')" ref="tab-step2">
            <template slot="label">
              <i class="icon icon-strategy-2"></i>
              {{ $t("Select your strategy") }}
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
              :wzModel="wizardModel"
            >
            </second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')" ref="tab-step3">
            <template slot="label">
              <i class="icon icon-multiple-11-2"></i>
              {{ $t("Select your audience") }}
            </template>
            <third-step
              ref="step3"
              :wzModel="this.wizardEntity"
              @on-validated="onAudienceValidated"
            ></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')" ref="tab-step4">
            <template slot="label">
              <i class="nc-icon nc-settings"></i>
              {{ $t("Configure your strategy") }}
            </template>
            <fourth-step
              ref="step4"
              @on-validated="onFouthStepValidated"
              :allowSubmit="allowSubmit"
              :wzModel="wizardModel"
              :initialRetryData="retries"
              :initialCampaignData="entity"
            >
            </fourth-step>
          </wizard-tab>
        </wizard>
      </div>
    </div>
  </div>
</template>

<script>
import FirstStep from "./wizard-performance-campaign/FirstStep.vue";
import SecondStep from "./wizard-performance-campaign/SecondStep.vue";
import ThirdStep from "./wizard-performance-campaign/ThirdStep.vue";
import FourthStep from "./wizard-performance-campaign/FourthStep";
import { Wizard, WizardTab } from "src/components/UIComponents";
import { clone, cloneDeep, values } from "lodash";

import { defaultMixin } from "src/app-components/FormMixin";

export default {
  mixins: [defaultMixin],

  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    Wizard,
    WizardTab,
  },

  props: {
    entity: Object,
    allowSubmit: {
      type: Boolean,
      default: true,
    },
  },

  provide() {
    return {
      getError: this.getError,
    };
  },

  data() {
    return {
      wizardEntity: this.factoryData(),
      wizardModel: this.factoryData(),
    };
  },

  computed: {
    finishButtonText() {
      return this.allowSubmit ? "FINISH" : "GO TO PLANNING";
    },
    retries() {
      if(this.entity) {
        if(this.entity.children) {
          return this.entity.children[0];
        }
      }
      return null;
    },
  },

  watch: {
    entity() {
      const x = cloneDeep(this.entity);
      const obj = {
        account_scenario_type_id: x.account_scenario_type_id,
        bookmarks: x.bookmarks,
        start_datetime: x.start_datetime,
        performance_campaign_type_id: parseInt(x.performanceTypeVariable.value),
        files: x.files,
        name: x.name,
        manual_start: x.manual_start,
        end_datetime: x.end_datetime,
        variables: x.variables,
      };

      this.wizardEntity = this.mergeData(this.wizardEntity, obj);
      this.wizardModel = this.mergeData(this.wizardModel, obj);

      // Allow switch on edit
      ["tab-step1", "tab-step2", "tab-step3", "tab-step4"].forEach((s) => {
        this.$refs[s].checked = true;
      });
    },
  },

  methods: {
    mergeData(obj1, obj2) {
      return { ...obj1, ...obj2 };
    },

    factoryData() {
      return {
        files: [],
        bookmarks: [],
        name: null,
        performance_campaign_type_id: null,
        account_scenario_type_id: null,
        percent: null,
        gap_time: null,
        manual_start: false,
        end_datetime: null,
        start_datetime: null,
        cap_type: '',
      };
    },

    validateStep(ref) {
      // console.log("Validating step " + ref)
      return this.$refs[ref].validate();
    },

    onAudienceValidated(validated, model) {
      //console.log("Audience step validation call");
      if (validated) {
        //console.log("Audience step is valid!");
        this.wizardModel.files = model;
        this.printModel();
      }
    },

    onFouthStepValidated(validated, model) {
      if (validated) {
        this.wizardModel = { ...this.wizardModel, ...model };
        this.printModel();
        this.$emit("submit", this.prepareData());
      }
    },

    onStepValidated(validated, model) {
      if (validated) {
        this.wizardModel = { ...this.wizardModel, ...model };
        this.printModel();
      }
    },

    printModel() {
      //para debugear
      // console.log(
      //   "Paso validado. Asi va quedando la campaña: " +
      //     JSON.stringify(this.wizardModel, null, 2)
      // );
    },

    prepareData() {
      return {
        files: this.wizardModel.files,
        bookmarks: this.wizardModel.bookmarks,
        name: this.wizardModel.name,
        performance_campaign_type_id: this.wizardModel.performance_campaign_type_id,
        account_scenario_type_id: this.wizardModel.account_scenario_type_id,
        percent: this.wizardModel.percent,
        gap_time: this.wizardModel.gap_time,
        start_datetime: this.wizardModel.start_datetime,
        end_datetime: this.wizardModel.end_datetime,
        retries: this.wizardModel.retries,
        cap_type: this.wizardModel.cap_type,
      };
    },
  },
};
</script>

<style></style>