<template>
    <div class="d-flex flex-column w-100">
        <div v-for="(content, index) in freeContentMap" :key="index" class="w-100" :class="[index > 0 ? 'mt-1' : '']">
            <component :is="getComponentByType(content.type)" :data="content.value" :messageMode="true"></component>
        </div>
    </div>
</template>

<script>
import { intersection } from 'lodash';

import TextMessagePreview from 'src/app-components/rcs/preview-elements/TextMessagePreview.vue'
import MediaContentPreview from 'src/app-components/rcs/preview-elements/MediaContentPreview.vue'

const CONTENT_TYPE = ['text', 'contentInfo'];
const COMPONENT_BY_TYPE = new Map();

export default {
    components:{
        TextMessagePreview,
        MediaContentPreview
    },

    props:{
        data:{
            type: [Object, Array],
            default: () => {
                return {  }
            }
        }
    },

    computed: {
        freeContentMap(){
            return this.data.freeContent.map( element => {
                const keys = Object.keys(element.contentMessage);
                const contentToGet = intersection(keys, CONTENT_TYPE);

                let obj = { type: null, value: null};
                if ( contentToGet.length > 0 ) {
                    obj.type = contentToGet[0];
                    if (contentToGet[0] == 'contentInfo') {
                        obj.value = element.contentMessage.contentInfo.fileUrl;
                    } else {
                        obj.value = element.contentMessage.text;
                    }
                };
                 return obj
            })
        }
    },

    created() {
        COMPONENT_BY_TYPE.set('text' , 'TextMessagePreview');
        COMPONENT_BY_TYPE.set('contentInfo' , 'MediaContentPreview');
    },

    methods: {
        getComponentByType(type){
            return COMPONENT_BY_TYPE.get(type) || null
        }
    },
}
</script>