<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "Event",

    extends: Report,

    created() {
        this.tableCols = [
            {
                label: "Event",
                hasLabel: true,
                prop: "event_name",
                width: 200,
            },
            {
                label: "template",
                prop: "product_name", 
            },
            {
                prop: "total_requests",
                label: "Requested",
                width: 150,
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "total_sent",
                label: "Sent",
                width: 150,
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "total_delivered",
                label: "Delivered",
                width: 150,
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "deliveryRate",
                label: "%DLV",
                width: 100,
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {
                prop: "total_acceptances",
                label: "Acceptances",
                width: 150,
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "acceptanceRate",
                label: "%AR",
                width: 100,
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {
                prop: "total_lb_views", 
                label: "View",
                width: 100,
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "viewRate", 
                label: "%View",
                width: 100,
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {   
                label: "URL",
                isHtml: true,
                prop: "event.bookmarks[0].url",
                width: 150,
                formatter: (row, column, cellValue, index) => {
                    if (row && row.event && row.event.bookmarks[0].url) {      
                            let a = document.createElement("a");
                            const url = row.event.bookmarks[0].url.toLowerCase();
                            a.href = url;
                            a.innerText = url;
                            a.target = '_blank';
                            a.className = "text-truncate";
                            return a.outerHTML
                    }
                    return "-";
                }
            },
            { 
                prop: "total_blacklist", 
                label: "Blacklisted", 
                formatter: this.formatterNumber, 
                minWidth: 120, 
                exportFormatter: Number, 
                excelFormat: excelFormatNumber 
            },
        ];

        this.customTableKeyCols = [
            {
                prop: "event_name", 
                label: "Event",
            }
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            // Not Lauch browser and not ping
            return scenario.scenario.sat_call_to_action_id != 1 && scenario.scenario.sat_call_to_action_id != null;
        });

        this.emitAllowEventFilter(true);
    },

    
    methods: {

        /*async getDataChild(){
            console.log("original filters", this.filters);
            const filters = {
                date_from: this.filters["campaign_start_datetime >="],
                date_to: this.filters["campaign_start_datetime <="],
            }
            console.log("new filters: ", filters);
            const result = await api.getEventReport(filters);
            console.log("Event report: ", result);
        },*/

        async getData(query) {
            const filters = this.prepareFilter();
            const response = await api.getEventReport(filters);
            this.tableData = response.data.map((row) => {
                row.deliveryRate = (row.total_delivered / row.total_sent);
                row.acceptanceRate = (row.total_acceptances / row.total_delivered);
                row.viewRate = (row.total_lb_views / row.total_acceptances);

                return row;
            });

            return Promise.resolve({
                isTree: this.dimensions.length > 0,
            });
        },

        prepareFilter(){
            if (this.$route.params.eventID !== undefined ) {
                return { event_id : this.$route.params.eventID };
            } else {
                return {
                    date_from: this.filters["campaign_start_datetime >="],
                    date_to: this.filters["campaign_start_datetime <="],
                    group_by: this.dimensions.map(dimension => dimension.groupingProp).join(','),
                };
            };
        },

        emitAllowEventFilter(value) {
            console.log("emmited allow event filter");
            this.$emit('allowEventFilter', value);
        },

        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "event_id ASC",
            ]
        },
    }
}
</script>