<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t("Payout") }}</label>
        <input type="number"
          class="form-control"
          :value="local"
          @input="$emit('input',$event.target.value)" 
          :name="name"
          min="0"
          :label="$t('Payout')"
          v-validate="'required|min_value:0'"
          :error="getError('price')"
          :disabled="! isEditable"
      />
      </div>

      <div v-show="errors.has(name)"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first(name) }}</div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  name: "price",

  props: {
    value: null,
    name: {
      type: String,
      required: false,
      default: "price",
    },

    isEditable: {
      type: Boolean,
      default: true
    },

    commercialModel: {
      type: Number,
    }
  },

  created() {
    this.$validator = this.parentValidator;
  },

  watch: {
    commercialModel () {
      if (typeof(this.commercialModel) == "undefined" || this.commercialModel == null) return;
      if (this.commercialModel == 4 /* N/A */) {
        this.$emit('input', null)
      }
    }
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },
};
</script>

<style>
</style>
