<template>
  <el-dialog
    top="15vh"
    width="300px"
    title="Select node"
    :show-close="false"
    :visible.sync="dialogVisible"
    @closed="clearData"
  >
    <div>
      <el-select v-model="id" placeholder="Select" v-if="dataGoTo" class="w-100">
        <el-option
          v-for="node in dataGoTo.nodes"
          :key="node"
          :label="`Node ${node}`"
          :value="node"
        >
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">{{
        $t("Cancel")
      }}</el-button>
      <el-button size="small" type="primary" @click="saveData" :disabled="id === null">{{
        $t("Save")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { Dialog, Button, Select } from "element-ui";

  export default {
    name: "ModalGoTo",
    props: ["dataGoTo"],
    components: {
      [Dialog.name]: Dialog,
      [Button.name]: Button,
      [Select.name]: Select,
    },

    data() {
      return {
        dialogVisible: false,
        id: null,
      };
    },

    watch: {
      dataGoTo() {
        this.dialogVisible = this.dataGoTo !== null;
      },
    },

    methods: {
      clearData() {
        this.$emit("clearData");
        this.id = null;
      },

      saveData() {
        if(this.id !== null) {
          this.$emit("createGoTo", {
            dataCreate: this.dataGoTo.data, 
            data: {
              goto: this.id,
            }
          });
          this.dialogVisible = false;
        }
      }
    },
  };
</script>
