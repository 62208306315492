const CampaignStatusIdDraft = 22;
const CampaignStatusIdReady = 15;
const CampaignStatusIdProgrammed = 4;
const CampaignStatusIdCancelled = 9;
const CampaignStatusIdFinished = 7;
const CampaignStatusIdFinishedWithPendingUsers = 16;
const CampaignStatusIdWaiting = 17;


export default {

    StatusIdCanEditCampaign: [
        CampaignStatusIdProgrammed,
        CampaignStatusIdReady,CampaignStatusIdDraft
    ],

    StatusIdRetryTypeMustBeProgrammed: [
        CampaignStatusIdCancelled,
        CampaignStatusIdFinished,
        CampaignStatusIdFinishedWithPendingUsers
    ],

    StatusIdFinished: [
        CampaignStatusIdCancelled,
        CampaignStatusIdFinished,
        CampaignStatusIdFinishedWithPendingUsers
    ],

    StatusIdWaiting: CampaignStatusIdWaiting,
    StatusIdProgrammed: CampaignStatusIdProgrammed,

    ScenarioTypesWithLaunchBrowser: [10,15,19,20,27,37,52,53,54,55,56,57,74,81,85,86,88,99]

};
