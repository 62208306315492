<template>
  <div class="col-12">
    <!-- <label>{{ $t(field.tooltip) }}</label> -->
    <fg-input :label="$t(field.tooltip)" :error="getError(field.name)">
      <el-select
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(243 243 243 / 80%)"
        size="large"
        :placeholder="$t('Select agent')"
        :name="field.name"
        :value="cleanValue"
        filterable
        :disabled="disabled || loading"
        @input="$emit('input', $event)"
        v-validate="field.required ? 'required' : ''"
      >
        <el-option
          v-for="option in account_agents"
          :value="option.id"
          :label="option.name"
          :key="option.id"
        ></el-option>
      </el-select>
    </fg-input>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import api from "src/api";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  inject: ["getError", "parentValidator"],
  props: ["value", "field", "width", "disabled"],

  data() {
    return {
      account_agents: [],
      loading: true,
    };
  },

  computed: {
    cleanValue() {
      return this.value ? parseInt(this.value) : null;
    },
  },

  created() {
    this.$validator = this.parentValidator;
    this.loadAgents();
  },

  methods: {
    async loadAgents() {
      try {
        const res = await api.getRcsAgents();
        this.account_agents = res.data;
        // console.log(this.account_agents);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
:deep(.el-select .el-loading-spinner) {
  top: 80% !important;
}
</style>
