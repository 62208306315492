<template>
  <div class="row">
    <div class="col-md-6">
      <NewServices @updateServices="getSubscriptionServices" />
    </div>
    <div class="col-md-6">
      <UploadServices :services="services" @updateServices="getSubscriptionServices" />
    </div>
    
    <div class="col-md-12">
      <ViewServices :services="services" @updateServices="getSubscriptionServices" />
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { mapMutations } from "vuex";
import NewServices from "src/app-components/services/NewServices";
import UploadServices from "src/app-components/services/UploadServices";
import ViewServices from "src/app-components/services/ViewServices";
export default {
  name: "AudienceServicesIndex",
  components: {
    NewServices,
    UploadServices,
    ViewServices,
  },

  data() {
    return {
      services: [],
    };
  },

  created() {
    this.setTopNavbarTitle("Services");
    this.getSubscriptionServices();
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    async getSubscriptionServices() {
      let res = await api.getSubscriptionServices();
      this.services = [...res.data];
    },
  },
};
</script>

<style>
.card-header p {
  font-size: 1.714em;
}
</style>