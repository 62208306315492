<template>
    <div class="d-flex align-items-center ml-auto">
        <div class="flex-shrink-1">
            <p-pagination :page-count="countPages"
                        v-model="pagination.currentPage"
                        :per-page="pagination.itemsPerPage"
                        class="m-0">
            </p-pagination>
        </div>
        <!-- Dropdown -->
        <div class="flex-shrink-1">
            <drop-down>
                <p-button slot="title"
                            class="dropdown-toggle m-0"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            type="primary"
                            size="sm"
                            block outline
                            round>
                    {{$t('Show')}} {{pagination.itemsPerPage}}
                </p-button>
                <a class="dropdown-item" @click="pagination.itemsPerPage = 5">5</a>
                <a class="dropdown-item" @click="pagination.itemsPerPage = 10">10</a>
                <a class="dropdown-item" @click="pagination.itemsPerPage = 20">20</a>
            </drop-down>
        </div>
    </div>
</template>

<script>
import Pagination from 'src/components/UIComponents/Pagination';

export default {
    components: {
        [Pagination.name]: Pagination,
    },

    props: {
        pagination : {
            type: Object,
            // required: false,
            default: () => ({
                currentPage: 1,
                itemsPerPage: 10,
            })
        },
        tableData: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        paginatedRows() {
            // Devuelve una pagina del arreglo
            return this.tableData.slice((this.pagination.currentPage - 1) * this.pagination.itemsPerPage, this.pagination.currentPage * this.pagination.itemsPerPage);
        },
        countPages() {
            // Devuelve cantidad de paginas del arreglo
            return Math.ceil(this.tableData.length/this.pagination.itemsPerPage);
        }
    },

    watch: {
        paginatedRows: function (paginatedTableData) {
            this.$emit("paginated-table", paginatedTableData );
        }
    }
}
</script>