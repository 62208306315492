<template>
  <div class="form-conversation">
    <div class="mb-3">
      <div class="d-flex align-items-center mb-2">
        <p class="mb-0 mr-2 title-option">Insistence</p>
        <el-switch v-model="view.insistence"></el-switch>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <el-input
            type="number"
            max="1440"
            v-model="dataConversation.insistence.minutes"
            size="small"
            :disabled="!view.insistence"
            @input="checkMinutes"
            @change="parseMinutes('insistence')"
          >
            <template slot="append">Minutes</template>
          </el-input>
        </div>
        <div class="w-100">
          <el-input
            placeholder="Message"
            type="textarea"
            :autosize="true"
            v-model="dataConversation.insistence.message"
            :disabled="!view.insistence"
            @input="updateData"
            :class="!validateInsistence ? 'error' : ''"
          ></el-input>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="d-flex align-items-center mb-2">
        <p class="mb-0 mr-2 title-option">Timeout</p>
        <!-- <el-switch v-model="view.timeout"></el-switch> -->
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <el-input
            type="number"
            max="1440"
            v-model="dataConversation.timeout.minutes"
            size="small"
            @input="checkMinutes"
            step="1"
            @keydown="test"
            >
            <!-- @change="parseMinutes('timeout')" -->
            <template slot="append">Minutes</template>
          </el-input>
        </div>
        <div class="w-100">
          <el-input
            placeholder="Message"
            type="textarea"
            :autosize="true"
            v-model="dataConversation.timeout.message"
            @input="updateData"
          ></el-input>
        </div>
      </div>
    </div>

    <div>
      <div class="d-flex align-items-center mb-2">
        <p class="mb-0 mr-2 title-option">Closing message</p>
        <el-switch v-model="view.closingMessage"></el-switch>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-100">
          <el-input
            placeholder="Message"
            type="textarea"
            :autosize="true"
            v-model="dataConversation.closingMessage.message"
            :disabled="!view.closingMessage"
            @input="updateData"
          ></el-input>
        </div>
      </div>

      <div class="py-3 closing-phrases">
        <div @keypress.enter="addClosingPhrase">
          <div class="d-flex align-items-center mb-2">
            <p class="mb-0 mr-2 title-option">Global closing phrases</p>
            <el-switch v-model="view.globalClosingPhrases"></el-switch>
          </div>
          <el-input
            v-model="newClosingPhrase"
            size="small"
            class="new-closing-phrase"
            :disabled="!view.globalClosingPhrases || maxClosingPhrases"
          >
            <template slot="append">
              <span :class="!view.globalClosingPhrases || maxClosingPhrases ? '' : 'pointer'" @click="addClosingPhrase">
                <i class="el-icon-plus"></i>
              </span>
            </template>
          </el-input>
        </div>

        <div v-if="view.globalClosingPhrases">
          <el-tag
            closable
            :key="index"
            type="info"
            size="small"
            class="m-0 mt-2 mr-1"
            @close="deleteClosingPhrase(index)"
            :disable-transitions="false"
            v-for="(phrase, index) in dataConversation.globalClosingPhrases
              .phrases"
          >
            {{ phrase }}
          </el-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Button, Tabs, TabPane, Input, Switch, Tag } from "element-ui";

  export default {
    name: "FormConversation",
    props: ["conversation", "validateInputs"],
    components: {
      [Button.name]: Button,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [Input.name]: Input,
      [Switch.name]: Switch,
      [Tag.name]: Tag,
    },

    data() {
      return {
        newClosingPhrase: "",
        updateDisabled: true,

        view: {
          insistence: false,
          timeout: true,
          closingMessage: false,
          globalClosingPhrases: false,
        },

        dataConversation: {
          insistence: {
            minutes: null,
            message: null,
          },
          timeout: {
            minutes: 60,
            message: "",
          },
          closingMessage: {
            message: null,
          },
          globalClosingPhrases: {
            phrases: null,
          },
        },
      };
    },

    mounted() {
      this.setView();

      this.dataConversation = { ...this.conversation };

      setTimeout(() => {
        this.updateDisabled = false;
      }, 10);
    },

    watch: {
      "view.insistence"() {
        if (this.updateDisabled) return;

        if (this.view.insistence) {
          this.dataConversation.insistence = {
            minutes: 1,
            message: "",
          };
        } else {
          this.dataConversation.insistence = {
            minutes: null,
            message: null,
          };
        }

        this.updateData();
      },

      "view.closingMessage"() {
        if (this.updateDisabled) return;

        if (this.view.closingMessage) {
          this.dataConversation.closingMessage = {
            message: "",
          };
        } else {
          this.dataConversation.closingMessage = {
            message: null,
          };
        }

        this.updateData();
      },

      "view.globalClosingPhrases"() {
        if (this.updateDisabled) return;

        if (this.view.globalClosingPhrases) {
          this.dataConversation.globalClosingPhrases = {
            phrases: [],
          };
        } else {
          this.dataConversation.globalClosingPhrases = {
            phrases: null,
          };
        }

        this.updateData();
      },
    },

    methods: {
      checkMinutes(event) {
        if (this.dataConversation.insistence.minutes !== null) {
          this.dataConversation.insistence.minutes = this.dataConversation.insistence.minutes.replace(/[^0-9]+/g, '');
          if (this.dataConversation.insistence.minutes > 1439) {
            this.dataConversation.insistence.minutes = 1439;
          } else if (this.dataConversation.insistence.minutes < 1) {
            this.dataConversation.insistence.minutes = 1;
          }
        }

        let limitTimeout =
          this.dataConversation.insistence.minutes !== null
            ? parseInt(this.dataConversation.insistence.minutes) + 1
            : 1;

        if (this.dataConversation.timeout.minutes !== null) {
          this.dataConversation.timeout.minutes = this.dataConversation.timeout.minutes.replace(/[^0-9]+/g, '');
          if (this.dataConversation.timeout.minutes > 1440) {
            this.dataConversation.timeout.minutes = 1440;
          } else if (this.dataConversation.timeout.minutes <= limitTimeout) {
            this.dataConversation.timeout.minutes = limitTimeout;
          }
        }

        this.updateData();
      },

      updateData() {
        this.$emit("updateData", this.dataConversation);
      },

      parseMinutes(type) {
        // type = "insistence" || "timeout"
        if(this.dataConversation[type].minutes) {
          this.dataConversation[type].minutes = parseInt(this.dataConversation[type].minutes);
        }
      },

      addClosingPhrase() {
        if (this.maxClosingPhrases) return;

        let newPhrase = this.newClosingPhrase.toLowerCase().trim();

        if(newPhrase === "") return;

        let exist =
          this.dataConversation.globalClosingPhrases.phrases.indexOf(newPhrase) !== -1;

        if (!exist) {
          this.dataConversation.globalClosingPhrases.phrases.push(newPhrase);
        }

        this.newClosingPhrase = "";
        this.updateData();
      },

      deleteClosingPhrase(key) {
        if (this.dataConversation.globalClosingPhrases.phrases) {
          if (this.dataConversation.globalClosingPhrases.phrases[key]) {
            this.dataConversation.globalClosingPhrases.phrases.splice(key, 1);
            this.updateData();
          }
        }
      },

      setView() {
        let { insistence, closingMessage, globalClosingPhrases } =
          this.conversation;

        if (insistence.minutes || insistence.message) {
          this.view.insistence = true;
        }

        if (closingMessage.message) {
          this.view.closingMessage = true;
        }

        if (globalClosingPhrases) {
          if (globalClosingPhrases.phrases.length) {
            this.view.globalClosingPhrases = true;
          }
        }
      },
    },

    computed: {
      maxClosingPhrases() {
        if(!this.dataConversation) return;
        if(!this.dataConversation.globalClosingPhrases) return;
        if (this.dataConversation.globalClosingPhrases.phrases === null) return;

        return this.dataConversation.globalClosingPhrases.phrases.length >= 5;
      },

      validateInsistence() {
        if (this.validateInputs) {
          if (this.dataConversation.insistence) {
            if (
              !this.dataConversation.insistence.message &&
              this.dataConversation.insistence.minutes
            ) {
              return false;
            }
          }
        }
        return true;
      },
    },
  };
</script>

<style>
  .new-closing-phrase .el-input-group__append {
    padding: 0;
  }
  
  .new-closing-phrase .el-input-group__append span {
    padding: 0 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>