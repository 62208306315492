<template>
  <div class="card" :style="'background-color: ' + backgroundColor">
    <div class="card-header text-center">
      <slot name="header">
        <h5 class="card-title" style="color: #5b6b62;">{{title}}</h5>
        <p class="card-category">{{description}}</p>
      </slot>
    </div>
    <div class="card-body">
        <slot name="content">
            <doughnut-chart :data="chartData"
                            :labels="labels"
                            :height="250"
                            :secondary-color="secondaryColor"
                            v-if="chartData[0] !== null"
                            :color="color">
            </doughnut-chart>
      </slot>
    </div>
    <div class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard'

  export default {
    name: 'circle-chart-card',
    extends: CircleChartCard,
    props: {
      backgroundColor: {
        type: String,
        default: '#FFFFFF',
        description: 'Chart color'
      },
    }
  }
</script>
<style>
</style>
