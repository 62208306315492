<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group has-label">
                    <label>Enabled?</label>
                    <br />
                    <p-switch type="primary"
                            on-text="ON"
                            off-text="OFF"
                            :value="value"
                            @input="handleInput"
                    ></p-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PSwitch from "src/components/UIComponents/Switch";

export default {
    components: {
        [PSwitch.name]: PSwitch
    },

    props: {
        value: Boolean
    },

    methods: {
        handleInput(value) {
            this.$emit("input", value);
        },
    }
}
</script>