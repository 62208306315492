<template>
	<div class="col-md-6">
		<div class="card profile-card">
			<div id="card-header"></div>
			<div class="profile-avatar">
				<img v-if="logo" :src="logo" />
			</div>
			<div class="card-content">
				<h3>{{ name }}</h3>
				<p>{{ description }}</p>

				<div class="icon-block">
					<div class="row">
						<template v-for="icon in icons">
                            <div class="col-md-4 text-center" :key="icon.name">
                                <a href="#">
                                    <i class="fa clean green" :class="`fa-${icon.name}`"></i>
                                    <p>{{ icon.text }}</p>
                                </a>
                            </div>
                        </template>    
					</div>
				</div>
				<hr />

				<div v-for="phone in phones" class="row phone">
					<div class="col-md-3">
						<i class="fa fa-phone clean"></i>
					</div>
					<div class="col-md-6">
						{{ phone.value }}
					</div>
				</div>

				<hr />
				<div class="row">
					<div class="col-md-3">
						<i class="fa fa-globe clean"></i>
					</div>
					<div class="col-md-6">
						{{ website }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AgentPreview",
    props: ['form', 'formattedPhones'],
	computed: {
        logo() {
            return this.form ? this.form.logo : '';
        },
        name() {
            return this.form ? this.form.name : '';
        },
        description() {
            return this.form ? this.form.description : '';
        },
        website() {
            return this.form ? this.form.website : '';
        },
        phones() {
            return this.formattedPhones;
        },
        icons() {
            return [
                {
                    name: 'phone',
                    text: 'Call'
                },
                {
                    name: 'globe',
                    text: 'Website'
                },
                {
                    name: 'envelope',
                    text: 'Email'
                },
            ]
        }
    },
};
</script>
