<template>
  <div :class="width">
    <fg-input
      :name="field.name"
      :label="$t(field.tooltip)"
      v-validate="field.required ? 'required' : ''"
      :value="value"
      @input="$emit('input', $event)"
      type="text"
      :error="getError(field.name)"
      :disabled="disabled"
    ></fg-input>
  </div>
</template>
<script>
export default {
  inject: ["getError", "parentValidator"],

  props: ["value", "field", "width", "disabled"],

  created() {
    this.$validator = this.parentValidator;
  }
};
</script>