<template>
  <div class="card">
    <div class="card-header">
      <p class="mb-0">{{ $t("Upload active/subscribed users") }}</p>
    </div>
    <div class="card-body" id="services-dropzone">
      <el-select
        filterable
        :placeholder="$t('Select Service')"
        v-model="serviceSelected"
        style="width: 100%"
        @change="updateServiceSelected"
      >
        <!-- :error="getError('account_scenario_type_id')" -->
        <el-option
          v-for="option in services"
          :value="option.id"
          :label="$t(option.name)"
          :key="option.id"
        ></el-option>
      </el-select>
      <p class="description-input">
        {{ $t("Drop your active/subscribed files here to upload") }}
        <br />
        {{ $t("or") }}
      </p>
      <file-upload
        class="btn-file"
        ref="services"
        v-model="files"
        name="file"
        :directory="false"
        drop="#services-dropzone"
        :post-action="fileUploadUrl"
        :headers="uploadHeaders"
        @input-file="inputFile"
        @input-filter="checkDroppedFile"
        :data="{ service_id: serviceSelected }"
      >
        <i class="nc-icon nc-simple-add"></i>
        {{ $t("Select Service") }}
      </file-upload>
      <input-tag
        class="w-100"
        :value="filesNames"
        @input="deleteFile"
      ></input-tag>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-info btn-block" @click="startUpload" :disabled="disabled">
        {{ $t("Save") }}
      </button>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import swal from "sweetalert2";
import InputTag from "vue-input-tag";
import sio from "@/sio";
const VueUploadComponent = require("vue-upload-component");
import { Select, Option } from "element-ui";

export default {
  name: "UploadServices",
  props: {
    services: {
      type: Array,
      default: () => [],
    },  
  },

  components: {
    InputTag,
    "file-upload": VueUploadComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },

  created() {
    sio.onAny((event, obj) => {
      if(obj.metadata){
        if(obj.metadata.service_id){
          this.notificationUpload(obj.metadata.service_id);
        }
      }
    });
  },

  data() {
    return {
      files: [],
      serviceSelected: null,

      fileUploadUrl: api.getBaseUrl() + "/subscriptions/active",
      uploadHeaders: api.getHeaders(),
      allowedFileTypes: ["txt", "csv"],

    };
  },

  mounted() {
    this.disabledInputTag();
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    notificationUpload(id) {
      let service = this.services.find((service) => service.id === id);
      this.$notify({
        title: `"${service.name}" ${this.$t("updated")}`,
        message: this.$t("The file was uploaded successfully"),
        icon: "fa fa-gift",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success"
      });
    },

    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr && newFile.xhr.status === 200) {
          const accountFile = newFile.response.data;
          this.$refs.services.update(newFile, { accountFile: accountFile });

          // this.notificationUpload(newFile.data.service_id);

          // TODO: Agregar mensaje al usuario
          this.$emit("updateServices");
          this.files = [];
          this.serviceSelected = null;
        }
      }
    },

    checkDroppedFile: function(newFile, oldFile, prevent) {
      if (!newFile) {
        return;
      }

      if (newFile && !oldFile) {
        // Filter files
        const ext = newFile.name
          .substr(newFile.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (!this.allowedFileTypes.includes(ext)) {
          swal.fire({
            icon: "error",
            title: this.$t("File type not supported"),
            text: `${this.$t("Supported types")}: ${this.allowedFileTypes.join(", ")}`,
          });
          return prevent();
        }
        // newFile.postAction = newFile.postAction + "?file_type=" + "SERVICES";
      }
    },

    startUpload() {
      this.$refs.services.active = true;
    },

    deleteFile(arr) {
      this.files.forEach((file, i) => {
        if (arr.indexOf(file.name) === -1) {
          this.files.splice(i, 1);
        }
      });
    },

    disabledInputTag() {
      let input = document.querySelector("#services-dropzone .new-tag");
      input.setAttribute("disabled", "disabled");
    },

    updateServiceSelected() {
      if(this.files.length) {
        if(this.files[0].data) {
          this.files[0].data.service_id = this.serviceSelected;
        }
      }
    },
  },

  computed: {
    filesNames() {
      let names = [];
      this.files.forEach((file) => names.push(file.name));
      return names;
    },

    disabled() {
      if(this.files.length === 0 || this.serviceSelected === null) {
        return true;
      }
      
      return false;
    },
  },
};
</script>
<style scoped>
.card {
  display: flex;
  flex-direction: column;
  height: 320px;
}

/* .card-header p {
  font-size: 1.714em;
} */

.btn-file {
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 90px);
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  border: 2px dashed #51bcda;
  border-radius: 5px;
  margin-bottom: 10px;
}

.description-input {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 70px;
  margin-bottom: 0;
}

#services-dropzone {
  width: calc(100% - 20px);
  margin: 10px;
  margin-bottom: 0;
  /* border: 2px dashed #51bcda;
  border-radius: 5px; */
  padding: 0;
  position: relative;
}
</style>

<style>
#services-dropzone .vue-input-tag-wrapper {
  border: 0;
}

#services-dropzone .vue-input-tag-wrapper .new-tag {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

#services-dropzone .vue-input-tag-wrapper .input-tag {
  background: #51bcda;
  border-color: #51bcda;
  color: white;
}

#services-dropzone .vue-input-tag-wrapper .input-tag .remove {
  color: white;
}
</style>
