<template>
  <div>
    <SectionHeader text="Campaign Info" :top-margin="false" />
    <div class="row">
      <div class="col-md-3">
        <campaign-name
          v-model.trim="form.name"
          :isUnique="!hasId"
          :disabled="disabled"
        >
        </campaign-name>
      </div>

      <div class="col-md-2">
        <commercial-model
          :is-editable="!disabled"
          v-model.number="form.commercial_model_id"
        />
      </div>

      <div class="col-md-1">
        <price
          v-model.number="form.price"
          :commercial-model="form.commercial_model_id"
          :isEditable="4 !== form.commercial_model_id && !disabled"
        />
      </div>

      <div class="col-md-3" v-if="$can('AssignUser', 'Campaigns')">
        <Users
          :filterable="true"
          :no-validation="true"
          v-model="form.user_id"
          :disabled="disabled"
        />
      </div>
    </div>

    <SectionHeader text="Flight Date" />

    <div class="row">
      <div class="col-md-7">
        <date-programmable
          :required-endtime="isWithPing"
          :auto-fill-endtime="isWithPing"
          :disabled="disabled"
          v-model="dateProgrammed"
        ></date-programmable>
      </div>

      <div class="col-md-1">
        <WithPingEnabled
          v-if="isPingAvailable"
          :force="hasCap"
          :disabled="disabled || hasCap"
          :variables="form.variables"
          @input="setVariable($event)"
        />
        <!-- v-model="form.variables" -->
      </div>

      <portal to="campaignConfigurationInProductConfiguration">
        <template v-if="showGapTime || showAmountUsers">
          <SectionHeader :text="$t('AD Optimizer')" />
          <p class="text-left">
            {{
              $t(
                "Configure the Sampling and the Waiting time to collect results over the selected ad versions. After this period the platform will use the best for the remaining audience"
              )
            }}
          </p>
          <div class="row">
            <div class="col-md-6" v-if="showGapTime">
              <performance-gap-time
                :variables="form.variables"
                :disabled="disabled"
                @input="setVariable($event)"
              >
              </performance-gap-time>
            </div>

            <div class="col-md-6" v-if="showAmountUsers">
              <amount-users
                :variables="form.variables"
                :disabled="disabled"
                @input="setVariable($event)"
              >
              </amount-users>
            </div>
          </div>
        </template>
      </portal>
    </div>

    <SectionHeader text="Campaign Cap" />
    <div class="row">
      <div class="col-md-2">
        <cap-type
          :variables="form.variables"
          :disabled="disabled"
          @enabled="checkCap"
          @input="setVariable($event)"
        />
      </div>

      <numeric-variable
        vname="cap-goal"
        :vid="93"
        :label="$t('Cap goal')"
        :visible="hasCap"
        :min="1"
        ref="cap-goal"
        :variables="form.variables"
        @input="setVariable($event)"
      />
    </div>
  </div>
</template>

<script>
import api from "src/api";

import { updatableForm } from "src/app-components/FormMixin";
import DateProgrammedMixin from "src/app-components/DateProgrammedMixin";

import WithPingEnabled from "src/app-components/form-elements/WithPingEnabled";
import CommercialModel from "src/app-components/form-elements/CommercialModel";
import Price from "src/app-components/form-elements/Price";
import PerformanceGapTime from "src/app-components/form-elements/PerformanceGapTime";
import AmountUsers from "src/app-components/form-elements/AmountUsers";
import CampaignName from "src/app-components/form-elements/CampaignName";
import DateProgrammable from "src/app-components/form-elements/DateProgrammable";
import CapType from "src/app-components/form-elements/CapType";
import NumericVariable from "src/app-components/form-elements/NumericVariable";
import SectionHeader from "src/app-components/form-elements/SectionHeader";
import { Portal } from "portal-vue";
import Users from "src/app-components/form-elements/Users";

export default {
  inject: {
    parentValidator: "parentValidator",
  },

  mixins: [updatableForm, DateProgrammedMixin("form")],

  components: {
    AmountUsers,
    CommercialModel,
    PerformanceGapTime,
    CampaignName,
    [DateProgrammable.name]: DateProgrammable,
    Price,
    WithPingEnabled,
    CapType,
    NumericVariable,
    SectionHeader,
    Portal,
    Users,
  },

  props: {
    commercialModelId: {
      type: Number,
      required: false,
      default: 0,
    },

    price: {
      type: Number,
      required: false,
      default: 0,
    },

    showGapTime: {
      type: Boolean,
      default: false,
    },

    showAmountUsers: {
      type: Boolean,
      default: false,
    },

    sizeAmountUsers: {
      type: Number,
      default: 0,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$validator = this.parentValidator;
  },

  computed: {
    hasId() {
      return typeof this.form.id !== "undefined" && this.form.id !== null;
    },

    isManualStart() {
      return this.form.manual_start === true;
    },

    isWithPing() {
      if (typeof this.form.variables !== "undefined") {
        let active = this.form.variables.find((variable) => {
          if (variable.variable) {
            return variable.variable.name === "active-ping";
          }
        });
        return active ? active.value : false;
      }

      return false;
    },

    hasCap() {
      if (!this.form.variables) return false;

      let index = this.form.variables.findIndex((variable) => {
        if (variable.variable) {
          return variable.variable.name === "cap-type";
        }
      });

      if (index !== -1) {
        return this.form.variables[index].value !== "none";
      }

      return false;
    },

    isPingAvailable() {
      return [1, 4, undefined].includes(this.form.channel_id);
    },
  },

  methods: {
    checkCap(capEnabled) {
      if (!capEnabled) {
        this.$delete(this.form.variables, "cap-goal");
        this.$emit("removeVariable", "cap-goal");
      }
    },

    setVariable($event) {
      let { variable_id, value } = $event;
      if (variable_id) {
        let index = this.form.variables.findIndex(
          (variable) => variable.variable_id == variable_id
        );
        if (index > -1) {
          this.form.variables[index].value = value;
        } else {
          this.form.variables.push($event);
        }
      }
    },
  },

  watch: {
    commercialModelId(nv) {
      this.form.commercial_model_id = nv;
    },

    price(nv) {
      this.form.price = nv;
    },
  },

  data() {
    return {
      form: {
        id: null,
        user_id: null,
        end_datetime: null,
        start_datetime: null,
        commercial_model_id: this.commercialModelId,
        price: this.price,
        manual_start: null,
        name: null,
        variables: [],
      },
    };
  },
};
</script>

<style></style>
