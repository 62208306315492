<template>
  <div
    class="row py-2 animated fadeIn"
    :class="[inUse && !canEdit ? 'ph-in-use' : 'bg-light']"
  >
    <fg-input
      class="col-3 col-md-2"
      labelClasses="w-100 text-left"
      :label="$t('Name')"
      :error="$t(getError(`name-${index}`))"
    >
      <el-input
        v-model="placeholder.name"
        size="mini"
        type="text"
        :name="`name-${index}`"
        :disabled="!canEdit"
        v-validate="'required|ph_unique_name'"
      ></el-input>
    </fg-input>
    <fg-input
      class="col-3 col-md-2"
      labelClasses="w-100 text-left"
      :label="$t('Max length')"
      :error="$t(getError(`max-length-${index}`))"
    >
      <el-input
        v-model.number="placeholder.max_length"
        size="mini"
        :name="`max-length-${index}`"
        :disabled="!canEdit"
        v-validate="'required|numeric|min_value:1'"
        data-vv-as="max length"
      ></el-input>
    </fg-input>
    <div class="col-3 col-md-2">
      <label class="w-100 text-left">{{ $t("Type") }}</label>
      <el-select
        v-model="placeholder.type"
        class="w-100"
        size="mini"
        placeholder="Select"
        :disabled="!canEdit"
      >
        <el-option
          v-for="type in type_options"
          :key="type.value"
          :label="type.name"
          :value="type.value"
        ></el-option>
      </el-select>
    </div>
    <div class="col">
      <label class="w-100 text-left">{{ $t("Alias") }}</label>
      <input-tag
        class="w-100"
        :placeholder="$t('Press ENTER for each alias to confirm.')"
        v-model="alias"
        disabled
      ></input-tag>
    </div>
    <div
      class="col-auto d-flex justify-content-start align-items-center flex-column"
    >
      <label>{{ $t("Can be empty") }}</label>
      <p-checkbox v-model="placeholder.can_be_empty"></p-checkbox>
    </div>
    <div
      class="col-auto d-flex justify-content-center"
      v-if="!inUse || canEdit"
      :class="
        getError(`max-length-${index}`) || getError(`name-${index}`)
          ? 'align-items-start pt-2'
          : 'align-items-end'
      "
    >
      <p-button size="sm" type="default" icon round @click="$emit('delete')">
        <i class="fa fa-close"></i>
      </p-button>
    </div>
    <span v-else class="message animated fadeInLeft">{{ $t("IN USE") }}</span>
  </div>
</template>

<script>
import InputTag from "vue-input-tag";
import { Input } from "element-ui";
import PSwitch from "src/custom-components/UIComponents/Switch";
import { Checkbox } from "src/components/UIComponents";

export default {
  components: {
    InputTag,
    [PSwitch.name]: PSwitch,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
  },
  props: {
    placeholder: Object,
    index: Number,
    inUse: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  inject: ["getError", "parentValidator"],
  data() {
    return {
      type_options: [
        { name: "String", value: "string" },
        { name: "Number", value: "number" },
      ],
      alias: [],
    };
  },
  computed: {
    canSave() {
      return typeof this.placeholder.id == "undefined";
    },
    canEdit() {
      return !this.inUse || this.errors.has(`name-${this.index}`);
    },
  },
  created() {
    this.$validator = this.parentValidator;
    this.setAlias();
  },

  methods: {
    setAlias() {
      this.placeholder.alias.forEach(alias => this.alias.push(alias.alias));
    },
  },

  watch: {
    alias() {
      let newAlias = [];
      this.alias.forEach(alias => {
        let exist = this.placeholder.alias.find(element => element.alias == alias);
        if (exist) {
          newAlias.push(exist);
        }else {
          newAlias.push({ alias });
        }
      });
      this.placeholder.alias = newAlias;
    },
  }
};
</script>

<style lang="scss" scoped>
:deep(.vue-input-tag-wrapper) {
  padding-top: 0;
  ::placeholder {
    text-overflow: ellipsis;
  }
  .new-tag {
    margin: 0;
    padding: 3px;
  }
  .input-tag {
    padding: 0 3px;
    margin: 2px 1px;
  }
}
:deep(.form-check .form-check-sign::before),
:deep(.form-check .form-check-sign::after) {
  top: -12px;
}
.ph-in-use {
  background-color: #ffd8005c;
  position: relative;
  padding-right: 32px;
  border-bottom: 4px solid #ffd8005c;
  & span.message {
    background-color: #ffe761;
    color: white;
    font-weight: 900;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    padding: 0.5em 0.35em;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

:deep(.el-input.is-disabled .el-input__inner) {
  background-color: #e3e3e3 !important;
}
</style>
