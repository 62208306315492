<template>
    <div>
        <div class="text-muted mb-2 mt-4"> > {{ $t("Step") }} {{ currentStep }}</div>
        <slot></slot>
        <div v-if="!disabled" class="row mt-3">
            <div class="col-md-9">
                <p-button type="dark" @click="onCancel">Back</p-button>
                <p-button v-if="!hideButtons" type="info" @click="onSubmit">Next</p-button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        /*
        provide() { // Pass to Mixin
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },
        */

        props: {
            currentStep: Number,
            disabled: Boolean,
            hideButtons: Boolean
        },

        methods: {
            onSubmit() {
                this.$emit("submit");
            },
            onCancel() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style>

</style>
