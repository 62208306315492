<template>
	<div @keyup.esc="closeModal" tabindex="0">
		<reject-creativity-configuration
			:show="showRejectModal"
			@modal-cancel="rejectCreativityModalCancel"
			@modal-save="rejectCreativityModalAccept"
		>
		</reject-creativity-configuration>

		<test-product-modal
			:show="showTestProductModal"
			@modal-cancel="testProductModalCancel"
			@modal-save="testProductModalAccept"
		>
		</test-product-modal>

		<h5 v-if="$route.query.createFromProduct" class="text-center mb-5">
			{{
				$route.query.event
					? $t(
							"Please, select the template you want to include in the external event"
					  )
					: $t(
							"Please, select the template you want to include in the campaign"
					  )
			}}
		</h5>

		<!--<Filters2 
      :default-values="defaultFilters"
      @filterUpdated="filterUpdated($event, refreshScroll)"
    />-->

		<div v-if="allBookmarks">
			<masonry
				:cols="{
					default: 6,
					2540: 6,
					1920: 5,
					1550: 4,
					1280: 3,
					900: 2,
				}"
				:gutter="30"
			>
				<!--
      <div class="bd-callout p-0 card card-stats" style="height: 300px;border: 0px;cursor: pointer;" @click="gotoNew">
        <div class="card-body ">
            <div class="row justify-content-center" style="padding-top: 80px;">
              {{ $t('NEW TEMPLATE') }}
            </div>  
            <div class="row justify-content-center" style="padding-top: 50px;">
              <i class="nc-icon nc-simple-add" style="font-size: 50px;color: mediumseagreen;"/>
            </div>  
        </div>
      </div>
      -->
				<div v-for="(bookmark, index) in allBookmarks" :key="index">
					<card-bookmark
						:bookmark="bookmark"
						@creativity-changed="creativityChanged"
						ref="cardBookmark"
					>
						<template slot-scope="slotProps" slot="action">
							<div
								v-if="
									!$route.query.createFromProduct &&
										bookmark.creatives.length > 0
								"
								class="float-left"
								style="padding-left: 0;"
							>
								<p
									v-if="bookmark.creatives.length > 0"
									class="card-category-mini centered-label"
								>
									{{ $t(getCreativity(bookmark.id).status) }}
								</p>
							</div>

							<div
								:class="
									$route.query.createFromProduct
										? 'text-center'
										: 'text-right'
								"
								style="padding-right: 0;"
							>
								<template
									v-if="!$route.query.createFromProduct"
								>
									<p-button
										v-if="
											$can('Approve', 'Bookmarks') &&
												!bookmark.archived
										"
										@click="handleTest(bookmark.id, index)"
										type="info"
										:title="$t('Send test')"
										icon
										:outline="true"
										class="btn-sm"
										round
									>
										<i class="fa fa-paper-plane"></i>
									</p-button>

									<!--
              <p-button v-if="$can('CreateFromProduct', 'Campaigns') && ! bookmark.archived && bookmark.status == 'APPROVED'"
                        @click="handleCreateNewCamapaign(bookmark.id, index)"
                        type="success"
                        :title="$t('Create a new campaign from this product')"
                        icon
                        :outline="true"
                        class="btn-sm"
                        round>
                <i class="fa fa-paper-plane"></i>
              </p-button>
              -->

									<p-button
										v-if="
											bookmark.creatives.length > 0 &&
												$can('Approve', 'Bookmarks') &&
												!bookmark.archived
										"
										@click="
											handleApprove(bookmark.id, index)
										"
										type="success"
										:title="$t('Approve creative')"
										icon
										:outline="!isApproved(bookmark.id)"
										class="btn-sm"
										round
									>
										<i class="icon icon-like-7"></i>
									</p-button>

									<p-button
										v-if="
											bookmark.creatives.length > 0 &&
												$can('Reject', 'Bookmarks') &&
												!bookmark.archived
										"
										@click="
											handleReject(bookmark.id, index)
										"
										type="danger"
										icon
										:title="$t('Reject creative')"
										:outline="
											!isRejected(bookmark.id, index)
										"
										class="btn-sm"
										round
									>
										<i class="icon icon-thumb-down"></i>
									</p-button>

									<!--
              <p-button v-if="! bookmark.archived"
                        @click="handleEdit(bookmark.id)"
                        type="warning"
                        icon
                        :title="$t('Edit product')"
                        outline
                        class="btn-sm"
                        round>
                <i class="icon icon-pen-2"></i>
              </p-button>

              <p-button v-if="bookmark.archived"
                        @click="handleRestore(bookmark.id, index)"
                        type="warning"
                        :title="$t('Restore product')"
                        icon
                        outline
                        class="btn-sm"
                        round>
                <i class="icon icon-data-upload"></i>
              </p-button>
              <p-button v-else
                        @click="handleDelete(bookmark.id, index)"
                        type="danger"
                        :title="$t('Archive product')"
                        icon
                        outline
                        class="btn-sm"
                        round>
                <i class="icon icon-data-download"></i>
              </p-button>
              -->

									<el-dropdown @command="onCommand">
										<p-button
											type="default"
											:title="$t('More options')"
											icon
											:outline="true"
											class="btn-sm btn-light-gray"
											round
										>
											<i class="fa fa-ellipsis-h"></i>
										</p-button>

										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item
												:command="{
													event: 'NEW_CAMPAIGN',
													id: bookmark.id,
													index,
												}"
												v-if="
													$can(
														'CreateFromProduct',
														'Campaigns'
													) &&
														!bookmark.archived &&
														bookmark.status ==
															'APPROVED'
												"
											>
												{{ $t("Create campaign") }}
											</el-dropdown-item>

											<el-dropdown-item
												:command="{
													event: 'EDIT_BOOKMARK',
													id: bookmark.id,
													index,
												}"
												v-if="!bookmark.archived"
											>
												{{ $t("Edit template") }}
											</el-dropdown-item>

											<el-dropdown-item
												:command="{
													event: 'ARCHIVE_BOOKMARK',
													id: bookmark.id,
													index,
												}"
												v-if="!bookmark.archived"
											>
												{{ $t("Archive template") }}
											</el-dropdown-item>

											<el-dropdown-item
												:command="{
													event: 'RESTORE_BOOKMARK',
													id: bookmark.id,
													index,
												}"
												v-if="bookmark.archived"
											>
												{{ $t("Restore template") }}
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</template>
								<template v-else>
									<p-button
										v-if="
											$can(
												'CreateFromProduct',
												'Campaigns'
											) &&
												!bookmark.archived &&
												bookmark.status == 'APPROVED'
										"
										@click="
											handleCreateNewCamapaign(
												bookmark.id,
												index
											)
										"
										type="success"
										:title="$t('Create campaign')"
										icon
										:outline="true"
										class="btn-sm"
										round
									>
										<i class="fa fa-check"></i>
									</p-button>
								</template>
							</div>
						</template>
					</card-bookmark>
				</div>
			</masonry>
		</div>

		<Slide @closeMenu="filterOpen = false" :isOpen="filterOpen">
			<Filters
				:default-values="defaultFilters"
				@filterUpdated="filterUpdated($event, refreshScroll)"
			/>
		</Slide>

		<fabAddBookmark
			v-if="!$route.query.d"
			:class="{ glow: allBookmarks.length === 0 }"
		/>

		<fabFilterBookmark
			v-if="!$route.query.d"
			:position="1"
			@click="filterOpen = true"
			v-click-outside="clickOutside"
		/>

		<infinite-loading
			v-if="!$route.query.d"
			:identifier="infiniteId"
			@distance="1"
			@infinite="infiniteHandler"
		>
			<div slot="no-more">{{ $t("No more templates!") }}</div>
			<div slot="no-results">
				<div class="row">
					<div class="col-12">
						<div class="alert alert-warning text-center">
							{{
								$t(
									"You don't have any templates yet. Please, create a template by clicking on the + button below!"
								)
							}}
						</div>
					</div>
				</div>
			</div>
		</infinite-loading>
	</div>
</template>

<script>
import { EventBus } from "src/eventBus.js";
import VueMasonry from "vue-masonry-css";
import Vue from "vue";
import FabAddBookmark from "src/app-components/FabAddBookmark";
import FabFilterBookmark from "src/app-components/FabFilter";
import Filters2 from "src/views/bookmarks/filters2";
import Slide from "src/custom-components/UIComponents/FilterSideBar";
import { Button } from "src/components/UIComponents";
import CardBookmark from "src/app-components/CardBookmark";
import RejectCreativityConfiguration from "src/app-components/modal-components/RejectCreativityConfiguration";
import api from "src/api";
import swal from "sweetalert2";
import { mapMutations } from "vuex";
import SelectedCreativitiesMixin from "src/app-components/SelectedCreativitiesMixin";
import Filters from "src/views/bookmarks/filters";
import InfiniteLoading from "vue-infinite-loading";
import VueScrollTo from "vue-scrollto";
import { flow, pick } from "lodash";
import { QueryBuilder } from "x-query-builder";
import { cloneDeep } from "lodash";
import FilterMixin from "src/app-components/FilterMixin";
import TestProductModal from "src/app-components/modal-components/TestProductModal";
import BookmarkName from "src/app-components/form-elements/BookmarkName";

import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import Text from "../../app-components/form-elements/Inputs/Text.vue";
Vue.use(VueMasonry);

export default {
	components: {
		[Dropdown.name]: Dropdown,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem,

		fabAddBookmark: FabAddBookmark,
		fabFilterBookmark: FabFilterBookmark,
		Button,
		CardBookmark,
		RejectCreativityConfiguration,
		Slide,
		Filters,
		InfiniteLoading,
		TestProductModal,
		Text,
		Filters2,

		BookmarkName,
	},

	mixins: [SelectedCreativitiesMixin("bookmarks"), FilterMixin()],

	data() {
		return {
			page: 1,
			limit: 10,
			bookmarks: [],
			showRejectModal: false,
			creativesToBeRejected: null,
			infiniteId: 1,
			showTestProductModal: false,
			productToBeTested: null,
			defaultFilters: this.generateDefaultFilters(),
			channelFilters: this.getChannelsAvailable(),
		};
	},

	computed: {
		allBookmarks() {
			return this.bookmarks;
		},
	},

	async beforeRouteUpdate(to, from, next) {
		// called when the route that renders this component has changed,
		// but this component is reused in the new route.
		// For example, for a route with dynamic params `/foo/:id`, when we
		// navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
		// will be reused, and this hook will be called when that happens.
		// has access to `this` component instance.
		//alert("Updating");
		this.defaultFilters = this.generateDefaultFilters(true);
		await this.filterUpdated(
			{
				advertiser: null,
				category: null,
				subcategory: null,
				status: null,
				archived: false,
				name: null,
				channel_id: null,
			},
			this.refreshScroll
		);
		next();
	},

	async mounted() {
		if (this.$route.query.d) {
			this.bookmarksHandler();
		}
	},

	async created() {
		this.setTopNavbarTitle("Templates");
		this.setDefaultContain({
			AccountScenarioType: {},
			"AccountScenarioType.ScenarioType": {},
			"AccountScenarioType.ScenarioType.SatCallToAction": {},
			BookmarkVariables: {},
			BookmarkCallToActionVariable: {},
			"BookmarkCallToActionVariable.ScenarioVariable": {},
			Advertiser: {},
			PerfText: {},
			PlatformCommercialModel: {},
			PlatformCategory: {},
			"PlatformSubcategory.Svg": {},
			BookmarkMenuEntries: {},
		});
		this.filters = this.generateDefaultFilters();
	},

	methods: {
		getChannelsAvailable(noAdditionalFilters = false) {
			return {
				"Bookmark.archived": false,
				...(this.$route.query.createFromProduct &&
					!noAdditionalFilters && {
						"PerfText.status_id": "APPROVED",
					}),
			};
		},

		closeModal() {
			if (this.$route.query.d) {
				this.$router.push("../");
			}
		},

		clickOutside(e) {
			if (
				this.filterOpen &&
				e.path.filter((element) => element._prevClass == "bm-menu")
					.length == 0
			) {
				this.filterOpen = false;
			}
		},

		generateDefaultFilters(noAdditionalFilters = false) {
			return {
				"Bookmark.archived": false,
				...(this.$route.query.createFromProduct &&
					!noAdditionalFilters && {
						"PerfText.status_id": "APPROVED",
					}),
			};
		},

		onCommand($event) {
			if ($event.event === "NEW_CAMPAIGN") {
				return this.handleCreateNewCamapaign($event.id);
			}

			if ($event.event === "EDIT_BOOKMARK") {
				return this.handleEdit($event.id);
			}

			if ($event.event === "ARCHIVE_BOOKMARK") {
				return this.handleDelete($event.id, $event.index);
			}

			if ($event.event === "RESTORE_BOOKMARK") {
				return this.handleRestore($event.id, $event.index);
			}
		},

		infiniteHandler($state) {
			this.getBookmarks()
				.then((response) => {
					if (response.data.length > 0) {
						this.page += 1;
						this.bookmarks.push(...response.data);
						$state.loaded();
					} else {
						$state.complete();
					}
				})
				.catch((e) => {});
		},

		async bookmarksHandler() {
			const response = await this.getBookmarks();
			if (response.data.length > 0) {
				this.page += 1;
				this.bookmarks.push(...response.data);
				return true;
			} else {
				return false;
			}
		},

		getCreativity(idBookmark) {
			return this.getSelectedCreativity(idBookmark);
		},

		isApproved(idBookmark) {
			const creativity = this.getSelectedCreativity(idBookmark);
			return creativity.status == "APPROVED";
		},

		isRejected(idBookmark) {
			const creativity = this.getSelectedCreativity(idBookmark);
			return creativity.status == "REJECTED";
		},

		async updateStatus(idBookmark, status, index, reason) {
			const idCreativity = this.getSelectedCreativityId(idBookmark);
			const creativity = this.getSelectedCreativity(idBookmark);
			const res = await api.updateCreativity(idBookmark, idCreativity, {
				status: status,
				reason,
			});

			creativity.status = status;
			this.jumpToPendingCreativity(index);
		},

		gotoNew() {
			this.$router.push({
				name: "AddBookmark",
				params: {},
			});
		},

		...mapMutations(["setTopNavbarTitle"]),

		handleCreateNewCamapaign(bookmarkId, index) {
			if (this.$route.query.d) {
				const idCreativity = this.getSelectedCreativityId(bookmarkId);
				const creativity = this.getSelectedCreativity(bookmarkId);
				const bookmark = this.allBookmarks[index];

				const dd = this.$route.query.d;
				EventBus.$emit("d-selected-" + dd, {
					bookmark,
					creativity,
				});

				this.$router.push("../");
				return;
			}

			if (this.$route.query.event) {
				this.$router.push({
					name: "ApiEventCreateFromBookmark",
					params: {
						bookmarkId: bookmarkId,
					},
				});
				return;
			}
			this.$router.push({
				// name: "CampaignCreateFromBookmark",
				name: "CampaignCreateFromTemplate",
				params: {
					bookmarkId: bookmarkId,
				},
			});
		},

		handleTest(bookmarkId) {
			const creativeId = this.getSelectedCreativity(bookmarkId).hash;
			this.productToBeTested = { bookmarkId, creativeId };
			this.showTestProductModal = true;
		},

		handleApprove(bookmarkId, index) {
			this.updateStatus(bookmarkId, "APPROVED", index, null);
		},

		handleReject(bookmarkId, index) {
			this.creativesToBeRejected = { bookmarkId, index };
			this.showRejectModal = true;
		},

		handleEdit(bookmarkId) {
			this.$router.push({
				name: "EditBookmark",
				params: { id: bookmarkId },
			});
		},

		handleRestore(bookmarkId, index) {
			swal({
				title: "Restore?",
				showCancelButton: true,
				confirmButtonClass: "btn btn-success btn-fill",
				cancelButtonClass: "btn btn-danger btn-fill",
				confirmButtonText: "Yes, restore it!",
				buttonsStyling: false,
				allowOutsideClick: false,
			}).then((result) => {
				if (result.value) {
					api.restoreBookmark(bookmarkId).then((response) => {
						if (response.status) {
							swal({
								title: "Restored!",
								text: "Your bookmark has been restored.",
								type: "success",
								confirmButtonClass: "btn btn-success btn-fill",
								buttonsStyling: false,
								allowOutsideClick: false,
							}).then(() => {
								this.$delete(this.bookmarks, index);
								/** Don't remove timeout. */
								setTimeout(
									function() {
										this.$refs["cardBookmark"].map(
											(cardBookmark) =>
												cardBookmark.$refs[
													"siema"
												].siema.resizeHandler()
										);
									}.bind(this),
									1
								);
							});
						}
					});
				}
			});
		},

		handleDelete(bookmarkId, index) {
			swal({
				title: "Are you sure?",
				//text: `You won't be able to revert this!`,
				showCancelButton: true,
				confirmButtonClass: "btn btn-success btn-fill",
				cancelButtonClass: "btn btn-danger btn-fill",
				confirmButtonText: "Yes, archive it!",
				buttonsStyling: false,
				allowOutsideClick: false,
			}).then((result) => {
				if (result.value) {
					api.archiveBookmark(bookmarkId).then((response) => {
						if (response.status) {
							swal({
								title: "Archived!",
								text: "Your bookmark has been archived.",
								type: "success",
								confirmButtonClass: "btn btn-success btn-fill",
								buttonsStyling: false,
								allowOutsideClick: false,
							}).then(() => {
								this.$delete(this.bookmarks, index);
								/** Don't remove timeout. */
								setTimeout(
									function() {
										this.$refs["cardBookmark"].map(
											(cardBookmark) =>
												cardBookmark.$refs[
													"siema"
												].siema.resizeHandler()
										);
									}.bind(this),
									1
								);
							});
						}
					});
				}
			});
		},

		jumpToPendingCreativity(index) {
			this.bookmarks[index].creatives.some((value, indexCreativity) => {
				const hasPending = value.status === "PENDING";

				if (hasPending)
					this.$refs["cardBookmark"][index].$refs["siema"].goTo(
						indexCreativity
					);

				return hasPending;
			});
		},

		rejectCreativityModalCancel() {
			this.showRejectModal = false;
		},

		async rejectCreativityModalAccept(reason) {
			await this.updateStatus(
				this.creativesToBeRejected.bookmarkId,
				"REJECTED",
				this.creativesToBeRejected.index,
				reason
			);
			this.showRejectModal = false;
		},

		testProductModalCancel() {
			this.showTestProductModal = false;
		},

		async testProductModalAccept(msisdnList) {
			const request = {
				bookmark_id: this.productToBeTested.bookmarkId,
				perf_text: [
					{
						bookmark_perf_text_hash: this.productToBeTested
							.creativeId,
					},
				],
				msisdn: msisdnList,
			};
			this.showTestProductModal = false;
			await api.testCampaign(request);
			swal({
				type: "success",
				html: this.$t("Test successfuly sent"),
				confirmButtonClass: "btn btn-success btn-fill",
				buttonsStyling: false,
			});
		},

		getQuery() {
			const activeFilters = this.getActiveFilters();
			const activePerfTextStatus =
				typeof activeFilters["PerfText.status_id"] !== "undefined";
			const query = flow([
				() => new QueryBuilder(),
				(builder) => {
					// Add Where conditions
					const where = pick(activeFilters, [
						"Bookmark.advertiser_id",
						"Bookmark.category_id",
						"Bookmark.subcategory_id",
						"Bookmark.archived",
					]);

					// Add product name like
					if (typeof activeFilters["Bookmark.name"] !== "undefined") {
						where["Bookmark.name LIKE"] = `%${
							activeFilters["Bookmark.name"]
						}%`;
					}

					return builder.addWhere(where);
				},
				(builder) => {
					// Add Contain
					const perfText = activePerfTextStatus
						? {
								PerfText: {
									where: {
										"PerfText.status":
											activeFilters["PerfText.status_id"],
									},
								},
						}
						: {};
					const defaultContain = this.getDefaultContain();
					const contain = { ...defaultContain, ...perfText };

					return builder.addContain(contain);
				},
				(builder) => {
					// Add Matching
					if (activePerfTextStatus) {
						return builder.addMatching({
							PerfText: {
								where: {
									"PerfText.status":
										activeFilters["PerfText.status_id"],
								},
							},
						});
					}

					return builder;
				},
				(builder) => {
					return builder.addPage(this.page);
				},
				(builder) => {
					return builder.addLimit(this.limit);
				},
				(builder) => {
					return builder.addGroup(["Bookmark.id"]);
				},
				(builder) => {
					return builder.addOrder([
						"FIELD(Bookmark.status, 'PENDING', 'APPROVED', 'REJECTED')",
						"Bookmark.created DESC",
					]);
				},
			]);

			return query().build;
		},

		getBookmarks() {
			const query = this.getQuery();
			const activeFilters = this.getActiveFilters();

			return api.getTemplates({
				ql: query,
				channel:
					typeof activeFilters !== "undefined" &&
					typeof activeFilters["channel_id"] !== "undefined"
						? activeFilters["channel_id"]
						: 0,
			});
			/* return api.getBookmarks({
				ql: query,
				channel:
					typeof activeFilters !== "undefined" &&
					typeof activeFilters["channel_id"] !== "undefined"
						? activeFilters["channel_id"]
						: 0,
			}); */
		},

		async refreshScroll() {
			// Scrolleamos hasta arriba de todo.
			// Workaround para que el Infinite Loading funcione correctamente.
			await new Promise(function(resolve) {
				const duration = 500;
				VueScrollTo.scrollTo("body", duration, {
					onDone: function(element) {
						// Este callback no se puede utilizar para resolver la promesa,
						// porque si no hubo que hacer scroll (porque ya estamos arriba de todo) no se llama.
					},
				});
				setTimeout(resolve, duration + 100);
			});

			this.page = 1; // Reset page
			this.bookmarks = [];
			this.infiniteId += 1;
		},
	},
};
</script>
<style>
.fab-main-container {
	z-index: 1000 !important;
}
</style>
<style scoped>
.centered-label {
	font-size: 12px !important;
	margin-bottom: 0;
	margin-top: 8px;
}

@-webkit-keyframes glow-green {
	0% {
		box-shadow: 0 0 16px #169467, 0 0 0 #169467;
	}
	20% {
		box-shadow: 0 0 16px #169467, 0 0 20px #169467;
	}
	100% {
		box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 150px #169467;
	}
}

:deep(.glow .fab i) {
	background-color: transparent;
	width: 48px;
	height: 48px;
	border-radius: 50px;
	-webkit-animation: glow-green 1.5s linear 1s infinite;
}
</style>
