<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "Creatives",

    extends: Report,

   created() {
        this.tableCols = [
            { label: "Template", hasLabel: true, isHtml: true, minWidth: 250 },
        //    { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
        //    { prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
        //    { prop: "Actives_Rate", label: "%Active", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
        //    { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
        //    { prop: "Revenue", label: "Revenue", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
        ];
       this.customTableKeyCols = [
           { prop: "Product", label: "Template" },
           { prop: "Creatives", label: "Creatives", exportFormatter: this.formatterCreatives }
       ];

        this.emitAllowedScenariosFilter((scenario) => {
            // Not Lauch browser and not ping
            return scenario.scenario.sat_call_to_action_id != 1 && scenario.scenario.sat_call_to_action_id != null;
        });
    },

    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Product";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "product_name ASC",
                "creatives_html ASC",
            ]
        },

        /**
         * @Override
         */
        tableRowClassName({row, rowIndex}) {
            if(row.Product === null && row.Creatives === null) {
                return 'success-row';
            }
            
            return '';
        },

        async getData(query) {
            query.ql.where["campaign_type NOT IN"] = ["PING", "TEST", "EVENT"];
            query.ql.where["product_id >"] = 0;
            query.ql.where["creative_id >"] = 0;

            const response = await api.getCampaignPivotCreative(query);
            this.tableData = response.data;
        },

        formatterCreatives (html) {
            const el = document.createElement( 'html' );
            el.innerHTML = html;
            
            const body = [ ...el.getElementsByTagName("body") ][0]
            const topDiv = [ ...body.childNodes ][0]
            let creatives = []
            if (topDiv) {
                creatives = [ ...topDiv.childNodes ]
            }

            return creatives.map(creative => {
                return creative.textContent
            }).join("\n")
        }
    }
}
</script>