<template>
  <floating-action-button color="#6699FF" 
    :icon="iconUse" :position="position" @click="$emit('click')" />
</template>

<script>
import FloatingActionButton from "src/app-components/FloatingActionButton";

export default {
  components: {
    FloatingActionButton
  },
  props: {
    icon: { type: String, default: 'search', required: false },
    position: { type: Number, default: 0 }
  },
  data() {
    return {
    };
  },

  computed: {
    iconUse() {
      return this.icon;
    }
  },

  created() {},

  methods: {
  }
};
</script>