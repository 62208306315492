<template>
  <div>
    <title-box
      componentName="Condition"
      :id="numberNodeID"
      :name="dataNode.name"
      @setNewName="setNewName($event)"
    />
    <div class="options">
      <ul>
        <li
          v-for="option in dataNode.options"
          :key="option.text"
          class="d-flex align-items-center justify-content-between pr-2"
          :class="option.type"
        >
          {{
            option.condition
              ? `@${option.variable} ${option.typeCondition} ${option.condition}`
              : "Fallback"
          }}

          <el-tooltip
            class="item"
            effect="dark"
            :content="option.message"
            placement="top"
            v-if="option.type === 'fallback' && !viewFallbackNode"
          >
            <i class="el-icon-refresh"></i>
          </el-tooltip>
        </li>
      </ul>
    </div>

    <template v-for="(opt, key) in optionsPopover">
      <create-element-popover
        :key="key"
        :output="key + 1"
        :optionsLength="dataNode.options.length"
        :numberNodeID="numberNodeID"
        v-if="
          dataNode.connections.findIndex((c) => c.idOption === opt.id) === -1
        "
      />
    </template>
  </div>
</template>

<script>
  import { EventBus } from "src/eventBus.js";
  import { Input, Button } from "element-ui";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "Condition",
    mixins: [ChatbotComponentsMixin],
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
    },

    data() {
      return {
        dataNode: {
          options: [
            {
              id: null,
              text: "Fallback",
              type: "fallback",
              message: "",
            },
          ],
        },
      };
    },

    computed: {
      optionsPopover() {
        if(this.dataNode) {
          return this.dataNode.options;
        }
        return [];
      }
    }
  };
</script>

<style>
  .wrapperCanvas .drawflow .drawflow-node.Condition.selected {
    -webkit-box-shadow: 0 2px 20px 2px #f56c6c;
    box-shadow: 0 2px 20px 2px #f56c6c;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.Condition.selected {
    -webkit-box-shadow: 0 2px 20px 2px #cf6679;
    box-shadow: 0 2px 20px 2px #cf6679;
  }
</style>
