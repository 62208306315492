<template>
    <div>
        <label class="w-100 text-left">{{$t(title)}}</label>
            <el-upload
            v-loading="loading"
            class="upload-demo bg-light py-3 border"
            :class="[hasMedia ? 'loaded' : '']"
            :limit="filesLimit"
            action=""
            :auto-upload="false"
            :file-list="files"
            :on-change="handleLoad"
            :on-remove="handleRemove"
            accept="image/jpeg, image/jpg, image/gif, image/png, video/h263, video/m4v, video/mp4, video/mpeg, video/mpeg4, video/webm"
            ref="uploader"
            :show-file-list="false"
            >
                <el-button slot="trigger" size="mini" type="primary" class="bg-miro" v-if="!hasMedia">{{$t('Select image or video')}}</el-button>
                <div slot="tip" class="el-upload__tip animated fadeIn">
                    <!-- En caso de que haya Media -->
                    <div class="row animated fadeIn" v-if="hasMedia">
                        <div class="col-12 preview">
                            <MediaContentPreview :data="url" :name="fileName"></MediaContentPreview>
                        </div>
                        <div class="col-12 mt-2">
                            <el-button class="w-25" type="success" size="mini" v-if="!autoUpload" @click="uploadFile">{{$t('Confirm')}}</el-button>
                            <el-button type="danger" size="mini" v-if="isEditable" @click="handleRemove">{{$t('Delete')}}</el-button>
                        </div>
                    </div>
                    <!-- En caso de que no haya Media -->
                    <small v-else>{{`${$t('The maximum file size is')} ${this.maxImgSize}MB ${$t('for images and')} ${this.maxVideoSize}MB ${$t('for videos')}`}}</small>
                </div>
            </el-upload>

            <input type="hidden" v-model="files" v-validate="{ required: isRequired && isEditable && !hasMedia, fileSize: true}" name="fileSize" :data-vv-scope="scope">
            <small class="text-danger">{{errors.first('fileSize', scope)}}</small>
    </div>
</template>

<script>
import { Input, Upload, Button } from "element-ui";
import api from 'src/api.js';

import MediaContentPreview from 'src/app-components/rcs/preview-elements/MediaContentPreview';

const uniqid = require('uniqid');

export default {
    components: {
        [Input.name]: Input,
        [Upload.name]: Upload,
        [Button.name]: Button,
        MediaContentPreview
    },

    props: {
        title: {
            type: String,
            default: 'Media content'
        },
        filesLimit: {
            type: Number,
            default: 1
        },
        fileUrl: {
            type: String,
            default: ''
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isRequired: {
            type: Boolean,
            default: true
        },
        autoUpload: {
            type: Boolean,
            default: true
        },
        // Esta expresado en MB
        maxImgSize: {
            type: Number,
            default: 2
        },
        maxVideoSize: {
            type: Number,
            default: 10
        }
    },

    inject: {
        parentValidator: "parentValidator",
    },

    data() {
        return {
            scope: uniqid('uploader-'),
            loading : false,
            files: [],
        }
    },

    computed: {
        hasMedia(){
            return this.files.length > 0 || this.url
        },
        fileName(){
            return this.files.length > 0 ? this.files[0].name : ''
        },
        url() {
            return this.fileUrl
        }
    },

    created() {
        this.$validator = this.parentValidator;
        // At least one creativity
        this.$validator.extend('fileSize', {
            validate: (file) => {
                // console.log("VALIDANDO ARCHIVO", file);
                if (file.length > 0) {
                    const validIMG = file[0].raw.type.includes('image') && (file[0].size / 1024 / 1024) < this.maxImgSize;
                    const validVIDEO = file[0].raw.type.includes('video') && (file[0].size / 1024 / 1024) < this.maxVideoSize;
                    return validIMG || validVIDEO 
                }
                return true
            },
            getMessage: () => this.$t('The file exceeds the permitted size.')
        });
    },

    methods: {
        handleRemove(){
            this.files.splice(0, this.files.length);
            this.$emit('pictureRemove');
            console.debug("La imagen ha sido eliminada");
        },

        async handleLoad(file, fileList){
            this.loading = true;
            this.files = fileList;

            this.$emit('pictureLoaded', URL.createObjectURL(file.raw));

            if (await this.$validator.validate(`${this.scope}.fileSize`) == false) {
                // this.$refs.uploader.clearFiles()
                this.loading = false;
                return;
            };

            if (this.autoUpload) {
                this.uploadFile(file)
            }
            this.loading = false;
        },


        async uploadFile (file = null) {
            
            this.loading = true;
            const formData = new FormData();

            if ('raw' in file) {
                formData.append("attachment", file.raw)
            } else {
                formData.append("attachment", this.files[0].raw);
            }
            
            try {
                const response = await api.uploadFile(formData, "IMAGE");
                if (response) {
                    console.log("IMAGE UPLOADED", response);
                    this.$emit('pictureLoaded', response.data.publicPath);
                    if (!this.autoUpload) {
                        this.$emit('submit');
                    }
                }
            } catch (error) {
                console.log(error);
                // this.handleRemove();
                this.loading = false;
                return null
            }
            this.loading = false;
        },

    },
}
</script>