<template>
  <div>
    <div class="row">
      <div class="col-3">
          <Sender @sent="handleSent" />
      </div>
      <div class="col-9">
          <Receiver :request="request" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import {Card} from 'src/components/UIComponents'
  import Sender from 'src/views/smsc-tester/sender/index'
  import Receiver from 'src/views/smsc-tester/receiver/index'

  export default {
    components: {
      Sender,
      Receiver
    },
    mounted () {
      this.setTopNavbarTitle("SMSC Tester");
    },
    methods: {
      ...mapActions(["setTopNavbarTitle"]),
      handleSent (request) {
          this.request = request;
      }
    },
    data () {
      return {
          request: null
      }
    }
  };
</script>
<style>
</style>