<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!--
        <div class="card-header">
          <h4 class="card-title">{{ $t(title) }}</h4>
        </div>
        -->
        <div class="card-body">
          <tabbed-form
            :save-button-disabled="tabbedFormSaveButtonDisabled"
            :cancel-action="onCancel"
            :initial-value="initialTab"
            @submit="onSubmit()"
            @error-tabs="onErrorTabs($event)"
            :tabs="getTabs()"
          >
            <tab-pane
              :title="$t('Configuration')"
              id="configuration"
              :hasError="errorTabs.configuration"
            >
              <div class="row">
                <div class="col-md-12">
                  <tab-campaign-configuration
                    :entity="initialCampaignData"
                    @modelUpdated="onCampaignModelUpdated"
                    @removeVariable="onRemoveVariable"
                    :disabled="disabled"
                    :showGapTime="campaignDefaults.showGapTime"
                    :showAmountUsers="campaignDefaults.showAmountUsers"
                  />
                </div>
              </div>
            </tab-pane>

            <tab-pane
              :title="$t('Template')"
              id="bookmark"
              :hasError="errorTabs.bookmark"
            >
              <div class="row">
                <div class="col-md-12">
                  <tab-bookmark
                    :entity="initialBookmarkData"
                    :hide-bookmark-info="hideBookmarkInfo"
                    :hideCommercialModel="bookmarkProps.hideCommercialModel"
                    :hidePrice="bookmarkProps.hidePrice"
                    :commercialModel="commercialModel"
                    :price="price"
                    :isEditable="bookmarkProps.isEditable"
                    :campaign-started="campaignStarted"
                    :disableCreativeSelection="activeSecondActions.length > 0"
                    :existingFallback="existingFallback"
                    @updateCategory="onUpdateCategory"
                    @updateSubCategory="onUpdateSubCategory"
                    @modelUpdated="onBookmarkModelUpdated"
                    @creativesSelected="onCreativesSelected"
                    :validateMinSelectedCreativities="true"
                    :enableFallback="enableFallback"
                    @updateCreativitiesTotal="onUpdateCreativitiesTotal"
                    @noCreativitiesMode="onNoCreativitiesMode"
                  />
                </div>
              </div>
            </tab-pane>

            <tab-pane
              :title="$t('Audience')"
              id="target"
              :hasError="errorTabs.target"
            >
              <div class="row">
                <div class="col-md-12">
                  <tab-target-uploader
                    :entity="initialTargetData"
                    :disable-remove="
                      initialCampaignData
                        ? ![22, 15, 4].includes(initialCampaignData.status_id)
                        : false
                    "
                    @modelUpdated="onTargetUpdated"
                    @updateTotalValidEntries="onUpdateTotalValidEntries"
                    :disabled="disabled"
                    :bookmark="form.bookmark"
                    :currentCreativities="form.perf_text"
                  />
                </div>
              </div>
            </tab-pane>

            <tab-pane
              v-if="$can('SecondAction', 'Campaigns') && !selectedChannelIsRcs"
              :title="$t('Second action')"
              id="sa_configuration"
              :hasError="errorTabs.sa_configuration"
            >
              <div v-show="selectedChannelIsSat">
                <div v-if="canUseSecondAction">
                  <second-action-configuration
                    :entity="initialSActiontData"
                    :bookmark="form.bookmark"
                    :selected-creatives="form.perf_text"
                    :show="isSaEnabled"
                    @modelUpdated="onSecondactionsUpdated"
                    :disabled="disabled"
                  >
                  </second-action-configuration>
                </div>
                <div v-else>
                  <p class="text-muted">
                    {{
                      $t(
                        "Oh no ðŸ˜¢, first, you have to create the campaign by selecting the Channel and the Scenario or Campaign Flow"
                      )
                    }}
                  </p>
                </div>
              </div>
              <div v-show="!selectedChannelIsSat">
                <p class="text-muted">
                  {{
                    $t(
                      "Selected scenario does not allow Second Action configuration"
                    )
                  }}
                </p>
              </div>
            </tab-pane>

            <tab-pane
              :title="$t('Retry')"
              v-if="!selectedChannelIsRcs"
              id="retry"
              :hasError="errorTabs.retry"
            >
              <div v-show="selectedChannelIsSat">
                <div class="row">
                  <div class="col-md-12">
                    <TabRetry
                      :allowConfigureSample="retryDefaults.allowConfigureSample"
                      :entity="initialRetryData"
                      @modelUpdated="onRetryModelUpdated"
                      :parent-campaign-status="
                        initialCampaignData ? initialCampaignData.status_id : 1
                      "
                      :parent-campaign="form"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div v-show="!selectedChannelIsSat">
                <p class="text-muted">
                  {{
                    $t("Selected scenario does not allow Retry configuration")
                  }}
                </p>
              </div>
            </tab-pane>

            <tab-pane
              v-if="$can('HiddenVariables', 'Campaigns')"
              :title="$t('Hidden configuration')"
              id="hiddenConfiguration"
              :hasError="errorTabs.hiddenConfiguration"
            >
              <div class="row">
                <div class="col-md-12">
                  <TabHiddenConfiguration
                    :scenario-id="accountScenarioTypeId"
                    @modelUpdated="onHiddenConfigurationUpdated"
                    :entity="form"
                  />
                </div>
              </div>
            </tab-pane>
          </tabbed-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, merge } from "lodash";
import { EventBus } from "src/eventBus";
import { defaultMixin } from "src/app-components/FormMixin";
import TabbedFormMixin from "src/app-components/TabbedFormMixin";
import FormatTemplateMixin from "src/app-components/FormatTemplateMixin";
import SecondActionMixin from "src/app-components/SecondActionMixin";
import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
import TabbedForm from "src/app-components/TabbedForm";
import TabBookmark from "src/app-components/form-tabs/TabBookmark.vue";
import TabCampaignConfiguration from "src/app-components/form-tabs/TabCampaignConfiguration.vue";
import TabRetry from "src/app-components/form-tabs/TabRetry.vue";
import TabHiddenConfiguration from "src/app-components/form-tabs/TabHiddenConfiguration.vue";
import TabTargetUploader from "src/app-components/form-tabs/TabTargetUploader.vue";
import SecondActionEnabled from "src/app-components/form-elements/SecondActionEnabled";
import SecondActionConfiguration from "src/app-components/SecondActionConfiguration";

// Parses a time string in the format "HH:MM", and returns the total minutes
const time2minutes = function (minutes) {
  if (typeof minutes === "number") {
    return minutes;
  }

  const parts = minutes.split(":");

  if (parts.length == 2) {
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  }

  throw new Error(`Unexpected time string format: ${minutes}`);
};

export default {
  mixins: [
    defaultMixin,
    SecondActionMixin("form"),
    TabbedFormMixin,
    FormatTemplateMixin,
  ],

  provide() {
    return {
      parentValidator: this.$validator,
      getError: this.getError,
      activateCreatives: this.activateCreatives,
    };
  },

  components: {
    SecondActionEnabled,
    SecondActionConfiguration,
    [TabPane.name]: TabPane,
    TabbedForm,
    TabBookmark,
    TabCampaignConfiguration,
    TabTargetUploader,
    TabRetry,
    TabHiddenConfiguration,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: "Create a new campaign from scratch",
    },

    enableFallback: {
      type: Boolean,
      default: false,
    },

    initialBookmarkData: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    bookmarkProps: {
      type: Object,
      required: false,
      default: () => ({
        isEditable: true,
        hideCommercialModel: false,
        hidePrice: false,
      }),
    },

    initialCampaignData: {
      type: Object,
      required: false,
    },

    initialTargetData: {
      type: Array,
      required: false,
    },

    initialSActiontData: {
      type: Array,
      required: false,
      default: () => [
        {
          trigger: "ACCEPTANCE",
          action: "CROSS_SELLING",
          delay: null,
          bookmark_id: null,
          active: false,
        },
        {
          trigger: "REJECTION",
          action: "REPEAT_SCREEN",
          delay: null,
          bookmark_id: null,
          active: false,
        },
      ],
    },

    initialRetryData: {
      type: Object,
      required: false,
    },

    initialTab: String,

    disabled: {
      type: Boolean,
      default: false,
    },

    hideBookmarkInfo: {
      type: Boolean,
      default: false,
    },

    updateCommercialModel: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    "form.bookmark.account_scenario_type_id": function (newVal, oldVal) {
      this.form.account_scenario_type_id = newVal;
    },

    isSaEnabled(newValue) {
      if (newValue === false) {
        this.resetSecondActions();
      }
    },
  },

  computed: {
    selectedChannelIsSat() {
      return this.form.bookmark.channel_id === 1;
    },
    selectedChannelIsRcs() {
      return this.form.bookmark.channel_id === 6;
    },

    existingFallback() {
      if (this.initialCampaignData.transactions) {
        return this.initialCampaignData.transactions.find(
          (t) => t.event_id === this.fallback_event_id
        );
      }
      return null;
    },

    commercialModel() {
      return this.form.commercial_model_id;
    },

    price() {
      return this.form.price;
    },

    hasId() {
      return typeof this.form.id !== "undefined";
    },

    accountScenarioTypeId() {
      return this.form.account_scenario_type_id;
    },

    isSaEnabled() {
      const index = this.form.variables.findIndex(
        (v) => v.variable.name == "second-action-enabled"
      );
      if (index !== -1) {
        return this.form.variables[index].value;
      }
      /* if ("second-action-enabled" in this.form.variables) {
        return this.form.variables["second-action-enabled"].value;
      } */

      return false;
    },

    campaignProps() {
      const props = {
        ...this.campaignDefaults,
      };

      return props;
    },

    campaignStarted() {
      if (
        !this.initialCampaignData ||
        !("status_id" in this.initialCampaignData)
      )
        return false;
      return ![22, 15, 4].includes(this.initialCampaignData.status_id); //TODO: redefinir validacion
    },

    activeSecondActions() {
      return this.form.second_actions.filter((sa) => sa.active);
    },
  },
  mounted() {
    EventBus.$on("fallback-creativity-selected", (fallback) => {
      this.fallback = fallback;
    });
  },
  beforeDestroy() {
    EventBus.$off("fallback-creativity-selected");
  },

  data() {
    return {
      retryDefaults: {
        allowConfigureSample: false,
      },
      fallback: null,
      fallback_event_id: 25,
      campaignDefaults: {
        showGapTime: false,
        showAmountUsers: false,
        sizeAmountUsers: 0,
      },
      noCreativitiesMode: false,

      form: {
        files: [],
        account_scenario_type_id: null,
        category_id: null,
        subcategory_id: null,
        perf_text: [],
        bookmark: {
          account_scenario_type_id: null,
          channel_id: null,
          commercial_model_id: null,
          price: null,
          creativives: [],
          placeholders: [],
        },
        // variables: {},
        variables: [],
        second_actions: [],
        manual_start: false,
      },
    };
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    activateCreatives() {
      if (
        typeof this.initialCampaignData !== "undefined" &&
        typeof this.initialCampaignData.perf_text !== "undefined"
      ) {
        return this.initialCampaignData.perf_text;
      }

      return [];
    },

    getTabs() {
      return {
        bookmark: false,
        configuration: false,
        target: false,
        sa_configuration: false,
        retry: false,
        hiddenConfiguration: false,
      };
    },

    onRemoveVariable(varName) {
      this.$delete(this.form.variables, varName);
    },

    onUpdateCategory($event) {
      this.form.category_id = $event;
    },

    onUpdateSubCategory($event) {
      this.form.subcategory_id = $event;
    },

    onCreativesSelected($event) {
      // Set visible GAP and AMOUNT USERS
      if ($event.length > 1) {
        this.$set(this.campaignDefaults, "showGapTime", true);
        this.$set(this.campaignDefaults, "showAmountUsers", true);
        this.$set(this.retryDefaults, "allowConfigureSample", true);
      } else {
        this.$set(this.campaignDefaults, "showGapTime", false);
        this.$set(this.campaignDefaults, "showAmountUsers", false);
        this.$set(this.retryDefaults, "allowConfigureSample", false);
      }

      const perfText = $event.map((e) => ({ bookmark_perf_text_hash: e }));
      this.$set(this.form, "perf_text", perfText);
      this.$emit("creativesSelected", cloneDeep(perfText));
    },

    onBookmarkModelUpdated($event) {
      this.$set(this.form, "bookmark", $event);
    },

    onCampaignModelUpdated($event) {
      const data = this.mergeForm($event);
      this.form = { ...this.form, ...data };
      this.form.variables = cloneDeep(data.variables);
    },

    onRetryModelUpdated($event) {
      this.$set(this.form, "children", cloneDeep($event));
    },

    onTargetUpdated($event) {
      this.$set(this.form, "files", $event);
    },

    onHiddenConfigurationUpdated($event) {
      this.form.variables = merge(this.form.variables, $event);
    },

    onUpdateTotalValidEntries($event) {
      this.campaignDefaults.sizeAmountUsers = $event;
    },

    onUpdateCreativitiesTotal($event) {},

    onCancel() {
      this.$router.push("/campaigns");
    },

    onNoCreativitiesMode(isNoCreativitiesMode) {
      this.noCreativitiesMode = isNoCreativitiesMode;
    },

    onSubmit() {
      const form = cloneDeep(this.form);
      form.template = this.formatTemplate(
        form.bookmark,
        !this.updateCommercialModel
      );

      if (this.noCreativitiesMode) {
        form.perf_text = this.initialBookmarkData.creatives.map((x) => {
          return {
            bookmark_perf_text_hash: x.hash,
          };
        });
      }

      const data = {
        ...form,
        ...{
          channel_id: form.template.channel_id,
        },
      };

      // Some Tunning for SecondAction
      data.second_actions = data.second_actions
        .filter((sa) => sa.active)
        .map((sa) => {
          const x = {
            active: sa.active,
            screen_event: sa.trigger,
            screen_id: sa.screen == "LAST_SCREEN" ? null : sa.screen,
            action: sa.action,
            delay: sa.delay ? time2minutes(sa.delay) : null,
            bookmark_id: sa.bookmark_id ? sa.bookmark_id : null,
            bookmark_perf_text_hash: sa.bookmark_perf_text_hash ? sa.bookmark_perf_text_hash : null,
            id: sa.id ? sa.id : null,
          };

          // If we have product
          if (sa.product && "bookmark" in sa.product) {
            x["bookmark_id"] = sa.product.bookmark.id;
            x["bookmark_perf_text_hash"] = sa.product.creativity.hash;
          }

          // If we are in campaign edit
          if (typeof data.id != "undefined" && data.id != null) {
            x["campaign_id"] = data.id;
          }

          return x;
        });

      // Add fallback
      if (this.fallback) {
        data.fallback = this.fallback;
      }

      // Add second enable var
      const dataSecondAction = data.second_actions.length > 0 ? 1 : 0;
      const index = data.variables.findIndex((v) => v.variable_id == 81);
      if (index == -1) {
        data.variables.push({
          value: dataSecondAction,
          variable_id: 81,
          variable: {
            name: "second-action-enabled",
          },
        });
      } else {
        data.variables[index].value = dataSecondAction;
      }

      if (data.bookmark) {
        delete data.bookmark;
      }

      this.$emit("submit", data);
    },
  },
};
</script>

<style></style>
