<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-auto pl-0 pr-0">
      <div style="width: 60px">
        {{ $t(day) }}
      </div>
    </div>
    <div class="col-12 col-md pl-0 pr-0">
      <el-slider class="slider-info"
                  :value="whiteinValue"
                  @input="(val) => $emit('input', whiteinToBlackout(val))"
                  :max="1440"
                  :step="15"
                  range
                  :format-tooltip="formatTooltip">
      </el-slider>
    </div>
    <div class="col-12 col-md-auto text-right pl-0 pr-0">
      <div style="width: 110px">
        {{ `${formatTooltip(whiteinValue[0])} - ${formatTooltip(whiteinValue[1])}` }}
      </div>
    </div>
  </div>
</template>

<script>
  import {Slider} from 'element-ui'

  export default {
    components: {
      [Slider.name]: Slider
    },
    props: {
        value: Object,
        day: String
    },
    methods: {
      formatTooltip (value) {
        return this.minutesToTime(value)
      },
      minutesToTime (value) {
          const hours = Math.floor(value / 60);
          const minutes = value - hours * 60;
          const pad = (e) => e < 10 ? ("0" + e) : e;
          return pad(hours) + ":" + pad(minutes);
      },
      blackoutToWhitein (blackout) {
        if (blackout.range1.start > 0) {
          return [0, blackout.range1.start]
        }
        return [blackout.range1.end, blackout.range2.start]
      },
      whiteinToBlackout (whitein) {
        return {
          range1: { start: 0, end: whitein[0] },
          range2: { start: whitein[1], end: 1440 },
        }
      }
    },
    computed: {
      whiteinValue () {
        return this.blackoutToWhitein(this.value)
      }
    },
    data () {
      return {
      }
    }
  };
</script>
<style>
</style>