<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <target-uploader ref="targetValidator"
          :with-files="withFiles"
          @fileRemoved="onFileRemoved"
          @fileProcessed="onFileProcessed">
        </target-uploader>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Button } from "src/components/UIComponents";
import Uploader from "src/app-components/Uploader";
import TargetUploader from "src/app-components/TargetUploader";

import TargetUploaderMixin from "src/app-components/TargetUploaderMixin"

export default {
  $_veeValidate: {
    validator: 'new'
  },

  provide() {
    return {
      parentValidator: this.$validator
    };
  },

  components: {
    Uploader,
    [TargetUploader.name]: TargetUploader
  },

  mixins: [
      TargetUploaderMixin("form")
  ],

  props: {
    wzModel: Object
  },

  watch: {
    wzModel() {
      // Previene constante watch despues de agregar nuevos files
      if(this.loadWithFiles) {
        this.withFiles = this.wzModel.files;
        this.withFiles.forEach((file) => {
          this.appendFile(file);
        });
        this.loadWithFiles = false;
      }
    }
  },

  data() {
    return {
      loadWithFiles: true,
      withFiles: [],
      fileProcessed: false,
      form: []
    };
  },

  methods: {
    appendFile(file) {
      this.form.push({
        id: file.id
      })
    },

    async validate() {
      const result = await this.$refs.targetValidator.$validator.validateAll();
      //console.log("VALIDATION RESULT: " + result);

      this.$emit("on-validated", result, this.form);
      return result;
    }
  }
};
</script>