<template>
    <fg-input
        :label="$t('# Effective deliveries')"
        :value="value"
        type="number"
        min="0"
        max="100"
        name="effective-deliveries"
        v-validate="'required|min_value:0|max_value:100'"
        :error="getError('effective-deliveries')"
        @input="(val) => $emit('input', val)"
    />
</template>

<script>

  export default {
    inject: ["getError", "parentValidator"],
    created() {
        this.$validator = this.parentValidator;
    },
    components: {
    },
    props: {
        value: Number
    },
    methods: {
    },
    data () {
      return {
      }
    }
  };
</script>
<style>
</style>