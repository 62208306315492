<template>
    <p-button type="warning" size="sm" icon @click="handlePause(id)">
        <i class="fa fa-power-off"></i>
    </p-button>
</template>

<script>
import api from "src/api"
import swal from "sweetalert2";

export default {
    props: {
        id: {
            type: Number,
            required: true
        }
    },

    methods: {
        handlePause(id) {
            swal({
                title: "Disable?",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, disable it!",
                buttonsStyling: false,
                allowOutsideClick: false
            }).then(async result => {
                if (result.value) {
                    const res = await api.updateApiEvent(id, {
                        status_id: 0
                    });
                    if(res.status) {
                        this.$emit('deactivated');
                    }
                }
            });
        }
    }
}
</script>
