<template>
  <el-table :data="value"
    style="width: 100%"
    row-key="account_user_file_id">
    <el-table-column :label="$t('Name')" 
      prop="name" 
      min-width="125" />
    <el-table-column :label="$t('Size')" prop="file._count" :formatter="numberFormmater" />
    <el-table-column :label="$t('Status')" prop="status" />
    <el-table-column :label="$t('Created')" prop="created" :formatter="dateFormatter" />

    <el-table-column :label="$t('Actions')" align="center">
      <template slot-scope="scope">
        <p-button v-if="!isChildren(scope.row)" 
          type="info" size="sm" icon @click="handleEdit(scope.row.id)">
          <i class="fa fa-edit"></i>
        </p-button>

        <FileDownloadButton v-if="scope.row.status == 'GATHERING_INFORMATION_READY'" size="sm" :id="scope.row.account_user_file_id" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import { Table, Popover, Tag } from 'element-ui'
  import swal from 'sweetalert2'
  import api from "src/api";
  import { Button } from 'src/components/UIComponents'
  import moment from 'moment-timezone'
  import FileDownloadButton from "src/app-components/buttons-components/FileDownload"

  export default {
    props: {
      value: Array
    },

    components: {
      [Table.name]: Table,
      [Popover.name]: Popover,
      [Tag.name]: Tag,
      [Button.name]: Button,
      FileDownloadButton
    },
    
    methods: {
      isChildren(row) {
        return (typeof row.crm_list_id !== "undefined");
      },

      numberFormmater(_, __, value) {
        if( value !== null) {
          return this.$options.filters.formatNumber(value);
        }
        
        return value;
      },

      dateFormatter (row, column, cellValue, index) {
        const userTimezone = this.$store.getters.appUserTimezone;
        return moment.utc(cellValue).tz(userTimezone).format("YYYY-MM-DD HH:mm");
      },

      handleEdit(id) {
        this.$router.push({name: 'EditSegmentationTool', params: { id: id }})
      },
    }
  }
</script>