<script>
import api from "src/api";
import Report from "./Report";
import { excelFormatPercentage, excelFormatNumber } from "./excel_formats.js";

export default {
  name: "Advertiser",

  extends: Report,

  created() {
    this.tableCols = [
      { label: "Advertiser", hasLabel: true, minWidth: 100 },
      //{ prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
      {
        prop: "Sent",
        label: "Sent",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
      },
      //{ prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
      {
        prop: "Delivered",
        label: "Delivered",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
      },
      {
        prop: "Acceptances",
        label: "Acceptances",
        formatter: this.formatterNumber,
        minWidth: 120,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
      },
      {
        prop: "Acceptance_Rate",
        label: "%AR",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
      },
      {
        prop: "Conversion_Rate",
        label: "%CR",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
      },
    ];

    this.customTableKeyCols = [{ prop: "Advertiser", label: "Advertiser" }];

    this.emitAllowedScenariosFilter((scenario) => {
      // Not Lauch browser and not ping
      return (
        scenario.scenario.sat_call_to_action_id != 1 &&
        scenario.scenario.sat_call_to_action_id != null
      );
    });
  },

  methods: {
    /**
     * @Override
     */
    filterProp() {
      return "Advertiser";
    },

    /**
     * @Override
     */
    tableDimensions() {
      return ["advertiser_name ASC"];
    },

    /**
     * @Override
     */
    tableRowClassName({ row, rowIndex }) {
      if (row.Advertiser === null) {
        return "success-row";
      }

      return "";
    },

    async getData(query) {
      query.ql.where["campaign_type NOT IN"] = ["PING", "TEST", "EVENT"];
      query.ql.where["scenario_id <>"] = 11; // NOT ping
      query.ql.where["product_id >"] = 0;
      const response = await api.getCampaignPivotAdvertiser(query);
      this.tableData = response.data;
    },
  },
};
</script>
