<template>
  <div>
    <div class="row">
      <div class="col-12">
        <label> {{ $t("% Sample") }} </label>
        <fg-input
          name="sample"
          v-validate="'required|min_value:1|max_value:100'"
          type="number"
          :error="getError('sample')"
          :disabled="disabled"
          :value="local.value"
          @input="onInput($event)"
        ></fg-input>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: {
      type: Object,
      default: function() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
    },
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    local() {
      let defaultValue = {
        variable_id: 83,
        value: "",
        variable: {
          name: "performance-sample-size",
        },
      };
      if (!this.variables) return defaultValue;

      let value = this.variables.find((variable) => {
        if (variable.variable) {
          return variable.variable.name === "performance-sample-size";
        }
      });

      return !value ? defaultValue : value;
    },
  },

  methods: {
    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };

      this.$emit("input", input);
    },
  },
};
</script>

<style></style>
