<template>
    <div>
        <modal :show.sync="show" headerClasses="justify-content-center"
            footerClasses="justify-content-center">

            <template slot="header"> 
                <h4 class="title title-up">{{ $t('Copy campaign') }}</h4>
                <p style="font-size: 12px"> {{ $t('Please, complete the following information and click ok') }} </p>
            </template>
        
            <div class="row">
                <div class="col-md-12">
                    <CampaignName v-model="form.name" />
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label>{{ $t("Copy target base?") }}</label><br />
                    <p-switch
                        v-model="form.copy_target"
                        type="primary"
                        on-text="ON"
                        off-text="OFF"
                    ></p-switch>
                </div>
            </div>

            <template slot="footer">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-dark" @click="onCancel">
                            <i class="fa fa-ban"></i> {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-success" @click="onSave">
                            <i class="fa fa-check"></i> {{ $t("OK") }}
                        </button>
                    </div>
                    <br />
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import api from "src/api"
import CampaignName from "src/app-components/form-elements/CampaignName"
import Modal from "src/custom-components/UIComponents/Modal";
import PSwitch from "src/components/UIComponents/Switch.vue";

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        name: {
            type: String,
            required: true
        },

        id: {
            type: Number,
            required: true
        }
    },

    $_veeValidate: {
        validator: 'new'
    },

    provide() {
        return {
            parentValidator: this.$validator,
            getError: this.getError
        };
    },

    components: {
        CampaignName,
        Modal,
        PSwitch
    },

    data() {
        return {
            form: {
                copy_target: false,
                name: this.name + " Copy" || null
            }
        }
    },

    methods: {
        handleCopy(id) {
            this.show = true
        },

        onCancel() {
            this.$emit("cancelled")
        },

        async onSave() {
            const copy = await api.copyCampaign(this.id, this.form)
            if (copy.status == true) {
                this.$emit("copied", copy.data)
            }
        },

        getError(fieldName) {
            return this.errors.first(fieldName);
        },
    }
}
</script>

<style scoped>

</style>
