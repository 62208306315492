var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeModal.apply(null, arguments)}}},[_c('reject-creativity-configuration',{attrs:{"show":_vm.showRejectModal},on:{"modal-cancel":_vm.rejectCreativityModalCancel,"modal-save":_vm.rejectCreativityModalAccept}}),_c('test-product-modal',{attrs:{"show":_vm.showTestProductModal},on:{"modal-cancel":_vm.testProductModalCancel,"modal-save":_vm.testProductModalAccept}}),(_vm.$route.query.createFromProduct)?_c('h5',{staticClass:"text-center mb-5"},[_vm._v(" "+_vm._s(_vm.$route.query.event ? _vm.$t( "Please, select the template you want to include in the external event" ) : _vm.$t( "Please, select the template you want to include in the campaign" ))+" ")]):_vm._e(),(_vm.allBookmarks)?_c('div',[_c('masonry',{attrs:{"cols":{
					default: 6,
					2540: 6,
					1920: 5,
					1550: 4,
					1280: 3,
					900: 2,
				},"gutter":30}},_vm._l((_vm.allBookmarks),function(bookmark,index){return _c('div',{key:index},[_c('card-bookmark',{ref:"cardBookmark",refInFor:true,attrs:{"bookmark":bookmark},on:{"creativity-changed":_vm.creativityChanged},scopedSlots:_vm._u([{key:"action",fn:function(slotProps){return [(
									!_vm.$route.query.createFromProduct &&
										bookmark.creatives.length > 0
								)?_c('div',{staticClass:"float-left",staticStyle:{"padding-left":"0"}},[(bookmark.creatives.length > 0)?_c('p',{staticClass:"card-category-mini centered-label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getCreativity(bookmark.id).status))+" ")]):_vm._e()]):_vm._e(),_c('div',{class:_vm.$route.query.createFromProduct
										? 'text-center'
										: 'text-right',staticStyle:{"padding-right":"0"}},[(!_vm.$route.query.createFromProduct)?[(
											_vm.$can('Approve', 'Bookmarks') &&
												!bookmark.archived
										)?_c('p-button',{staticClass:"btn-sm",attrs:{"type":"info","title":_vm.$t('Send test'),"icon":"","outline":true,"round":""},on:{"click":function($event){return _vm.handleTest(bookmark.id, index)}}},[_c('i',{staticClass:"fa fa-paper-plane"})]):_vm._e(),(
											bookmark.creatives.length > 0 &&
												_vm.$can('Approve', 'Bookmarks') &&
												!bookmark.archived
										)?_c('p-button',{staticClass:"btn-sm",attrs:{"type":"success","title":_vm.$t('Approve creative'),"icon":"","outline":!_vm.isApproved(bookmark.id),"round":""},on:{"click":function($event){return _vm.handleApprove(bookmark.id, index)}}},[_c('i',{staticClass:"icon icon-like-7"})]):_vm._e(),(
											bookmark.creatives.length > 0 &&
												_vm.$can('Reject', 'Bookmarks') &&
												!bookmark.archived
										)?_c('p-button',{staticClass:"btn-sm",attrs:{"type":"danger","icon":"","title":_vm.$t('Reject creative'),"outline":!_vm.isRejected(bookmark.id, index),"round":""},on:{"click":function($event){return _vm.handleReject(bookmark.id, index)}}},[_c('i',{staticClass:"icon icon-thumb-down"})]):_vm._e(),_c('el-dropdown',{on:{"command":_vm.onCommand}},[_c('p-button',{staticClass:"btn-sm btn-light-gray",attrs:{"type":"default","title":_vm.$t('More options'),"icon":"","outline":true,"round":""}},[_c('i',{staticClass:"fa fa-ellipsis-h"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
													_vm.$can(
														'CreateFromProduct',
														'Campaigns'
													) &&
														!bookmark.archived &&
														bookmark.status ==
															'APPROVED'
												)?_c('el-dropdown-item',{attrs:{"command":{
													event: 'NEW_CAMPAIGN',
													id: bookmark.id,
													index,
												}}},[_vm._v(" "+_vm._s(_vm.$t("Create campaign"))+" ")]):_vm._e(),(!bookmark.archived)?_c('el-dropdown-item',{attrs:{"command":{
													event: 'EDIT_BOOKMARK',
													id: bookmark.id,
													index,
												}}},[_vm._v(" "+_vm._s(_vm.$t("Edit template"))+" ")]):_vm._e(),(!bookmark.archived)?_c('el-dropdown-item',{attrs:{"command":{
													event: 'ARCHIVE_BOOKMARK',
													id: bookmark.id,
													index,
												}}},[_vm._v(" "+_vm._s(_vm.$t("Archive template"))+" ")]):_vm._e(),(bookmark.archived)?_c('el-dropdown-item',{attrs:{"command":{
													event: 'RESTORE_BOOKMARK',
													id: bookmark.id,
													index,
												}}},[_vm._v(" "+_vm._s(_vm.$t("Restore template"))+" ")]):_vm._e()],1)],1)]:[(
											_vm.$can(
												'CreateFromProduct',
												'Campaigns'
											) &&
												!bookmark.archived &&
												bookmark.status == 'APPROVED'
										)?_c('p-button',{staticClass:"btn-sm",attrs:{"type":"success","title":_vm.$t('Create campaign'),"icon":"","outline":true,"round":""},on:{"click":function($event){return _vm.handleCreateNewCamapaign(
												bookmark.id,
												index
											)}}},[_c('i',{staticClass:"fa fa-check"})]):_vm._e()]],2)]}}],null,true)})],1)}),0)],1):_vm._e(),_c('Slide',{attrs:{"isOpen":_vm.filterOpen},on:{"closeMenu":function($event){_vm.filterOpen = false}}},[_c('Filters',{attrs:{"default-values":_vm.defaultFilters},on:{"filterUpdated":function($event){return _vm.filterUpdated($event, _vm.refreshScroll)}}})],1),(!_vm.$route.query.d)?_c('fabAddBookmark',{class:{ glow: _vm.allBookmarks.length === 0 }}):_vm._e(),(!_vm.$route.query.d)?_c('fabFilterBookmark',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],attrs:{"position":1},on:{"click":function($event){_vm.filterOpen = true}}}):_vm._e(),(!_vm.$route.query.d)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"distance":function($event){1},"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(_vm._s(_vm.$t("No more templates!")))]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-warning text-center"},[_vm._v(" "+_vm._s(_vm.$t( "You don't have any templates yet. Please, create a template by clicking on the + button below!" ))+" ")])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }