<template>
  <div v-loading="loading" :element-loading-text="$t('Loading')">
    <template v-if="!hideBookmarkInfo">
      <SectionHeader text="Template Info" :top-margin="false" />

      <div class="row">
        <div class="col-md-3">
          <BookmarkName
            v-model="form.name"
            :isUnique="!hasId"
            :isEditable="isEditable && !viewOnly"
          />
        </div>

        <div class="col-md-2" v-if="!hideCommercialModel">
          <commercial-model
            v-model="form.commercial_model_id"
            name="bookmark_commercial_model"
            :isEditable="isEditable && !viewOnly"
          />
        </div>

        <div class="col-md-1" v-if="!hidePrice">
          <price
            v-model="form.price"
            :commercial-model="form.commercial_model_id"
            name="bookmark_price"
            :isEditable="
              isEditable && 4 !== form.commercial_model_id && !viewOnly
            "
          />
        </div>

        <div class="col-md-3">
          <categories
            v-model="form.category_id"
            :isEditable="isEditable && !viewOnly"
            :hide-ping="true"
          />
        </div>

        <div class="col-md-3">
          <sub-categories
            :categoryId="form.category_id"
            v-model="form.subcategory_id"
            :isEditable="isEditable && !viewOnly"
          />
        </div>

        <div class="col-md-3">
          <Advertisers
            :withBookmarks="false"
            :isEditable="isEditable && !viewOnly"
            v-if="$can('AssignAdvertiser', 'Campaign')"
            v-model="form.advertiser_id"
          />
        </div>

        <!-- <div class="col-md-3">
          <ServiceName
            v-if="$can('AssignService', 'Bookmarks')"
            v-model="form.services"
          />
        </div> -->

        <div class="col-md-3">
          <SubscriptionServicesSelect
            :value="form.service_id"
            @input="form.service_id = $event"
            :isEditable="isEditable && !viewOnly"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <campaign-flow-r
            :filterScenarioBy="activeScenarios"
            :channelDisabled="!isEditable || viewOnly"
            :scenarioDisabled="!isEditable || viewOnly"
            :entity="initialCampaignFlowData"
            @scenarioTypeChange="handleTemplateWithoutCreativities"
            @modelUpdated="onCampaignFlowUpdated"
          />
        </div>
        <div class="col-md-6">
          <!-- v-model="form.variables[agentVariableData]" -->
          <call-to-action
            v-model="form"
            :scenarioId="scenarioId"
            :isEditable="isEditable && !viewOnly"
          >
          </call-to-action>
        </div>
      </div>
    </template>

    <div class="row" v-if="scenarioId && viewCellC">
      <div class="col-12">
        <SectionHeader text="Doi Suscription Info" />
      </div>
      <div class="col-12 row">
        <div class="col-md-3" v-for="(input, key) in inputListCellC" :key="key">
          <InputsCellC
            :label="input.label"
            :name="input.name"
            :variable_id="input.variable_id"
            v-model="form.variables[indexVariables(input.variable_id)]"
            :isEditable="isEditable && !viewOnly"
            :required="requiredCellC"
            />
        </div>
      </div>
    </div>

    <div class="row" v-if="enableFlowConfiguration">
      <div class="col-12">
        <FlowConfiguration
          ref="flow-configuration"
          :scenario="scenario_type"
          v-model="flowJSON"
          :isEditable="isEditable && !viewOnly"
          :entity="entity"
          :enableFallback="enableFallback"
          :existingFallback="existingFallback"
          :fallbackTransactions="fallbackTransactions"
        ></FlowConfiguration>
      </div>
    </div>

    <div class="mt-3">
      <campaign-flow
        v-model="form"
        :scenarioId="scenarioId"
        :disabledCreativities="disableScenarioCreativitiesElements"
        :isEditable="isEditable && !viewOnly"
        :hide-bookmark-info="hideBookmarkInfo"
        v-show="!enableFlowConfiguration"
        v-if="!enableFlowConfiguration"
      >
        <div class="row" v-if="showCreatives">
          <div class="col-md-12">
            <creativity-configuration
              :creativitiesEnabled="true"
              :title="
                hideBookmarkInfo
                  ? 'Please, select the creatives you want for your campaign'
                  : undefined
              "
              :disableCreativeSelection="disableCreativeSelection"
              :entity="initialCreativitiesData"
              :minCreativities="1"
              :scenario="scenarioId"
              :vars="form.variables"
              :disabled="!(forEvent || !campaignStarted)"
              :disable-creative-selection="!forEvent"
              :tableHeight="creativesLength > 3 ? '250' : null"
              :validateMinSelectedCreativities="validateMinSelectedCreativities"
              :onlyOneCreativeSelected="onlyOneCreativeSelected"
              :isSatMultimedia="creativitiesNotAllowed"
              @creativesSelected="onCreativesSelected"
              @modelUpdated="onCreativitiesUpdated"
              ref="creativity-configuration"
              :allowAdd="allowAddCreative"
            />
          </div>
        </div>
        <div
          class="row satpush-multimedia-msg"
          v-if="!showCreatives && noCreativitiesMode"
        >
          <p>{{ $t("SatPush Multimedia does not support creativities") }}</p>
        </div>
        <div class="row" v-if="scenarioHasMenu && !enableFlowConfiguration">
          <div class="col-md-12 pr-0">
            <ProductMenuConfiguration
              @modelUpdated="onMenuEntriesUpdated"
              :entity="entityMenuToFrontendFormat"
            />
          </div>
        </div>
      </campaign-flow>
    </div>
    <!--  <div v-if="enableFallback && isSatPush" class="col-md-6 sat-fallback">
      <Fallback
        :existingFallback="existingFallback"
        :isSat="true"
        :template="entity"
      ></Fallback>
    </div> -->
  </div>
</template>

<script>
import { updatableForm } from "src/app-components/FormMixin";
import { isEmpty } from "lodash";
import { mapMutations } from "vuex";

import SubscriptionServicesSelect from "src/app-components/form-elements/Inputs/SubscriptionServicesSelect";
import Advertisers from "src/app-components/form-elements/Advertisers";
import BookmarkName from "src/app-components/form-elements/BookmarkName";
import InputsCellC from "src/app-components/form-elements/InputsCellC";
import CampaignFlowR from "src/app-components/form-elements/CampaignFlowR";
import CommercialModel from "src/app-components/form-elements/CommercialModel";
import Price from "src/app-components/form-elements/Price";
import Categories from "src/app-components/form-elements/Categories";
import SubCategories from "src/app-components/form-elements/SubCategories";
import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
import ScenarioCreativitiesElements from "src/app-components/form-elements/generators/ScenarioCreativitiesElements";
import ScenarioOptionalElements from "src/app-components/form-elements/generators/ScenarioOptionalElements";
import CampaignFlow from "src/app-components/form-elements/CampaignFlow";
import CreativityConfiguration from "src/app-components/CreativityConfiguration";
// import CreativityConfiguration from "src/app-components/CreativityConfigurationOld";
import ServiceName from "src/app-components/form-elements/ServiceName";
import SectionHeader from "src/app-components/form-elements/SectionHeader";
import CallToAction from "src/app-components/form-elements/CallToAction";
import ProductMenuConfiguration from "src/app-components/product-menu-configuration/ProductMenuConfiguration";

import FlowConfiguration from "src/app-components/form-tabs/FlowConfiguration.vue";

import api from "src/api";
import { merge, assign } from "lodash";
import Fallback from "src/app-components/rcs/Fallback";
import { forEach } from 'core-js/internals/array-iteration';

const REMOVE_PHS_FROM_FORM = ["EditBookmark", "AddBookmark"];

export default {
  name: "tab-bookmark",

  inject: {
    parentValidator: "parentValidator",
  },

  mixins: [updatableForm],

  components: {
    SubscriptionServicesSelect,
    Advertisers,
    CampaignFlowR,
    BookmarkName,
    InputsCellC,
    Categories,
    Price,
    ServiceName,
    [CampaignFlow.name]: CampaignFlow,
    [CommercialModel.name]: CommercialModel,
    [CreativityConfiguration.name]: CreativityConfiguration,
    [ScenarioCreativitiesElements.name]: ScenarioCreativitiesElements,
    [ScenarioOptionalElements.name]: ScenarioOptionalElements,
    [ScenarioRequiredElements.name]: ScenarioRequiredElements,
    [SubCategories.name]: SubCategories,
    SectionHeader,
    CallToAction,
    ProductMenuConfiguration,
    FlowConfiguration,
    Fallback,
  },

  props: {
    hideCommercialModel: {
      type: Boolean,
      default: false,
    },
    enableFallback: {
      type: Boolean,
      default: false,
    },

    existingFallback: {},
    /* viewOnly: {
      type: Boolean,
      default: false,
    }, */

    fallbackTransactions: {
      default: null,
    },

    commercialModel: {
      type: Number,
      required: false,
    },

    fallbackTransactions: {
      default: null,
    },

    hidePrice: {
      type: Boolean,
      default: false,
    },

    price: {
      type: Number,
      required: false,
    },

    allowAddCreative: {
      type: Boolean,
      default: true,
    },

    disableCreativeSelection: {
      type: Boolean,
      default: false,
    },

    validateMinSelectedCreativities: {
      type: Boolean,
      required: false,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },

    hideBookmarkInfo: {
      type: Boolean,
      default: false,
    },

    campaignStarted: {
      type: Boolean,
      default: false,
    },

    onlyOneCreativeSelected: {
      type: Boolean,
      default: false,
    },

    forEvent: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    this.$validator = this.parentValidator;
    if (isEmpty(this.entity)) this.loading = false;
  },

  beforeUpdate() {
    // console.log("BEFORE UPDATE");
    if (
      "placeholders" in this.form &&
      REMOVE_PHS_FROM_FORM.includes(this.$route.name)
    )
      delete this.form.placeholders;

    if (!this.viewOnly && this.entity.creatives) {
      if (
        this.entity.creatives.some((x) => x.in_use) &&
        this.entity.channel_id === 6
      ) {
        this.viewOnly = true;
      }
    }
    this.$emit("noCreativitiesMode", this.noCreativitiesMode);
  },

  destroyed() {
    this.resetCurrentBookmark();
  },

  computed: {
    requiredCellC() {
      let res = false;

      let inputList = [
        this.form.variables[this.indexVariables(115)],
        this.form.variables[this.indexVariables(116)],
        this.form.variables[this.indexVariables(117)],
      ]

      inputList.forEach(input => {
        if(input) {
          if(input.value) res = true;
        }
      })

      return res;
    },

    inputListCellC() {
      return [
        {
          label: "Charge code",
          name: "charge_code",
          variable_id: 115,
        },
        {
          label: "Charge interval",
          name: "charge_interval",
          variable_id: 116,
        },
        {
          label: "Content Type",
          name: "content_type",
          variable_id: 117,
        },
      ]
    },

    viewCellC() {
      const idCellC = [135, 136];
      if(this.$store.getters.appUser) {
        if(this.$store.getters.appUser.account) {
          return idCellC.includes(this.$store.getters.appUser.account.id);
        }
      }
      return false;
    },

    entityMenuToFrontendFormat() {
      const entity = this.entity;
      let data;
      if (!entity || !entity.menu) {
        data = [];
      } else {
        data = entity.menu.map((e) => {
          return {
            menuTitle: e.text_option,
            callToActionId: e.call_to_action_id,
            callToActionConfig: e.params.reduce(function (out, current) {
              out[current.variable.name] = current;
              return out;
            }, {}),
          };
        });
      }
      return { menuEntries: data };
    },
    categoryId() {
      return this.form.category_id;
    },

    hasId() {
      return typeof this.form.id !== "undefined";
    },

    creativesLength() {
      return this.form.creatives ? this.form.creatives.length : 0;
    },

    initialCampaignFlowData() {
      if (!this.entity.account_scenario) {
        return {
          account_scenario_type_id: null,
          channel_id: null,
        };
      }

      return {
        account_scenario_type_id: this.entity.account_scenario_type_id,
        channel_id: this.entity.account_scenario.scenario
          ? this.entity.account_scenario.scenario.channel_id
          : null,
        // channel_id: this.entity.channel_id,
      };
    },

    noCreativitiesMode() {
      if (this.scenario_type) {
        if (this.scenario_type.id == 74 || this.scenario_type.id == 78) {
          this.$emit("noCreativitiesMode", true);
          return true;
        }
      }
      if (this.creativitiesNotAllowed) {
        this.$emit("noCreativitiesMode", true);
        return true;
      }

      return false;
    },

    initialCreativitiesData() {
      if ("creatives" in this.entity) {
        return this.entity.creatives;
      }
      return [];
    },
    //  Hasta aca
    initialFlowJson() {
      if (this.entity.creatives && !this.creativitiesNotAllowed) {
        if (this.entity.creatives.length) {
          if (this.entity.creatives[0].variables) {
            if (this.entity.creatives[0].variables[0].variable) {
              if (
                this.entity.creatives[0].variables[0].variable.name ===
                "flow-json"
              ) {
                return {
                  hash: this.entity.creatives[0].hash,
                  id: this.entity.creatives[0].id,
                  status: this.entity.creatives[0].status,
                  variable_id:
                    this.entity.creatives[0].variables[0].variable_id,
                  value: JSON.parse(
                    this.entity.creatives[0].variables[0].value
                  ),
                };
              }
            }
          }
        }
      }
      return undefined;
    },

    formCreativitiesData() {
      if ("creatives" in this.form) {
        return this.form.creatives.filter(
          (element) => !Object.keys(element).includes("flow-json")
        );
      }
      return [];
    },

    formFlowJson() {
      if (this.form.creatives) {
        if (this.form.creatives.length) {
          if (this.form.creatives[0].variables) {
            if (this.form.creatives[0].variables.length) {
              if (this.form.creatives[0].variables[0].variable) {
                if (
                  this.form.creatives[0].variables[0].variable.name ===
                  "flow-json"
                ) {
                  return {
                    hash: this.form.creatives[0].hash,
                    id: this.form.creatives[0].id,
                    status: this.form.creatives[0].status,
                    variable_id:
                      this.form.creatives[0].variables[0].variable_id,
                    value: JSON.parse(
                      this.form.creatives[0].variables[0].value
                    ),
                  };
                }
              }
            }
          }
        }
      }
      return undefined;
    },

    showCreatives() {
      return (
        !this.enableFlowConfiguration &&
        !this.isCustomPing &&
        !this.noCreativitiesMode
      );
    },

    enableFlowConfiguration() {
      // return false;
      return this.channelId == 6;
    },

    isSatPush() {
      return this.channelId == 1;
    },

    channelId() {
      return this.form.channel_id;
    },

    scenarioId() {
      return this.form.account_scenario_type_id;
    },

    subCategoryId() {
      return this.form.subcategory_id;
    },

    agentVariableData() {
      if (this.form.variables) {
        if (this.form.variables.length > 0) {
          let position = 0;
          this.form.variables.forEach((elem, key) => {
            if (elem.variable) {
              if (elem.variable.name === "rcs-agent") {
                position = `${key}`;
              }
            }
          });
          return parseInt(position);
        }
      }
      return 0;
    },
  },

  watch: {
    flowJSON: {
      deep: true,
      handler(nv) {
        //console.debug("%cWATCH: flowJSON changed", 'background: #222; color: #bada55', JSON.parse(JSON.stringify(nv)));
        let flow = this.formFlowJson;
        if (flow) {
          assign(flow, nv);
        } else if (this.channelId == 6) {
          this.form.creatives.length == 0
            ? this.form.creatives.push(nv)
            : (this.form.creatives[0] = { ...nv });
        }

        if (this.form.creatives) {
          if (this.form.creatives[0]) {
            if (this.form.creatives[0].variables) {
              this.$set(
                this.form.creatives[0].variables[0],
                "value",
                JSON.stringify({
                  contentMessage: nv.value.contentMessage,
                })
              );

              this.updatedModel(this.form);
            }
          }
        }
      },
    },

    categoryId(nv) {
      this.$emit("updateCategory", nv);
    },

    subCategoryId(nv) {
      this.$emit("updateSubCategory", nv);
    },

    commercialModel(nv, ov) {
      if (this.hideCommercialModel) {
        this.form.commercial_model_id = nv;
      }
    },

    creativesLength() {
      this.$emit("updateCreativitiesTotal", this.creativesLength);
    },

    price(nv, ov) {
      if (this.hidePrice) {
        this.form.price = nv;
      }
    },

    async "form.account_scenario_type_id"() {
      if (this.form.account_scenario_type_id != null) {
        const scenarioType = await api.getScenarioType(
          this.form.account_scenario_type_id
        );
        this.scenario_type = scenarioType.data.scenario;
        if ([78, 88].includes(scenarioType.data.scenario.id)) {
          this.isCustomPing = true;
        }

        this.scenarioHasMenu = scenarioType.data.scenario.has_menu;
      } else {
        this.isCustomPing = false;
        this.scenarioHasMenu = false;
      }
    },
  },

  data() {
    return {
      viewOnly: false,
      loading: true,
      disableScenarioCreativitiesElements: false,
      creativitiesNotAllowed: false,
      viewOnly: false,
      form: {
        advertiser_id: null,
        account_scenario_type_id: null,
        category_id: null,
        channel_id: null,
        commercial_model_id: null,
        name: null,
        price: null,
        subcategory_id: null,
        variables: [],
        creatives: [],
        // creativities: [],
        placeholders: [],
        menu: [],
        services: [],
        service_id: null,
      },
      flowJSON: {},
      flow_patched: false,
      scenarioHasMenu: false,
      isCustomPing: false,
      scenario_type: {},
    };
  },

  methods: {
    ...mapMutations(["setCurrentBookmark", "resetCurrentBookmark"]),

    indexVariables(id) {
      if(this.form.variables) {
        let index = null;
        this.form.variables.forEach((v, i) => {
          if(v.variable_id === id) index = i;
        })

        return index !== null ? index : this.form.variables.length
      }

      return 0;
    },

    afterModelPatched() {
      // Por que hago esto? Debido a que el form tiene un watch, cada vez que este se modifica hace un emit del formulario modificado y me pisa
      // cualquier cambio que haya hecho en los placeholders, ya que el form conserva el estado original del arreglo.
      this.setCurrentBookmark(this.form);
      this.loading = false;
      // console.log("form patched", JSON.parse(JSON.stringify(this.form.creativities)));

      if (typeof this.initialFlowJson !== "undefined" && !this.flow_patched) {
        this.flow_patched = true;
        assign(this.flowJSON, this.initialFlowJson);
        /* console.log(
					"%c",
					"color:orange",
					"flowJSON patched",
					JSON.parse(JSON.stringify(this.flowJSON))
				); */
      }
    },

    handleTemplateWithoutCreativities(creativitiesNotAllowed = false) {
      console.log(
        "Handling templates without creativities",
        creativitiesNotAllowed
      );
      this.creativitiesNotAllowed = creativitiesNotAllowed;
      this.$emit("addEmptyCreativity", creativitiesNotAllowed);
    },

    onCampaignFlowUpdated($event) {
      this.form = { ...this.form, ...$event };
      this.clearRelated();
    },

    onCreativesSelected($event) {
      this.$emit("creativesSelected", $event);
    },

    onCreativitiesUpdated($event) {
      if (this.formFlowJson) {
        this.$set(
          this.form,
          "creatives",
          // "creativities",
          [this.formFlowJson].concat($event)
        );
      } else {
        this.$set(this.form, "creatives", $event);
        // this.$set(this.form, "creativities", $event);
      }
    },

    clearRelated() {
      if (this.$refs["creativity-configuration"]) {
        this.$refs["creativity-configuration"].clear();
      }
      if (!this.enableFlowConfiguration) {
        this.form.creatives.splice(0, this.form.creatives.length);
        this.$set(this.flowJSON, {}, {}); //Este deberia ejecutarse siempre
      }
    },

    activeScenarios(scenario) {
      if (scenario.scenario.name === "Custom Click to SMS") return true;
      return scenario.scenario.ping !== true;
    },

    onMenuEntriesUpdated(form) {
      this.form.menu = form.menuEntries.map((e, i) => {
        let params = Object.values(e.callToActionConfig);

        console.log(
          "params antes de manipular: ",
          JSON.parse(JSON.stringify(params))
        );

        // TODO: cambiar el modelo de datos de e.callToActionConfig. No se entiende por que es tan choto
        if (Array.isArray(params)) {
          if (params.length === 1 && Array.isArray(params[0])) {
            params = params[0].map((_params) => ({
              variable_id:
                typeof _params.variable_id === "object" &&
                "id" in _params.variable_id
                  ? _params.variable_id.id
                  : _params.variable_id,
              value: _params.value,
            }));
          } else {
						params = params.map((_params) => ({
							bookmark_menu_entry_id: _params.bookmark_menu_entry_id,
              variable_id: _params.variable_id,
              value: _params.value,
            }));
					}
        }

        return {
          call_to_action_id: e.callToActionId,
          text_option: e.menuTitle,
          position: i,
          params,
        };
      });

      console.log("Menu: ", JSON.parse(JSON.stringify(this.form.menu)));
    },
  },
};
</script>

<style scoped>
:deep(.form-group .el-input__inner[disabled]) {
  background-color: #e3e3e3 !important;
  cursor: not-allowed !important;
}
:deep(.el-select .el-input.is-disabled .el-input__inner:hover) {
  color: #66615b;
}

.satpush-multimedia-msg {
  margin-left: 1%;
  color: gray;
}
.sat-fallback {
  margin-top: 1%;
}
</style>