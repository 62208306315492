<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <form method="post" class="form-horizontal" novalidate>
                    <tabs :value="initialValue" tab-content-classes="text-center" ref="tabs">
                        <slot></slot>
                    </tabs>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <p-button type="dark" @click="goBack">{{ $t('Cancel') }}</p-button>
                <p-button :disabled="saveButtonDisabled" type="info" @click="validateBeforeSubmit">{{ $t('Save') }}</p-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Tabs from "src/custom-components/UIComponents/Tabs/Tabs";

    export default {
        name: "tabbed-form",
        inject: ["getError", "parentValidator"],

        props: {
            tabs: Object,
            initialValue: {
                type: String,
                default: "Description"
            },
            cancelAction: { type: Function, required: false },
            saveButtonDisabled: {
                type: Boolean,
                default: false
            }
        },

        components: {
            Tabs: Tabs
        },

        data() {
            return {
                errorTabs: this.getTabs()
            }
        },

        methods: {
            async validateBeforeSubmit() {
                this.$validator = this.parentValidator;
                this.errorTabs = this.getTabs();

                // Cambiamos validateAll() -> validate()
                if (await this.$validator.validate()) {
                    this.$emit("submit");
                }

                this.$validator.fields.map(field => { // Loopeamos sobre los campos registrados a validar
                    if (this.$validator.errors.items.some(error => error.field === field.name )) {
                        const tabId = this.getParentTabId(field.el);
                        this.errorTabs[tabId] = true;
                    }
                });

                this.$emit('error-tabs', this.errorTabs)
            },

            goBack() {
                if(typeof this.cancelAction == "function") {
                    this.cancelAction();
                }else{
                    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
                }
            },

            getTabs() {
                return this.tabs;
            },

            // Si el componente es el tab, devuelve el Id, sino, sigue buscando.
            getParentTabId(field) {
                return Object.keys(this.errorTabs).some(errorTab => errorTab === field.id) ? field.id : this.getParentTabId(field.parentElement);
            }
        }
    }
</script>