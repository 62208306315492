<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Category') }}</label>
        <el-select
          size="large"
          name="category"
          :filterable="filterable"
          :clearable="clearable"
          v-validate="noValidation ? null : 'required'"
          @input="$emit('input', $event)"
          :error="getError('category')"
          :value="local"
          :placeholder="''"
          :disabled="! isEditable"
        >
          <el-option
            v-for="option in categories"
            :value="option.id"
            :label="option.name"
            :key="'category-'+option.id"
          ></el-option>
        </el-select>
      </div>
      <div
        v-show="errors.has('category')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('category') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null,
    noValidation: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    hidePing: {
      type: Boolean,
      default: false
    }
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getPlatformCategories();
  },

  data() {
    return {
      categories: []
    };
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  methods: {
    async getPlatformCategories() {
      const categories = await api.getPlatformCategories();
      this.categories = categories.data;
      if (this.hidePing) {
        this.categories = this.categories.filter(c => c.id != 10)
      }
    }
  }
};
</script>

<style>
</style>
