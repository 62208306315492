<template>
    <div>
        <div class="row align-items-center">
            <div class="col-md-1">
                <RetryIndex :value="index + 1" />
                <span class="badge tiny badge-outline default">{{getStatusName(entity.statusId)}}</span>
            </div>


            <div class="col-md-2">
                <div class="form-group has-label">
                    <label>{{ $t('Retry type') }}</label>
                    <el-select size="large"
                               name="retry_type"
                               :disabled="disabled"
                               v-model="form.type">
                        <el-option v-for="option in retries"
                                   :value="option.type"
                                   :label="option.name"
                                   :key="option.type"
                        ></el-option>
                    </el-select>
                </div>
            </div>

            <div class="col-md-5">
                <GapRetry v-if="form.type === 'GAP'"
                    :disabled="disabled"
                    :variables.sync="form.variablesGapTime"
                    :dateProgrammed.sync="form.dateProgrammedGAP"
                    :name="entity.appId.toString()"
                    :parent-campaign-data="parentCampaignData"
                    :manual-start="manualStart" />

                <ProgrammedRetry v-if="form.type === 'PROGRAMMED'"
                    :disabled="disabled"
                    :dateProgrammable.sync="form.dateProgrammedPROGRAMMED"
                    :name="entity.appId.toString()"
                    :parent-campaign-data="parentCampaignData" />
            </div>

            <div class="col-md-3">
                <RetryOptions 
                    :channels="channels"
                    :value="form.variablesRetry"
                    @input="onRetryOptionsUpdated"
                    :name="`retryOptions${entity.appId}`"
                    :disabled="disabled"
                    :with-ping="parentCampaignData.activePing"
                />
            </div>

            <div class="col-md-1">
                <!-- v-if="index > 0"  -->
                <button type="button"
                    :disabled="!isDeleteable"
                    @click="$emit('delete')"
                    style="margin-top: 22px !important"
                    class="btn btn-icon btn-round btn-default">
                    <i class="nc nc-icon nc-simple-remove"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FloatingActionButton from "src/app-components/FloatingActionButton";
import { RadioGroup, RadioButton } from "element-ui";
import GapRetry from 'src/app-components/form-elements/GapRetry'
import ProgrammedRetry from 'src/app-components/form-elements/ProgrammedRetry'
import CampaignName from 'src/app-components/form-elements/CampaignName'
import RetryIndex from 'src/app-components/form-elements/RetryIndex'
import RetryOptions from 'src/app-components/form-elements/RetryOptions'
import RetryEnabled from 'src/app-components/form-elements/RetryEnabled'
import RepeatSamplingEnabled from 'src/app-components/form-elements/RepeatSamplingEnabled'
import { cloneDeep } from 'lodash'
import Constants from 'src/views/campaigns/constants'

import CampaignStatus from 'src/utils/campaign_status'

export default {
    components: {
        FloatingActionButton,
        [RadioGroup.name]: RadioGroup,
        [RadioButton.name]: RadioButton,
        GapRetry,
        ProgrammedRetry,
        CampaignName,
        RepeatSamplingEnabled,
        RetryOptions,
        RetryEnabled,
        RetryIndex
    },
    props: {
        index: Number,
        channels: Array,

        entity: {
            type: Object,
            default: null,
        },

        parentCampaignState: Number,
        parentCampaignData: Object,
        manualStart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: cloneDeep(this.entity.form),
        }
    },
    computed: {
        currentId () {
            return this.entity ? this.entity.id : null;
        },
        retries () {
            const values = [];
            /* Does not apply anymore DEV-406
            if (this.parentCampaignData.endDateTime) {
                values.push({ type: "PROGRAMMED", name: "Programmed" });
            } else {
                values.push({ type: "GAP", name: "Gap" });
            }
            */
            /* Does not apply anymore DEV-474
            values.push({ type: "PROGRAMMED", name: "Programmed" });
            values.push({ type: "GAP", name: "Gap" });
            */
            if (this.disabled) {
                values.push({ type: "GAP", name: "Gap" });
                values.push({ type: "PROGRAMMED", name: "Programmed" });
            } else if (Constants.StatusIdRetryTypeMustBeProgrammed.includes(this.parentCampaignState)) {
                values.push({ type: "PROGRAMMED", name: "Programmed" });
            } else {
                values.push({ type: "GAP", name: "Gap" });
                values.push({ type: "PROGRAMMED", name: "Programmed" });
            }
            return values;
        },
        disabled () {
            // return Constants.StatusIdFinished.includes(this.entity.statusId) ||
            //          this.entity.statusId == Constants.StatusIdWaiting /* DEV-845 */
            //          this.entity.statusId == Constants.StatusIdProgrammed

            return !CampaignStatus.isEditable(this.entity.statusId);
        },
        isDeleteable () {
            return [ 
                CampaignStatus.CREATED,
                CampaignStatus.DRAFT, 
                CampaignStatus.READY,
                CampaignStatus.PROGRAMMED,
                CampaignStatus.WAITING,
            ].includes(this.entity.statusId)
        }
    },
    methods: {
        onRetryOptionsUpdated($event) {
            //const vars = { ...this.form.variablesRetry, ...$event }
            this.$set(this.form, "variablesRetry", $event)
//            this.form.targetBase = $event;
        },

        getStatusName(statusId){
            return CampaignStatus.getName(statusId)
        }
    },
    watch: {
        form: {
            handler () {
                this.$emit("onModelUpdated", this.entity.appId, cloneDeep(this.form));
            },
            deep: true
        },
        /* Does not apply anymore DEV-406
        retries: {
            handler () {
                this.form.type = this.retries[0].type;
            },
            immediate: true,
        }
        */
        // This applies again DEV-474
        retries: {
            handler () {
                if (this.disabled) return;
                if (this.retries.find(r => r.type == this.form.type) == null) {
                    this.form.type = this.retries[0].type;
                }
            },
            immediate: true,
        },
    }

}
</script>

<style scoped>
.badge{
    font-size: .6em !important;
}
</style>
