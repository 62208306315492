<template>
  <div>
    <div class="mb-2">
      <span class="label">
        {{ $t("Type of options") }}
        <el-tooltip
          class="item"
          effect="dark"
          :content="infoTypeOptions"
          placement="top-start"
        >
          <i class="el-icon-info"></i>
        </el-tooltip>
      </span>
      <el-select
        size="small"
        v-model="typeOptions"
        placeholder="Select type of options"
        class="w-100"
        @change="updateData"
      >
        <el-option
          :label="$t('Interactive elements')"
          value="interactive"
        ></el-option>
        <el-option :label="$t('List of numbers')" value="list"></el-option>
      </el-select>
    </div>

    <span class="label">{{ $t("New option") }}</span>
    <div
      class="d-flex justify-content-between new-option mb-2"
      @keypress.enter="addOption"
    >
      <div>
        <el-select
          placeholder="Type"
          class="w-100 mb-2"
          v-model="newOption.type"
          size="small"
        >
          <el-option
            v-for="(option, key) in optionsType"
            :key="key"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="new-options">
        <el-input
          placeholder="Option text"
          v-model="newOption.text"
          :disabled="fullOptions"
          :maxlength="20"
          show-word-limit
          size="small"
        >
          <template slot="append">
            <span :class="!fullOptions ? 'pointer' : ''" @click="addOption">
              <i class="el-icon-plus"></i>
            </span>
          </template>
        </el-input>

        <picker-emoji @isertEmoji="isertEmoji($event)" />
      </div>
    </div>

    <el-collapse v-model="accordion" accordion class="collapse-options">
      <el-collapse-item
        v-for="(option, key) in optionsList"
        :name="key"
        :key="key"
      >
        <template slot="title">
          <div
            class="d-flex justify-content-between align-items-center w-100 pr-2"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex mr-2"
                style="width: 15px; flex-wrap: wrap"
                v-if="option.type !== 'fallback'"
              >
                <el-button
                  type="text"
                  icon="el-icon-arrow-up"
                  @click.stop="move('up', key)"
                  size="mini"
                  class="m-0 p-0"
                >
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-arrow-down"
                  @click.stop="move('down', key)"
                  size="mini"
                  class="m-0 p-0"
                >
                </el-button>
              </div>
              <span>
                <span
                  v-if="option.type !== 'fallback' && typeOptions === 'list'"
                  >{{ `${key + 1}.` }}</span
                >
                {{ option.text }}

                <template v-for="icon in iconsWithTooltipInformation">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="icon.text"
                    placement="top-start"
                    v-if="option.type === icon.show"
                    :key="icon.icon"
                  >
                    <i :class="icon.icon"></i>
                  </el-tooltip>
                </template>
              </span>
            </div>

            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="deleteOption(key)"
              v-if="option.type !== 'fallback'"
            >
            </el-button>
          </div>
          <i class="header-icon el-icon-information"></i>
        </template>

        <div v-if="option.type === 'reply'" @keypress.enter="addPhrase">
          <el-input
            size="mini"
            placeholder="Training phrase"
            v-model="newPhrase"
            :maxlength="20"
            show-word-limit
            :disabled="option.trainingPhrases.length >= 10"
          >
            <template slot="append">
              <span
                class="px-1"
                @click="addPhrase"
                :class="option.trainingPhrases.length < 10 ? 'pointer' : ''"
              >
                <i class="el-icon-plus"></i>
              </span>
            </template>
          </el-input>

          <el-tag
            :closable="phrase !== option.text.toLowerCase() && index > 0"
            :key="index"
            type="info"
            size="small"
            class="m-0 mt-2 mr-1"
            @close="deletePhrase(index)"
            :disable-transitions="false"
            v-for="(phrase, index) in option.trainingPhrases"
          >
            {{ phrase }}
          </el-tag>
        </div>

        <div v-if="option.type === 'url'">
          <el-input
            size="mini"
            placeholder="example.com"
            v-model="option.domain"
            :class="validate && option.domain === '' ? 'invalid' : ''"
            @input="updateDataUrl(key)"
          >
            <template slot="prepend">
              <el-select
                v-model="option.protocol"
                size="mini"
                style="width: 100px"
                @change="updateDataUrl(key)"
              >
                <el-option label="http://" value="http://"> </el-option>
                <el-option label="https://" value="https://"> </el-option>
              </el-select>
            </template>
          </el-input>
        </div>

        <div v-if="option.type === 'call'">
          <el-input
            size="mini"
            placeholder="+5491122334455"
            v-model="option.phone"
            @input="updateData"
            :class="validate && option.phone === '' ? 'invalid' : ''"
          ></el-input>
        </div>

        <div v-if="option.type === 'fallback'">
          <input-actions
            :variables="variables"
            :value="option.message"
            @updateValue="updateValueFallback($event)"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import PickerEmoji from "src/app-components/chatbot/elements/PickerEmoji";
  import InputActions from "src/app-components/chatbot/elements/InputActions";
  import {
    Button,
    Input,
    Tag,
    Select,
    Collapse,
    CollapseItem,
  } from "element-ui";
  export default {
    name: "FormOptions",
    props: {
      options: {
        type: Array,
        default: () => [],
      },
      typeOfOptions: {
        type: String,
        default: () => "",
      },
      infoFallback: {
        type: String,
        default: () => "",
      },
      variables: {
        type: Array,
        default: () => [],
      },
      validate: {
        type: Boolean,
        default: () => false,
      },
    },

    components: {
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
      "input-actions": InputActions,
      "picker-emoji": PickerEmoji,
    },

    data() {
      return {
        typeOptions: "interactive",
        optionsList: [],
        newOption: {
          text: "",
          type: "reply",
        },

        accordion: "",

        newPhrase: "",
      };
    },

    watch: {
      accordion() {
        this.newPhrase = "";
      },

      valid() {
        this.$emit("updateValid", this.valid);
      },

      typeOptions(n, o) {
        if (n === "list") {
          this.updateIndexOptions("unset");
          setTimeout(() => {
            this.updateIndexOptions("set");
          }, 5)
        } else if (o === "list") {
          this.updateIndexOptions("unset");
        }
      },
    },

    mounted() {
      this.optionsList = clone(this.options);
      this.typeOptions = clone(this.typeOfOptions);

      setTimeout(() => {
        this.$emit("updateValid", this.valid);
      }, 5);
    },

    methods: {
      move(action, key) {
        let options = clone(this.optionsList);
        let item = options.splice(key, 1);
        if (action === "up") {
          if (key > 0) {
            options.splice(key - 1, 0, item[0]);
          } else {
            return;
          }
        } else {
          if (key < options.length - 1) {
            options.splice(key + 1, 0, item[0]);
          } else {
            return;
          }
        }

        

        this.optionsList = clone(options);

        if (this.typeOptions === "list") {
          this.updateIndexOptions("unset");
          setTimeout(() => {
            this.updateIndexOptions("set");
            this.updateData();
            this.$emit("positionOptionsEdited");
          }, 5);
        } else {
          this.updateData();
          this.$emit("positionOptionsEdited");
        }

        
      },
      updateIndexOptions(action = "set") {
        var callToActions = 0;
        this.optionsList.forEach((opt, key) => {
          if (opt.type === "reply") {
            if (action === "set") {
              opt.trainingPhrases.unshift((key + 1) - callToActions);
            } else {
              if (opt.trainingPhrases[0] !== opt.text) {
                opt.trainingPhrases.shift();
              }
            }
          } else if (opt.type !== "fallback") {
            callToActions++;
          }
        });
      },

      addOption() {
        if (this.optionsList && this.newOption.text !== "") {
          let repeated = this.optionsList.findIndex(
            (o) => o.text === this.newOption.text
          );
          if (repeated !== -1 || this.fullOptions) {
            this.newOption.text = "";
            return;
          }

          const items = {
            reply: {
              text: "",
              type: "reply",
              trainingPhrases: [],
            },

            url: {
              text: "",
              type: "url",
              url: "",
              protocol: "http://",
              domain: "",
            },

            call: {
              text: "",
              type: "call",
              phone: "",
            },
          };

          let newItem = items[this.newOption.type];

          newItem.text = this.newOption.text;
          newItem.id = Date.now();

          if (this.newOption.type === "reply") {
            if (this.typeOptions === "list") {
              newItem.trainingPhrases.push(this.optionsList.length);
            }
            newItem.trainingPhrases.push(this.newOption.text.toLowerCase());
          }

          // this.optionsList.push(newItem);
          this.optionsList.splice(this.optionsList.length - 1, 0, newItem);

          this.$emit("positionOptionsEdited");
          this.updateData();

          if (this.newOption.type === "reply") {
            this.createOutput();
          }

          this.newOption.text = "";
          this.newOption.type = "reply";
        }
      },

      addPhrase() {
        if (this.newPhrase !== "") {
          if (this.optionsList[this.accordion]) {
            if (this.optionsList[this.accordion].trainingPhrases.length >= 10) {
              return;
            }

            let newPhrase = this.newPhrase.toLowerCase();

            if (
              this.optionsList[this.accordion].trainingPhrases.indexOf(
                newPhrase
              ) === -1
            ) {
              this.optionsList[this.accordion].trainingPhrases.push(newPhrase);
              this.updateData();
            }

            this.newPhrase = "";
          }
        }
      },

      deletePhrase(key) {
        if (this.optionsList[this.accordion].trainingPhrases) {
          if (this.optionsList[this.accordion].trainingPhrases[key]) {
            this.optionsList[this.accordion].trainingPhrases.splice(key, 1);
            this.updateData();
          }
        }
      },

      deleteOption(key) {
        if (this.optionsList[key]) {
          if (this.optionsList[key].type !== "fallback") {
            let outputs = this.outputsIdFormat(this.optionsList);
            let outputClass = outputs.indexOf(key);
            if (outputClass !== -1) {
              this.deleteOutput(`output_${outputClass + 1}`);
            }

            this.optionsList.splice(key, 1)[0];

            this.$emit("positionOptionsEdited");

            if (this.typeOptions === "list") {
              this.updateIndexOptions("unset");
              setTimeout(() => {
                this.updateIndexOptions("set");
                this.updateData();
              }, 5);
            } else {
              this.updateData();
            }
          }
        }
      },

      updateValueFallback(message) {
        if (this.optionsList.length) {
          this.optionsList[this.optionsList.length - 1].message = message;
          this.updateData();
        }
      },

      isertEmoji(emoji) {
        this.newOption.text += emoji;
      },

      updateData() {
        setTimeout(() => {
          this.$emit("updateDataOptions", this.optionsList);
          this.$emit("updateDataType", this.typeOptions);
          this.$emit("updateValid", this.valid);
        }, 1);
      },

      createOutput() {
        this.$emit("createOutput");
      },

      deleteOutput(output) {
        this.$emit("deleteOutput", output);
      },

      outputsIdFormat(options = null) {
        let keys = [];
        if (options) {
          options.forEach((o, index) => {
            if (o.type === "reply") {
              keys.push(index);
            }
          });
        }

        return keys;
      },

      updateDataUrl(key) {
        if (this.optionsList[key]) {
          this.optionsList[key].url =
            this.optionsList[key].protocol + this.optionsList[key].domain;
          this.updateData();
        }
      },
    },

    computed: {
      fullOptions() {
        if (!this.optionsList) return true;
        return this.optionsList.length >= 10;
      },

      optionsType() {
        return [
          {
            label: "Reply",
            value: "reply",
          },
          {
            label: "URL",
            value: "url",
          },
          {
            label: "Call",
            value: "call",
          },
        ];
      },

      infoTypeOptions() {
        return this.$t(
          'If you choose "interactive elements", they will look like buttons. If you choose a "list of numbers", each option will be sent as a numbered text.'
        );
      },

      valid() {
        if (this.optionsList.length) {
          var valid = true;
          this.optionsList.forEach((opt) => {
            if (opt.type === "url") {
              if (opt.domain === "") valid = false;
            }

            if (opt.type === "call") {
              if (opt.phone === "") valid = false;
            }
          });

          return valid;
        }
        return false;
      },

      iconsWithTooltipInformation() {
        return [
          {
            icon: "el-icon-info",
            text: this.infoFallback,
            show: "fallback",
          },
          {
            icon: "el-icon-link",
            text: "URL",
            show: "url",
          },
          {
            icon: "el-icon-phone",
            text: "Call",
            show: "call",
          },
        ];
      },
    },
  };
</script>

<style scoped>
  .new-option > div:first-of-type {
    width: 30%;
  }

  .new-option > div:last-of-type {
    width: calc(70% - 8px);
  }
</style>

<style>
  .wrapperCanvas .collapse-options .el-input-group__prepend {
    overflow: hidden;
  }

  .wrapperCanvas.dark .collapse-options .el-input-group__prepend {
    border-color: #555 !important;
  }

  .wrapperCanvas .new-options .el-input-group__append {
    padding: 0;
  }

  .wrapperCanvas .new-options .el-input-group__append span {
    padding: 0 15px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
