<template>
  <div class="row">
    <!-- Aca iria el selector lateral del scenario -->
    <div class="col-12 col-md-7">
      <div class="rcs-form-wrapper">
        <component
          :isEditable="isEditable"
          class="animated fadeIn"
          :is="rcsTemplate.component"
          v-model="flow.value.contentMessage"
        ></component>
        <span class="text-muted" v-if="!rcsTemplate.component">{{
          $t("Please, select scenario")
        }}</span>
      </div>

      <div class="rcs-form-wrapper border-secondary mt-3" v-if="rcsTemplate.component">
        <Suggestions
          :isEditable="isEditable"
          :max="10"
          :title="$t('Suggested answers')"
          :value="flow.value.contentMessage.suggestions"
          @input="input($event)"
          @edit="edit($event)"
          @remove="remove($event)"
        ></Suggestions>
      </div>
      <div
        class="rcs-form-wrapper border-secondary mt-3"
        v-if="rcsTemplate.component && enableFallback"
      >
        <Fallback :existingFallback="fallback" :template="rcsTemplate"></Fallback>
      </div>
    </div>
    <div class="col-12 col-md-5 d-flex flex-column justify-content-center">
      <Preview :value="flow.value" :scenario="scenarioID"></Preview>
    </div>

    <!-- Esto lo dejo deshabilitado pero sirve un monton si quieren debuguear -->

    <!-- <div class="col-auto">
            <label class="w-100">Show code</label>
            <el-switch v-model="debug"></el-switch>
        </div>
        <pre v-if="debug" class="text-left bg-light w-100">{{(flow.value)}}</pre> -->
  </div>
</template>

<script>
const uniqid = require("uniqid");

import { Input, Button, Switch } from "element-ui";
import RichCard from "src/app-components/rcs/RichCard";
import CarouselCard from "src/app-components/rcs/CarouselCard";
import FreeContentCard from "src/app-components/rcs/FreeContentCard";
import Preview from "src/app-components/rcs/Preview";
import Suggestions from "src/app-components/rcs/Suggestions";
import Fallback from "src/app-components/rcs/Fallback";

import VModelMixin from "src/mixins/v-model-mixin.js";

const SCENARIOS = new Map();

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [Switch.name]: Switch,
    RichCard,
    CarouselCard,
    FreeContentCard,
    Preview,
    Suggestions,
    Fallback,
  },

  props: {
    scenario: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    enableFallback: {
      tpye: Boolean,
      default: false,
    },
    fallbackTransactions: {
      type: Array,
      default: null,
    },
  },

  inject: {
    parentValidator: "parentValidator",
  },

  mixins: [VModelMixin("flow")],

  beforeCreate() {
    SCENARIOS.set(95, {
      component: "RichCard",
      contentType: "richCard",
      property: "standaloneCard",
    });
    SCENARIOS.set(96, {
      component: "CarouselCard",
      contentType: "richCard",
      property: "carouselCard",
    });
    SCENARIOS.set(97, {
      component: "FreeContentCard",
      contentType: "contentInfo",
      property: null,
    });
  },

  data() {
    let contentMessageType = "richCard";
    return {
      fallback_event_id: 25,
      fallback: null,
      debug: false,
      contentMessageType,
      patched: false,
      creativities: [],
      flow: {
        variable_id: 112,
        default: false,
        hash: uniqid("cr-"),
        variables: [
          {
            creative_field: this.setCreativeField(),
          },
        ],
        value: {
          // name: '',
          contentMessage: {
            suggestions: [],
            // ...
          },
        },
      },
    };
  },

  watch: {
    rcsTemplate(nv, ov) {
      if (ov.component) this.flow.value.contentMessage = { suggestions: [] };
    },

    creativeField() {
      this.flow.variables[0].creative_field = this.setCreativeField();
    },
  },

  computed: {
    rcsTemplate() {
      let scenario = { component: null, contentType: "", property: null };
      if ("id" in this.scenario) {
        scenario = SCENARIOS.get(this.scenarioID) || scenario;
      }
      return scenario;
    },
    scenarioID() {
      return this.scenario.id || null;
    },

    creativeField() {
      if (this.scenario.variables) {
        if (this.scenario.variables.length > 0) {
          return this.scenario.variables[0].creative_field;
        }
      }
      return null;
    },
  },

  mounted() {
    this.$validator = this.parentValidator;
    if (this.fallbackTransactions) {
      this.fallback = this.fallbackTransactions.find(
        (t) => t.event_id === this.fallback_event_id
      );
    }
  },

  methods: {
    input(suggestion) {
      this.$set(
        this.flow.value.contentMessage.suggestions,
        this.flow.value.contentMessage.suggestions.length,
        suggestion
      );
    },
    edit({ suggestion, index }) {
      this.$set(this.flow.value.contentMessage.suggestions, index, suggestion);
    },
    remove(index) {
      this.flow.value.contentMessage.suggestions.splice(index, 1);
    },

    setCreativeField() {
      return this.creativeField;
    },
  },
};
</script>

<style lang="scss" scoped>
.rcs-form-wrapper {
  border-radius: 10px;
  border: 1px solid var(--info);
  padding: 1rem;
}

:deep(input[maxlength]) {
  padding-right: 50px !important;
}
:deep(textarea[maxlength]) {
  padding-bottom: 25px !important;
}

:deep(.invalid-feedback) {
  position: absolute;
}

:deep(.col-with-errors) {
  div[class*="col"] {
    margin-bottom: 1rem !important;
  }
}

@media (min-width: 991px) {
  .rcs-form-wrapper::after {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f0da";
    color: var(--info);
    position: absolute;
    right: -5%;
    top: 50%;
    font-size: xxx-large;
  }
}
</style>
