<template>
  <div
    v-bind:class="[
      border ? 'card-w-border' : '',
      'card card-stats',
      selected ? 'card-selected' : '',
      colorstatus.toLowerCase(),
    ]"
    class="bd-callout p-0"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <div class="card-category-mini">
            {{ curSlide + 1 }} | {{ bookmark.creatives.length }}
          </div>
          <div
            class="icon-big text-center text-primary"
            style="margin-top: 0px; font-size: inherit; min-height: 48px; height: 48px"
          >
            <RcsIcon v-show="channel_id == 6" />
            <SmsIcon v-show="channel_id == 2 || channel_id == 3" />
            <SatIcon v-show="channel_id == 1" />
          </div>
        </div>

        <div class="col-7 col-md-8">
          <div class="numbers">
            <p class="card-category-mini" v-if="bookmark.advertiser">
              {{ bookmark.advertiser.name }}
            </p>
            <p class="card-title font-14">{{ bookmark.name }}</p>
            <p
              style="font-size: 10px; color: #9a9a9a; font-weight: bold"
              v-if="bookmark.category && bookmark.subcategory"
            >
              {{ bookmark.category.name }} /
              {{ bookmark.subcategory.name }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="flowJson == null">
        <siema
          ref="siema"
          v-on:update:current="onUpdateCurrentSlide"
          class="siema"
          :ready="true"
        >
          <div
            v-if="bookmark.creatives"
            class="slide"
            v-for="(creative, key) in bookmark.creatives"
            :key="key"
            @mouseenter="buttonShow = true"
            @mouseleave="buttonShow = false"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="row" v-if="bookmark.creatives.length > 1">
                  <transition name="fade" mode="out-in">
                    <div class="centered-div" v-if="buttonShow && key !== 0">
                      <button
                        class="el-carousel__arrow el-carousel__arrow--left only-arrow"
                        @click.stop.prevent="prevCreativity()"
                      >
                        <i class="el-icon-arrow-left"></i>
                      </button>
                    </div>
                  </transition>
                  <transition name="fade" mode="out-in">
                    <div
                      class="centered-div r-0"
                      v-if="buttonShow && key !== bookmark.creatives.length - 1"
                    >
                      <button
                        class="el-carousel__arrow el-carousel__arrow--right only-arrow"
                        @click.stop.prevent="nextCreativity()"
                      >
                        <i class="el-icon-arrow-right"></i>
                      </button>
                    </div>
                  </transition>
                </div>
                <tabs value="Description" tab-content-classes="text-center">
                  <tab-pane
                    :title="variable.variable.tooltip"
                    :key="index"
                    v-for="(variable, index) in creative.variables"
                  >
                    <template>
                      <div style="overflow: auto" class="creativity-height">
                        <div style="font-size: 11px">
                          {{ variable.value }}
                        </div>
                      </div>
                    </template>
                  </tab-pane>

                  <tab-pane
                    v-if="bookmark.account_scenario.scenario.has_menu"
                    :title="$t('Options')"
                    :key="bookmark.creatives[key].length"
                  >
                    <div style="font-size: 11px">
                      <span>{{
                        bookmark.menu.map((e) => e.text_option).join(" | ")
                      }}</span>
                    </div>
                  </tab-pane>
                </tabs>
              </div>
            </div>
          </div>
        </siema>
        <div class="row" v-if="bookmark.variables && bookmark.variables.length > 0">
          <div class="col-md-12">
            <tabs value="Description" tab-content-classes="text-center">
              <tab-pane :title="$t('Call to action')" key="home" style="min-height: 20px">
                <p style="font-size: 11px; text-transform: uppercase">
                  <b>
                    {{ 
                      bookmark.account_scenario.scenario.call_to_action 
                      ? bookmark.account_scenario.scenario.call_to_action.name 
                      : "" 
                    }}
                  </b>
                  <a
                    v-if="bookmark.account_scenario.scenario.call_to_action 
                        ? bookmark.account_scenario.scenario.call_to_action.id === 1 
                        : false"
                    target="_blank"
                    :href="linkLaunchBrowser"
                    >LINK</a
                  >

                  <span v-else>{{ variablesAction }}</span>
                </p>
              </tab-pane>
            </tabs>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-md-12">
            <tabs value="Description" tab-content-classes="text-center">
              <tab-pane :title="$t('Call to action')" key="home" style="min-height: 20px">
                <p style="font-size: 11px; text-transform: uppercase">
                  <span class="text-muted">N/A</span>
                </p>
              </tab-pane>
            </tabs>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="blockquote blockquote-primary">
              <blockquote>
                <p
                  class="pull-left"
                  style="font-size: 16px; font-weight: bold"
                  v-if="bookmark.commercial_model"
                >
                  {{ bookmark.commercial_model.name }}
                  <!-- {{ 
                    bookmark.commercial_model.name && bookmark.price
                      ? bookmark.commercial_model.name
                      : 'N/A'
                  }} -->
                </p>

                <p
                  v-if="hasPrice"
                  class="pull-right"
                  style="font-size: 16px; font-weight: bold"
                >
                  {{ bookmark.commercial_model.id !== 8 ? "$" + bookmark.price : "" }}
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bg-light pb-2 mt-1">
        <Preview
          :value="flowJson"
          :scenario="scenarioID"
          :phoneBackground="false"
          :animated="false"
          :showAnswers="false"
        ></Preview>
      </div>
    </div>

    <div class="card-footer">
      <hr />
      <div class="row">
        <div class="col-sm-12">
          <slot name="action">
            <div v-if="actions.includes('select')" class="d-flex justify-content-center">
              <p-button
                @click="emitSelected(bookmark)"
                type="success"
                :title="$t('Select bookmark')"
                icon
                :outline="true"
                class="btn-sm"
                round
              >
                <i class="fa fa-check"></i>
              </p-button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RcsIcon, SmsIcon, SatIcon } from "src/app-components/channel-icon";
import { Button, StatsCard } from "src/components/UIComponents";
import RemoteSvg from "src/components/UIComponents/RemoteSvg";
import Preview from "src/app-components/rcs/Preview";

import {
  Checkbox,
  Collapse,
  CollapseItem,
  Tabs,
  TabPane,
  Card,
} from "src/components/UIComponents";

export default {
  components: {
    Collapse,
    CollapseItem,
    RcsIcon,
    SmsIcon,
    SatIcon,

    TabPane,
    Tabs,

    [Button.name]: Button,
    StatsCard,
    [Checkbox.name]: Checkbox,
    RemoteSvg,
    Preview,
  },

  props: {
    bookmark: Object,
    form: Object,
    border: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedCreativeHash: {
      type: String,
      default: undefined,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    showOnlyApprovedCreativities: {
      type: Boolean,
      default: false,
    },
    activateGoTo: {
      type: Boolean,
      default: true,
    },
    colorstatus: {
      type: String,
      default: "PENDING",
    },
  },

  data() {
    return {
      curSlide: 0,
      model: this.form || {},
      buttonShow: false,
    };
  },
  created() {
    if (this.showOnlyApprovedCreativities) {
      this.bookmark.creatives.forEach((creativity, key) => {
        if (creativity.status !== "APPROVED") {
          this.bookmark.creatives.splice(key, 1);
        }

        if (this.bookmark.creatives[key].length === 0) {
          this.bookmark.creatives.splice(key, 1);
        }
      });
    }
  },
  computed: {
    variablesAction() {
      if (!this.bookmark.variables) return [];

      // let filter = this.bookmark.variables.filter((action) => action.variable.id === 4 || action.variable.id === 3);
      let filter = this.bookmark.variables.filter((action) => {
        if (!action.variable) return false;
        return action.variable.id === 4 || action.variable.id === 3;
      });
      return filter
        .map((action) => action.variable.tooltip + ": " + action.value)
        .join(" / ");
    },

    variblesCount() {
      if (!this.bookmark.variables) return 0;
      return this.bookmark.variables.length;
    },

    hasPrice() {
      return this.bookmark.price !== null;
    },

    scenarioID() {
      return this.bookmark.account_scenario.scenario_type_id;
    },

    flowJson() {
      if (this.bookmark.creatives[0]) {
        if (this.bookmark.creatives[0].variables) {
          if (this.bookmark.creatives[0].variables[0]) {
            if (
              this.channel_id == 6 &&
              "creatives" in this.bookmark &&
              this.bookmark.creatives[0].variables[0].variable_id == 112
            ) {
              let value = this.bookmark.creatives[0].variables[0].value;
              return JSON.parse(value);
            }
          }
        }
      }
      return null;
    },

    channel_id() {
      return this.bookmark.channel_id;
    },

    linkLaunchBrowser() {
      if(this.bookmark.variables) {
        let value = "";
        this.bookmark.variables.forEach(v => {
          if(v.variable) {
            if(v.variable.name === "url-lb") {
              value = v.value;
            }
          }
        })
        return value !== "" ? value : "#";
      }
      return "#";
    },
  },

  watch: {
    selectedCreativeHash: {
      handler() {
        if (this.selectedCreativeHash) {
          this.curSlide = this.getCurSlideByCreativeHash(this.selectedCreativeHash);
          setTimeout(() => {
            if(this.activateGoTo) {
              this.$refs.siema.goTo(this.curSlide);
            }
          }, 100);
        }
      },
      immediate: true,
    },
  },
  methods: {
    onUpdateCurrentSlide(currentSlide) {
      this.curSlide = currentSlide;
      this.$emit("creativity-changed", this.bookmark, this.curSlide);
    },

    jumpToId(perfTextId) {
      if (this.bookmark.creatives && this.activateGoTo) {
        const index = this.bookmark.creatives.findIndex((c) => c[0].id === perfTextId);
        this.$refs["siema"].goTo(index);
        //console.log("GO TO INDEX: " + index + " , " + this.bookmark.id )
      }
    },

    nextCreativity() {
      const ref = "siema";
      this.$refs[ref].next();
    },

    prevCreativity() {
      const ref = "siema";
      this.$refs[ref].prev();
    },

    getCurSlideByCreativeHash(hash) {
      if (this.bookmark.creatives) {
        let res = this.bookmark.creatives.findIndex((e) => {
          return e.hash == hash;
          // return e[0].hash == hash;
        });
        return res;
      }
    },

    emitSelected(bookmark) {
      this.$emit("selected", {
        bookmark: bookmark,
        creativity: bookmark.creatives ? bookmark.creatives[this.curSlide] : "",
      });
    },
  },
};
</script>

<style scoped>
:deep(div.tab-content) {
  padding: 0 0 0 0 !important;
  min-height: 48px;
}

:deep(.nav-tabs-navigation) {
  margin-bottom: 10px !important;
}

:deep(li.nav-item > a > div) {
  font-size: 10px;
}

:deep(.blockquote) {
  padding: 4px 20px 12px;
}

:deep(.font-14) {
  font-size: 16px;
}

:deep(.card-category-mini) {
  color: #9a9a9a;
  font-size: 8px;
  line-height: 1.4em;
}

.card-w-border {
  border: 2px solid rgba(0, 0, 0, 0.125);
}

:deep(.centered-div) {
  z-index: 1;
  position: absolute;
  top: 80%;
}

:deep(.r-0) {
  right: 0;
}

:deep(.only-arrow) {
  background-color: inherit;
  color: black;
}

:deep(.fade-enter-active),
.fade-leave-active {
  transition: opacity 0.5s;
}

:deep(.fade-enter),
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.creativity-height {
  max-height: 50px;
  min-height: 50px;
}

@media (max-width: 1750px) {
  .creativity-height {
    max-height: 75px;
    min-height: 75px;
  }
}

@media (max-width: 1390px) {
  .creativity-height {
    max-height: 90px;
    min-height: 90px;
  }
}

@media (max-width: 1199px) {
  .creativity-height {
    max-height: 75px;
    min-height: 75px;
  }
}

@media (max-width: 1070px) {
  .creativity-height {
    max-height: 90px;
    min-height: 90px;
  }
}

@media (max-width: 991px) {
  .creativity-height {
    max-height: 50px;
    min-height: 50px;
  }
}

@media (max-width: 850px) {
  .creativity-height {
    max-height: 75px;
    min-height: 75px;
  }
}

@media (max-width: 767px) {
  .creativity-height {
    max-height: 50px;
    min-height: 50px;
  }
}

@media (max-width: 410px) {
  .creativity-height {
    max-height: 75px;
    min-height: 75px;
  }
}

@media (max-width: 321px) {
  .creativity-height {
    max-height: 90px;
    min-height: 90px;
  }
}

@media (max-width: 270px) {
  .creativity-height {
    max-height: 150px;
    min-height: 150px;
  }
}

.card-selected {
  border: 2px solid rgb(102, 189, 101);
}
</style>
