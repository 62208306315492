<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!--
        <div class="card-header">
          <h4 class="card-title">{{ $t(title) }}</h4>
        </div>
        -->
        <div class="card-body">
          <tabbed-form
            @submit="onSubmit()"
            @error-tabs="onErrorTabs($event)"
            :tabs="getTabs()"
          >
            <tab-pane
              :title="$t('Template')"
              id="bookmark"
              :hasError="errorTabs.bookmark"
            >
              <div class="row">
                <div class="col-md-12">
                  <tab-bookmark
                    :entity="initialBookmarkData"
                    :isEditable="bookmarkProps.isEditable"
                    @addEmptyCreativity="onEmptyCreativityRequest"
                    @modelUpdated="onBookmarkModelUpdated"
                  />
                </div>
              </div>
            </tab-pane>
            <tab-pane
              :title="$t('Placeholders')"
              id="placeholders"
              :hasError="errorTabs.placeholders"
            >
              <TabPlaceholders
                :entity="initialBookmarkData"
                @modelUpdated="onBookmarkModelUpdated"
                :creatives="form.creatives"
                @valid-alias="valid_alias = $event"
              >
              </TabPlaceholders>
            </tab-pane>
          </tabbed-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { mapMutations } from "vuex";
import TabbedFormMixin from "src/app-components/TabbedFormMixin";
import FormatTemplateMixin from "src/app-components/FormatTemplateMixin";
import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
import TabbedForm from "src/app-components/TabbedForm";
import TabBookmark from "src/app-components/form-tabs/TabBookmark.vue";
import TabPlaceholders from "src/app-components/form-tabs/TabPlaceholders.vue";
import api from "src/api";

export default {
  mixins: [TabbedFormMixin, FormatTemplateMixin],

  provide() {
    return {
      parentValidator: this.$validator,
      getError: this.getError,
    };
  },

  components: {
    [TabPane.name]: TabPane,
    TabbedForm,
    TabBookmark,
    TabPlaceholders,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: "Create a new template",
    },
    templatetoCopyId: {
      type: Number,
      default: null,
    },
    updateCommercialModel: {
      type: Boolean,
      default: false,
    },

    initialBookmarkData: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    bookmarkProps: {
      type: Object,
      required: false,
      default: () => ({ isEditable: true }),
    },
  },

  async created() {
    this.$nextTick(async () => {
      if (this.templatetoCopyId) {
        const template = (await api.getTemplate(this.templatetoCopyId)).data;
        template.name = null;
        template.id = null;
        template.creatives.forEach((element) => {
          element.id = null;
          let newid = uniqid("cr-");
          element.hash = newid;
          element.in_use = 0;
        });
        this.initialBookmarkData = template;
      }
    });
  },

  watch: {
    form() {
      this.setCurrentBookmark(this.form);
    },
  },

  destroyed() {
    this.resetCurrentBookmark();
  },

  data() {
    return {
      valid_alias: true,
      addEmptyCreativity: false,
      form: {
        account_id: null,
        account_scenario: {},
        account_scenario_type_id: null,
        advertiser_id: null,
        archived: false,
        category_id: null,
        channel_id: null,
        commercial_model: {},
        commercial_model_id: null,
        created: null,
        creatives: [],
        deleted: null,
        deleted_at: null,
        id: null,
        menu: [],
        modified: null,
        name: "",
        placeholders: [],
        price: null,
        scenario_type_id: null,
        services: [],
        service_id: null,
        status: null,
        subcategory_id: null,
        user_id: null,
        variables: [],
        variables_action: [],
      },
    };
  },

  /* computed: {
    dataNoSend() {
      return [
        'account_scenario',
        'channel_id',
        'commercial_model',
      ]
    }
  }, */

  methods: {
    ...mapMutations(["setCurrentBookmark", "resetCurrentBookmark"]),

    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getTabs() {
      return {
        bookmark: false,
        placeholders: false,
      };
    },

    onBookmarkModelUpdated($event) {
      this.form = {
        ...this.form,
        ...$event,
      };
    },

    onEmptyCreativityRequest(add) {
      this.addEmptyCreativity = add;
    },
    addEmptyCreativityForSatPushMultimedia() {
      const hash = uniqid("cr-");
      this.form.creatives = [
        {
          hash,
          variables: [],
        },
      ];
    },

    onSubmit() {
      if (this.valid_alias) {
        if (this.addEmptyCreativity) {
          this.addEmptyCreativityForSatPushMultimedia();
        }
        const form = this.formatTemplate(this.form, !this.updateCommercialModel);
        this.$emit("submit", { ...form, account_scenario: null });
      } else {
        this.$notifications.clear();
        this.$notify({
          title: this.$t("Can't save"),
          message: this.$t("Some placeholders have repeated alias."),
          icon: "fa fa-info",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "warning",
          duration: 10000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-loading-mask) {
  background: #ffffff96 !important;
}
</style>
