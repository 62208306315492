<template>
  <div>
      <modal
          :show.sync="show"
          modalClasses="animated fadeIn"
          headerClasses="justify-content-center"
          type="mini"
      >
          <h4 slot="header" class="title title-up">{{$t('Change password')}}</h4>

          <div class="row input_container">
              <div class="col-12">
                  <label>{{$t('Current Password')}}</label>
                  <fg-input :error="getError(`${scope}.current`)">
                      <el-input
                          v-model="password.current"
                          show-password
                          type="password"
                          prefix-icon="el-icon-lock"
                          v-validate="'required'"
                          :data-vv-scope="scope"
                          name="current"
                          autocomplete="off"
                      />
                  </fg-input>
              </div>
              <div class="col-12">
                  <label>{{$t('New Password')}}</label>
                  <fg-input :error="getError(`${scope}.new`)">
                      <el-input
                          type="password"
                          prefix-icon="el-icon-lock"
                          autocomplete="off"
                          v-model="password.new"
                          show-password
                          v-validate="'required'"
                          :data-vv-scope="scope"
                          name="new"
                          ref="new"
                      />
                  </fg-input>
              </div>
              <div class="col-12 my-2">
                  <small><b>{{ $t("Password requeriments")}}</b></small>
                  <ul>
                      <li v-bind:class="{ is_valid: contains_eight_characters }">8 {{ $t("Characters")}}</li>
                      <li v-bind:class="{ is_valid: contains_number }">{{ $t("Contains Number")}}</li>
                      <li v-bind:class="{ is_valid: contains_uppercase }">{{ $t("Contains Uppercase")}}</li>
                      <li v-bind:class="{ is_valid: contains_special_character }">{{ $t("Contains Special Characters")}}</li>
                      
                      <li v-bind:class="{ is_valid: contains_name }">{{ $t("Does not contain name")}}</li>
                      <li v-bind:class="{ is_valid: contains_last_name }">{{ $t("Does not contain last name")}}</li>
                      <li v-bind:class="{ is_valid: contains_email }">{{ $t("Does not contain email")}}</li>
                  </ul>

                  <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">		
                      <svg width="50%" height="50%" viewBox="0 0 140 100">
                          <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                      </svg>
                  </div>
              </div>
              <div class="col-12">
                  <label>{{$t('Confirm password')}}</label>
                  <fg-input :error="getError(`${scope}.confirm`)">
                      <el-input
                          type="password"
                          prefix-icon="el-icon-lock"
                          autocomplete="off"
                          v-model="password.confirm"
                          show-password
                          v-validate="'required|confirmed:new'"
                          :data-vv-scope="scope"
                          data-vv-as="new"
                          name="confirm"
                      />
                  </fg-input>
              </div>
          </div>
          <template slot="footer">
              <div class="d-flex w-100">
                  <p-button
                      type="neutral"
                      class="ml-auto"
                      @click.native="show = false"
                  >{{$t('Cancel')}}</p-button>
                  <p-button
                      type="primary"
                      class="mr-3"
                      @click="submit"
                      :disabled="!valid_password || loading"
                      style="min-width: 105px;"
                  >
                    <i class="el-icon-loading" v-if="loading"></i>
                    <span v-else>{{$t('Confirm')}}</span>
                  </p-button>
              </div>
          </template>
      </modal>
  </div>
</template>

<script>
import api from "src/api";
import Modal from "src/custom-components/UIComponents/Modal";
import { Input } from "element-ui";
import swal from "sweetalert2";

export default {
  props: ["user"],
  components: {
      Modal,
      [Input.name]: Input,
  },
  $_veeValidate: {
      validator: "new",
  },
  data() {
      const scope = "password";
      return {
          scope,
          show: false,
          canSubmit: false,
          password: {
              current: '',
              new: '',
              confirm: '',
          },
          password_length : 0,
          contains_eight_characters: false,
          contains_number: false,
          contains_uppercase: false,
          contains_special_character: false,

          contains_name: true,
          contains_last_name: true,
          contains_email: true,

          loading: false,
      };
  },
  computed: {
      valid_password() {
          this.password_length = (this.password.new == null) ? 0 : this.password.new.length;
          const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

          if (this.password_length > 8) {
              this.contains_eight_characters = true;
          } else {
              this.contains_eight_characters = false;
          }

          this.contains_number = /\d/.test(this.password.new);
          this.contains_uppercase = /[A-Z]/.test(this.password.new);
          this.contains_special_character = format.test(this.password.new);


          let contains_name = true;
          this.names.forEach(name => {
            if(this.password.new.toLowerCase().includes(name.toLowerCase())) {
              contains_name = false;
            }
          });
          
          let contains_last_name = true;
          this.lastNames.forEach(lastname => {
            if(this.password.new.toLowerCase().includes(lastname.toLowerCase())) {
              contains_last_name = false;
            }
          });


          this.contains_name = contains_name;
          this.contains_last_name = contains_last_name;
          this.contains_email = !(this.password.new.toLowerCase().includes(this.email));




          if (
              this.contains_eight_characters &&
              this.contains_special_character &&
              this.contains_uppercase &&
              this.contains_number &&
              this.contains_name &&
              this.contains_last_name &&
              this.contains_email
          ) {
              return true;
          } else {
              return false;
          }
          /* if (
              this.contains_eight_characters === true &&
              this.contains_special_character === true &&
              this.contains_uppercase === true &&
              this.contains_number === true
          ) {
              return true;
          } else {
              return false;
          } */
      },

      names() {
        return this.user.firstname ? this.user.firstname.split(" ") : "";
      },

      lastNames() {
        return this.user.lastname ? this.user.lastname.split(" ") : "";
      },

      email() {
        if(this.user.email) {
          return this.user.email.split("@")[0].toLowerCase();
        }

        return "";
      }
  },
  watch: {
      password: {
          async handler(p) {
              // console.log("Validando", p);
              this.canSubmit = p.new.length > 0  && p.confirm.length > 0 && p.current.length > 0;
          },
          deep: true,
      },
      show: function (isVisible) {
          if (!isVisible) this.clean();
      },
  },
  created() {},
  methods: {
      showModal() {
          this.show = !this.show;
      },

      async submit() {
          if (this.canSubmit && this.valid_password && this.formIsValid()) {
            console.log(this.password);
            this.loading = true;
            let res = await api.changePassword({
              new_password: this.password.new,
              current_password: this.password.current,
            })

            this.loading = false;
            if(res.status) {
              // this.$notify("Password saved successfully");
              this.$notify({
                title: this.$t("Congratulations!"),
                message: this.$t("Password saved successfully"),
                type: 'success'
              });
              this.show = false;
            }else {
              this.$notify({
                title: this.$t("An error has ocurred"),
                message: this.$t(res.errors),
                type: "danger",
              });
            }
          }
      },

      async formIsValid(){
          return await this.$validator.validateAll(this.scope);
      },
      
      clean() {
          this.password.current = '';
          this.password.new = '';
          this.password.confirm = '';
          this.loading = false;
          this.$validator.errors.clear(this.scope);
          this.$validator.reset({
              scope: this.scope,
              vmId: this.$validator.id,
          });
      },
      getError(fieldName) {
          return this.errors.first(fieldName);
      },

      hasError(fieldName) {
          return this.errors.has(fieldName);
      },
  },
};
</script>

<style scoped lang="scss">

:deep(.modal-header) {
  border-bottom: none !important;
}
:deep(.title.title-up) {
  margin-bottom: 0px;
}

:deep(.el-input__inner) {
  padding-left: 33px;
}

ul {
padding-left: 20px;
display: flex;
flex-direction: column;
align-items: flex-start;
}

li { 
font-size: .8em;
color: #525f7f;
position: relative;
}

li:before {
content: "";
width: 0%; height: 2px;
background: #2ecc71;
position: absolute;
left: 0; top: 45%;
display: block;
transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Password Input --------- */

.input_container {
position: relative;
// padding: 30px;
// box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
// border-radius: 6px;
// background: #FFF;
}

input[type="password"] {
line-height: 1.5;
display: block;
color: rgba(136, 152, 170, 1);
font-weight: 300;
width: 100%;
height: calc(2.75rem + 2px);
padding: .625rem .75rem;
border-radius: .25rem;
background-color: #fff;
transition: border-color .4s ease;
border: 1px solid #cad1d7;
outline: 0;
}

input[type="password"]:focus {
border-color: rgba(50, 151, 211, .45);
}


/* Checkmark & Strikethrough --------- */

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }

.checkmark_container {
border-radius: 50%;
position: absolute;
top: 25%;
  right: 10%;
background: #2ecc71;
width: 50px; height: 50px;
visibility: hidden;
opacity: 0;
display: flex;
justify-content: center;
align-items: center;
transition: opacity .4s ease;
  transform: rotate(-13deg);
}

.show_checkmark {
visibility: visible;
opacity: 1;
}

.checkmark {
width: 100%;
height: 100%;
fill: none;
stroke: white;
stroke-width: 15;
stroke-linecap: round;
stroke-dasharray: 180;
stroke-dashoffset: 180;
}

.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
to { stroke-dashoffset: 0; }
}
</style>