<template>
  <div>
    <div @keypress.enter="addPhrase">
      <span class="label">
        Phrases
        <el-tooltip class="item" effect="dark" :content="infoTooltip" placement="top-start">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </span>

      <el-input class="w-100 new-global-scope" v-model="newPhrase" :disabled="maxPhrases">
      <template slot="append">
        <span @click="addPhrase" :class="!maxPhrases ? 'pointer' : ''">
          <i class="el-icon-plus"></i>
        </span>
      </template>
      </el-input>
    </div>

    <div>
      <el-tag
        closable
        :key="index"
        type="info"
        size="small"
        class="m-0 mt-2 mr-1"
        @close="deletePhrase(index)"
        :disable-transitions="false"
        v-for="(phrase, index) in phrases"
      >
        {{ phrase }}
      </el-tag>
    </div>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import { Tag, Input, Tooltip } from "element-ui";
  export default {
    name: "FormStorage",
    props: ["globalScope"],

    components: {
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Tooltip.name]: Tooltip,
    },

    data() {
      return {
        phrases: [],

        newPhrase: "",
      };
    },

    mounted() {
      this.phrases = clone(this.globalScope);
    },

    methods: {
      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.phrases);
        }, 1);
      },

      addPhrase() {
        if(this.maxPhrases) return;
        let newPhrase = this.newPhrase.toLowerCase().trim();
        if(newPhrase === "") return;

        let exist = this.phrases.indexOf(newPhrase) !== -1;
        if(!exist) {
          this.phrases.push(newPhrase);
          this.updateData();
        }

        this.newPhrase = "";
      },

      deletePhrase(key) {
        if(this.phrases[key]) {
          this.phrases.splice(key, 1);
          this.updateData();
        }
      },
    },

    computed: {
      infoTooltip() {
        return this.$t("Write words with which users can jump from one node to another. These words will be heard in the message nodes with options. You can set up to 10 words.");
      },

      maxPhrases() {
        return this.phrases.length >= 10;
      }
    },
  };
</script>
<style>
  .new-global-scope .el-input-group__append {
    padding: 0;
  }
  
  .new-global-scope .el-input-group__append span {
    padding: 0 15px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>