<template>
  <div>
    <span class="message">Contact center</span>
  </div>
</template>

<script>
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";
  export default {
    name: "ContactCenter",
    mixins: [ChatbotComponentsMixin],
    data() {
      return {};
    },
  };
</script>

<style>
  .wrapperCanvas .drawflow .drawflow-node.ContactCenter,
  .wrapperCanvas.dark .drawflow .drawflow-node.ContactCenter {
    width: 0;
    border: 0;
    box-shadow: 0 2px 15px 2px transparent;
  }

  .wrapperCanvas .drawflow .drawflow-node.ContactCenter .message {
    position: relative;
    z-index: 2;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
  }

  .wrapperCanvas .drawflow .drawflow-node.ContactCenter .input {
    width: 75px;
    height: 75px;
    transition: box-shadow linear .18s;
  }

  .wrapperCanvas .drawflow .drawflow-node.ContactCenter .input:hover {
    background: white;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.ContactCenter .input,
  .wrapperCanvas.dark .drawflow .drawflow-node.ContactCenter .input:hover {
    background: #555;
    border-color: #353535;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.ContactCenter .message {
    color: #ddd;
  }

  .wrapperCanvas .drawflow .drawflow-node.ContactCenter.selected .input {
    -webkit-box-shadow: 0 2px 12px 2px #909399;
    box-shadow: 0 2px 12px 2px #909399;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.ContactCenter.selected .input {
    -webkit-box-shadow: 0 2px 12px 2px #666;
    box-shadow: 0 2px 12px 2px #666;
  }
</style>
