import { transformCreativities } from "./_creativities";
import { transformVariables } from "./_variables"

const view = (res) => {
    res.data.variables = transformVariables(res.data.variables);
    res.data.creativities = transformCreativities(res.data.creativities);

    if(res.data.services) {
        res.data.services = res.data.services.map(service => service.service);
    }

    return res
}

export { view }