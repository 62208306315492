<template>
    <div class="accounts-selector" :class="{ 'isActive': isActive }">
        <span class="current-account d-flex align-items-center">
            <i :class="getCountryFlagIcon(currentAccount ? currentAccount.operator.country.countrycode : null)" v-if="!loading"></i>
            <i class="fa fa-circle-o-notch fa-spin fa-lg ml-1" v-if="loading"></i>
            <p class="w-100">{{ accountName }}</p>
            <a  v-if="accounts.length > 1"
                @click="showSelect"
                class="btn-change btn-rotate float-right ml-auto"
                :class="{ 'disabled' : loading}"
            >
                <i class="fa fa-retweet" aria-hidden="true"></i>
            </a>
        </span>
        <div class="accounts" v-if="accounts.length > 1">
            <el-select
                class="select w-100"
                filterable
                :value="$auth.selectedAccountId"
                @change="val => changeAccount(val)"
                :placeholder="$t('Change account')"
            >
                <el-option
                    v-for="a in accounts"
                    :key="a.id"
                    :label="a.name"
                    :value="a.id"
                ><i :class="getCountryFlagIcon(a.countrycode)" class="mr-2"></i>{{ a.name }}</el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            isActive: false,
        };
    },
    computed: {
        currentAccount(){
            return (
                this.$store.state.user ?
                this.$store.state.user.account :
                null
            );
        },
        accounts(){
            return (
                this.$store.state.user ?
                this.$store.state.user.accounts :
                []
            ).map(account => ({
                id: account.id,
                name: account.name,
                countrycode: account.country.countrycode,
            })).sort(function compare( a, b ) {
                if ( a.name < b.name ){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            });
        },
        countryFlagIcon() {
            return (
                this.$store.state.user ?
                this.$store.state.user.account.operator.country :
                ""
            );
        },

        accountName() {
            return (
                this.$store.state.user ?
                this.$store.state.user.account.name :
                ""
            );
        },
    },
    methods: {
        getCountryFlagIcon(code){
            return (
                code ?
                `nc-icon fi fi-${code.toLowerCase()} fis` :
                `nc-icon fi fis`
            );
        },

        changeAccount(account_id) {
            this.$auth.changeAccount(account_id);
            /*this.isActive = false;
            this.loading = true;

            const relpath = window.location.href.substring(
                window.location.href.indexOf("/", 8)
            );
            document.location.href = `/account/change?id=${account_id}&redirect=${encodeURIComponent(relpath)}`;*/
        },

        showSelect(){
            this.isActive = true
        },

        hideSelect(event){
            // console.log(event);
            if (this.isActive && event.path.filter(element => element._prevClass == "navbar").length == 0) {
                this.isActive = false;
            };
        }
    },
};
</script>
<style lang="scss">
.accounts-selector {
    .fi {
        font-size: 25px;
        
    }
    .current-account > .fi {
        margin-left: 10px;
    }
} 
</style>