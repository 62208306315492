<template>
	<div>
		<div v-if="forbidden">
			<not-found :description="$t('The template does not exist, or you don\'t have access to this template')" />
		</div>
		<div v-else>
			<form-bookmark
				:title="$t('Update your template')"
				:initialBookmarkData="initialBookmarkData"
				:bookmarkProps="{ isEditable: isEditable }"
    			:updateCommercialModel="true"
				@submit="handleSubmit"
			/>
		</div>
	</div>
</template>

<script>
import api from "src/api";
import Mixin from "./mixin.js";
import FormBookmark from "src/app-components/FormBookmark";
import { mapMutations } from "vuex";
import NotFound from 'src/components/GeneralViews/NotFound.vue';

export default {
    name: "TemplatesEdit",
	mixins: [Mixin],

	components: {
		"form-bookmark": FormBookmark,
		NotFound,
	},

	async mounted() {
		this.setTopNavbarTitle(this.$t("Edit a template"));
		if(this.user && this.templateId !== null){
			this.getTemplate(this.templateId);
		}
	},

	data() {
		return {
			forbidden: false,
			initialBookmarkData: {
				creativities: [],
			},
		};
	},

	computed: {
		user(){
			return this.$store.state.user;
		},
		templateId(){
			if ("id" in this.$route.params) {
				return parseInt(this.$route.params.id);
			}

			return null;
		},
		isEditable() {
			if (this.$can("Edit", "Bookmarks")) {
				return true;
			}

			const creatives = this.initialBookmarkData.creatives || [];

			const status = creatives.map(creative => creative.status);

			return !status.includes("APPROVED");
		},
	},
	watch:{
		user(){
			if ("id" in this.$route.params) {
				this.getTemplate(this.$route.params.id);
			}
		}
	},
	methods: {
		async getTemplate(id) {
			try {
				this.forbidden = false;
				const res = await api.getTemplate(id);
				this.initialBookmarkData = res.data;
				if(this.initialBookmarkData.service_id == undefined) {
					this.initialBookmarkData.service_id = null;
				}
			} catch(error){
				console.warn(`Failed to load template ${this.templateId}: `, error.message);
				this.forbidden = true;
			}
		},

		handleSubmit(data) {
			const id = data.id;
			api.updateTemplate(id, data).then((res) => {
				this.$router.push("/bookmarks/index");
			});
		},

		...mapMutations(["setTopNavbarTitle"]),
	},
};
</script>
