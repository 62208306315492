<template>
  <el-table :data="tableData" row-key="id">
    <el-table-column :label="$t('Type')" prop="type" />
    <el-table-column :label="$t('Name')" prop="name" />
    <el-table-column :label="$t('Size')" prop="size" />
    <el-table-column :label="$t('Uploaded by')" prop="uploadedBy" />
    <el-table-column :label="$t('Uploaded at')" prop="uploadedAt" />
    <el-table-column :label="$t('Valid entries')" prop="total" />
    <el-table-column :label="$t('Actions')">
      <template v-if="!scope.row.children" slot-scope="scope">
        <template v-if="isDeleteable(scope.row)">
          <p-button type="danger" size="sm" icon @click="handleDelete(scope.row)">
            <i class="fa fa-trash"></i>
          </p-button>
        </template>
        <FileDownload :id="scope.row.id" :filename="scope.row.name" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
  import { Table, TableColumn } from 'element-ui'
  import FileDownload from 'src/app-components/buttons-components/FileDownload'
  import lodash from 'lodash'
  import moment from 'moment'
  import api from 'src/api'
  import swal from "sweetalert2";

  export default {
    props: {
      value: Array
    },

    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      FileDownload
    },

    methods: {
      // ToDo, please, refactor to use button-components/FileRemove instead!
      async handleDelete (row) {
        const confirmResult = await swal({
            title: "Delete?",
            //text,
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
            allowOutsideClick: false
        });
        if (!confirmResult.value) return;
        await api.deleteUpload(row.id)
        this.$emit("deleted", row.id);
      },
      apiToFrontend (files) {
        const grouped = _.groupBy(files, f => this.getGroupingFileType(f.file_type));
        return Object.keys(grouped).map(file_type => {
          const fileTypeStr = this.formatFileType(file_type)
          return {
            id: file_type,
            type: fileTypeStr,
            //file_type: null,
            is_deleteable: false,
            name: "",
            size: "",
            uploadedBy: "",
            uploadedAt: "",
            total: "",
            children: grouped[file_type].map(file => {

              let uploadedBy;
              const uploadMoment = moment(file.created).tz(this.$store.getters.appUserTimezone);

              if (this.isDeleteable(file)) {
                uploadedBy = `${file.user.firstname} ${file.user.lastname}`
              } else {
                uploadedBy = this.$t("System")
              }

              return {
                id: file.id,
                type: fileTypeStr,
                //file_type: file.file_type,
                is_deleteable: file.is_deleteable,
                name: file.name,
                size: this.$options.filters["formatSize"](file.size),
                uploadedBy,
                uploadedAt: (
                  uploadMoment ?
                  uploadMoment.format("YYYY-MM-DD HH:mm") :
                  ''
                ),
                total: this.$options.filters["formatNumber"](file._count_success, 2)
              }
            })
          }
        })
      },
      formatFileType(fileType) {
        switch (fileType) {
          case "OTHER": return "Other"
          case "BLACKLIST": return "Blacklist"
          case "WHITELIST": return "Whitelist"
        }
      },
      getGroupingFileType(fileType) {
        switch (fileType) {
          case "ACCOUNT_OTHER": return "OTHER"
          case "ACCOUNT_BLACKLIST": return "BLACKLIST"
          case "ACCOUNT_WHITELIST": return "WHITELIST"
        }
      },
      isDeleteable(file) {
        return file.is_deleteable
      }
    },

    computed: {
      tableData () {
        return this.apiToFrontend(this.value)
      }
    }
  }
</script>