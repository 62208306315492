<template>
  <div class="wrapper">
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>
  </div>
</template>
<script>
export default {
   props: {
      title: {
         type: String,
         required: false,
         default: "Not Found"
      },
		description: {
         type: String,
         required: false,
         default: ""
      }
   }
  }
</script>
<style scoped>
.wrapper {
	text-align: center;
	height: 100% !important;
	display: flex;
	align-items: center;
	flex-direction: column;
}
</style>
