<template>
  <navbar :show-navbar="showNavbar" :navbarMenuClasses="'flex-grow-0 align-items-center'" :class="[showNavbar ? 'shadow-sm pb-2' : '']" v-click-outside="clickOutside">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#">{{ $t(title) }}</a>
    </div>

    <div class="ml-auto">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item">
          <div class="nav-link" href="#">
            <p>
              <span class="d-md-block">{{ datenow }}</span>
            </p>
          </div>
        </li>
        <li class="nav-item mb-0 d-lg-none d-md-block">
          <div class="nav-link" href="#">
            <p>
              <flag :iso="getCountryFlagIcon()" :squared="true" />
            </p>
          </div>
        </li>
        <li class="nav-item m-0 d-lg-none d-md-block">
            <a
            :title="$t('Show navbar')"
            class="nav-link btn-magnify"
            @click="toggleNavbar"
            >
            <i class="nc-icon nc-world-2"></i>
          </a>
        </li>
        <li class="nav-item m-0 d-lg-none d-md-block" v-if="$can('Configuration', 'Account')">
          <a
            :title="$t('Configure your account')"
            class="nav-link btn-magnify"
            href="/#/account-configuration/index">
            <i class="nc-icon nc-settings-gear-65"></i>
          </a>
        </li>
      </ul>
    </div>

    <template slot="navbar-menu">
      <li class="mr-2 flex-fill flex-md-fill">
        <ChangeAccount :isActive="changeAccountIsActive" ref="change-account"></ChangeAccount>
      </li>
      <li class="nav-item mb-1">
        <a class="round-button" :title="$t('Help')"
            target="_blank"
            href="https://dynamomobile.atlassian.net/servicedesk/customer/portals" 
          >
            <span style="color: white">
            <i class="fa fa-question-circle mr-1"></i>
              {{ $t('Help') }}
            </span>
        </a>
      </li>
      <li class="nav-item m-0 d-lg-block d-md-none d-sm-none d-none" v-if="$can('Configuration', 'Account')">
        <a
          :title="$t('Configure your account')"
          class="nav-link btn-rotate"
          href="/#/account-configuration/index">
          <i class="nc-icon nc-settings-gear-65"></i>
          <!-- <p>
            <span class="d-lg-none d-md-block">{{$t('Account')}}</span>
          </p> -->
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";
import ChangeAccount from "src/custom-components/Dashboard/Layout/ChangeAccount"
import FlagIcon from 'vue-flag-icon'

const moment = require("moment-timezone");

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    ChangeAccount,
    FlagIcon
  },

  data() {
    return {
      changeAccountIsActive: false,
      datenow: "",
      activeNotifications: false,
      showNavbar: false,
    };
  },

  computed: {
    timezone(){
      if(this.$auth && this.$auth.user){
        return this.$auth.user.account.operator.country.timezone;
      }

      return null;
    },
    title() {
      return this.$store.getters.topNavbar.title;
    },
  },

  created() {
    this.$sidebar.toggleMinimize();
  },

  async mounted() {
    this.interval = setInterval(this.time, 1000);
  },

  methods: {
    clickOutside(event){
        this.$refs["change-account"].hideSelect(event)
    },
    time() {
      this.datenow = (
        this.timezone ?
        moment().tz(this.timezone) :
        moment()
      ).format("YYYY-MM-DD, h:mm a");
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },

    closeDropDown() {
      this.activeNotifications = false;
    },

    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },

    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },

    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },

    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },

    getCountryFlagIcon(){

      return (
        this.$auth && this.$auth.user ?
        this.$auth.user.account.operator.country.countrycode :
        null
      );
    },
  }
};
</script>

<style scoped lang="scss">
:deep(.btn-change) {
    cursor: pointer;
    padding: 5px 7px;
    background-color: white;
    border-radius: 25px;
    transition: all 0.2s linear;
    color: #ccc;
    &:hover {
        box-shadow: 0px 0px 3px 1px #00000017;
    }
    &.disabled {
        pointer-events: none;
    }
}

:deep(.accounts-selector) {
    display: flex;
    overflow: hidden;
    position: relative;
    min-width: 200px;
    height: 100%;
    justify-content: center;
    align-items: center;
    // margin-right: 15px;

    .el-input__inner {
        background-color: #fff !important;
        // border: none !important;
        border-radius: 25px !important;
    }
}
:deep(.accounts) {
    min-height: 200px;
    width: 100%;
    top: 0;
    position: absolute;
    & .select {
        transform: translateY(110%);
        transition: all 0.2s ease-in-out;
    }
}
:deep(.current-account) {
    z-index: 998;
    visibility: visible;
    opacity: 1;
    transition: all 0.1s linear;
    width: 100%;
    min-height: 38px;
    text-align: center;
    padding: 4px;
    border-radius: 25px;
    background-color: #e4e7ed;

    i.flag-icon {
        margin-left: 0.7em;
        font-size: 1.5em;
        margin-right: 0.4em;
    }

    i.fa {
        color: #ccc;
    }

    p {
        font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
        text-transform: uppercase;
        font-size: 0.7142em;
        margin-top: 0.3em;
    }
}

:deep(.accounts-selector.isActive) {
    .accounts .select {
        transform: translateY(0);
        z-index: 999;
    }
    .current-account {
        visibility: hidden;
        opacity: 0;
        transform: scale(0.8);
        background-color: transparent;
    }
}

:deep(.navbar-nav) {
  flex-direction: row !important;
}

@media screen and (max-width: 991px) {
  .navbar-brand{
    font-size: small;
    -webkit-line-clamp: 2;
    width: min-content;
    white-space: break-spaces;
    text-overflow: ellipsis;
    line-height: 1;
  }
  .navbar .navbar-nav .nav-link {
    padding: 0.5rem 0.3rem;
  }
}
</style>
