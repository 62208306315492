<template>
    <div>
        <div :class="[ phoneBackground ? 'phone-view' : '']">
            <div class="chat-wrapper">
                <div v-if="dataExist && template" :class="[animated ? 'animated slideInLeft' : '']">
                    <div class="mb-1" v-if="template == 'CarouselPreview' && phoneBackground">
                        <label class="w-100"><small>{{$t("If a device can't display all elements of a card because of display constraints or card height, some elements will be truncated.")}}</small></label>
                    </div>
                    <component :is="template" :data="data"></component>
                    <div class="chip-list" v-if="showAnswers && suggestions.length > 0">
                        <span class="chip" v-for=" (s, index) in suggestions" :key="index">{{ s[Object.keys(s)[0]].text }}</span>
                    </div>
                    <small v-if="template == 'CarouselPreview'" class="d-inline-block w-100 text-center mt-2 text-muted">{{$t('Press shift while scrolling to move horizontally.')}}</small>
                </div>
                <div v-else class="d-flex align-items-center">
                    <span class="w-100 text-muted">{{$t('Complete any information to start.')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

import RichCardPreview from 'src/app-components/rcs/preview-elements/RichCardPreview.vue'
import CarouselPreview from 'src/app-components/rcs/preview-elements/CarouselPreview.vue'
import FreeContentPreview from 'src/app-components/rcs/preview-elements/FreeContentPreview.vue'

const PREVIEW_TEMPLATE = new Map();

export default {
    components: {
        RichCardPreview,
        CarouselPreview,
        FreeContentPreview
    },

    props: {
        value : {
            type: Object,
            default: () => {}
        },
        scenario: {
            type: Number,
        },
        phoneBackground: {
            type: Boolean,
            default: true
        },
        showAnswers: {
            type: Boolean,
            default: true
        },
        animated: {
            type: Boolean,
            default: true
        }
    },

    beforeCreate() {
        PREVIEW_TEMPLATE.set( 95, 'RichCardPreview');
        PREVIEW_TEMPLATE.set( 96, 'CarouselPreview');
        PREVIEW_TEMPLATE.set( 97, 'FreeContentPreview');
    },

    computed: {
        scenarioID(){
            return this.scenario
        },
        template(){
            return PREVIEW_TEMPLATE.get(this.scenarioID)
        },
        suggestions(){
            return this.value.contentMessage.suggestions
        },
        data(){
            return this.value.contentMessage
        },
        dataExist(){
            return Object.keys(this.data).filter( element => element !== "suggestions" ).length > 0 || this.suggestions.length > 0
        },
    }
}
</script>

<style lang="scss" scoped>

    .phone-view {
        background-image: url("~@/assets/img/frameless.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: rgb(235, 239, 242);
        max-width: 100%;
        width: 345px;
        height: 618px;
        padding: 94px 2px 80px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: end;
        justify-content: flex-end;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
    }

    .chat-wrapper{
        width: 100%;
        padding: 15px 15px 0;
        // margin-bottom: 12px;
        height: 100%;
        display: block;
        overflow-y: auto;
        // display: flex;
        // flex-direction: column;
        // -webkit-box-pack: end;
        // justify-content: flex-end;

        & > div {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;
            min-height: 100%;
        }
    }

    .chip{
        padding: 5px 15px;
        background: #FFF;
        color: #606266;
        border: 1px solid #d7d7d7;
        border-radius: 1rem;
        font-weight: 600;
        font-size: 80%;
        white-space: nowrap;

        & + & {
            margin-left: 5px;
        }
        &:first-child {
            margin-left: auto;
        }
    }

    .chip-list{
        overflow-x: auto;
        display: inline-flex;
        width: 100%;
        padding-bottom: 5px;
        margin-top: 5px;
    }

    pre{
        height: inherit;
        width: inherit;
    }

    small.d-inline-block{
        white-space: nowrap;
        font-size: 70%;
    }

    :deep(*) {
        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background-color: #fff;
        }
        &::-webkit-scrollbar {
            width: 5px;
            height: 4px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px #c6c6c691;
            background-color: #ebeff2;
        }
    }
</style>