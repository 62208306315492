<template>
    <div>
        <my-upload
            field="img"
            :noSquare="true"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="show"
            :width="300"
            :height="300"
            :params="params"
            :headers="headers"
            img-format="png"
            :langType="langType"
        ></my-upload>
            <!-- :langExt="getLanguage()" -->
    </div>
</template>

<script>
import myUpload from "vue-image-crop-upload/upload-2.vue";
import languages from './imageUploaderLanguages'

export default {
    components: {
        myUpload,
    },
    props: {
        lang: {
            type: String,
            default: 'en-EN'
        }
    },
    data() {
        return {
            show: false,
            imageURL: '',
            params: {
                token: "123456798",
                name: "avatar",
            },
            headers: {
                smail: "*_~",
            }
        };
    },
    
    watch: {
      imageURL() {
        this.$emit("updImage", this.imageURL);
      }
    },

    computed: {
      langType() {
        switch(this.lang) {
          case "pt-PT":
            return "pt-pt";
          case "es-ES":
            return "es-MX";
          default:
            return "en";
        }
        
      }
    },

    methods: {
        post(){
            return '/'
        },
        getLanguage(){
            return languages.get(this.lang)
        },
        toggleShow(){
            this.show = !this.show
        },
        /**
         * crop success
         *
         * [param] imageURL
         * [param] field
         */
        cropSuccess(imageURL, field) {
            console.log("-------- crop success --------");
            this.imageURL = imageURL;
        },
        /**
         * upload success
         *
         * [param] jsonData  server api return data, already json encode
         * [param] field
         */
        cropUploadSuccess(jsonData, field) {
            console.log("-------- upload success --------");
            console.log(jsonData);
            console.log("field: " + field);
        },
        /**
         * upload fail
         *
         * [param] status    server api return error status, like 500
         * [param] field
         */
        cropUploadFail(status, field) {
            console.log("-------- upload fail --------");
            console.log(status);
            console.log("field: " + field);
            this.show = !this.show
        },
    },
};
</script>

<style scoped lang="scss">
    :deep(img) {
        max-width: unset !important;
    }
    :deep(.vue-image-crop-upload .vicp-wrap) {
        border-radius: 12px;
    }
</style>