export default (bookmarkList) => {
	return {
		data() {
			return {
				selectedCreatives: {},
			};
		},
		methods: {
			creativityChanged(bookmark, creativityId) {
				this.$set(
					this.selectedCreatives,
					bookmark.id,
					bookmark.creatives[creativityId]
				);
			},
			getSelectedCreativity(idBookmark) {
				return this.selectedCreatives[idBookmark];
			},
			getSelectedCreativityId(idBookmark) {
				return this.getSelectedCreativity(idBookmark).id;
			},
		},
		watch: {
			[bookmarkList]() {
				this.selectedCreatives = this[bookmarkList].reduce((res, b) => {
					this.$set(res, b.id, b.creatives[0]);
					return res;
				}, {});
			},
		},
	};
};
