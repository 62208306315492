<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fg-input :label="$t('Campaign name')"
                          :value="local"
                          :name="name"
                          @input="$emit('input', $event)"
                          v-validate="{ required: required, uniqueCampaignName: isUnique }"
                          :error="getError(name)"
                          type="text"
                          :disabled="disabled" />
            </div>
        </div>
    </div>
</template>

<script>
import api from "src/api";
import debounce from "src/utils/debounce.js";

export default {
    inject: ["getError", "parentValidator"],

    props: {
        value: null,

        isUnique: {
            type: Boolean,
            default: true
        },

        required: {
            type: Boolean,
            default: true
        },

        name: {
            type: String,
            default: "campaign_name"
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.$validator = this.parentValidator;

        const isUnique = debounce((value) => {
            return api.validateCampaignName({ name: value.trim() }).then((response) => {
                return {
                    valid: response.status,
                    data: {
                        message: response.status ? "" : response.errors[Object.keys(response.errors).shift()]
                    }
                };
            });
        }, 1000);

        this.$validator.extend("uniqueCampaignName", {
            validate: isUnique,
            getMessage: (field, params, data) => data.message
        });
    },

    computed: {
        local() {
            return this.value ? this.value : null;
        }
    }
}
</script>

<style>

</style>
