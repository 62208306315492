<template>
  <div class="broadcast-integration-index card p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h6 mb-0">{{ $t("Integrations - API templates") }}</h1>
      <div class="d-flex justify-content-between align-items-center container-search">
        <el-input
          :placeholder="$t('Search')"
          v-model="search"
          suffix-icon="el-icon-search"
          size="small"
          class="mr-3"
        ></el-input>

        <el-button type="primary" @click="dialogCreateInstance = !dialogCreateInstance" size="small">{{
          $t("Create instance")
        }}</el-button>
      </div>
    </div>
    <el-table :data="tableData" class="w-100" stripe>
      <el-table-column label="NAME" prop="name" width="150px"></el-table-column>
      <el-table-column label="Services" width="150">
        <template slot-scope="scope">
          <span>
            {{ servicesString(scope.row.services) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Status" width="100">
        <template slot-scope="scope">
          <span :class="scope.row.status === 1 ? 'enabled' : 'disabled'" class="status">
            {{ scope.row.status === 1 ? "Enabled" : "Disabled" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Actions" width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="btn.tooltip"
            placement="top"
            :key="btn.tooltip"
            v-for="btn in buttonsActions"
          >
            <el-button type="text" class="icon-action py-0" @click="btn.action(scope.row.id)">
              <i :class="btn.icon"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <modal-integration
      title="New API template"
      :openModal="dialogCreateInstance"
      :optionsServices="optionsServices"
      :namesUsed="namesUsed"
      @save="createApiTemplate($event)"
    />

    <modal-integration
      title="Edit API template"
      :openModal="dialogEditInstance"
      :optionsServices="optionsServices"
      :dataIntegration="dataIntegrationEdit"
      :namesUsed="namesUsed"
      @closeModal="dataIntegrationEdit = null"
      @save="editApiTemplate($event)"
    />

    <el-dialog
      :title="`Are you sure you want to delete ${atDelete ? atDelete.name : ''}?`"
      :visible.sync="dialogDelete"
      @closed="clearDelete"
      class="dialog-delete-at"
    >
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogDelete = false" :disabled="loadingDelete">{{ $t("Cancel") }}</el-button>
        <el-button size="small" type="primary" @click="deleteApiTemplate" :disabled="loadingDelete">
          <i class="el-icon-loading" v-if="loadingDelete"></i>
          <span v-else>{{ $t("Confirm") }}</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { Input, Button, Table, TableColumn, Tooltip, Select, Dialog } from "element-ui";

  import ModalIntegration from "src/app-components/broadcast/ModalIntegration";
  import { mapMutations } from "vuex";
  import api from "src/api";
  export default {
    name: "BroadcastIntegrationIndex",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dialog.name]: Dialog,
      "modal-integration": ModalIntegration,
    },

    data() {
      return {
        search: "",

        dialogCreateInstance: false,
        dialogEditInstance: false,
        dataIntegrationEdit: null,

        newApi: {
          name: "",
          services_id: "",
          token: "",
        },

        services: [],

        apiTemplate: [],

        atDelete: null,
        dialogDelete: false,
        loadingDelete: false,
      };
    },

    created() {
      if(!this.$can('Views', 'Broadcast')) this.$router.push({ name: "DashboardIndex" });
      
      this.setTopNavbarTitle("Broadcast");
      this.getApiTemplate();
      this.getServices();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      downloadTxt(text = "", name = "") {
        let blob = new Blob([text], { type: "text/plain" });
        let blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);

        // Es necesario hacerlo de esta manera porque en Firefox link.click() no funciona
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        document.body.removeChild(link);
      },

      handleEdit(id) {
        this.dataIntegrationEdit = this.apiTemplate.find((data) => data.id === id);
        this.dialogEditInstance = !this.dialogEditInstance;
      },

      handleDownloadSecret(id) {
        let at = this.apiTemplate.find((s) => s.id === id);
        if (at) {
          this.downloadTxt(at.secret, `Api Secret - ${at.name}`);
        }
      },

      handleDelete(id) {
        let index = this.apiTemplate.findIndex((at) => at.id === id);
        if (index !== -1) {
          this.atDelete = {
            index,
            ...this.apiTemplate[index],
          };

          this.dialogDelete = true;
        }
      },

      clearDelete() {
        this.atDelete = null;
        this.loadingDelete = false;
      },

      async deleteApiTemplate() {
        try {
          this.loadingDelete = true;
          let res = await api.deleteApiTemplate(this.atDelete.id);
          if (res.status) {
            this.apiTemplate.splice(this.atDelete.index, 1);
            this.dialogDelete = false;
          }
        } catch (error) {
          console.log("error catch handleDelete", error);
        }
      },

      async getApiTemplate() {
        try {
          let res = await api.getApiTemplate();
          if (res.status) {
            this.apiTemplate = [...res.data];
          }
        } catch (error) {
          console.log("error catch getApiTemplate", error);
        }
      },
      
      async regenerateApiTemplate(id) {
        try {
          let res = await api.regenerateApiTemplate(id);
          console.log(res);
        } catch (error) {
          console.log("error catch regenerateApiTemplate", error);
        }
      },

      async getServices() {
        try {
          let res = await api.getServices();
          if (res.status) {
            this.services = [...res.data];
          }
        } catch (error) {
          console.log("error catch getServices", error);
        }
      },

      createApiTemplate(data) {
        this.apiTemplate.push(data);
      },

      editApiTemplate(data) {
        let index = this.apiTemplate.findIndex((at) => at.id === data.id);
        if (index !== -1) {
          this.apiTemplate.splice(index, 1, {
            ...this.apiTemplate[index],
            ...data,
          });
        }
      },

      servicesString(services) {
        let res = "";
        services.forEach((id, key) => {
          let service = this.services.find((service) => service.id === id);
          if (service) {
            if (key) {
              res += ", ";
            }
            res += service.name;
          }
        });

        return res;
      },
    },

    computed: {
      tableData() {
        return this.apiTemplate.filter((at) => at.name.toLowerCase().includes(this.search.toLowerCase()));
      },

      buttonsActions() {
        return [
          /* {
            icon: "el-icon-refresh",
            action: this.regenerateApiTemplate,
            tooltip: "Regenerate token",
          }, */
          {
            icon: "el-icon-edit",
            action: this.handleEdit,
            tooltip: "Edit",
          },
          {
            icon: "el-icon-download",
            action: this.handleDownloadSecret,
            tooltip: "Download api secret",
          },
          {
            icon: "el-icon-delete",
            action: this.handleDelete,
            tooltip: "Delete",
          },
        ];
      },

      optionsServices() {
        const validServices = [8, 10];
        let services = this.services.filter((service) => validServices.indexOf(service.channel_id) != -1);

        return services.map((s) => {
          return {
            label: `${s.name} (${s.agent_options.phone ? s.agent_options.phone : s.agent_options.phone_id})`,
            value: s.id,
          };
        });
      },

      namesUsed() {
        if(!this.apiTemplate.length) return [];

        return this.apiTemplate.map((at) => {
          if (this.dataIntegrationEdit) {
            return at.id !== this.dataIntegrationEdit.id ? at.name.toLowerCase() : "";
          }

          return at.name.toLowerCase();
        });
      },
    },
  };
</script>

<style scoped>
  .container-search {
    max-width: 325px;
  }

  span.status {
    display: inline-block;
    padding: 0 10px;
    border-radius: 20px;
    color: white;
    background: #939392;
    width: 70px;
    text-align: center;
    font-weight: bold;
  }
  span.status.disabled {
    background: #c84a4a;
  }

  .icon-action i {
    font-size: 16px;
    color: #303030;
    margin: 0 5px;
  }
</style>

<style>
  .dialog-delete-at .el-dialog__body {
    display: none;
  }

  .broadcast-integration-index button {
    outline: 0;
  }
</style>
