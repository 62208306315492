<template>
    <div class="rcs-card-content row">
        <div class="col-12 col-md">
            <div class="row">
                <div class="col-12">
                    <fg-input :error="errors.first('title', scope)">
                        <label class="text-left w-100">{{ $t('Title') }}</label>
                        <el-input
                            v-validate="{ required : cardContent.description == '' && cardContent.suggestions.length == 0 && !('media' in cardContent)}"
                            name="title"
                            :data-vv-scope="scope"
                            v-model="cardContent.title"
                            size="mini"
                            :maxlength="200"
                            show-word-limit
                            :disabled="!isEditable">
                        </el-input>
                    </fg-input>
                </div>

                <!-- Card ID -->
                <div class="col-12">
                    <el-switch
                        class="label"
                        v-model="manualCardId"
                        inactive-text="Card ID: AUTO"
                        active-text="MANUAL"
                        :active-value="true"
                        :inactive-value="false"
                    />
                    <el-input
                        name="card-id"
                        v-model="cardContent.id"
                        type="text"
                        size="mini"
                        :maxlength="200"
                        show-word-limit
                        :disabled="!manualCardId"
                    />
                </div>
                <div class="col-12">
                    <label class="w-100 text-left">{{$t('Description')}}</label>
                    <el-input
                        v-model="cardContent.description"
                        type="textarea"
                        :label="$t('Description')"
                        resize="none"
                        :autosize="{ minRows: 3, maxRows: 5 }"
                        :maxlength="2000"
                        show-word-limit
                        :disabled="!isEditable">
                    </el-input>
                </div>
                <div class="col-12">
                    <!-- La idea es que en este slot puedan colocarse mas elementos al form llegado el caso -->
                    <slot name="properties" v-bind="cardContent"></slot>
                </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="row">

                <div class="col-12" v-if="cardOrientation == 'vertical' && hasMedia">
                    <label class="w-100 text-left">{{$t('Media height')}}</label>
                    <el-select v-model="cardContent.media.height" :placeholder="$t('Select')" class="w-100" size="mini">
                        <el-option
                            v-for="option in MEDIA_HEIGHT"
                            :key="option.value"
                            :label="$t(option.label)"
                            :value="option.value"
                            :disabled="!isEditable">
                        </el-option>
                    </el-select>
                </div>

                <div class="col-12">
                    <MediaUploader :isEditable="isEditable" :fileUrl="fileUrl" @pictureLoaded="pictureLoaded" @pictureRemove="pictureRemove" :isRequired="!cardContent.title && !cardContent.description"></MediaUploader>
                </div>
                
            </div>
        </div>
        <div class="col-12">
            <Suggestions :isEditable="isEditable" class="mt-3" :title="$t('Calls to action')" :value="cardContent.suggestions" @input="input($event)" @remove="remove($event)"></Suggestions>
            <fg-input type="hidden" :error="errors.first('minSuggestions', scope)" v-validate="'minSuggestions'" :data-vv-scope="scope" name="minSuggestions" v-model="suggestionsLength"></fg-input>
        </div>
        <!-- <div class="col-12 col-md-6">
            <SMSCompatibles class="mt-3" v-model="richCard.sms_compatible"></SMSCompatibles>
        </div> -->
    </div>
</template>

<script>
import { Input, Upload, Button, Row, Select, Option, Switch, Tooltip } from "element-ui";

import Suggestions from 'src/app-components/rcs/Suggestions';
import SMSCompatibles from 'src/app-components/rcs/SMSCompatibles';
import MediaContentPreview from 'src/app-components/rcs/preview-elements/MediaContentPreview';
import MediaUploader from 'src/app-components/rcs/MediaUploader';

import VModelMixin from 'src/mixins/v-model-mixin.js';

export default {
    name: 'CardContent',

    components: {
        [Input.name]: Input,
        [Upload.name]: Upload,
        [Button.name]: Button,
        [Row.name]: Row,
        [Select.name]: Select,
        [Option.name]: Option,
        [Switch.name]: Switch,
        [Tooltip.name]: Tooltip,
        MediaUploader,
        Suggestions,
        SMSCompatibles,
        MediaContentPreview
    },

    props: {
        index: {
            type: Number,
            required: false,
            default: 0
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        orientation: {
            type: String,
            default: 'vertical'
        },
        maxImgSize: {
            type: Number,
            default: 2
        },
        maxVideoSize: {
            type: Number,
            default: 10
        }
    },

    inject: {
        parentValidator: "parentValidator",
    },


    mixins: [
        VModelMixin('cardContent', 'CardContent')
    ],

    data() {
        const MEDIA_HEIGHT = [
            // { label : "Not specified" , value : "HEIGHT_UNSPECIFIED" },
            { label : "Short"         , value : "SHORT"              },
            { label : "Medium"        , value : "MEDIUM"             },
            { label : "Tall"          , value : "TALL"               }
        ];
        return {
            scope: `cardContent-${this.index}`,
            MEDIA_HEIGHT,
            loading: false,
            files: [],
            manualCardId: false,
            cardContent: {
                id: '',
                title: '',
                description: '',
                // media: {
                //     height: 'SHORT',
                //     contentInfo: {
                //         fileUrl: '',
                //         thumbnailUrl: '',
                //         forceRefresh: false,
                //     }
                // },
                suggestions: []
            }
        };
    },

    computed: {
        hasMedia(){
            return 'media' in this.cardContent
        },
        suggestionsLength(){
            return this.cardContent.suggestions.length
        },
        cardOrientation(){
            return this.orientation.toLowerCase()
        },

        fileUrl(){
            return this.hasMedia ? this.cardContent.media.contentInfo.fileUrl : ''
        },
    },

    created() {
        // Falta agregarle un index a los nombres de las validaciones para diferenciar los cardcontent
        this.$validator = this.parentValidator;
        // At least one creativity
        this.$validator.extend('fileSize', {
            validate: (file) => {
                // console.log("VALIDANDO ARCHIVO", file);
                if (file.length > 0) {
                    const validIMG = file[0].raw.type.includes('image') && (file[0].size / 1024 / 1024) < this.maxImgSize;
                    const validVIDEO = file[0].raw.type.includes('video') && (file[0].size / 1024 / 1024) < this.maxVideoSize;
                    return validIMG || validVIDEO 
                }
                return true
            },
            getMessage: () => this.$t('The file exceeds the permitted size.')
        });

        // At least one suggestion
        this.$validator.extend('minSuggestions', {
            validate: (count) => {
                return count !== 0
            },
            getMessage: () => this.$t('Need at least one call to action')
        });
    },

    watch: {
        "manualCardId": {
            immediate: true,
            handler: function(newVal, oldVal){
                if(newVal === false){
                    //auto
                    this.updateCardId();
                }
            }
        },
        "cardContent.title": {
            immediate: true,
            handler: function(newVal, oldVal){
                if(!this.manualCardId){
                    this.updateCardId();
                }
            }
        }
    },

    methods: {
        //patchLocalValue(propData) {
        //    this.$set(this, 'cardContent', flat(propData))
        //    this.afterModelPatched()
        //},
        updateCardId(){
            if(this.cardContent.title.length > 0){
                this.cardContent.id = `${this.cardContent.title} (Card #${this.index + 1})`;
            } else {
                //TODO: mejorar/definir
                this.cardContent.id = `Card #${this.index + 1}`;
            }
        },

        pictureRemove() {
            this.$delete( this.cardContent, 'media');
        },

        async pictureLoaded(url) {

            this.cardContent = Object.assign({},this.cardContent, this.newMedia());
            this.cardContent.media.contentInfo.fileUrl = url;

        },

        input(suggestion){
            this.$set(this.cardContent.suggestions, this.cardContent.suggestions.length, suggestion )
        },

        remove(index){
            this.cardContent.suggestions.splice(index, 1);
        },

        newMedia(){
            return {
                media : {
                    height: 'SHORT',
                    contentInfo: {
                        fileUrl: '',
                        thumbnailUrl: '',
                        forceRefresh: false,
                    }
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    :deep(.input-group-text) {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        background-color: white;
    }

    :deep(.el-upload-list) {
        margin-left: 5px;
        margin-right: 5px;
    }

    :deep(.el-upload-list--picture .el-upload-list__item-thumbnail) {
        width: unset;
    }

    :deep(.loaded) {
        border-color: var(--success);
        // background-color: #a3ff9d70 !important;
        .el-upload--text {
            display: none;
        }
    }

    :deep(.preview) {
        
        img, video {
            height: 100%;
            max-width: 90%;
            max-height: 125px;
        }

        video {
            background-color: black;
        }
    }

    .bg-miro{
        background-color: #da0063 !important;

        &.is-disabled {
            background-color: #cf709b !important;
        }
    }
</style>
<style>
/*.rcs-card-content .el-switch__label * {
    
}
.rcs-card-content .el-switch {
    
}*/
.rcs-card-content .label {
    display: block;
    text-align: left !important;
    margin-bottom: 5px;
    color: #9A9A9A;
}
.rcs-card-content .label * {
    font-size: 0.8571em !important;
    color: #9A9A9A !important;
}

</style>