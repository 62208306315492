<template>
  <component :is="baseComponent"
             :to="link.path ? link.path : '/'"
             :class="{active: isActive || link.path.includes($route.path)}"
             tag="li">
             
    <a v-if="isMenu"
       data-toggle="collapse"
       href="#"
       @click.prevent="collapseMenu">
      <i :class="link.icon"></i>
        <p>{{translated}}
          <b class="caret" :class="{rotated: !collapsed}"></b>
        </p>
    </a>

    <collapse-transition v-if="$slots.default || this.isMenu">
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
      <component
        :to="link.path"
        :is="elementType(link, false)"
        :class="{active: isActive}"
        :target="link.target"
        @click.native.stop="onItemClick"
        :href="link.path">
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{ translated.charAt(0) }}</span>
          <span class="sidebar-normal">{{translated}}</span>
        </template>
        <template v-else>
          <i :class="link.icon"></i>
          <div :class="{floatText : translated.length > 22}">
            <p>{{translated}}</p>
          </div>
          <component class="sidebaritem-badge-component" v-if="link['badge-component']" :is="link['badge-component']" />
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import LiveCampaignsBadge from "src/app-components/badge-components/LiveCampaigns";

  export default {
    components: {
      CollapseTransition,
      LiveCampaignsBadge
    },
    props: {
      menu: {
        type: Boolean,
        default: false,
        description: 'Whether item is a menu containing multiple items'
      },
      link: {
        type: Object,
        default: () => {
          return {
            name: '',
            path: '',
            children: []
          }
        },
        description: 'Link object'
      }
    },
    provide() {
      return {
        addLink: this.addChild,
        removeLink: this.removeChild
      }
    },
    inject: {
      addLink: { default: null },
      removeLink: { default: null },
      autoClose: { default: false },
    },
    data() {
      return {
        children: [],
        collapsed: true
      }
    },
    computed: {
      baseComponent() {
        const component = function(link) {
          if(!link.internal) {
            return 'li';
          }

          return 'router-link';
        };

        return this.isMenu || this.link.isRoute ? 'li' : component(this.link)
      },
      isMenu() {
        return this.children.length > 0 || this.menu === true
      },
      isActive() {
        if (this.$route) {
          let matchingRoute= this.children
            .find((c) => c.link.path.includes(this.$route.path)); 

          if (matchingRoute !== undefined) {
            return true
          }
        }
        return false
      },
      translated(){
        return this.$t(this.link.name);
      }
    },
    methods: {
      addChild(item) {
        const index = this.$slots.default.indexOf(item.$vnode)
        this.children.splice(index, 0, item)
      },
      removeChild(item) {
        const tabs = this.children
        const index = tabs.indexOf(item)
        tabs.splice(index, 1)
      },
      elementType(link, isParent = true) {
        if(!link.internal) {
          return "a";
        }

        if (link.isRoute === false) {
          return isParent ? 'li' : 'a'
        } else {
          return 'router-link'
        }
      },
      collapseMenu() {
        this.collapsed = !this.collapsed
      },
      onItemClick() {
        if(this.autoClose) {
          this.$sidebar.showSidebar = false;
        }
      },
    },
    mounted() {
      if (this.addLink) {
        this.addLink(this)
      }
      if (this.link.collapsed !== undefined) {
        this.collapsed = this.link.collapsed
      }
      if (this.isActive && this.isMenu) {
        this.collapsed = false
      }
    },
    destroyed() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
      if (this.removeLink) {
        this.removeLink(this)
      }
    }
  }
</script>
<style scoped>
  .caret.rotated {
    transform: rotate(180deg);
  }

  .sidebar .nav li.active > a.active i,
  .sidebar .nav li.active > a[data-toggle="collapse"],
  .sidebar .nav li.active > a[data-toggle="collapse"] i,
  .sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active i,
  .sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active a,
  .sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a,
  .off-canvas-sidebar .nav li.active > a[data-toggle="collapse"],
  .off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] i,
  .off-canvas-sidebar .nav li.active > a > i,
  .off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active i,
  .off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active a,
  .off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
    color: #6bd098 !important;
    -webkit-transition: all 300ms ease 0s; 
    transition: all 300ms ease-in 0s;
  }

  .sidebar-mini .sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li   
  {
    height:40px;
  }
 
  .sidebar-mini .sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal
  {
    visibility: visible;
    transition: visibility 0s, opacity 0.5s linear;
    opacity: 1;
    height: auto;
  }
  
  .sidebar-mini-icon,
  .sidebar-normal {
    padding-left: 10px;
  }

</style>
