<template>
  <div class="card">
    <el-table :data="tableData" style="width: 100%" :empty-text="emptyText">
      <el-table-column :label="$t('ID')" width="50px">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Name')">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Actions')">
        <template slot-scope="scope">
          <p-button
            class="mr-1"
            :type="btn.type"
            size="sm"
            icon
            @click="btn.event(scope.row.id)"
            v-for="btn in buttonsAction"
            :key="btn.icon"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(btn.tooltip)"
              placement="top"
            >
              <i :class="btn.icon"></i>
            </el-tooltip>
          </p-button>
        </template>
      </el-table-column>
    </el-table>

    <floating-action-button
      color="#339564"
      icon="add"
      :position="0.4"
      @click="openModal"
    />

    <ModalCreateFlow
      :agentsGbm="agentsGbm"
      :flowsName="flowsName"
      :close="closeModalCreateFlow"
      :open="openModalCreateFlow"
      @closeModal="closeModal($event)"
    />

    <el-dialog
      width="30%"
      class="confirm-dialog"
      :visible.sync="modalConfirmDelete"
      title="Are you sure you want to delete this flow?"
    >
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeConfirmDelete">Cancel</el-button>
        <el-button size="mini" type="primary" @click="deleteFlow"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import api from "src/api";
  import { mapMutations } from "vuex";
  import { Table, Button, Dialog } from "element-ui";
  import FloatingActionButton from "src/app-components/FloatingActionButton";
  import ModalCreateFlow from "src/app-components/chatbot/index/ModalCreateFlow";
  export default {
    name: "FlowIndex",

    components: {
      ModalCreateFlow,
      FloatingActionButton,
      [Table.name]: Table,
      [Button.name]: Button,
      [Dialog.name]: Dialog,
    },

    data() {
      return {
        tableData: [],

        openModalCreateFlow: false,
        closeModalCreateFlow: false,

        agentsGbm: [],

        idFlowDelete: null,
        modalConfirmDelete: false,
      };
    },

    created() {
      if(!this.$can('Views', 'Chatbots')) this.$router.push({ name: "DashboardIndex" });
      
      this.setTopNavbarTitle("Flows");
      this.getFlows();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getFlows() {
        let res = await api.getFlows();
        if (res.status) {
          this.tableData = [...res.data];
        }
      },

      editAgent(id) {
        this.pushCanvas(id);
      },

      confirmDeleteAgent(id) {
        this.idFlowDelete = id;
        this.modalConfirmDelete = true;
      },

      async deleteFlow() {
        if (this.idFlowDelete) {
          let res = await api.deleteFlow(this.idFlowDelete);
          if (res.status) {
            this.getFlows();
          }
        }

        this.closeConfirmDelete();
      },

      closeConfirmDelete() {
        this.modalConfirmDelete = false;
        this.idFlowDelete = null;
      },

      closeModal(id) {
        this.pushCanvas(id);
        this.getFlows();
        this.closeModalCreateFlow = !this.closeModalCreateFlow;
      },

      pushCanvas(id) {
        let routeData = this.$router.resolve({
          name: "FlowCanvas",
          params: { id },
        });
        window.open(routeData.href, "_blank");
      },

      nameAgentGbm(id) {
        let agent = this.agentsGbm.find((agent) => agent.id === id);
        return agent ? `${agent.agent_name} (${agent.brand_name})` : "";
      },

      openModal() {
        this.openModalCreateFlow = !this.openModalCreateFlow;
      },
    },

    computed: {
      buttonsAction() {
        return [
          {
            type: "info",
            icon: "fa fa-edit",
            tooltip: "Edit",
            event: this.editAgent,
          },
          {
            type: "danger",
            icon: "fa fa-trash",
            tooltip: "Delete",
            event: this.confirmDeleteAgent,
          },
        ];
      },

      emptyText() {
        return "You don't have any flow yet. Please, create a flow by clicking on the + button below!";
      },

      flowsName() {
        return this.tableData.map(i => i.name);
      }
    },
  };
</script>

<style>
  .el-dialog__wrapper.confirm-dialog .el-dialog__body {
    padding: 0;
  }
</style>
