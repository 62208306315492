<template>
  <div>
    <div class="row justify-content-center">
      <h5 class="info-text">{{ $t('Please, select the strategy you would like to use') }}</h5>
    </div>

    <div class="row justify-content-center mb-4">
      <div
        class="col-md-3"
        v-for="(performanceCampaign, index) in performanceCampaingTypesFiltered"
        v-if="!bookmarkWithoutPrice || performanceCampaign.id !== 4"
        :key="index"
      >
        <icon-radio
          v-model="model.performance_campaign_type_id"
          v-validate="'required'"
          name="performance_campaign_type_id"
          :icon="performanceCampaign.icon"
          :real-value="performanceCampaign.id"
          :title="$t(performanceCampaign.name)"
        ></icon-radio>

        <p>{{ performanceCampaign.description }}</p>
      </div>
    </div>

    <div class="row" v-if="errors.has('performance_campaign_type_id')">
      <div class="col-md-12">
        <div class="text-danger invalid-feedback"
          style="display: block;"
        >{{ errors.first('performance_campaign_type_id') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import IconRadio from "src/app-components/form-elements/Inputs/IconRadio";
import { defaultMixin } from "src/app-components/FormMixin"
import { EventBus } from "src/eventBus"

export default {
  $_veeValidate: {
    validator: 'new'
  },

  mixins: [
      defaultMixin
  ],

  components: {
    "icon-radio": IconRadio
  },

  props: {
    wzModel: Object,
  },

  async created() {
    const performanceCampaingTypes = await api.getPerformaceCampaignTypes();
    this.performanceCampaingTypes = performanceCampaingTypes.data.filter(type => type.id !== 3);
    this.performanceCampaingTypesFiltered = this.performanceCampaingTypes;
  },
  
  /* async created() {
    const performanceCampaingTypes = await api.getPerformaceCampaignTypes();
    this.performanceCampaingTypes = performanceCampaingTypes.data.filter(type => [1, 4].includes(type.id));
    this.performanceCampaingTypesFiltered = this.performanceCampaingTypes;

    EventBus.$on("remove-strategies", ($event) => {
      if ($event.filter.length === 0) {
        this.performanceCampaingTypesFiltered = this.performanceCampaingTypes;
      } else {
        this.performanceCampaingTypesFiltered = this.performanceCampaingTypes.filter(perfCampType => !$event.filter.some(filter => perfCampType.id === filter));
      }
    });
  }, */

  watch: {
    wzModel() {
      this.model.performance_campaign_type_id = this.wzModel.performance_campaign_type_id;
    }
  },

  data() {
    return {
      performanceCampaingTypes: null,
      performanceCampaingTypesFiltered: null,
      model: {
        performance_campaign_type_id: null
      }
    };
  },

  computed: {
    performanceCampaign() {
      return this.performanceCampaingTypes
        .filter(x => x.id == this.model.performance_campaign_type_id)
        .shift();
    },

    bookmarkWithoutPrice() {
      let res = false;
      if(this.wzModel.bookmarks) {
        this.wzModel.bookmarks.forEach(bookmark => {
          if(isNaN(parseInt(bookmark.price))){
            res = true;
          }
        })
      }
      return res;
    },
  },

  methods: {
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>