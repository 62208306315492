<template>
    <div>
        <div class="row" v-if="$can('Filter', 'Account')">
            <div class="col-md-12">
                <Accounts v-model="filters.accounts" :filterable="true" :no-validation="true" />
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <Channels v-model="filters.channel_id"
                    @clear="onClear"
                    :noValidation="true"
                    :clearable="true" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <Scenarios :disabled="!filters.channel_id"
                    :filterBy="allowedScenariosFilter"
                    :noValidation="true"
                    :clearable="true"
                    :channel="filters.channel_id"
                    v-model="filters.account_scenario_type_id" />
            </div>
        </div>

        <div class="row" v-if="allowEventFilter">
            <div class="col-md-12">
                <Events :required="false"
                    v-model="filters.events" />
            </div>
        </div>
    </div>
</template>

<script>
    import Accounts from "src/app-components/form-elements/Accounts";
    import FilterPanelMixin from "src/app-components/FilterPanelMixin";
    import Channels from "src/app-components/form-elements/Channels";
    import Scenarios from "src/app-components/form-elements/AccountScenarios";
    import Events from "src/app-components/form-elements/Events";

    export default {
        components: {
            Accounts,
            Channels,
            Events,
            Scenarios
        },

        mixins: [
            FilterPanelMixin({
                accounts: [],
                account_scenario_type_id: null,
                channel_id: null,
                events: []
            })
        ],

        props: { 
            allowedScenariosFilter: {required: false},
            allowEventFilter: {required: false},
        },

        data() {
            return {
                options: []
            }
        },

        mounted(){
            this.filters.accounts = this.filters.accounts.filter(accountId => accountId !== this.$store.getters.appUser.selected_account_id);
            this.filters.accounts.push(this.$store.getters.appUser.selected_account_id);
        },

        methods: {
            onClear() {
                this.filters.account_scenario_type_id = null
            },

            prepareFilters (filters) {
                return {
                    "Campaign.account_id IN": this.filters.accounts,
                    "Campaign.channel_id": this.filters.channel_id,
                    "Campaign.account_scenario_type_id": this.filters.account_scenario_type_id,
                };
            },
        },
    };
</script>