<template>
    <div>
        <form @submit.prevent="validateBeforeSubmit">
            <blockquote class="bd-callout">
                <h4 class="mt-0">{{$t('MSISDN List')}}</h4>

                <label for>{{$t('Insert the MSISDNs to get the historical information.')}}</label>
                <div class="d-flex flex-row mb-3">
                    <input-tag
                        class="flex-grow-1"
                        :placeholder="$t('Press ENTER for each MSISDN to create a tag.')"
                        v-model="msisdns"
                        validate="digits"
                    ></input-tag>

                    <p-button
                        class="m-0 ml-3 align-self-start"
                        type="primary"
                        nativeType="submit"
                        :disabled="msisdns.length == 0"
                    >
                        <i class="fa fa-search"></i>
                        {{$t('Search')}}
                    </p-button>
                </div>
                <a href="javascript:void(0)" v-show="msisdns.length > 0" @click="msisdns = []" class="animated fadeIn">
                    <i class="fa fa-times" aria-hidden="true"></i> {{$t('Clear list')}}
                </a>
            </blockquote>
        </form>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div
                        class="card-body"
                        v-loading="loading"
                        :element-loading-text="$t('Loading')"
                    >
                        <h6>{{$t('History')}}</h6>
                        <CustomerServiceTable :tableData="historyTable" :selectedMsisdns="cloneMsisdns"></CustomerServiceTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionHeader from "src/app-components/form-elements/SectionHeader";
import { Input } from "element-ui";
import InputTag from "vue-input-tag";
import CustomerServiceTable from "src/app-components/customer-service/CustomerServiceTable";

import { cloneDeep } from "lodash";
import { mapActions } from "vuex";
import api from "src/api";

export default {
    components: {
        SectionHeader,
        CustomerServiceTable,
        InputTag,
        [Input.name]: Input,
    },

    data() {
        return {
            msisdns: [],
            cloneMsisdns: [],
            historyTable: [],
            loading: false,
        };
    },

    created() {
        this.setTopNavbarTitle(this.$t("Customer Service"));
    },

    watch: {
        historyTable: function (val) {
            if (val.length == 0) {
                this.$notify({
                    title: this.$t("No results found."),
                    icon: "fa fa-history",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "info",
                });
            }
        },
    },

    methods: {
        ...mapActions(["setTopNavbarTitle"]),

        validateBeforeSubmit() {
            this.loading = true;
            this.requestCustomerService();
            this.cloneMsisdns = cloneDeep(this.msisdns);
            this.loading = false;
        },

        async requestCustomerService() {
            try {
                const res = await api.getCustomerService(this.msisdns);
                this.historyTable = res.data;
            } catch (error) {
                this.$notify({
                    title: this.$t("An error has ocurred. Try again."),
                    icon: "fa fa-bomb",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "danger",
                });
            }
        },
    },
};
</script>

<style scoped>
:deep(.vue-input-tag-wrapper .input-tag) {
    background-color: var(--info);
    border-color: var(--info);
    color: var(--white);
}

:deep(.vue-input-tag-wrapper .input-tag .remove) {
    color: var(--white);
}
</style>