const OptionsType = {
  computed: {
    optionsType() {
      return [
        {
          id: "AUDIENCE_FROM_FILE",
          name: "Audience from file",
          group: "AUDIENCE",
        },
        {
          id: "ACTIVES_IN_CAMPAIGN",
          name: "Actives in campaign",
          group: "USER",
        },
        {
          id: "ACTIVES_IN_LAST_DAYS",
          name: "Actives in last days",
          group: "USER",
        },
        {
          id: "USER_QUALITY",
          name: "User quality",
          group: "USER",
        },
        {
          id: "ACTIVITY",
          name: "User activity",
          group: "USER",
        },
        {
          id: "AFFINITY",
          name: "User affinity",
          group: "USER",
        },
        {
          id: "SUBSCRIBED_TO_SERVICE",
          name: "Subscribed to service",
          group: "SUBSCRIPTIONS",
        },
        {
          id: "SUBSCRIPTION_COUNT",
          name: "Subscription count",
          group: "SUBSCRIPTIONS",
        },
        {
          id: "SENT_IN_LAST_DAYS",
          name: "Sent in last days",
          group: "EVENTS",
        },
        {
          id: "SENT_IN_CAMPAIGN",
          name: "Sent in campaign",
          group: "EVENTS",
        },
        {
          id: "SENT_PING_IN_CAMPAIGN",
          name: "Sent ping in campaign",
          group: "EVENTS",
        },
        {
          id: "DELIVERIES_IN_LAST_DAYS",
          name: "Deliveries in last days",
          group: "EVENTS",
        },
        {
          id: "DELIVERIES_IN_CURRENT_MONTH",
          name: "Deliveries in current month",
          group: "EVENTS",
        },
        {
          id: "DELIVERIES_IN_CAMPAIGN",
          name: "Deliveries in campaign",
          group: "EVENTS",
        },
        {
          id: "ACCEPTANCE_IN_LAST_DAYS",
          name: "Acceptance in last days",
          group: "EVENTS",
        },
        {
          id: "ACCEPTANCE_IN_CAMPAIGN",
          name: "Acceptance in campaign",
          group: "EVENTS",
        },
        {
          id: "REJECTED_IN_LAST_DAYS",
          name: "Reject in last days",
          group: "EVENTS",
        },
        {
          id: "REJECTED_IN_CAMPAIGN",
          name: "Reject in campaign",
          group: "EVENTS",
        },
      ];
    },
  },
};

export default OptionsType;
