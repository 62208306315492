<template>
    <fg-input :required="required" :error="error" :label="label">
        <el-date-picker
                :disabled="disabled"
                :value="value"
                type="datetime"
                @input="handleInput"
                :size="size"
                :picker-options="pickerOptions"
        >
        </el-date-picker>
    </fg-input>
</template>

<script>
    import {DatePicker} from 'element-ui';
    import moment from "moment";

    export default {
        components: {
            [DatePicker.name]: DatePicker,
        },
        props: {
            label: String,
            value: Date,
            error: String,
            size: {
                type : String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            disabledDates: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleInput (value) {
                this.$emit("input", value);
            }
        },

        computed: {
          pickerOptions() {
            return {
              disabledDate: (time) => {
                if (!this.disabledDates) return false;

                return (
                  moment(time.getTime()).add(1, "day") <
                  moment().tz(this.$store.getters.appUserTimezone)
                );
              },
            };
          }
        }
    }
</script>

<style>

</style>