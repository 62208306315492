<template>
  <div>
    <!-- TODO Eze: Borrar con refactor -->
    <div class="row" style="width: 100%">
      <div
        class="col-4 col-sm-4 col-md-4 col-lg-2"
        style="margin-left: auto"
      >
        <drop-down>
          <p-button
            slot="title"
            class="dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            type="primary"
            block
            round
          >
            {{ $t("Export As") }}
          </p-button>
          <a class="dropdown-item" @click.prevent="exportToExcel" href="#">
            <i class="fa fa-file-excel-o"></i> Excel
          </a>
        </drop-down>
      </div>
    </div>
    <el-table
      :data="tableData"
      class="convertions-table"
      @sort-change="sortChange"
      row-key="Pkey"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        label="Campaign"
        width="130"
        prop="campaign_name"
      ></el-table-column>

      <el-table-column :label="$t('Card')" width="130">
        <template slot-scope="scope">
          <span v-for="card in getInteractions(scope.row.interactions, 'card')">
          </br>
            {{ card ? card : 'Suggested Actions' }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('CTA')" width="130">
        <template slot-scope="scope">
          <span v-for="cta in getInteractions(scope.row.interactions, 'cta')">
          </br>
            {{ cta }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Type')" width="100">
        <template slot-scope="scope">
          <span v-for="type in getInteractions(scope.row.interactions, 'type')">
          </br>
            {{ type }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Clicks')" width="80">
        <template slot-scope="scope">
          <span v-for="totalClicks in getInteractions(scope.row.interactions, 'total_clicks')">
          </br>
            {{ totalClicks }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Clicks %')" width="80">
        <template slot-scope="scope">
          <span
            v-for="clcikRate in getInteractions(scope.row.interactions, 'click_rate')"
          >
          </br>
            {{ Math.round((clcikRate + Number.EPSILON) * 100) / 100 }}%
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Read')" width="80">
        <template slot-scope="scope">
          <span v-for="read in getInteractions(scope.row.interactions, 'read')">
          </br>
            {{ read }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import api from "src/api";
import Report from "./Report";
import moment from "moment"; // TODO Eze: Borrar con refactor 
import * as Export2Excel from "src/app-components/Export2Excel.js"; // Refactor
import { excelFormatPercentage, excelFormatNumber } from "./excel_formats.js";

export default {
  name: "ConvertionsReport",

  extends: Report,

  created() {
    this.customTableKeyCols = [{ prop: "Convertions", label: "Convertions" }];
  },

  methods: {
    getInteractions(value, prop) {
      const cta = value.map((x) => {
        return x[prop];
      });

      return cta;
    },
    /**
     * @Override
     */
    filterProp() {
      return "campaign_name";
    },
    /**
     * @Override
     */
    tableDimensions() {
      return ["id ASC"];
    },

    /**
     * @Override
     */
    tableRowClassName({ row, rowIndex }) {
      if (row.id === null) {
        return "success-row";
      }

      return "";
    },

    prepareFilter() {
      return {
        date_from: this.filters["campaign_start_datetime >="],
        date_to: this.filters["campaign_start_datetime <="],
        group_by: this.dimensions.map((dimension) => dimension.groupingProp).join(","),
      };
    },

    async getData(query) {
      const filters = this.prepareFilter();
      const response = await api.getConvertionsPivot(filters);
      this.tableData = response.data;
      this.tableData.ownTemplate = true;
    },
    
    createTablaExcel(idTable = 'table-excel') {
      // Creo la tabla y le seteo los estilos
      let table = document.createElement("table");
      table.setAttribute("id", idTable);

      // Creo los headers
      let trHeader = document.createElement("tr");
      this.th.forEach(dataTH => {
        let td = document.createElement("td");
        td.innerHTML = dataTH.text;
        trHeader.appendChild(td);
      });
      table.appendChild(trHeader);

      // Recorro formatTableData para armar el body
      this.formatTableData.forEach(data => {
        let tr = document.createElement("tr");
        this.th.forEach(dataTH => {
          let td = document.createElement("td");
          if(data[dataTH.prop] || data[dataTH.prop] === '') {
            td.innerHTML = data[dataTH.prop]
            if(dataTH.prop === 'campaign_name' && data.rowspan ) {
              td.setAttribute("rowspan", data.rowspan);
            }
            tr.appendChild(td)
          }
        });
        table.appendChild(tr);
      });

      document.body.appendChild(table);
    },

    async exportToExcel() {
      let tableId = 'table-excel';
      this.createTablaExcel(tableId);
      Export2Excel.export_table_to_excel(tableId, this.excelFileName);
      let table = document.getElementById('table-excel');
      document.body.removeChild(table);
    },
  },

  computed: {
    th() {
      return [
        { 
          text: 'CAMPAIGN',
          prop: 'campaign_name',
        },
        { 
          text: 'CARD',
          prop: 'card',
        },
        { 
          text: 'CTA',
          prop: 'cta',
        },
        { 
          text: 'TYPE',
          prop: 'type',
        },
        { 
          text: 'CLICKS',
          prop: 'total_clicks',
        },
        { 
          text: 'CLICKS %',
          prop: 'click_rate',
        },
        { 
          text: 'READ',
          prop: 'read',
        },
      ]
    },
    
    formatTableData() {
      let formatData = [];
      if(this.filteredData.length > 0) {
        this.filteredData.forEach(elem => {
          let obj = {};
          obj.campaign_name = elem.campaign_name;
          if(elem.interactions.length) {
            if(elem.interactions.length > 1) {
              obj.rowspan = elem.interactions.length;
              elem.interactions.forEach((interaction, key) => {
                obj.action_id = interaction.action_id ? interaction.action_id : '';
                obj.card = interaction.card ? interaction.card : 'Suggested Actions';
                obj.click_rate = interaction.click_rate ? `${interaction.click_rate}%` : '0%';
                obj.cta = interaction.cta ? interaction.cta : '';
                obj.read = interaction.read ? interaction.read : '0';
                obj.total_clicks = interaction.total_clicks ? interaction.total_clicks : '0';
                obj.type = interaction.type ? interaction.type : '';

                formatData.push(obj);
                obj = {};
              });
            }else {
              obj.action_id = elem.interactions[0].action_id ? elem.interactions[0].action_id : '';
              obj.card = elem.interactions[0].card ? elem.interactions[0].card : 'Suggested Actions';
              obj.click_rate = elem.interactions[0].click_rate ? `${elem.interactions[0].click_rate}%` : '0%';
              obj.cta = elem.interactions[0].cta ? elem.interactions[0].cta : '';
              obj.read = elem.interactions[0].read ? elem.interactions[0].read : '0';
              obj.total_clicks = elem.interactions[0].total_clicks ? elem.interactions[0].total_clicks : '0';
              obj.type = elem.interactions[0].type ? elem.interactions[0].type : '';
              
              formatData.push(obj);
              obj = {};
            }
          }
        });
      }
      return formatData;
    },

    excelFileName() {
      const date = moment().format("YYYYMMDD");
      const report = 'Rcs- Convertions';
      const account = this.$store.getters.appUser.account.name;
      return `${date}_${report}_${account}`;
    },
  }
};
</script>

<style>
.convertions-table td {
  border-bottom: 1px groove;
  border-color: #ffffff !important;
}
</style>