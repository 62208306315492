const transformVariables = (input) => {
    const variables = {};

    input.forEach(element => {
      const val = {
        value: element.value,
        variable_id: element.variable_id,
        status: element.status
      };

      if(typeof element.id !== 'undefined') {
        val.id = element.id;
      }

      // Only for creativities var
      /** 
       * ToDO, move to _creatives
      */
      if(typeof element.hash !== 'undefined') {
        val.hash = element.hash;
      }

      variables[element.variable.name] = val;
    });

    return variables
};

export { transformVariables }