<template>
    <vue-fab
        :style="style"
        :mainBtnColor="color"
        @clickMainBtn="$emit('click')"
        size="big"
        :scrollAutoHide="false"
        :hidden="false"
        :icon="icon"
    >
    </vue-fab>
</template>

<script>
    import Vue from "vue";
    import VueFab from "vue-float-action-button";
    Vue.use(VueFab);

    export default {
        name: "FloatingActionButton",
        components: {
        },
        props: {
            color: {
                type: String,
                default: "#FF0099"
            },
            icon: {
                type: String,
                default: "add"
            },
            position: {
                type: Number,
                default: 0
            }
        },
        data () {
            return {
                style: {
                    position: "fixed",
                    right: "70px",
                    bottom: (75 + this.position * 65) + "px",
                }
            }
        }
    }
</script>

<style>

</style>