<template>
    <div>
        <div class="d-flex align-items-center">
            <div>
                <h4 class="m-0 w-auto">{{$t('Submitted queries')}}</h4>
            </div>
            <div>
                
            </div>
            <div class="ml-3">
                <el-tooltip :content="$t('Filter queries')" :open-delay="150" placement="right">
                    <p-button
                        type="default"
                        size="sm"
                        @click="toggleFilters = !toggleFilters"
                        icon
                        round
                        outline
                    >
                        <i class="fa fa-filter" aria-hidden="true"></i>
                    </p-button>
                </el-tooltip>
            </div>
            <CrmPagination :tableData="filteredTableData" @paginated-table="onPagination($event)"></CrmPagination>
        </div>
        <!-- Filtros -->
        <CrmFilters v-show="toggleFilters" :tableData="tableData" @filteredrows="filteredTableData = $event"></CrmFilters>

        <!-- Tabla -->
        <el-table
            :data="paginatedTableData"
            class="w100"
            :default-sort="{prop: 'id', order: 'descending'}"
        >
            <el-table-column :label="$t('ID')" :width="fitColumnToContent('id', $t('ID'), 60)" sortable>
              <template slot-scope="scope">
                {{ scope.row.id | formatNumber }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Applicant')" :width="fitColumnToContent('user.username', $t('Applicant'), 60)" prop="user.username"></el-table-column>
            <el-table-column :label="$t('Query')" prop="user_query" style="width: 100%">
                <template slot-scope="scope">
                    <ul>
                        <li v-if="scope.row.user_query.date_from && scope.row.user_query.date_to">
                            <b>{{$t('From')}}:</b>
                            {{formatDate(scope.row.user_query.date_from)}} -
                            <b>{{$t('To')}}:</b>
                            {{formatDate(scope.row.user_query.date_to)}}
                        </li>
                        <li v-if="scope.row.user_query.events && scope.row.user_query.events.length > 0">
                            <b>{{$t('Events')}}:</b>
                            <span class="badge tiny salmon badge-outline break-word"
                                v-for="e in scope.row.user_query.events"
                                :key="e.id"
                            >{{ getObjectByValue(events, e).name }}</span>
                        </li>
                        <li v-if="scope.row.user_query.status && scope.row.user_query.status.length > 0">
                            <b>{{$t('Event status')}}:</b>
                            <span
                                class="badge tiny default badge-outline"
                                v-for="s in scope.row.user_query.status"
                                :key="s.id"
                            >{{ $t(getObjectByValue(eventStatus, s).name) }}</span>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Query status')" :width="fitColumnToContent('status', $t('Query status'), 60)" prop="status">
                <template slot-scope="scope">
                    <span
                        class="badge w-75" :class="scope.row.status.toLowerCase()"
                    >{{$t(scope.row.status)}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('Total MSISDNs')" :width="fitColumnToContent('_count', $t('Total MSISDNs'), 60)">
              <template slot-scope="scope">
                {{ scope.row["_count"] | formatNumber }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Actions')" width="100" align="right">
                <template slot-scope="scope">
                    <p-button
                        v-if="scope.row._count > 0"
                        type="primary"
                        size="sm"
                        @click="handleDownload(scope.row)"
                        :title="$t('Download file')"
                        round icon
                    >
                        <i class="fa fa-2x fa-download" aria-hidden="true"></i>
                    </p-button>
                    <p-button
                        type="default"
                        size="sm"
                        :title="$t('Copy query')"
                        @click="copyQuery(scope.row.user_query, scope.row.id)"
                        round icon
                    >
                        <i class="fa fa-2x fa-copy" aria-hidden="true"></i>
                    </p-button>
                    
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { Table, TableColumn, Input } from "element-ui";
import CrmFilters from 'src/app-components/crm/events/CrmFilters';
import CrmPagination from "../CrmPagination.vue"

import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";
import FileDownload from 'js-file-download';
import moment from 'moment';
import api from "src/api";

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Input.name]: Input,
        CrmFilters,
        CrmPagination
    },

    props: {
        tableData: {
            type: Array,
            default: [],
        },
        events: {
            type: Array,
            default: () => [],
        },
        eventStatus: {
            type: Array,
            default: [],
        },
        pagination : {
            type: Object,
            // required: false,
            default: () => ({
                currentPage: 1,
                itemsPerPage: 10,
            })
        }
    },

    mixins: [
      FitColumnToContentMixin({ tableDataProp: "tableData" })
    ],

    data() {
        const queryStatus = [ 'Pending','In-Progress','Done','Failed'];

        return {
            queryStatus,
            search: {
                byID: "",
                byUsername: "",
                byStatus: ""
            },
            filteredTableData : [],
            paginatedTableData : [],
            toggleFilters: false
        };
    },

    methods: {

        async handleDownload(job) {
            this.working = true;
            const file = await api.downloadFile(`/crm/events/query/${job.id}/download`);
            await FileDownload(
                file,
                job.filename.substring(job.filename.lastIndexOf('/')+1)
            )
        },

        copyQuery(query, id) {
            this.$emit("copyquery", { id, user_query: query });
        },

        getObjectByValue(oArray, value) {
            // oArray debe ser un arreglo de objetos
            // value es el valor a buscar en alguno de los atributos del objeto deseado
            return oArray.find((obj, index) => {
                if (obj.id === value) return true;
            });
        },

        onPagination(val){
            this.paginatedTableData = val
        },

        formatDate(date){
            return date != null ? moment(date).format('YYYY-MM-DD') : "-"
        },
    },
};
</script>