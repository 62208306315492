<template>
  <div class="d-md-flex">
    <div class="template-form card p-4 w-100">
      <el-steps :space="10" :active="stepActive" simple>
        <el-step :status="stepStatus(1)" :title="$t('Configuration')"></el-step>
        <el-step :status="stepStatus(2)" :title="$t('Content')"></el-step>
      </el-steps>
      <div v-show="stepActive === 1" style="min-height: 350px">
        <div>
          <h2>{{ $t("Category") }}</h2>
          <p class="label">
            {{ $t("Choose a category that describes the text, media, and buttons you'll send.") }}
          </p>
          <el-select v-model="template.category" class="w-100" size="small" placeholder="Choose a category">
            <el-option v-for="option in optionsCategory" :key="option" :value="option"> </el-option>
          </el-select>
        </div>
        <div class="my-3">
          <h2>{{ $t("Name") }}</h2>
          <p class="label">{{ $t("Name the message template") }}</p>
          <el-input size="small" show-word-limit maxlength="512" v-model="template.name" @input="updateNameTemplate"></el-input>
        </div>
        <div class="my-3">
          <h2>{{ $t("Languages") }}</h2>
          <p class="label">
            {{ $t("Choose languages for your message template. You can remove or add languages later.") }}
          </p>
          <el-select v-model="template.language" class="w-100" size="small" filterable placeholder="Choose a language">
            <el-option
              v-for="option in optionsLanguage"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <h2>{{ $t("Service") }}</h2>
          <p class="label">
            {{ $t("Choose the service for which the template will be created.") }}
          </p>
          <el-select v-model="template.service_id" class="w-100" size="small" placeholder="Choose a service">
            <el-option v-for="option in channelServices" :key="option.id" :value="option.id" :label="option.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-show="stepActive === 2" style="min-height: 350px">
        <div>
          <h2>{{ $t("Header") }}<span v-if="template.channel_id != 7"> ({{ $t("optional") }})</span></h2>
          <p class="label">
            {{ $t("Add a title or choose what type of content you'll use for this header.") }}
          </p>
          <div class="d-flex">
            <el-select v-model="template.header_format" class="w-25 mr-2" size="small" clearable>
              <el-option
                v-for="option in optionsFormatHeader"
                :key="option"
                :value="option"
                :label="$t(option)"
              ></el-option>
            </el-select>
            <div class="w-100" v-if="template.header_format">
              <el-input
                v-if="template.header_format.toLowerCase() === 'text'"
                size="small"
                v-model="template.header_value"
                :placeholder="$t('Text in selected language')"
                show-word-limit
                maxlength="60"
              ></el-input>
              <el-input
                v-else
                size="small"
                class="w-100"
                v-model="template.header_value"
                :placeholder="`${template.header_format} link`"
              ></el-input>
            </div>
          </div>
        </div>

        <div class="my-3">
          <h2>{{ $t("Body") }}</h2>
          <p class="label">
            {{ $t("Enter the text of your message in the language you have selected.") }}
          </p>

          <el-input
            type="textarea"
            size="small"
            resize="none"
            show-word-limit
            maxlength="1024"
            :autosize="true"
            v-model="template.body"
            :placeholder="$t('Text in selected language')"
          ></el-input>
          <div class="w-100 d-flex justify-content-end mt-1" style="margin-bottom: -20px">
            <picker-emoji @isertEmoji="isertEmoji($event)" />
          </div>
        </div>

        <div class="my-3" v-if="template.channel_id != 7">
          <h2>{{ $t("Footer (optional)") }}</h2>
          <p class="label">
            {{ $t("Add a short line of text at the bottom of your message template.") }}
          </p>

          <el-input
            show-word-limit
            maxlength="70"
            v-model="template.footer"
            :placeholder="$t('Text in selected language')"
            size="small"
          ></el-input>
        </div>

        <div>
          <h2>{{ $t("Buttons (optional)") }}</h2>
          <el-select
            v-model="template.typeButtons"
            class="w-50"
            size="small"
            clearable
            :placeholder="$t('Button type')"
          >
            <el-option
              v-for="option in optionsTypeButtons"
              :key="option.value"
              :value="option.value"
              :label="$t(option.label)"
            ></el-option>
          </el-select>
        </div>

        <div v-if="template.typeButtons" class="mt-3">
          <div
            class="d-flex align-items-start btn-config p-2 mb-2"
            v-for="(button, key) in template.buttons"
            :key="key"
          >
            <div v-if="template.typeButtons === 'CALL_TO_ACTION'" class="mr-1" style="min-width: 140px">
              <p class="label mb-0 font-weight-bold">{{ $t("Action") }}</p>
              <el-select clearable class="w-100" size="small" v-model="template.buttons[key].type">
                <el-option
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                  v-for="opt in actionButtons"
                ></el-option>
              </el-select>
            </div>
            <div class="w-100 mr-1">
              <p class="label mb-0 font-weight-bold">{{ $t("Text") }}</p>
              <el-input size="small" show-word-limit maxlength="35" v-model="template.buttons[key].text"></el-input>
            </div>
            <div v-if="template.typeButtons === 'CALL_TO_ACTION'" class="mr-1" style="min-width: 125px">
              <p class="label mb-0 font-weight-bold" v-show="template.buttons[key].type">
                {{ $t(template.buttons[key].type === "PHONE_NUMBER" ? "Country" : "Url type") }}
              </p>
              <el-select
                clearable
                class="w-100"
                size="small"
                filterable
                v-show="template.buttons[key].type"
                v-if="template.buttons[key].type === 'PHONE_NUMBER'"
                v-model="template.buttons[key].country"
              >
                <el-option
                  v-for="option in countryNumbers"
                  :key="option.value"
                  :value="option.value"
                  :label="option.label"
                ></el-option>
              </el-select>
              <el-select
                clearable
                class="w-100"
                size="small"
                v-else
                v-model="template.buttons[key].typeUrl"
                v-show="template.buttons[key].type"
              >
                <el-option value="static" label="Static"></el-option>
                <el-option value="dynamic" label="Dynamic"></el-option>
              </el-select>
            </div>
            <div v-if="template.typeButtons === 'CALL_TO_ACTION'" class="w-100 mr-1">
              <p class="label mb-0 font-weight-bold" v-show="template.buttons[key].type">
                {{ $t(template.buttons[key].type === "PHONE_NUMBER" ? "Phone number" : "Url") }}
              </p>
              <el-input
                size="small"
                show-word-limit
                maxlength="20"
                v-model="template.buttons[key].phone_number"
                v-show="template.buttons[key].type"
                @input="validNumber(template.buttons[key].phone_number, key)"
                v-if="template.buttons[key].type === 'PHONE_NUMBER'"
              ></el-input>
              <el-input
                size="small"
                show-word-limit
                maxlength="2000"
                v-else
                v-show="template.buttons[key].type"
                v-model="template.buttons[key].url"
              ></el-input>
            </div>

            <el-button type="text" class="btn-delete p-0" @click="deleteButton(key)">X</el-button>
          </div>

          <el-button size="small" @click="addButton" :disabled="addButtonsDisabled">
            {{ $t("Add button") }}
          </el-button>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <el-button size="small" @click="cancel" :disabled="loading">
          {{ $t("Cancel") }}
        </el-button>

        <el-button size="small" @click="stepActive--" :disabled="loading" v-if="stepActive > 1">
          {{ $t("Back") }}
        </el-button>
        <el-button size="small" @click="stepActive++" v-if="stepActive < 2" :disabled="!validateStep" type="primary">
          {{ $t("Continue") }}
        </el-button>

        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-if="stepActive === 2"
          :disabled="!validateStep || loading"
        >
          <i class="el-icon-loading" v-if="loading"></i>
          <span v-else>{{ $t("Save") }}</span>
        </el-button>
      </div>
    </div>

    <div class="card template-view p-3">
      <view-template :template="template" />
      <!-- <view-template :template="formattedTemplate" /> -->
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  import api from "src/api";
  import { Input, Button, Select, Step, Steps } from "element-ui";

  import ViewTemplate from "src/app-components/broadcast/ViewTemplate";
  import PickerEmoji from "src/app-components/chatbot/elements/PickerEmoji";
  export default {
    name: "BroadcastTemplateForm",

    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Step.name]: Step,
      [Steps.name]: Steps,

      "view-template": ViewTemplate,
      "picker-emoji": PickerEmoji,
    },

    data() {
      return {
        loading: false,

        stepActive: 1,

        services: [],

        template: {
          name: "",
          header_format: "",
          header_value: "",
          body: "",
          footer: "",

          buttons: [],
          typeButtons: "",
          category: "",
          language: "",
          status: 2, // 1.Pending - 2.Approved - 3.Rejected
          channel_id: 7,
          service_id: "",
        },
      };
    },

    watch: {
      "template.header_format"() {
        this.template.header_value = "";
      },

      "template.typeButtons"() {
        this.template.buttons = [];
      },
    },

    created() {
      if (!this.$can("Views", "Broadcast")) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle(this.$t("Create template"));
      this.getServices();
    },

    mounted() {
      this.setChannel()
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      setChannel() {
        /* if (this.$route.name === "BroadcastTemplatesFormWPPCreate") {
          this.template.channel_id = 8;
          this.template.status = 1;
        } */

        switch(this.$route.name) {
          case 'BroadcastTemplatesFormWPPCreate':
            this.template.channel_id = 8;
            this.template.status = 1;
            break;
            case 'BroadcastTemplatesFormGupshupCreate':
            this.template.channel_id = 10;
            this.template.status = 1;
            break;
        }
      },

      cancel() {
        this.$router.push({ name: "BroadcastTemplatesIndex" });
      },

      isertEmoji(emoji) {
        this.template.body += emoji;
      },

      stepStatus(key) {
        let res = "wait";
        if (key === this.stepActive) return "process";
        if (key < this.stepActive) return "success";

        return res;
      },

      addButton() {
        if (this.addButtonsDisabled) return;
        let button = { text: "", type: "QUICK_REPLY" };

        if (this.template.typeButtons === "CALL_TO_ACTION") {
          button.type = "";
          button.country = "";
          button.phone_number = "";
          button.typeUrl = "";
          button.url = "";
        }

        this.template.buttons.push(button);
      },

      deleteButton(key) {
        if (this.template.buttons[key]) {
          this.template.buttons.splice(key, 1);
        }
      },

      handleSave() {
        console.log("save", this.template);
        this.createChatbotTemplate();
      },

      validNumber(number, key) {
        let res = [];
        number.split("").forEach((item) => {
          if (!isNaN(item)) res.push(item);
        });
        this.template.buttons[key].phone_number = res.join("");
      },

      updateNameTemplate() {
        this.template.name = this.template.name.toLowerCase();
        this.template.name = this.template.name.replaceAll(' ', '_');
      },

      async createChatbotTemplate() {
        this.loading = true;
        let res = await api.createChatbotTemplate(this.template);
        /* if(this.template.channel_id == 8) {
          res = JSON.parse(res.data)
        } */
        try {
          res = JSON.parse(res.data)
        } catch (error) {}


        if(res.status) {
          this.$notify({
            message: "Template created successfully",
            type: "success",
          });
          this.cancel();
        }else {
          this.$notify({
              title: this.$t("An error has ocurred. Try again."),
              icon: "fa fa-bomb",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
          });
        }

        this.loading = false;
      },

      async getServices() {
        let res = await api.getServices();
        if (res.status) {
          this.services = [...res.data];
        }
      },
    },

    computed: {
      channelServices() {
        return this.services.filter((s) => s.channel_id === this.template.channel_id);
      },

      steps() {
        return [
          {
            title: "Configuration",
            icon: "el-icon-setting",
          },
          {
            title: "Content",
            icon: "el-icon-upload",
          },
        ];
      },

      validateStep() {
        if (this.stepActive === 1) {
          if (!this.template.name || !this.template.category || !this.template.language || !this.template.service_id) {
            return false;
          }
        } else {
          if (this.template.body === "") return false;
          if(this.template.channel_id == 7) {
            if (this.template.header_format === "" || this.template.header_value === "") return false;
          }else {
            if (this.template.header_format) {
              if (this.template.header_value === "") return false;
            }
          }

          if (this.template.typeButtons) {
            var invalidButtons = false;
            this.template.buttons.forEach((btn) => {
              if (!btn.text) {
                invalidButtons = true;
                return;
              }
              if (this.template.typeButtons === "CALL_TO_ACTION") {
                if (!btn.type) {
                  invalidButtons = true;
                  return;
                }

                if (btn.type === "PHONE_NUMBER") {
                  if (!btn.country || !btn.phone_number) {
                    invalidButtons = true;
                    return;
                  }
                } else {
                  if (!btn.typeUrl || !btn.url) {
                    invalidButtons = true;
                    return;
                  }
                }
              }
            });

            if (invalidButtons) return false;
          }
        }

        return true;
      },

      optionsCategory() {
        return ["Marketing", "Service", "Authentication"];
      },

      optionsLanguage() {
        return [
          {
            label: "Afrikaans",
            value: "af",
          },
          {
            label: "Albanian",
            value: "sq",
          },
          {
            label: "Arabic",
            value: "ar",
          },
          {
            label: "Azerbaijani",
            value: "az",
          },
          {
            label: "Bengali",
            value: "bn",
          },
          {
            label: "Bulgarian",
            value: "bg",
          },
          {
            label: "Catalan",
            value: "ca",
          },
          {
            label: "Chinese (CHN)",
            value: "zh_CN",
          },
          {
            label: "Chinese (HKG)",
            value: "zh_HK",
          },
          {
            label: "Chinese (TAI)",
            value: "zh_TW",
          },
          {
            label: "Croatian",
            value: "hr",
          },
          {
            label: "Czech",
            value: "cs",
          },
          {
            label: "Danish",
            value: "da",
          },
          {
            label: "Dutch",
            value: "nl",
          },
          {
            label: "English",
            value: "en",
          },
          {
            label: "English (UK)",
            value: "en_GB",
          },
          {
            label: "English (US)",
            value: "en_US",
          },
          {
            label: "Estonian",
            value: "et",
          },
          {
            label: "Filipino",
            value: "fil",
          },
          {
            label: "Finnish",
            value: "fi",
          },
          {
            label: "French",
            value: "fr",
          },
          {
            label: "German",
            value: "de",
          },
          {
            label: "Greek",
            value: "el",
          },
          {
            label: "Gujarati",
            value: "gu",
          },
          {
            label: "Hausa",
            value: "ha",
          },
          {
            label: "Hebrew",
            value: "he",
          },
          {
            label: "Hindi",
            value: "hi",
          },
          {
            label: "Hungarian",
            value: "hu",
          },
          {
            label: "Indonesian",
            value: "id",
          },
          {
            label: "Irish",
            value: "ga",
          },
          {
            label: "Italian",
            value: "it",
          },
          {
            label: "Japanese",
            value: "ja",
          },
          {
            label: "Kannada",
            value: "kn",
          },
          {
            label: "Kazakh",
            value: "kk",
          },
          {
            label: "Korean",
            value: "ko",
          },
          {
            label: "Lao",
            value: "lo",
          },
          {
            label: "Latvian",
            value: "lv",
          },
          {
            label: "Lithuanian",
            value: "lt",
          },
          {
            label: "Macedonian",
            value: "mk",
          },
          {
            label: "Malay",
            value: "ms",
          },
          {
            label: "Malayalam",
            value: "ml",
          },
          {
            label: "Marathi",
            value: "mr",
          },
          {
            label: "Norwegian",
            value: "nb",
          },
          {
            label: "Persian",
            value: "fa",
          },
          {
            label: "Polish",
            value: "pl",
          },
          {
            label: "Portuguese (BR)",
            value: "pt_BR",
          },
          {
            label: "Portuguese (POR)",
            value: "pt_PT",
          },
          {
            label: "Punjabi",
            value: "pa",
          },
          {
            label: "Romanian",
            value: "ro",
          },
          {
            label: "Russian",
            value: "ru",
          },
          {
            label: "Serbian",
            value: "sr",
          },
          {
            label: "Slovak",
            value: "sk",
          },
          {
            label: "Slovenian",
            value: "sl",
          },
          {
            label: "Spanish",
            value: "es",
          },
          {
            label: "Spanish (ARG)",
            value: "es_AR",
          },
          {
            label: "Spanish (SPA)",
            value: "es_ES",
          },
          {
            label: "Spanish (MEX)",
            value: "es_MX",
          },
          {
            label: "Swahili",
            value: "sw",
          },
          {
            label: "Swedish",
            value: "sv",
          },
          {
            label: "Tamil",
            value: "ta",
          },
          {
            label: "Telugu",
            value: "te",
          },
          {
            label: "Thai",
            value: "th",
          },
          {
            label: "Turkish",
            value: "tr",
          },
          {
            label: "Ukrainian",
            value: "uk",
          },
          {
            label: "Urdu",
            value: "ur",
          },
          {
            label: "Uzbek",
            value: "uz",
          },
          {
            label: "Vietnamese",
            value: "vi",
          },
          {
            label: "Zulu",
            value: "zu",
          },
        ];
      },

      optionsFormatHeader() {
        if(this.template.channel_id === 7) {
          return ["text", "image"];
        }
        return ["text", "image", "video", "file"];
      },

      optionsTypeButtons() {
        return [
          {
            label: "Call to action",
            value: "CALL_TO_ACTION",
          },
          {
            label: "Quick reply",
            value: "QUICK_REPLY",
          },
        ];
      },

      addButtonsDisabled() {
        let maxlength = this.template.typeButtons === "CALL_TO_ACTION" ? 2 : 3;
        return this.template.buttons.length >= maxlength;
      },

      actionButtons() {
        let res = [
          {
            value: "PHONE_NUMBER",
            label: "Call",
          },
          {
            value: "URL",
            label: "Go to website",
          },
        ];

        this.template.buttons.forEach((btn) => {
          let index = res.findIndex((opt) => opt.value === btn.type);

          if (index !== -1) {
            res.splice(index, 1);
          }
        });

        return res;
      },

      countryNumbers() {
        return [
          {
            label: "Afganistán	(+93)",
            value: "+93",
          },
          {
            label: "Albania	(+355)",
            value: "+355",
          },
          {
            label: "Alemania	(+49)",
            value: "+49",
          },
          {
            label: "Andorra	(+376)",
            value: "+376",
          },
          {
            label: "Angola	(+244)",
            value: "+244",
          },
          {
            label: "Antillas Neerlandesas	(+599)",
            value: "+599",
          },
          {
            label: "Argelia	(+213)",
            value: "+213",
          },
          {
            label: "Argentina	(+54)",
            value: "+54",
          },
          {
            label: "Armenia	(+374)",
            value: "+374",
          },
          {
            label: "Aruba	(+297)",
            value: "+297",
          },
          {
            label: "Ascensión	(+247)",
            value: "+247",
          },
          {
            label: "Australia	(+61)",
            value: "+61",
          },
          {
            label: "Austria	(+43)",
            value: "+43",
          },
          {
            label: "Azerbaiyán	(+994)",
            value: "+994",
          },
          {
            label: "Bahrein	(+973)",
            value: "+973",
          },
          {
            label: "Bangladesh	(+880)",
            value: "+880",
          },
          {
            label: "Bélgica	(+32)",
            value: "+32",
          },
          {
            label: "Belice	(+501)",
            value: "+501",
          },
          {
            label: "Benín	(+229)",
            value: "+229",
          },
          {
            label: "Bielorrusia	(+375)",
            value: "+375",
          },
          {
            label: "Bolivia	(+591)",
            value: "+591",
          },
          {
            label: "Bosnia y Herzegovina	(+387)",
            value: "+387",
          },
          {
            label: "Botsuana	(+267)",
            value: "+267",
          },
          {
            label: "Brasil	(+55)",
            value: "+55",
          },
          {
            label: "Brunei	(+673)",
            value: "+673",
          },
          {
            label: "Bulgaria	(+359)",
            value: "+359",
          },
          {
            label: "Burkina Faso	(+226)",
            value: "+226",
          },
          {
            label: "Burundi	(+257)",
            value: "+257",
          },
          {
            label: "Bután	(+975)",
            value: "+975",
          },
          {
            label: "Cabo Verde	(+238)",
            value: "+238",
          },
          {
            label: "Camboya	(+855)",
            value: "+855",
          },
          {
            label: "Camerún	(+237)",
            value: "+237",
          },
          {
            label: "Canadá	(+1)",
            value: "+1",
          },
          {
            label: "Chad	(+235)",
            value: "+235",
          },
          {
            label: "Chile	(+56)",
            value: "+56",
          },
          {
            label: "China	(+86)",
            value: "+86",
          },
          {
            label: "Chipre	(+357)",
            value: "+357",
          },
          {
            label: "Colombia	(+57)",
            value: "+57",
          },
          {
            label: "Comoras	(+269)",
            value: "+269",
          },
          {
            label: "Corea del Norte	(+850)",
            value: "+850",
          },
          {
            label: "Corea del Sur	(+82)",
            value: "+82",
          },
          {
            label: "Costa de Marfil	(+225)",
            value: "+225",
          },
          {
            label: "Costa Rica	(+506)",
            value: "+506",
          },
          {
            label: "Croacia	(+385)",
            value: "+385",
          },
          {
            label: "Cuba	(+53)",
            value: "+53",
          },
          {
            label: "Departamentos y territorios franceses en el Océano Índico	(+262)",
            value: "+262",
          },
          {
            label: "Diego García	(+246)",
            value: "+246",
          },
          {
            label: "Dinamarca	(+45)",
            value: "+45",
          },
          {
            label: "Ecuador	(+593)",
            value: "+593",
          },
          {
            label: "Egipto	(+20)",
            value: "+20",
          },
          {
            label: "El Salvador	(+503)",
            value: "+503",
          },
          {
            label: "Emiratos Árabes Unidos	(+971)",
            value: "+971",
          },
          {
            label: "Eritrea	(+291)",
            value: "+291",
          },
          {
            label: "Eslovaquia	(+421)",
            value: "+421",
          },
          {
            label: "Eslovenia	(+386)",
            value: "+386",
          },
          {
            label: "España	(+34)",
            value: "+34",
          },
          {
            label: "Estados Federados de Micronesia	(+691)",
            value: "+691",
          },
          {
            label: "Estados Unidos	(+1)",
            value: "+1",
          },
          {
            label: "Estonia	(+372)",
            value: "+372",
          },
          {
            label: "Etiopía	(+251)",
            value: "+251",
          },
          {
            label: "Filipinas	(+63)",
            value: "+63",
          },
          {
            label: "Finlandia	(+358)",
            value: "+358",
          },
          {
            label: "Fiyi	(+679)",
            value: "+679",
          },
          {
            label: "Francia	(+33)",
            value: "+33",
          },
          {
            label: "Gabón	(+241)",
            value: "+241",
          },
          {
            label: "Gambia	(+220)",
            value: "+220",
          },
          {
            label: "Georgia	(+995)",
            value: "+995",
          },
          {
            label: "Ghana	(+233)",
            value: "+233",
          },
          {
            label: "Gibraltar	(+350)",
            value: "+350",
          },
          {
            label: "Grecia	(+30)",
            value: "+30",
          },
          {
            label: "Groenlandia	(+299)",
            value: "+299",
          },
          {
            label: "Guadalupe	(+590)",
            value: "+590",
          },
          {
            label: "Guatemala	(+502)",
            value: "+502",
          },
          {
            label: "Guayana Francesa	(+594)",
            value: "+594",
          },
          {
            label: "Guinea Ecuatorial	(+240)",
            value: "+240",
          },
          {
            label: "Guinea	(+224)",
            value: "+224",
          },
          {
            label: "Guinea-Bissau	(+245)",
            value: "+245",
          },
          {
            label: "Guyana	(+592)",
            value: "+592",
          },
          {
            label: "Haití	(+509)",
            value: "+509",
          },
          {
            label: "Honduras	(+504)",
            value: "+504",
          },
          {
            label: "Hong Kong	(+852)",
            value: "+852",
          },
          {
            label: "Hungría	(+36)",
            value: "+36",
          },
          {
            label: "India	(+91)",
            value: "+91",
          },
          {
            label: "Indonesia	(+62)",
            value: "+62",
          },
          {
            label: "Irak	(+964)",
            value: "+964",
          },
          {
            label: "Irán	(+98)",
            value: "+98",
          },
          {
            label: "Irlanda	(+353)",
            value: "+353",
          },
          {
            label: "Islandia	(+354)",
            value: "+354",
          },
          {
            label: "Islas Cook	(+682)",
            value: "+682",
          },
          {
            label: "Islas Feroe	(+298)",
            value: "+298",
          },
          {
            label: "Islas Malvinas (Falkland Islands)	(+500)",
            value: "+500",
          },
          {
            label: "Islas Marshall	(+692)",
            value: "+692",
          },
          {
            label: "Islas Salomón	(+677)",
            value: "+677",
          },
          {
            label: "Israel	(+972)",
            value: "+972",
          },
          {
            label: "Italia	(+39)",
            value: "+39",
          },
          {
            label: "Japón	(+81)",
            value: "+81",
          },
          {
            label: "Jordania	(+962)",
            value: "+962",
          },
          {
            label: "Kazajstán	(+7)",
            value: "+7",
          },
          {
            label: "Kenia	(+254)",
            value: "+254",
          },
          {
            label: "Kirguistán	(+996)",
            value: "+996",
          },
          {
            label: "Kiribati	(+686)",
            value: "+686",
          },
          {
            label: "Kuwait	(+965)",
            value: "+965",
          },
          {
            label: "Laos	(+856)",
            value: "+856",
          },
          {
            label: "Lesotho	(+266)",
            value: "+266",
          },
          {
            label: "Letonia	(+371)",
            value: "+371",
          },
          {
            label: "Líbano	(+961)",
            value: "+961",
          },
          {
            label: "Liberia	(+231)",
            value: "+231",
          },
          {
            label: "Libia	(+218)",
            value: "+218",
          },
          {
            label: "Liechtenstein	(+423)",
            value: "+423",
          },
          {
            label: "Lituania	(+370)",
            value: "+370",
          },
          {
            label: "Luxemburgo	(+352)",
            value: "+352",
          },
          {
            label: "Macao	(+853)",
            value: "+853",
          },
          {
            label: "Macedonia	(+389)",
            value: "+389",
          },
          {
            label: "Madagascar	(+261)",
            value: "+261",
          },
          {
            label: "Malasia	(+60)",
            value: "+60",
          },
          {
            label: "Malawi	(+265)",
            value: "+265",
          },
          {
            label: "Maldivas	(+960)",
            value: "+960",
          },
          {
            label: "Malí	(+223)",
            value: "+223",
          },
          {
            label: "Malta	(+356)",
            value: "+356",
          },
          {
            label: "Marruecos	(+212)",
            value: "+212",
          },
          {
            label: "Martinica	(+596)",
            value: "+596",
          },
          {
            label: "Mauricio	(+230)",
            value: "+230",
          },
          {
            label: "Mauritania	(+222)",
            value: "+222",
          },
          {
            label: "México	(+52)",
            value: "+52",
          },
          {
            label: "Moldavia	(+373)",
            value: "+373",
          },
          {
            label: "Mónaco	(+377)",
            value: "+377",
          },
          {
            label: "Mongolia	(+976)",
            value: "+976",
          },
          {
            label: "Montenegro	(+382)",
            value: "+382",
          },
          {
            label: "Mozambique	(+258)",
            value: "+258",
          },
          {
            label: "Namibia	(+264)",
            value: "+264",
          },
          {
            label: "Nauru	(+674)",
            value: "+674",
          },
          {
            label: "Nepal	(+977)",
            value: "+977",
          },
          {
            label: "Nicaragua	(+505)",
            value: "+505",
          },
          {
            label: "Níger	(+227)",
            value: "+227",
          },
          {
            label: "Nigeria	(+234)",
            value: "+234",
          },
          {
            label: "Niue	(+683)",
            value: "+683",
          },
          {
            label: "Noruega	(+47)",
            value: "+47",
          },
          {
            label: "Nueva Caledonia	(+687)",
            value: "+687",
          },
          {
            label: "Nueva Zelanda	(+64)",
            value: "+64",
          },
          {
            label: "Omán	(+968)",
            value: "+968",
          },
          {
            label: "Países Bajos	(+31)",
            value: "+31",
          },
          {
            label: "Pakistán	(+92)",
            value: "+92",
          },
          {
            label: "Palau	(+680)",
            value: "+680",
          },
          {
            label: "Panamá	(+507)",
            value: "+507",
          },
          {
            label: "Papúa Nueva Guinea	(+675)",
            value: "+675",
          },
          {
            label: "Paraguay	(+595)",
            value: "+595",
          },
          {
            label: "Perú	(+51)",
            value: "+51",
          },
          {
            label: "Polinesia Francesa	(+689)",
            value: "+689",
          },
          {
            label: "Polonia	(+48)",
            value: "+48",
          },
          {
            label: "Portugal	(+351)",
            value: "+351",
          },
          {
            label: "Qatar	(+974)",
            value: "+974",
          },
          {
            label: "Reino Unido	(+44)",
            value: "+44",
          },
          {
            label: "República Centroafricana	(+236)",
            value: "+236",
          },
          {
            label: "República Checa	(+420)",
            value: "+420",
          },
          {
            label: "República del Congo	(+242)",
            value: "+242",
          },
          {
            label: "República Democrática del Congo	(+243)",
            value: "+243",
          },
          {
            label: "Ruanda	(+250)",
            value: "+250",
          },
          {
            label: "Rumanía	(+40)",
            value: "+40",
          },
          {
            label: "Rusia	(+7)",
            value: "+7",
          },
          {
            label: "Sahara Occidental	(+212)",
            value: "+212",
          },
          {
            label: "Samoa	(+685)",
            value: "+685",
          },
          {
            label: "San Marino	(+378)",
            value: "+378",
          },
          {
            label: "San Pedro y Miquelón	(+508)",
            value: "+508",
          },
          {
            label: "San Vicente y las Granadinas	(+966)",
            value: "+966",
          },
          {
            label: "Santa Helena	(+290)",
            value: "+290",
          },
          {
            label: "Santo Tomé y Príncipe	(+239)",
            value: "+239",
          },
          {
            label: "Senegal	(+221)",
            value: "+221",
          },
          {
            label: "Serbia	(+381)",
            value: "+381",
          },
          {
            label: "Seychelles	(+248)",
            value: "+248",
          },
          {
            label: "Sierra Leona	(+232)",
            value: "+232",
          },
          {
            label: "Singapur	(+65)",
            value: "+65",
          },
          {
            label: "Siria	(+963)",
            value: "+963",
          },
          {
            label: "Somalia	(+252)",
            value: "+252",
          },
          {
            label: "Sri Lanka	(+94)",
            value: "+94",
          },
          {
            label: "Suazilandia	(+268)",
            value: "+268",
          },
          {
            label: "Sudáfrica	(+27)",
            value: "+27",
          },
          {
            label: "Sudán	(+249)",
            value: "+249",
          },
          {
            label: "Suecia	(+46)",
            value: "+46",
          },
          {
            label: "Suiza	(+41)",
            value: "+41",
          },
          {
            label: "Surinam	(+597)",
            value: "+597",
          },
          {
            label: "Tailandia	(+66)",
            value: "+66",
          },
          {
            label: "Taiwán	(+886)",
            value: "+886",
          },
          {
            label: "Tanzania	(+255)",
            value: "+255",
          },
          {
            label: "Tayikistán	(+992)",
            value: "+992",
          },
          {
            label: "Timor-Leste	(+670)",
            value: "+670",
          },
          {
            label: "Togo	(+228)",
            value: "+228",
          },
          {
            label: "Tokelau	(+690)",
            value: "+690",
          },
          {
            label: "Tonga	(+676)",
            value: "+676",
          },
          {
            label: "Túnez	(+216)",
            value: "+216",
          },
          {
            label: "Turkmenistán	(+993)",
            value: "+993",
          },
          {
            label: "Turquía	(+90)",
            value: "+90",
          },
          {
            label: "Tuvalu	(+688)",
            value: "+688",
          },
          {
            label: "Ucrania	(+380)",
            value: "+380",
          },
          {
            label: "Uganda	(+256)",
            value: "+256",
          },
          {
            label: "Uruguay	(+598)",
            value: "+598",
          },
          {
            label: "Uzbekistán	(+998)",
            value: "+998",
          },
          {
            label: "Vanuatu	(+678)",
            value: "+678",
          },
          {
            label: "Vietnam	(+84)",
            value: "+84",
          },
          {
            label: "Wallis y Futuna	(+681)",
            value: "+681",
          },
          {
            label: "Yemen	(+967)",
            value: "+967",
          },
          {
            label: "Yibuti (+253)",
            value: "+253",
          },
          {
            label: "Zambia	(+260)",
            value: "+260",
          },
          {
            label: "Zimbabue	(+263)",
            value: "+263",
          },
        ];
      },

      formattedTemplate() {
        let res = {
          name: this.template.name,
          components: [],
        };

        if (this.template.header_format) {
          let header = {
            type: "HEADER",
            format: this.template.header_format.toUpperCase(),
          };

          if (this.template.header_format === "Text") {
            header.text = this.template.header_value;
          } else {
            header.example = {
              header_handle: [this.template.header_value],
            };
          }
          res.components.push(header);
        }

        res.components.push({
          type: "BODY",
          text: this.template.body,
        });

        if (this.template.footer) {
          res.components.push({
            type: "FOOTER",
            text: this.template.footer,
          });
        }

        if (this.template.buttons.length) {
          res.components.push({
            type: "BUTTONS",
            buttons: this.template.buttons,
          });
        }

        return res;
      },
    },
  };
</script>

<style scoped>
  .template-form h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .template-form div .label {
    font-size: 12px;
    margin-bottom: 10px;
    color: #999;
  }

  .template-form .el-steps--simple {
    background: transparent;
    padding-top: 0;
    padding-bottom: 25px;
  }

  .template-form .btn-config {
    background: #e4e6ec;
    border-radius: 4px;
  }

  .template-form .btn-config .btn-delete {
    color: #737372;
  }

  .template-view {
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: url("https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png") 100% 100%;
  }

  .template-view::-webkit-scrollbar {
    width: 4px;
  }

  .template-view::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  @media screen and (min-width: 768px) {
    .template-view {
      width: 420px;
      margin-left: 25px;
    }
  }
</style>

<style>
  .template-form .el-step.is-simple .el-step__icon {
    font-size: 18px;
  }

  .template-form .el-step__icon.is-text {
    border: 0;
  }

  .template-form .el-button.add-icon {
    border: 0;
    font-size: 14px;
    color: #737372;
    background: transparent !important;
  }

  @media screen and (max-width: 820px) {
    .template-form .el-step.is-simple .el-step__title {
      font-size: 14px;
    }
  }
</style>
