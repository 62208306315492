<template>
  <div class="bd-callout py-2">
    <div class="row">
      <DateRangePicker
        :auto-translate-shortcuts="true"
        class="col-lg-4 p-0 m-0"
        @date-range-changed="filters.dateRange = $event"
      />

      <div class="col-lg-4" v-if="reports">
        <div class="form-group has-label">
          <label>{{ $t("Report Type") }}</label>
          <el-select size="large" name="category" v-model="selectedReport">
            <el-option
              v-for="option in reports"
              :value="option.id"
              :label="$t(option.name)"
              :key="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="col-lg-4" v-if="showGroupBySelect">
        <div class="form-group has-label">
          <label>{{ $t("Group By") }}</label>
          <el-select size="large" name="category" multiple v-model="selectedGroup">
            <el-option
              v-for="option in options"
              :value="option.id"
              :label="$t(option.label)"
              :key="option.id"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import moment from "moment";
import AdvertisersFormComponent from "src/app-components/form-elements/Advertisers";
import DateRangePickerFormComponent from "src/app-components/form-elements/DateRangePicker";
import FilterPanelMixin from "src/app-components/FilterPanelMixin";
import { RadioGroup, RadioButton } from "element-ui";
import { cloneDeep } from "lodash";

const GROUPS = {
  Monthly: 'DATE_FORMAT(`campaign_start_datetime`, "%Y-%m-01")',
  Weekly:
    "CONCAT(STR_TO_DATE(CONCAT(YEARWEEK(`campaign_start_datetime`, 1), 'Monday'), '%x%v %W'), '/', DATE_ADD(STR_TO_DATE(CONCAT(YEARWEEK(`campaign_start_datetime`, 1),'Monday'), '%x%v %W'), INTERVAL 7 DAY))",
  Daily: 'DATE_FORMAT(`campaign_start_datetime`, "%Y-%m-%d")',
};

export default {
  components: {
    Advertisers: AdvertisersFormComponent,
    DateRangePicker: DateRangePickerFormComponent,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },

  props: {
    showGroupBySelect: {
      type: Boolean,
      default: true,
    },
    initialReport: {
      type: Number,
      default: null,
    },
  },

  computed: {
    canFilter() {
      return (
        (Array.isArray(this.filters.dateRange) &&
          this.filters.dateRange.length !== 0 &&
          this.selectedReport) ||
        Object.keys(this.$route.params).length > 0
      );
    },

    options() {
      let options = [
        {
          id: 1,
          label: "Monthly",
          expr: 'DATE_FORMAT(`campaign_start_datetime`, "%Y-%m-01")',
          groupingProp: "Month",
          groupingLabel: "Month",
        },
        {
          id: 2,
          label: "Weekly",
          expr: GROUPS.Weekly,
          groupingProp: "Week",
          groupingLabel: "Week",
        },
        {
          id: 3,
          label: "Daily",
          expr: GROUPS.Daily,
          groupingProp: "Daily",
          groupingLabel: "Daily",
        },
        {
          id: 4,
          label: "Account",
          expr: "account_name",
          groupingProp: "Account",
          groupingLabel: "Account",
        },
      ];

      if(this.selectedReport === 2) {
        options.push({
          id: 5,
          label: "Price",
          expr: "Price",
          groupingProp: "Price",
          groupingLabel: "Price",
        });
        options.push({
          id: 6,
          label: "Campaign Type",
          expr: "Type",
          groupingProp: "Type",
          groupingLabel: "Type",
        });
      }

      return options;
    }
  },

  watch: {
    selectedGroup(value) {
      const sel = cloneDeep(
        this.options.filter((option) => this.selectedGroup.includes(option.id))
      );
      sel.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.$emit("groupChanged", sel);
    },
    selectedReport(value) {
      const report = this.reports.filter((report) => report.id === value).shift();
      if (this.canFilter) {
        this.$emit("reportChanged", report);
      }
    },
    "filters.dateRange"(value) {
      // console.log(value, this.selectedReport);
      if (this.canFilter) {
        const report = this.reports
          .filter((report) => report.id === this.selectedReport)
          .shift();
        this.$emit("reportChanged", report);
      }
    },
  },

  async created() {
    this.reports = (await api.getAccountPivotReports()).data;
    if (this.initialReport) this.selectedReport = this.initialReport;
  },

  data() {
    return {
      reports: null,
      /* options: [
        {
          id: 1,
          label: "Monthly",
          expr: 'DATE_FORMAT(`campaign_start_datetime`, "%Y-%m-01")',
          groupingProp: "Month",
          groupingLabel: "Month",
        },
        {
          id: 2,
          label: "Weekly",
          expr: GROUPS.Weekly,
          groupingProp: "Week",
          groupingLabel: "Week",
        },
        {
          id: 3,
          label: "Daily",
          expr: GROUPS.Daily,
          groupingProp: "Daily",
          groupingLabel: "Daily",
        },
        {
          id: 4,
          label: "Account",
          expr: "account_name",
          groupingProp: "Account",
          groupingLabel: "Account",
        },
      ], */
      selectedGroup: [],
      selectedReport: null,
    };
  },

  mixins: [
    FilterPanelMixin({
      datetimeGroup: null,
      dateRange: {},
    }),
  ],

  methods: {
    prepareFilters(filters) {
      const st = moment(new Date(this.filters.dateRange[0])).format(
        "YYYY-MM-DD 00:00:00"
      );
      const nd = moment(new Date(this.filters.dateRange[1])).format(
        "YYYY-MM-DD 23:59:59"
      );
      return {
        "campaign_start_datetime >=": this.fixCleared(
          this.filters.dateRange[0] ? st : null
        ),
        "campaign_start_datetime <=": this.fixCleared(
          this.filters.dateRange[1] ? nd : null
        ),
      };
    },
  },
};
</script>
<style scoped>
:deep(.el-radio-button__inner) {
  color: #51cbce;
  background: transparent;
  border: 2px solid #51cbce !important;
}

:deep(.el-radio-group .el-radio-button + .el-radio-button) {
  margin-left: -2px !important;
}

:deep(.el-radio-button__inner:hover) {
  color: #fff;
  background: #51cbce;
}

:deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
  background: #51cbce;
  box-shadow: -1px 0 0 0 #51cbce;
}
</style>
