<template>
  <div class="col-md-2" v-if="visible">
    <label> {{ label }} </label>
    <fg-input
      :name="this.vname"
      type="number"
      v-model="local.value"
      @input="onInput($event)"
      :disabled="disabled"
      :min="min"
    />
  </div>
</template>

<script>
import { cloneDeep, tap, set } from "lodash";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    vname: {
      type: String,
      required: true,
    },
    vid: {
      type: Number,
      required: true,
    },
    label: String,
    visible: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: function() {
        return {};
      },
    },
    min: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    variables: {
      type: Array,
    },
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    /* local() {
      if (this.value && this.visible) {
        if (typeof this.value[this.vname] === "undefined") {
          this.value[this.vname] = {
            variable_id: this.vid,
            value: 1,
          };
        }

        return this.value;
      }
    }, */

    local() {
      let defaultValue = {
        variable_id: this.vid,
        value: 0,
        variable: {
          name: this.vname,
        }
      };
      if(!this.variables) return defaultValue;

      let value = this.variables.find(variable => {
        if(variable.variable) {
          return variable.variable.name === this.vname;
        }
      });
      
      return !value ? defaultValue : value;
    },
  },

  methods: {
    /* onInput(value) {
      const key = `[${this.vname}].value`;
      this.$emit(
        "input",
        tap(cloneDeep(this.local), (v) => set(v, key, value))
      );
    }, */

    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };
      this.$emit("input", input);
    },
  },
};
</script>

<style></style>
