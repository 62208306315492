<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <label>{{ $t("Gap-time") }}</label>
        <fg-input :error="getError(name)">
          <el-time-select
            :name="name"
            :picker-options="datepickerOptions"
            v-validate="required ? 'required' : null"
            :placeholder="$t('Select time')"
            :disable="disabled"
            :value="local.value"
            @input="onInput($event)"
          >
          </el-time-select>
        </fg-input>
      </div>
    </div>
  </div>
</template>

<script>
import { TimeSelect } from "element-ui";
import { cloneDeep } from "lodash";

export default {
  inject: ["getError", "parentValidator"],

  created() {
    this.$validator = this.parentValidator;
  },

  components: {
    [TimeSelect.name]: TimeSelect,
  },

  props: {
    value: {
      type: Object,
      default: function() {
        return {};
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "performance-gap-time",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
    },
  },

  computed: {
    local() {
      let defaultValue = {
        variable_id: 84,
        value: "",
        variable: {
          name: "performance-gap-time",
        }
      };
      if(!this.variables) return defaultValue;

      let value = this.variables.find(variable => {
        if(variable.variable) {
          return variable.variable.name === "performance-gap-time";
        }
      });
      
      return !value ? defaultValue : value;
    },
  },

  data() {
    return {
      datepickerOptions: {
        start: "00:00",
        step: "00:10",
        end: "02:00",
      },
    };
  },

  methods: {
    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };

      this.$emit("input", input);
    },
  },
};
</script>

<style></style>
