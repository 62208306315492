<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>Provider</label>
        <el-select
          size="large"
          name="provider"
          :filterable="filterable"
          :clearable="clearable"
          v-validate="noValidation ? null : 'required'"
          @input="handleInput"
          :error="getError('provider')"
          :value="local"
          :placeholder="''"
        >
          <el-option
            v-for="option in providers"
            :value="option.id"
            :label="option.name"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
      <div
        v-show="errors.has('provider')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('provider') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null,
    noValidation: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    }
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getProviders();
  },

  data() {
    return {
      providers: []
    };
  },

  computed: {
    local() {
      return this.value ? this.value.id : null;
    }
  },

  methods: {
    async getProviders() {
      const providers = await api.getSmppGatewayProviders();
      this.providers = providers.data;
    },
    handleInput (val) {
      this.$emit('input', this.providers.find(p => p.id == val))
    }
  }
};
</script>

<style>
</style>
