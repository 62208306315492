<template>
  <div>
    <form-campaign
      :tabbed-form-save-button-disabled="workingSave"
      :initial-tab="$route.params.tab"
      :bookmarkProps="bookmarkProps"
      @submit="onSubmit"

      :initialBookmarkData="initialBookmarkData"
      :initialCampaignData="campaignDefaults"
      :enableFallback="true"
      :hide-bookmark-info="bookmarkId ? true : false"
      :updateCommercialModel="bookmarkId ? false : true"
    ></form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from "src/app-components/FormCampaign";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import { mapMutations } from "vuex";
import WorkingMixin from "src/app-components/WorkingMixin";
import { EventBus } from "src/eventBus.js";

export default {
  components: {
    FormCampaign,
  },

  mixins: [RetryCrudMixin(), WorkingMixin("Save")],

  mounted() {
    this.setTopNavbarTitle(this.$t("Create a new campaign"));
  },

  data() {
    return {
      bookmarkProps: {
        isEditable: true,
        hidePrice: true,
        hideCommercialModel: true,
      },

      bookmarkId: null,
      initialBookmarkData: {},
      campaignDefaults: {
				files: [],
				account_scenario_type_id: null,
				category_id: null,
				subcategory_id: null,
				channel_id: null,
				perf_text: [],
				variables: [],
				second_actions: [],
				manual_start: false,
				start_datetime: "",
				end_datetime: "",
        advertiser_id: null,
			},
    };
  },

  async created() {
		if ("bookmarkId" in this.$route.params) {
      this.bookmarkId = this.$route.params.bookmarkId;
			const response = await api.getTemplate(
				this.$route.params.bookmarkId
			);
			const bookmark = response.data;
			//console.debug("Template", bookmark);

			this.campaignDefaults.account_scenario_type_id =
				bookmark.account_scenario_type_id;
			this.campaignDefaults.channel_id = bookmark.channel_id;
			this.campaignDefaults.category_id = bookmark.category_id;
			this.campaignDefaults.subcategory_id = bookmark.subcategory_id;
			
      this.campaignDefaults.advertiser_id = bookmark.advertiser_id;

			if (
				bookmark.channel_id == 6 && //RCS
				Array.isArray(bookmark.creatives) &&
				bookmark.creatives.length >= 1
			) {
				// seleccionamos automaticamente la primera creatividad
				this.campaignDefaults.perf_text = [
					{
						bookmark_perf_text_hash: bookmark.creatives[0].hash,
					},
				];
			}

      this.initialBookmarkData = response.data;
    }
  },

  methods: {
    async onSubmit($event) {
      try {
        this.setWorkingSave(true);

        if(this.bookmarkId === null) {
          const resTemplate = await api.createTemplate($event.template)
          $event.template = resTemplate.data;
        }else {
          await api.updateTemplate(this.bookmarkId, $event.template);
        }

        // elimino el template porque si se lo envío a la campaña puede que rompa
        if($event.template) {
          $event.bookmark_id = $event.template.id;
          $event.template_id = $event.template.id;
          delete $event.template;
        }

        const res = await api.createCampaign({
          ...$event,
          children: undefined,
        });
        await this.processOperationLog(res.data.id, $event.children);

        if (res.status) {
          this.$router.push({ path: `/campaigns/planning` });
        } else {
          EventBus.$emit("axios-request-error", {
            errors: res.errors,
            type: "danger",
          });
        }

      } finally {
        this.setWorkingSave(false);
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
