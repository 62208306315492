<template>
  <b>{{ mts.mt ? mts.mt : 0 }} / {{ mts.mo ? mts.mo : 0 }}</b>
  <!-- <b v-if="$asyncComputed.mts.success">{{ mts.mt }} / {{ mts.mo }}</b> -->
</template>

<script>
import api from "src/api";

export default {
  props: {
    scenarioID: { type: Number },
    row: { type: Object },
    vars: { required: true },
  },

  asyncComputed: {
    mts: {
      async get() {
        if (!this.row) {
          return;
        }

        let variables = {};

        this.row.variables.forEach((variable) => {
          if (variable.variable) {
            variables[variable.variable.name] = variable.value;
          }
        });

        this.vars.forEach((variable) => {
          if (variable.variable) {
            variables[variable.variable.name] = variable.value;
          }
        });

        const res = await api.getMt({
          variables,
          account_scenario_id: this.scenarioID,
        });

        return res.data;
      },
    },
  },
};
</script>
