<template>
  <div class="modal-create-agent">
    <el-dialog :visible.sync="viewModal" width="350px" :show-close="false">
      <div v-show="!loading">
        <el-tooltip
          class="tooltip-name"
          effect="dark"
          content="That name is already in use"
          placement="top"
          :value="!validName"
          :manual="true"
        >
          <el-input
            :class="!validName ? 'error' : ''"
            :placeholder="$t('Enter flow name')"
            v-model="name"
            @input="validateName"
          ></el-input>
        </el-tooltip>
      </div>

      <span v-show="!loading" slot="footer" class="dialog-footer">
        <el-button @click="closeModal">{{ $t("Cancel") }}</el-button>
        <el-button
          type="primary"
          @click="createFlow"
          :disabled="!allValidated"
          >{{ $t("Create") }}</el-button
        >
      </span>

      <div v-show="loading" class="py-3 text-center">
        <i class="el-icon-loading py-3" style="font-size: 24px"></i>
        <p style="font-size: 18px" class="mb-2">{{ $t("Creating agent") }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import api from "src/api";
  import { Dialog, Input, Select, Option, Button } from "element-ui";

  export default {
    name: "ModalCreateFlow",
    props: ["open", "close", "flowsName"],
    components: {
      [Dialog.name]: Dialog,
      [Input.name]: Input,
      [Select.name]: Select,
      [Option.name]: Option,
      [Button.name]: Button,
    },

    data() {
      return {
        viewModal: false,

        loading: false,
        name: "",

        validName: true,
      };
    },

    watch: {
      open() {
        this.viewModal = true;
      },

      close() {
        this.viewModal = false;
      },

      viewModal() {
        if (!this.viewModal) {
          this.name = "";
          this.validName = true;
        }
      },
    },

    methods: {
      async createFlow() {
        if (!this.allValidated) return;

        this.loading = true;
        let res = await api.createFlow(this.name.trim());
        if (res.status) {
          this.$emit("closeModal", res.data.id);
          this.viewModal = false;
          this.loading = false;
          this.name = "";
        }
      },

      closeModal() {
        this.viewModal = false;
      },

      validateName() {
        if (this.flowsName) {
          let flow = this.flowsName.find(f => f.toLowerCase() === this.name.trim().toLowerCase())
          this.validName = flow ? false : true;
        }
      },
    },

    computed: {
      allValidated() {
        return this.name.trim() !== "" && this.validName;
      },
    },
  };
</script>

<style scoped></style>

<style>
  .modal-create-agent .el-dialog__header {
    padding: 0 !important;
  }

  .modal-create-agent .error .el-input__inner {
    border-color: red;
  }
</style>
