<template>
    <p-button type="success" size="sm" icon @click="handlePlay(id)">
        <el-tooltip class="item" effect="dark" content="Play" placement="top">
            <i class="fa fa-play"></i>
        </el-tooltip>
    </p-button>
</template>

<script>
import api from "src/api"
import swal from "sweetalert2";

export default {
    props: {
        id: {
            type: Number,
            required: true
        }
    },

    methods: {
        handlePlay(id) {
            swal({
                title: "Play?",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, play it!",
                buttonsStyling: false,
                allowOutsideClick: false
            }).then(async result => {
                if (result.value) {
                    const res = await api.startCampaign(id);
                    if(res.status) {
                        this.$emit('played', res);
                    }
                }
            });
        }
    }
}
</script>
