<template>
    <li :class="{active: link.active}">
        <a v-if="link.icon" data-toggle="collapse"
           :href="link.path">
            <i :class="link.icon"></i>
            <p>{{link.name}}
            </p>
        </a>
        <a v-else :href="link.path" data-toggle="collapse">
            <span class="sidebar-mini-icon router-mini-icon">{{link.name.substring(0, 1)}}</span>
            <span class="sidebar-normal">{{link.name}}</span>
        </a>
    </li>
</template>

<script>
    export default {
        props: [ "link", "to" ]
    }
</script>