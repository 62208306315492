import { mergeWith, isEqual } from 'lodash';

function flat(value){
    return JSON.parse(JSON.stringify(value));
};

function customizer(objValue, srcValue) {
    if (Array.isArray(objValue)) {
        return srcValue;
    }
};

/**
 * Returns a customized mixin to conveniently implement custom v-model bindings
 * (https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model)
 * with a local copy of the "value" prop, wich can be mutated by the component using
 * this mixin, and pass this "localValue" data down to other components
 * @param {string} localValue name of field inside your components 'data' which will mantain
 * a copy of the 'value' property
 * @param {string} propName name of the prop that will be used for v-model. Defaults to 'value'
 * @param {string} eventName name of the event that will be emitted when your localValue changes. Defaults to 'input'
 * @param {false|string} debug Whether to console.debug every event in this mixin. Every message logged will be prefixed
 * with the provided string. We recommend passing the name of the component on which you are debugging the mixin.
 * 
 */
export default function(
    localValue,
    debug = false,
    propName = 'value',
    eventName = 'input',
){
    //TODO: Revisar esto en unos meses... ya no se estaria usando (please check)
    var patching = false;

    return {
        model: {
            prop: propName,
            event: eventName,
        },
        props: {
            [propName]: {
                //TODO: Confirmar que esto no impacta en el funcionamiento del mixin. Se necesitaba que soportara mas tipos de datos porque si no rompia.
                type: [Object, Array, String, Boolean, Number],
                required: false
            }
        },

        /*computed: {
            prop(){
                if(debug) console.debug('%c', 'color:green',"COMPUTED prop has been triggered. New value: ", flat(this[propName]));
                return this[propName]
            }
        },*/
      
        watch: {
            [propName]: {
                deep: true,
                immediate: true,
                handler(nv, ov) {
                    const equal = isEqual(nv, this[localValue]);

                    if(debug) console.debug(`%cWATCH prop: ${debug} - value prop ('${propName}') changed. Equals? ${equal}`, 'background: #222; color: #bada55');
                    
                    if(!equal){
                        this.patchLocalValue(nv);
                    }
                    
                }
            },
            
            [localValue]: {
                deep: true,
                handler(newVal, oldVal) {
                    if(debug) console.debug(`${debug} - watch for local value ("${localValue}") triggered`);
                    //if(patching){
                    //    patching = false;
                    //    return;
                    //}
                    
                    //if(debug) console.debug(`WATCH local: ${debug} - local value ('${localValue}') changed. Patching: ${patching}. New value: `, flat(newVal));
                    
                    const emit = this.beforeEmitChange(newVal, oldVal);
                    if(emit === false){
                        // cancelled
                        if(debug) console.debug(`${debug} - emit cancelled`);
                        
                    } else {
                        if(debug) console.debug(`${debug} - emitting change: `, flat(newVal));
                        this.$emit(eventName, flat(newVal));
                    }
                }
            },
        },
        methods: {
            patchLocalValue(propData) {
                if(debug) console.debug(`${debug} - Patching localValue '${localValue}' with: `, flat(propData));
                //patching = true;
                if(Array.isArray(this[localValue]) || Array.isArray(propData)){
                    this.$set(this, [localValue], propData);
                } else {
                    mergeWith(this[localValue], flat(propData), customizer);
                }
                if(debug) console.debug(`${debug} - localValue ('${localValue}') patched. New state: `, flat(this[localValue]));
                this.afterModelPatched();
            },

            beforeEmitChange(val){
                return true;
            },
      
            afterModelPatched: function() {

            },
        }
    };
}
