<template>
    <p-button type="success" size="sm" icon @click="handleRetry()">
        <el-tooltip class="item" effect="dark" :content="$t('Retry')" placement="top">
            <i class="fa fa-repeat"></i>
        </el-tooltip>
    </p-button>
</template>

<script>
import { Tooltip } from "element-ui";
import api from "src/api"
import swal from "sweetalert2";

export default {
    props: {
    },

    methods: {
        handleRetry() {
            swal({
                title: this.$t("Do you want to configure a campaign Retry?"),
                html: `
                  <span style="font-size: 12px;">${this.$t('A retry is made to Undelivered users that were not active at the time of the campaign or by lack of bandwidth in case the status of the campaign indicates "finished with pending users"')}</span>
                `,
                showCancelButton: true,
                keydownListenerCapture: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: this.$t("Yes, let's retry!"),
                buttonsStyling: false,
                allowOutsideClick: false
            }).then(async result => {
                if (result.value) {
                    this.$emit("click");
                }
            });
        }
    }
}
</script>