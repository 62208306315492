<template>
  <div>
    <title-box
      componentName="Message"
      :id="numberNodeID"
      :name="dataNode.name"
      @setNewName="setNewName($event)"
    />

    <div class="p-2">
      <div class="first-message" v-if="firstMessage">
        <p
          v-html="textMarked(firstMessage.text)"
          class="text-response mb-0"
          v-if="firstMessage.text"
        ></p>
        <img
          v-if="
            firstMessage.type === 'image' || firstMessage.type === 'sticker'
          "
          :src="firstMessage.link"
          :alt="firstMessage.caption"
        />

        <video
          v-if="firstMessage.type === 'video'"
          :src="firstMessage.link"
          class="w-100"
        ></video>
        <p class="text-response mb-0" v-if="firstMessage.type === 'file'">
          <i class="el-icon-document"></i> File: {{ firstMessage.filename }}
        </p>
      </div>
      <el-button
        round
        size="mini"
        class="my-1 w-100"
        v-else
        @click="activeOptions"
      >
        {{ $t("Create message") }}
      </el-button>

      <div v-if="otherMessages" v-show="showOtherMessages">
        <div v-for="(message, key) in otherMessages" :key="key" class="mt-2">
          <p
            v-html="textMarked(message.text)"
            class="text-response mb-0"
            v-if="message.text"
          ></p>
          <img
            v-if="message.type === 'image' || message.type === 'sticker'"
            :src="message.link"
            :alt="message.caption"
          />

          <video
            v-if="message.type === 'video'"
            :src="message.link"
            class="w-100"
          ></video>
          <p v-if="message.type === 'file'">File: {{ message.filename }}</p>
        </div>
      </div>

      <el-button
        v-if="otherMessages"
        @click="showOtherMessages = !showOtherMessages"
        size="mini"
        type="text"
        class="w-100 pb-0 ml-0"
      >
        {{ !showOtherMessages ? `${otherMessages.length} more` : "Hide" }}
      </el-button>
    </div>

    <create-element-popover 
      output="1"
      :numberNodeID="numberNodeID" 
      v-if="viewPopover" 
    />

  </div>
</template>

<script>
  import { Input, Button, Popover } from "element-ui";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "Message",
    mixins: [ChatbotComponentsMixin],
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Popover.name]: Popover,
    },

    data() {
      return {
        dataNode: {
          messages: [],
          global_scope: [],
        },

        showOtherMessages: false,
      };
    },

    watch: {
      showOtherMessages() {
        this.updateConnectionNodes();
      },
    },

    computed: {
      /**** Overwrite ****/
      firstMessage() {
        if (this.dataNode.messages) {
          if (this.dataNode.messages.length) {
            return this.dataNode.messages[0];
            /* let key =
              this.dataNode.messages[0].type === "text" ? "text" : "link";
            if (this.dataNode.messages[0][key]) {
              return this.dataNode.messages[0];
            } */
          }
        }

        return null;
      },

      otherMessages() {
        if (this.dataNode.messages) {
          if (this.dataNode.messages.length > 1) {
            let messages = [...this.dataNode.messages];
            messages.shift();
            return messages;
          }
        }

        return null;
      },

      viewPopover() {
        if(this.dataNode) {
          if(this.dataNode.connections) {
            return this.dataNode.connections.length === 0
          }
        }
        return false;
      }
    },
  };
</script>
<style>
  .wrapperCanvas .drawflow .drawflow-node.Message.selected {
    -webkit-box-shadow: 0 2px 20px 2px #85ce61;
    box-shadow: 0 2px 20px 2px #85ce61;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.Message.selected {
    -webkit-box-shadow: 0 2px 20px 2px #03dac6;
    box-shadow: 0 2px 20px 2px #03dac6;
  }
</style>
