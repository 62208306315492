<template>
  <div>
    <div
      v-if="limit !== 1"
      class="d-flex justify-content-between align-items-center"
    >
      <div>
        <el-button
          size="mini"
          round
          @click="addMessage('text')"
          :disabled="limit === messagesList.length"
          >Add message</el-button
        >
        <el-button
          size="mini"
          round
          @click="addMessage('multimedia')"
          :disabled="limit === messagesList.length"
          >Add multimedia</el-button
        >
      </div>
      <div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="tooltipLimitMessages"
          placement="top"
        >
          <i class="el-icon-info"></i>
        </el-tooltip>
      </div>
    </div>

    <div>
      <div
        class="my-3 d-flex align-items-center justify-content-between"
        v-for="(message, key) in messagesList"
        :key="`${message.id}-${key}`"
      >
        <!-- :key="`${message.type === 'text' ? messagesList[key].text : messagesList[key].link}-${key}`" -->
        <div style="width: 16px" v-if="limit !== 1">
          <el-button
            @click="move('up', key)"
            size="small"
            type="text"
            icon="el-icon-arrow-up"
            class="m-0 p-0"
          ></el-button>
          <el-button
            @click="move('down', key)"
            size="small"
            type="text"
            icon="el-icon-arrow-down"
            class="m-0 p-0"
          ></el-button>
        </div>

        <div :class="limit !== 1 ? 'width-40' : 'w-100'">
          <input-actions
            v-if="message.type === 'text'"
            :validate="validate"
            :variables="variables"
            :value="messagesList[key].text"
            @updateValue="updateValue($event, key)"
          />

          <div v-if="message.type !== 'text'" class="d-flex align-items-center">
            <el-select
              v-model="messagesList[key].type"
              placeholder="Type"
              size="small"
              @change="updateTypeMultimedia(key)"
            >
              <el-option
                v-for="(type, key) in typeMultimedia"
                :key="key"
                :label="type"
                :value="type.toLowerCase()"
              ></el-option>
            </el-select>

            <el-tooltip
              class="item"
              effect="dark"
              content="Format valid: JPG | JPEG | PNG | WEBP - Max size: 5MB"
              :value="!messagesList[key].valid && messagesList[key].link !== ''"
              :manual="true"
              placement="top"
            >
              <el-input
                placeholder="Link"
                v-model="messagesList[key].link"
                @input="updateImageLink(key)"
                class="mx-2"
                size="small"
                :class="[
                  !messagesList[key].valid && messagesList[key].link !== ''
                    ? 'invalid'
                    : '',
                  validate && messagesList[key].link === '' ? 'invalid' : '',
                ]"
              >
              </el-input>
            </el-tooltip>

            <el-input
              placeholder="Caption"
              v-model="messagesList[key].caption"
              @input="updateData"
              size="small"
              v-if="messagesList[key].type === 'image' || messagesList[key].type === 'video'"
            >
            </el-input>

            <el-input
              placeholder="File name"
              v-model="messagesList[key].filename"
              :class="
                validate && messagesList[key].filename === '' ? 'invalid' : ''
              "
              @input="updateData"
              size="small"
              v-if="messagesList[key].type === 'file'"
            >
            </el-input>
          </div>
        </div>

        <div style="width: 16px" v-if="limit !== 1">
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="deleteMessage(key)"
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import { Button, Input } from "element-ui";

  import InputActions from "src/app-components/chatbot/elements/InputActions";
  export default {
    name: "FormMessages",
    props: {
      limit: {
        type: Number,
        default: () => 3,
      },
      messages: {
        type: Array,
        default: () => [],
      },
      variables: {
        type: Array,
        default: () => [],
      },
      validate: {
        type: Boolean,
        default: () => false,
      },
    },

    components: {
      [Button.name]: Button,
      [Input.name]: Input,

      "input-actions": InputActions,
    },

    data() {
      return {
        messagesList: [],
      };
    },

    mounted() {
      this.messagesList = clone(this.messages);

      setTimeout(() => {
        this.$emit("updateValid", this.valid);
      }, 5);
    },

    methods: {
      addMessage(type) {
        if (this.messagesList.length >= this.limit) return;

        let item = {
          text: {
            type,
            text: "",
            id: Date.now(),
          },
          multimedia: {
            type: "image",
            link: "",
            caption: "",
            filename: "",
            id: Date.now(),
            valid: true,
          },
        };

        this.messagesList.push(item[type]);
      },

      deleteMessage(key) {
        if (this.messagesList[key]) {
          this.messagesList.splice(key, 1);
          this.updateData();
        }
      },

      updateTypeMultimedia(key) {
        if (this.messagesList[key]) {
          if (this.messagesList[key].type !== "file") {
            this.messagesList[key].filename = "";
          } else {
            this.messagesList[key].caption = "";
          }
          this.updateData();
        }
      },

      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.messagesList);
          this.$emit("updateValid", this.valid);
        }, 1);
      },

      updateValue(text, key) {
        if (this.messagesList[key]) {
          this.messagesList[key].text = text;
          this.updateData();
        }
      },

      async validateImageLink(link) {
        if (!link) return false;
        if (link.includes("base64")) return false;

        try {
          const res = await fetch(link);
          const buff = await res.blob();
          return (
            buff.type.match(/(jpeg|jpg|webp|png)$/) != null &&
            buff.size / 1000000 < 5
          );
        } catch (error) {
          return false;
        }
      },

      async updateImageLink(key) {
        if (this.messagesList[key].type === "image") {
          this.messagesList[key].valid = await this.validateImageLink(
            this.messagesList[key].link
          );
        } else {
          this.messagesList[key].valid = true;
        }
        this.updateData();
      },

      move(action, key) {
        let messages = clone(this.messagesList);
        let item = messages.splice(key, 1);
        if (action === "up") {
          if (key > 0) {
            messages.splice(key - 1, 0, item[0]);
          } else {
            return;
          }
        } else {
          if (key < messages.length) {
            messages.splice(key + 1, 0, item[0]);
          } else {
            return;
          }
        }

        this.messagesList = clone(messages);

        this.updateData();
        this.updateConnectionNodes();
      },

      updateConnectionNodes() {
        setTimeout(() => {
          this.$emit("updateConnectionNodes");
        }, 180);
      },
    },

    computed: {
      tooltipLimitMessages() {
        return this.$t(
          "Up to 3 elements can be configured per Node. The available elements are text and images."
        );
      },

      typeMultimedia() {
        return ["Image", "Video", "File", "Sticker"];
      },

      valid() {
        if (this.messagesList.length) {
          var valid = true;
          this.messagesList.forEach((message) => {
            switch (message.type) {
              case "text":
                if (message.text === "") valid = false;
                break;
              case "file":
                if (message.link === "" || message.filename === "")
                  valid = false;
                break;
              case "image":
                if (message.link === "" || !message.valid)
                  valid = false;
                break;
              default:
                if (message.link === "") valid = false;
            }
          });

          return valid;
        }
        return false;
      },
    },
  };
</script>

<style scoped>
  .width-40 {
    width: calc(100% - 40px);
  }
</style>
