<template>
  <div class="card">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t('ID')" width="75">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Agent')">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Channel')">
        <template slot-scope="scope">
          <span>{{ scope.row.channel_id | setChannelName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Flow')">
        <template slot-scope="scope">
          <el-select v-model="scope.row.flow_id" placeholder="Select flow" size="mini"
            no-data-text="You have no flows created" @change="setFlow(scope.$index, scope.row.flow_id)">
            <el-option v-for="flow in flows" :key="flow.id" :label="flow.name" :value="flow.id">
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Actions')" v-if="true">
        <template slot-scope="scope">
          <p-button class="mr-1" :type="btn.type" size="sm" icon :key="btn.icon" v-for="btn in buttonsAction"
            @click="btn.event(scope.row)" v-show="btn.viewOnly === scope.row.channel_id ||
              btn.viewOnly === undefined ||
              (Array.isArray(btn.viewOnly) && btn.viewOnly.includes(scope.row.channel_id))
              ">
            <el-tooltip class="item" effect="dark" :content="$t(btn.tooltip)" placement="top">
              <i :class="btn.icon"></i>
            </el-tooltip>
          </p-button>
        </template>
      </el-table-column>
    </el-table>

    <floating-action-button color="#339564" icon="add" :position="0" @click="modalCreate = true" />

    <el-dialog width="30%" class="confirm-dialog" :visible.sync="modalConfirmDelete"
      title="Are you sure you want to delete this flow?">
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeConfirmDelete">Cancel</el-button>
        <el-button size="mini" type="primary" @click="deleteAgent">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog width="30%" class="confirm-dialog" :visible.sync="modalCreate" title="Select channel">
      <el-select placeholder="Select channel" v-model="idChannelCreate" class="d-block my-2 mx-4">
        <el-option :value="channel.id" :label="channel.name" :key="channel.id" v-for="channel in channels"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="createAgent" :disabled="!idChannelCreate">
          Create
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "src/api";
import { mapMutations } from "vuex";
import { Select, Table, Dialog, Button } from "element-ui";
import FloatingActionButton from "src/app-components/FloatingActionButton";
export default {
  name: "ServicesIndex",

  components: {
    [Select.name]: Select,
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FloatingActionButton,
  },

  data() {
    return {
      services: [],
      flows: [],

      agentDelete: null,
      modalConfirmDelete: false,

      modalCreate: false,
      idChannelCreate: null,
    };
  },

  created() {
    if (!this.$can('Views', 'Services')) this.$router.push({ name: "DashboardIndex" });

    this.setTopNavbarTitle("Services");
    this.getServices();
    this.getFlows();
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    createAgent() {
      if (this.idChannelCreate) {
        let channel = this.channels.find(
          (c) => c.id === this.idChannelCreate
        );
        if (channel) {
          this.idChannelCreate = null;
          this.$router.push({ name: channel.routeCreate });
        }
      }
    },

    async getServices() {
      let res = await api.getServices();
      if (res.status) {
        this.services = [...res.data];
      }
    },

    async getFlows() {
      let res = await api.getFlows();
      if (res.status) {
        this.flows = [...res.data];
      }
    },

    async deleteAgent() {
      if (this.agentDelete) {
        let res = await api.deleteService(this.agentDelete.id);
        if (res) {
          this.deleteAgentInList(this.agentDelete.id);
        } else {
          this.errorDeleteAgent();
        }
      }

      this.closeConfirmDelete();
    },

    deleteAgentInList(id) {
      let index = this.services.findIndex((agent) => agent.id === id);
      if (index !== -1) {
        this.services.splice(index, 1);
      }
    },

    errorDeleteAgent() {
      this.getServices();
      this.$notify({
        title: this.$t("An error occurred"),
        message: this.$t("Could not delete agent."),
        icon: "fa fa-info",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "warning",
        duration: 5000,
      });
    },

    async setFlow(index, value) {
      if (this.services[index]) {
        try {
          await api.updateService(this.services[index].id, {
            flow_id: value,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },

    openDeleteAgent(agent) {
      this.agentDelete = agent;
      this.modalConfirmDelete = true;
    },

    closeConfirmDelete() {
      this.modalConfirmDelete = false;
      this.agentDelete = null;
    },

    openInNewTab(agent) {
      window.open(
        this.services.find((a) => a.id == agent.id).testing_url,
        "_blank",
        "noreferrer"
      );
    },

    copyLinkId(agent) {
      if (agent) {
        navigator.clipboard
          .writeText(
            agent.webhook_url
          )
          .then(
            () => {
              this.$notify({
                message: this.$t("Content copied to clipboard"),
                type: "success",
              });
            },
            () => {
              this.$notify({
                message: this.$t("Failed to copy"),
                type: "warning",
              });
            }
          );
      }
    },
  },

  computed: {
    tableData() {
      return this.services;
    },

    channels() {
      return [
        {
          id: 7,
          name: "GBM",
          routeCreate: "FormGBM",
        },
        {
          id: 8,
          name: "WhatsApp Api Cloud",
          routeCreate: "FormWPP",
        },
        {
          id: 10,
          name: "WhatsApp BSP",
          routeCreate: "FormGupshup",
        },
        {
          id: 9,
          name: "Facebook",
          routeCreate: "FormFB",
        },
      ];
    },

    buttonsAction() {
      return [
        // {
        //   type: "info",
        //   icon: "fa fa-edit",
        //   tooltip: "Edit",
        //   event: null,
        // },
        {
          type: "danger",
          icon: "fa fa-trash",
          tooltip: "Delete",
          event: this.openDeleteAgent,
        },
        {
          type: "primary",
          icon: "fa fa-clipboard",
          tooltip: "Copy link webhook",
          event: this.copyLinkId,
          viewOnly: [7, 8],
        },
        {
          type: "testing",
          icon: "fa fa-external-link",
          tooltip: "Testing link",
          viewOnly: 7,
          event: this.openInNewTab,
        },
      ];
    },
  },
}
</script>
