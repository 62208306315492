<template>
    <call-to-action-elements
        v-model="form.callToActionConfig"
        :call-to-action-id="callToActionId"
        :scenario="callToActionId"
        width="col-md-6"
    />
</template>

<script>
    import CallToActionElements from 'src/app-components/form-elements/generators/CallToActionElements'
    import { updatableForm } from 'src/app-components/FormMixin'
    import { cloneDeep } from 'lodash'

    export default {
        components: {
            CallToActionElements
        },
        mixins: [
            updatableForm
        ],
        props: {
            callToActionId: Number,
        },
        data () {
            return {
                form: {
                    callToActionConfig: cloneDeep(this.entity.callToActionConfig)
                }
            }
        },
        watch: {
            callToActionId () {
                this.form.callToActionConfig = {}
            },
        }
    };
</script>

<style scoped>
</style>