<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t("Segmentate a base") }}</h4>
                    </div>

                    <StepOne :initial-list-data="initialListData" @submit="onSubmitStepOne" v-if="currentStep >= 1" :disabled="currentStep > 1" />
                    <StepTwo :initial-list-data="initialListData" :crm-list-id="crmListId" v-if="currentStep >= 2" :disabled="currentStep > 2" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash';

    import TabbedFormMixin from 'src/app-components/TabbedFormMixin';
    import { defaultMixin } from "src/app-components/FormMixin";

    import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
    import TabbedForm from 'src/app-components/TabbedForm';

    import SegmentationListName from "src/app-components/form-elements/SegmentationListName";
    import StepOne from "src/app-components/segmentation-steps/StepOne";
    import StepTwo from "src/app-components/segmentation-steps/StepTwo";

    export default {
        mixins: [
            defaultMixin,
            TabbedFormMixin
        ],

        provide() { // Pass to Mixin
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },

        components: {
            [TabPane.name]: TabPane,
            TabbedForm,
            SegmentationListName,
            StepOne,
            StepTwo
        },

        props: {
            initialListData: {
                type: Object,
                default: undefined
            },

            initialTab: String,

            disabled: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            initialListData () {
                this.form.name = this.initialListData.name;
                this.form.account_user_file_id = this.initialListData.account_user_file_id;
                this.crmListId = this.initialListData.id;

                if (this.initialListData.status == "GATHERING_INFORMATION_READY") {
                    this.currentStep = 2;
                } else {
                    this.currentStep = 1;
                }
            }
        },

        data() {
            return {
                form: {
                    name: "",
                    rules: [],
                    account_user_file_id: null
                },
                currentStep: 1,
                crmListId: null,
            }
        },

        methods: {
            getError(fieldName) {
                return this.errors.first(fieldName);
            },

            getTabs() {
                return {
                    configuration: false,
                }
            },

            onSubmit() {
                const data = cloneDeep(this.form)
                this.$emit("submit", data);
            },

            handleRulesUpdated (data) {
                this.form.rules = data;
            },

            onSubmitStepOne (crmList) {
                this.crmListId = crmList.data.id;
                this.currentStep++;
            }
        }
    }
</script>

<style>

</style>
