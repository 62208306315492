<template>
    <div class="row align-items-end my-2">
        <div class="text-left col-8">
            <label>{{$t('Text')}}</label>
            <el-input size="mini" v-model="form.name"></el-input>
        </div>
        <div class="text-left col-4">
            <label>{{$t('Short Number')}}</label>
            <el-input size="mini" v-model="form.short_number"></el-input>
        </div>
        <div class="text-left col">
            <label>{{$t('Text message')}}</label>
            <el-input size="mini" v-model="form.msg"></el-input>
        </div>
        <div class="text-left col-auto">
            <el-button size="mini" type="primary" :disabled="disabled" @click="submit">{{$t('Confirm')}}</el-button>
        </div>
    </div>
</template>

<script>
import { Input, Button } from "element-ui";

export default {
    components: {
        [Input.name]: Input,
        [Button.name]: Button,
    },

    data() {
        return {
            form: {
                name: '',
                short_number: '',
                msg: ''
            }
        }
    },

    computed: {
        disabled(){
            return this.form.name == '' || this.form.short_number == '' || this.form.msg == ''
        }
    },

    methods: {
        submit(){
            this.$emit('submit', this.form);
            this.clear();
        },

        clear(){
            this.form = {
                name: '',
                short_number: '',
                msg: ''
            }
        }
    },
}
</script>