<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <event-name v-model="filters.event_name" 
                    :isUnique="false"
                    :required="false">
                </event-name>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import BookmarkNameComponent from "src/app-components/form-elements/BookmarkName";
    import EventName from "src/app-components/form-elements/EventName";
    import CampaignStatus from "src/app-components/form-elements/CampaignStatus";
    import CampaignType from "src/app-components/form-elements/CampaignType";
    import FilterPanelMixin from "src/app-components/FilterPanelMixin";
    import DateRangePicker from "src/app-components/form-elements/DateRangePicker";
    import { CurrentMonth, Today, CurrentWeek, Yesterday } from "src/app-components/DateRangePresets.js"
    import Accounts from "src/app-components/form-elements/Accounts";
    import Channels from "src/app-components/form-elements/Channels";
    import Scenarios from "src/app-components/form-elements/Scenarios";

    export default {
        components: {
            Accounts,
            BookmarkName: BookmarkNameComponent,
            EventName,
            CampaignStatus,
            Channels,
            CampaignType,
            DateRangePicker,
            Scenarios
        },

        mixins: [
            FilterPanelMixin({
                event_name: null,
            })
        ],

        data() {
            return {
            }
        },

        methods: {
            prepareFilters (filters) {
                return {
                    "ApiEvent.name": this.fixCleared(this.filters.event_name),
                };
            },
        },
    };
</script>
<style scoped>
</style>