import moment from 'moment';

const Today =  function() {
    const start = new Date();
    const end = new Date();

    start.setHours(0,0,0,0);
    end.setHours(23,59,59,999);
    
    return [ start, end ]
};

const LastWeek = function() {
    const start = moment().subtract(1, 'weeks').startOf('isoWeek');
    const end = moment().subtract(1, 'weeks').endOf('isoWeek');
    return [ start, end ]
};

const CurrentMonth = function() {
    const start = moment().startOf('month');
    const end = moment().endOf('month');
    return [start, end];
};

const CurrentWeek = function() {
    const start = moment().startOf('isoWeek');
    const end = moment().endOf('isoWeek');
    return [start, end];
};

const Yesterday = function() {
    const start = moment().subtract(1, 'days').startOf('day');
    const end = moment().subtract(1, 'days').endOf('day');
    
    return [ start, end ];
};

export {
    CurrentMonth,
    CurrentWeek,
    LastWeek,
    Today,
    Yesterday
}