export default {
    CREATED                     : 1 ,
    PENDING                     : 2 ,
    DISAPPROVED                 : 3 ,
    PROGRAMMED                  : 4 ,
    RUNNING                     : 5 ,
    STOPPED                     : 6 ,
    FINISHED                    : 7 ,
    PAUSED                      : 8 ,
    CANCELLED                   : 9 ,
    DELETED                     : 10,
    STOPPED_BLACKOUT            : 11,
    STARTING                    : 12,
    LOADING_MSISDN_DB           : 13,
    RUNNING_CONNECTION_DOWN     : 14,
    READY                       : 15,
    FINISHED_WITH_PENDING_USERS : 16,
    WAITING                     : 17,
    PAUSING                     : 18,
    RESUMING                    : 19,
    FINISHING                   : 20,
    STOPPING                    : 21,
    DRAFT                       : 22,
    PRE_COMPILATION_PENDING     : 23,
    PRE_COMPILATION_RUNNING     : 24,
    PRE_COMPILATION_RESUMING    : 25,
    APPROVED_WITH_PENDING_BASE  : 26,
    REJECTED                    : 27,
    EXPIRED                     : 28,
    SAMPLING                    : 29,
    WAITING_FOR_RESULTS         : 30,
    INSUFFICIENT_RESULTS        : 31,
    INVALID_DATES               : 32,
    PROGRAMMED_WAITING          : 33,
    STARTING_VALIDATING_FILES   : 34,
    CONFIG_ERROR                : 35,

    getName: function(status_id){
        for(const key in this){
            if(this[key] === status_id){
                return key;
            }
        }
        return null;
    },
    isRunning : function(status_id){
        return [
            this.RUNNING,
            this.SAMPLING,
            this.WAITING_FOR_RESULTS,
        ].includes(status_id);
    },
    isFinished : function(status_id){
        return [
            this.FINISHED,
            this.FINISHED_WITH_PENDING_USERS,
        ].includes(status_id);
    },
    isEditable : function(status_id){
        return [
            this.CREATED,
            this.PENDING,
            this.DISAPPROVED,
            this.PROGRAMMED,
            this.READY,
            this.WAITING,
            this.DRAFT,
            this.INVALID_DATES,
        ].includes(status_id);
    }
    
};
