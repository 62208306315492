<template>
    <div class="row">
        <div class="col-md-2"
            :key="index" 
             v-for="(bookmark, index) in bookmarks">
            <fg-input :name="getInputName(index)"
                      :key="index"
                      min="1"
                      :label="indexToOrdinal(index)"
                      v-validate="'required|between:1,99'"
                      type="number"
                      v-model="model.percents[index]"
                      :error="getError(getInputName(index))"
                      @input="notifyToParent()">
            </fg-input>
        </div>

        <div class="col-md-2">
            <fg-input name="total_percent"
                      disabled="disabled"
                      label="Total percent"
                      v-validate="'required|my'"
                      type="number"
                      v-model="model.sumPercent"
                      :error="getError('total_percent')">
            </fg-input>
        </div>
    </div>
</template>

<script>
    const converter = require('number-to-words');

    export default {
        inject: [ 'parentValidator' ],

        props: [
            "bookmarks"
        ],  

        created () {
            this.$validator = this.parentValidator;
            this.$validator.extend("my", (value) => {
                return parseInt(value) === 100;
            })
        },

        computed: {
            percents() {
                return this.model.percents;
            },
        },

        watch: {
            percents() {
                this.model.sumPercent = this.percents.reduce((sum, x) =>  parseInt(sum) + parseInt(x));
            }
        },

        data() {
            return {
                model: {
                    percents:  [],
                    sumPercent: 0
                }
            }
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },

            getInputName(index) {
                return "percent-" + index;
            },

            indexToOrdinal(index) {
                const ri = index + 1
                return "The " +  converter.toOrdinal(ri) + " best";
            },

            notifyToParent() {
                this.$emit("updated", this.model);
            }
        }
    }
</script>