<template>
  <div class="row" v-loading="true" id="loading">
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Loading} from 'element-ui'

  Vue.use(Loading.directive)
  export default {}
</script>
<style lang="scss">
  #loading {
    min-height: 200px;
    display: flex;
    align-items: center;
  }

  /* .el-loading-spinner .path {
    stroke: #66615B !important;
  } */
  
  .card.el-loading-parent--relative {
    transition: backdrop-filter .5s cubic-bezier(0.39, 0.58, 0.57, 1);
    -webkit-transition: backdrop-filter .5s cubic-bezier(0.39, 0.58, 0.57, 1);
  }

  .card.el-loading-parent--relative .el-loading-mask {
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
  }

  .el-loading-mask {
    background: transparent !important;
  }
</style>
