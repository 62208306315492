<script>
import BaseFlow from "./BaseFlow";

export default {
    extends: BaseFlow,

    name: "AcceptFlow",

    methods: {
        /**
         * @override
         */
        d() {
            return "accept";
        },

        /**
         * @override
         */
        listening() {
            return "d-selected-accept";
        },

        getDefaultModel(){
            return {
                active: false,
                trigger: "ACCEPTANCE",
                screen: "LAST_SCREEN",
                action: "CROSS_SELLING",
                product: {},
            };
        },
    }
}
</script>