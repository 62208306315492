var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tablediv"},[(!_vm.sortedData.ownTemplate)?_c('el-table',{key:_vm.tableRenderId,attrs:{"data":_vm.sortedData,"row-key":"Pkey","row-class-name":_vm.tableRowClassName},on:{"sort-change":_vm.sortChange}},_vm._l((_vm.tableCols),function(col,index){return _c('el-table-column',{key:index,attrs:{"header-align":"left","sortable":_vm.isSortable(col.prop) ? 'custom' : false,"width":col.width
          ? col.width
          : _vm.fitColumnToContent(
              _vm.columnProp(col.prop),
              _vm.$t(col.label),
              col.width / 2 < 80 ? col.width / 2 : 90
            ),"align":"left","prop":col.prop,"label":_vm.$t(col.label)},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(col.animated)?_c('a-counter',{attrs:{"value":props.row[col.prop],"formatter":col.formatter ? col.formatter : null}}):(!col.isHtml)?_c('span',[_vm._v(" "+_vm._s(_vm.getCellValue(props.row, col))+" ")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.getCellValue(props.row, col))}})]}}],null,true)})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }