<script>
  import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
  import api from "src/api";

  export default {
    name: "call-to-action-elements",
    extends: ScenarioRequiredElements,

    props: {
      width: {
        type: String,
        default: 'col-md-6'
      },
      callToActionId: Number
    },

    computed: {
      schemas() {
        return this.schema;
      }
    },

    methods: {
      async getSchema() {
        const variables = (await api.getSatCallToAction(this.callToActionId)).data.variables;
        this.schema = variables.map(v => {
          const schema = v.variable;
          if(schema.type.id === 6){
            schema.type.web_component = 'TextBrowser'; // ni idea
          }

          return schema;
        });
      },
      getValue(index, id, variable) {
        
        if (this.value.variables && this.value.variables[index]) {
          return this.value.variables[index].value;
        }

        if(variable.name in this.value){
          return this.value[variable.name].value;
        }

        return this.value.value ? this.value.value : "";
      },
    }
  };
</script>