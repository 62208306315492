<template>
  <div class="input-action">
    <el-input
      class="w-100 padding-limit"
      v-model="text"
      :type="!noAutosize ? 'textarea' : 'text'"
      :autosize="!noAutosize"
      @input="updateData"
      :class="[
        validate && text === '' ? 'invalid' : '',
        classInput ? classInput : '',
      ]"

      :maxlength="onlyVariables ? null: 1024"
      :show-word-limit="!onlyVariables"
    ></el-input>

    <div class="d-flex align-items-center mt-1">
      <el-dropdown trigger="click" :disabled="dataVariables.length === 0" class="mr-1" @command="addVariable">
        <el-button
          type="info"
          size="mini"
          class="px-2 py-1"
          :disabled="dataVariables.length === 0"
          >Variables</el-button
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="v in dataVariables" :key="v.value" :command="v.value">
            {{ v.value }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <picker-emoji @isertEmoji="isertEmoji($event)" v-if="!onlyVariables" />

      <el-tooltip class="item" effect="dark" :content="tooltipMarkdown" placement="top" v-if="!onlyVariables">
        <el-button class=" ml-1 py-1 px-2 add-icon" size="mini" @click="viewMoreMarkdown"
          ><i class="el-icon-info"></i
        ></el-button>
      </el-tooltip>
    </div>


  </div>
</template>

<script>
  // import { EventBus } from "src/eventBus.js";
  import PickerEmoji from "src/app-components/chatbot/elements/PickerEmoji";
  import {
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Button,
    Input,
  } from "element-ui";
  export default {
    name: "InputActions",
    props: ["variables", "value", "validate", "onlyVariables", "noAutosize", "classInput"],

    components: {
      "picker-emoji": PickerEmoji,
      [Input.name]: Input,
      [Button.name]: Button,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Autocomplete.name]: Autocomplete,
    },

    data() {
      return {
        text: "",
        activeVariables: false,
        disabledUpdateText: false,
        variablesSelect: "",
        timeoutUpdate: null,
      };
    },

    mounted() {
      if (this.value) {
        this.text = this.value;
      }
    },

    watch: {
      text(n, o) {
        if (this.activeVariables) {
          this.updateVariableText(n, o);
          this.activeVariables = false;
          return;
        } else {
          if (!this.dataVariables.length) return;

          if (this.disabledUpdateText) {
            this.disabledUpdateText = false;
            return;
          }

          let variablesOld = o.split("{{");
          let variablesNew = n.split("{{");
          if (
            variablesNew.length > 1 &&
            variablesOld.length === variablesNew.length
          ) {
            let v = variablesNew[variablesNew.length - 1];
            if (v.split("}}").length < 2) {
              this.disabledUpdateText = true;
              this.text = o;
            }
          }
        }
      },
    },

    methods: {
      selectVariable() {
        this.activeVariables = true;
      },

      updateVariableText(n, o) {
        this.text = o + n + "}}";
      },

      updateData() {
        if (this.timeoutUpdate === null) clearTimeout(this.timeoutUpdate);

        this.timeoutUpdate = setTimeout(() => {
          this.$emit("updateValue", this.text);
        }, 50);
      },

      addVariable(variable) {
        let text = this.text.trim();
        if (text.length >= 2) {
          if (this.text.slice(-2) === "{{") {
            this.text = this.text.trim() + `${variable}}}`;
            this.updateData();
            return;
          }
        }

        this.text = this.text.trim() + `{{${variable}}}`;
        this.updateData();
      },
      
      isertEmoji(emoji) {
        this.text += emoji;
        this.updateData();
      },

      viewMoreMarkdown() {
        window.open("https://www.markdownguide.org/basic-syntax", "_blank");
      },
    },

    computed: {
      dataVariables() {
        let res = [];

        if (this.variables) {
          res = this.variables.map((v) => {
            return {
              value: v.name,
            };
          });
        }

        return res;
      },

      tooltipMarkdown() {
        return "You can use commands to add styles to your text. See more in https://www.markdownguide.org/basic-syntax";
      }
    },
  };
</script>

<style>
  .el-dropdown-menu__item:focus,
  .input-action .el-dropdown-menu__item:hover,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #fcfcfc !important;
    color: #606266 !important;
  }
</style>
