<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive overflow-auto" :class="{'min-height' : model.files.length}">
          <table class="table" :class="{'table-hover' : model.files.length}">
            <thead>
            <tr>
              <th>{{ $t("Name") }}</th>
              <th>{{ $t("TYPE") }}</th>
              <th>{{ $t("Status") }}</th>
              <th>{{ $t("Action") }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!model.files.length && showTableBox">
              <td colspan="6">
                <div class="text-center p-5 dropzone">
                  <p>{{ $t('You have nothing to see') }}<p/>
                </div>
              </td>
            </tr>

            <slot name="append-data"></slot>

            <tr v-for="(file, index) in model.files" :key="file.id">
              <td>
                <div class="filename">
                  {{file.name}}
                </div>
                <div class="progress" v-if="file.active || file.progress !== '0.00'">
                  <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
                </div>
              </td>

              <td>{{file.file_type }}</td>
              <td v-if="file.error">{{file.error}}</td>
              <td v-else-if="file.success">success</td>
              <td v-else-if="file.active">active</td>
              <td v-else></td>
              <td>
                <p-button  
                  type="danger" 
                  size="sm" icon @click="remove(file)">
                  <i class="fa fa-remove"></i>
                </p-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-if="model.files.length">
          <button type="button"
                  class="btn btn-info"
                  v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
            <i class="nc-icon nc-minimal-up" aria-hidden="true"></i>
            {{ $t('Start Upload') }}
          </button>
          <button type="button"
                  class="btn btn-danger"
                  v-else @click.prevent="$refs.upload.active = false">
            <i class="fa fa-stop" aria-hidden="true"></i>
            {{ $t('Stop Upload') }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
          <div class="btn-group">
            <file-upload class="btn btn-success"
                         ref="upload"
                         v-model="model.files"
                         name="attachment"
                         :post-action="fileUploadUrl"
                         :headers="uploadHeaders"
                         :multiple="true"
                         :directory="false"
                         :drop="false"
                         :disabled="disabled"
                         @input-file="inputFile"
                         @input-filter="inputFilter">
              <i class="nc-icon nc-simple-add"></i>
              {{ $t('Select Targetbase') }}
            </file-upload>

            <div class="row">
              <div class="col-md-6">
                <span :disabled="disabled" class="btn btn-danger file-uploads file-uploads-html5">
                  <i class="nc-icon nc-simple-add"></i>
                    {{ $t('Select Blacklist') }}
                    <label @click="setButtonClick" for="attachment"></label> 
                </span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style>
  .dropdown .dropdown-menu[x-placement="bottom-start"], .dropup .dropdown-menu[x-placement="bottom-start"], .bootstrap-select .dropdown-menu[x-placement="bottom-start"] {
    transform: translate3d(-60px, 0px, 0) !important;
  }
  .dropdown.show .dropdown-menu[x-placement="bottom-start"], .dropup.show .dropdown-menu[x-placement="bottom-start"], .bootstrap-select.show .dropdown-menu[x-placement="bottom-start"] {
    transform: translate3d(-60px, 30px, 0) !important;
  }
</style>

<style scoped>
  .min-height {
    min-height: 350px;
    max-height: 350px;
  }

  label.btn {
    margin-bottom: 10px;
    color: white;
  }

  .dropzone {
    border: 2px dashed #51cbce;
    border-radius: 5px;
  }

  .dropzone > .align-middle {
    text-align: center;
    margin-top: 50px;
  }

  .overflow-auto {
    overflow: auto;
  }
</style>

<script>
  const VueUploadComponent = require("vue-upload-component");
  import DropDown from 'src/components/UIComponents/Dropdown.vue';
  import api from "src/api";

  export default {
    components: {
      DropDown,
      "file-upload": VueUploadComponent
    },

    mounted(){
      /*
      this.$nextTick(function () {
        document.getElementById("attachment").addEventListener("click", () => {
          console.log("mamama")
        });
      });
      */
    },

    data() {
      return {
        buttonClicked: "TARGET_BASE",
        fileUploadUrl: api.getBaseUrl() + "/uploads",
        uploadHeaders: api.getHeaders(),
        model: {
          files: []
        }
      };
    },

    props: {
      showTableBox: {
        type:  Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      setButtonClick() {
        this.buttonClicked = "BLACKLIST";
      },

      remove(file) {
        this.$refs.upload.remove(file);
        if(file.response && file.response.data) {
          this.$emit('file-removed', file.response.data);
        }
      },

      inputFile: function(newFile, oldFile) {
        if (newFile && oldFile && ! newFile.active && oldFile.active) {
          if (newFile.xhr) {
            if (newFile.xhr.status === 200) {
              this.$emit('file-uploaded', newFile);
            }
          }
        }
      },

      inputFilter: function(newFile, oldFile, prevent) {
        if (! newFile) {
          return;
        }

        if (newFile && ! oldFile) {
          // Filter non-image file
          if (!/\.(txt)|\.(zip)||\.(csv)|\.(xls)|\.(xlsx)$/i.test(newFile.name)) {
            console.log("File type not supported...");
            return prevent();
          }

          newFile.file_type  = this.buttonClicked;
          newFile.postAction = newFile.postAction + "?file_type=" + this.buttonClicked;
          //this.$emit('file-loaded', newFile);
        }

        // Create a blob field
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;

        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }

        this.buttonClicked = "TARGET_BASE";
      },

    }
  };
</script> 