<template>
  <div class="row justify-content-end">
    <div class="col-auto">
      <label>{{ $t("Exclude Ping campaigns?") }}</label><br />
      <p-switch
        :value="value"
        @input="val => $emit('input', val)"
        type="primary"
        on-text="ON"
        off-text="OFF"
      ></p-switch>
    </div>
  </div>
</template>

<script>
import PSwitch from "src/components/UIComponents/Switch.vue";

export default {
  components: {
    PSwitch
  },
  props: {
    value: Boolean
  },
};
</script>