<template>
  <div class="col-md-3">
    <div class="form-group has-label">
      <label>{{ $t(field.tooltip) }}</label>
      <el-select
        size="large"
        :placeholder="field.tooltip"
        :name="field.name"
        :value="cleanValue"
        v-validate="field.required ? 'required' : ''"
        @input="$emit('input', $event)"
        :disabled="disabled"
      >
        <el-option
          v-for="option in field.list_values"
          :value="option.backend_value"
          :label="$t(option.frontend_value)"
          :key="option.backend_value"
        ></el-option>
      </el-select>
    </div>

    <div
      v-show="errors.has(field.name)"
      class="text-danger invalid-feedback"
      style="display: block;"
    >{{ errors.first(field.name) }}</div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {
  //inject: [ 'parentValidator' ],

    props: ["value", "field", "disabled"],

    components: {
        [Select.name]: Select,
        [Option.name]: Option
    },

    created() {
        //this.$validator = this.parentValidator
    },

    computed: {
        cleanValue(){
            if(typeof this.value == 'boolean'){
                return (this.value ? 1 : 0);
            }

            return this.value;
        }
    }
};
</script>