export default (field) => {
  return {
    created() {
        console.log("Second action mixin created in " + JSON.stringify(field));
    },

    computed: {
      canUseSecondAction() {
        const res = (typeof this[field].account_scenario_type_id !== "undefined" &&
        this[field].account_scenario_type_id !== null);
        //console.log("CAN USE SA: " + this[field].account_scenario_type_id + ", VAL: " + res)
        return res

      }
    },

    methods: {
      onSecondactionsUpdated(model) {
        //console.log("SA UPDATEEEEEED");
        //console.log(model)
        this.$set(this[field], "second_actions", model);
      },

      resetSecondActions() {
        //console.log("RESETTTT SA");
        this.$set(this[field], "second_actions", []);
      }
    }
  };
};