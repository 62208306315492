<template>
  <div class="choice"
       :class="{active: realValue === value}"
       data-toggle="wizard-checkbox"
       @click="updateValue(realValue)">

    <input type="radio"
           :name="name"
           :disabled="disabled"
           :checked="realValue === value">

    <div class="icon">
      <slot name="icon">
        <i :class="icon"></i>
      </slot>
    </div>

    <slot name="title">
      <h6>{{title}}</h6>
    </slot>
  </div>
</template>
<script>
  export default {
      name: 'icon-radio',

      props: [
          "checked", "name", "title", "icon", "disabled", "value", "real-value"
      ],

      methods: {
        updateValue($event){
            this.$emit('input', $event);
        }
      }
  }
</script>
<style>
</style>
