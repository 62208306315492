<template>
  <div class="outbound-index">
    <div class="card">
      <div class="d-flex justify-content-between align-items-center px-3 pt-3">
        <h1 class="h6 mb-0">{{ $t("Campaigns") }}</h1>
        <div class="d-flex justify-content-between align-items-center container-search">
          <el-input
            :placeholder="$t('Search')"
            v-model="search"
            suffix-icon="el-icon-search"
            size="small"
            class="mr-3"
          ></el-input>

          <el-button type="primary" size="small" @click="modalCreate = true">{{ $t("Create campaign") }}</el-button>
        </div>
      </div>

      <el-table :data="tableData" class="w-100" stripe>
        <el-table-column label="ID" prop="id" width="75"></el-table-column>
        <el-table-column label="Name" prop="name"></el-table-column>
        <el-table-column label="Template" prop="template_name"></el-table-column>
        <el-table-column label="Channel">
          <template slot-scope="scope">
            <span>{{ getService(scope.row.service_id).channel_id === 8 || setChannelName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Service">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                getService(scope.row.service_id).agent_options
                  ? getService(scope.row.service_id).agent_options.phone
                  : ''
              "
              :disabled="!getService(scope.row.service_id).agent_options"
              placement="top"
            >
              <span>{{ getService(scope.row.service_id).name }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Date">
          <template slot-scope="scope">
            <span>{{
              scope.row.start_date ? new Date(scope.row.start_date).toLocaleString("es-ES", optionsDate) : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Target">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="`Date range: ${scope.row.date_range}`"
              placement="top"
              :disabled="getService(scope.row.service_id).channel_id === 8"
            >
              <span>{{ scope.row.audience }}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- <el-table-column label="Target" prop="target"></el-table-column> -->

        <el-table-column label="Status" width="100">
          <template slot-scope="scope">
            <span class="status" :class="statusName(scope.row.status).toLowerCase()">
              {{ statusName(scope.row.status) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Actions" width="130">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(btn.tooltip)"
              placement="top"
              :key="btn.tooltip"
              v-for="btn in buttonsActions"
            >
              <el-button
                v-if="
                  checkViewAction({
                    status: scope.row.status,
                    tooltipAction: btn.tooltip,
                    channel_id: getService(scope.row.service_id).channel_id,
                  })
                "
                type="text"
                class="icon-action py-0"
                @click="btn.action(scope.row.id)"
              >
                <i :class="btn.icon"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="campaignStats ? campaignStats.name : ''"
      :visible.sync="dialogStatusVisible"
      width="320px"
      @closed="closeDialogStats"
    >
      <div class="d-flex justify-content-center py-5" v-if="loadingStats">
        <i class="el-icon-loading" style="font-size: 20px"></i>
      </div>
      <el-table :data="tableStats" class="w-100" size="small" stripe :header-cell-class-name="() => 'py-1'" v-else>
        <el-table-column label="Status" prop="status"></el-table-column>
        <el-table-column label="Messages" prop="value"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="info" @click="dialogStatusVisible = false">{{ $t("Cancel") }}</el-button>
        <el-button size="small" type="primary" @click="exportToExcel">{{ $t("Download report") }}</el-button>
      </span>
    </el-dialog>

    <el-dialog width="30%" class="confirm-dialog" :visible.sync="modalCreate" title="Select channel">
      <el-select placeholder="Select channel" v-model="idChannelCreate" class="d-block my-2 mx-4">
        <el-option :value="channel.id" :label="channel.name" :key="channel.id" v-for="channel in channels"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="formView" :disabled="!idChannelCreate"> Create </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // import api from "src/api";
  import { Input, Button, Table, TableColumn, Tooltip, Select, Dialog } from "element-ui";
  import api from "src/api";
  import * as Export2Excel from "src/app-components/Export2Excel";
  import swal from "sweetalert2";
  import { mapMutations } from "vuex";
  export default {
    name: "BroadcastCampaignIndex",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Select.name]: Select,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dialog.name]: Dialog,
    },

    data() {
      return {
        search: "",

        campaigns: [],
        services: [],

        dialogStatusVisible: false,

        intervalCampaigns: null,

        optionsDate: {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },

        campaignStats: null,
        tableStats: [],
        loadingStats: false,
        timeoutStats: null,

        modalCreate: false,
        idChannelCreate: "",
      };
    },

    created() {
      if (!this.$can("Views", "Broadcast")) this.$router.push({ name: "DashboardIndex" });

      this.setTopNavbarTitle("Broadcast");
      this.getCampaignsOutbound();
      this.intervalCampaigns = setInterval(() => {
        this.getCampaignsOutbound();
      }, 2500);

      this.getServices();
    },

    beforeDestroy() {
      clearInterval(this.intervalCampaigns);
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getServices() {
        let res = await api.getServices();
        if (res.status) {
          this.services = [...res.data];
          // console.log("services", this.services);
        }
      },

      getService(id) {
        let service = this.services.find((s) => s.id === id);
        if (service) {
          return service;
        }
        return {};
      },

      async getCampaignsOutbound() {
        try {
          let res = await api.getCampaignsOutbound();
          if (res.status) {
            this.campaigns = res.data ? [...res.data] : [];
          }
        } catch (error) {
          console.log("error catch getCampaignsOutbound", error);
        }
      },

      async statsCampaignsOutbound(id) {
        try {
          let res = await api.statsCampaignsOutbound(id);
          if (res.status) {
            const stats = res.data;

            if (stats) {
              this.tableStats = [
                {
                  status: "Waiting",
                  value:
                    stats.messages_total -
                    stats.messages_sent -
                    stats.messages_read -
                    stats.messages_fail -
                    stats.messages_blacklist,
                },
                {
                  status: "Sent",
                  value: stats.messages_sent,
                },
                {
                  status: "Read",
                  value: stats.messages_read,
                },
                {
                  status: "Error sending",
                  value: stats.messages_fail,
                },
                {
                  status: "Blacklist",
                  value: stats.messages_blacklist,
                },
                {
                  status: "TOTAL",
                  value: stats.messages_total,
                },
              ];
            }

            const campaign = this.campaigns.find((c) => c.id === id);
            if (campaign.status !== 7) {
              console.log("en 5 me activo otra vez");
              this.timeoutStats = setTimeout(() => {
                this.statsCampaignsOutbound(id);
              }, 1000);
            } else {
              console.log("no vuelvo a ejecutar, ya finalizó");
            }
          }
        } catch (error) {
          console.log("error catch statsCampaignsOutbound", error);
        }

        this.loadingStats = false;
      },

      formView() {
        let channel = this.channels.find((c) => c.id === this.idChannelCreate);

        if (channel) {
          this.idChannelCreate = null;
          this.$router.push({ name: channel.routeCreate });
          this.modalCreate = false;
        }
      },

      handlePlay(id) {
        swal({
          title: "Play?",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, play it!",
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            await api.toggleStatusCampaignsOutbound(id);
          }
        });
      },

      handlePause(id) {
        swal({
          title: "Pause?",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, pause it!",
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            console.log("Pause id: ", id);
            await api.toggleStatusCampaignsOutbound(id);
          }
        });
      },

      handleEdit(id) {
        console.log("handleEdit", id);
        this.$router.push({ name: `BroadcastCampaignsFormEdit`, params: { id } });
      },

      handleDelete(id) {
        swal({
          title: "Delete?",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await api.deleteCampaignsOutbound(id);
            if (res.status) {
              let index = this.campaigns.findIndex((c) => c.id === id);
              if (index !== -1) {
                this.campaigns.splice(index, 1);
              }
            }
          }
        });
      },

      handleStats(id) {
        console.log("Stats id: ", id);
        let campaign = this.campaigns.find((c) => c.id === id);
        if (campaign) {
          this.campaignStats = campaign;
          this.statsCampaignsOutbound(id);
          this.loadingStats = true;
          this.dialogStatusVisible = true;
        }
      },

      closeDialogStats() {
        this.campaignStats = null;
        this.tableStats = [];
        this.loadingStats = false;
        clearTimeout(this.timeoutStats);
      },

      checkViewAction({ status, tooltipAction, channel_id }) {
        const actions = {
          play: (status) => {
            return status === 4 || status === 8 || status === 15;
          },
          pause: (status) => {
            return status === 5 && channel_id !== 7;
          },
          edit: (status) => {
            return status === 15 || status === 4;
          },
          stats: (status) => {
            // return true;
            return status !== 15 && status !== 12 && status !== 4;
          },
          delete: (status) => {
            return status === 15 || status === 4;
          },
        };

        if (actions[tooltipAction.toLowerCase()]) {
          return actions[tooltipAction.toLowerCase()](status);
        }

        return true;
      },

      statusName(id) {
        const status = {
          4: "Scheduled",
          5: "Running",
          7: "Finished",
          8: "Paused",
          12: "Starting",
          15: "Ready",

          18: "Pausing",
          19: "Resuming",
        };

        if (status[`${id}`]) {
          return status[`${id}`];
        }

        return "";
      },

      async exportToExcel() {
        const tHeader = ["Status", "Messages"];
        const data = this.tableStats.map((message) => {
          return [message.status, message.value];
        });

        let formats = [];
        let filename = this.campaignStats ? this.campaignStats.name : "";
        Export2Excel.export_json_to_excel({
          header: tHeader,
          data,
          formats,
          filename,
          autoWidth: true,
          bookType: "xlsx",
        });
      },
    },

    computed: {
      channels() {
        return [
          {
            id: 7,
            name: "GBM",
            routeCreate: "BroadcastCampaignsFormGBMCreate",
          },
          {
            id: 8,
            name: "WhatsApp Api Cloud",
            routeCreate: "BroadcastCampaignsFormWPPCreate",
          },
        ];
      },

      tableData() {
        // return this.campaigns;
        return this.campaigns.filter((campaign) => campaign.name.toLowerCase().includes(this.search.toLowerCase()));
      },

      buttonsActions() {
        return [
          {
            icon: "el-icon-video-play",
            action: this.handlePlay,
            tooltip: "Play",
          },
          {
            icon: "el-icon-video-pause",
            action: this.handlePause,
            tooltip: "Pause",
          },
          {
            icon: "el-icon-edit",
            action: this.handleEdit,
            tooltip: "Edit",
          },
          {
            icon: "el-icon-data-analysis",
            action: this.handleStats,
            tooltip: "Stats",
          },
          {
            icon: "el-icon-delete",
            action: this.handleDelete,
            tooltip: "Delete",
          },
        ];
      },
    },
  };
</script>

<style scoped>
  .container-search {
    max-width: 325px;
  }

  span.status {
    display: inline-block;
    padding: 0 10px;
    border-radius: 20px;
    color: white;
    background: #939392;
    text-align: center;
    font-weight: bold;
  }

  span.status.ready {
    background: #027a48;
  }
  span.status.running {
    color: #027a48;
    border: 1px solid #027a48;
    background: #ecfdf3;
  }

  span.status.finished {
    background: #b42318;
  }

  .icon-action {
    outline: 0 !important;
  }
  .icon-action i {
    font-size: 16px;
    color: #303030;
  }
</style>

<style>
  .outbound-index .el-dialog__body {
    padding: 0;
  }

  .outbound-index .el-dialog__body table > thead > tr > th {
    height: 32px !important;
  }
</style>
