<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Campaign Type') }}</label>
        <el-select
          :value="local"
          @input="$emit('input', $event)"
          size="large"
          name="campaign_type"
          :clearable="true"
          :placeholder="''">
          <el-option v-for="option in types"
            :value="option.id"
            :label="$t(option.name)"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null
  },

  created() {
    this.getCampaignTypes();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  data() {
    return {
      types: []
    };
  },

  methods: {
    getCampaignTypes() {
      this.types = [
          { id: 'SINGLE', name: "On Demand" },
          { id: 'RETRY', name: "Retry" },
          { id: 'PERFORMANCE', name: "Programmatic" },
          { id: 'PING', name: "Ping" }
      ];
    }
  }
};
</script>

<style>
</style>
