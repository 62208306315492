<template>
    <div class="row">
        <div :class="requiredContextWidth">
            <slot>
                <div v-show="requiredFieldsCount > 0">
                    <scenario-creativities-elements v-model="local"
                                                    :disabled="disabledCreativities"
                                                    :scenario="scenarioId"
                                                    :width="requiredFieldsWidth"
                                                    @schema-changed="onCampaignSchemaChanges">
                        <template slot="title">
                            <h5 class="text-left" style="font-size: 1rem">{{ $t('Creatives') }}</h5>
                        </template>
                    </scenario-creativities-elements>
                    <scenario-required-elements v-model="local"
                                                :scenario="scenarioId"
                                                :width="requiredFieldsWidth"
                                                @schema-changed="onCampaignSchemaChanges">
                    </scenario-required-elements>
                </div>
            </slot>
        </div>
        <div class="col-md-4">
            <portal-target name="campaignConfigurationInProductConfiguration">
            </portal-target>
        </div>
        <div class="col-12 mt-3" v-show="optionalFieldsCount > 0" v-if="!hideBookmarkInfo">
            <collapse>
                <collapse-item
                        :title="$t('Optional configuration')"
                        name="1"
                        headerClass="col-md-3"
                        bodyClass="col-md-12 pl-0 pr-0"
                >
                    <scenario-optional-elements
                            v-model="local"
                            :scenario="scenarioId" type="not-required"
                            @schema-changed="onCampaignSchemaChanges"
                            :disabled="!isEditable"
                            :class="{'text-left': optionalFieldsCount === 1}"
                    >
                    </scenario-optional-elements>
                </collapse-item>
            </collapse>
        </div>
    </div>
</template>

<script>
    import { Collapse } from 'src/components/UIComponents'
    import CollapseItem from "src/custom-components/UIComponents/CollapseItem";
    import ScenarioRequiredElements from 'src/app-components/form-elements/generators/ScenarioRequiredElements'
    import ScenarioCreativitiesElements from 'src/app-components/form-elements/generators/ScenarioCreativitiesElements'
    import ScenarioCallToActionElements from 'src/app-components/form-elements/generators/ScenarioCallToActionElements'
    import ScenarioOptionalElements from 'src/app-components/form-elements/generators/ScenarioOptionalElements'
    import { PortalTarget } from 'portal-vue'

    export default {
        name: 'campaign-flow',

        props: {
            value: Object,
            scenarioId: Number,
            disabledCreativities: Boolean,
            isEditable: {
                type: Boolean,
                default: true
            },
            hideBookmarkInfo: {
                type: Boolean,
                default: false
            }
        },

        components: {
            Collapse,
            CollapseItem,
            [ScenarioCreativitiesElements.name]: ScenarioCreativitiesElements,
            [ScenarioRequiredElements.name]: ScenarioRequiredElements,
            [ScenarioOptionalElements.name]: ScenarioOptionalElements,
            [ScenarioCallToActionElements.name]: ScenarioCallToActionElements,
            PortalTarget
        },

        data() {
            return {
                retrieved_fields: {
                    'scenario-required-elements': {
                        counted: 3
                    },
                    'scenario-creativities-elements': {
                        counted: 0
                    },
                    'scenario-optional-elements': {
                        counted: 0
                    },
                },
            }
        },

        computed: {
            local: {
                get() {
                    return this.value ? this.value : {}
                },

                set(nv, ov) {
                    this.$emit("input", nv)
                }
            },

            requiredFieldsCount() {
                return this.retrieved_fields['scenario-creativities-elements'].counted + this.retrieved_fields['scenario-required-elements'].counted;
            },

            optionalFieldsCount() {
                return this.retrieved_fields['scenario-optional-elements'].counted;
            },

            requiredContextWidth() {
                const fields = this.requiredFieldsCount;
                return fields <= 1 ? 'col-md-3' : fields === 2 ? 'col-md-6' : 'col-md-8';
            },

            requiredFieldsWidth() {
                const fields = this.requiredFieldsCount;
                return fields <= 1 ? 'col-md-12' : fields === 2 ? 'col-md-6' : 'col-md-4';
            }
        },

        methods: {
            onCampaignSchemaChanges(component, length) {
                if (component === undefined || length === undefined) {
                    return;
                }

                this.retrieved_fields[component].counted = length;
            }
        }
    }
</script>

<style scoped>
    :deep(a:hover) {
        text-decoration: unset;
    }

    :deep(.card-collapse .card .card-header::after) {
        background-color: unset;
    }

    :deep(.card-collapse .card .card-body) {
        padding: unset;
    }
</style>