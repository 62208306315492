export default function(selector = '.items') {
    
    let isDown = false;
    let startX;
    let scrollLeft;

    let slider;

    const end = () => {
        isDown = false;
        slider.classList.remove('active');
    }

    const start = (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;	
    }

    const move = (e) => {
        if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        const dist = (x - startX);
        slider.scrollLeft = scrollLeft - dist;
    }

    return {
        
        mounted() {
            slider = document.querySelector(selector);
            this.initSlider();
        },
    
        beforeUnmount(){
            this.destroySlider()
        },

        methods: {

            initSlider() {   
                (() => {
                    slider.addEventListener('mousedown', start);
                    slider.addEventListener('touchstart', start);
                
                    slider.addEventListener('mousemove', move);
                    slider.addEventListener('touchmove', move);
                
                    slider.addEventListener('mouseleave', end);
                    slider.addEventListener('mouseup', end);
                    slider.addEventListener('touchend', end);
                })();
            },

            destroySlider() {
                (() => {
                    slider.removeEventListener('mousedown', start);
                    slider.removeEventListener('touchstart', start);
                
                    slider.removeEventListener('mousemove', move);
                    slider.removeEventListener('touchmove', move);
                
                    slider.removeEventListener('mouseleave', end);
                    slider.removeEventListener('mouseup', end);
                    slider.removeEventListener('touchend', end);
                })();
            },


        },
    }
}