<template>
    <div class="row mb-1">
        <div class="col-md-12">
            <h6 :class="headerStyle">{{ $t(text) }}</h6>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        },
        topMargin: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        headerStyle () {
            return `pull-left ${ this.topMargin ? "mt-4" : "" }`
        }
    }
}
</script>

<style>

</style>
