import sio from "socket.io-client";
import { store } from './store';

function getSioAuthFromUserData(userData){
    return {
        account_id: userData.selected_account_id,
        advertiser_id: userData.advertiser_id,
        user_id: userData.id,
    };
}

var socket = null;
const socketListeners = {
    "*": []
};

/**
 * Bind existing listener to a new socket instance
 */
function bindListeners(socket){
    console.debug("sio: binding event listeners to new socket instance...");
    for(const eventName in socketListeners){
        const eventListeners = socketListeners[eventName];
        if(eventName === "*"){
            for(const callback of eventListeners){
                socket.onAny(callback);
            }
        } else {
            for(const callback of eventListeners){
                socket.onAny(callback);
            }

        }
    }
}

const sioProxy = {
    on: function(eventName, callback){
        if(!(eventName in socketListeners)){
            socketListeners[eventName] = [];
        }
        socketListeners[eventName].push(callback);

        if(socket){
            socket.on(eventName, callback);
        }
    },
    onAny: function(callback){
        socketListeners["*"].push(callback);
        if(socket){
            socket.onAny(callback);
        }
    }
};

store.subscribe((mutation, state) => {
    if(mutation.type !== 'createSession') return; 
    
    if(socket){
        console.debug("sio: new session detected. Closing current websocket connection...");
        socket.close();
    }

    if(process.env.VUE_APP_WS_URL){
        console.debug(`sio: Connecting websocket to ${process.env.VUE_APP_WS_URL}`);
        socket = sio(process.env.VUE_APP_WS_URL, {
            auth: {
                user: getSioAuthFromUserData(mutation.payload),
            },
        });
        bindListeners(socket);
    }
});

export default sioProxy;