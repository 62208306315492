<template>
  <div class="upload-segmentation">
    <div class="card-body segmentation-dropzone" :id="indexNameDropzone">
      <div v-show="!files.length">
        <p class="description-input">
          {{
            $t(
              "Drop your target-base files"
            )
          }}
        </p>
        <file-upload
          class="btn-file"
          :ref="indexName"
          :input-id="`${indexName}-id`"
          v-model="files"
          name="targetbase"
          :directory="false"
          :drop="`#${indexNameDropzone}`"
          :post-action="fileUploadUrl"
          :headers="uploadHeaders"
          @input-file="inputFile"
          @input-filter="checkDroppedFile"
        >
        </file-upload>
      </div>

      <div :class="files.length ? 'd-flex align-items-center' : 'd-none'">
        <input-tag
          :value="filesNames"
          @input="deleteFile"
          :read-only="isDisabled"
        ></input-tag>
          
        <!-- <span class="ml-2"><i class="fa fa-circle text-success"></i> Valids: 5 / <i class="fa fa-circle text-danger"></i> Invalids: 0</span> -->
      </div>
    </div>
    <div class="card-footer py-0">
      <button
        type="submit"
        class="btn btn-sm btn-info"
        @click="startUpload"
        :disabled="buttonUploadDisabled"
      >
        <span v-show="!loading || upload">
          {{ $t(buttonText) }}
        </span>
        <i v-show="loading && !upload" class="px-4 el-icon-loading"></i>
      </button>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import swal from "sweetalert2";
import InputTag from "vue-input-tag";
const VueUploadComponent = require("vue-upload-component");
import { Select, Option } from "element-ui";

export default {
  name: "UploadSegmentation",
  props: ["value", "disabled", "index"],

  components: {
    InputTag,
    "file-upload": VueUploadComponent,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      files: [],

      upload: false,
      loading: false,

      fileUploadUrl: api.getBaseUrl() + "/uploads?file_type=SEGMENTATION_TARGET_BASE",
      uploadHeaders: api.getHeaders(),
      allowedFileTypes: ["txt", "csv"],
    };
  },
  
  watch: {
    files() {
      if(this.files.length === 0) {
        this.upload = false;
        this.updateFileData();
      }
    },
    
    upload() {
      this.loading = this.upload;
      console.log("this.loading", this.loading)
    },
  },

  created() {
    if(this.value.name) {
      this.files = [{name: this.value.name}];
      this.upload = true;
    }else {
      this.updateFileData();
    }
  },

  mounted() {
    this.disabledInputTag();
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr && newFile.xhr.status === 200) {
          const accountFile = newFile.response.data;
          
          this.$refs[this.indexName].update(newFile, { accountFile: accountFile });
          if(newFile.response.data) {
            this.upload = true;
            this.updateFileData(newFile.response.data);
          }
          // TODO: Agregar mensaje al usuario
          // this.files = [];
        }
      }
    },

    checkDroppedFile: function(newFile, oldFile, prevent) {
      if (!newFile) {
        return;
      }

      if (newFile && !oldFile) {
        // Filter files
        const ext = newFile.name
          .substr(newFile.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (!this.allowedFileTypes.includes(ext)) {
          swal.fire({
            icon: "error",
            title: "File type not supported",
            text: "Supported types: " + this.allowedFileTypes.join(", "),
          });
          return prevent();
        }
      }
    },

    startUpload() {
      this.$refs[this.indexName].active = true;
      this.loading = true;
      console.log(this.$refs[this.indexName]);
    },

    deleteFile(arr) {
      this.files.forEach((file, i) => {
        if (arr.indexOf(file.name) === -1) {
          this.files.splice(i, 1);
        }
      });
    },

    disabledInputTag() {
      // let input = document.querySelector("segmentation-dropzone .new-tag");
      let input = document.querySelector(`#${this.indexNameDropzone}-dropzone`);
      if(input) {
        input.setAttribute("disabled", "disabled");
        input.setAttribute("style", "display: none;");
      }
    },

    updateFileData(data = {path: null, name: ''}) {
      this.$emit('updateFileData', data);
    }
  },

  computed: {
    filesNames() {
      let names = [];
      this.files.forEach((file) => names.push(file.name));
      return names;
    },

    isDisabled() {
      if (this.files.length === 0 || this.disabled) {
        return true;
      }

      return false;
    },

    buttonText() {
      if(this.files.length !== 0 && this.upload) {
        return "Uploaded";
      }
      return 'Start upload';
    },

    indexName(){
      return `segmentation-${this.index}`
    },
    
    indexNameDropzone(){
      return `segmentation-${this.index}-dropzone`
    },

    buttonUploadDisabled() {
      return this.isDisabled || this.upload || this.loading;
    }
  },
};
</script>
<style scoped>
.upload-segmentation {
  display: flex;
  /* flex-direction: column; */
  min-height: 27px;
  /* width: 300px; */
  width: 100%;
  /* height: 500px; */
}

.btn-file {
  width: 100%;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #51bcda;
  border-radius: 5px;
  font-size: 12px;
}

.description-input {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 4px;
  margin-bottom: 0;
  font-size: 12px;
}

.segmentation-dropzone {
  width: calc(100% - 20px);
  margin: 0;
  padding: 0!important;
  position: relative;
}

.bullet {
  font-size: 25px;
}
</style>

<style>
.segmentation-dropzone .vue-input-tag-wrapper {
  border: 0;
  padding-left: 8px;
  padding-top: 0;
}

.segmentation-dropzone .vue-input-tag-wrapper .new-tag {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.segmentation-dropzone .vue-input-tag-wrapper .input-tag {
  background: #51bcda;
  border-color: #51bcda;
  color: white;
  font-size: 12px;
  margin-bottom: 0;
}

.segmentation-dropzone .vue-input-tag-wrapper .input-tag .remove {
  color: white;
}

</style>
