<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group has-label mb-0">
          <label>{{ $t("Type") }}</label>
          <el-select
            @input="$emit('input', $event)"
            size="large"
            :value="local"
            :disabled="! isEditable"
            v-validate="'required'"
            name="file-type"
          >
            <el-option
              v-for="option in types"
              :value="option.id"
              :label="option.name"
              :key="option.name"
            ></el-option>
          </el-select>
        </div>

        <div
          v-show="errors.has('file-type')"
          class="text-danger invalid-feedback"
          style="display: block;"
        >{{ errors.first('file-type') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";

export default {
  inject: ["parentErrors", "parentValidator"],
  props: {
    value: String,
    isEditable: {
      type: Boolean,
      default: true
    }
  },

  async created() {
    this.$validator = this.parentValidator;
    //this.errors = this.parentErrors;
    this.getTypes();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  data() {
    return {
      types: []
    };
  },

  methods: {
    async getTypes() {
      this.types = [
        {id: "ACCOUNT_BLACKLIST", name: this.$t("Blacklist")},
        {id: "ACCOUNT_WHITELIST", name: this.$t("Whitelist")},
        {id: "ACCOUNT_OTHER", name: this.$t("Other")},
      ];
    }
  }
};
</script>

<style>
</style>
