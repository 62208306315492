<template>
  <el-popover
    placement="right"
    trigger="focus"
    :close-delay="0"
    v-model="popover"
  >
    <div style="width: 60px;">
      <el-tooltip
        v-for="opt in options"
        :key="opt.icon"
        effect="dark"
        :content="$t(opt.tooltip)"
        placement="right"
      >
        <el-button
          class="mx-2 my-1 btn-popover"
          :class="opt.item"
          :icon="opt.icon"
          :type="opt.type"
          size="mini"
          @click="createElement(opt.item)"
        ></el-button>
      </el-tooltip>
    </div>
    <el-button :class="`popover-${numberNodeID}-${output}`" slot="reference" class="popover-target" :style="`top: ${top}px;`"></el-button>
  </el-popover>
</template>

<script>
  import { EventBus } from "src/eventBus.js";
  import { Button, Popover } from "element-ui";

  export default {
    name: "CreateElementPopover",
    props: ["numberNodeID", "output", "optionsLength"],
    components: {
      [Popover.name]: Popover,
      [Button.name]: Button,
    },

    data() {
      return {
        popover: false,
        top: 12,
      };
    },

    mounted() {
      EventBus.$on("activePopover", ({node, output}) => {
        if(node == this.numberNodeID && this.output == output.slice(7)) {
          let popover = document.querySelector(`.popover-${this.numberNodeID}-${this.output}`);
          if(popover) popover.focus();
        }
      });

      if(this.optionsLength) {
        this.setPositionOptions();
      }
    },

    watch: {
      optionsLength() {
        if(this.top !== 0) {
          this.setPositionOptions();
        }
      }
    },

    methods: {
      createElement(item = null) {
        if (!item) return;
        let node = document.querySelector(`#node-${this.numberNodeID}`);
        if (!node) return;

        let x = parseFloat(node.style.left) + 350;
        let y = parseFloat(node.style.top);

        if(this.optionsLength) {
          let output = document.querySelector(`#node-${this.numberNodeID} .output_${this.output}`);
          y += (parseFloat(output.style.top) - 7);
        }

        EventBus.$emit("newItemWithConnection", {
          item,
          x,
          y,
          idParent: this.numberNodeID,
          output: `output_${this.output}`,
        });
        this.popover = false;
      },

      setPositionOptions() {
        setTimeout(() => {
          let output = document.querySelector(`#node-${this.numberNodeID} .output_${this.output}`);
          if(output) {
            this.top = parseFloat(output.style.top) + 6;
          }
        }, 80);
      }
    },

    computed: {
      options() {
        return [
          {
            icon: "el-icon-chat-line-round",
            tooltip: "Create message",
            type: "success",
            item: "Message",
          },
          {
            icon: "el-icon-chat-line-square",
            tooltip: "Create message with options",
            type: "primary",
            item: "MessageWithOptions",
          },
          {
            icon: "el-icon-tickets",
            tooltip: "Data storage",
            type: "info",
            item: "DataStorage",
          },
          {
            icon: "el-icon-guide",
            tooltip: "Condition",
            type: "danger",
            item: "Condition",
          },
          {
            icon: "el-icon-share",
            tooltip: "Integration",
            type: "warning",
            item: "Integration",
          },
          {
            icon: "el-icon-switch-button",
            tooltip: "Close",
            type: "",
            item: "Close",
          },
          {
            icon: "el-icon-help",
            tooltip: "Contact center",
            type: "",
            item: "ContactCenter",
          },
          {
            icon: "el-icon-s-promotion",
            tooltip: "Go to",
            type: "",
            item: "GoTo",
          },
        ];
      },
    },
  };
</script>

<style scoped>
  .popover-target {
    width: 4px;
    height: 4px;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: -2px;
  }
</style>

<style>
  .el-popover {
    min-width: 15px !important;
    padding: 5px;
  }
  
  .dark .el-popover {
    background: #353535;
    border-color: #353535;
  }

  .dark .el-popper[x-placement^=right] .popper__arrow,
  .dark .el-popper[x-placement^=right] .popper__arrow::after {
    border-right-color: #555!important;
  }

  .dark .btn-popover {
    background: #555;
    border-color: #555;
    color: white;
  }
  
  .dark .btn-popover.Message {
    background: #03DAC6;
    border-color: #03DAC6;
  }
  .dark .btn-popover.MessageWithOptions {
    background: #6200EE;
    border-color: #6200EE;
  }
  .dark .btn-popover.DataStorage {
    background: #BB86FC;
    border-color: #BB86FC;
  }
  .dark .btn-popover.Condition {
    background: #CF6679;
    border-color: #CF6679;
  }
</style>
