<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group has-label">
                    <label>Second action enabled?</label>
                    <br />
                    <p-switch v-model="saEnabled"
                            type="primary"
                            on-text="ON"
                            off-text="OFF"
                            :disabled="disabled"
                            @input="onInput('[second-action-enabled].value', $event)"
                    ></p-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep, tap, set } from "lodash";
import PSwitch from "src/custom-components/UIComponents/Switch";

export default {
    inject: ["getError", "parentValidator"],

    components: {
        [PSwitch.name]: PSwitch
    },

    props: {
        value: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        local() {
            if (this.value) {
                if (typeof this.value["second-action-enabled"] === "undefined") {
                    this.value["second-action-enabled"] = {
                        variable_id: 81,
                        value: false
                    }
                }

                return this.value;
            }
        },

        saEnabled: {
            get() {
                return ((this.local["second-action-enabled"]) instanceof Object) ? this.local["second-action-enabled"].value : false;
            },

            set(value) {
                this.local["second-action-enabled"].value = value;
            }
        }
    },

    methods: {
        onInput(key, value) {
            this.$emit("input", tap(cloneDeep(this.local), v => set(v, key, value)));
        },
    }
}
</script>