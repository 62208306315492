<template>
  <div class="card-note">
    <el-input
      type="textarea"
      :rows="2"
      placeholder="Please input"
      autosize
      v-model="dataNode.note"
    >
    </el-input>
  </div>
</template>

<script>
  import { Input } from "element-ui";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "Note",
    mixins: [ChatbotComponentsMixin],
    components: {
      [Input.name]: Input,
    },

    data() {
      return {
        dataNode: {
          note: "",
        },
      };
    },

    watch: {
      "dataNode.note"() {
        this.updateData();
      },
    },
  };
</script>

<style scoped>
  .card-note {
    background: #FFA;
    border-radius: 8px;
    min-height: 80px;
    box-shadow: inset 0 -40px 40px rgba(0, 0, 0, 0.01),
      inset 0 25px 10px rgba(0, 0, 0, 0.01), 0 5px 6px 5px rgba(0, 0, 0, 0.01);
  }
</style>

<style>
  .card-note .el-textarea__inner {
    background: transparent;
    border: 0px;
    min-height: 80px !important;
    resize: none;
    font-weight: bold;
    font-size: 12px;
    color: black;
  }

  .card-note .el-textarea__inner::placeholder,
  .card-note .el-textarea__inner:-ms-input-placeholder,
  .card-note .el-textarea__inner::-ms-input-placeholder {
    color: #666;
    opacity: 1; /* Firefox */
  }

  .wrapperCanvas .drawflow .drawflow-node.Note.selected {
    border: 1px solid #a8a86d;
    -webkit-box-shadow: 0 2px 20px 2px #a8a86d;
    box-shadow: 0 2px 20px 2px #a8a86d;
  }
</style>
