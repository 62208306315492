<template>
    <p-button type="success" size="sm" icon @click="handlePlay(id)">
        <i class="fa fa-play"></i>
    </p-button>
</template>

<script>
import api from "src/api"
import swal from "sweetalert2";

export default {
    props: {
        id: {
            type: Number,
            required: true
        }
    },

    methods: {
        handlePlay(id) {
            swal({
                title: "Enable?",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, enable it!",
                buttonsStyling: false,
                allowOutsideClick: false
            }).then(async result => {
                if (result.value) {
                    const res = await api.updateApiEvent(id, {
                        status_id: 1
                    });
                    if(res.status) {
                        this.$emit('activated');
                    }
                }
            });
        }
    }
}
</script>
