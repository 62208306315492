<script>
import api from "src/api";
import Report from "./Report";
import { excelFormatPercentage, excelFormatNumber } from "./excel_formats.js";

export default {
  name: "OptAgentReport",

  extends: Report,

  created() {
    this.tableCols = [
      { prop: "Agent", label: "Agent", minWidth: 100 },
      {
        prop: "Deliveries",
        label: "Delivered",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 150,
      },
      {
        prop: "OptOut",
        label: "Opt-out",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 150,
      },
      {
        prop: "OptOutRate",
        label: "% Opt out",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
        width: 150,
      },
      {
        prop: "OptIn",
        label: "Opt-In",
        formatter: this.formatterNumber,
        exportFormatter: Number,
        excelFormat: excelFormatNumber,
        width: 150,
      },
      {
        prop: "OptInRate",
        label: "% Opt In",
        formatter: this.formatterPercentage,
        exportFormatter: Number,
        excelFormat: excelFormatPercentage,
        width: 150,
      },
    ];
    this.customTableKeyCols = [{ prop: "Opt", label: "Opt" }];
  },

  methods: {
    /**
     * @Override
     */
    filterProp() {
      return "Agent";
    },
    /**
     * @Override
     */
    tableDimensions() {
      return ["id ASC"];
    },

    /**
     * @Override
     */
    tableRowClassName({ row, rowIndex }) {
      if (row.id === null) {
        return "success-row";
      }

      return "";
    },

    prepareFilter() {
      return {
        date_from: this.filters["campaign_start_datetime >="],
        date_to: this.filters["campaign_start_datetime <="],
        group_by: this.dimensions.map((dimension) => dimension.groupingProp).join(","),
      };
    },

    async getData(query) {
      const filters = this.prepareFilter();
      console.log("calling backend");
      const response = await api.getOptPivot(filters);
      this.tableData = response.data;
    },
  },
};
</script>
