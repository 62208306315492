<template>
  <div>
    <el-transfer
      filterable
      v-model="local"
      :data="data"
      :titles="titles" />

    <div class="col-12 mt-3">
      <input type="hidden" name="minProductsSelected" v-validate="'min_value:1'" v-model="value.length">
      <div class="col-12 alert alert-danger" v-if="getError('minProductsSelected')">
        Please, select at least one product.
      </div>
    </div>
  </div>
</template>

<script>
  import { Transfer } from "element-ui";

  export default {
    props: {
      value: Array,
      data: Array,
      titles: {
        type: Array,
        default: () => ['Source', 'Target']
      }
    },

    inject: ["getError", "parentValidator"],

    components: {
      [Transfer.name]: Transfer
    },

    computed: {
      local: {
        get() {
          return this.value ? this.value : []
        },

        set(nv) {
          this.$emit("input", nv)
        }
      }
    },

    created() {
      this.$validator = this.parentValidator;
    }
  };
</script>
<style scoped>
  :deep(.el-transfer-panel) {
    width: 45%;
  }
</style>