<template>
    <div>
      <BookmarkName :is-unique="false"
        :required="false"
        v-model="filters.name" />

      <Advertisers
          filterable
          v-if="$can('FilterByAdvertiser', 'Bookmarks')"
          clearable
          no-validation
          v-model="filters.advertiser"
          placeholder="Advertiser"
      />
      <Categories
          filterable
          clearable
          no-validation
          v-model="filters.category"
          placeholder="Categoría"
      />
      <SubCategories
          filterable
          clearable
          no-validation
          v-model="filters.subcategory"
          :categoryId="filters.category"
          placeholder="Subcategoría"
      />
      <Status
          filterable
          clearable
          no-validation
          v-model="filters.status"
          placeholder="Status"
      />
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="form-group">
            <p-checkbox v-model="filters.archived">{{ $t('Show archived templates') }}</p-checkbox>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import AdvertisersFormComponent from "src/app-components/form-elements/Advertisers";
  import BookmarkNameComponent from "src/app-components/form-elements/BookmarkName";
  import CategoriesFormComponent from "src/app-components/form-elements/Categories";
  import SubCategoriesFormComponent from "src/app-components/form-elements/SubCategories";
  import StatusFormComponent from "src/app-components/form-elements/Status";
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";

  export default {
      name: "TemplatesFilters",
    components: {
      Advertisers: AdvertisersFormComponent,
      BookmarkName: BookmarkNameComponent,
      Categories: CategoriesFormComponent,
      Status: StatusFormComponent,
      SubCategories: SubCategoriesFormComponent,
    },

    props: {
      defaultValues: Object,
    },

    mixins: [
      FilterPanelMixin((self) => ({
        advertiser: null,
        category: null,
        subcategory: null,
        status: self.defaultValues['PerfText.status_id'],
        archived: false,
        name: null
      }))
    ],

    watch: {
      "filters.category" () {
        this.filters.subcategory = null;
      },
      defaultValues: {
        handler () {
          this.filters.status = this.defaultValues['PerfText.status_id']
          this.filters.advertiser = null;
          this.filters.category = null;
          this.filters.subcategory = null;
          this.filters.archived = false;
          this.filters.name = null;
        },
        //immediate: true,
      }
    },

    methods: {
      prepareFilters (filters) {
        return {
          "advertiser_id": this.fixCleared(this.filters.advertiser),
          "category_id": this.fixCleared(this.filters.category),
          "subcategory_id": this.fixCleared(this.filters.subcategory),
          "name": this.fixCleared(this.filters.name),
          "status": this.fixCleared(this.filters.status),
          "archived": this.filters.archived,
        };
      },
    },
  };
</script>
<style scoped>
</style>