<template>
  <div class="form-global-variables">
    <p class="mb-0 title-option">New variable</p>
    <div
      class="d-flex justify-content-between align-items-center mb-2"
      @keypress.enter="addVariable"
    >
      <el-input
        size="small"
        class="w-50"
        placeholder="Name"
        v-model="newVariable.name"
      ></el-input>
      
      <el-select v-model="newVariable.type" placeholder="Type" size="small" class="w-50 mx-1">
        <el-option label="String" value="string">
        </el-option>
        <el-option label="Number" value="number">
        </el-option>
      </el-select>
      
      <el-input
        size="small"
        class="mx-1 w-100"
        placeholder="Value"
        v-model="newVariable.value"
        :type="newVariable.type === 'number' ? 'number' : 'text'"
      ></el-input>


    <el-checkbox-group v-model="newVariable.editable" size="small">
      <el-checkbox-button class="mb-0 mx-1">
        <i :class="newVariable.editable ? 'el-icon-check' : 'el-icon-close'"></i>
        Editable
      </el-checkbox-button>
    </el-checkbox-group>

      <el-button
        :disabled="!validNewVariable"
        size="small"
        type="primary"
        icon="el-icon-plus"
        class="px-2"
        @click="addVariable"
      ></el-button>
    </div>

    <el-table
      :data="dataVariables"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="Name"
        width="180">
      </el-table-column>
      <el-table-column
        prop="type"
        label="Type"
        width="180">
      </el-table-column>
      <el-table-column label="Value">
        <template slot-scope="scope">
          <span>{{ scope.row.value ? scope.row.value : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Editable" width="100" align="center">
        <template slot-scope="scope">
          <span>
            <i :class="scope.row.editable ? 'el-icon-check' : 'el-icon-close'"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-delete" size="small" @click="removeVariable(scope.$index)"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { Table, TableColumn, Input, Button, CheckboxGroup, CheckboxButton, Select } from "element-ui";
  export default {
    name: "FormVariables",
    props: ["variables"],
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [CheckboxGroup.name]: CheckboxGroup,
      [CheckboxButton.name]: CheckboxButton,
    },

    data() {
      return {
        newVariable: {
          name: "",
          value: "",
          type: "",
          editable: true,
        },

        dataVariables: [],
      };
    },

    mounted() {
      if(this.variables) {
        this.dataVariables = [...this.variables];
      }
    },

    methods: {
      updateData() {
        this.$emit("updateData", { variables: this.dataVariables });
      },

      addVariable() {
        if (!this.validNewVariable) return;

        this.dataVariables.push(this.newVariable);

        this.updateData();

        this.newVariable = {
          name: "",
          value: "",
          type: "",
          editable: true,
        };
      },

      removeVariable(key) {
        if(this.dataVariables[key]) {
          this.dataVariables.splice(key, 1);
          this.updateData();
        }
      }
    },

    computed: {
      nameExist() {
        if (this.newVariable.name !== "") {
          let item = this.dataVariables.find(
            (v) => v.name === this.newVariable.name
          );
          if (item) {
            return item.name;
          }
        }

        return null;
      },

      validNewVariable() {
        if(this.newVariable.name !== "" && this.newVariable.type !== "") {
          if(this.newVariable.value !== "" || this.newVariable.editable) {
            return this.nameExist === null;
          }
        }

        return false;
      },
    },
  };
</script>
