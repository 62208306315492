<template>
    <div>
        <form @submit.prevent="submit">
            <div class="card d-flex flex-md-row flex-sm-column" :class="{'animated' : errors.all(scope).length > 0}" style="overflow-x: hidden">
                <!-- Form -->
                <div class="card-body flex-grow-1">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="mt-0">{{$t('Create query')}}</h4>
                        </div>

                        <ChannelDropdown
                            class="col-md-3 col-sm-6"
                            v-model="queryRequest.channel_id"
                            :required="true"
                            :allowedChannels="[]"
                        />
                        
                        <div class="col-md-2 col-sm-5">
                            <div class="form-group has-label">
                                <label class="important">{{$t("Status")}}</label>
                                <fg-input :error="getError(`${scope}.status`)">
                                    <el-select filterable v-model="queryRequest.target_status_id" v-validate.immediate="'required'" name="status" :data-vv-scope="scope" :placeholder="$t('Select')">
                                        <el-option
                                            v-for="o in status"
                                            :label="$t(o.name)"
                                            :value="o.id"
                                            :key="o.id"
                                        >{{$t(o.name)}}</el-option>
                                    </el-select>
                                </fg-input>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-7">
                            <DateRangePicker
                                :auto-translate-shortcuts="true"
                                @date-range-changed="dateRange = $event"
                                ref="daterange"
                                v-validate="{ campaign_date_range: this.queryRequest.campaign_id.length == 0 && dateRange.length == 0}"
                                name="daterange"
                                :data-vv-scope="scope"
                                :error="getError(`${scope}.daterange`)"
                            />
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="form-group has-label">
                                <label>{{$t("Campaign Type")}}</label>
                                <fg-input :error="campaign_type_error.error ? campaign_type_error.msg : ''">
                                    <el-select
                                        :placeholder="$t('Select')"
                                        multiple
                                        filterable
                                        clearable
                                        collapse-tags
                                        v-model="queryRequest.campaign_type_id"
                                        :disabled="availableCampaignTypes.length === 1"
                                    >
                                        <el-option
                                            v-for="o in availableCampaignTypes"
                                            :label="o.name"
                                            :value="o.id"
                                            :key="o.id"
                                        >{{$t(o.name)}}</el-option>
                                    </el-select>
                                </fg-input>
                            </div>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label>{{$t("Template")}}</label>
                                <el-select
                                    :placeholder="$t('Select')"
                                    multiple
                                    filterable
                                    clearable
                                    collapse-tags
                                    v-model="queryRequest.product_id"
                                >
                                    <el-option
                                        v-for="o in filteredProducts"
                                        :label="o.name"
                                        :value="o.id"
                                        :key="o.id"
                                    >{{$t(o.name)}}</el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="form-group has-label">
                                <label>{{$t("Campaign")}}</label>
                                    <el-select
                                        :placeholder="$t('Select')"
                                        v-loading="loading_campaigns"
                                        element-loading-spinner="el-icon-loading"
                                        element-loading-background="rgb(243 243 243 / 80%)"
                                        multiple filterable clearable collapse-tags
                                        :disabled="loading_campaigns"
                                        v-model="queryRequest.campaign_id"
                                        :data-vv-scope="scope"
                                        name="campaign_id"
                                        v-validate="{ campaign_date_range: this.queryRequest.campaign_id.length == 0 && dateRange.length == 0}"
                                    >
                                        <el-option
                                            v-for="o in campaigns"
                                            :label="o.name"
                                            :value="o.id"
                                            :key="o.id"
                                        >{{$t(o.name)}}</el-option>
                                    </el-select>
                                <small class="text-danger" v-if="hasError(`${scope}.daterange`) && hasError(`${scope}.campaign_id`)">{{ getError(`${scope}.campaign_id`) }}</small>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group has-label">
                                <label>{{$t("Available categories")}}</label>
                                    
                                <!-- <el-tooltip :content="$t('Some text')" placement="right"> -->
                                <el-select
                                    :placeholder="$t('Select')"
                                    multiple
                                    filterable
                                    clearable
                                    collapse-tags
                                    v-model="queryRequest.category_id"
                                >
                                    <el-option
                                        v-for="o in categories"
                                        :label="o.name"
                                        :value="o.id"
                                        :key="o.id"
                                    >{{$t(o.name)}}</el-option>
                                </el-select>
                                <!-- </el-tooltip> -->
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group has-label">
                                <label>{{$t("Subcategories")}}</label>
                                <el-select
                                    :placeholder="$t('Select')"
                                    multiple filterable clearable
                                    v-loading="loading_subcategories"
                                    element-loading-spinner="el-icon-loading"
                                    element-loading-background="rgb(243 243 243 / 80%)"
                                    :disabled="loading_subcategories || queryRequest.category_id.length == 0"
                                    collapse-tags
                                    v-model="queryRequest.subcategory_id"
                                >
                                    <el-option
                                        v-for="o in filteredSubcategories"
                                        :label="o.name"
                                        :value="o.id"
                                        :key="o.id"
                                    >{{$t(o.name)}}</el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-12">
                            <a href="javascript:void(0)" @click="clearForm">
                                <i class="fa fa-times" aria-hidden="true"></i> {{$t('Clear form')}}
                            </a>
                        </div>
                    </div>
                    <!-- OUTPUT OPTIONS -->
                    <OutputOptions
                        :isRcs="queryRequest.channel_id === 6"
                        class="mt-3"
                        v-model="output_options"
                    />
                </div>
                <p-button class="m-0 vertical-btn" type="primary" :disabled="!canSubmit" nativeType="submit">
                    <i class="fa fa-check fa-lg"></i>
                </p-button>
            </div>
        </form>

        <div class="row">
            <div class="col-12">
                <div class="card" v-loading="loading" :element-loading-text="$t('Loading')">
                    <div class="card-body">
                        <CrmCampaignsTable
                            :tableData="indexData"
                            @copyquery="copyQuery($event)"
                            :campaign_type="campaignTypes"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DatePicker, TimeSelect, Input, Tooltip } from "element-ui";
import DateRangePickerFormComponent from "src/app-components/form-elements/DateRangePicker";
import PSwitch from "src/custom-components/UIComponents/Switch";

import moment from "moment";
import { intersection } from "lodash";
import api from "src/api";
import { EventBus } from "src/eventBus";

import CrmCampaignsTable from "src/app-components/crm/campaigns/CrmCampaignsTable";
import ChannelDropdown from "src/app-components/form-elements/ChannelDropdown";
import OutputOptions from "src/app-components/crm/campaigns/OutputOptions.vue";
import { CrmMixin, CampaignValidations } from "src/app-components/crm/CrmMixin";

const PING_REJECTS = ["SINGLE", "PERFORMANCE" , "SECOND_ACTION"]; // "PING" = 2
const PROGRAMMATIC_REJECTS = ["SINGLE", "SECOND_ACTION" ]; // "PERFORMANCE" = 4

export default {

    components: {
        CrmCampaignsTable,
        [DatePicker.name]: DatePicker,
        [TimeSelect.name]: TimeSelect,
        [Tooltip.name]: Tooltip,
        [PSwitch.name]: PSwitch,
        [Input.name]: Input,
        DateRangePicker: DateRangePickerFormComponent,
        OutputOptions,
        ChannelDropdown,
    },

    mixins: [CrmMixin, CampaignValidations],

    data() {
        const scope = "campaign";

        return {
            scope,
            campaign_type_error: {
                error: false,
                msg : this.$t("There are incompatible campaign types")
            },
            loading: false,
            loading_campaigns: false,
            loading_products: false,
            loading_subcategories: false,

            // Datepickers
            dateRange: [],

            // Armado de la query - Filtros seleccionados
            queryRequest: {
                target_status_id: null,
                campaign_type_id: [],
                campaign_id: [],
                product_id: [],
                category_id: [],
                subcategory_id: [],
                fecha_desde: "",
                fecha_hasta: "",
                account_scenario_types: "",
                channel_id: null
            },

            // Output Options
            output_options: {
                // include_iccid: false,
                campaign_data: ["dump_target"],
                split_campaign: false,
                send_email: false,
                dump_unique: true,
            },
            // Objetos de datos para rellenar selects
            campaignTypes: [
                { name: "On Demand"     , id: "SINGLE"        },
                { name: "Ping"          , id: "PING"          },
                { name: "Retry"         , id: "RETRY"         },
                { name: "Programmatic"  , id: "PERFORMANCE"   },
                { name: "Second action" , id: "SECOND_ACTION" },
                { name: "Fallback"      , id: "FALLBACK"      },
            ],
            campaigns: [],
            products: [],
            categories: [],
            subcategories: [],
            // Datos de la tabla
            indexData: [],

        };
    },

    beforeMount() {
        EventBus.$on("crm-campaigns-lambda", async (obj) => {
            // this.$notifications.clear();
            // console.log("WEBSOCKET", obj);
            this.getCrmCampaignsIndex();
        });
    },

    beforeDestroy() {
        EventBus.$off("crm-campaigns-lambda");
    },

    async mounted() {

        this.updateBookmarks();
        api.getBookmarkCategories().then(response => {
            // console.log("Available categories: ", response.data);
            this.categories = response.data;
            this.subcategories = [].concat(
                ...this.categories.map(
                    category => category.subcategories.map(
                        subcat => ({
                            id: subcat.id,
                            name: subcat.name,
                            category_id: category.id
                        })
                    )
                )
            );
            // console.log("Subcategories: ", this.subcategories);
        });

        this.getFilteredCampaigns();
        this.getCrmCampaignsIndex();
    },

    watch: {
        // Temporal hasta que haga las validaciones
        dateRange: function (val) {
            if (val == null) this.dateRange = []
        },

        getCampaignsOptions: async function (params) {
            this.loading_campaigns = true;
            await this.getFilteredCampaigns();
            this.queryRequest.campaign_id = intersection(this.campaigns.map(c => c.id ), this.queryRequest.campaign_id);
            // console.log("IDS",this.queryRequest.campaign_id);
            this.loading_campaigns = false;
        },

        filteredSubcategories: function(array) {
            if (array.length == 0) this.queryRequest.subcategory_id = []
            setTimeout(() => { this.loading_subcategories= false }, 300);
        },

        filteredProducts: function(array) {
            // console.log("PRODUCTOS FILTRADOS",array.map( p => p.id));
            this.queryRequest.product_id = intersection(array.map( p => p.id), this.queryRequest.product_id)
            setTimeout(() => { this.loading_products= false }, 300);
        },

        'queryRequest.campaign_type_id': function (val) {

            this.campaign_type_error.error = Array.isArray(val) && (
                (val.includes("PING") && intersection(val, PING_REJECTS).length > 0) ||
                (val.includes("PERFORMANCE") && intersection(val, PROGRAMMATIC_REJECTS).length > 0)
            );
            console.log("Nuevo valor para campaign_type_id: ", this.queryRequest.campaign_type_id);
        },

        'queryRequest.channel_id': function(newVal){
            this.clearForm(['channel_id']);
            this.updateBookmarks();
        },

        availableCampaignTypes: function(newVal, oldVal){
            if(Array.isArray(newVal) && newVal.length === 1){
                this.queryRequest.campaign_type_id = [ newVal[0].id ];
                console.log("Query: ", this.queryRequest);
            }
        }
    },

    computed: {
        canSubmit(){
            return !this.loading_campaigns && !this.loading_products && !this.loading_subcategories && !this.campaign_type_error.error
        },

        mandatoryFields: function () {
            return  [
                { name : "status"      , value : this.queryRequest.target_status_id, mandatory : true , rules: 'required' },
                { name : 'campaign_id' , value : this.queryRequest.campaign_id     , mandatory : false, rules: 'campaign_date_range' },
                { name : 'daterange'   , value : this.dateRange                    , mandatory : false, rules: 'campaign_date_range' },
            ]
        },

        status(){
            if(!this.queryRequest.channel_id){
                return [];
            }

            if([2].includes(this.queryRequest.channel_id)){
                // SMS & Flash SMS
                return [
                    { name: "Target Base"      , id: 0  },
                    { name: "Pending"          , id: 1  },
                    { name: "Blacklisted"      , id: 6  },
                    { name: "Sent"             , id: 2  },
                    { name: "Delivered"        , id: 3  },
                    { name: "Browser Launched" , id: 24 },
                ];
            }

            if([3].includes(this.queryRequest.channel_id)){
                // SMS & Flash SMS
                return [
                    { name: "Target Base"      , id: 0  },
                    { name: "Pending"          , id: 1  },
                    { name: "Blacklisted"      , id: 6  },
                    { name: "Sent"             , id: 2  },
                    { name: "Delivered"        , id: 3  },
                ];
            }
            
            if(this.queryRequest.channel_id === 6){
                return [
                    { name: "Pending"          , id: 1  },
                    { name: "Sent"             , id: 2  },
                    { name: "Delivered"        , id: 3  },
                    { name: "Undelivered"      , id: 8  },
                    { name: "Rejected"         , id: 5  },
                    { name: "Accepted"         , id: 4  },
                    { name: "Blacklisted"      , id: 6  },
                    { name: "Target Base"      , id: 0  },
                    { name: "Active SIM cards" , id: 14 },
                    { name: "Sent ping"        , id: 21 },
                    { name: "Incompatible"     , id: 25  },
                    { name: "Read"             , id: 26  },
                    { name: "Opt-in"           , id: "opt-in"  },
                    { name: "Opt-out"          , id: "opt-out"  },
                    { name: "Interactions"     , id: 27  },
                ];
                /* return [
                    { name: "Pending"          , id: 1  },
                    { name: "Sent"             , id: 2  },
                    { name: "Delivered"        , id: 3  },
                    { name: "Undelivered"      , id: 8  },
                    { name: "Read"             , id: 26  },
                    { name: "Opt-out"          , id: "opt-out"  },
                    { name: "Opt-in"           , id: "opt-in"  },
                    { name: "Interactions"     , id: 27  },
                    { name: "Incompatible"     , id: 25  },
                    { name: "Blacklisted"      , id: 6  },
                    { name: "Target Base"      , id: 0  },
                ]; */
            }
            
            // OTA
            if(this.queryRequest.channel_id === 5){
                return [
                    { name: "Pending"          , id: 1  },
                    { name: "Sent"             , id: 2  },
                    { name: "Delivered"        , id: 3  },
                    { name: "Blacklisted"      , id: 6  },
                    { name: "Target Base"      , id: 0  },
                    { name: "Applet installed" , id: 15 },
                ];
            }

            return [
                { name: "Pending"          , id: 1  },
                { name: "Sent"             , id: 2  },
                { name: "Delivered"        , id: 3  },
                { name: "Undelivered"      , id: 8  },
                { name: "Rejected"         , id: 5  },
                { name: "Accepted"         , id: 4  },
                { name: "Blacklisted"      , id: 6  },
                { name: "Target Base"      , id: 0  },
                { name: "Active SIM cards" , id: 14 },
                { name: "Sent ping"        , id: 21 },
                { name: "Browser Launched" , id: 24 },
            ];
        },

        // based on selected channel
        availableCampaignTypes(){

            // if([2,3,6].includes(this.queryRequest.channel_id)){
            if([2,3].includes(this.queryRequest.channel_id)){
                return [
                    { name: "On Demand", id: "SINGLE" },
                    //{ name: "Retry"    , id: "RETRY"  },
                ];
            }
            
            if(this.queryRequest.channel_id === 6){
                return [
                    { name: "On Demand", id: "SINGLE" },
                    { name: "Fallback"    , id: "FALLBACK"  },
                ];
            }

            return this.campaignTypes;
        },

        getCampaignsOptions() {
            return {
                "daterange":  this.dateRange ,
                "campaign_type_id": this.queryRequest.campaign_type_id,
                "products" : this.queryRequest.product_id
            };
        },

        filteredProducts(){
            this.loading_products = true;
            if(this.queryRequest.category_id.length == 0){
                return this.products;
            } else if (this.queryRequest.subcategory_id.length == 0) {
                return this.products.filter(prod => this.queryRequest.category_id.includes(prod.category_id));
            }
            return this.products.filter(prod => this.queryRequest.category_id.includes(prod.category_id) && this.queryRequest.subcategory_id.includes(prod.subcategory_id));
        },

        filteredSubcategories(){
            this.loading_subcategories = true;
            if(this.queryRequest.category_id.length == 0){
                this.queryRequest.subcategory_id = [];
                return this.subcategories;
            }
            return this.subcategories.filter(sub_cat => this.queryRequest.category_id.includes(sub_cat.category_id));
        },
    },

    methods: {

        async getFilteredCampaigns() {
            console.log("Fetching filtered campaigns...");
            this.prepareDates();
            const response = await api.getFilteredCampaigns(this.queryRequest);
            this.campaigns = response.data;
        },

        prepareDates() {
            if (this.dateRange.length != 0 && this.dateRange != null) {
                this.queryRequest.fecha_desde = moment(new Date(this.dateRange[0])).format("YYYY-MM-DD 00:00");
                this.queryRequest.fecha_hasta = moment(new Date(this.dateRange[1])).format("YYYY-MM-DD 23:59");
            } else {
                this.queryRequest.fecha_desde = "",
                this.queryRequest.fecha_hasta = ""
            }
        },

        async updateBookmarks() {
            // GET Todos los productos
            let query = { all: true };
            if(this.queryRequest.channel_id !== null) {
              query.channel = this.queryRequest.channel_id
            }
            
            const res = await api.getTemplates(query);
            this.products = res.data.map(template => ({
                id: template.id,
                name: template.name,
                category_id: template.category_id,
                subcategory_id: template.subcategory_id,
            }));
            
            /*.then(data => {
            this.products = data.data.map(product => ({
                id: product.id,
                name: product.name,
                category_id: product.category_id,
                subcategory_id: product.subcategory_id,
            }));*/
        },

        async submit() {
            this.loading = true;
            this.$notifications.clear();
            const isValid = await this.validateBeforeSubmit(this.scope, this.mandatoryFields, 1);
            try {
                console.log("TRYING TO POST QUERY");
                if (isValid) {
                    this.prepareDates();
                    await this.postQuery();
                    this.clearForm();
                };
                this.notification(isValid);
            } catch ($event) {
                // console.log($event)
            } finally {
                this.loading = false;
            }
            
        },

        async getCrmCampaignsIndex() {
            this.loading = true;
            const res = await api.getCrmCampaignsIndex();
            this.indexData = res.data;
            this.loading = false;
        },

        async postQuery(){
            const data = {
                function_id: 1,
                user_query: JSON.parse(JSON.stringify({
                    ...this.queryRequest,
                    ...this.output_options
                })),
            };
            // console.log(data);
            const res = await api.createCrmCampaignsQuery(data);
            this.indexData.push(res.data);
        },

        copyQuery(query) {
            this.$notifications.clear();
            this.clearForm();

            this.copyFields(query.user_query, this.queryRequest);
            
            this.$notify({
                title: `${this.$t('Query ID')}: ${query.id}`,
                message: this.$t("Query params have been copied."),
                icon: "fa fa-copy",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "info",
            });
        },

        // Override
        copyDateRange(user_query) {
            const validDateTime = moment(user_query.fecha_desde).isValid() && moment(user_query.fecha_hasta).isValid();

            this.dateRange = [];
            if (validDateTime) {
                this.dateRange[0] = user_query.fecha_desde;
                this.dateRange[1] = user_query.fecha_hasta;
            } else {
                this.queryRequest.fecha_desde = "";
                this.queryRequest.fecha_hasta = "";
            }
            this.$refs.daterange.setDaterange(this.dateRange);
        },

        clearForm(except = []) {
            const defaults = {
                channel_id: null,
                target_status_id: null,
                campaign_type_id: [],
                campaign_id: [],
                product_id: [],
                category_id: [],
            };
            const newQueryRequest = { ...this.queryRequest };
            for(const key in defaults){
                if(!Array.isArray(except) || !except.includes(key)){
                    newQueryRequest[key] = defaults[key];
                }
            }
            this.queryRequest = newQueryRequest;
            this.dateRange = [];
            this.$refs.daterange.setDaterange(null);
            //this.clearFields(this.queryRequest, this.scope, this.$validator.id)
        },
    },
};
</script>

<style scoped>
    :deep(.el-select .el-loading-spinner) {
        top: 80% !important
    }
</style>