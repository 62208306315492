<template>
  <div :class="getWidth()">
    <div class="form-group">
      <label>{{ $t(field.tooltip) }}</label>
      <textarea
        class="form-control"
        :class="{
          'has-danger': error,
          'has-success': value && !error && focused,
        }"
        rows="3"
        :disabled="disabled"
        :name="field.name"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="focused = true"
        @blur="focused = false"
        v-validate="validations()"
        :maxlength="maxLength"
        ref="textarea"
      ></textarea>
      <div class="row m-0">
        <div class="col" v-if="error">
          <span
            class="text-danger text-left w-100 invalid-feedback"
            style="display: block;"
            >{{ error }}</span
          >
        </div>
        <div class="col">
          <label
            class="w-100 text-right"
            :class="{
              'text-danger': this.sampleText.length > maxLength,
            }"
          >
            {{ this.sampleText.length + " / " + maxLength }}
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p-button
            size="sm"
            class="pull-left btn-ph"
            v-on:click="appendMsisdn"
            type="primary"
          >
            MSISDN
          </p-button>
          <p-button
            v-for="ph in placeholders"
            :key="ph.id"
            size="sm"
            type="primary"
            class="pull-left btn-ph"
            v-on:click="appendPlaceholder(ph)"
          >
            {{ ph.name.toUpperCase() }}
          </p-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "element-ui";

import api from "src/api.js";
import { mapGetters } from "vuex";

export default {
  components: {
    [Button.name]: Button,
  },

  inject: ["getError", "parentValidator"],

  props: {
    value: String,
    field: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      default: "col-md-3",
      type: String,
    },
  },

  data() {
    return {
      maxLengthInitial: 160,
      maxLengthAccepted: this.maxLengthInitial,
      focused: false,
      bookmark_id: null,
      placeholdersMAP: new Map(),
      placeholders: [],
    };
  },

  computed: {
    maxLength() {
      if(!this.value) return this.maxLengthInitial;
      const result = this.value.match(
        /^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u0027\u0022\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\#$%&amp;()*+,\./\-:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\[~\]|\u20AC]*$/g
      );
      this.maxLengthAccepted =
        null === result && this.value.length > 0
          ? this.maxLengthInitial / 2
          : this.maxLengthInitial;
      return this.maxLengthAccepted;
    },

    error() {
      return this.getError(this.field.name);
    },

    sampleText() {
      if(!this.value) return "";
      return this.value.replace(/\{(\w+)\}/g, (match, capture) => {
        const ph = this.placeholdersMAP.get(capture.toLowerCase());
        if (ph) {
          return "".padStart(ph.max_length, "X");
        } else if (capture.toLowerCase() == "msisdn") {
          return "".padStart(13, "X");
        }
        return match;
      });
    },

    ...mapGetters(["getCurrentBookmark"]),
  },

  created() {
    this.$validator = this.parentValidator;
  },

  async mounted() {
    this.setPlaceholders();
  },

  methods: {
    setPlaceholders() {
      this.bookmark_id = this.getCurrentBookmark
        ? this.getCurrentBookmark.id
        : null;

      if (this.getCurrentBookmark) {
        if (this.getCurrentBookmark.placeholders) {
          for (const placeholder of this.getCurrentBookmark.placeholders) {
            this.placeholdersMAP.set(
              placeholder.name.toLowerCase(),
              placeholder
            );
            this.placeholders.push(placeholder);
          }
        }
      }
    },
    appendMsisdn() {
      if (!this.disabled && this.sampleText.length + 13 <= this.maxLength) {
        const value = this.value + "{MSISDN}";
        this.$emit("input", value);
      }
      this.$refs.textarea.focus();
    },

    appendPlaceholder(ph) {
      if (
        !this.disabled &&
        this.sampleText.length + ph.max_length <= this.maxLength
      ) {
        const value = this.value + `{${ph.name.toUpperCase()}}`;
        this.$emit("input", value);
      }
      this.$refs.textarea.focus();
    },

    validations() {
      const glue = "|";
      let validations = this.field.required ? "required" + glue : "";
      validations += "max:" + this.maxLength;

      return validations;
    },

    getWidth() {
      return this.width;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-ph {
  font-size: 10.5px !important;
  margin: 2px 1px !important;
  padding: 4px 5px !important;
}

.has-danger {
  background-color: #ffc0a4;
  border: 1px solid #ef8157;
  color: #ef8157;
}

.has-success {
  border: 1px solid #6bd098;
  color: #6bd098;
}
</style>
