<template>
  <div>
    <form-campaign-ping
      :tabbed-form-save-button-disabled="workingSave"
      :initial-tab="$route.params.tab"
      @submit="onSubmit"
    ></form-campaign-ping>
  </div>
</template>

<script>
import api from "src/api";

import FormCampaignPing from "src/app-components/FormCampaignPing";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import { mapMutations } from "vuex";
import WorkingMixin from "src/app-components/WorkingMixin";

export default {
  components: {
    FormCampaignPing,
  },

  mixins: [RetryCrudMixin(), WorkingMixin("Save")],

  mounted() {
    const name =
      this.$route.name === "CampaignCreateFromPing"
        ? "Create a new ping campaign"
        : "Create a new OTA campaign";
    this.setTopNavbarTitle(this.$t(name));
  },

  methods: {
    async onSubmit($event) {
      try {
        this.setWorkingSave(true);
        const res = await api.createPingCampaign({
          ...$event,
          children: undefined,
        });
        await this.processOperationLog(res.data.id, $event.children);
        this.$router.push({ path: `/campaigns/planning` });
      } finally {
        this.setWorkingSave(false);
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
