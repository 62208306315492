<template>
    <modal :show="show" headerClasses="justify-content-center" @close="onCancel">
        <h4 slot="header" class="title title-up">{{ $t("Menu entry configuration") }}</h4>
        <div class="row">
            <div class="col-12 col-md-6">
                <MenuTitle
                    v-model="menuTitle"
                />
            </div>
            <div class="col-12 col-md-6">
                <CallToActionType
                    v-model="callToActionType"
                />
            </div>
        </div>
        <CallToActionConfiguration
            :key="renderId"
            @modelUpdated="callToActionConfigUpdated"
            :entity="{ callToActionConfig: initialData ? (initialData.callToActionConfig ? initialData.callToActionConfig : {}) : {} }"
            :call-to-action-id="callToActionType"
        />
        <template slot="footer">
            <button type="button" class="btn btn-dark" @click="onCancel"><i class="fa fa-ban"></i> {{ $t("Cancel") }}</button>
            <button type="button" class="btn btn-success mr-3" @click="onSave"><i class="fa fa-check"></i> {{ $t("OK") }}</button>
        </template>
    </modal>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";
    import MenuTitle from "src/app-components/form-elements/MenuTitle";
    import CallToActionType from "src/app-components/form-elements/CallToActionType";
    import CallToActionConfiguration from 'src/app-components/product-menu-configuration/CallToActionConfiguration'
    import { cloneDeep } from 'lodash'

    export default {
        name: 'menu-entry-configuration',

        props: {
            show: Boolean,
            initialData: Object
        },

        provide() {
            return {
                parentValidator: this.$validator,
                getError: this.getError
            };
        },

        components: {
            Modal,
            MenuTitle,
            CallToActionType,
            CallToActionConfiguration
        },

        data() {
            return {
                menuTitle: "",
                callToActionType: null,
                callToActionConfig: {},
                renderId: 1
            }
        },

        watch: {
            show () {
                if (this.show) {
                    this.resetForm()
                }
            },
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            async onSave() {
                const valid = await this.$validator.validateAll()
                if (valid) this.$emit("modal-save", cloneDeep({
                    menuTitle: this.menuTitle,
                    callToActionId: this.callToActionType,
                    callToActionConfig: this.callToActionConfig
                }));
            },

            onCancel() {
                this.$emit("modal-cancel");
            },

            resetForm () {
                if (this.initialData) {
                    let initialData = cloneDeep(this.initialData)
                    this.menuTitle = initialData.menuTitle || ""
                    this.callToActionType = initialData.callToActionId || null
                    this.callToActionConfig = initialData.callToActionConfig || {}
                } else {
                    this.menuTitle = ""
                    this.callToActionType = null
                    this.callToActionConfig = {}
                }
                this.renderId++;
                this.$validator.reset()
            },

            callToActionConfigUpdated (form) {
                this.callToActionConfig = form.callToActionConfig
            }
        }
    }
</script>

<style scoped>
</style>