const mixin = {
    props: {
        tabbedFormSaveButtonDisabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            errorTabs: this.getTabs()
        }
    },

    methods: {
        onErrorTabs(validatedTabs) {
            console.log("Valdation failed in tabs: " + JSON.stringify(validatedTabs, null, 2));
            this.errorTabs = validatedTabs;
        },
    },
};

export default mixin;
