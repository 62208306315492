<template>
  <div
    class="wrapperCanvas"
    :class="[darkMode ? 'dark' : '', lockCanvas ? 'lock' : '']"
  >
    <div class="col-right">
      <div id="drawflow" class="drawflow">
        <NavbarFlow
          :name="flow.name"
          :saving="saving"
          :lockCanvas="lockCanvas"
          @clear="openModalConfirm('clear')"
          @saveData="saveData"
          @changeLockCanvas="changeLockCanvas"
          @openConfig="openConfig"
          @changeDarkMode="darkMode = $event"
          @saveDataTest="saveDataTest"
        />
      </div>
    </div>

    <CreateElementButtons @newItemCanvas="newItemCanvas({item: $event})" />

    <ModalOptions
      :editor="editor"
      v-if="editor"
      :variables="variables"
    />
      <!-- :variables="configuration.variables" -->

    <ModalConfiguration
      :configuration="configuration"
      :active="openModal"
      :nameFlow="flow.name"
      @updateData="updateConfiguration($event)"
      @updateName="flow.name = $event"
    />
    
    <ModalGoTo
      :dataGoTo="dataModalGoTo"
      @createGoTo="newItemWithConnection($event.dataCreate, $event.data)"
    />

    <el-dialog
      width="30%"
      class="confirm-dialog"
      :visible.sync="modalConfirm.status"
      :title="dataModalConfirm ? $t(dataModalConfirm.title) : ''"
    >
      <span v-if="dataModalConfirm">{{
        dataModalConfirm.text ? $t(dataModalConfirm.text) : ""
      }}</span>
      <span slot="footer" class="dialog-footer" v-if="dataModalConfirm">
        <el-button size="mini" @click="closeModalConfirm">
          {{ dataModalConfirm.confirm ? $t("Cancel") : $t("Close") }}
        </el-button>
        <el-button
          v-if="dataModalConfirm.confirm"
          size="mini"
          type="primary"
          @click="dataModalConfirm.confirm"
          >{{ $t("Confirm") }}</el-button
        >
      </span>
    </el-dialog>

    <div class="options-canvas">
      <el-button-group>
        <el-tooltip
          class="item"
          effect="dark"
          :content="opt.tooltip"
          placement="top"
          v-for="(opt, key) in zoomOptions"
          :key="key"
        >
          <el-button
            size="mini"
            :icon="opt.icon"
            @click="zoomCanvas(opt.action)"
          ></el-button>
        </el-tooltip>
      </el-button-group>
    </div>
  </div>
</template>

<script>
  import sio from "@/sio";
  import Vue from "vue";
  import api from "src/api";
  import { EventBus } from "src/eventBus.js";

  import Drawflow from "drawflow"; //https://github.com/jerosoler/Drawflow
  import "drawflow/dist/drawflow.min.css";

  import { Switch, Button, ButtonGroup, Dialog, Tooltip } from "element-ui";

  import CreateElementButtons from "src/app-components/chatbot/elements/CreateElementButtons";

  import Note from "src/app-components/chatbot/components/Note";
  import Message from "src/app-components/chatbot/components/Message";
  import MessageWithOptions from "src/app-components/chatbot/components/MessageWithOptions";
  import DataStorage from "src/app-components/chatbot/components/DataStorage";
  import Condition from "src/app-components/chatbot/components/Condition";
  import GoTo from "src/app-components/chatbot/components/GoTo";
  import Start from "src/app-components/chatbot/components/Start";
  import Close from "src/app-components/chatbot/components/Close";
  import ContactCenter from "src/app-components/chatbot/components/ContactCenter";

  import Integration from "src/app-components/chatbot/components/Integration";

  import ModalGoTo from "src/app-components/chatbot/elements/ModalGoTo";

  import ModalOptions from "src/app-components/chatbot/configuration/components/ModalOptions";
  import ModalConfiguration from "src/app-components/chatbot/configuration/global/ModalConfiguration";

  import NavbarFlow from "src/app-components/chatbot/elements/NavbarFlow";

  export default {
    name: "FlowCanvas",

    components: {
      NavbarFlow,
      ModalGoTo,
      ModalOptions,
      ModalConfiguration,
      CreateElementButtons,
      [Switch.name]: Switch,
      [Button.name]: Button,
      [Dialog.name]: Dialog,
      [Tooltip.name]: Tooltip,
      [ButtonGroup.name]: ButtonGroup,
    },

    data() {
      return {
        editor: null,
        saving: false,

        modalConfirm: {
          status: false,
          type: "",
          data: null,
        },
        openModal: false,
        
        dataModalGoTo: null,
        
        darkMode: false,
        lockCanvas: false,

        flow: {},

        configuration: {
          insistence: {
            minutes: null,
            message: null,
          },
          timeout: {
            minutes: 60,
            message: "",
          },
          closingMessage: {
            message: null,
          },
          globalClosingPhrases: {
            phrases: null,
          },

          variables: [],
        },
      };
    },

    created() {
      this.getFlow(this.flowID);
    },

    mounted() {
      this.setEventBusOn();
      this.activeDraw();
    },

    methods: {
      openModalConfirm(type = "", data = null) {
        if (type) {
          this.modalConfirm.status = true;
          this.modalConfirm.type = type;
          this.modalConfirm.data = data;
        }
      },

      clear() {
        let data = Object.values(this.editor.export().drawflow.Home.data);
        let start = data.find((n) => n.class === "Start");

        this.editor.clear();
        this.newItemCanvas({item: "Start", x: start.pos_x, y: start.pos_y});

        this.closeModalConfirm();
      },

      closeModalConfirm() {
        this.modalConfirm.status = false;
        this.modalConfirm.type = "";
        this.modalConfirm.data = null;
      },

      saveDataTest() {
        console.log("Export: ", this.editor.export().drawflow.Home.data);
      },

      saveData() {
        if (this.saving) return;

        this.saving = true;
        let dataExport = this.editor.export();

        let errorNodes = this.validateCloseNodes(dataExport.drawflow.Home.data);
        if (errorNodes.length) {
          this.openModalConfirm("closeNodes", errorNodes);
          this.saving = false;
          return;
        }

        this.updateFlow(dataExport.drawflow.Home.data);
      },

      validateCloseNodes(data) {
        let dataArray = Object.values(data);
        let errorNodes = [];

        dataArray.forEach((node) => {
          if (node.outputs) {
            let keysOutputs = Object.keys(node.outputs);
            
            const outputsOptions = node.data.options 
                                  ? this.outputsIdFormat(node.data.options)
                                  : null;
            keysOutputs.forEach((key, index) => {
              if (node.name === "MessageWithOptions") {
                if(node.data.options[outputsOptions[index]]) {
                  if(node.data.options[outputsOptions[index]].type === "fallback") {
                    if (node.data.options[outputsOptions[index]].message !== "") return;
                  }
                }
              }
              
              
              if (node.outputs[key]) {
                if (node.outputs[key].connections) {
                  if (node.outputs[key].connections.length === 0) {
                    let index = errorNodes.findIndex((i) => i.id === node.id);
                    if (index === -1) {
                      errorNodes.push({
                        id: node.id,
                        outputs: [key],
                      });
                    } else {
                      errorNodes[index].outputs.push(key);
                    }
                  }
                }
              }
            });
          }
        });

        return errorNodes;
      },

      async autoCloseNodes() {
        if (this.modalConfirm.data) {
          await this.modalConfirm.data.forEach(async (node) => {
            let dataNode = this.editor.getNodeFromId(node.id);
            let htmlLastOutput = document.querySelector(
              `#node-${node.id} .${node.outputs[node.outputs.length - 1]}`
            );

            let pos_x = dataNode.pos_x + 320;
            let pos_y = dataNode.pos_y + parseInt(htmlLastOutput.style.top);
            let closeNode = await this.newItemCanvas({item: "Close", x: pos_x, y: pos_y});
            node.outputs.forEach((output) => {
              this.addConnection(node.id, closeNode, output);
            });

            if(dataNode.data.options) {
              setTimeout(() => {
                this.updateConnectionList(node.id, dataNode.data);
              }, 5);
            }
          });

          this.closeModalConfirm();

          
          
          setTimeout(() => {
            this.saveData();
          }, 500);
        }
      },

      async updateFlow(data) {
        data = this.addPosCanvas(data);
        this.flow.raw_flow = data;
        this.flow.configuration = this.configuration;

        const res = await api.updateFlow(this.flow.id, this.flow);
        this.saving = false;
      },

      addPosCanvas(data) {
        var id = null;
        Object.values(data).forEach((i) => {
          if (i.name === "Start") {
            id = i.id;
          }
        });
        if (id) {
          data[id].data.pos_x = this.editor.canvas_x;
          data[id].data.pos_y = this.editor.canvas_y;
        }
        return data;
      },

      async getFlow(id) {
        let res = await api.getFlow(id);
        if (res.status) {
          this.flow = { ...res.data };
          if (res.data.configuration) {
            this.configuration = { ...res.data.configuration };
            if(this.configuration.timeout) {
              if(this.configuration.timeout.minutes === null) {
                this.configuration.timeout.minutes = 60;
              }
            }
            if (!this.configuration.variables) {
              this.configuration.variables = [];
            }
          }

          if (this.editor) {
            this.importData({
              drawflow: {
                Home: {
                  // data: {},
                  data: { ...this.flow.raw_flow },
                },
              },
            });
          }
        }
      },

      importData(data = null) {
        if (Object.keys(data.drawflow.Home.data).length) {
          this.editor.import(data);

          let start =
            data.drawflow.Home.data[Object.keys(data.drawflow.Home.data)[0]];

          if (start.data) {
            if (start.data.pos_x && start.data.pos_y) {
              this.editor.translate_to(start.data.pos_x, start.data.pos_y);
            }
          }

          this.setDataImportToComponents(data);
        } else {
          this.newItemCanvas({item: "Start"});
        }
      },

      // Inicio y creo el DrawFlow
      activeDraw() {
        let id = document.getElementById("drawflow");
        this.editor = new Drawflow(id, Vue, this);

        this.editor.translate_to = function (x, y) {
          this.canvas_x = x;
          this.canvas_y = y;
          let storedZoom = this.zoom;
          this.zoom = 1;
          this.zoom_max = 1;
          this.zoom_min = 0.3;
          this.precanvas.style.transform =
            "translate(" +
            this.canvas_x +
            "px, " +
            this.canvas_y +
            "px) scale(" +
            this.zoom +
            ")";
          this.zoom = storedZoom;
          this.zoom_last_value = 1;
          this.zoom_refresh();
        };

        this.editor.activePopover = this.activePopover;

        this.editor.start();

        // Sobreescribo la curvatura
        this.overwriteCreateCurvature();
        // Fin Sobreescribo la curvatura
        this.overwriteAddNode();
        this.overwriteAddNodeOutput();

        this.overwriteAddNodeImport();

        // Registro los componentes para poder crearlos despues
        this.componentList.forEach((item) => {
          // let props = item.props ? item.props : {};
          let props = item.props ? item.props : {};
          // let props = item.props ? item.props : {editor: this.editor};
          this.editor.registerNode(item.component.name, item.component, props);
        });

        this.editor.on(
          "connectionCreated",
          ({ output_id, input_id, output_class, input_class }) => {
            const nodeInfo = this.editor.getNodeFromId(output_id);

            // Verifico que el output no tenga mas de dos salidas
            if (nodeInfo.outputs[output_class].connections.length > 1) {
              const removeConnectionInfo =
                nodeInfo.outputs[output_class].connections[0];
              this.editor.removeSingleConnection(
                output_id,
                removeConnectionInfo.node,
                output_class,
                removeConnectionInfo.output
              );
            }

            // Seteo root y armo relacion de options/connections
            setTimeout(() => {
              let nodeIn = this.editor.getNodeFromId(input_id);
              let nodeOut = this.editor.getNodeFromId(output_id);

              if (nodeOut.class === "Start") {
                nodeIn.data.root = true;

                this.editor.updateNodeDataFromId(nodeIn.id, nodeIn);
                this.updateDataNode(nodeIn.id, nodeIn);
              }

              let idOption = null;

              if (nodeOut.data.options) {
                let index = output_class.slice(7)
                  ? output_class.slice(7) - 1
                  : null;
                if (index !== null) {
                  let indexOption = this.outputsIdFormat(nodeOut.data.options)[
                    index
                  ];
                  idOption = nodeOut.data.options[indexOption].id;
                }
              }

              if (nodeOut.data.connections) {
                let newItem = {
                  id: nodeIn.name === "GoTo" 
                      ? nodeIn.data.goto
                      : nodeIn.id,
                };

                if (idOption) {
                  newItem.idOption = idOption;
                }

                let exist = nodeOut.data.connections.findIndex(c => c.idOption === idOption);
                if(exist !== -1) {
                  nodeOut.data.connections[exist].id = newItem.id;
                }else {
                  nodeOut.data.connections.push(newItem);
                }
                
                // nodeOut.data.connections.push(newItem);
                  
                this.editor.updateNodeDataFromId(nodeOut.id, nodeOut);
                this.updateDataNode(nodeOut.id, nodeOut);
              }
            }, 10);
          }
        );

        this.editor.on("nodeRemoved", (id) => {
          let allNodes = Object.values(this.editor.export().drawflow.Home.data);
          let goto = allNodes.filter(node => node.name === "GoTo");
          goto.forEach(node => {
            if(node.data.goto == id) {
              this.editor.removeNodeId(`node-${node.id}`)
            }
          })
        })


        this.editor.on(
          "connectionRemoved",
          ({ output_id, input_id, output_class, input_class }) => {
            let nodeIn = this.editor.getNodeFromId(input_id);
            let nodeOut = this.editor.getNodeFromId(output_id);

            // Remuevo root y relacion de options/connections

            if (nodeOut.class === "Start") {
              nodeIn.data.root = false;

              this.editor.updateNodeDataFromId(nodeIn.id, nodeIn);
              this.updateDataNode(nodeIn.id, nodeIn);
            }

            if (nodeOut.data.connections) {
              if(nodeOut.data.options) {
                let keysOutputs = this.outputsIdFormat(nodeOut.data.options);
                let index = nodeOut.data.connections.findIndex(
                  (c) => c.idOption === nodeOut.data.options[keysOutputs[output_class.slice(7) - 1]].id
                  );

                nodeOut.data.connections.splice(index, 1);
              }else {
                nodeOut.data.connections = [];
              }

              this.editor.updateNodeDataFromId(nodeOut.id, nodeOut);
              this.updateDataNode(nodeOut.id, nodeOut);
            }

            setTimeout(() => {
              if (nodeIn.class === "GoTo") {
                try {
                  this.editor.removeNodeId(`node-${input_id}`);
                } catch (error) {}
              }
            }, 5);
          }
        );
      },

      activePopover(node, target) {
        let output = target.classList[1];
        EventBus.$emit("activePopover", { node, output });
      },

      overwriteAddNode() {
        this.editor.addNode = function (
          name,
          num_in,
          num_out,
          ele_pos_x,
          ele_pos_y,
          classoverride,
          data,
          html,
          typenode = false
        ) {
          if (this.useuuid) {
            var newNodeId = this.getUuid();
          } else {
            var newNodeId = this.nodeId;
          }
          const parent = document.createElement("div");
          parent.classList.add("parent-node");

          const node = document.createElement("div");
          node.innerHTML = "";
          node.setAttribute("id", "node-" + newNodeId);
          node.classList.add("drawflow-node");
          if (classoverride != "") {
            node.classList.add(...classoverride.split(" "));
          }

          const inputs = document.createElement("div");
          inputs.classList.add("inputs");

          const outputs = document.createElement("div");
          outputs.classList.add("outputs");

          const json_inputs = {};
          for (var x = 0; x < num_in; x++) {
            const input = document.createElement("div");
            input.classList.add("input");
            input.classList.add("input_" + (x + 1));
            json_inputs["input_" + (x + 1)] = { connections: [] };
            inputs.appendChild(input);
          }

          const json_outputs = {};
          for (var x = 0; x < num_out; x++) {
            const output = document.createElement("div");
            output.classList.add("output");
            output.classList.add("output_" + (x + 1));

            output.addEventListener("contextmenu", (event) => {
              this.activePopover(newNodeId, event.target);
            });

            json_outputs["output_" + (x + 1)] = { connections: [] };
            outputs.appendChild(output);
          }

          const content = document.createElement("div");
          content.classList.add("drawflow_content_node");
          if (typenode === false) {
            content.innerHTML = html;
          } else if (typenode === true) {
            content.appendChild(this.noderegister[html].html.cloneNode(true));
          } else {
            if (parseInt(this.render.version) === 3) {
              //Vue 3
              let wrapper = this.render.h(
                this.noderegister[html].html,
                this.noderegister[html].props,
                this.noderegister[html].options
              );
              wrapper.appContext = this.parent;
              this.render.render(wrapper, content);
            } else {
              // Vue 2
              let wrapper = new this.render({
                parent: this.parent,
                render: (h) =>
                  h(this.noderegister[html].html, {
                    props: this.noderegister[html].props,
                  }),
                ...this.noderegister[html].options,
              }).$mount();
              //
              content.appendChild(wrapper.$el);
            }
          }

          Object.entries(data).forEach(function (key, value) {
            if (typeof key[1] === "object") {
              insertObjectkeys(null, key[0], key[0]);
            } else {
              var elems = content.querySelectorAll("[df-" + key[0] + "]");
              for (var i = 0; i < elems.length; i++) {
                elems[i].value = key[1];
                if (elems[i].isContentEditable) {
                  elems[i].innerText = key[1];
                }
              }
            }
          });

          function insertObjectkeys(object, name, completname) {
            if (object === null) {
              var object = data[name];
            } else {
              var object = object[name];
            }
            if (object !== null) {
              Object.entries(object).forEach(function (key, value) {
                if (typeof key[1] === "object") {
                  insertObjectkeys(object, key[0], completname + "-" + key[0]);
                } else {
                  var elems = content.querySelectorAll(
                    "[df-" + completname + "-" + key[0] + "]"
                  );
                  for (var i = 0; i < elems.length; i++) {
                    elems[i].value = key[1];
                    if (elems[i].isContentEditable) {
                      elems[i].innerText = key[1];
                    }
                  }
                }
              });
            }
          }
          node.appendChild(inputs);
          node.appendChild(content);
          node.appendChild(outputs);
          node.style.top = ele_pos_y + "px";
          node.style.left = ele_pos_x + "px";
          parent.appendChild(node);
          this.precanvas.appendChild(parent);
          var json = {
            id: newNodeId,
            name: name,
            data: data,
            class: classoverride,
            html: html,
            typenode: typenode,
            inputs: json_inputs,
            outputs: json_outputs,
            pos_x: ele_pos_x,
            pos_y: ele_pos_y,
          };
          this.drawflow.drawflow[this.module].data[newNodeId] = json;
          this.dispatch("nodeCreated", newNodeId);
          if (!this.useuuid) {
            this.nodeId++;
          }
          return newNodeId;
        };
      },

      overwriteAddNodeOutput() {
        this.editor.addNodeOutput = function (id) {
          var moduleName = this.getModuleFromNodeId(id);
          const infoNode = this.getNodeFromId(id);
          const numOutputs = Object.keys(infoNode.outputs).length;
          if (this.module === moduleName) {
            //Draw output
            const output = document.createElement("div");
            output.classList.add("output");
            output.classList.add("output_" + (numOutputs + 1));
            const parent = this.container.querySelector(
              "#node-" + id + " .outputs"
            );

            output.addEventListener("contextmenu", (event) => {
              this.activePopover(id, event.target);
            });

            parent.appendChild(output);
            this.updateConnectionNodes("node-" + id);
          }
          this.drawflow.drawflow[moduleName].data[id].outputs[
            "output_" + (numOutputs + 1)
          ] = { connections: [] };
        };
      },

      overwriteAddNodeImport() {
        this.editor.addNodeImport = function (dataNode, precanvas) {
          const parent = document.createElement("div");
          parent.classList.add("parent-node");

          const node = document.createElement("div");
          node.innerHTML = "";
          node.setAttribute("id", "node-" + dataNode.id);
          node.classList.add("drawflow-node");
          if (dataNode.class != "") {
            node.classList.add(...dataNode.class.split(" "));
          }

          const inputs = document.createElement("div");
          inputs.classList.add("inputs");

          const outputs = document.createElement("div");
          outputs.classList.add("outputs");

          Object.keys(dataNode.inputs).map(function (input_item, index) {
            const input = document.createElement("div");
            input.classList.add("input");
            input.classList.add(input_item);
            inputs.appendChild(input);
            Object.keys(dataNode.inputs[input_item].connections).map(function (
              output_item,
              index
            ) {
              var connection = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg"
              );
              var path = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "path"
              );
              path.classList.add("main-path");
              path.setAttributeNS(null, "d", "");
              // path.innerHTML = 'a';
              connection.classList.add("connection");
              connection.classList.add("node_in_node-" + dataNode.id);
              connection.classList.add(
                "node_out_node-" +
                  dataNode.inputs[input_item].connections[output_item].node
              );
              connection.classList.add(
                dataNode.inputs[input_item].connections[output_item].input
              );
              connection.classList.add(input_item);

              connection.appendChild(path);
              precanvas.appendChild(connection);
            });
          });

          for (var x = 0; x < Object.keys(dataNode.outputs).length; x++) {
            const output = document.createElement("div");
            output.classList.add("output");
            output.classList.add("output_" + (x + 1));

            output.addEventListener("contextmenu", (event) => {
              this.activePopover(dataNode.id, event.target);
            });

            outputs.appendChild(output);
          }

          const content = document.createElement("div");
          content.classList.add("drawflow_content_node");

          if (dataNode.typenode === false) {
            content.innerHTML = dataNode.html;
          } else if (dataNode.typenode === true) {
            content.appendChild(
              this.noderegister[dataNode.html].html.cloneNode(true)
            );
          } else {
            if (parseInt(this.render.version) === 3) {
              //Vue 3
              let wrapper = this.render.h(
                this.noderegister[dataNode.html].html,
                this.noderegister[dataNode.html].props,
                this.noderegister[dataNode.html].options
              );
              wrapper.appContext = this.parent;
              this.render.render(wrapper, content);
            } else {
              //Vue 2
              let wrapper = new this.render({
                parent: this.parent,
                render: (h) =>
                  h(this.noderegister[dataNode.html].html, {
                    props: this.noderegister[dataNode.html].props,
                  }),
                ...this.noderegister[dataNode.html].options,
              }).$mount();
              content.appendChild(wrapper.$el);
            }
          }

          Object.entries(dataNode.data).forEach(function (key, value) {
            if (typeof key[1] === "object") {
              insertObjectkeys(null, key[0], key[0]);
            } else {
              var elems = content.querySelectorAll("[df-" + key[0] + "]");
              for (var i = 0; i < elems.length; i++) {
                elems[i].value = key[1];
                if (elems[i].isContentEditable) {
                  elems[i].innerText = key[1];
                }
              }
            }
          });

          function insertObjectkeys(object, name, completname) {
            if (object === null) {
              var object = dataNode.data[name];
            } else {
              var object = object[name];
            }
            if (object !== null) {
              Object.entries(object).forEach(function (key, value) {
                if (typeof key[1] === "object") {
                  insertObjectkeys(object, key[0], completname + "-" + key[0]);
                } else {
                  var elems = content.querySelectorAll(
                    "[df-" + completname + "-" + key[0] + "]"
                  );
                  for (var i = 0; i < elems.length; i++) {
                    elems[i].value = key[1];
                    if (elems[i].isContentEditable) {
                      elems[i].innerText = key[1];
                    }
                  }
                }
              });
            }
          }
          node.appendChild(inputs);
          node.appendChild(content);
          node.appendChild(outputs);
          node.style.top = dataNode.pos_y + "px";
          node.style.left = dataNode.pos_x + "px";
          parent.appendChild(node);
          this.precanvas.appendChild(parent);
        };
      },

      // Sobreescrivo la logica de la libreria para que arme de distinta manera las lineas de conexion
      overwriteCreateCurvature() {
        this.editor.createCurvature = function (
          start_pos_x,
          start_pos_y,
          end_pos_x,
          end_pos_y,
          curvature_value
        ) {
          let angle = 4;
          const input_distance = 0;
          const fix_arrow_distance = 0;
          const min_x_distance = 40;

          const x = start_pos_x + input_distance;
          const y = start_pos_y;
          const end_x = end_pos_x - fix_arrow_distance;
          const end_y = end_pos_y;
          const center_x = (end_x - x) / 2 + x;
          const center_y = (end_y - y) / 2 + y;
          const distance_x = end_x - x;
          const distance_y = end_y - y;
          let minX = false;

          let line = "";

          if (distance_x <= min_x_distance) {
            minX = true;
          }

          if (end_y > y) {
            //Down Lines
            if (distance_y <= angle * 2) {
              angle = distance_y / 2;
            }
            if (minX) {
              if (distance_y <= angle * 4) {
                angle = distance_y / 4;
              }
              line += `
                M ${x} ${y} 
                L ${x + min_x_distance - angle} ${y} 
                A ${angle} ${angle} 1 0 1 ${x + min_x_distance} ${y + angle}
                L ${x + min_x_distance} ${center_y - angle}
                A ${angle} ${angle} 1 0 1 ${
                x + min_x_distance - angle
              } ${center_y}
                L ${end_x - min_x_distance} ${center_y}
                A ${angle} ${angle} 1 0 0 ${end_x - min_x_distance - angle} ${
                center_y + angle
              }
                L ${end_x - min_x_distance - angle} ${end_y - angle}
                A ${angle} ${angle} 1 0 0 ${end_x - min_x_distance} ${end_y}
                L ${end_x} ${end_y}
                `;
            } else {
              line += `
                M ${x} ${y} 
                L ${center_x - angle} ${y} 
                A ${angle} ${angle} 1 0 1 ${center_x} ${y + angle}
                L ${center_x} ${center_y} 
                L ${center_x} ${end_y - angle} 
                A ${angle} ${angle} 1 0 0 ${center_x + angle} ${end_y}
                L ${end_x} ${end_y}
                `;
            }
          } else {
            // UP Lines
            if (distance_y * -1 <= angle * 2) {
              angle = (distance_y * -1) / 2;
            }
            if (minX) {
              if (distance_y * -1 <= angle * 4) {
                angle = (distance_y * -1) / 4;
              }
              line += `
                M ${x} ${y} 
                L ${x + min_x_distance - angle} ${y} 
                A ${angle} ${angle} 1 0 0 ${x + min_x_distance} ${y - angle}
                L ${x + min_x_distance} ${center_y + angle}
                A ${angle} ${angle} 1 0 0 ${
                x + min_x_distance - angle
              } ${center_y}
                L ${end_x - min_x_distance} ${center_y}
                A ${angle} ${angle} 1 0 1 ${end_x - min_x_distance - angle} ${
                center_y - angle
              }
                L ${end_x - min_x_distance - angle} ${end_y + angle}
                A ${angle} ${angle} 1 0 1 ${end_x - min_x_distance} ${end_y}
                L ${end_x} ${end_y}
                `;
            } else {
              line += `
                M ${x} ${y} 
                L ${center_x - angle} ${y} 
                A ${angle} ${angle} 1 0 0 ${center_x} ${y - angle}
                L ${center_x} ${center_y} 
                L ${center_x} ${end_y + angle} 
                A ${angle} ${angle} 1 0 1 ${center_x + angle} ${end_y}
                L ${end_x} ${end_y}
                `;
            }
          }

          return line;
        };
      },

      async updatePositionOptions(id, data) {
        let connections = [ ...data.connections ];
        let count = 1;
        let optionsOutput = [];
        data.options.forEach(opt => {
          if(opt.type !== "url" && opt.type !== "call") {
            optionsOutput.push({
              id: opt.id,
              output: `output_${count}`,
            })
            count++;
          }
        })

        let outputs = Object.values(this.editor.getNodeFromId(id).outputs);
        outputs.forEach((opt, key) => {
          if(opt.connections.length) {
            this.editor.removeSingleConnection(id, opt.connections[0].node, `output_${key + 1}`, "input_1");
          }
        })

        setTimeout(() => {
          connections.forEach(c => {
            let item = optionsOutput.find(opt => opt.id === c.idOption);
            if(item) {
              this.editor.addConnection(id, c.id, item.output, "input_1");
            }
          })

          setTimeout(() => {
            this.updateConnectionList(id, data);
          }, 5)
        }, 10)
      },

      updateConnectionList(id, data) {
        let node = this.editor.getNodeFromId(id);
        if(!data) return;
        if(!data.options) return;

        let options = this.outputsIdFormat([...data.options]);
        let outputs = Object.values({...node.outputs});
        let connections = [];

        outputs.forEach((o, i) => {
          if(o.connections.length) {
            if(data.options[options[i]]) {
              connections.push({
                idOption: data.options[options[i]].id,
                id: o.connections[0].node,
              })
            }
          }
        })

        node.data = data;
        node.data.connections = [...connections];

        
        setTimeout(() => {
          this.editor.updateNodeDataFromId(id, node);
          this.updateDataNode(id, node);
        }, 5)
      },

      /* Seteo los eventos a escuchar que emiten los componentes hijos */
      setEventBusOn() {
        EventBus.$on("changeNodeData", ({ id, data, positionOptionsEdited }) => {
          try {
            this.editor.updateNodeDataFromId(id.slice(5), data);
            if(positionOptionsEdited) {
              setTimeout(() => {
                this.updatePositionOptions(id.slice(5), data)
              }, 1)
            }else {
              this.updateConnectionNodes(id.slice(5));
            }
          } catch (error) {
            // console.log("error", error);
          }
        });

        EventBus.$on("showNodeFallback", (id) => {
          this.showNodeFallback(id);
        });
        
        EventBus.$on("removeNodeId", (id) => {
          this.editor.removeNodeId(`node-${id}`);
        });
        
        EventBus.$on("cloneNodeId", (id) => {
          const node = this.editor.getNodeFromId(id);
          if(node) {
            let outputs = Object.keys(node.outputs).length;

            if(node.data.options) {
              node.data.options.forEach((opt, key) => {
                opt.id = Date.now() + (key * 2);
              })
            }

            node.data.name = null;
            
            this.newItemCanvas({
              item: node.html,
              data: {
                ...node.data,
                connections: [],
                root: false,
              },
              outputs: outputs ? outputs : null,
            })
          }
        });

        EventBus.$on("hideNodeFallback", (data) => {
          this.hideNodeFallback(data);
        });

        EventBus.$on("updateConnectionNodes", (id) => {
          setTimeout(() => {
            this.updateConnectionNodes(id);
          }, 1);
        });

        EventBus.$on(
          "newItemWithConnection", (data) => {
            if(data.item === "GoTo") {
              this.dataModalGoTo = {data, nodes: this.getNodesGoTo(data.idParent) }
            }else {
              this.newItemWithConnection(data);
            }
          }
        );
      },

      // Paso la data a los componentes hijos al importar la info nueva
      setDataImportToComponents(data) {
        const dataItems = data.drawflow.Home.data;
        let keys = Object.keys(dataItems);
        keys.forEach((element) => {
          this.updateDataNode(dataItems[element].id, dataItems[element]);
        });
      },

      // Actualizo la data del componenete que fue editado
      updateDataNode(id, data) {
        EventBus.$emit("updateDataComponent", { id, data });
        this.updateConnectionNodes(id);
      },

      getNodesGoTo(idFilter = null) {
        const nodesFilter = ["Start", "Close", "GoTo", "ContactCenter"];
        let allNodes = Object.values(this.editor.export().drawflow.Home.data);
        let nodes = [];
        allNodes.forEach(node => {
          if(nodesFilter.indexOf(node.name) === -1) {
            if(node.id != idFilter) {
              nodes.push(node.id)
            }
          }
        })
        return nodes;
      },

      async newItemWithConnection({ item, x, y, idParent, output }, data = null) {
        let newNode = await this.newItemCanvas({item, x, y, data});
        this.addConnection(idParent, newNode, output);
      },

      // Creo nuevo item en una posición aleatoria
      newItemCanvas({item, x = null, y = null, data = null, inputs = null, outputs = null}) {
        let vw =
          Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          ) - 350;
        let vh =
          Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
          ) - 350;

        let pos_x = x
          ? x
          : Math.floor(Math.random() * (vw < 1 ? 1 : vw) + 50) -
            this.editor.canvas_x;

        let pos_y = y
          ? y
          : Math.floor(Math.random() * (vh < 1 ? 1 : vh) + 100) -
            this.editor.canvas_y;

        let newID = null;
        this.componentList.forEach((component) => {
          if (component.node === item) {
            newID = this.editor.addNode(
              component.component.name,
              inputs ? inputs : component.in,
              outputs ? outputs : component.out,
              pos_x,
              pos_y,
              component.component.name,
              {},
              component.component.name,
              "vue",
            );
          }
        });

        /* if(item === "GoTo") {
          this.updateDataNode(newID, {data})
        } */
        if(data) {
          this.updateDataNode(newID, {data})
        }

        return newID;
      },

      addConnection(id_output, id_input, output_class = "output_1") {
        this.editor.addConnection(id_output, id_input, output_class, "input_1");
      },

      // Reubico los nodos de salida acorde a las opciones en caso de tenerlas
      updateConnectionNodes(id) {
        setTimeout(() => {
          var dataNode = null;
          try {
            dataNode = this.editor.getNodeFromId(id);
          } catch (error) {
            return;
          }

          if (dataNode.name === "Start" || dataNode.name === "Close" || dataNode.name === "ContactCenter") return;

          let nodeDOM = document.querySelector(`#node-${id}`);
          let outputsDOM = document.querySelector(`#node-${id} .outputs`);

          this.updateInputNodePosition(id);

          /* if (
            dataNode.name === "MessageWithOptions" ||
            dataNode.name === "Condition"
          ) { */
          if (dataNode.data.options) {
            outputsDOM.style.height = "0px";
            let optionsDOM = document.querySelector(`#node-${id} .options ul`);
            outputsDOM.style.height = `${
              nodeDOM.getBoundingClientRect().height / this.editor.zoom - 2
            }px`;

            let updateNodes = this.outputsIdFormat(dataNode.data.options);

            
            updateNodes.forEach((i, key) => {
              if (optionsDOM.children[i]) {
                /* if(outputsDOM.children[key]) {
                  if(optionsDOM.children.length - 1 !== i) {
                    if(outputsDOM.children[key].classList.contains("fallbackHidden")) {
                      outputsDOM.children[key].classList.remove("fallbackHidden")
                    }
                  }else {
                    if(!outputsDOM.children[key].classList.contains("fallbackHidden")) {
                      outputsDOM.children[key].classList.add("fallbackHidden")
                    }
                  }
                } */


                let top =
                  (optionsDOM.children[i].getBoundingClientRect().top -
                    nodeDOM.getBoundingClientRect().top) /
                  this.editor.zoom;

                if (outputsDOM.children[key]) {
                  outputsDOM.children[key].style.top = `${
                    top + 7.5 * this.editor.zoom
                  }px`;
                  outputsDOM.children[key].style.right = `-8px`;
                  outputsDOM.children[key].style.position = `absolute`;
                }
              }
            });
          } else {
            let node = document.querySelector(`#node-${id}`);
            let target = document.querySelector(
              `#node-${id} .outputs .output_1`
            );

            if (target != null) {
              let posNode = node.getBoundingClientRect();
              target.style.top = `-${
                posNode.height / this.editor.zoom / 2 - 17
              }px`;
            }
          }

          setTimeout(() => {
            this.editor.updateConnectionNodes(`node-${id}`);
          }, 1);
        }, 1);
      },

      updateInputNodePosition(id) {
        let node = document.querySelector(`#node-${id}`);
        let target = document.querySelector(`#node-${id} .inputs .input_1`);

        if (target != null) {
          let posNode = node.getBoundingClientRect();
          // target.style.top = `-${posNode.height / 2 - 17}px`;
          target.style.top = `-${posNode.height / this.editor.zoom / 2 - 17}px`;
        }
      },

      outputsIdFormat(options = null) {
        let keys = [];
        if (options) {
          options.forEach((o, index) => {
            if (
              o.type === "reply" ||
              o.type === "fallback" ||
              o.type === "condition"
            ) {
              keys.push(index);
            }
          });
        }

        return keys;
      },

      showNodeFallback(id) {
        let node = document.querySelector(`#node-${id}`);
        if (node) {
          if(node.classList.contains("fallbackHidden")){ 
            node.classList.remove("fallbackHidden");
          }
        }
      },

      hideNodeFallback(id) {
        let node = document.querySelector(`#node-${id}`);
        if (node) {
          node.classList.add("fallbackHidden");
        }
      },

      openConfig() {
        this.openModal = !this.openModal;
      },

      updateConfiguration(data) {
        this.configuration = data;
      },

      zoomCanvas(action) {
        switch (action) {
          case "out":
            this.editor.zoom_out();
            break;
          case "reset":
            this.editor.zoom_reset();
            break;
          case "in":
            this.editor.zoom_in();
            break;
        }
      },

      changeLockCanvas() {
        this.lockCanvas = !this.lockCanvas;

        this.editor.editor_mode = this.lockCanvas ? "fixed" : "edit";
      },
    },

    computed: {
      variables() {
        return [
          ...this.configuration.variables,
          {
            editable: false,
            name: "lastUserMessage",
            type: "string",
            value: "",
          },
          {
            editable: false,
            name: "errorCode",
            type:"number",
            value: "",
          },
          {
            editable: false,
            name: "errorMessage",
            type: "string",
            value: "",
          },
        ]
      },

      componentList() {
        return [
          {
            node: "Note",
            in: 0,
            out: 0,
            component: Note,
          },
          {
            node: "Message",
            in: 1,
            out: 1,
            component: Message,
          },
          {
            node: "MessageWithOptions",
            in: 1,
            out: 1,
            component: MessageWithOptions,
          },
          {
            node: "DataStorage",
            in: 1,
            out: 1,
            component: DataStorage,
          },
          {
            node: "Condition",
            in: 1,
            out: 1,
            component: Condition,
          },
          {
            node: "Integration",
            in: 1,
            out: 2,
            component: Integration,
          },
          {
            node: "Start",
            in: 0,
            out: 1,
            component: Start,
          },
          {
            node: "Close",
            in: 1,
            out: 0,
            component: Close,
          },
          {
            node: "GoTo",
            in: 1,
            out: 0,
            component: GoTo,
          },
          {
            node: "ContactCenter",
            in: 1,
            out: 0,
            component: ContactCenter,
          },
        ];
      },

      flowID() {
        return this.$route.params.id;
      },

      zoomOptions() {
        return [
          {
            icon: "el-icon-zoom-out",
            action: "out",
            tooltip: "Zoom out",
          },
          {
            icon: "el-icon-search",
            action: "reset",
            tooltip: "Zoom reset",
          },
          {
            icon: "el-icon-zoom-in",
            action: "in",
            tooltip: "Zoom in",
          },
        ];
      },

      dataModalConfirm() {
        const data = {
          clear: {
            title: "Are you sure you want to delete everything?",
            text: "",
            confirm: this.clear,
          },

          closeNodes: {
            title: "You still have unclosed nodes",
            text: "Do you want to close them automatically?",
            confirm: this.autoCloseNodes,
          },
        };

        if (this.modalConfirm.type) {
          return data[this.modalConfirm.type];
        }

        return null;
      },
    },
  };
</script>

<style>
  .wrapperCanvas {
    margin: 0px;
    padding: 0px;
    width: 100vw;
    /* height: calc(100vh - 5px); */
    height: 100vh;
    overflow: hidden;
    display: flex;
  }

  .wrapperCanvas button {
    outline: 0 !important;
  }

  .wrapperCanvas #drawflow {
    position: relative;
    width: 100vw;
    height: calc(100% - 2px);
    top: 0;
    background: #ffffff;
    background-size: 25px 25px;
    background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px),
      linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
  }

  /* Editing Drawflow */

  .wrapperCanvas .drawflow .drawflow-node {
    background: #ffffff;
    border: 1px solid #cacaca;
    -webkit-box-shadow: 0 2px 15px 2px #cacaca;
    box-shadow: 0 2px 15px 2px #cacaca;
    padding: 0px;

    border-radius: 8px;
    width: 275px;

    transition: box-shadow linear .18s;
  }

  .wrapperCanvas.lock .drawflow .drawflow-node {
    cursor: default;
  }

  .wrapperCanvas .drawflow .drawflow-node.Note {
    width: 200px;
  }

  .wrapperCanvas .padding-limit textarea {
    padding-right: 80px;
  }

  .wrapperCanvas .options-canvas {
    position: fixed;
    bottom: 40px;
    left: 40px;
  }

  .wrapperCanvas .drawflow .connection .main-path {
    stroke: #4ea9ff;
    stroke-width: 3px;
  }

  .wrapperCanvas .drawflow .drawflow-node .input,
  .wrapperCanvas .drawflow .drawflow-node .output {
    height: 15px;
    width: 15px;
    border: 2px solid #cacaca;
  }

  .wrapperCanvas .drawflow .drawflow-node .input:hover,
  .wrapperCanvas .drawflow .drawflow-node .output:hover {
    background: #4ea9ff;
  }

  .wrapperCanvas.lock .drawflow .drawflow-node .input,
  .wrapperCanvas.lock .drawflow .drawflow-node .output {
    background: #fff;
    cursor: default;
  }

  .wrapperCanvas .drawflow .drawflow-node .output {
    right: 7px;
  }

  .wrapperCanvas .drawflow .drawflow-node .input {
    left: -10px;
    background: white;
    top: 0;
  }

  .wrapperCanvas .drawflow > .drawflow-delete {
    border: 2px solid #43b993;
    background: white;
    color: #43b993;
    -webkit-box-shadow: 0 2px 20px 2px #43b993;
    box-shadow: 0 2px 20px 2px #43b993;
  }

  .wrapperCanvas .drawflow-delete {
    border: 2px solid #4ea9ff;
    background: white;
    color: #4ea9ff;
    -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
    box-shadow: 0 2px 20px 2px #4ea9ff;
  }

  /* .wrapperCanvas .Message .drawflow-delete,
  .wrapperCanvas .MessageWithOptions .drawflow-delete,
  .wrapperCanvas .DataStorage .drawflow-delete,
  .wrapperCanvas .Condition .drawflow-delete,
  .wrapperCanvas .Integration .drawflow-delete {
    display: none!important;
  } */


  .wrapperCanvas .drawflow-node .box {
    padding: 5px;
    font-size: 14px;
    color: #555555;
  }
  .wrapperCanvas .drawflow-node .box p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .wrapperCanvas .drawflow-node .box .el-textarea__inner {
    padding: 5px !important;
  }

  .wrapperCanvas .drawflow .connection .point {
    stroke: #cacaca;
    stroke-width: 2;
    fill: white;
  }

  .wrapperCanvas .drawflow .connection .point.selected,
  .wrapperCanvas .drawflow .connection .point:hover {
    fill: #4ea9ff;
  }

  /* .wrapperCanvas .fallbackHidden {
    z-index: -1 !important;
    right: 25px !important;
    opacity: 0;
  } */
  
  .wrapperCanvas .fallbackHidden .output:last-of-type {
    z-index: -1 !important;
    right: 25px !important;
    opacity: 0;
  }

  /* NAV */
  .wrapperCanvas .nav-canvas {
    position: fixed;
    background: white;
    width: 100%;
    height: 40px;
    z-index: 1000000;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.4);
  }

  .wrapperCanvas .nav-canvas h1 {
    font-size: 16px;
    display: inline;
    margin: 0;
    margin-left: 15px;
    font-weight: bold;
  }

  .wrapperCanvas .nav-canvas i {
    font-size: 16px;
    color: #222;
  }

  .wrapperCanvas .invalid.el-tabs__item {
    color: red;
  }

  .wrapperCanvas .invalid .el-input__inner,
  .wrapperCanvas .invalid .el-textarea__inner {
    border-color: red !important;
  }

  /* MESSAGES */
  .wrapperCanvas .text-response {
    font-size: 12px;
    padding: 5px 8px;
    background: #eee;
    border-radius: 10px;
    word-wrap: break-word;
  }

  .wrapperCanvas.dark .text-response {
    background: #555;
    color: #ddd;
  }

  .wrapperCanvas .text-response p {
    margin-bottom: 0;
  }

  /* OPTIONS */
  .wrapperCanvas .options ul {
    margin: 0;
    padding: 0;
  }

  .wrapperCanvas .options ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .wrapperCanvas .options ul li {
    font-size: 12px;
    padding: 5px 10px;
    border-top: 1px solid #ccc;
  }

  .wrapperCanvas .options ul li.fallback {
    background: #eee;
  }

  .wrapperCanvas .options ul li i {
    font-size: 10px;
    font-weight: bold;
  }

  .wrapperCanvas .el-dialog__wrapper.confirm-dialog .el-dialog__body {
    padding: 0 20px;
  }

  .wrapperCanvas .el-dialog__wrapper:not(.confirm-dialog) .el-dialog {
    min-height: 25vh;
    max-height: 80vh;
    /* overflow-y: scroll; */
  }

  .wrapperCanvas
    .el-dialog__wrapper:not(.confirm-dialog)
    .el-dialog
    .el-dialog__body {
    max-height: calc(80vh - 120px);
    overflow-y: scroll;
    padding-top: 10px;
  }

  .wrapperCanvas
    .el-dialog__wrapper:not(.confirm-dialog)
    .el-dialog
    .el-dialog__footer {
    padding: 20px;
  }

  .wrapperCanvas
    .el-dialog__wrapper:not(.confirm-dialog)
    .el-dialog
    .el-dialog__body::-webkit-scrollbar {
    width: 6px;
  }

  .wrapperCanvas
    .el-dialog__wrapper:not(.confirm-dialog)
    .el-dialog
    .el-dialog__body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  .wrapperCanvas .el-dialog .label {
    font-size: 11px;
    font-weight: bold;
  }

  /* DARK */
  .wrapperCanvas.dark .el-dialog {
    background: #2d2d2d;
  }

  .wrapperCanvas.dark .el-tabs__nav-wrap::after {
    background-color: #2d2d2d;
  }

  .wrapperCanvas.dark .el-dialog .el-tabs__item,
  .wrapperCanvas.dark .el-dialog .el-dialog__title {
    color: #eee;
  }

  .wrapperCanvas.dark .el-dialog .el-tabs__item.is-active {
    color: #409eff;
  }

  .wrapperCanvas.dark .el-dialog .el-input__inner,
  .wrapperCanvas.dark .el-dialog .el-textarea__inner {
    color: #eee;
    background: #353535 !important;
    border: 1px solid #555;
  }

  .wrapperCanvas.dark .el-dialog .el-input-group__append {
    color: #eee;
    background-color: #555 !important;
    border: 1px solid #555;
  }

  .wrapperCanvas.dark .el-dialog .is-disabled .el-input-group__append {
    color: #aaa;
    background-color: #353535 !important;
  }

  .wrapperCanvas.dark .el-collapse-item__header,
  .wrapperCanvas.dark .el-collapse-item__content {
    color: #eee;
    background: #2d2d2d !important;
  }

  .wrapperCanvas.dark .el-collapse {
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
  }

  .wrapperCanvas.dark .el-collapse-item__header,
  .wrapperCanvas.dark .el-collapse-item__wrap {
    border-bottom: 1px solid #555;
  }

  .wrapperCanvas.dark .el-collapse-item__header.is-active {
    border-bottom: 1px solid transparent;
  }

  .wrapperCanvas.dark .el-tag {
    border-color: transparent;
  }

  /* DARK MODE */
  .wrapperCanvas.dark #drawflow {
    background: #000;
    background-size: 25px 25px;
    background-image: linear-gradient(to right, #333 1px, transparent 1px),
      linear-gradient(to bottom, #333 1px, transparent 1px);
  }

  .wrapperCanvas.dark .nav-canvas {
    background: #1f1f1f;
    color: #ddd;
  }

  .wrapperCanvas.dark .nav-canvas i {
    color: #ddd;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node {
    background: #2d2d2d;
    border: 1px solid #555;
    -webkit-box-shadow: 0 2px 10px 2px rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 10px 2px rgba(255, 255, 255, 0.3);
  }

  .wrapperCanvas.dark .el-button--default,
  .wrapperCanvas.dark .el-button.is-round {
    background: #353535;
    border: 1px solid #555;
    color: #ddd;
  }

  .wrapperCanvas.dark .options ul li {
    border-top: 1px solid #555;
    background: #353535;
    color: #ddd;
  }

  .wrapperCanvas.dark .options ul li.fallback {
    border-top: 1px solid #555;
    background: #555;
  }

  .wrapperCanvas.dark .options-canvas button {
    background: #353535;
    color: #ddd;
    border-color: #555;
  }

  .wrapperCanvas.dark .el-dialog .label {
    color: #ddd;
  }

  .wrapperCanvas.dark .el-input__count,
  .wrapperCanvas.dark .el-input__count .el-input__count-inner {
    background: transparent;
  }
</style>
