<template>
  <div>
    <div class="row">
      <div class="col-12">
        FALLBACK
        <el-switch v-model="fallbackEnabled"> </el-switch
        >{{ $t("Send communication through alternative channels") }}
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-4">
        <div class="form-group has-label">
          <label>{{ $t("When the user") }}</label>
          <fg-input :required="fallbackEnabled" :error="getError('form.fallbackReason')">
            <el-select
              :disabled="!fallbackEnabled"
              v-model="form.fallbackReason"
              :name="'fallbackReason'"
              v-validate="{ required: fallbackEnabled }"
              :placeholder="$t('Select')"
              size="mini"
            >
              <el-option
                v-for="option in rcsEnabledOptions"
                :key="option.value"
                :label="$t(option.label)"
                :value="option.value"
                clearable
                collapse-tags
                filterable
              >
              </el-option>
            </el-select>
          </fg-input>
        </div>
      </div> -->
      <div class="col-8">
        <ChannelDropdown
          v-model="form.fallbackChannelId"
          :size="'small'"
          :label="channelLabel"
          :disabled="!fallbackEnabled"
          :allowedChannels="allowedChannels"
        />
      </div>
      <!-- <div class="col-3">
        <div class="form-group has-label">
          <label>{{ $t("Template") }}</label>
          <el-select
            :disabled="!fallbackEnabled"
            v-model="form.fromExistingTemplate"
            size="mini"
            :placeholder="$t('Select')"
          >
            <el-option
              v-for="option in fallbackOptions"
              :key="option.value"
              :label="$t(option.label)"
              :value="option.value"
              clearable
              filterable
              collapse-tags
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div class="col-3 button-col">
        <button
          :disabled="!fallbackEnabled || !form.fallbackChannelId"
          type="button"
          class="btn btn-success mr-3"
          @click="openModal"
        >
          {{ $t("Select template") }}
        </button>
      </div>
    </div>
    <small class="text-danger" v-if="noTemplates">{{
      $t("Selected channel has no tenmplates available")
    }}</small>
    <small v-if="!noTemplates"
      >{{ $t("Selected template: ") }}{{ selectedTemplateName }}</small
    >
    <small v-if="!noTemplates"
      >{{ $t("Selected creative: ") }}{{ selectedCreativeName }}</small
    >
    <modal :name="'modal'" height="auto" :scrollable="true" :classes="modalClasses">
      <div class="modal-content bookmark-modal">
        <div class="modal-body">
          <div v-if="templates.length === 0">
            <loading :active="true" :is-full-page="false"></loading>
          </div>
          <div v-else-if="templates.length === 1">
            <card-bookmark
              :bookmark="templates[0]"
              :actions="['select']"
              :showOnlyApprovedCreativities="true"
              @selected="setBookmarkAndCreative"
            >
            </card-bookmark>
          </div>
          <div v-else class="row">
            <div
              class="col-md-4"
              v-for="template in templates"
              :key="'template-' + template.id"
            >
              <card-bookmark
                :bookmark="template"
                :actions="['select']"
                ref="cardBookmark"
                :showOnlyApprovedCreativities="true"
                @selected="setBookmarkAndCreative"
              >
              </card-bookmark>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Input, Button, Switch } from "element-ui";
import ChannelDropdown from "src/app-components/form-elements/ChannelDropdown";
import CardBookmark from "src/app-components/CardBookmark";
import Loading from "vue-loading-overlay";
import { EventBus } from "src/eventBus";
import api from "src/api";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [Switch.name]: Switch,
    ChannelDropdown,
    CardBookmark,
    Loading,
  },
  async mounted() {
    this.$store.dispatch("loadBookmarks");
    if (this.existingFallback) {
      if(this.existingFallback.template_id) {
        this.fallbackEnabled = true;
        this.fallback = this.existingFallback;
        await this.getFallbackTemplateById(this.fallback.template_id);
      }
    }
  },
  data() {
    const fallbackOptions = [
      {
        label: this.$t("Existing one"),
        value: true,
      },
      {
        label: this.$t("New one"),
        value: false,
      },
    ];
    const rcsEnabledOptions = [
      { label: this.$t("Is not RCS enabled"), value: "notEnabled" },
      { label: this.$t("Is not RCS compatible"), value: "notCompatible" },
    ];
    const allowedChannels = [1, 2, 3];
    return {
      selectedTemplateName: "",
      selectedCreativeName: "",
      fallbackEnabled: false,
      noTemplates: false,
      form: {
        fromExistingTemplate: true,

        fallbackChannelId: null,
        fallbackReason: "notCompatible",
        selectedBookmarkId: null,
      },
      fallback: {},
      rcsEnabledOptions,
      fallbackOptions,
      allowedChannels,
      templates: [],
      selection: null,
    };
  },
  props: {
    template: {
      type: Object,
    },
    existingFallback: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    channelLabel() {
      return this.$t("Send");
    },
    modalClasses() {
      return this.templates.length > 1
        ? "modal-dialog modal-lg modal-xl bookmark-modal"
        : "modal-dialog modal-sm bookmark-modal";
    },
    shouldValidate() {
      return this.fallbackEnabled ? "required" : false;
    },
  },
  methods: {
    async openModal() {
      this.templates = await this.getTemplates();
      if (this.templates.length === 0) {
        this.noTemplates = true;
        return;
      } 
        this.noTemplates = false;
        this.$modal.show("modal");
      
    },
    getError(fieldName) {
      return this.$t(this.errors.first(fieldName));
    },
    async getTemplates() {
      return await this.$store.getters.activeBookmarks.filter(
        (bookmark) =>
          bookmark.channel_id === this.form.fallbackChannelId &&
          bookmark.status === "APPROVED"
      );
    },
    setBookmarkAndCreative(selection) {
      this.fallback.template_id = selection.bookmark ? selection.bookmark.id : "";
      this.fallback.creative_id = selection.creativity ? selection.creativity.id : "";
      this.selection = selection;
      this.selectedTemplateName = selection.bookmark.name;
      this.selectedCreativeName = selection.creativity.variables[0].value;
      EventBus.$emit("fallback-creativity-selected", this.fallback);
      this.$modal.hide(`modal`);
    },
    async getFallbackTemplateById(templateId) {
      const template = (await api.getTemplate(templateId)).data;
      this.selectedTemplateName = template.name;
      const creative = template.creatives.find((c) => c.id === this.fallback.creative_id)
        .variables[0];
      this.selectedCreativeName = creative.value;
    },
  },
};
</script>

<style>
.button-col {
  margin-top: 1%;
}
</style>
