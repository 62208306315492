<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <!--
                <fg-input class="mt-3" label="#">
                    <div>{{ value }}</div>
                </fg-input>
                -->
                <div class="text-muted"># {{ value }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: null,
        },
    }
</script>

<style>

</style>
