<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "Programmatic",

    extends: Report,

    created() {
        this.tableCols = [
            { label: "Campaign", hasLabel: true, minWidth: 100 },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent_Ping", label: "Sent Ping", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives_Rate", label: "%Active", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
        ];

        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
            { prop: "Product", label: "Template" },
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            // Not Lauch browser and not ping
            return scenario.scenario.sat_call_to_action_id != 1 && scenario.scenario.sat_call_to_action_id != null;
        });
    },

    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "campaign_name ASC",
                "product_name ASC"
            ]
        },

        /**
         * @Override
         */
        tableRowClassName({row, rowIndex}) {
            if(!row.Campaign) {
                return 'success-row';
            }
            
            return '';
        },

        async getData(query) {
            query.ql.where["campaign_type"] = "PERFORMANCE";
            query.ql.where["product_id <>"] = -1;
            const response = await api.getCampaignPivot(query);
            this.tableData = response.data;
        },
    }
}
</script>