<template>
  <div class="card p-3 gbm-agent-form">
    <div class="d-md-flex justify-content-between">
      <div class="mb-3" :class="errorItem(agent.agentName) ? 'error' : ''">
        <span>Name*</span>
        <el-input v-model="agent.agentName" placeholder="Name"></el-input>
      </div>
      <div
        class="mb-3"
        :class="errorItem(agent.brand.displayName) ? 'error' : ''"
      >
        <span>Brand*</span>
        <!-- <el-input v-model="agent.brand.displayName" placeholder="Brand"></el-input> -->
        <el-autocomplete
          class="inline-input w-100"
          v-model="agent.brand.displayName"
          :fetch-suggestions="querySearch"
          placeholder="Brand"
          @select="handleSelect"
        ></el-autocomplete>
      </div>
    </div>

    <div class="d-md-flex justify-content-between">
      <div class="mb-3">
        <span>Default Locale</span>
        <el-select
          placeholder="Default Locale"
          v-model="agent.defaultLocale"
          class="d-block"
        >
          <el-option value="en">EN</el-option>
          <el-option value="es">ES</el-option>
        </el-select>
      </div>

      <div class="mb-3" :class="!validUrl && validate ? 'error' : ''">
        <span>Privacy Policy</span>
        <el-input
          v-model="agent.privacyPolicy"
          placeholder="https://app.satpush.com"
        ></el-input>
      </div>
    </div>
    
    <div class="mb-3" :class="errorItem(agent.welcomeMessage) ? 'error' : ''">
      <span>Welcome Message*</span>
      <el-input
        type="textarea"
        placeholder="Please input"
        v-model="agent.welcomeMessage"
        maxlength="1000"
        show-word-limit
      >
      </el-input>
    </div>

    <el-button type="primary" @click="createAgentGBM" :disabled="loading">
      <i class="el-icon-loading" v-if="loading"></i>
      <span v-else>Save</span>
    </el-button>
    <el-button
      type="text"
      @click="cancel"
      :disabled="loading"
      class="mt-2 pb-0"
    >
      Cancel
    </el-button>
  </div>
</template>

<script>
  import sio from "@/sio";
  import api from "src/api";
  import { mapMutations } from "vuex";
  import { Input, Button, Autocomplete } from "element-ui";
  export default {
    name: "FormGBM",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Autocomplete.name]: Autocomplete,
    },

    created() {
      if(!this.$can('Views', 'Services')) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle("Create Service GBM");
      this.agent = { ...this.dataAgent };

      if (this.$route.params.id) {
        this.getAgent(this.$route.params.id);
      }

      this.getBrands();

      sio.onAny((event, obj) => {
        console.log("agent", obj);
        if (obj.metadata) {
          if (obj.metadata.service) {
            this.loading = false;
            if(obj.metadata.service.status) {
              this.validate = false;
              this.agent = { ...this.dataAgent };
              this.agent.brand.displayName = "";

              this.cancel();
            }
          }
        }
      });
    },

    data() {
      return {
        agent: {},

        loading: false,
        validate: false,

        brands: [],
      };
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      querySearch(queryString, cb) {
        var brands = this.brands;
        var results = queryString
          ? brands.filter(
              (brand) =>
                brand.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
                0
            )
          : brands;
        // call callback function to return suggestions
        cb(results);
      },

      handleSelect(item) {
        // console.log(item);
        this.agent.brand.id = item.id;
      },

      async createAgentGBM() {
        if (this.loading) return;
        this.validateItems();
        if (
          this.agent.agentName &&
          this.agent.brand.displayName &&
          this.agent.welcomeMessage &&
          this.validUrl
        ) {
          this.loading = true;
          try {
            // await api.createAgentGBM(this.agent);

            await api.createService({
              channel_id: 7,
              chatbot_params: this.agent,
            });

            // console.log("createAgentGBM", res);
          } catch (error) {
            this.loading = false;
            this.$notify({
              title: this.$t("An error has ocurred"),
              type: "danger",
            });
          }
        }
      },

      errorItem(item) {
        if (this.validate) {
          if (item === "") return true;
        }

        return false;
      },

      validateItems() {
        this.validate = true;

        setTimeout(() => (this.validate = false), 5000);
      },

      cancel() {
        this.$router.push({ name: "ServicesIndex" });
      },

      async getAgent(id) {
        let res = await api.getAgentGBM(id);
        // console.log("getAgent", res);
        if (res.status) {
          this.agent = {
            agentName: res.data.agent_name,
            brand: res.data.brand,
            welcomeMessage: res.data.welcome_message,
            privacyPolicy: res.data.privacy_policy,
            defaultLocale: res.data.default_locale,
            agentData: res.data.agent_configuration,

            id: res.data.id,
            flow_id: res.data.flow_id,
            updated_at: res.data.updated_at,
            created_at: res.data.created_at,
            agent_key: res.data.agent_key,
          };
        } else {
          this.cancel();
        }
      },

      async getBrands() {
        let res = await api.getBrandsGBM();
        // console.log("getBrands", res);
        if (res.status) {
          this.brands = res.data.map((brand) => {
            return {
              id: brand.id,
              value: brand.displayName,
            };
          });
        }
      },
    },

    computed: {
      validUrl() {
        if (this.agent.privacyPolicy !== "") {
          const regex =
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
          return regex.test(this.agent.privacyPolicy);
        }
        return true;
      },

      optionsDays() {
        return [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
        ];
      },

      dataAgent() {
        return {
          agentName: "",
          brand: {
            displayName: "",
          },
          welcomeMessage: "",
          privacyPolicy: "",
          defaultLocale: "en",

          agentData: {
            interactionType: "BOT",
            botRepresentative: {
              botMessagingAvailability: {
                hours: [
                  {
                    startTime: {
                      hours: 0,
                    },
                    endTime: {
                      hours: 23,
                    },
                    timeZone: "America/Los_Angeles",
                    startDay: "MONDAY",
                    endDay: "SUNDAY",
                  },
                ],
              },
            },
          },
        };
      },
    },
  };
</script>

<style scoped>
  .gbm-agent-form .error {
    color: red;
  }
  .gbm-agent-form {
    max-width: 800px;
    margin: 0 auto;
  }

  .gbm-agent-form .d-md-flex > div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .gbm-agent-form .d-md-flex > div {
      width: 49%;
    }

    .gbm-agent-form .d-md-flex.time > div {
      width: 24%;
    }
  }
</style>

<style>
  .gbm-agent-form .error .el-input__inner,
  .gbm-agent-form .error .el-textarea__inner {
    border-color: red;
    color: red;
  }
</style>
