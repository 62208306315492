<template>
    <div class="form-group has-label">
        <label class="important">{{ $t('Anti-Spam Type') }}</label>
        <el-select @change="$emit('change', $event)" :value="value">
            <el-option
                v-for="option in options"
                :value="option.value"
                :label="`${$t(option.name)}`"
                :key="option.value"
            >
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {

    props: {
        value: String,
        period: Number,
    },

    data() {
        return {
            options: [
                {
                    name: "Days in a row",
                    value: "N-DAYS",
                },
                {
                    name: "Monthly",
                    value: "MONTHLY",
                },
            ],
        };
    }
};
</script>