<template>
    <fg-input
        :label="$t('Time Frame (days)')"
        :value="value"
        type="number"
        min="0"
        max="365"
        :disabled="isDisabled"
        name="time-frame"
        v-validate="'required|min_value:0|max_value:365'"
        :error="getError('time-frame')"
        @input="(val) => $emit('input', val)"
    />
</template>

<script>
  export default {
    inject: ["getError", "parentValidator"],

    created() {
        this.$validator = this.parentValidator;
    },

    props: {
        value: Number,

        isDisabled: {
          type: Boolean,
          default: false
        }
    }
  };
</script>