<template>
  <div>
    <tabs pills type="primary" icons centered tab-content-classes="tab-space">
    <tab-pane id="rules" key="rules">
      <span slot="title">
          <i class="fa fa-cogs"></i> {{ $t("Rules") }}
      </span>

    <div class="row">
        <div class="col-12 col-md-4">
          <card>
            <div class="card-header">
              <h4 class="card-title">{{ $t("Anti-Spam") }}</h4>
            </div>
            <div class="card-body">
              <AntiSpam :entity="account" />
            </div>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card>
            <div class="card-header">
              <h4 class="card-title">{{ $t("Windowing") }}</h4>
            </div>
            <div class="card-body">
              <Blackout :entity="account" />
            </div>
          </card>
        </div>
        <div class="col-12 col-md-4">
          <card>
            <div class="card-header">
              <h4 class="card-title">{{ $t("Testing MSISDNs") }}</h4>
            </div>
            <div class="card-body">
              <TestingWhitelist />
            </div>
          </card>
        </div>
    </div>
    </tab-pane>

    <tab-pane id="segmmentation-rules" key="segmmentation-rules">
      <span slot="title">
          <i class="fa fa-file-text"></i> {{ $t("Segmentation rules") }}
      </span>

      <SegmentationRules />

    </tab-pane>

    <tab-pane id="files" key="files">
      <span slot="title">
          <i class="fa fa-file-text"></i> {{ $t("Blacklist / Whitelist") }}
      </span>
      <card>
      <div class="card-header">
        <h4 class="card-title">{{ $t("Blacklist / Whitelist") }}</h4>
      </div>
      <div class="card-body">
        <Files :entity="account" @refresh="getAccount" />
      </div>
    </card>
    </tab-pane>

    <tab-pane id="webhooks" key="webhooks" v-if="$can('Planning', 'ApiEvents')">
      <span slot="title">
          <i class="fa fa-share-alt"></i> {{ $t("Webhooks") }}
      </span>
      <Webhooks :entity="account" />
    </tab-pane>
  </tabs>
  </div>
</template>

<script>
  import {Tabs, TabPane} from 'src/components/UIComponents'
  import { mapActions } from "vuex";
  import {Card} from 'src/components/UIComponents'
  import Blackout from 'src/app-components/account-configuration/Blackout'
  import Files from 'src/app-components/account-configuration/Files'
  import AntiSpam from 'src/app-components/account-configuration/AntiSpam'
  import TestingWhitelist from 'src/app-components/account-configuration/TestingWhitelist'
  import Webhooks from 'src/app-components/account-configuration/Webhooks'
  import api from 'src/api'

  import SegmentationRules from 'src/app-components/account-configuration/SegmentationRules'

  export default {
    components: {
      Card,
      Blackout,
      Files,
      AntiSpam,
      Tabs,
      TabPane,
      TestingWhitelist,
      Webhooks,
      SegmentationRules,
    },
    async mounted () {
      this.setTopNavbarTitle(this.$t("Account configuration"));
      this.getAccount();
    },
    methods: {
      ...mapActions(["setTopNavbarTitle"]),

      async getAccount() {
        this.account = (await api.getAccount(this.$auth.selectedAccountId)).data
      }
    },
    data () {
      return {
        account: null,
      }
    },
  };
</script>
<style>
</style>