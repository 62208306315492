<template>
    <Badge v-if="counter > 0" type="danger">{{ displayCounter }}</Badge>
</template>

<script>
import Badge from "src/components/UIComponents/Badge"

export default {
    components: {
        Badge
    },
    data () {
        return {
            counter: 0
        }
    },
    computed: {
        displayCounter () {
            return this.counter >= 10 ? "9+" : this.counter
        }
    },
    methods: {
        liveCampaignsChanged (data) {
            this.counter = data.message
        }
    }
}
</script>
