<template>
  <div>
    <form-campaign
      :tabbed-form-save-button-disabled="workingSave"
      :hide-bookmark-info="true"
      title="Create a new campaign from template"
      @submit="onSubmit"
      :initialBookmarkData="initialBookmarkData"
      :initialCampaignData="campaignDefaults"
      :enableFallback="true"
      :bookmarkProps="{ isEditable: isEditable }"
    >
    </form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from "src/app-components/FormCampaign";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import { mapMutations } from "vuex";
import WorkingMixin from "src/app-components/WorkingMixin";

export default {
  name: 'createFromTemplate',
	components: {
		FormCampaign,
	},

  mixins: [RetryCrudMixin(), WorkingMixin("Save")],

  mounted() {
    this.setTopNavbarTitle(this.$t("Create campaign from template"));
  },

	async created() {
		if ("bookmarkId" in this.$route.params) {
			const response = await api.getTemplate(
				this.$route.params.bookmarkId
			);
			const bookmark = response.data;
			//console.debug("Template", bookmark);

			this.campaignDefaults.account_scenario_type_id =
				bookmark.account_scenario_type_id;
			this.campaignDefaults.channel_id = bookmark.channel_id;
			this.campaignDefaults.category_id = bookmark.category_id;
			this.campaignDefaults.subcategory_id = bookmark.subcategory_id;
			
      this.campaignDefaults.advertiser_id = bookmark.advertiser_id;

			if (
				bookmark.channel_id == 6 && //RCS
				Array.isArray(bookmark.creatives) &&
				bookmark.creatives.length >= 1
			) {
				// seleccionamos automaticamente la primera creatividad
				this.campaignDefaults.perf_text = [
					{
						bookmark_perf_text_hash: bookmark.creatives[0].hash,
					},
				];
			}

      this.initialBookmarkData = response.data;
    }
  },

  computed: {
    isEditable() {
      return false;
    },
  },

	data() {
		return {
			initialBookmarkData: {},
			campaignDefaults: {
				files: [],
				account_scenario_type_id: null,
				category_id: null,
				subcategory_id: null,
				channel_id: null,
				perf_text: [],
				variables: {},
				second_actions: [],
				manual_start: false,
				start_datetime: "",
				end_datetime: "",
        advertiser_id: null,
			},
		};
	},

  methods: {
    async onSubmit(data) {
      try {
        this.setWorkingSave(true);
        const res = await api.createCampaign({
          ...data,
          children: undefined,
        });
        await this.processOperationLog(res.data.id, data.children);
        this.$router.push({ path: `/campaigns/planning` });
      } finally {
        this.setWorkingSave(false);
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
