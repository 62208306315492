<template>
  <div>
    <div v-if="schema">
      <div class="row">
        <div class="col-md-12">
          <slot name="title"></slot>
        </div>
      </div>

      <div class="row">
        <component
          v-for="(field, index) in filteredSchemas"
          :key="index"
          :disabled="disabled"
          :is="field.type.web_component"
          :value="getValue(index, field.id, field)"
          :field="field"
          @input="updateForm(field, $event, index)"
          :class="{
            input: true,
            'is-danger': errors.has(field.name),
          }"
          :width="width"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import NumberInput from "src/app-components/form-elements/Inputs/Number";
import TextInput from "src/app-components/form-elements/Inputs/Text";
import TextBrowser from "src/app-components/form-elements/Inputs/TextBrowser";
import TextOptin from "src/app-components/form-elements/Inputs/TextOptin";
import SelectInput from "src/app-components/form-elements/Inputs/Select";
import TextSms from "src/app-components/form-elements/Inputs/TextSms";
import TextMenuTitle from "src/app-components/form-elements/Inputs/TextMenuTitle";
import TextMenu from "src/app-components/form-elements/Inputs/TextMenu";
import Slider from "src/app-components/form-elements/Inputs/Slider";
import GetInputTextMsg from "src/app-components/form-elements/Inputs/GetInputTextMsg";
import RcsAgentSelect from "src/app-components/form-elements/Inputs/RcsAgentSelect";

export default {
  name: "scenario-required-elements",
  components: {
    GetInputTextMsg,
    NumberInput,
    SelectInput,
    Slider,
    TextInput,
    TextOptin,
    TextSms,
    TextMenu,
    TextMenuTitle,
    TextBrowser,
    RcsAgentSelect,
  },
  props: {
    value: Object,
    scenario: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "col-md-4",
    },
    fillDefaultValues: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    local() {
      return this.value ? this.value : {};
    },

    schemas() {
      if (this.schema == null) {
        return [];
      }
      const creativitiesFilter = this.schema.creativities.map(
        (creativity) => creativity.id
      );
      const requiredFilter = this.schema.variables.filter((variable) => {
        const exists = creativitiesFilter.includes(variable.id);
        return !exists && variable.required && !variable.is_call_to_action;
      });

      return requiredFilter;
    },
    filteredSchemas(){
      return this.schemas.filter(schema => (
        schema.type.web_component !== 'Url' &&
        schema.type.web_component !== 'TimePicker'
      ));
    }
  },

  data() {
    return {
      schema: null,
    };
  },

  watch: {
    scenario: {
      immediate: true,
      handler(nv, ov) {
        if (nv) {
          this.getSchema();
        }
      },
    },

    schema(nv) {
      let length = nv.length;

      if ("scenario-required-elements" === this.$options._componentTag) {
        length = this.schemas.length;
      }

      this.$emit("schema-changed", this.$options._componentTag, length);
    },

    schemas() {
      if (this.fillDefaultValues) {
        let newValues = this.schemas.reduce((accumulator, currentValue) => {
          if (typeof this.local[currentValue.name] !== "undefined")
            return accumulator;
          if (currentValue.value != null)
            return {
              ...accumulator,
              [currentValue.name]: {
                variable_id: currentValue.id,
                value: currentValue.value,
              },
            };
        }, {});
        this.$emit("input", {
          ...this.local,
          ...newValues,
        });
      }
    },
  },
  methods: {
    getSchema() {
      api.getScenarioType(this.scenario).then((res) => {
        this.schema = res.data.scenario;
      });
    },

    getValue(key) {
      if (this.value.variables) {
        if (this.value.variables[key]) {
          return this.value.variables[key].value;
        }
      }

      return this.value.value ? this.value.value : "";
    },

    updateForm(id, value, index) {
      let newObj = { ...this.local };
      if(newObj.variables) {
        newObj.variables[index] = {
          variable_id: id,
          value: value,
        }
      }else {
        newObj.variables = [
          {
            variable_id: id,
            value: value,
          }
        ]
      }

      this.$emit("input", newObj); 
    },
  },
};
</script>
