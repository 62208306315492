<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <div v-if="$auth.loading">
      <loading-cover :visible="true" />
    </div>
    <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    />
    <router-view name="header"></router-view>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import Login from "src/views/Login.vue";
import LoadingCover from "@/components/LoadingCover.vue";
import "sweetalert2/dist/sweetalert2.css";
import "vue-notifyjs/themes/default.css";
//import "flag-icon-css/css/flag-icon.min.css";
//import "flag-icons/css/flag-icons.min.css";
import { mapMutations } from "vuex";
import { EventBus } from "src/eventBus";
import api from "src/api";
import sio from "./sio";
import swal from "sweetalert2";

function getNotificationType(type) {
  if (typeof type == "string") {
    type = type.toLowerCase();

    if (type.startsWith("warn")) {
      return "warning";
    }

    if (type == "error") {
      return "danger";
    }
  }

  return "info";
}

const INACTIVITY = 15 * 60;

export default {
  name: "app",
  components: {
    Login,
    LoadingCover,
  },
  created() {
    this.$i18n.locale = this.$store.getters.appUserLanguage;

    EventBus.$on("axios-request-error", ($event) => {
      console.log($event);
      const message =
        typeof $event.errors === "string"
          ? $event.errors
          : "Oops, something went wrong";
      const title = $event.type == "danger" ? "API Error" : "Warning";
      this.$notifications.clear();
      this.$notify({
        title: this.$t(title),
        message: this.$t(message),
        icon: $event.type == "danger" ? "fa fa-bomb" : "fa fa-info",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: $event.type,
        timeout: 8000,
      });
    });

    //Listening to events
    sio.onAny((event, obj) => {
      // console.log(`Recieved "${event}" event from web-sockets service: `, obj);
      if (obj.metadata) {
        if (obj.metadata.pipelineId || obj.metadata.stageId || obj.service === "campaign-target-validator") return;
      }
      if (typeof obj.message == "string") {
        this.$notify({
          title: "Update",
          message: this.$t(obj.message),
          icon: "fa fa-info",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: getNotificationType(obj.type),
        });
      }
      EventBus.$emit(obj.service, obj); //mandamos el evento y otro archivo lo puede escuchar
    });
  },

  mounted() {
    // TODO: mover a plugin de auth?
    /*
    api.getAuth().then(res => {      
      const userData = res.data;
      this.createSession(userData);
      if(!userData.isSuperAdmin){
        this.setupInactivityLogout();
      }
    });*/
  },

  methods: {
    ...mapMutations(["createSession"]),
    /**
     * Sets up the inactivity logout feature
     */
    setupInactivityLogout() {
      this.countdown = INACTIVITY;
      console.log("Setting up inactivity logout feature...");
      ["click", "scroll"].forEach((event) =>
        window.addEventListener(event, this.handleClick)
      );
      this.timer = setInterval(() => {
        this.countdown -= 1;
        //console.debug("countdown: ", this.countdown);
        if (this.countdown == 60) {
          this.handleInactivityTimeout();
        } else if (this.countdown <= 0) {
          this.logout();
        }
      }, 1000);
    },
    handleClick() {
      this.countdown = INACTIVITY;
    },
    handleInactivityTimeout() {
      swal({
        title: this.$t("Are you still there?"),
        text: "You are about to be logged out",
        showCancelButton: false,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("I'm still here"),
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then((result) => {
        console.log("Value: " + JSON.stringify(result));
        if (result.value === true) {
          this.countdown = INACTIVITY;
        }
      });
    },
    logout() {
      window.location.replace("/site/logout");
    },
  },

  data() {
    return {
      timer: null,
      countdown: INACTIVITY,
      /*user : {
        account_id: appUser.selected_account_id,
        advertiser_id: appUser.advertiser_id,
        user_id: appUser.id
      }*/
    };
  },

  computed: {
    user(){
      return this.$store.state.user;
    }
  },

  watch: {
    async user(userData){
      console.log("Cambio la data del usuario: ", userData);
      if(userData.default_language !== this.$i18n.locale){
        this.$i18n.locale = userData.default_language;
        const messages = await api.getTransalations(userData.default_language);
        this.$i18n.setLocaleMessage(userData.default_language, messages.data);
      }
    },
  },
};
</script>

<style>
@import "../node_modules/pretty-checkbox/dist/pretty-checkbox.css";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
    .fade-leave-to
        /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>