<script>
  import api from "src/api";
  import Report from "./Report";
  import { excelFormatPercentage, excelFormatNumber } from "./excel_formats.js";

  export default {
    name: "ProgrammaticFull",

    extends: Report,

    created() {
      this.tableCols = [
        { label: "Campaign", hasLabel: true, minWidth: 250 },
        { prop: "template", label: "Template", minWidth: 100 },

        {
          prop: "sent_in_sampling",
          label: "Sent in Sampling",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "delivered_in_sampling",
          label: "Deliveries in Sampling",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "acceptance_in_sampling",
          label: "Acceptances in Sampling",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },

        {
          prop: "sent_in_campaign",
          label: "Sent",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "delivered_in_campaign",
          label: "Delivered",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "acceptance_in_campaign",
          label: "Acceptances",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },

        {
          prop: "cap",
          label: "Cap",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "price",
          label: "Price",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        {
          prop: "revenue",
          label: "Revenue",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
        { prop: "rank", label: "Rank" },
        {
          prop: "granted_base",
          label: "Granted Base",
          formatter: this.formatterNumber,
          exportFormatter: Number,
          excelFormat: excelFormatNumber,
        },
      ];

      // this.customTableKeyCols = [{ prop: "Rcs", label: "Rcs" }];
      this.customTableKeyCols = [{ prop: "campaign", label: "Campaign" }];
    },

    watch: {
      search() {
        console.log("search", this.search);
        if (this.search) {
          this.tableData = this.originalData.filter((data) =>
            data.campaign.toLowerCase().includes(this.search.toLowerCase())
          );
        } else {
          this.tableData = [...this.originalData];
        }
      },
    },

    data() {
      return {
        countPkey: 1,
        totalsFromFront: true,
        originalData: [],
      };
    },

    methods: {
      /**
       * @Override
       */
      filterProp() {
        return "Campaign";
      },
      /**
       * @Override
       */
      tableDimensions() {
        return ["id ASC"];
      },

      /**
       * @Override
       */
      tableRowClassName({ row, rowIndex }) {
        if (this.tableKeyCols.length > 0) {
          const prop = this.tableKeyCols[0].prop;
          if (!row[prop]) {
            return "success-row";
          }
        }

        return "";
      },

      prepareFilter() {
        let filter = {
          from: this.filters["campaign_start_datetime >="],
          to: this.filters["campaign_start_datetime <="],
        };

        if (this.dimensions.length) {
          filter.group_by = this.dimensions
            .map((dimension) => dimension.groupingProp)
            .join(",");
        }

        if (this.campaignId) {
          filter.campaign_id = this.campaignId;
        }
        return filter;
      },

      async getData(query) {
        const filters = this.prepareFilter();
        const response = await api.getStatsProgrammatic(filters);
        this.tableData = this.formatData(response.data);
        this.originalData = [...this.tableData];
      },

      setPkey() {
        let key = "pkey_" + this.countPkey;
        this.countPkey++;
        return key;
      },

      formatData(data) {
        let newData = [];
        data.forEach((item) => {
          item.Pkey = this.setPkey();
          let index = newData.findIndex(
            (el) =>
              el.campaign === `${item.campaign} (${item.type.toLowerCase()})`
          );
          if (index === -1) {
            let newItem = {
              ...item,
              children: [],
            };
            newItem.template = "";
            newItem.campaign = `${item.campaign} (${item.type.toLowerCase()})`;
            newItem.rank = "";
            newItem.cap = "";
            newItem.price = "";
            newItem.Pkey = this.setPkey();
            newItem.label = item.campaign;
            newItem.children.push(item);
            newData.push(newItem);
          } else {
            newData[index].children.push(item);
            newData[index].sent_in_sampling += item.sent_in_sampling;
            newData[index].delivered_in_sampling += item.delivered_in_sampling;
            newData[index].acceptance_in_sampling +=
              item.acceptance_in_sampling;
            newData[index].sent_in_campaign += item.sent_in_campaign;
            newData[index].delivered_in_campaign += item.delivered_in_campaign;
            newData[index].acceptance_in_campaign +=
              item.acceptance_in_campaign;
            newData[index].granted_base += item.granted_base;
            newData[index].revenue += item.revenue;
          }
        });

        return newData;
      },
    },
  };
</script>
