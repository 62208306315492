<template>
    <div>
        <!--
        <div class="row">
            <div class="col-md-12">
                <p-button class="pull-right" type="default" tag="a" href="/performance-campaign/add">Add new campaign</p-button>
            </div>
        </div>
        -->

        <div class="row">
            <div class="col-md-12 card">
                <div class="card-header">
                    <div class="category">Performance Campaigns</div>
                </div>
                <div class="card-body row">
                    <div class="col-sm-12">
                        <div class="pull-right">
                            <fg-input class="input-sm"
                                      placeholder="Search"
                                      v-model="searchQuery"
                                      addon-right-icon="nc-icon nc-zoom-split">
                            </fg-input>
                        </div>
                    </div>
                    <div class="col-sm-12 mt-2">
                        <el-table  header-row-class-name="text-primary"
                                  :data="queriedData">
                            <el-table-column v-for="column in tableColumns"
                                             :key="column.label"
                                             :min-width="column.minWidth"
                                             :prop="column.prop"
                                             :label="column.label">
                            </el-table-column>
                            <el-table-column
                                    :min-width="120"
                                    fixed="right"
                                    class-name="td-actions"
                                    label="Actions">
                                <template slot-scope="props">
                                    <!--
                                    <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                                        <i class="fa fa-user"></i>
                                    </p-button>
                                    -->

                                    <p-button type="success" size="sm" icon @click="handleView(props.$index, props.row)">
                                        <i class="fa fa-edit"></i>
                                    </p-button>

                                    <!--
                                    <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                                        <i class="fa fa-times"></i>
                                    </p-button>
                                    -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="col-sm-6 pagination-info">
                        <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                    </div>
                    <div class="col-sm-6">
                        <p-pagination class="pull-right"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total="pagination.total">
                        </p-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import api from 'src/api'
    import {Table, TableColumn, Select, Option} from 'element-ui'
    import PPagination from 'src/components/UIComponents/Pagination.vue'

    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Select)
    Vue.use(Option)

    export default {
        data() {
            return {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableColumns: [
                    {
                        prop: 'name',
                        label: 'Name',
                        minWidth: 200
                    },
                    {
                        prop: 'accountScenario.scenario.name',
                        label: 'Scenario',
                        minWidth: 250
                    },
                    {
                        prop: 'performance_type.name',
                        label: 'TYPE',
                        minWidth: 100
                    },
                    {
                        prop: 'bookmark_sum',
                        label: '# Bookmark',
                        minWidth: 120
                    }
                ],
                tableData: []
            }
        },

        created() {
            api.getPerformanceCampaigns().then(res => this.tableData = res.data).catch(err => console.log("Error retrieving data from api"));
        },

        components: {
            PPagination
        },
        computed: {
            pagedData () {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            queriedData () {
                if (!this.searchQuery) {
                    this.pagination.total = this.tableData.length
                    return this.pagedData
                }
                let result = this.tableData
                    .filter((row) => {
                        let isIncluded = false
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString().toLowerCase()
                            if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true
                            }
                        }
                        return isIncluded
                    })
                this.pagination.total = result.length
                return result.slice(this.from, this.to)
            },
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total () {
                this.pagination.total = this.tableData.length
                return this.tableData.length
            }
        },

        methods: {
            handleView (index, row) {
                this.$router.push({ name: 'ViewPerformanceCampaign', params: { id: row.id }})
            },

            handleDelete (index, row) {
                let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === row.id)
                if (indexToDelete >= 0) {
                    this.tableData.splice(indexToDelete, 1)
                }
            }
        }
    }
</script>

<style lang="scss">
    .el-table .td-actions{
        button.btn {
            margin-right: 5px;
        }
    }
</style>