<template>
<div>
<card class="form-horizontal" v-if="entity">
    <h4 slot="header" class="card-title">
        Details of your campaign
    </h4>

    <div class="row">
      <label class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-7">
        <fg-input type="text"
                  disabled="disabled"
                  v-model="entity.name">
        </fg-input>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Scenario</label>
      <div class="col-sm-3">
        <fg-input type="text"
                  v-model="entity.accountScenario.scenario.name"
                  disabled="disabled">
        </fg-input>
      </div>
      <div class="col-sm-4">
        <fg-input type="text"
                  disabled="disabled"
                  v-model="entity.accountScenario.scenario.channel.name">
        </fg-input>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Type</label>
      <div class="col-sm-7">
        <fg-input type="text"
                  disabled="disabled"
                  v-model="entity.performance_type.name">
        </fg-input>
      </div>
    </div>

    <!--<div class="row" v-for="(percent, index) in entity.percents" :key="index">
      <label class="col-sm-2 col-form-label"> The {{ percent.ordinal_word }} best</label>
      <div class="col-sm-7">
        <fg-input type="text"
                  name="number"
                  disabled="disabled"
                  v-model="entity.percents[index].value">
        </fg-input>
      </div>
    </div>-->

    <div slot="footer">
      <p-button type="info" @click.prevent="goBack">Back</p-button>
    </div>
  </card>
</div>
</template>

<script>
    import api from 'src/api'
    import { Card } from 'src/components/UIComponents'

    export default {
        components: {
            Card
        },

        data() {
            return {
                entity: null
            }
        },

        created() {
            api.getPerformanceCampaign(this.$route.params.id).then(res => {
                this.entity = res.data;
                console.log(this.entity);
            }).catch(err => console.log("Error retrieving data from api."))
        },

        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
        }
    }
</script>