<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center"
      @keypress.enter="addKeyValue"
    >
      <div
        class="w-50 pr-2"
        :class="variables && !isResponse ? 'pb-24' : ''"
        v-if="isResponse"
      >
        <span class="label">Variables</span>
        <el-select v-model="key" class="w-100">
          <template v-for="v in variables">
            <el-option
              :key="v.name"
              :value="v.name"
              v-if="v.editable"
            ></el-option>
          </template>
        </el-select>
      </div>

      <div
        class="w-100 pr-2"
        :class="variables && !isResponse ? 'pb-24' : ''"
        v-else
      >
        <span class="label">Key</span>
        <el-input v-model="key"></el-input>
      </div>

      <div class="w-100">
        <span class="label">Value</span>
        <el-input v-model="value">
          <template slot="prepend" v-if="isResponse">response.</template>
          <template slot="append">
            <span @click="addKeyValue">
              <i class="el-icon-plus"></i>
            </span>
          </template>
        </el-input>

        <el-dropdown
          trigger="click"
          :disabled="variables ? variables.length === 0 : false"
          v-if="variables && !isResponse"
          class="mt-1"
        >
          <el-button
            type="info"
            size="mini"
            class="px-2 py-1"
            :disabled="variables.length === 0"
            >Variables</el-button
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="v in variables" :key="v.value">
              <span @click="addVariable(v.name)">
                {{ v.name }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-tag
      closable
      :key="index"
      type="info"
      size="small"
      class="m-0 mt-2 mr-1"
      @close="deleteKeyValue(index)"
      :disable-transitions="false"
      v-for="(item, index) in list"
    >
      {{ isResponse ? `@${item.key}` : item.key }}:
      {{ isResponse ? "response." : "" }}{{ item.value }}
    </el-tag>
  </div>
</template>

<script>
  import { clone } from "lodash";
  import {
    Button,
    Input,
    Select,
    Tag,
    Dropdown,
    DropdownMenu,
    DropdownItem,
  } from "element-ui";
  export default {
    name: "FormKeyValue",
    props: {
      list: {
        type: Array,
        default: () => [],
      },
      variables: {
        type: Array,
        default: () => null,
      },
      isResponse: {
        type: Boolean,
        default: () => false,
      },
    },

    components: {
      [Button.name]: Button,
      [Input.name]: Input,
      [Select.name]: Select,
      [Tag.name]: Tag,
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },

    data() {
      return {
        key: "",
        value: "",
        listItems: [],
      };
    },

    mounted() {
      setTimeout(() => {
        this.listItems = clone(this.list);
      }, 1);
    },

    methods: {
      updateData() {
        setTimeout(() => {
          this.$emit("updateData", this.listItems);
        }, 1);
      },

      addKeyValue() {
        if (this.key !== "" && this.value !== "") {
          let index = this.listItems.findIndex((p) => p.key === this.key);
          if (index !== -1) {
            this.listItems[index].value = this.value;
          } else {
            this.listItems.push({
              key: this.key,
              value: this.value,
            });
          }

          this.key = "";
          this.value = "";
          this.updateData();
        }
      },

      deleteKeyValue(index) {
        if (this.listItems[index]) {
          this.listItems.splice(index, 1);
          this.updateData();
        }
      },

      addVariable(variable) {
        let value = this.value.trim();

        if (value.length >= 2) {
          if (this.value.slice(-2) === "{{") {
            this.value = this.value.trim() + `${variable}}}`;
            this.updateData();
            return;
          }
        }

        this.value = this.value.trim() + `{{${variable}}}`;
        this.updateData();
      },
    },
  };
</script>

<style scoped>
  .pb-24 {
    padding-bottom: 24px;
  }
</style>
