const productAssignation = (input) => {
  const form = {
    name: input.name,
    subcategories: []
  };

  input.subcategories.map(category => {
    form.subcategories.push(category.id);
  });

  return form;
};

export { productAssignation }