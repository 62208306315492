<template>
    <div>
        <Provider
                :clearable="true"
                :filterable="true"
                :no-validation="true"
                v-model="filters.provider" />
    </div>
</template>

<script>
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";
  import Provider from 'src/views/smpp-gateway/statistics/SmppGatewayProvider'

  export default {
    components: {
        Provider
    },

    mixins: [
      FilterPanelMixin({
          provider: null
      })
    ],
    methods: {
      prepareFilters (filters) {
        return {
            provider: this.filters.provider,
        };
      },
    },
  };
</script>
<style scoped>
</style>