<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Advertiser') }}</label>
        <el-select
          size="large"
          name="advertiser"
          :filterable="filterable"
          :clearable="clearable"
          v-validate="noValidation ? null : 'required'"
          @input="$emit('input', $event)"
          :error="getError('advertiser')"
          :value="local"
          :disabled="! isEditable"
          :placeholder="''"
        >
          <el-option
            v-for="option in advertisers"
            :value="option.advertiser_id"
            :label="option.name"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
      <div
        v-show="errors.has('advertiser')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('advertiser') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null,
    isEditable: {
      type: Boolean,
      default: true
    },
    noValidation: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    withBookmarks: {
      type: Boolean,
      default: true,
    }
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getAdvertisers();
  },

  data() {
    return {
      advertisers: []
    };
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  methods: {
    async getAdvertisers() {
      const advertisers = await api.getAccountAdvertisers(this.$auth.selectedAccountId, this.withBookmarks);
      this.advertisers = advertisers.data;
    }
  }
};
</script>

<style>
</style>
