<template>
  <div class="card dictionary-index">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t('Sentence')">
        <template slot-scope="scope">
          <span>{{ scope.row.sentence }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Flow ID')"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.agent_id }}</span>
        </template>
      </el-table-column>
      
      <el-table-column
        :label="$t('Node')"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span>Node {{ scope.row.last_node_id }}</span>
        </template>
      </el-table-column>
      
      <el-table-column
        :label="$t('Channel')"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.channel_id | setChannelName
          }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="w-100 mt-2">
      <p-pagination
        class="pull-right"
        v-model="page"
        :page-count="pages"
      ></p-pagination>
    </div>
  </div>
</template>

<script>
  import api from "src/api";
  import { mapMutations } from "vuex";
  import { Table, Button } from "element-ui";
  import Pagination from "src/components/UIComponents/Pagination";
  export default {
    name: "DictionaryIndex",

    components: {
      [Table.name]: Table,
      [Button.name]: Button,
      [Pagination.name]: Pagination,
    },

    data() {
      return {
        dataDictionary: [],
        dataPagination: [],

        page: 1,

        pages: 1,
      };
    },

    created() {
      if(!this.$can('Views', 'Chatbots')) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle("Dictionary");
    },

    mounted() {
      this.getDictionary();
    },

    watch: {
      page() {
        this.getDictionary();
      },
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getDictionary() {
        if (this.dataPagination.length >= this.page) {
          if(this.dataPagination[this.page - 1] !== undefined) {
            this.dataDictionary = [...this.dataPagination[this.page - 1]];
            return;
          }
        }

        let res = await api.getDictionary(this.page);
        if (res.status) {
          this.dataDictionary = [...res.data];
          this.dataPagination[this.page - 1] = [...res.data];
          if (res.metadata.total) {
            this.pages = Math.floor(res.metadata.total / 10) + 1;
          }
        }
      },
    },

    computed: {
      tableData() {
        return this.dataDictionary;
      },
    },
  };
</script>
<style>
  .dictionary-index table > thead > tr > th > div.cell {
    text-align: center !important;
  }

  .dictionary-index table > thead > tr > th:first-of-type > div.cell {
    text-align: left !important;
  }
</style>
