<template>
  <card class="animated fadeInRight" cardBodyClasses="pb-4">
    <div
      slot="footer"
      class="text-muted text-center"
      :class="{ active: true }"
    >
      <a @click.prevent="submitChanges" href>
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        {{ $t("Save changes") }}
      </a>
    </div>
    <div class="row">
      <h4 class="card-title mt-0 col-12">{{ $t("User account").toUpperCase() }}</h4>
      <div class="col-7 col-md-12 col-lg-4">
        <label>{{ $t("Username") }}</label>
        <el-input size="mini" :value="user.email" disabled />
      </div>
      <div class="col-5 col-md-12 col-lg-4">
        <label>{{ $t("Advertiser") }}</label>
        <el-input size="mini" :value="advertiser.name" disabled />
      </div>
    </div>

    <div class="row mt-3">
      <h5 class="card-title h6 mt-0 col-12">{{ $t("Accounts") }}</h5>
      <div class="col-12">
        <label>{{ $t("Current account") }}</label>
        <el-collapse class="mb-2">
          <el-collapse-item>
            <div class="d-flex align-items-center" slot="title">
              <span class="mr-3">{{ user.account.name }}</span>
              <span class="text-info mr-2">{{ $t("Show all") }}</span>
            </div>
            <ol class="mt-2 responsive-columns">
              <li v-for="a in accounts" :key="a.id">{{ a.name }}</li>
            </ol>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <div class="row mt-3">
      <h5 class="card-title h6 mt-0 col-12">{{ $t("Personal data") }}</h5>
      <div class="col-6 col-md-6 col-lg-3">
        <label>{{ $t("Name") }}</label>
        <fg-input :error="getError(`${scope}.firstname`)">
          <el-input
            size="mini"
            :value="user.firstname"
            v-model="user_form.firstname"
            v-validate="'required'"
            :data-vv-scope="scope"
            name="firstname"
          />
        </fg-input>
      </div>
      <div class="col-6 col-md-6 col-lg-3">
        <label>{{ $t("Last Name") }}</label>
        <fg-input :error="getError(`${scope}.lastname`)">
          <el-input
            size="mini"
            :value="user.lastname"
            v-model="user_form.lastname"
            v-validate="'required'"
            :data-vv-scope="scope"
            name="lastname"
          />
        </fg-input>
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <label>{{ $t("Email") }}</label>
        <fg-input :error="getError(`${scope}.email`)">
          <el-input
            size="mini"
            :value="user.email"
            v-model="user_form.email"
            v-validate="'required|email'"
            :data-vv-scope="scope"
            name="email"
          />
        </fg-input>
      </div>
    </div>

    <div class="row mt-3">
      <h5 class="card-title h6 mt-0 col-12">{{ $t("Language") }}</h5>
      <div class="col-12 col-md-12 col-lg-6">
        <label>{{ $t("Default language") }}</label>
        <div class="d-flex">
          <i
            class="flag-icon m-2"
            :class="getFlagIcon(user_form.default_language)"
          ></i>
          <el-select
            class="w-100"
            size="mini"
            :placeholder="$t('Select a language')"
            v-model="user_form.default_language"
            :value="user.default_language"
          >
            <el-option
              v-for="lang in languages"
              :key="lang.value"
              :value="lang.value"
              :label="$t(lang.name)"
            >
              <i class="flag-icon" :class="lang.icon"></i>
              {{ $t(lang.name) }}
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h5 class="card-title h6 mt-0 col-12">{{ $t("Security") }}</h5>
      <div class="col-6">
        <a href @click.prevent="showModal">
          <i class="fa fa-lock" aria-hidden="true"></i>
          {{ $t("Change password") }}
        </a>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "../../components/UIComponents/Cards/Card.vue";
import { Input, Collapse, CollapseItem } from "element-ui";
import api from "src/api.js";

export default {
  components: {
    Card,
    [Input.name]: Input,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    newImg: {
      type: String,
      default: () => "",
    },
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      scope: "user",
      show_modal: false,
      saveEnabled: false,
      advertiser: {
        name: "",
      },
      accounts: [],
      user_form: {},
    };
  },
  watch: {
    user_form: {
      handler(val) {
        this.saveEnabled = !this.hasSameValue(val, this.user);
      },
      deep: true,
    },

    newImg() {
      if(this.newImg !== "") this.saveEnabled = true;
    }
  },
  beforeMount() {
    let { firstname, lastname, email, default_language } = this.user;
    this.user_form = { firstname, lastname, email, default_language };
  },
  mounted() {
    if(this.user.advertiser) {
      this.advertiser = this.user.advertiser;
    }
    this.getAccounts();
  },

  computed: {
    languages() {
      return [
        { name: "Spanish", icon: "flag-icon-es", value: "es-ES" },
        { name: "English", icon: "flag-icon-us", value: "en-US" },
        { name: "Portuguese", icon: "flag-icon-pt", value: "pt-PT" },
      ]
    }
  },

  methods: {
    async getAccounts() {
      const arr = await api.getAccounts();
      this.accounts = arr.data;
    },

    async submitChanges() {
      console.log("submitting user form: ", JSON.stringify(this.user_form));
      const formIsValidated = await this.$validator.validateAll(this.scope);
      this.trimValues(this.user_form);
      if (formIsValidated) {
        this.$emit("submit", this.user_form);
      } else {
        this.$notify({
          title: this.$t("Cannot Submit"),
          message: this.$t("Please check mandatory fields."),
          type: "danger",
        });
      }
    },

    showModal() {
      this.$emit('showModal');
    },

    hasSameValue(obj1, obj2) {
      const keys1 = Object.keys(obj1);

      for (let key of keys1) {
        if (obj1[key].trim() !== obj2[key].trim()) {
          return false;
        }
      }

      return true;
    },

    trimValues(obj) {
      const keys = Object.keys(obj);
      for (let key of keys) {
        obj[key] = obj[key].trim();
      }
    },

    getFlagIcon(val) {
      // arr debe ser un arreglo de objetos
      // id es el valor a buscar en alguno de los atributos del objeto deseado
      return this.languages.find((obj, index) => {
        if (obj.value === val) return true;
      }).icon;
    },

    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    hasError(fieldName) {
      return this.errors.has(fieldName);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-collapse),
:deep(.el-collapse-item),
:deep(.el-collapse-item__header) {
  border: none !important;
}

:deep(.el-collapse-item__header) {
  height: 15px;
  line-height: 15px;
}

:deep(.el-collapse-item__arrow) {
  margin-left: 0;
}

.responsive-columns {
  columns: 2;
}

@media (min-width: 993px) {
  .responsive-columns {
    columns: 2;
  }
}

@media (min-width: 1280px) {
  .responsive-columns {
    columns: 3;
  }
}
</style>
