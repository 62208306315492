<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Status') }}</label>
        <el-select
          :value="local"
          @input="$emit('input', $event)"
          size="large"
          name="campaign_type"
          :clearable="true"
          :placeholder="''">
          <el-option v-for="option in statuses"
            :value="option.id"
            :label="$t(option.name)"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null
  },

  created() {
    this.getCampaignStatuses();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  data() {
    return {
      statuses: []
    };
  },

  methods: {
    getCampaignStatuses() {
      this.statuses = [
        { id: 4, name: "Programmed" },
        { id: 5, name: "Running" },
        { id: 6, name: "Stopped" },
        { id: 7, name:  "Finished" },
        { id: 8, name:  "Paused" },
        { id: 14, name: "Running - Connection down" },
        { id: 15, name: "Ready" },
        { id: 16, name: "Finished with pending users" },
        { id: 22, name: "Draft" },
        { id: 23, name: "Pre-compilation pending" },
        { id: 24, name: "Pre-compilation running" },
        { id: 25, name: "Pre-compilation resuming" },
        { id: 29, name: "Sampling" },
        { id: 30, name: "Waiting for results" },
        { id: 31, name: "Insufficient results" },
      ];
    }
  }
};
</script>

<style>
</style>
