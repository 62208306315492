<template>
    <div>
        <div class="d-flex align-items-center">
            <div>
                <h4 class="m-0 w-auto">{{$t('Submitted queries')}}</h4>
            </div>
            <CrmPagination :tableData="filteredRows" @paginated-table="onPagination($event)"></CrmPagination>
        </div>
        <el-table
            :data="paginatedTable"
            class="w100"
            :default-sort="{prop: 'id', order: 'descending'}"
        >
            <!-- <el-table-column :label="$t('ID')" :width="fitColumnToContent('id', $t('ID'), 60)" prop="id" sortable></el-table-column> -->
            <el-table-column :label="$t('ID')" :width="fitColumnToContent('id', $t('ID'), 60)" sortable>
              <template slot-scope="scope">
                {{ scope.row.id | formatNumber }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Applicant')" :width="fitColumnToContent('user.username', $t('Applicant'), 60)" prop="user.username"></el-table-column>
            <el-table-column :label="$t('Query')" prop="user_query" min-width=250>
                <template slot-scope="scope">
                    <ul class="m-0">
                        <li v-if="scope.row.user_query.target_status_id != null">
                            <b>{{$t('Status')}}:</b> <span class="badge tiny default badge-outline">{{$t(getStatusName(scope.row.user_query.target_status_id))}}</span>
                        </li>
                        <li v-if="scope.row.user_query.fecha_desde && scope.row.user_query.fecha_hasta">
                            <b>{{$t('From')}}:</b> {{formatDate(scope.row.user_query.fecha_desde)}} -
                            <b>{{$t('To')}}:</b> {{formatDate(scope.row.user_query.fecha_hasta)}}
                        </li>
                    </ul>
                    <div v-if="hasMoreDetails(scope.row.user_query)" class="pl-2">
                        <el-collapse>
                            <el-collapse-item :name="scope.row.id">
                                <b class="text-info" slot="title">{{$t('More details')}}</b>
                                <ul class="ml-1">
                                    <li v-if="scope.row.user_query.campaign_type_id && scope.row.user_query.campaign_type_id.length > 0">
                                        <b>{{$t('Campaign Type')}}:</b>
                                        <span v-for="(cti, index) in scope.row.user_query.campaign_type_id" :key="index">
                                            {{$t(getObjectById(campaign_type, cti).name)}}
                                            {{!isTheLastInArray(scope.row.user_query.campaign_type_id, index) ? ", " : ''}}
                                        </span>
                                    </li>
                                    <li v-if="scope.row.user_query.product_id && scope.row.user_query.product_id.length > 0">
                                        <b>{{$t('Template')}}:</b>
                                        <span v-for="p_id in scope.row.user_query.product_id" :key="p_id" class="badge tiny salmon badge-outline break-word">{{p_id}}</span>
                                    </li>
                                    <li v-if="scope.row.user_query.campaign_id && scope.row.user_query.campaign_id.length > 0">
                                        <b>{{$t('Campaign')}}:</b>
                                        <span v-for="c_id in scope.row.user_query.campaign_id" :key="c_id" class="badge tiny info badge-outline break-word">{{c_id | formatNumber}}</span>
                                    </li>
                                    <li v-if="scope.row.user_query.category_id && scope.row.user_query.category_id.length > 0">
                                        <b>{{$t('Category')}}:</b>
                                        <span v-for="cat_id in scope.row.user_query.category_id" :key="cat_id">{{cat_id}}</span>
                                    </li>
                                    <li v-if="scope.row.user_query.subcategory_id && scope.row.user_query.subcategory_id.length > 0">
                                        <b>{{$t('Subcategory')}}:</b>
                                        <span v-for="sc_id in scope.row.user_query.subcategory_id" :key="sc_id">{{sc_id}}</span>
                                    </li>
                                </ul>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    
                </template>
            </el-table-column>
            <el-table-column :label="$t('Query status')" :width="fitColumnToContent('status', $t('Query status'), 60)" prop="status">
                <template slot-scope="scope">
                    <span class="badge w-75" :class="scope.row.status.toLowerCase()">{{$t(scope.row.status)}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column :width="fitColumnToContent('_count', $t('Unique MSISDNs'), 60)" :label="$t('Unique MSISDNs')" prop="_count"></el-table-column> -->
            <el-table-column :width="fitColumnToContent('_count', $t('Unique MSISDNs'), 60)" :label="$t('Unique MSISDNs')">
              <template slot-scope="scope">
                {{ scope.row['_count'] | formatNumber }}
              </template>
            </el-table-column>
            <el-table-column :width="fitColumnToContent('created', $t('Queue time'), 10)" :label="$t('Queue time')">
                <template slot-scope="scope">
                    {{formatDate(scope.row.created)}}
                </template>
            </el-table-column>
            <el-table-column :width="fitColumnToContent('finished', $t('Finished at'), 60)" :label="$t('Finished at')">
                <template slot-scope="scope">
                    {{formatDate(scope.row.finished)}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('Actions')" width="100" align="right">
                <!-- <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" :placeholder="$t('Search')" />
                </template> -->
                <template slot-scope="scope">
                    <p-button
                        v-if="scope.row._count > 0"
                        type="primary"
                        size="sm"
                        @click="handleDownload(scope.row)"
                        :title="$t('Download file')"
                        round outline icon
                    >
                        <i class="fa fa-2x fa-download" aria-hidden="true"></i>
                    </p-button>
                    <p-button
                        type="default"
                        size="sm"
                        :title="$t('Copy query')"
                        @click="emitCopyQuery(scope.row.user_query, scope.row.id)"
                        round icon
                    >
                        <i class="fa fa-2x fa-copy" aria-hidden="true"></i>
                    </p-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { Table, TableColumn, Input, Collapse, CollapseItem } from "element-ui";
import CrmPagination from "../CrmPagination.vue"

import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";
import moment from 'moment';
import FileDownload from 'js-file-download';
import api from "src/api";

const TARGET_STATUS = new Map([
    [0,"Target Base"],
    [1,"Pending"],
    [2,"Sent"],
    [3,"Delivered"],
    [4,"Accepted"],
    [5,"Rejected"],
    [6,"Blacklisted"],
    [8,"Undelivered"],
    [14,"Active SIM cards"],
    [15,"Applet installed"],
    [21,"Sent ping"],
    [24,"Browser Launched"],
    [25,"Incompatible"],
    [26,"Read"],
    [27,"Interactions"],
    ["opt-out","Opt-out"],
    ["opt-in","Opt-in"],
]);

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Input.name]: Input,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        CrmPagination
    },

    props:{
        tableData: {
            type: Array,
            default: () => []
        },
        campaign_type: Array,
    },

    mixins: [
      FitColumnToContentMixin({ tableDataProp: "tableData" })
    ],

    data() {
        return {
            search: "",
            paginatedTable: []
        }
    },

    methods: {
        async handleDownload(job) {
            this.working = true;
            const file = await api.downloadFile(`/crm/campaign/query/${job.id}/download`);
            await FileDownload(
                file,
                job.filename.substring(job.filename.lastIndexOf('/')+1)
            )
        },
        emitCopyQuery(query, id) {
            this.$emit("copyquery", { id, user_query: query });
        },
        onPagination(val){
            this.paginatedTable = val
        },
        formatDate(date){
            return date != null ? moment(date).format('YYYY-MM-DD') : "-"
        },
        hasMoreDetails(data){
            try {
                return data.campaign_id.length + data.campaign_type_id.length + data.category_id.length + data.product_id.length + data.subcategory_id > 0
            } catch {
                return false
            }
        },
        getObjectById(arr, id) {
            // arr debe ser un arreglo de objetos
            // id es el valor a buscar en alguno de los atributos del objeto deseado
            return arr.find((obj, index) => {
                if (obj.id === id) return true;
            });
        },
        isTheLastInArray(arr, index){
            // console.log(arr.length-1, index)
            return index == arr.length - 1
        },
        getStatusName(id){
            if(TARGET_STATUS.has(id)){
                return TARGET_STATUS.get(id);
            }

            return id;
        }
    },

    computed: {
        filteredRows () {
            return this.tableData.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase())).reverse();
        }
    }
};
</script>