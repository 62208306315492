<template>
  <el-dialog :title="title" :visible.sync="dialogStatus" width="500px" class="dialog-integration" @closed="clearData">
    <div v-if="integration">
      <div>
        <span class="label">{{ $t("Name") }}</span>
        <el-input
          size="small"
          class="w-100"
          maxlength="50"
          show-word-limit
          :placeholder="$t('Name')"
          v-model="integration.name"
          :class="nameInUse ? 'error' : ''"
        ></el-input>
        <span class="aclaration" v-if="!nameInUse">{{
          $t("Assign a name to the instance for the integration")
        }}</span>
        <span class="aclaration error" v-else>{{
          $t("The name is already in use")
        }}</span>
      </div>

      <div class="my-2" v-if="!dataIntegration">
        <span class="label">{{ $t("Services") }}</span>
        <el-select v-model="integration.services" placeholder="Services" class="w-100" size="small" multiple>
          <el-option
            v-for="service in optionsServices"
            :key="service.value"
            :value="service.value"
            :label="service.label"
          ></el-option>
        </el-select>
        <span class="aclaration">{{ $t("Select the services for which you will send notifications") }}</span>
      </div>

      <div v-if="dataIntegration">
        <span class="label">{{ $t("Status") }}</span>
        <el-select v-model="integration.status" class="w-100" size="small">
          <el-option :value="1" label="Enabled"></el-option>
          <el-option :value="0" label="Disabled"></el-option>
        </el-select>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogStatus = false">{{ $t("Cancel") }}</el-button>
      <el-button size="small" type="primary" :disabled="!validData || loading" @click="save">
        <i v-if="loading" class="el-icon-loading"></i>
        <span v-else>{{ $t("Save") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { Input, Button, Tooltip, Dialog, Select } from "element-ui";
  import api from "src/api";
  export default {
    name: "ModalIntegration",
    props: ["title", "openModal", "optionsServices", "dataIntegration", "namesUsed"],

    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
    },

    data() {
      return {
        dialogStatus: false,

        integration: null,
        loading: false,
      };
    },

    watch: {
      openModal() {
        if (this.dataIntegration) {
          this.integration = { ...this.dataIntegration };
        } else {
          this.integration = {
            name: "",
            services: [],
          };
        }
        this.dialogStatus = true;
      },
    },

    methods: {
      clearData() {
        this.integration = null;
        this.$emit("closeModal");
      },

      save() {
        if (!this.validData) return;

        console.log(this.integration);
        this.loading = true;
        if (this.dataIntegration) {
          this.editApiTemplate(this.integration);
        } else {
          this.createApiTemplate(this.integration);
        }
      },

      async createApiTemplate(data) {
        try {
          let res = await api.createApiTemplate(data);
          console.log(res);
          this.$emit("save", res.data);
          this.dialogStatus = false;
          this.loading = false;
          // this.getApiTemplate();
        } catch (error) {
          console.log("error catch createApiTemplate", error);
        }
      },

      async editApiTemplate(data) {
        try {
          delete data.secret;
          let res = await api.editApiTemplate(data);
          if (res.status) {
            this.$emit("save", data);
            this.dialogStatus = false;
            this.loading = false;
          }
        } catch (error) {
          console.log("error catch editApiTemplate", error);
        }
      },
    },

    computed: {
      validData() {
        if (!this.integration) return;
        if (this.integration.services) {
          if (!this.integration.name || this.integration.services.length === 0 || this.nameInUse) return false;
        } else {
          if (!this.integration.name) return false;
        }

        return true;
      },

      nameInUse() {
        if (this.integration.name) {
          return this.namesUsed.indexOf(this.integration.name.toLowerCase()) !== -1;
        }

        return false;
      },
    },
  };
</script>
<style scoped>
  .dialog-integration .label {
    font-weight: bold;
    font-size: 12px;
  }

  .dialog-integration .aclaration {
    font-size: 10px;
    color: #737372;
  }

  .dialog-integration .aclaration.error {
    color: red;
  }
</style>

<style>
  .dialog-integration .el-dialog__body {
    padding: 0 20px;
    word-break: break-word;
  }
  
  .dialog-integration .el-input.error .el-input__inner {
    color: red;
    border-color: red;
  }
</style>
