<template>
  <div>
    <title-box
      componentName="MessageWithOptions"
      :id="numberNodeID"
      :name="dataNode.name"
      @setNewName="setNewName($event)"
    />

    <div class="p-2">
      <div class="first-message" v-if="firstMessage">
        <p
          v-html="textMarked(firstMessage.text)"
          class="text-response mb-0"
        ></p>
      </div>
      <el-button
        round
        size="mini"
        class="my-1 w-100"
        v-else
        @click="activeOptions"
      >
        {{ $t("Create message") }}
      </el-button>
    </div>

    <div class="options">
      <ul>
        <li
          v-for="option in dataNode.options"
          :key="option.text"
          class="d-flex align-items-center justify-content-between pr-2"
          :class="option.type"
        >
          {{ option.text }}

          <el-tooltip
            class="item"
            effect="dark"
            :content="option.url"
            placement="top"
            v-if="option.type === 'url'"
          >
            <i class="el-icon-link"></i>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="option.phone"
            placement="top"
            v-if="option.type === 'call'"
          >
            <i class="el-icon-phone"></i>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="option.message"
            placement="top"
            v-if="option.type === 'fallback' && !viewFallbackNode"
          >
            <i class="el-icon-refresh"></i>
          </el-tooltip>
        </li>
      </ul>
    </div>

    <template v-for="(opt, key) in optionsWithoutCallToActions">
      <create-element-popover
        :key="key"
        :output="key + 1"
        :optionsLength="dataNode.options.length"
        :numberNodeID="numberNodeID"
        v-show="key !== (optionsWithoutCallToActions.length - 1) || fallbackText === ''"
        v-if="
          dataNode.connections.findIndex((c) => c.idOption === opt.id) === -1
        "
      />
    </template>
  </div>
</template>

<script>
  import { Input, Button } from "element-ui";
  import ChatbotComponentsMixin from "src/app-components/chatbot/ChatbotComponentsMixin.js";

  export default {
    name: "MessageWithOptions",
    mixins: [ChatbotComponentsMixin],
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
    },

    data() {
      return {
        dataNode: {
          messages: [
            {
              type: "text",
              text: "",
            },
          ],
          options: [
            {
              id: null,
              text: "Fallback",
              type: "fallback",
              message: "",
            },
          ],

          global_scope: [],

          typeOfOptions: "interactive",
        },
      };
    },

    watch: {
      outputFallback(n, o) {
        if(!this.viewFallbackNode) {
          this.showNodeFallback(o)
          this.hideNodeFallback(n);
        }
      }
    },

    computed: {
      optionsWithoutCallToActions() {
        if (this.dataNode) {
          if (this.dataNode.options) {
            return this.dataNode.options.filter(
              (opt) => opt.type === "fallback" || opt.type === "reply"
            );
          }
        }

        return [];
      },

      fallbackText() {
        if(this.dataNode) {
          if(this.dataNode.options) {
            if(this.dataNode.options.length) {
              return this.dataNode.options[this.dataNode.options.length - 1].message;
            }
          }
        }
        return "";
      }
    },
  };
</script>

<style>
  .wrapperCanvas .drawflow .drawflow-node.MessageWithOptions.selected {
    -webkit-box-shadow: 0 2px 20px 2px #409eff;
    box-shadow: 0 2px 20px 2px #409eff;
  }

  .wrapperCanvas.dark .drawflow .drawflow-node.MessageWithOptions.selected {
    -webkit-box-shadow: 0 2px 20px 2px #6200ee;
    box-shadow: 0 2px 20px 2px #6200ee;
  }
</style>
