<template>
    <div class="row pull-left">
        <div class="col-md-12 text-left">
            <h5 style="font-size: 1rem; padding-top: 15px; padding-bottom: 15px">{{ $t("Menu") }}</h5>
            <div class="d-inline-flex">
                <p-button type="success" @click="onAdd()">
                    <i class="fa fa-plus"></i> {{ $t('Add Entry') }}
                </p-button>
            </div>
        </div>
        <div class="col-md-12">
            <MenuEntriesTable
                v-if="form.menuEntries.length > 0"
                :value="form.menuEntries"
                @deleted="onDeleted"
                @edit="onEdit"
                @reordered="onReordered"
            />
        </div>
        <MenuEntryConfiguration
            :initial-data="menuEntryInitialData"
            :show="showModal"
            @modal-save="onModalSave"
            @modal-cancel="closeModal()" />
    </div>

</template>

<script>
import MenuEntryConfiguration from 'src/app-components/modal-components/MenuEntryConfiguration'
import MenuEntriesTable from 'src/app-components/table-components/MenuEntries'
import { updatableForm } from 'src/app-components/FormMixin'

export default {
    components: {
        MenuEntryConfiguration,
        MenuEntriesTable
    },
    mixins: [
        updatableForm
    ],
    data () {
        return {
            showModal: false,
            menuEntryInitialData: null,
            editingIndex: null,
            form: {
                menuEntries: this.entity.menuEntries,
            }
        }
    },
    methods: {
        openModal () {
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
        },
        onModalSave (menuEntry) {
            if (this.menuEntryInitialData == null) {
                this.form.menuEntries.push(menuEntry)
            } else {
                this.$set(this.form.menuEntries, this.editingIndex, menuEntry)
            }
            this.closeModal();
        },
        onAdd () {
            this.menuEntryInitialData = null
            this.openModal();
        },
        onDeleted(index) {
            this.form.menuEntries.splice(index, 1)
        },
        onEdit(index) {
            this.menuEntryInitialData = this.form.menuEntries[index]
            this.editingIndex = index
            this.openModal()
        },
        onReordered(menuEntries) {
            this.form.menuEntries = menuEntries;
        }
    },
};
</script>

<style scoped>
</style>