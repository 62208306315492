<template>
  <apex-chart type="line" ref="chartstatus" width="100%" height="250px" :options="dataChartStatus.options" :series="dataChartStatus.series"></apex-chart>
  <!-- <apex-chart type="line" ref="chartstatus" width="100%" height="250px" :options="options" :series="series"></apex-chart> -->
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  export default {
    name: "ChartStatus",
    props: {
      series: {
        type: Array,
        default: [],
      },
      options: {
        type: Object,
        default: {},
      },
    },
    components: {
      "apex-chart": VueApexCharts,
    },

    data() {
      return {
        dataChartStatus: {
          options: {
            chart: {
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            xaxis: {
              type: "datetime",
              tooltip: {
                enabled: false,
              }
            },
          },
          series: [
            {
              name: "Sent",
              data: [
                {
                  x: new Date().getTime() - (86400000 * 6),
                  y: 15,
                },
                {
                  x: new Date().getTime() - (86400000 * 5),
                  y: 45,
                },
                {
                  x: new Date().getTime() - (86400000 * 4),
                  y: 52,
                },
                {
                  x: new Date().getTime() - (86400000 * 3),
                  y: 70,
                },
                {
                  x: new Date().getTime() - (86400000 * 2),
                  y: 86,
                },
                {
                  x: new Date().getTime() - (86400000 * 1),
                  y: 95,
                },
                {
                  x: new Date().getTime(),
                  y: 100,
                },
              ],
            },
            {
              name: "Received",
              data: [
                {
                  x: new Date().getTime() - ((86400000 * 6) + 14400000),
                  y: 10,
                },
                {
                  x: new Date().getTime() - ((86400000 * 5) + 14400000),
                  y: 38,
                },
                {
                  x: new Date().getTime() - ((86400000 * 4) + 14400000),
                  y: 45,
                },
                {
                  x: new Date().getTime() - ((86400000 * 3) + 14400000),
                  y: 60,
                },
                {
                  x: new Date().getTime() - ((86400000 * 2) + 14400000),
                  y: 75,
                },
                {
                  x: new Date().getTime() - ((86400000 * 1) + 14400000),
                  y: 81,
                },
                {
                  x: new Date().getTime() + (14400000),
                  y: 85,
                },
              ],
            },
            {
              name: "Error",
              data: [
                {
                  x: new Date().getTime() - ((86400000 * 6) + 14400000),
                  y: 5,
                },
                {
                  x: new Date().getTime() - ((86400000 * 5) + 14400000),
                  y: 7,
                },
                {
                  x: new Date().getTime() - ((86400000 * 4) + 14400000),
                  y: 7,
                },
                {
                  x: new Date().getTime() - ((86400000 * 3) + 14400000),
                  y: 10,
                },
                {
                  x: new Date().getTime() - ((86400000 * 2) + 14400000),
                  y: 11,
                },
                {
                  x: new Date().getTime() - ((86400000 * 1) + 14400000),
                  y: 14,
                },
                {
                  x: new Date().getTime() + (14400000),
                  y: 15,
                },
              ],
            },
          ],
        },
      };
    },

    methods: {
      /* updateChart() {
        this.options.xaxis.categories.push(this.x);
        this.series[0].data.push(Math.round(Math.random() * (100 - 20) + 20));
        this.$refs.refchart.updateOptions(
          {
            xaxis: {
              categories: [...this.options.xaxis.categories],
            },
          },
          true,
          false
        );
        // this.$refs.refchart.updateSeries(this.series[0]);

        this.x += 1;
      }, */
    },
  };
</script>
