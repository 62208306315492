<template>
  <div>
    <form-bookmark title="Create a new template"
      @submit="handleSubmit"></form-bookmark>
  </div>
</template>

<script>
import api from "src/api"
import Mixin from "./mixin.js"; 
import FormBookmark from 'src/app-components/FormBookmark';
import { mapMutations } from "vuex";

export default {
  mixins: [
    Mixin
  ],

  components: {
    FormBookmark
  },

  mounted () {
    this.setTopNavbarTitle(this.$t("Create a new template"));
  },

  methods: {
    handleSubmit(data) {
      api.createBookmark(data).then(res => {
        this.$router.push("/bookmarks/index");
      });
    },

    ...mapMutations(["setTopNavbarTitle"]),
  }
}
</script>

<style>

</style>