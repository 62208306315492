<template>
  <div>
    <div class="d-flex">
      <div>
        <p>
          <strong>{{ $t("Configure Cap") }}</strong>
        </p>
        <el-switch v-model="active"> </el-switch>
      </div>

      <div class="form-group has-label pl-5" v-if="active && false">
        <p class="label-input">{{ $t("Cap Type") }}</p>
        <el-select size="large" v-model="type">
          <el-option
            v-for="type in typeCap"
            :value="type"
            :label="$t(type)"
            :key="type"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="pt-5" v-if="active">
      <div class="d-flex pb-3" v-for="(bookmark, key) in bookmarks">
        <div style="width: 200px;" class="mt-1">
          <p-checkbox v-model="checks[bookmark.id]" @input="activeCheckbox(key)">{{ bookmark.name }}</p-checkbox>
        </div>
        <el-input
          style="width: 200px;"
          size="medium"
          :placeholder="$t('CAP GOAL')"
          v-model="bookmark._joinData.cap"
          clearable
          type="number"
          :disabled="!checks[bookmark.id]"
          @input="updateCap(bookmark.id, bookmark._joinData.cap)"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, Switch, Input } from "element-ui";
import { Checkbox } from "src/components/UIComponents";
export default {
  props: ["parentCampaign"],

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Input.name]: Input,

    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      active: false,
      bookmarks: [],
      checks: {},
      timeoutUpd: null,
      type: "Acceptance",
    };
  },

  watch: {
    parentCampaign() {
      this.initializeCap();
    },
    active() {
      if(this.active) {
        this.initializeCap();
      }else {
        this.clearCaps();
      }
    },
  },

  mounted() {
    this.onCapTypeUpdated();
  },

  methods: {
    initializeCap() {
      if (this.parentCampaign.bookmarks) {
        if (this.parentCampaign.bookmarks.length > 0) {
          this.bookmarks = [...this.parentCampaign.bookmarks];
          this.parentCampaign.bookmarks.forEach((bookmark) => {
            if(bookmark._joinData.cap) {
              this.checks[bookmark.id] = true;
              this.active = true;
            }
          });
        }
      }
    },

    updateCap(id, value) {
      clearTimeout(this.timeoutUpd);
      this.timeoutUpd = setTimeout(this.modelUpdated, 400);
    },

    modelUpdated() {
      this.$emit("modelUpdated", this.bookmarks);
    },

    onCapTypeUpdated() {
      this.$emit("onCapTypeUpdated", this.type);
    },

    activeCheckbox(index) {
      if(this.bookmarks[index]) {
        if(!this.checks[this.bookmarks[index].id]) {
          if(this.bookmarks[index]._joinData) {
            this.bookmarks[index]._joinData.cap = '';
            this.modelUpdated();
          }
        }
      }
    },

    clearCaps() {
      this.bookmarks.forEach((bookmark) => {
        if(bookmark._joinData) {
          bookmark._joinData.cap = '';
        }
      });
      this.modelUpdated();
    },
  },

  computed: {
    typeCap() {
      return ["Acceptance"];
    },
  },
};
</script>

<style scoped>
.label-input {
  font-size: 0.8571em;
  margin-bottom: 5px;
  color: #9a9a9a;
}
</style>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
