<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group has-label mb-0">
          <label>Test scenario</label>
          <el-select
            @input="handleInput"
            size="large"
            :value="local"
            :disabled="! isEditable"
            name="test-scenario"
            v-validate="'required'"
          >
            <el-option
              v-for="option in channels"
              :value="option.id"
              :label="option.name"
              :key="option.name"
            ></el-option>
          </el-select>
        </div>

        <div
          v-show="errors.has('test-scenario')"
          class="text-danger invalid-feedback"
          style="display: block;"
        >{{ errors.first('test-scenario') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  props: {
    value: Object,
    isEditable: {
      type: Boolean,
      default: true
    },
    testType: String,
  },

  async created() {
    this.$validator = this.parentValidator;
    //this.getChannels();
  },

  computed: {
    local() {
      return this.value ? this.value.id : null;
    }
  },

  data() {
    return {
      channels: []
    };
  },

  watch: {
    testType () {
      this.$emit("input", null);
      this.getChannels();
    }
  },

  methods: {
    handleInput (val) {
      this.$emit('input', this.channels.find(e => e.id === val))
    },
    async getChannels() {
      if (this.testType == "TXT") {
        this.channels = [
          {
            id: 1,
            name: "1 MT",
            msg: { text: "Start xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx End" },
          },
          {
            id: 2,
            name: "2 MT",
            msg: { text: "Start xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx End" },
          },
        ];
        return;
      }
      if (this.testType == "BIN") {
        this.channels = [
          {
            id: 3,
            name: "Sat Push 1 MT",
            msg: {
              "type": "2SCREEN_SENDSMS",
              "msg1": "Test",
              "msg2": "Success",
              "keyword": "OK"
            }
          },
          {
            id: 4,
            name: "Sat Push 2 MT",
            msg: {
              "type": "2SCREEN_SENDSMS",
              "msg1": "Test Test Test Test Test Test",
              "msg2": "Success Success Success Success",
              "keyword": "OK"
            }
          },
          {
            id: 5,
            name: "Sat Push 3 MT",
            msg: {
              "type": "2SCREEN_SENDSMS",
              "msg1": "Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test",
              "msg2": "Success Success Success Success Success Success Success Success Success Success Success Success Success Success",
              "keyword": "OK"
            }
          },
          {
            id: 6,
            name: "Sat Push 4 MT",
            msg: {
              "type": "2SCREEN_SENDSMS",
              "msg1": "Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test",
              "msg2": "Success Success Success Success Success Success Success Success Success Success Success Success Success Success Success Success Success Success Success",
              "keyword": "OK"
            }
          },
          {
            id: 7,
            name: "Ping",
            msg: {
              "type": "PING",
            }
          },
        ];
        return;
      }
      throw new Error("Invalid test type " + this.testType);
    }
  }
};
</script>

<style>
</style>
