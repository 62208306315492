<template>
  <div class="mb-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group has-label mb-0">
          <label>Test type</label>
          <el-select
            @input="$emit('input', $event)"
            size="large"
            :value="local"
            :disabled="! isEditable"
            v-validate="'required'"
            name="test-type"
          >
            <el-option
              v-for="option in channels"
              :value="option.id"
              :label="option.name"
              :key="option.name"
            ></el-option>
          </el-select>
        </div>

        <div
          v-show="errors.has('test-type')"
          class="text-danger invalid-feedback"
          style="display: block;"
        >{{ errors.first('test-type') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  props: {
    value: String,
    isEditable: {
      type: Boolean,
      default: true
    }
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getChannels();
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },

  data() {
    return {
      channels: []
    };
  },

  methods: {
    async getChannels() {
      this.channels = [
        {id: "TXT", name: "Text"},
        {id: "BIN", name: "Binary"},
      ];
    }
  }
};
</script>

<style>
</style>
