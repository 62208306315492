<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <a class="simple-text logo-mini" href="http://www.dynamomovil.com">
        <div class="logo-img">
          <img :src="logo" alt />
        </div>
      </a>
      <a class="simple-text logo-normal" href="http://www.dynamomovil.com">{{
        title
      }}</a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot> </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
			   v-for="(link, index) in menu"
				:key="index"
				:link="link"
			  >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
              <sidebar-item
                v-for="(subSubLink, index) in subLink.children"
                :key="subSubLink.name + index"
                :link="subSubLink"
              >
              </sidebar-item>
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from "perfect-scrollbar";
import MENU from './menu';

export default {
    props: {
      title: {
        type: String,
        default: 'Vue PDP Pro',
        description: 'Sidebar title'
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar active text color (primary|info|success|warning|danger)'
      },
      logo: {
        type: String,
        default: 'static/img/vue-logo.png',
        description: 'Sidebar Logo'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    computed:{
      menu(){

			const menuFilter = (_menu) => {
				return _menu.filter(link => {
					if(typeof link.visible === 'undefined'){
						return true;
					}

					if(typeof link.visible === 'function'){
						return link.visible(this.$can);
					}

					return !!link.visible;
				}).map(link => ({
					...link,
					children: menuFilter(link.children || [])
				}));
			};

			return menuFilter(MENU);
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    methods: {
      async initScrollBarAsync () {
        new PerfectScrollbar(this.$refs.sidebarScrollArea);
      },
		isLinkVisible(link){
			if(typeof link.visible === 'undefined'){
				return true;
			}

			if(typeof link.visible === 'function'){
				return link.visible(this.$can);
			}

			return !!link.visible;
		}
    },
    mounted () {
      this.initScrollBarAsync()
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
}
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.ps__rail-x,
.ps__thumb-x {
  display: none!important;
}
</style>
