import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);
const lang = 'en';

export const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: lang,
  messages: { lang },
  silentTranslationWarn: true
});