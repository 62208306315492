<template>
  <div>
    <input
      type="hidden"
      name="selected_bookmark_size"
      v-model="selectedBookmarkSize"
      v-validate="'min_value:2'"
    />

    <div class="row justify-content-center">
      <h5 class="info-text">
        {{ $t("Select the scenario type and templates for your campaign") }}
      </h5>
      <div class="col-md-12 text-center">
        <el-select
          filterable
          name="account_scenario_type_id"
          v-validate="'required'"
          :placeholder="$t('Select a scenario')"
          :error="getError('account_scenario_type_id')"
          v-model="model.account_scenario_type_id"
        >
          <el-option
            v-for="option in availableScenarioTypes"
            :value="option.id"
            :label="$t(option.name)"
            :key="option.id"
          ></el-option>
        </el-select>

        <div
          v-show="errors.has('account_scenario_type_id')"
          class="text-danger invalid-feedback"
          style="display: block;"
        >
          {{ errors.first("account_scenario_type_id") }}
        </div>
      </div>
      <Spinner :display="loadingProgress" class="spinner" />
    </div>

    <div class="row" v-if="products" style="margin-top: 20px;">
      <div class="col-md-3" v-for="(bookmark, index) in products" :key="index">
        <card-bookmark
          :border="true"
          :bookmark="bookmark"
          :selected="bookmarksSelected.some((b) => b.id === bookmark.id)"
          :selectedCreativeHash="selectedCreativeHash(bookmark)"
          @creativity-changed="creativityChanged"
          :ref="'card-bookmark-' + bookmark.id"
        >
          <template slot="action">
            <div class="row">
              <div class="col-12 text-center">
                <p-check
                  class="p-icon p-round p-tada"
                  color="success-o"
                  @change="onCreativitySelected(bookmark)"
                  :checked="
                    bookmarksSelected.some((b) => b.id === bookmark.id)
                      ? 'checked'
                      : false
                  "
                  style="font-size: 30px;"
                >
                  <i class="icon icon-check-single-2"></i>
                </p-check>
              </div>
            </div>
          </template>
        </card-bookmark>
      </div>
    </div>

    <div
      class="row"
      v-show="
        !errors.has('account_scenario_type_id') &&
          errors.has('selected_bookmark_size')
      "
    >
      <div class="col-md-12">
        <div class="text-danger invalid-feedback" style="display: block;">
          * {{$t("Please, you must select two bookmarks at least")}}.
        </div>
      </div>
    </div>

    <Slide @closeMenu="filterOpen = false" :isOpen="filterOpen">
      <Filters @filterUpdated="filterUpdated" :maxPrice="maxPrice" />
    </Slide>

    <FabFilter
      :position="1"
      @click="filterOpen = true"
      v-click-outside="clickOutside"
    />
    <fab />
  </div>
</template>
<script>
import Spinner from "src/app-components/Spinner";
import FabFilter from "src/app-components/FabFilter";
import Fab from "src/app-components/FabAddBookmark";
import Filters from "src/app-components/wizard-performance-campaign/filters";
import Slide from "src/custom-components/UIComponents/FilterSideBar";
import api from "src/api";
import { Checkbox, Select, Option } from "element-ui";
import CardBookmark from "src/app-components/CardBookmark";
import SelectedCreativitiesMixin from "src/app-components/SelectedCreativitiesMixin";
import FilterMixin from "src/app-components/FilterMixin";
import { clone, pick } from "lodash";
import PrettyCheck from "pretty-checkbox-vue/check";
import { defaultMixin } from "src/app-components/FormMixin";
import { EventBus } from "src/eventBus";

export default {
  $_veeValidate: {
    validator: "new",
  },

  mixins: [
    defaultMixin,
    SelectedCreativitiesMixin("availableBookmarks"),
    FilterMixin(),
  ],

  props: {
    scenarioTypeId: Number,
    withBookmarks: {
      type: Array,
    },
  },

  components: {
    Spinner,
    fab: Fab,
    CardBookmark,
    [Select.name]: Select,
    [Option.name]: Option,
    Filters,
    Slide,
    FabFilter,
    ["p-check"]: PrettyCheck,
  },

  async mounted() {
    this.getScenarioTypes();
  },

  computed: {
    maxPrice() {
      return typeof this.metadata.max_price === "undefined"
        ? 100
        : this.metadata.max_price;
    },

    products() {
      return this.availableBookmarks;
    },

    selectedBookmarkSize() {
      return this.model.bookmarks.length;
    },

    accountScenarioTypeId() {
      return this.model.account_scenario_type_id;
    },

    bookmarksWithCreativities() {
      return this.model.bookmarks.map((bookmark) => {
        let res = {
          id: bookmark.id,
          name: bookmark.name,
          price: bookmark.price,
          _joinData: {
            bookmark_perf_text_id: this.getSelectedCreativityId(bookmark.id),
          },
        };
        
        if(bookmark._joinData) {
          if (bookmark._joinData.id) {
            res._joinData = {...bookmark._joinData};
          }
        }
        return res;
      });
    },

    bookmarksSelected() {
      return this.model.bookmarks;
    },

    queryFilter() {
      return {
        account_scenario_type_id: this.accountScenarioTypeId,
        ...this.filters,
      };
    },

    loadingProgress() {
      return this.loading;
    },
  },

  watch: {
    scenarioTypeId() {
      this.model.account_scenario_type_id = this.scenarioTypeId;
    },

    withBookmarks() {
      this.$set(this.model, "bookmarks", clone(this.withBookmarks));

      /* setTimeout(() => {
        this.model.bookmarks.forEach((bookmark) => {
          const ref = this.$refs["card-bookmark-" + bookmark.id].shift();
          const perfTextId = bookmark._joinData.bookmark_perf_text_id;
          ref.jumpToId(perfTextId);
        });
      }, 1000); */
    },

    active() {
      this.filterOpen = false;
    },

    queryFilter() {
      this.getBookmarks();
    },

    selectedBookmarkSize() {
      EventBus.$emit("templatesChanged", this.selectedBookmarkSize);
    }
  },

  data() {
    return {
      metadata: {},
      filters: {},
      bookmarksEmptyWarning: false,
      availableBookmarks: [],
      availableScenarioTypes: [],
      loading: false,

      model: {
        account_scenario_type_id: null,
        bookmarks: [],
      },
    };
  },

  methods: {
    selectedCreativeHash(bookmark) {
      let res = undefined;
      const index = this.bookmarksSelected.findIndex((b) => b.id === bookmark.id);
      if(index !== -1) {
        if(this.bookmarksSelected[index]._joinData) {
          bookmark.creatives.forEach(creative => {
            if(creative.id === this.bookmarksSelected[index]._joinData.bookmark_perf_text_id) {
              res = creative.hash;
            }
          });
        }
      }
      return res;
    },

    clickOutside(e) {
      if (
        this.filterOpen &&
        e.path.filter((element) => element._prevClass == "bm-menu").length == 0
      ) {
        this.filterOpen = false;
      }
    },

    filterUpdated(filters) {
      this.filters = filters;
    },

    isBookmarksEmpty() {
      return this.model.bookmarks.length < 2;
    },

    isBookmarkSelected(id) {
      const f = this.model.bookmarks.filter((bookmark) => bookmark.id === id);
      return f.length > 0;
    },

    async getScenarioTypes() {
      const scenariosTypes = await api.getScenarioTypes({
        filterby: "performance",
      });
      this.availableScenarioTypes = scenariosTypes.data
        .filter((obj) => obj.scenario.channel_id === 1)
        .map((obj) => ({
          id: obj.id,
          name: obj.alias ? obj.alias : obj.scenario.name,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },

    async getBookmarks() {
      if (this.accountScenarioTypeId === null) return;

      const activeFilters = this.getActiveFilters();

      if(activeFilters.price) {
        if (activeFilters.price.length === 2) {
          if (activeFilters.price[0] === 0 && activeFilters.price[1] === 0) {
            delete activeFilters.price;
          } else {
            activeFilters.price = activeFilters.price.join(",");
          }
        } else {
          delete activeFilters.price;
        }
      }

      const bookmarks = await api.getTemplates({
        account_scenario_type_id: this.accountScenarioTypeId,
        // ...activeFilters,
        status: 'APPROVED',
        archived: false,
        _page: 1,
        _limit: 1000000,
      });
      this.availableBookmarks = bookmarks.data;
      this.metadata = bookmarks.metadata;
    },

    notifyUnsupportedProduct(isValid) {
      if (isValid) {
        // Detecta si dentro de los seleccionados, hay al menos un prodcto sin precio o id igual a 4 (N/A)
        const hasNAProduct = this.products
          .filter((product) =>
            this.bookmarksWithCreativities.some(
              (bookmark) => bookmark.id === product.id
            )
          )
          .some(
            (product) =>
              product.price === null || product.commercial_model.id === 4
          );
        EventBus.$emit("remove-strategies", {
          filter: hasNAProduct ? [4] : [],
        });
      }
    },

    updateBookmarks(bookmark, value) {
      if (value === false) {
        this.model.bookmarks = this.model.bookmarks.filter(
          (sel) => sel.id !== bookmark.id
        );
      } else {
        this.model.bookmarks.push({ id: bookmark.id });
      }
    },

    async validate() {
      const result = await this.$validator.validateAll();
      this.$emit("on-validated", result, {
        ...this.model,
        bookmarks: this.bookmarksWithCreativities,
      });
      this.notifyUnsupportedProduct(result);
      return result;
    },

    onCreativitySelected(bookmark) {
      if (this.model.bookmarks.some((b) => b.id === bookmark.id)) {
        console.log('true')
        this.model.bookmarks = this.model.bookmarks.filter(
          (sel) => sel.id !== bookmark.id
        );
      } else {
        this.model.bookmarks.push({ id: bookmark.id, name: bookmark.name, price: bookmark.price });
      }
    },
  },
};
</script>

<style scoped>
.fab-main-container {
  z-index: 1000 !important;
}

.card-wizard .tab-content {
  min-height: 0;
}

.spinner {
  margin-top: 65px;
  margin-bottom: 35px;
}

:deep(.pretty) {
  margin-right: unset;
}
:deep(.card-stats .card-footer) {
  padding: 0 15px 5px;
}
:deep(.pretty .state label) {
  text-indent: -2px;
}
:deep(.pretty.p-icon .state .icon) {
  top: calc((0% - (100% - 1em)) - 2%);
}
</style>