<template>
  <div>
    <div class="row">
      <div class="col-12 col-md">
        <p>{{ $t("Upload Blacklist (list of users to exclude on any campaign type), Whitelist (list of eligible users for your campaigns), or any other kind of file") }}</p>
      </div>
      <div class="col-12 col-md-auto">
        <p-button type="info" @click="onAddFile">{{ $t('Add file') }}</p-button>
      </div>
    </div>
    <AccountFilesTable :value="form.accountUserFiles" @deleted="handleFileDeleted" />
    <FileUploadModal :show="showAddFileModal"
                    @modal-cancel="onCancelAddFile"
                    @modal-save="onConfirmAddFile($event)"
    />
  </div>
</template>

<script>
  import AccountFilesTable from 'src/app-components/table-components/AccountFiles'
  import FileUploadModal from 'src/app-components/modal-components/FileUpload'
  import { updatableForm } from 'src/app-components/FormMixin'

  export default {
    components: {
      AccountFilesTable,
      FileUploadModal
    },
    mixins: [
      updatableForm
    ],
    methods: {
      onAddFile () {
        this.showAddFileModal = true;
      },
      onConfirmAddFile (uploaded) {
        this.showAddFileModal = false;
        this.$emit("refresh");
        // this.form.accountUserFiles.push(uploaded)
      },
      onCancelAddFile () {
        this.showAddFileModal = false;
      },
      handleFileDeleted (id) {
        this.form.accountUserFiles = this.form.accountUserFiles.filter(file => file.id != id)
      }
    },
    data () {
      return {
        showAddFileModal: false,
        form: {
          accountUserFiles: []
        }
      }
    }
  };
</script>
<style>
</style>