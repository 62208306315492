<script>
import ScenarioRequiredElements from "src/app-components/form-elements/generators/ScenarioRequiredElements";
import api from "src/api";

export default {
  name: "scenario-hidden-elements",
  extends: ScenarioRequiredElements,

  props: {
    width: {
      type: String,
      default: "col-md-3",
    },

    module:{
      type: String,
      default: "campaign"
    },

    variables: {
      type: Array,
    },
  },

  computed: {
    schemas() {
      return this.schema;
    },
    local() {
      return this.variables ? Object.values(this.variables) : [];
    },
  },

  methods: {
    async getSchema() {
      // TODO: tratar de que todo el objeto accountScenario llegue via prop,
      // porque ya se carga desde otro componente padre.
      const scenarioType = await api.getScenarioType(this.scenario, {
        variable_group: "hidden-group",
        remove_invisible: 0,
        module: this.module
      });

      const variables = (scenarioType.data.variables || scenarioType.data.scenario.variables || [])
        .filter(v => v.variable_group_id == 3)
        .map((v) => {
          if("final_variables" in v && v.final_variables[0]) {
            return {
              ...v,
              value: v.final_variables[0].variable_value,
              id: v.final_variables[0].variable_id,
              variable_id: v.final_variables[0].variable_id,
            };
          }
          
          return v;
        });

      this.schema = variables;
      this.setVariables(variables);
    },

    setVariables(variables) {
      variables.forEach((v) => {
        const field = {
          name: v.name,
          tooltip: v.tooltip,
          id: v.id,
        }

        this.updateForm(field, v.value, false);
      });
    },

    getValue(index, variableId) {
      let variable = null;
      const finder = v => (v.id == variableId || v.variable_id == variableId);

      // buscamos primero en la prop "variables"
      if(this.variables && Array.isArray(this.variables)){
        variable = this.variables.find(finder);
      }

      // si no existe, buscamos en el schema (variables por defecto para el escenario)
      if(!variable){
        variable = this.schema.find(finder);
      } 

      return variable ? variable.value : '';
      /*let value = this.schema.find(variable => {
        if(variable.variable) {
          return variable.variable_id === variableId;
        }
      });

      return value ? value.value : '';*/
    },

    updateForm(field, value, updValue = true) {
      const input = {
        value,
        updValue: updValue === false ? false : true,
        variable: field,
        variable_id: field.id
      }

      this.$emit("input", input);
    },
  },
};
</script>
