<template>
    <card>
        <div class="card-header">
            <h4 class="card-title">Sender</h4>
        </div>
        <div class="card-body">
            <div class="form-row">
                <div class="col-6">
                    <Msisdn :required="true" v-model="msisdn" />
                </div>
                <div class="col-6">
                    <Msisdn :required="true" v-model="shortcode" name="shortcode" label="Shortcode" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <TestType v-model="testType" />
                </div>
                <div class="col-12">
                    <TestScenario :test-type="testType" v-model="testScenario" />
                </div>
                <div class="col-12">
                    <BinaryFormat v-if="isBinary" v-model="formats" />
                </div>
            </div>
            <fg-input label="Options">
                <div class="row">
                    <div class="col-auto">
                        <p-checkbox v-model="dlr">Request DLR</p-checkbox>
                    </div>
                    <template v-if="isBinary">
                        <div class="col-auto">
                            <p-checkbox v-model="por">Request PoR</p-checkbox>
                        </div>
                    </template>
                </div>
            </fg-input>
            <p-button :disabled="working" type="primary" @click="send">Send</p-button>
        </div>
    </card>
</template>

<script>
  import {Checkbox} from 'src/components/UIComponents'
  import TestType from 'src/views/smsc-tester/sender/TestType'
  import TestScenario from 'src/views/smsc-tester/sender/TestScenario'
  import BinaryFormat from 'src/views/smsc-tester/sender/BinaryFormat'
  import Msisdn from 'src/app-components/form-elements/Msisdn'
  import {Card} from 'src/components/UIComponents'
  import api from 'src/api'

  export default {
    components: {
      [Checkbox.name]: Checkbox,
      TestType,
      TestScenario,
      Msisdn,
      BinaryFormat,
      Card,
    },
    mounted () {
    },
    data () {
      return {
        msisdn: "",
        testType: null,
        testScenario: null,
        dlr: false,
        formats: ["4", "1"],
        por: false,
        shortcode: "",
        working: false,
      }
    },
    computed: {
      isBinary(){
          return this.testType == 'BIN'
      }
    },
    provide() {
      return {
        parentValidator: this.$validator,
        getError: this.getError
      };
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async send () {
          const isValid = await this.$validator.validateAll();
          if (!isValid) return;

          try {
              const request = {
                  msisdn: this.msisdn,
                  shortcode: this.shortcode,
                  message: this.testScenario.msg,
                  dlr: this.dlr,
                  isBinary: this.isBinary,
                  por: this.por,
                  formats: this.formats,
              };
              this.working = true;
              await api.sendSms(request);
              this.$emit("sent", request);
              //console.log(JSON.stringify(request, null, 2));
              //await this.waitMs(2000);
          } catch (e) {
              // Por ahora ignoramos el error
          } finally {
              this.working = false;
          }
      },
      /*
      waitMs (ms) {
          return new Promise(function(resolve){
              setTimeout(resolve, ms);
          });
      }
      */
    },
  };
</script>
<style>
</style>