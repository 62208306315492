<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-2 col-md-3">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div :class="chartData[0] !== null ? 'col-10 col-md-9' : 'col-10 col-md-9'">
          <slot name="content">
            <div class="numbers">
              <p class="card-category" v-if="smallTitle" style="white-space: nowrap;">{{smallTitle}}</p>
              <p class="card-title" v-if="title">{{title | formatNumber}}</p>
              <p v-if="subtitleToday != null" style="font-size: 45%">{{ $t('Today') }}: {{subtitleToday | formatNumber}}</p>
              <p v-if="subtitleMonth != null" style="font-size: 45%">{{ $t('Month') }}: {{subtitleMonth | formatNumber}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr/>
      <slot name="footer"></slot>
    </div>

  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard'

  export default {
    name: 'stats-doughnut-card',
    extends: CircleChartCard,
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        default: null,
        description: 'Card title'
      },
      subtitleToday: {
        type: null,
        default: null,
        description: 'Card titles'
      },
      subtitleMonth: {
        type: null,
        default: null,
        description: 'Card titles'
      },
      smallTitle: {
        type: String,
        default: null,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      }
    },
    data() {
      return {
        colors: {
          danger: 'red',
          success: 'green',
          warning: 'yellow',
        }
      }
    }
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px
  }
</style>
