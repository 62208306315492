<template>
  <div class="card">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t('Conversation')">
        <template slot-scope="scope">
          <span>{{ scope.row.conversation_id }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Channel')">
        <template slot-scope="scope">
          <span>{{ scope.row.channel_id | setChannelName }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('User')">
        <template slot-scope="scope">
          <span>
            {{ scope.row.user.displayName ? scope.row.user.displayName : "" }}
            {{ scope.row.user.contactWaId ? `(${scope.row.user.contactWaId})` : "" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Date')">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.lastUpdate) }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('Actions')" width="100">
        <template slot-scope="scope">
          <p-button
            class="mr-1"
            :type="btn.type"
            size="sm"
            icon
            :key="btn.icon"
            v-for="btn in buttonsAction"
            @click="btn.event(scope.row.conversation_id)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t(btn.tooltip)"
              placement="top"
            >
              <i :class="btn.icon"></i>
            </el-tooltip>
          </p-button>
        </template>
      </el-table-column>
    </el-table>

    <floating-action-button 
      color="#339564" 
      icon="download" 
      :position="0" 
      @click="exportToExcel" 
      v-if="tableData.length"
    />
  </div>
</template>

<script>
  import api from "src/api";
  import moment from "moment";
  import { mapMutations } from "vuex";
  import { Table } from "element-ui";
  import * as Export2Excel from "src/app-components/Export2Excel";
  import FloatingActionButton from "src/app-components/FloatingActionButton";
  export default {
    name: "ConversationsIndex",

    components: {
      [Table.name]: Table,
      FloatingActionButton
    },

    data() {
      return {
        conversations: [],
      };
    },

    created() {
      if(!this.$can('Views', 'Chatbots')) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle("Conversations");
    },
    
    mounted() {
      this.getConversations();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      formatDate(date) {
        if (date.$date) {
          if (date.$date.$numberLong) {
            return moment(parseInt(date.$date.$numberLong)).format(
              "DD/MM/YY HH:mm"
            );
          }
        }
        return "";
      },

      viewConversation(id) {
        console.log(id);
        this.$router.push({ name: "ConversationsInfo", params: { id } });
      },

      async getConversations() {
        let res = await api.getConversations();
        if(res.status) {
          this.conversations = [...res.data];
        }
      },

      async exportToExcel() {
        const tHeader = [
          "Conversation",
          "Channel",
          "User",
          "Date",
        ];
        const data = this.tableData.map((e) => {
          return [
            e.conversation_id,
            e.channel_id,
            e.user.displayName,
            this.formatDate(e.lastUpdate)
          ];
        });
        
        let formats = [];
        Export2Excel.export_json_to_excel({
          header: tHeader,
          data,
          formats,
          filename: this.excelFileName,
          autoWidth: true,
          bookType: "xlsx",
        });
      },
    },

    computed: {
      tableData() {
        return this.conversations;
      },

      buttonsAction() {
        return [
          {
            type: "info",
            icon: "fa fa-info",
            tooltip: "View",
            event: this.viewConversation,
          },
        ];
      },

      excelFileName () {
          return `Dynamo_Chatbots_Conversations_${moment().format("YYYY-MM-DD")}`
      },
    },
  };
</script>
