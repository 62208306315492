<template>
    <div class="row animated fadeIn mt-2 pt-2 pb-3 bg-light">
        <div class="col-2">
            <label>{{$t('ID')}}</label>
            <el-input
                v-model="search.byID"
                size="mini"
                :placeholder="$t('Filter by ID')"
                addon-right-icon="fa fa-search"
                label="Search"
                clearable
            />
        </div>
        <div class="col">
            <label>{{$t('Applicant')}}</label>
            <el-input
                v-model="search.byUsername"
                size="mini"
                :placeholder="$t('Filter by Applicant')"
                addon-right-icon="fa fa-search"
                label="Search"
                clearable
            />
        </div>
        <div class="col-3">
            <label>{{$t('Query status')}}</label>
            <el-select
                class="w-100"
                filterable
                clearable
                size="mini"
                :placeholder="$t('Filter by status')"
                v-model="search.byStatus"
            >
                <el-option v-for="s in queryStatus" :value="s" :key="s" :label="$t(s)">
                    <span class="badge w-100" :class="s.toLowerCase()">{{s}}</span>
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
import { Input } from "element-ui";

export default {
    components: {
        [Input.name]: Input,
    },

    props: {
        tableData: {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            search: {
                byID: "",
                byUsername: "",
                byStatus: "",
            },
            queryStatus: ["Pending", "In-Progress", "Done", "Failed"],
        };
    },

    computed: {
        filteredRows() {
            return this.tableData.filter((row) => {
                    // console.log(row);
                    const id = row.id.toString();
                    const username = row.user.username.toLowerCase();
                    const status = row.status.toLowerCase();

                    return (
                        id.includes(this.search.byID.toString()) &&
                        username.includes(this.search.byUsername.toLowerCase()) &&
                        status.includes(this.search.byStatus != null ? this.search.byStatus.toLowerCase() : "" )
                    );
                }).reverse();
        }
    },

    watch: {
        filteredRows: function (val) {
            this.$emit('filteredrows', val);
        }
    },
};
</script>