<template>
    <div>
        <MediaUploader
            :autoUpload="false"
            :fileUrl="fileUrl"
            @pictureLoaded="pictureLoaded"
            @pictureRemove="pictureRemove"
            :isRequired="false"
            @submit="submit"
        ></MediaUploader>
    </div>
</template>

<script>
import { Input, Upload, Button } from "element-ui";
import { cloneDeep } from "lodash";

import MediaUploader from "src/app-components/rcs/MediaUploader.vue";

export default {
    components: {
        [Input.name]: Input,
        [Upload.name]: Upload,
        [Button.name]: Button,
        MediaUploader,
    },

    // props: {
    //     isEditable: {
    //         type: Boolean,
    //         default: true,
    //     },
    //     maxImgSize: {
    //         type: Number,
    //         default: 2,
    //     },
    //     maxVideoSize: {
    //         type: Number,
    //         default: 10,
    //     },
    // },

    data() {
        return {
            scope: `uploader`,
            loading: false,
            files: [],
            form: {
                contentMessage: {
                    contentInfo: {
                        fileUrl: "",
                        thumbnailUrl: "",
                        forceRefresh: false,
                    },
                    suggestions: [],
                },
            },
        };
    },

    computed: {
        hasMedia() {
            return this.form.contentMessage.contentInfo.fileUrl !== "";
        },
        fileUrl() {
            return this.form.contentMessage.contentInfo.fileUrl;
        },
    },

    methods: {
        submit() {
            this.$emit("input", cloneDeep(this.form));
        },

        pictureRemove() {
            // console.log(file);
            this.form.contentMessage.contentInfo.fileUrl = "";
        },

        pictureLoaded(url) {
            console.log("LOADED", url);
            this.form.contentMessage.contentInfo.fileUrl = url;
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(.preview) {
    video,
    img {
        max-height: 180px;
        max-width: 90%;
    }
}
</style>