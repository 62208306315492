<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <target-uploader :with-files="entityWithFiles"
                    :disabled="disabled"
                    :disable-remove="disableRemove"
                    :bookmark="bookmark"
                    :currentCreativities="currentCreativities"
                    @fileRemoved="onFileRemoved"
                    @fileProcessed="onFileProcessed" />
            </div>
        </div>
    </div>
</template>

<script>
import { updatableForm } from "src/app-components/FormMixin";
import TargetUploaderMixin from "src/app-components/TargetUploaderMixin"

import TargetUploader from "src/app-components/TargetUploader";

export default {
    inject: ["getError", "parentValidator"],

    mixins: [
        updatableForm,
        TargetUploaderMixin("form")
    ],

    components: {
        [TargetUploader.name]: TargetUploader
    },

    props: {
        entity: {
            type: Array,
            required: false
        },

        bookmark: {
            type: Object,
            required: false
        },

        currentCreativities: {
            type: Array,
            required: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        disableRemove: {
            type: Boolean,
            default: false
        }
    },

    created() {
        this.$validator = this.parentValidator
    },

    computed: {
        // REF REF REF
        entityWithFiles() {
            if(this.entity && this.entity.length > 0) {
                //console.log("ENTITY WITH FILES: " + JSON.stringify(this.entity.files, null, 2))
                this.updateTotalValidEntries(this.sumTotalValid(this.entity));
                return this.entity
            }

            return [];
        }
    },

    watch: {
        totalValidEntries: {
            handler() {
                this.updateTotalValidEntries(this.totalValidEntries);
            }
        }
    },

    data() {
        return {
            form: []
        }
    },

    methods: {
        updateTotalValidEntries(total) {
            this.$emit("updateTotalValidEntries", total);
        }
    }
}
</script>

<style>

</style>
