<template>
    <div>
        <!--
        <div class="row">
            <div class="col-md-1">
                <RetryEnabled v-model="retryEnabled" />
            </div>
        </div>
        -->

        <p-button type="success"
            style="position:absolute; right:0; top:0; z-index: 1000"
            class="mt-2 mr-3"
            @click="addNew">
            <i class="fa fa-plus"></i> {{ $t("Retry") }}
        </p-button>


        <div class="row" v-if="retryEnabled">
            <div class="col-md-12 align-self-center">
                <p class="text-muted mb-0 mt-3" v-if="retries.length == 0">
                    {{ $t("There are no retries configured yet. Please add one if you need.") }}
                </p>
            </div>
        </div>

        <div class="mt-5" v-if="retryEnabled">
            <div v-for="(retry, i) in retries" :key="retry.appId">
                <div v-if="i > 0" class="mt-3 mb-3"><i style="color: #A9A9A9" class="fa fa-chevron-down"></i></div>
                <RetryRow
                    @onModelUpdated="onRetryModelUpdated"
                    :channels="channels"
                    :entity="retry"
                    :index="i"
                    :parent-campaign-state="retry.parentCampaignStatus"
                    :parent-campaign-data="parentCampaignsData[i]"
                    :manual-start="parentCampaign.manual_start"
                    @delete="onRetryDelete(retry, i)"
                />
            </div>
        </div>

        <div class="row" v-if="retryEnabled">
            <div class="col-md-3" v-if="allowConfigureSample && retries.length > 0">
                <RepeatSamplingEnabled v-model="variablesRetry" />
            </div>
        </div>
    </div>
</template>

<script>
import api from "src/api";
import FloatingActionButton from "src/app-components/FloatingActionButton";
import { RadioGroup, RadioButton } from "element-ui";
import GapRetry from 'src/app-components/form-elements/GapRetry'
import ProgrammedRetry from 'src/app-components/form-elements/ProgrammedRetry'
import CampaignName from 'src/app-components/form-elements/CampaignName'
import RetryOptions from 'src/app-components/form-elements/RetryOptions'
import RetryEnabled from 'src/app-components/form-elements/RetryEnabled'
import RepeatSamplingEnabled from 'src/app-components/form-elements/RepeatSamplingEnabled'
import RetryRow from 'src/app-components/RetryRow'
import { cloneDeep } from 'lodash'
import swal from "sweetalert2";
import moment from "moment";

export default {
    components: {
        RetryRow,
        FloatingActionButton,
        [RadioGroup.name]: RadioGroup,
        [RadioButton.name]: RadioButton,
        GapRetry,
        ProgrammedRetry,
        CampaignName,
        RepeatSamplingEnabled,
        RetryOptions,
        RetryEnabled,
        RetryRow
    },
    props: {
        entity: {
            type: Object,
            default: null,
        },

        allowConfigureSample: {
            type: Boolean,
            default: false
        },

        parentCampaignStatus: Number,
        parentCampaign: Object,
    },
    data() {
        return {
            retryEnabled: true,
            retries: [
            ],
            variablesRetry: {
                "performance-repeat-sampling": {
                    "variable_id": 90,
                    "value": true
                }
            },
            operationsLog: {},
            channels: []
        }
    },
    
    created() {
      this.getChannels();
    },

    computed: {

        finalForm () {
            return {
                retries: this.retries.map((retry, i) => {
                    const dateProgrammed = retry.form[`dateProgrammed${retry.form.type}`];
                    let endDateTime = dateProgrammed.end_datetime
                    if (endDateTime != null && typeof(endDateTime) == "object") {
                        endDateTime = moment(endDateTime).format("YYYY-MM-DD HH:mm:ss")
                    }

                    return {
                        "appId": retry.appId,
                        "retry_type": retry.form.type,
                        //"name": retry.form.campaign,
                        //"name": (i + 1).toString(),
                        "name": this.parentCampaign.name + " Retry " + (i+1),
                        "manual_start": dateProgrammed.manual_start,
                        "start_datetime": dateProgrammed.start_datetime,
                        "end_datetime": endDateTime,
                        "variables": {
                            ...(retry.form.type == "GAP" && retry.form.variablesGapTime),
                            ...retry.form.variablesRetry,
                            ...this.variablesRetry
                        }
                    };
                }),
                operationsLog: this.operationsLog
            };

        },

        parentCampaignsData () {
            return this.retries.map((retry, i) => {
                let finishDateTime;
                let endDateTime;
                let activePing = false;
                if (i == 0) {
                    finishDateTime = this.parentCampaign ? this.parentCampaign.finished_datetime : null
                    endDateTime = this.parentCampaign ? this.parentCampaign.end_datetime : null
                } else {
                    const previousRetry = this.retries[i-1];
                    const dateProgrammed = previousRetry.form[`dateProgrammed${previousRetry.form.type}`];
                    finishDateTime = previousRetry.finishedDateTime
                    endDateTime = dateProgrammed ? dateProgrammed.end_datetime : null
                }
                /* if (
                    this.parentCampaign &&
                    this.parentCampaign.variables &&
                    this.parentCampaign.variables['active-ping']
                ) {
                    activePing = (this.parentCampaign.variables['active-ping'].value == "1");
                } */

                activePing = this.checkWithPing();
                
                return {
                    endDateTime: finishDateTime || endDateTime,
                    activePing
                }
            });
        }
    },
    methods: {
        checkWithPing() {
          let res = false;
          if(this.parentCampaign) {
            if(this.parentCampaign.variables) {
              this.parentCampaign.variables.forEach(variable => {
                if(variable.variable) {
                  if(variable.variable.name === "active-ping") {
                    res = variable.value;
                  }
                }
              });
            }
          }
          return res;
        },

        async getChannels() {
          const channels = await api.getScenarioChannels();
          this.channels = channels.data;
        },
        async onRetryDelete (retry, index) {
            const res = await swal({
                title: this.$t("Delete retry?"),
                text: this.$t("All descendant retries will be deleted too."),
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: this.$t("Yes"),
                buttonsStyling: false,
                allowOutsideClick: false
            });
            if (!res.value) return;
            //if (index == 0) return;
            this.retries.filter((r, i) => i >= index).forEach(r => this.operationsLogRegisterDelete(r.appId));
            this.retries = this.retries.filter((r, i) => i < index);
        },


        addNew() {
            const newRetry = this.createRetry();
            //console.log(newRetry);
            this.retries.push(newRetry);
            this.operationsLogRegisterCreate(newRetry.appId)
        },

        onRetryModelUpdated (appId, form) {
            this.retries = this.retries.map(r => {
                if (r.appId == appId) {
                    return { ...r, form };
                }
                return r;
            });
            this.operationsLogRegisterUpdate(appId);
        },

        createRetry () {
            let parentCampaignStatus;
            if (this.retries.length == 0) {
                parentCampaignStatus = this.parentCampaignStatus
            } else {
                //parentCampaignStatus = 1;
                parentCampaignStatus = this.retries[this.retries.length - 1].statusId;
            }

            return {
                appId: Date.now(),
                parentCampaignStatus,
                //parentCampaignData,
                finishedDateTime: null,
                statusId: 1,
                form: {
                    type: "GAP",
                    campaign: null,
                    variablesGapTime: {
                        "time-gap": {
                            variable_id: 40,
                            value: null
                        }
                    },
                    variablesRetry: {
                        "retry-statuses-id": {
                            "variable_id": 34,
                            "value": ""
                        }
                    },
                    dateProgrammedGAP: {
                        manual_start: true,
                        start_datetime: null,
                        end_datetime: null
                    },
                    dateProgrammedPROGRAMMED: {
                        manual_start: false,
                        start_datetime: null,
                        end_datetime: null
                    }
                }
            }
        },

        fillStateFromEntity (entity) {

            const list = this.entityGraphToList(entity);

            //console.log(list);

            this.retries = list.map((retry, i, retryList) => {

                const timeGap = retry.variables.find(v => v.variable_id == 40);
                const retryStatuses = retry.variables.find(v => v.variable_id == 34);

                const parentCampaignStatus = i == 0 ? this.parentCampaignStatus : (retryList[i-1].status_id);

                return {
                    appId: retry.id,
                    parentCampaignStatus,
                    //parentCampaignData,
                    finishedDateTime: retry.finished_datetime,
                    statusId: retry.status_id,
                    form: {
                        type: retry.retry_type,
                        campaign: retry.name,
                        variablesGapTime: {
                            "time-gap": timeGap || {
                                variable_id: 40,
                                value: null
                            }
                        },
                        variablesRetry: {
                            "retry-statuses-id": retryStatuses || {
                                "variable_id": 34,
                                "value": ""
                            }
                        },
                        dateProgrammedGAP: {
                            manual_start: true,
                            start_datetime: null,
                            end_datetime: null
                        },
                        dateProgrammedPROGRAMMED: {
                            manual_start: false,
                            start_datetime: null,
                            end_datetime: null
                        },
                        [`dateProgrammed${retry.retry_type}`]: {
                            manual_start: retry.manual_start,
                            start_datetime: retry.start_datetime,
                            end_datetime: retry.end_datetime
                        },
                    }
                }
            });

            //console.log(this.retries);

            // Retry configured with repeat creatives
            const repeat = entity.variables.find(v => v.variable_id == 90);
            this.variablesRetry = {
                "performance-repeat-sampling": repeat || {
                    "variable_id": 90,
                    "value": true
                }
            };

            this.operationsLogInit(list);
        },

        entityGraphToList (entity, result = []) {
            result.push(entity);
            if (typeof(entity.children) !== "undefined" && Array.isArray(entity.children) && entity.children.length > 0) {
                this.entityGraphToList(entity.children[0], result);
            }
            return result;
        },



        //===========================================================
        // Operation log for edit flow
        //===========================================================

        operationsLogInit (retryList) {
            this.operationsLog = retryList.reduce((log, current) => {
                log[current.id] = "PRISTINE";
                return log;
            }, {});
        },

        operationsLogRegisterCreate (appId) {
            this.$set(this.operationsLog, appId, "CREATED");
        },

        operationsLogRegisterUpdate (appId) {
            if (this.operationsLog[appId] == "CREATED") return;
            this.$set(this.operationsLog, appId, "UPDATED");
        },

        operationsLogRegisterDelete (appId) {
            const newValue = this.operationsLog[appId] == "CREATED" ? undefined : "DELETED";
            this.$set(this.operationsLog, appId, newValue);
        }

    },
    watch: {

        /*
        operationsLog: {
            handler () {
                console.log(JSON.stringify(this.operationsLog, null, 2));
            },
            deep: true
        },

         */


        finalForm: {
            handler () {
                //console.log(JSON.stringify(this.finalForm, null, 2));
                this.$emit("modelUpdated", this.finalForm);
            },
        },
        entity: {
            handler () {
                // console.log("%cENTITY ------- %s",this.entity, 'background: #222; color: #bada55');
                if (!this.entity) return;
                this.fillStateFromEntity(cloneDeep(this.entity));
            },
        },

        retries: {
            handler () {
                console.log("=====================================================");
                console.log(JSON.stringify(this.retries, null, 2));
                console.log("=====================================================");
            },
            immediate: true
        },

        'parentCampaign.name' (newVal, oldVal) {
            if (!oldVal) return;
            Object.keys(this.operationsLog).forEach(key => {
                if (this.operationsLog[key] == "PRISTINE") {
                    this.operationsLogRegisterUpdate(key);
                } 
            });
        },
    }

}
</script>

<style scoped>
</style>
