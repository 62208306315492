<template>
  <div>
    <SectionHeader text="Campaign Info" :top-margin="false" />
    <div class="row">
      <div class="col-md-3">
        <campaign-name
          v-model="form.name"
          :isUnique="!hasId"
          :disabled="disabled"
        />
      </div>

      <div class="col-md-6">
        <date-programmable :disabled="disabled" v-model="dateProgrammed" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <campaign-flow
          :channelId="channelId"
          :scenarioId="form.account_scenario_type_id"
          :channelDisabled="true"
          :scenarioDisabled="disabled"
          :filterScenarioBy="activeScenarios"
          :isEditable="true"
          @modelUpdated="onCampaignFlowUpdated"
        />
      </div>

      <div class="col-md-4">
        <call-to-action
          v-model="form"
          :scenarioId="scenarioId"
          :isEditable="true"
        >
        </call-to-action>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

import api from "src/api";

import { updatableForm } from "src/app-components/FormMixin";
import DateProgrammedMixin from "src/app-components/DateProgrammedMixin";

import CallToAction from "src/app-components/form-elements/CallToAction";

import CampaignFlow from "src/app-components/form-elements/CampaignFlowR";
import CampaignName from "src/app-components/form-elements/CampaignName";
import DateProgrammable from "src/app-components/form-elements/DateProgrammable";
import SectionHeader from "src/app-components/form-elements/SectionHeader";

export default {
  inject: {
    parentValidator: "parentValidator",
  },

  mixins: [updatableForm, DateProgrammedMixin("form")],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CampaignFlow,
    CampaignName,
    [DateProgrammable.name]: DateProgrammable,
    SectionHeader,
    CallToAction,
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    hasId() {
      return typeof this.form.id !== "undefined" && this.form.id != null;
    },

    scenarioId() {
      return this.form.account_scenario_type_id;
    },

    channelId() {
      return (this.$route.name === "CampaignCreateFromPing" || this.$route.name === "CampaignEditFromPing") ? 1 : 5;
    },
  },

  data() {
    return {
      form: {
        id: null,
        end_datetime: null,
        start_datetime: null,
        manual_start: null,
        name: null,
        channel_id: null,
        account_scenario_type_id: null,
        variables: [],
      },
    };
  },

  methods: {
    onCampaignFlowUpdated($event) {
      this.form = { ...this.form, ...cloneDeep($event) };
    },

    activeScenarios(scenario) {
      if(this.channelId === 5) {
        return scenario.scenario.ping === true || scenario.scenario.channel_id === 5;
      }

      return scenario.scenario.ping === true
    },
  },
};
</script>

<style></style>
