<template>
  <div>
    <div class="card">
      <el-table :data="segmentationsWithPagination" style="width: 100%">
        <el-table-column prop="id" :label="$t('ID')" :width="100">
        </el-table-column>

        <el-table-column prop="name" :label="$t('Name')">
          <b slot-scope="scope">{{ scope.row.name }}</b>
        </el-table-column>

        <el-table-column :label="$t('Filters')">
          <template slot-scope="scope">
            <span>{{ $t(pipelinesInUse(scope.row.id).filter) }}</span>
            <span v-if="pipelinesInUse(scope.row.id).extraFilters">
              {{ $t("and") }}
              <el-tooltip class="extra-filters" effect="dark" placement="top">
                <template v-slot:content>
                  <span v-html="pipelinesInUse(scope.row.id).tooltip"></span>
                </template>

                <span
                  >{{ pipelinesInUse(scope.row.id).extraFilters }}
                  {{ $t("more") }}</span
                >
              </el-tooltip>
            </span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('Last run')">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.last_run) }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('Delay time')">
          <template slot-scope="scope">
            <span>{{ formatDelayTime(scope.row.last_exec_time) }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('Audience')">
          <template slot-scope="scope">
            <span>{{ scope.row.last_run_result | formatNumber }}</span>
          </template>
        </el-table-column>

        <el-table-column
          class-name="action-buttons td-actions"
          align="left"
          :label="$t('Actions')"
          :width="255"
        >
          <template slot-scope="scope">
            <template v-for="action in actions">
              <p-button
                :type="action.type"
                size="sm"
                icon
                :disabled="
                  classIconActions(scope.row, action) === 'el-icon-loading'
                "
                @click="action.event(scope.row.id)"
                v-if="scope.row.file_path || action.show"
              >
                <el-badge
                  is-dot
                  class="item item-badge"
                  v-show="scope.row.new_files"
                  v-if="action.id === 'download'"
                ></el-badge>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t(action.tooltip)"
                  placement="top"
                >
                  <i :class="classIconActions(scope.row, action)"></i>
                  {{ scope.row.loading }}
                </el-tooltip>
              </p-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <p-pagination
			:page-count="pageCount"
			class="pull-right"
			v-model="page"
		>
		</p-pagination>

    <floating-action-button
      color="#339564"
      icon="add"
      :position="0.4"
      @click="createView"
    />
  </div>
</template>

<script>
import sio from "@/sio";
import api from "src/api";
import swal from "sweetalert2";
import { mapMutations } from "vuex";
import FileDownload from "js-file-download";
import OptionsTypeMixin from "src/app-components/segmentation/OptionsTypeMixin";
import FloatingActionButton from "src/app-components/FloatingActionButton";
import Pagination from "src/components/UIComponents/Pagination";
import { Badge, Button } from "element-ui";
export default {
  name: "SegmentationIndex",
  mixins: [OptionsTypeMixin],
  components: {
    FloatingActionButton,
    [Badge.name]: Badge,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
  },

  data() {
    return {
      segmentations: [],
      getInterval: null,
      downloadingFiles: [],
      page: 1,
      segmentationPerPage: 10,
    };
  },

  created() {
    sio.onAny((event, obj) => {
      if (obj.metadata) {
        const index = this.findIndexSegmentation(obj.metadata.pipelineId);
        if (index >= 0) {
          console.log("socket pipelineID", obj.metadata);
          if (obj.metadata.result) {
            this.getSegmentations();
          }

          if (obj.metadata.fileId) {
            this.downloadPartial(obj.metadata.fileId, obj.metadata.pipelineId);
          }
        }
      }
    });

    this.setTopNavbarTitle("Segmentation");

    this.getSegmentations();
    this.getInterval = setInterval(() => {
      this.getSegmentations();
    }, 60000);
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    createView() {
      swal({
        title: "Create segmentation",
        input: "text",
        inputPlaceholder: "Enter segment name",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Create",
        buttonsStyling: false,
        allowOutsideClick: false,
        inputValidator: async (value) => {
          if (value === "") return this.$t("Name is required");
          let res = await this.validateNameSegmentation(value);
          if (!res.status) return this.$t(res.errors);
        },
      }).then(async (result) => {
        if (result.value) {
          let res = await api.createSegmentation({
            name: result.value,
          });
          if (res.status) {
            this.editView(res.data.id);
          }
        }
      });
    },

    async statusNewFilesSegmentation(id, status = true) {
      let res = await api.statusNewFilesSegmentation(id, status);

      let index = this.findIndexSegmentation(id);
      
      if(index !== -1) {
        this.segmentations[index].new_files = status;
      }
    },

    async runSegmentation(id) {
      const res = await api.runSegmentations(id);
      console.log("runSegmentation", id);
      const index = this.findIndexSegmentation(id);
      this.segmentations[index].running = true;
    },

    async validateNameSegmentation(name) {
      return await api.validateNameSegmentation(name);
    },

    infoView(id) {
      this.$router.push({
        name: `SegmentationInfo`,
        params: { id },
      });
    },

    editView(id) {
      this.$router.push({
        name: `SegmentationEdit`,
        params: { id },
      });
    },

    async download(id) {
      let index = this.findIndexSegmentation(id);
      
      if(this.segmentations[index].new_files) {
        this.statusNewFilesSegmentation(id, false);
      }
      
      let { name, last_run, last_run_result, files } = this.findSegmentation(
        id
      );
      let inputOptions = [
        `${this.$t("All")} (${this.$options.filters.formatNumber(
          last_run_result
        )})`,
        this.$t("Partial"),
      ];

      files.forEach((file) => {
        inputOptions.push(
          `${this.$t("Partial")} (${this.$options.filters.formatNumber(
            file.count
          )})`
        );
      });
      // Modal para seleccionar todos o parcial
      const { value: option } = await swal({
        title: this.$t("Select the number of users."),
        input: "select",
        inputOptions: inputOptions,

        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        buttonsStyling: false,
        allowOutsideClick: false,
        inputValidator: (value) => {
          if (!value) {
            return this.$t("You need to choose something!");
          }
        },
      });

      if (option == 0) {
        // Si eligió todos descargo el archivo con todos los usuarios
        this.downloadingFiles.push(id);
        let file = await api.downloadAllSegmentation(id);
        // await FileDownload(file, `${name} ${this.formatDate(last_run, true)}.txt`);
        await FileDownload(
          file,
          `${name} ${this.formatDate(new Date(), true)}.txt`
        );
        let index = this.downloadingFiles.findIndex((file) => file === id);
        this.downloadingFiles.splice(index, 1);
      } else if (option == 1) {
        // Si eligió parcial pregunto cuantos usuarios quiere descargar
        const { value: total } = await swal({
          title: `${this.$t(
            "Select the number of users."
          )} Max: ${this.$options.filters.formatNumber(last_run_result)}`,
          input: "text",
          inputValue: "",

          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (!value) {
              return this.$t("You need to write something!");
            } else if (isNaN(value)) {
              return this.$t("You need to write a number!");
            } else if (value > last_run_result) {
              return `${this.$t(
                "You can't download more than the total of users."
              )} Max: ${this.$options.filters.formatNumber(last_run_result)}`;
            }
          },
        });

        if (total) {
          // Pido a la api que genere el nuevo archivo con la cantidad de usuarios que seleccionó
          this.downloadingFiles.push(id);
          let splitFile = await api.splitSegmentation(id, total);
          swal(this.$t("This process may take a few minutes."));
          // swal(this.$t("This process may take a few minutes. The file will be downloaded upon completion."));
        }
      } else if (option > 1) {
        let { id, pipeline_id, count } = files[option - 2];
        this.downloadingFiles.push(pipeline_id);
        let file = await api.downloadFileSegmentation(id);
        // await FileDownload(file, `${name} ${this.formatDate(last_run)} partial ${count}.txt`);
        await FileDownload(
          file,
          `${name} ${this.formatDate(new Date(), true)} partial ${count}.txt`
        );
        let index = this.downloadingFiles.findIndex(
          (file) => file === pipeline_id
        );
        this.downloadingFiles.splice(index, 1);
      }
    },

    async downloadPartial(id, pipelineId) {
      await this.statusNewFilesSegmentation(pipelineId, true);
      await this.getSegmentations();
      this.$notifications.clear();
      this.$notify({
        message: "Segmentation cut finished. Now you can download the file",
        icon: "fa fa-check",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "info",
      });

      let index = this.downloadingFiles.findIndex(
        (file) => file === pipelineId
      );
      this.downloadingFiles.splice(index, 1);
    },

    async copyView(id) {
      let { status, data } = await api.getSegmentation(id);
      if (!status) return;

      let stages = this.newStagesCopy(data.stages);

      swal({
        title: `${this.$t("Create segmentation from")} "${data.name}"`,
        input: "text",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Create"),
        buttonsStyling: false,
        allowOutsideClick: false,
        inputValidator: async (value) => {
          if (value === "") return this.$t("Name is required");
          let res = await this.validateNameSegmentation(value);
          if (!res.status) return this.$t(res.errors);
        },
      }).then(async (result) => {
        if (result.value) {
          let res = await api.createSegmentation({
            name: result.value,
            stages,
          });
          if (res.status) {
            this.editView(res.data.id);
          }
        }
      });
    },

    async delete(id) {
      let res = await api.deleteSegmentation(id);
      if (res.status) {
        this.$notify({
          title: this.$t("Congratulations!"),
          message: this.$t("You have successfully delete the segmentation."),
          icon: "fa fa-info",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
          duration: 10000,
        });
        this.deleteSegmentationInView(id);
      }
    },

    modalDelete(id) {
      swal({
        title: this.$t("Delete?"),
        text: this.$t("Are you sure you want to delete this segmentation?"),
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Yes, delete it!"),
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.delete(id);
        }
      });
    },

    deleteSegmentationInView(id) {
      let index = this.findIndexSegmentation(id);
      this.segmentations.splice(index, 1);
    },

    newStagesCopy(stages) {
      return stages.map((stage) => {
        return {
          action: stage.action,
          type: stage.type,
          params: stage.params,
        };
      });
    },

    async getSegmentations(download = false) {
      try {
        const response = await api.getSegmentations();
        if (response.status) {
          this.segmentations = response.data;
        }

        if (download) {
        }
      } catch (error) {
        console.log(error);
      }
    },

    findSegmentation(id) {
      return this.segmentations.find((segmentation) => segmentation.id === id);
    },

    findIndexSegmentation(id) {
      return this.segmentations.findIndex(
        (segmentation) => segmentation.id === id
      );
    },

    formatDate(date, file = false) {
      if (!date) return "";
      let separator = file ? "-" : ":";
      let day = new Date(date);

      let dd = day.getDate();
      let mm = day.getMonth() + 1;
      let yyyy = day.getFullYear();
      let hh = day.getHours();
      let min = day.getMinutes();
      let sec = day.getSeconds();

      return `${yyyy}-${mm < 10 ? "0" + mm : mm}-${dd < 10 ? "0" + dd : dd} ${
        hh < 10 ? "0" + hh : hh
      }${separator}${min < 10 ? "0" + min : min}${separator}${
        sec < 10 ? "0" + sec : sec
      }`;
    },

    formatDelayTime(sec) {
      let formatted = "";
      sec = parseInt(sec);
      if (sec) {
        if (sec >= 60) {
          let minutes = parseInt(sec / 60);
          let seconds = sec - minutes * 60;

          formatted =
            minutes < 10
              ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds} min`
              : `${minutes} min`;
        } else {
          formatted = `${sec} ${this.$t("sec")}`;
        }
      }

      return formatted;
    },

    pipelinesInUse(id) {
      const segmentation = this.findSegmentation(id);
      let res = {
        filter: null,
        tooltip: null,
        extraFilters: 0,
      };
      if (!segmentation) return res;

      segmentation.stages.forEach((stage, key) => {
        if (key === 0) return;

        let name = this.nameOptionType(stage.type);

        if (key === 1) {
          res.filter = name;
        } else {
          res.extraFilters++;
          res.tooltip = res.tooltip
            ? `${this.$t(res.tooltip)},<br>${this.$t(name)}`
            : this.$t(name);
        }
      });

      return res;
    },

    nameOptionType(id) {
      let res = this.optionsType.find((option) => option.id === id);
      return res ? res.name : "";
    },

    classIconActions(row, action) {
      if (action.id === "play") {
        if (row.running) return "el-icon-loading";
      }

      if (action.id === "download") {
        if (this.checkLoadingFiles(row.id) > -1) return "el-icon-loading";
      }

      return `fa ${action.icon}`;
    },

    checkLoadingFiles(id) {
      return this.downloadingFiles.findIndex((file) => file === id);
    },
  },

  computed: {
    actions() {
      return [
        {
          id: "play",
          event: this.runSegmentation,
          // icon: "el-icon-loading",
          icon: "fa-play",
          tooltip: "Run segmentation",
          type: "success",
          show: true,
        },
        {
          id: "info",
          event: this.infoView,
          icon: "fa-info",
          tooltip: "Info",
          type: "warning",
          show: true,
        },
        {
          id: "edit",
          event: this.editView,
          icon: "fa-edit",
          tooltip: "Edit",
          type: "info",
          show: true,
        },
        {
          id: "download",
          event: this.download,
          icon: "fa-download",
          tooltip: "Download",
          type: "success",
        },
        {
          id: "clone",
          event: this.copyView,
          icon: "fa-clone",
          tooltip: "Clone",
          type: "dribbble",
          show: true,
        },
        {
          id: "delete",
          event: this.modalDelete,
          icon: "fa-trash",
          tooltip: "Delete",
          type: "danger",
          show: true,
        },
      ];
    },

    segmentationsWithPagination() {
      let res = [];
      let count = 0;

      let arraysTotal = Math.ceil(this.segmentations.length / this.segmentationPerPage);

      for (let i = 0; i < arraysTotal; i++) {
        res.push(this.segmentations.slice(i * this.segmentationPerPage, (i + 1) * this.segmentationPerPage))
      }

      return res[this.page - 1];
    },

    pageCount() {
      return Math.ceil(this.segmentations.length / this.segmentationPerPage);
    },
  },

  beforeDestroy() {
    clearInterval(this.getInterval);
  },
};
</script>
<style scoped>
.save {
  display: flex;
  justify-content: flex-end;
}
.segmentation {
  overflow: hidden;
}

.card .extra-filters {
  color: #51bcda;
}
</style>

<style>
.segmentation ul li {
  list-style: none;
}

.segmentation button {
  outline: none;
}

.card th.el-table__cell > .cell {
  padding-left: 0;
  padding-right: 0;
  word-break: normal !important;
}

.card .el-table .cell {
  word-break: normal;
}

.item.item-badge {
  margin-top: -8px;
  margin-right: -16px;
}
</style>
