<template>
  <apex-chart type="pie" ref="chartsent" width="100%" height="250px" :options="dataChartSent.options" :series="dataChartSent.series"></apex-chart>
  <!-- <apex-chart type="pie" ref="chartsent" width="100%" height="250px" :options="options" :series="series"></apex-chart> -->
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  export default {
    name: "ChartSent",
    props: {
      series: {
        type: Array,
        default: [],
      },
      options: {
        type: Object,
        default: {},
      },
    },
    
    components: {
      "apex-chart": VueApexCharts,
    },

    data() {
      return {
        dataChartSent: {
          options: {
            labels: ["Sent", "Failed"],

            chart: {
              animations: {
                enabled: false,
              },
            },

            legend: {
              position: "bottom",
            },
            responsive: [
              {
                breakpoint: 768,
                options: {
                  legend: {
                    position: "right",
                  },
                },
              },
            ],
          },
          series: [1257, 243],
        },
      };
    },
  };
</script>
