<template>
    <div>
        <div class="row" v-if="!scenarioId">
            <div class="col-md-12 align-self-center">
                <p class="text-muted">
                    {{ $t("Please select a scenario first.") }}
                </p>
            </div>
        </div>
        <scenario-hidden-elements
                v-else
                :scenario="scenarioId"
                :variables="form.variables"
                :module="module"
                :fill-default-values="true"
                @input="onInput($event)"
        >
        </scenario-hidden-elements>
    </div>
</template>

<script>
import _ from 'lodash'
import swal from "sweetalert2";
import ScenarioHiddenElements from 'src/app-components/form-elements/generators/ScenarioHiddenElements'
import { updatableForm } from 'src/app-components/FormMixin'
import { cloneDeep, merge } from "lodash";

export default {
    components: {
        ScenarioHiddenElements
    },
    props: {
        scenarioId: Number,
        module: {
          type:String,
          default: "campaign"
        }
    },
    mixins: [
        updatableForm
    ],
    data() {
        return {
            form: {
                variables: []
            }
        }
    },

    methods: {
      onInput({ value, updValue, variable, variable_id }) {
        if(!variable_id) return;

        let input = cloneDeep(this.form.variables);
        let index = input.findIndex(v => v.variable_id == variable_id);
        if(index > -1) {
          if(updValue || updValue === 0) {
            input[index].value = value;
          }
        }else{
          input.push({
            value,
            variable,
            variable_id,
          });
        }

        this.form.variables = merge(this.form.variables, input);
        this.updatedModel(this.form.variables);
      },
    }
}
</script>

<style scoped>
</style>
