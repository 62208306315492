<template>
  <div
    class="btn-new-elements text-center"
    @mouseover="activeButtons = true"
    @mouseleave="activeButtons = false"
  >
    <transition name="el-fade-in">
      <div v-show="activeButtons">
        <el-tooltip
          v-for="opt in options"
          :key="opt.icon"
          class="item"
          effect="dark"
          :content="$t(opt.tooltip)"
          placement="left"
        >
          <el-button
            :type="opt.type"
            class="d-block mx-auto mb-2"
            :class="opt.item"
            :icon="opt.icon"
            circle
            @click="newItemCanvas(opt.item)"
          ></el-button>
        </el-tooltip>
      </div>
    </transition>

    <el-button
      type="primary"
      class="d-block mx-auto"
      icon="el-icon-plus"
      circle
    ></el-button>
  </div>
</template>

<script>
  import { Button, Tooltip } from "element-ui";

  export default {
    name: "CreateElementButtons",
    components: {
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
    },

    data() {
      return {
        activeButtons: false,
      };
    },

    methods: {
      newItemCanvas(item) {
        this.$emit("newItemCanvas", item);
      },
    },

    computed: {
      options() {
        return [
          {
            icon: "el-icon-notebook-2",
            tooltip: "Create note",
            type: "warning",
            item: "Note",
          },
          {
            icon: "el-icon-chat-line-round",
            tooltip: "Create message",
            type: "success",
            item: "Message",
          },
          {
            icon: "el-icon-chat-line-square",
            tooltip: "Create message with options",
            type: "primary",
            item: "MessageWithOptions",
          },
          {
            icon: "el-icon-tickets",
            tooltip: "Data storage",
            type: "info",
            item: "DataStorage",
          },
          {
            icon: "el-icon-guide",
            tooltip: "Condition",
            type: "danger",
            item: "Condition",
          },
          {
            icon: "el-icon-share",
            tooltip: "Integration",
            type: "warning",
            item: "Integration",
          },
          {
            icon: "el-icon-switch-button",
            tooltip: "Close",
            type: "",
            item: "Close",
          },
          {
            icon: "el-icon-help",
            tooltip: "Contact center",
            type: "",
            item: "ContactCenter",
          },
          /* {
            icon: "el-icon-s-promotion",
            tooltip: "Go to",
            type: "",
            item: "GoTo",
          }, */
        ];
      },
    },
  };
</script>

<style scoped>
  .btn-new-elements {
    z-index: 5;
    position: absolute;
    right: 40px;
    bottom: 40px;
  }
  
  .wrapperCanvas.dark .btn-new-elements .el-button {
    background: #555;
    border-color: #555;
    color: white;
  }
  
  .wrapperCanvas.dark .btn-new-elements .Message {
    background: #03DAC6;
    border-color: #03DAC6;
  }
  .wrapperCanvas.dark .btn-new-elements .MessageWithOptions {
    background: #6200EE;
    border-color: #6200EE;
  }
  .wrapperCanvas.dark .btn-new-elements .DataStorage {
    background: #BB86FC;
    border-color: #BB86FC;
  }
  .wrapperCanvas.dark .btn-new-elements .Condition {
    background: #CF6679;
    border-color: #CF6679;
  }
  .wrapperCanvas.dark .btn-new-elements .Note {
    background: #E6A23C;
    border-color: #E6A23C;
  }

</style>

<style></style>
