<template>
    <div class="row">
        <div class="col-12 d-flex align-items-center mb-2">
            <h6 class="text-left my-1">
                {{ $t("SMS Usuarios no compatibles") }}
            </h6>
            <p-switch class="ml-2 mb-0" v-model="form.enabled" :on-text="$t('YES')" :off-text="$t('NO')"></p-switch>
        </div>
        <div class="col">
            <el-input
                type="textarea"
                resize="none"
                :disabled="!form.enabled"
                v-model="form.text"
                :autosize="{ minRows: 2, maxRows: 3 }"
            ></el-input>
        </div>
    </div>
</template>

<script>
import { Input, Button } from "element-ui";
import PSwitch from "src/custom-components/UIComponents/Switch";

import VModelMixin from 'src/mixins/v-model-mixin.js'

export default {
    components: {
        [Input.name]: Input,
        [Button.name]: Button,
        [PSwitch.name]: PSwitch,
    },

    mixins: [
        VModelMixin('form')
    ],

    data() {
        return {
            form: {
                enabled: false,
                text: ''
            }
        };
    },
};
</script>