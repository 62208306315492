<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label v-if="label">{{ $t(label) }}</label>
        <el-select
          size="large"
          name="status"
          :clearable="clearable"
          :filterable="filterable"
          v-validate="noValidation ? null : 'required'"
          @input="$emit('input', $event)"
          :error="getError('status')"
          :value="local"
          :placeholder="placeholder"
        >
          <el-option
            v-for="option in statuses"
            :value="option.id"
            :label="option.name"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>
      <div
        v-show="errors.has('status')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('status') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],

  props: {
    value: null,
    label: {
      type: String,
      default: "Status",
    },
    noValidation: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    }
  },

  async created() {
    this.$validator = this.parentValidator;
  },

  data() {
    return {
      statuses: [{ id: "PENDING", name: "Pending" }, { id: "REJECTED", name: "Rejected" }, { id: "APPROVED", name: "Approved" }]
    };
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  },
};
</script>

<style>
</style>
