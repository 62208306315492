<template>
    <div class="col-md-4">
      <label>{{ field.tooltip }}</label>
      <br>
      <p-switch
        type="primary"
        on-text="ON"
        off-text="OFF"
        :name="field.name"
        v-validate="field.required ? 'required' : ''"
        :value="local"
        @input="$emit('input', $event)"
        :error="getError(field.name)"
      ></p-switch>
    </div>
</template>

<script>
import PSwitch from "src/components/UIComponents/Switch.vue";

export default {
    name: 'slider',
    
    components: {
        [PSwitch.name]: PSwitch
    },

    inject: ["getError", "parentValidator"],
    
    props: [ "value", "field"],
    
    computed: {
        local() {
            return this.value ? Boolean(this.value) :false;
        }
    },

    created() {
        this.$validator = this.parentValidator;
    }
}
</script>

<style>

</style>
