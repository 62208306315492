<template>
  <card>
    <div class="card-header">
      <h4 class="card-title">SMPP Gateway</h4>
    </div>
    <div class="card-body">
      <tabs @tabChanged="tabChanged" tab-content-classes="text-center">
        <tab-pane id="statistics" key="statistics">
          <span slot="title">
              Statistics
          </span>
          <Statistics :active="currentTab === 'statistics'" />
        </tab-pane>
        <tab-pane id="customerService" key="customerService">
          <span slot="title">
              Customer Service
          </span>
          <CustomerService :active="currentTab === 'customerService'" />
        </tab-pane>
      </tabs>
    </div>
  </card>
</template>

<script>
  import { mapActions } from "vuex";
  import {Collapse, CollapseItem, Card} from 'src/components/UIComponents'
  import Tabs from 'src/custom-components/UIComponents/Tabs/Tabs'
  import TabPane from 'src/custom-components/UIComponents/Tabs/Tab'
  import Statistics from 'src/views/smpp-gateway/statistics/index'
  import CustomerService from 'src/views/smpp-gateway/customer-service/index'

  export default {
    components: {
      TabPane,
      Tabs,
      CollapseItem,
      Collapse,
      Card,
      Statistics,
      CustomerService
    },
    mounted () {
      this.setTopNavbarTitle("SMPP Gateway");
    },
    methods: {
      ...mapActions(["setTopNavbarTitle"]),
      tabChanged (tab) {
        this.currentTab = tab.id;
      }
    },
    data () {
      return {
        currentTab: "statistics",
      }
    }
  };
</script>
<style>
</style>