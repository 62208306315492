<template>
  <div>
    <el-tooltip
      :content="$t('An agent is a conversational entity for your business or brand. Is the one that speaks directly with the users')"
    >
      <p>
        {{
          $t("Here you will be able to edit an Agent. In order to do so, please click on an Agent. If you need to add a new Agent, click on the bottom on the bottom right corner.")
        }}
      </p>
    </el-tooltip>
    <div class="row">
      <div class="col-sm-4" v-for="agent in agents">
        <div class="card" @click="editAgent(agent.id)">
          <img class="card-img-top" :src="agent.logo" alt="Card image cap" />
          <div class="card-body">
            <h5 class="card-title">{{ agent.name }}</h5>
          </div>
        </div>
      </div>
    </div>
    <floating-action-button
      color="#339564"
      icon="add"
      :position="position"
      @click="gotoNew"
    />
  </div>
</template>

<script>
import api from "src/api";
import { mapActions } from "vuex";
import FloatingActionButton from "src/app-components/FloatingActionButton";
import { Tooltip } from "element-ui";
import { Card } from "src/components/UIComponents";
import { BCard } from "bootstrap-vue";

export default {
  components: { FloatingActionButton, [Tooltip.name]: Tooltip, Card, BCard },
  props: {
    position: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agents: [],
    };
  },
  computed: {
    allAgents() {
      return this.agents;
    },
  },
  async mounted() {
    this.fetchAgents();
    this.setTopNavbarTitle(this.$t("Agents"));
  },
  methods: {
    async fetchAgents() {
      const response = await api.getRcsAgents();
      this.agents.push(...response.data);
    },
    ...mapActions(["setTopNavbarTitle"]),

    editAgent(id) {
      this.$router.push({
        name: "EditAgent",
        params: { id },
      });
    },

    gotoNew() {
      this.$router.push({
        name: "AddAgent",
        params: {},
      });
    },
  },
};
</script>

<style scoped>
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
.card {
  padding: 10px;
}

.card:hover {
  cursor: pointer;
}
</style>
