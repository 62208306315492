<template>
  <div class="w-100 mr-2">
    <el-select
      size="large"
      v-model="valueModel"
      @input="handleValueParamsChange($event)"
      :placeholder="$t(placeholder)"
      :multiple="multiple"
      :filterable="true"
      :disabled="disabled"
    >
      <el-option
        v-for="option in options"
        :value="option.id || option.id === 0 ? option.id : option.name"
        :label="$t(option.name)"
        :key="option.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "SelectSegmentation",

  props: {
    value: {
      default: null,
    },

    options: {
      default: [],
    },

    placeholder: {
      default: "",
    },

    param: {
      default: "",
    },

    multiple: {
      default: false,
    },

    default: {
      default: null,
    },
    
    disabled: {
      default: false,
    },
  },

  watch: {
    value() {
      this.checkChangeValue();
    },

    options() {
      this.checkChangeValue();
    },
  },

  data() {
    return {
      valueModel: null,
    };
  },

  created() {
    this.checkChangeValue();
  },

  methods: {
    checkChangeValue() {
      if (this.value !== null) {
        this.valueModel = this.value;
      } else {
        this.valueModel = this.default;
        this.handleValueParamsChange(this.valueModel);
      }
    },

    handleValueParamsChange(value) {
      this.$emit("valueParamsChange", {
        value,
        param: this.param,
      });
    },
  },
};
</script>
