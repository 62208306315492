<template>
  <el-dialog
    :title="`Template configuration ${
      dataConnection ? dataConnection.name : ''
    }`"
    :visible.sync="dialogView"
    width="500px"
    top="25px"
    class="dialog-set-connection"
    @closed="clearData"
  >
    <p class="modal-aclaration">
      If you do not have a flow to associate with this template, it is necessary
      to create a new canvas with a conversation.
      <router-link :to="{ name: 'FlowIndex' }"
        >Go to canvas creation</router-link
      >
    </p>

    <div v-if="dataConnection">
      <div class="my-2">
        <span class="label d-block">{{ $t("Validity") }}</span>
        <span class="aclaration">
          {{ $t("Definir cuanto es el tiempo que está escuchando.") }}
          <span style="font-weight: bold">{{
            $t("El máximo es de hasta 24 hs")
          }}</span>
        </span>
        <div style="width: 110px" class="mt-1">
          <el-input
            v-model="dataConnection.validity"
            type="number"
            size="small"
            class="w-100"
            :min="1"
            :max="24"
            @blur="checkValidity"
          >
            <template slot="append">hs</template>
          </el-input>
        </div>
      </div>

      <div class="my-2">
        <span class="label">{{ $t("Connection type") }}</span>
        <el-select
          v-model="dataConnection.connection_type"
          class="w-100"
          size="small"
        >
          <el-option value="flow" label="Flow"></el-option>
          <el-option
            value="options"
            label="Options"
            v-if="dataConnection.template_options.length"
          ></el-option>
        </el-select>
        <span class="aclaration">{{
          $t("Select the type of connection")
        }}</span>
      </div>

      <div v-if="dataConnection.connection_type === 'flow'">
        <span class="label">{{ $t("Flows") }}</span>
        <el-select
          v-model="dataConnection.connections.flowId"
          class="w-100"
          size="small"
          :placeholder="$t('Select flow')"
        >
          <el-option
            v-for="flow in flows"
            :key="flow.id"
            :value="flow.id"
            :label="flow.name"
          ></el-option>
        </el-select>
        <span class="aclaration">{{
          $t("Select the type of connection")
        }}</span>
      </div>

      <div
        v-if="
          dataConnection.connection_type === 'options' &&
          dataConnection.template_options.length
        "
      >
        <span class="label d-block">{{ $t("From different options") }}</span>
        <span class="aclaration">{{
          $t(
            "Indicates the flow and the node in each of the options that the selected template has"
          )
        }}</span>

        <div class="modal-options">
          <div v-for="opt in options" :key="opt.button" class="my-2">
            <span class="aclaration">{{ $t("Option") }} "{{ opt.button }}"</span>
            <div>
              <el-select
                v-model="opt.flowId"
                class="mr-2"
                size="small"
                :placeholder="$t('Select flow')"
                @change="opt.nodeId = ''"
              >
                <el-option
                  v-for="flow in flows"
                  :key="flow.id"
                  :value="flow.id"
                  :label="flow.name"
                ></el-option>
              </el-select>
              <el-select
                size="small"
                :placeholder="$t('Select node')"
                v-model="opt.nodeId"
                v-show="opt.flowId"
              >
                <el-option 
                  :key="key"
                  :value="node.id" 
                  :label="`${node.name} (${node.type})`"
                  v-for="(node, key) in flowNodes(opt.flowId)"
                ></el-option>
                
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogView = false">{{
        $t("Cancel")
      }}</el-button>
      <el-button
        size="small"
        type="primary"
        @click="save"
        :disabled="!setConnectionValid"
        >{{ $t("Save") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
  import api from "src/api";
  import { Input, Button, Tooltip, Dialog, Select } from "element-ui";
  export default {
    name: "ModalConnectionTemplate",
    props: ["connection", "open", "flows"],

    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Dialog.name]: Dialog,
      [Select.name]: Select,
    },

    data() {
      return {
        dialogView: false,
        dataConnection: null,

        options: []
      };
    },

    watch: {
      open() {
        this.dialogView = true;
      },

      connection() {
        if (this.connection) {
          this.dataConnection = { ...this.connection };
        }
      },

      "dataConnection.connection_type"(new_item, old_item) {
        if (old_item) {
          if (new_item === "flow") {
            this.options = [];
            this.dataConnection.connections = {
              flowId: "",
            };
          }
          if (new_item === "options") {
            this.dataConnection.connections = {};

            this.dataConnection.template_options.forEach((button) => {
              this.options.push({
                button,
                flowId: "",
                nodeId: "",
              });
            });
          }
        }else {
          if (new_item === "options") {
            Object.keys(this.dataConnection.connections).forEach(button => {
              this.options.push({
                button,
                flowId: this.dataConnection.connections[button].flowId,
                nodeId: this.dataConnection.connections[button].nodeId,
              });
            });
          }
        }
      },
    },

    methods: {
      flowNodes(id) {
        let flow = this.flows.find(f => f.id === id);
        if(flow) return flow.nodes;
        return [];
      },

      clearData() {
        this.dataConnection = null;
        this.options = [];
        this.$emit("closeModal");
      },

      async save() {
        if (!this.setConnectionValid) return;
        let data = { ...this.dataConnection };

        data.validity = parseInt(data.validity) ? parseInt(data.validity) : 24;

        if(data.connection_type === "options") {
          data.connections = {}
          this.options.forEach(opt => {
            data.connections[opt.button] = {
              flowId: opt.flowId,
              nodeId: opt.nodeId,
            }
          })
        }
        
        const id = `${data.id}`;
        delete data.id;
        delete data.name;
        delete data.template_options;

        let res = await api.addConnectionTemplates(id, data);
        if (!res.status) {
          console.log(res.error);
          return;
        }

        this.$emit("updateConnection", {...data, name: this.dataConnection.name});
        this.dialogView = false;
      },

      checkValidity() {
        let validity = 24;
        if(isNaN(parseInt(this.dataConnection.validity))) {
          validity = this.dataConnection.validity.replace(/[^0-9]+/g, '')
        }else {
          validity = parseInt(this.dataConnection.validity);
        }

        this.dataConnection.validity = validity >= 1 && validity <= 24 ? validity : 24;
      }
    },

    computed: {
      setConnectionValid() {
        if (!this.validValidity) return false;
        if (!this.validConnections) return false;

        return true;
      },

      validValidity() {
        if (this.dataConnection) {
          if (
            (this.dataConnection.validity <= 24) &
            (this.dataConnection.validity >= 1)
          )
            return true;
        }
        return false;
      },

      validConnections() {
        if (this.dataConnection) {
          if (this.dataConnection.connection_type === "flow") {
            if (this.dataConnection.connections.flowId) {
              return true;
            }
          } else {
            let res = true;
            this.options.forEach(opt => {
              if(!opt.flowId || !opt.nodeId) {
                res = false;
              }
            })

            return res;
          }
        }
        return false;
      },
    },
  };
</script>

<style scoped>
  .dialog-set-connection p.modal-aclaration {
    font-size: 12px;
    margin-bottom: 0;
  }

  .dialog-set-connection p.modal-aclaration a {
    color: #409eff;
  }

  .dialog-set-connection .label {
    font-weight: bold;
    font-size: 12px;
  }

  .dialog-set-connection .aclaration {
    font-size: 10px;
    color: #737372;
  }

  .dialog-set-connection .modal-options .aclaration {
    font-weight: bold;
  }
</style>

<style>
  .dialog-set-connection .el-dialog__body {
    padding: 0 20px;
    word-break: break-word;
  }
</style>
