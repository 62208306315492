<template>
  <div>
    <div class="row justify-content-center">
      <BookmarkName :is-unique="false"
        :required="false"
        :label="false"
        :placeholder="$t('Search')"
        v-model="filters.name" />

      <StatusFormComponent
          filterable
          clearable
          no-validation
          :label="''"
          v-model="filters.status"
          :placeholder="$t('Status')"
      />
    </div>  

    <div class="row justify-content-center">
      <b-form-group  v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="filters.channel_id"
          :options="channels"
          :aria-describedby="ariaDescribedby"
          name="radios-btn-default"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
  import BookmarkNameComponent from "src/app-components/form-elements/BookmarkName";
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";
  import { BFormRadioGroup, BFormGroup } from 'bootstrap-vue';
  import StatusFormComponent from "src/app-components/form-elements/Status";
  import api from "src/api";
  
  export default {
    name: "filters2",
    components: {
      BookmarkName: BookmarkNameComponent,
      BFormGroup,
      BFormRadioGroup,
      StatusFormComponent,

    },

    data() {
      return {
        channels: [],
      }
    },

    async created() {
      this.getChannels();
    },

    props: {
      defaultValues: Object,
    },

    mixins: [
      FilterPanelMixin((self) => ({
        name: null,
        channel_id: null,
        status: self.defaultValues['PerfText.status_id'],
      }))
    ],

    watch: {
      defaultValues: {
        handler () {
          this.filters.name = null;
          this.filters.channel_id = null;
          this.filters.status = this.defaultValues['PerfText.status_id'];
        },
        //immediate: true,
      }
    },

    methods: {
      prepareFilters (filters) {
        return {
          "Bookmark.name": this.fixCleared(this.filters.name),
          "channel_id": this.fixCleared(this.filters.channel_id),
          "PerfText.status_id": this.fixCleared(this.filters.status),
        };
      },
      async getChannels() {
        const channels = await api.getScenarioChannels();
        if ( channels.data.length > 1) {
          this.channels = channels.data.map(function (channel) {
              return {value:channel.id, text:channel.name}
            }  
          );
          this.channels.unshift({ value: 0,     text: "All" });
        }
      }
    },
  };
</script>
<style scoped>
</style>