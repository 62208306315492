<template>
  <div>
    <emoji-picker @emoji="insert">
      <div
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        @click.stop="clickEvent"
      >
        <el-button class="py-1 px-2 add-icon" size="mini"
          ><i class="fa fa-smile-o"></i
        ></el-button>
      </div>
      <div
        slot="emoji-picker"
        slot-scope="{ emojis, insert, display }"
        class="emoji-picker"
      >
        <el-button-group
          class="w-100 d-flex justify-content-between align-items-center"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="btn.tooltip"
            placement="top"
            v-for="btn in categories"
            :key="btn.tooltip"
          >
            <el-button
              class="py-1 w-100 btn-category"
              size="mini"
              :icon="`fa ${btn.icon}`"
              :type="btn.tooltip === viewCategory ? 'primary' : ''"
              @click="setViewCaregory(btn.tooltip)"
              
            ></el-button>
          </el-tooltip>
        </el-button-group>

        <div
          v-for="(emojiGroup, category) in emojis"
          :key="category"
          v-show="category === viewCategory"
        >
          <div class="p-2 icon-list">
            <span
              class="pointer"
              v-for="(emoji, emojiName) in emojiGroup"
              :key="emojiName"
              @click="insert(emoji)"
              :title="emojiName"
              >{{ emoji }}</span
            >
          </div>
        </div>
      </div>
    </emoji-picker>
  </div>
</template>

<script>
  import EmojiPicker from "vue-emoji-picker";
  import { Button, ButtonGroup } from "element-ui";
  export default {
    name: "PickerEmoji",

    components: {
      EmojiPicker,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
    },

    data() {
      return {
        viewCategory: "People",
      };
    },

    methods: {
      insert(emoji) {
        this.$emit("isertEmoji", emoji);
      },

      setViewCaregory(category) {
        this.viewCategory = category;
      },
    },

    computed: {
      categories() {
        return [
          {
            icon: "fa-smile-o",
            tooltip: "People",
          },
          {
            icon: "fa-leaf",
            tooltip: "Nature",
          },
          {
            icon: "fa-camera",
            tooltip: "Objects",
          },
          {
            icon: "fa-building",
            tooltip: "Places",
          },
          {
            icon: "fa-code",
            tooltip: "Symbols",
          },
        ];
      },
    },
  };
</script>

<style scoped>
  .emoji-picker {
    position: fixed;
    width: 250px;
    height: 250px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 15;
  }

  .emoji-picker .icon-list {
    height: 227px;
    overflow-y: scroll;
  }

  .emoji-picker .icon-list::-webkit-scrollbar {
    width: 8px;
  }

  .emoji-picker .icon-list::-webkit-scrollbar-track {
    background: #fff;
  }

  .emoji-picker .icon-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  .wrapperCanvas.dark .add-icon {
    background: #909399;
    border-color: #909399;
    color: #fff;
  }

  .wrapperCanvas.dark .emoji-picker {
    background: #555;
    border: 1px solid #555;
  }

  .wrapperCanvas.dark .emoji-picker .icon-list::-webkit-scrollbar-track {
    background: #555;
  }
  
  .wrapperCanvas.dark .emoji-picker .btn-category {
    background: #555;
    border-color: #ccc;
    color: white;
  }
  
  .wrapperCanvas.dark .emoji-picker .btn-category.el-button--primary {
    background: #409EFF;
    border-color: #409EFF;
  }

  
</style>
<style>
.add-icon {
  outline: 0!important;
}
</style>
