<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "TimBrasilByName",

    extends: Report,

    created() {
        this.tableCols = [
            { prop: "CampaignStartDate", label: "Date" },
            { prop: "CampaignStartTime", label: "Time" },
            { prop: "CampaignGroup", label: "ID" },
            { prop: "Campaign", label: "Campaign" },
            { prop: "Category", label: "Category" },
            { prop: "Subcategory", label: "Subcategory" },
            { prop: "KW", label: "KW" },
            { prop: "Shortcode", label: "Shortcode", formatter: this.formatShortcode, isHtml: true },
            { prop: "ScenarioType", label: "Scenario Type" },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent_Ping", label: "Sent Ping", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives_Rate", label: "%Active", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", minWidth: 400, formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Blacklisted", label: "Blacklisted", formatter: this.formatterNumber,  minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Requester", label: "Requester" },
        ];
        this.customTableKeyCols = [
        ];
    },
    
    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "CampaignGroup";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
            ]
        },

        async getData() {
            const query = {
                from: (this.filters["campaign_start_datetime >="] || "").substr(0, 10),
                to: (this.filters["campaign_start_datetime <="] || "").substr(0, 10),
            };
            this.$emit("set-up-filters", {
                showGroupBySelect: false
            });

            //delete query.ql.where;
            const response = await api.getTimBrasilByNameReport(query);
            const data = response.data.map(e => ({
                ...e,
                CampaignGroup: (e.CampaignGroup ? e.CampaignGroup.toString() : "")
                
            }));

            data.push(this.rollup(data));

            this.tableData = data;

            return {
                isTree: false
            }
        },

        formatShortcode(row, column, cellValue, index) {
            if(cellValue && cellValue.match(/^https?:\/\/.+/i)){
                return `<a href="${cellValue}">${cellValue}</a>`;

            }

            return cellValue;
        },

        tableRowClassName({row, rowIndex}) {
            if(!row.CampaignGroup){
                return 'success-row';
            }  
            
            return '';
        },
    }
}
</script>