<template>
  <div v-if="conversation">
    <Conversation
      :conversation="conversation"
      :testMode="testMode"
      @goBack="goToConversations"
      @newMessage="newMessage($event)"
    />
  </div>
</template>

<script>
  import api from "src/api";
  import { Button, Tooltip } from "element-ui";
  import { mapMutations } from "vuex";
  import FloatingActionButton from "src/app-components/FloatingActionButton";
  import Conversation from "src/app-components/chatbot/conversations/Conversation";
  export default {
    name: "ConversationTest",

    components: {
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      FloatingActionButton,
      Conversation,
    },

    data() {
      return {
        conversation: null,
        testMode: true,
      };
    },

    created() {
      this.setTopNavbarTitle("Conversation Test");
    },

    mounted() {
      this.getConversationsInfo();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getConversationsInfo() {
        let res = await api.getConversationsInfo("2a743e82-53f3-49a9-99cb-0974bbcf7569");
        if (res.status) {
          if(!res.data.length) {
            this.$router.push({ name: "ConversationsIndex" });
            return;
          }

          console.log(res.data.length)
          this.conversation = res.data[0];

          let text = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam deleniti illo, maxime soluta quas dicta et sit odit recusandae aliquam obcaecati architecto sed vitae pariatur a itaque porro sequi fuga?";
          
          this.conversation.messages.push({
            destination: "HUMAN",
            message: null,
            messageId: "3C6BE187-3A34-48BC-894F-E1B2AF54464",
            origin: "BOT",
            richcard: {
              img: {
                src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHx43LWOb5VZ5SlokcDaCUiw-CCmumC0cfNA&usqp=CAU",
                alt: "Bob Esponja",
              },
              title: "Lorem ipsum dolor",
              text,
              suggestions: [
                {
                  label: "Soy una sugerencia",
                },
              ],
            },
            timestamp: {
              $date: {
                $numberLong: "1671548979918",
              },
            },
          });

          this.conversation.messages.push({
            destination: "HUMAN",
            message: null,
            messageId: "3C6BE187-3A34-48BC-894F-E1B2AF54484",
            origin: "BOT",
            carousel: [
              {
                img: {
                  src: "https://s.yimg.com/ny/api/res/1.2/EQLx7DEsMIWQS.0iHMrPsQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTc5NDtoPTU3MA--/https://media.zenfs.com/en/homerun/feed_manager_auto_publish_494/525a8d395e657bee03633515e442bfba",
                  alt: "Bob Esponja",
                },
                title: "Lorem ipsum dolor",
                text,
                suggestions: [
                  {
                    label: "Soy una sugerencia",
                  },
                ],
              },
              {
                img: {
                  src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8tAheyjd9O9Ywyu_0M5pdj0IOrlMwuwAKmSG1A2l_61UEuVywzP_MA_K8f0abRfDXeo4&usqp=CAU",
                  alt: "Bob Esponja",
                },

                title: "Lorem ipsum dolor",
                text,

                suggestions: [
                  {
                    label: "Sugerencia 1",
                  },
                  {
                    label: "Sugerencia 2",
                  },
                  {
                    label: "Link redirect",
                    href: "https://www.google.com/",
                  },
                ],
              },
            ],
            timestamp: {
              $date: {
                $numberLong: "1671548979918",
              },
            },
          });
        }else {
          this.$router.push({ name: "ConversationsIndex" });
        }
      },

      goToConversations() {
        this.$router.push({ name: "ConversationsIndex" });
      },

      newMessage(message) {
        // console.log(message);
        this.conversation.messages.push(message);
      },
    },
  };
</script>
