<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fg-input :label="$t('Event ID')"
                          :value="local"
                          :name="name"
                          @input="$emit('input', $event)"
                          v-validate="{ required: required, uniqueEventName: isUnique, regex: /^[^\s]*$/ }"
                          :error="getError(name)"
                          type="text"
                          :disabled="disabled" />
            </div>
        </div>
    </div>
</template>

<script>
import api from "src/api";

export default {
    inject: ["getError", "parentValidator"],

    props: {
        value: null,

        isUnique: {
            type: Boolean,
            default: true
        },

        required: {
            type: Boolean,
            default: true
        },

        name: {
            type: String,
            default: "event_name"
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data(){
        return {
            validationError: null,
            timer: {}
        }
    },

    created() {
        this.$validator = this.parentValidator;

        this.$validator.extend("uniqueEventName", {
            validate: (value) => (new Promise((resolve, reject) => {

                if(this.timer.instance){
                    clearTimeout(this.timer.instance);
                }

                //retrasamos la llamada a la API por 1 segundo
                this.timer.instance = setTimeout(() => {
                    api.validateEventName({ name: value }).then((response) => {
                        resolve({
                            valid: response.status,
                            data: {
                                message: (
                                    response.errors ?
                                    response.errors[Object.keys(response.errors).shift()] :
                                    ""
                                )
                            }
                        });
                    });
                }, 1000);
            })),
            getMessage: (field, params, data) => (data.message)
        });
    },

    computed: {
        local() {
            return this.value ? this.value : null;
        }
    },

}
</script>

<style>

</style>
