/**
 * Returns a new function-wrapper to your function, which gets invoked with a delay.
 * This is called "debounce"
 * @param {*} callback 
 * @param {*} wait 
 * @returns 
 */
export default function debounce(callback, wait = 1000){
    let timer = null;

    return (...args) => {
        clearTimeout(timer);

        return new Promise((resolve, reject) => {
            timer = setTimeout(() => {
                const result = callback(...args);
                if(typeof result.then === "function"){
                    // its a promise! return a promise
                    result.then((val) => {
                        resolve(val);
                    });
                } else {
                    resolve(val);
                }
            }, wait);
        });
    }
}
