<template>
  <div ref="tablediv">
    <el-table
      v-if="!sortedData.ownTemplate"
      :data="sortedData"
      :key="tableRenderId"
      @sort-change="sortChange"
      row-key="Pkey"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        header-align="left"
        :sortable="isSortable(col.prop) ? 'custom' : false"
        :width="
          col.width
            ? col.width
            : fitColumnToContent(
                columnProp(col.prop),
                $t(col.label),
                col.width / 2 < 80 ? col.width / 2 : 90
              )
        "
        align="left"
        :prop="col.prop"
        :label="$t(col.label)"
        v-for="(col, index) in tableCols"
        :key="index"
      >
        <template slot-scope="props">
          <a-counter
            v-if="col.animated"
            :value="props.row[col.prop]"
            :formatter="col.formatter ? col.formatter : null"
          />
          <span v-else-if="!col.isHtml">
            {{ getCellValue(props.row, col) }}
            <!-- {{ getCellValue(props.row, col) | formatNumber}} -->
          </span>
          <span v-else v-html="getCellValue(props.row, col)"></span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import FitColumnToContentMixin from "src/app-components/FitColumnToContentMixin";
import api from "src/api";
import { flow, pick, cloneDeep, groupBy, min } from "lodash";
import { QueryBuilder } from "x-query-builder";
import { Table, TableColumn } from "element-ui";
import AnimatedCounter from "./AnimatedCounter";

export default {
  name: "Report",

  mixins: [FitColumnToContentMixin({ tableDataProp: "filteredData" })],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [AnimatedCounter.name]: AnimatedCounter,
  },

  props: {
    search: { type: String, required: false },
    dimensions: {
      type: Array,
      default: [],
    },
    campaignId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      tableData: [],
      tableCols: [],
      customTableKeyCols: [],
      emitLoading: true,
      sort: {
        prop: null,
        order: null,
      },

      timeOutRefresh: null,
      totalsFromFront: false,
    };
  },

  computed: {
    filteredData() {
      if (this.search !== null && this.search != "") {
        return this.tableData.filter((data) => {
          if (data[this.filterProp()] == null) return true;
          return data[this.filterProp()]
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }

      this.refreshTable();
      return this.tableData;
    },

    sortedData() {
      if(this.totalsFromFront) {
        let resWithTotals = [];
        if (!this.sort.prop){
          resWithTotals = cloneDeep(this.filteredData);
          resWithTotals.push(this.computeTotals(this.filteredData));
          return resWithTotals;
        };

        let sorted = [...this.filteredData].sort(this.compareObjectsField);
        // return this.sort.order == "descending" ? sorted : sorted.reverse();
        
        resWithTotals = cloneDeep(sorted);
        resWithTotals.push(this.computeTotals(sorted));
        return this.sort.order == "descending" ? resWithTotals : resWithTotals.reverse();
      }


      if (!this.sort.prop) return this.filteredData;
      const sorted = [...this.filteredData].sort(this.compareObjectsField);

      return this.sort.order == "descending" ? sorted : sorted.reverse();
    },

    tableKeyCols() {
      return [
        ...this.dimensions.map((e) => ({ prop: e.groupingProp, label: e.groupingLabel })),
        ...this.customTableKeyCols,
      ];
    },
  },

  watch: {
    search: {
      immediate: true,
      async handler() {
        clearTimeout(this.timeOutRefresh);
        this.timeOutRefresh = setTimeout(() => {
          this.refreshTable();
        }, 250);
        if(this.$refs.tablediv) {
          this.$refs.tablediv.style.minHeight = "0px";
        }
      },
    },
    
    dimensions: {
      immediate: true,
      async handler() {
        await this.getDataChild();
        this.emitReportLoaded();
      },
    },

    filters: {
      immediate: true,
      deep: true,
      async handler() {
        await this.getDataChild();
        this.emitReportLoaded();
      },
    },
  },

  methods: {
    computeTotals(data) {
      if(!data.length) return data;
      let keys = Object.keys(data[0]);
      let totals = {};
      keys.forEach((key) => {
        data.forEach((row) => {
          if(typeof row[key] == "number") {
            totals[key] = totals[key] ? totals[key] + row[key] : row[key];
            return;
          }
          
          if(typeof row[key] == "string" || row[key] === null) {
            totals[key] = "";
            return; 
          };
        });
      });

      totals.Actives_Rate = totals.Sent_Ping > 0 ? totals.Actives / totals.Sent_Ping : 0;
      const dr_base = totals.Sent_Ping > 0 ? min([totals.Actives, totals.Sent, totals.Audience]) : min([totals.Sent, totals.Audience]);
      totals.Delivery_Rate = totals.Delivered / dr_base;
      totals.Acceptance_Rate = totals.Delivered > 0 ? totals.Acceptances / totals.Delivered : 0;
      totals.Conversion_Rate = totals.Sent > 0 ? totals.Acceptances / totals.Sent : 0;
      
      if(totals.Actives_Rate == Infinity) totals.Actives_Rate = 0;
      if(totals.Delivery_Rate == Infinity) totals.Delivery_Rate = 0;
      if(totals.Acceptance_Rate == Infinity) totals.Acceptance_Rate = 0;
      if(totals.Conversion_Rate == Infinity) totals.Conversion_Rate = 0;

      return totals;
    },

    compareObjectsField(a, b) {
      if (Number(a[this.sort.prop]) < Number(b[this.sort.prop])) {
        return -1;
      }
      if (Number(a[this.sort.prop]) > Number(b[this.sort.prop])) {
        return 1;
      }
      return 0;
    },

    sortChange(sort) {
      this.sort = sort;
    },

    columnProp(prop) {
      if (typeof prop === "undefined") {
        return "Campaign";
      }

      return prop;
    },

    isSortable(prop) {
      return [
        "Audience",
        "Actives",
        "Request",
        "Campaign",
        "Delivered",
        "Acceptances",
        "Acceptance_Rate",
        "Conversion_Rate",
        "Revenue",
        "Blacklisted",
        "Delivery_Rate",
        "Actives_Rate",
        "Sent_Ping",
        "Sent",
        "Lb_Open",
        "Lb_Open_Rate",
      ].includes(prop);
    },

    /**
     * Gets a cell content, given the entire row, and the column object to
     * which the cell belongs.
     */
    getCellValue(row, col) {
      const val = col.hasLabel && row.label != undefined ? row.label : row[col.prop];

      if (col.formatter) {
        return col.formatter(row, col, val, null);
      }

      return val;
    },

    formatterNumber(row, column, cellValue, index) {
      return this.$options.filters["formatNumber"](cellValue, 2);
    },

    formatterPercentage(row, column, cellValue, index) {
      return this.$options.filters["formatPercentage"](cellValue, 2);
    },

    formatterPercentageExport(row, column, cellValue, index) {
      return this.$options.filters["formatPercentageExport"](cellValue, 2);
    },

    tableRowClassName({ row, rowIndex }) {
      if (this.tableKeyCols.length > 0) {
        const prop = this.tableKeyCols[0].prop;
        if (row[prop] === null) {
          return "success-row";
        }
      }

      return "";
    },

    getActiveFilters() {
      return Object.keys(this.filters).reduce((newObj, key) => {
        const value = this.filters[key];
        if (value !== null) {
          newObj[key] = value;
        }

        return newObj;
      }, {});
    },

    getQuery() {
      const activeFilters = this.getActiveFilters();
      const where = pick(activeFilters, [
        "campaign_start_datetime >=",
        "campaign_start_datetime <=",
        "account_id IN",
        "channel_id",
        "OR",
        "scenario_id",
      ]);

      const query = flow([
        () => new QueryBuilder(),

        (builder) => {
          return builder.addWhere(where);
        },

        (builder) => {
          const dimensions =
            this.dimensions.length > 0
              ? [...this.dimensions.map((dim) => dim.expr), ...this.tableDimensions()]
              : this.tableDimensions();

          const lastDimension = dimensions.pop();

          if (lastDimension) {
            const last = lastDimension + " WITH ROLLUP";
            return builder.addGroup([...dimensions.concat(last)]);
          }
          return builder;
        },
      ]);

      return query().build;
    },

    async getDataChild() {
      if (this.emitLoading) {
        this.$emit("loading-data", true);
      }

      let scroll = document.querySelector('.el-table__body-wrapper');
      let scrollLeft = null;
      if(scroll) {
        scrollLeft = scroll.scrollLeft;
      }
      if(this.$refs.tablediv) {

        this.$refs.tablediv.style.minHeight = this.$refs.tablediv.clientHeight + 'px';
      }

      const query = this.getQuery();
      const options = await this.getData({ ql: query });
      
      if(scroll) {
        if(scrollLeft) {
          setTimeout(() => {
            scroll = document.querySelector('.el-table__body-wrapper');
            scroll.scrollLeft = scrollLeft;
          }, 0);
        }
      }

      // const dimensions = this.tableKeyCols.map((e) => e.prop);
      const dimensions = this.groupFields ? this.groupFields : this.tableKeyCols.map((e) => e.prop);
      console.log("dimensions", dimensions);

      let isTree = true;
      if (typeof options !== "undefined") {
        if (options.isTree === false) {
          isTree = false;
        }
      }

      if (isTree) this.transformDataToFrontendFormat(dimensions);

      //console.log(JSON.stringify(this.tableData, null, 2));
      this.$emit("loading-data", false);
    },

    transformDataToFrontendFormat(dimensions) {
      console.debug("Generating data tree. Dimensions: ", dimensions);
      this.tableData = this.transformTreeToFrontendFormat(
        this.listToTree(this.tableData, dimensions),
        dimensions
      );
    },

    emitReportLoaded() {
      this.$emit("report-loaded", {
        rows: this.generateExportableReport({
          tableData: this.tableData,
          tableCols: this.tableCols.filter(
            (col) => typeof col.exportable === "undefined" || col.exportable
          ),
          tableKeyCols: this.tableKeyCols.filter(
            (col) => typeof col.exportable === "undefined" || col.exportable
          ),
        }),
        cols: this.tableCols,
        keyCols: this.tableKeyCols,
      });
    },

    generateExportableReport({ tableData, tableCols, tableKeyCols }) {
      const report = tableData.reduce(this.nodeReducer(tableCols, tableKeyCols), []);
      return report;
    },

    nodeReducer(tableCols, tableKeyCols) {
      return (data, e) => {
        if (e.children) {
          return this.parentReducer(tableCols, tableKeyCols)(data, e);
        } else {
          return this.childrenReducer(tableCols, tableKeyCols)(data, e);
        }
      };
    },

    parentReducer(tableCols, tableKeyCols) {
      return (data, e) => {
        const parent = { ...this.formatRow(e, tableCols, tableKeyCols) };
        delete parent.children;
        return [
          ...data,
          parent,
          ...e.children.reduce(this.nodeReducer(tableCols, tableKeyCols), []),
        ];
      };
    },

    childrenReducer(tableCols, tableKeyCols) {
      return (data, e) => {
        return [...data, this.formatRow(e, tableCols, tableKeyCols)];
      };
    },

    formatRow(row, tableCols, tableKeyCols) {
      const result = tableKeyCols.reduce((finalRow, currentProp) => {
        finalRow[currentProp.label] = row[currentProp.prop] || " ";
        return finalRow;
      }, {});
      return tableCols.reduce((finalRow, currentProp) => {
        if (!finalRow[currentProp.label]) {
          finalRow[currentProp.label] = row[currentProp.prop] || 0;
        }
        return finalRow;
      }, result);
    },

    listToTree(list, dimensions) {
      const grouped = groupBy(list, (e) => e[dimensions[0]]);
      return Object.keys(grouped).reduce((reduced, e) => {
        if (dimensions.length == 1) {
          reduced[e] = grouped[e];
        } else {
          reduced[e] = this.listToTree(grouped[e], dimensions.slice(1));
        }
        return reduced;
      }, {});
    },

    transformTreeToFrontendFormat(tree, dimensions, level = 0) {
      const addLabelToItem = (item) => {
        return {
          ...item,
          label: item[dimensions[level]],
        };
      };

      const getGrandTotal = (e) => {
        if (Array.isArray(e)) {
          return e[0];
        } else {
          return getGrandTotal(e.null);
        }
      };

      return Object.keys(tree).reduce((reduced, e) => {
        const current = tree[e];
        if (Array.isArray(current)) {
          reduced.push(...current.map(addLabelToItem));
        } else {
          let newItem = cloneDeep(getGrandTotal(current.null));
          newItem = addLabelToItem(newItem);
          newItem.children = current;
          delete newItem.children.null;
          newItem.children = this.transformTreeToFrontendFormat(
            newItem.children,
            dimensions,
            level + 1
          );
          if (newItem.children.length == 0) delete newItem.children;
          reduced.push(newItem);
        }
        return reduced;
      }, []);
    },

    emitAllowedScenariosFilter(value) {
      this.$emit("allowedScenariosFilter", value);
    },

    /**
     * Rolls-up an array of stats, and return a totalized row
     *
     * @param {Array} data
     * @returns {Object} Totalized row
     */
    rollup(data) {
      const totalRow = data.reduce(
        (reduced, cur) => {
          reduced.Audience += cur.Audience;
          reduced.Sent += cur.Sent;
          reduced.Sent_Ping += cur.Sent_Ping;
          reduced.Actives += cur.Actives;
          reduced.Delivered += cur.Delivered;
          reduced.Acceptances += cur.Acceptances;
          reduced.Blacklisted += cur.Blacklisted;
          console.log(cur);
          return reduced;
        },
        {
          Audience: 0,
          Sent: 0,
          Sent_Ping: 0,
          Actives: 0,
          Delivered: 0,
          Acceptances: 0,
          Blacklisted: 0,
        }
      );

      // Active Rate
      totalRow.Actives_Rate =
        totalRow.Sent_Ping > 0 ? totalRow.Actives / totalRow.Sent_Ping : 0;

      // Delivery Rate
      const dr_base =
        totalRow.Sent_Ping > 0
          ? min([totalRow.Actives, totalRow.Sent, totalRow.Audience])
          : min([totalRow.Sent, totalRow.Audience]);
      totalRow.Delivery_Rate = totalRow.Delivered / dr_base;

      // % Acceptance Rate
      totalRow.Acceptance_Rate =
        totalRow.Delivered > 0 ? totalRow.Acceptances / totalRow.Delivered : 0;

      totalRow.Conversion_Rate =
        totalRow.Sent > 0 ? totalRow.Acceptances / totalRow.Sent : 0;

      return totalRow;
    },
  },
};
</script>

<style>
.el-table__row.success-row {
  background: #fdf5e6 !important;
}
.el-table__row.success-row td {
  font-weight: bold;
  font-size: 110%;
}
.el-table__body,
.el-table__footer,
.el-table__header,
.el-table__empty-block {
  width: 100% !important;
}
</style>
