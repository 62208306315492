<template>
  <div>
    <FormPerformanceCampaign
      :entity="entity"
      :allowSubmit="allowSubmit"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import FormPerformanceCampaign from "src/app-components/FormPerformanceCampaign";
import api from "src/api";
import RetryCrudMixin from "src/app-components/RetryCrudMixin";
import { cloneDeep } from "lodash";

export default {
  components: {
    FormPerformanceCampaign,
  },
  mixins: [RetryCrudMixin()],

  async mounted() {
    if ("id" in this.$route.params) {
      const campaign = await api.getPerformanceCampaign(this.$route.params.id);
      this.entity = campaign.data;

      if (![15, 22].includes(this.entity.status_id)) {
        // Do not edit on ready or draft
        this.allowSubmit = false;
      }
    }
  },

  data() {
    return {
      allowSubmit: true,
      entity: null,
    };
  },

  methods: {
    async onSubmit(data) {
      const cloneData = cloneDeep(data);
      const retries = cloneData.retries;
      delete cloneData.retries;

      cloneData.bookmarks = cloneData.bookmarks.map((bookmark) => {
        return {
          id: bookmark.id,
          name: bookmark.name,
          _joinData: bookmark._joinData
        };
      });

      const res = await api.updatePerformanceCampaign(
        this.$route.params.id,
        cloneData
      );
      if (retries) {
        await this.processOperationLog(res.data.id, retries);
      }
      this.$router.push({ path: `/campaigns/planning` });
    },
  },
};
</script>

<style></style>
