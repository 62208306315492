<template>
  <div class="form-group has-label">
    <label>{{ $t("Cap type") }}</label>
    <el-select
      :value="local.value"
      @input="onInput($event)"
      size="large"
      :name="name"
      :clearable="false"
      v-validate="required ? 'required' : ''"
      :disabled="!isEditable"
      :placeholder="''"
    >
      <el-option
        v-for="(label, key) in options"
        :value="key"
        :label="$t(label)"
        :key="key"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep, tap, set } from "lodash";

export default {
  name: "cap-type",

  props: {
    value: {
      type: Object,
    },
    variables: {
      type: Array,
    },
    name: {
      type: String,
      default: "cap-type",
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => ({ none: "(none)", deliveries: "Deliveries" }),
    },
    vid: {
      type: Number,
      default: 92,
    },
  },

  computed: {
    local() {
      let defaultValue = {
        variable_id: this.vid,
        value: "none",
        variable: {
          name: "cap-type",
        }
      };
      if(!this.variables) return defaultValue;

      let value = this.variables.find(variable => {
        if(variable.variable) {
          return variable.variable.name === "cap-type";
        }
      });
      
      return !value ? defaultValue : value;
    },
  },

  methods: {
    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };
      this.$emit("input", input);
      this.$emit("enabled", value != "none");
    },
  },
};
</script>

<style></style>
