const mixin = {
    provide() {
        return {
          configCreativities: this.configCreativities
        }
    },

    methods: {
        configCreativities() {
            return {
              actions: [
                "ALLOW_EDIT_CREATIVITY",
                "ALLOW_DELETE_CREATIVITY" 
              ]
            }
        }
    },
};

export default mixin;
