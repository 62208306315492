<template>
    <span>{{ formatedValue }}</span>
</template>

<script>
import ticker from "src/ticker";

//const REFRESH_INTERVAL = 6000; //ms
const TICK_INTERVAL = ticker.getInterval();
//const STEPS = REFRESH_INTERVAL / TICK_INTERVAL;

export default {
    name: "a-counter",

    props: {
        value: {
            type: Number,
            default: null
        },

        delay: {
            type: Number,
            default: 6000
        },

        formatter : {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            delayedValue : null,
            delta : 0,
        };
    },

    computed: {

        formatedValue () {
            return (
                this.formatter ?
                this.formatter(this.delayedValue, 2) :
                Math.round(this.delayedValue).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            );
        }
    },

    watch: {
        value(val){
            this.delta = (val - this.delayedValue) / (this.delay / TICK_INTERVAL);
        }
    },

    created(){
        if(this.value !== null){
            this.delta = (this.value - this.delayedValue) / (this.delay / TICK_INTERVAL);
        }
        ticker.listen(this.tick);
    },

    destroyed(){
        ticker.remove(this.tick);
    },

    methods : {
        tick(){
            if(this.delayedValue === null){
                this.delayedValue = this.value;
                this.delta = 0;
            }else if(this.value !== null && this.delta !== 0){
                if(
                    ( this.delta > 0 && (this.delayedValue + this.delta) >= this.value ) ||
                    ( this.delta < 0 && (this.delayedValue + this.delta) <= this.value )
                ){
                    this.delayedValue = this.value;
                    this.delta = 0;
                } else {
                    this.delayedValue += this.delta;
                }
            } 
        }
    }
}
</script>