export default () => {
  return {
    data () {
      return {
        defaultContain: {},
        filters: {},
        filterOpen: false,
      }
    },
    methods: {
      getActiveFilters() {
        let obj = Object.keys(this.filters).reduce((newObj, key) => {
          const value = this.filters[key];
          if (value !== null && value !== undefined) {
            newObj[key] = value;
          }
          return newObj;
        }, {});

        obj['_page'] = this.page;
        obj['_limit'] = this.limit;
        return obj;
      },

      async filterUpdated(filters, callback) {
        this.filters = filters;
        await callback();
      },

      setDefaultContain(defaultContain) {
        this.defaultContain = defaultContain;
      },

      getDefaultContain() {
        return this.defaultContain
      },
    }
  }
}