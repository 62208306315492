<template>
  <div class="row">
    <div class="col-md-12">
      <fg-input
        :value="local"
        type="text"
        :disabled="disabled"
        @input="$emit('input', $event)"
        :name="name"
        :label="$t(label)"
        v-validate="required ? 'required' : null"
        :error="getError(name)"
      ></fg-input>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["getError", "parentValidator"],
  name: "segmentationListName",

  props: {
    value: null,
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "List name"
    },
    name: {
      type: String,
      default: "segmentationListName"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    local() {
      return this.value ? this.value : "";
    }
  },

  data() {
    return {
    };
  },

  methods: {}
};
</script>

<style>
</style>
