<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Subcategory') }}</label>
        <el-select
          size="large"
          :disabled="!categoryId || ! isEditable"
          name="subcategory"
          :filterable="filterable"
          :clearable="clearable"
          v-validate="noValidation ? null : 'required'"
          :value="local"
          @input="$emit('input', $event)"
          :error="getError('category')"
          :placeholder="''"
        >
          <el-option
            v-for="option in subcategories"
            :value="option.id"
            :label="option.name"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>

      <div
        v-show="errors.has('subcategory')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('subcategory') }}</div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  name: "sub-categories",
  inject: ["getError", "parentValidator"],
  props: {
    value: null,
    categoryId: null,
    noValidation: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },

  async created() {
    this.$validator = this.parentValidator;
  },

  data() {
    return {
      subcategories: []
    };
  },

  watch: {
    categoryId() {
      if (this.categoryId !== null) {
        api.getPlatformSubCategories(this.categoryId).then(res => {
          this.subcategories = res.data;
        });
      }
    }
  },

  computed: {
    local() {
      return this.value ? this.value : null;
    }
  }
};
</script>