<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <SegmentationLists v-if="$asyncComputed.lists.success" v-model="lists.data" @delete="handleDelete" />
      </div>
    </div>
    <div class="col-md-6 mt-4 pagination-info" v-if="$asyncComputed.lists.success">
      <p class="category">{{ lists.metadata.total }} entries</p>
    </div>
    <div class="col-md-6 mt-4" v-if="$asyncComputed.lists.success">
      <p-pagination :page-count="lists.metadata.size"
                    class="pull-right"
                    v-model="search.defaultPagination">
      </p-pagination>
    </div>
    <floating-action-button color="#339564" icon="add" @click="handleAdd" />
  </div>
</template>
<script>
  import api from "src/api";
  import SegmentationLists from "src/app-components/table-components/SegmentationLists";
  import FloatingActionButton from "src/app-components/FloatingActionButton";
  import { QueryBuilder } from "x-query-builder";
  import { flow, pick, cloneDeep } from "lodash";
  import Pagination from 'src/components/UIComponents/Pagination'
  import { mapMutations } from "vuex";

  export default {
    components: {
      SegmentationLists,
      FloatingActionButton,
      [Pagination.name]: Pagination
    },

    created() {
      this.setTopNavbarTitle("Segmentation tool");
    },

    asyncComputed: {
      async lists() {
        const pageNumber = this.search.defaultPagination

        const query = flow([
          () => new QueryBuilder(),

          (builder) => {
            return builder.addContain({
              "AccountUserFile": {},
              "CrmListRule": {},
            });
          },

          (builder) => {
            return builder.addPage(pageNumber)
          },

          (builder) => {
            return builder.addLimit(10)
          },

        ]);

        const lists = await api.getSegmentationLists({
          ql: query().build
        });

        const clone = cloneDeep(lists.data).map(item => {
          return {
            id: item.id,
            user_id: item.user_id,
            name: item.name,
            account_user_file_id: item.account_user_file_id,
            status: item.status,
            created: item.created,
            children: item.pipelines,
            file: item.file
          }
        });

        delete lists["data"]
        lists["data"] = clone

        return lists
      }
    },

    data() {
      return {
        search: {
//          filter: {},
          defaultPagination: 1
        }
      }
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      handleDelete(id) {
        this.products = this.products.filter(product => product.id !== id);
      },
      handleAdd () {
        this.$router.push({name: 'FormSegmentationTool'})
      }
    }
  }
</script>