<script>
  import TextMenuTitle from "./TextMenuTitle";

  export default {
    extends: TextMenuTitle,

    data() {
      return {
        maxLengthInitial: 160
      };
    },
  };
</script>