<template>
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<b-form @submit.prevent="onSubmit">
					<div class="row">
						<b-form-group
							id="input-group-1"
							label="Agent's Name:"
							label-for="name"
							class="col-10"
						>
							<b-form-input
								id="name"
								v-model="form.name"
								placeholder="Enter Agent Name"
								v-validate="'required|max:40'"
								data-vv-as="Name"
								name="name"
								type="text"
								maxlength="40"
							></b-form-input>
							<small
								class="text-danger"
								v-if="errors.has('name')"
								>{{ errors.first("name") }}</small
							>
						</b-form-group>

						<b-form-group
							id="color-grp"
							label="Color:"
							label-for="color"
							class="col-2"
						>
							<el-color-picker
								id="color"
								v-model="form.color"
								data-vv-as="color"
								v-validate="'required'"
							></el-color-picker>
						</b-form-group>
					</div>

					<b-form-group
						id="description-grp"
						label="Description:"
						label-for="description"
					>
						<b-form-textarea
							id="description"
							placeholder="Enter description"
							rows="3"
							v-validate="'required|max:160'"
							data-vv-as="Description"
							name="description"
							type="text"
							maxlength="160"
							v-model="form.description"
						></b-form-textarea>
						<small
							class="text-danger"
							v-if="errors.has('description')"
							>{{ errors.first("description") }}</small
						>
					</b-form-group>

					<b-form-group
						id="website-grp"
						label="Website:"
						label-for="website"
					>
						<b-form-input
							id="website"
							v-model="form.website"
							placeholder="www.yourbrand.com"
							name="website"
							data-vv-as="website"
							v-validate="'required|url'"
							maxlength="25"
						></b-form-input>
					</b-form-group>
					<small class="text-danger" v-if="errors.has('website')">
						{{ errors.first("website") }}
					</small>

					<b-form-group
						v-for="(phone, index) in formattedPhones"
						id="phone"
						label="Phone"
						label-for="phone"
						:key="index"
					>
						<b-form-input
							id="phone"
							v-model="phone.value"
							placeholder="+12125551212"
							v-validate="'required|validPhone'"
							:name="`phone${index}`"
						></b-form-input>
						<small
							class="text-danger"
							v-if="errors.has(`phone${index}`)"
							>{{ errors.first(`phone${index}`) }}</small
						>
						<span>
							<i
								class="col-sm-6 fa fa-trash pointer clean"
								@click="removePhone(index)"
								v-show="
									index ||
										(index && formattedPhones.length > 1)
								"
							></i>
							<i
								class="col-sm-6 fa fa-plus pointer clean"
								v-if="formattedPhones.length < 3"
								@click="addPhone(index)"
							></i>
						</span>
					</b-form-group>

					<b-form-group
						id="email-grp"
						label="Email:"
						label-for="email"
					>
						<div>
							<b-form-input
								id="email"
								v-model="form.email"
								placeholder="yourmail@mail.com"
								v-validate="'required|email'"
								name="email"
								data-vv-as="email"
							></b-form-input>
							<small
								class="text-danger"
								v-if="errors.has('email')"
								>{{ errors.first("email") }}</small
							>
						</div>
					</b-form-group>

					<div class="row">
						<b-form-group
							id="logo-grp"
							label="Logo:"
							label-for="logo"
							class="col-12 col-lg-6"
						>
							<div class="custom-file">
								<input
									type="file"
									name="logo"
									class="custom-file-input"
									@change="handleImage($event, 'logo')"
									id="logo"
								/>
								<label class="custom-file-label" for="logo">
									{{ form.logo_name }}
								</label>
							</div>
							<small
								class="text-danger"
								v-if="errors.has('logo')"
							>
								{{ errors.first("logo") }}
							</small>
						</b-form-group>

						<b-form-group
							id="banner-grp"
							label="Banner:"
							label-for="banner"
							class="col-12 col-lg-6"
						>
							<div class="custom-file">
								<input
									type="file"
									name="banner"
									v-validate="'size:200'"
									class="custom-file-input"
									@change="handleImage($event, 'banner')"
									id="banner"
								/>
								<label class="custom-file-label" for="banner">
									{{ form.banner_name }}
								</label>
							</div>
							<small
								class="text-danger"
								v-if="errors.has('banner')"
							>
								{{ errors.first("banner") }}
							</small>
						</b-form-group>
					</div>

					<b-form-group
						id="region-grp"
						label="Region:"
						label-for="region"
					>
						<b-form-select
							v-model="form.region"
							:options="regions"
							name="region"
							v-validate="'required'"
							data-vv-as="region"
						></b-form-select>
						<small
							class="text-danger"
							v-if="errors.has('region')"
							>{{ errors.first("region") }}</small
						>
					</b-form-group>

					<div class="row">
						<b-form-group
							id="activate-grp"
							label="Activation word:"
							label-for="activate"
							class="col-12 col-lg-6"
						>
							<b-form-input
								id="activate"
								v-model="form.activate"
								placeholder="Enter activation phrase"
								v-validate="'required|max:100'"
								data-vv-as="Activate"
								name="activate"
								type="text"
								maxlength="100"
							></b-form-input>
							<small
								class="text-danger"
								v-if="errors.has('activate')"
								>{{ errors.first("activate") }}</small
							>
						</b-form-group>

						<b-form-group
							id="stop-grp"
							label="Stop word:"
							label-for="stop"
							class="col-12 col-lg-6"
						>
							<b-form-input
								id="stop"
								v-model="form.stop"
								placeholder="Enter stopping word"
								v-validate="'required|max:100'"
								data-vv-as="Stop"
								name="stop"
								type="text"
								maxlength="100"
							></b-form-input>
							<small
								class="text-danger"
								v-if="errors.has('stop')"
								>{{ errors.first("stop") }}</small
							>
						</b-form-group>
					</div>

					<b-form-group
						id="message-grp"
						label="Stop message:"
						label-for="stop-message"
					>
						<b-form-textarea
							id="stop-message"
							placeholder="Message"
							rows="3"
							v-validate="'required|max:160'"
							data-vv-as="Message"
							name="stop-message"
							type="text"
							maxlength="160"
							v-model="form.stop_message"
						></b-form-textarea>
						<small
							class="text-danger"
							v-if="errors.has('stop_message')"
							>{{ errors.first("stop_message") }}</small
						>
					</b-form-group>

					<div class="row">
						<b-form-group
							id="privacy-grp"
							label="Privacy policies (URL):"
							label-for="privacy"
							class="col-12 col-lg-6"
						>
							<b-form-input
								id="privacy"
								v-model="form.privacy_policy"
								placeholder="www.yourbrand.com"
								name="privacy"
								data-vv-as="privacy"
								v-validate="'required|url'"
								maxlength="50"
							></b-form-input>
						</b-form-group>
						<small
							class="text-danger"
							v-if="errors.has('privacy_policy')"
						>
							{{ errors.first("privacy_policy") }}
						</small>

						<b-form-group
							id="terms-grp"
							label="Terms of use (URL):"
							label-for="terms"
							class="col-12 col-lg-6"
						>
							<b-form-input
								id="terms"
								v-model="form.terms_of_use"
								placeholder="www.yourbrand.com"
								name="terms"
								data-vv-as="terms"
								v-validate="'required|url'"
								maxlength="50"
							></b-form-input>
						</b-form-group>
						<small
							class="text-danger"
							v-if="errors.has('terms_of_use')"
						>
							{{ errors.first("terms_of_use") }}
						</small>
					</div>

					<b-form-group
						id="json-grp"
						label="Json:"
						label-for="agentJson"
					>
						<div class="custom-file">
							<input
								accept="application/JSON"
								type="file"
								name="agentJson"
								class="custom-file-input"
								id="agentJson"
								@change="handleJson($event)"
								:required="form.rbm_private_key === ''"
							/>
							<label class="custom-file-label" for="json">{{
								json_name
							}}</label>
						</div>
						<small class="text-danger" v-if="jsonError"
							>{{
								$t(
									"Invalid json structure. Please contact support"
								)
							}}
						</small>
						<div
							v-if="this.form.rbm_private_key"
							@click="downloadJson"
						>
							<i class="fa fa-download" aria-hidden="true"></i>
						</div>
					</b-form-group>

					<b-button type="submit" variant="primary">{{
						$t("Submit")
					}}</b-button>
					<b-button v-if="form.Id" type="delete" variant="danger">{{
						$t("Delete")
					}}</b-button>
				</b-form>
			</div>

			<AgentPreview :form="form" :formattedPhones="formattedPhones" />
		</div>
	</div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
	FormPlugin,
	FormGroupPlugin,
	BForm,
	BFormGroup,
	BFormInput,
	BButton,
	BFormTextarea,
	BFormFile,
	BFormSelect,
} from "bootstrap-vue";
import { ColorPicker } from "element-ui";
import AgentPreview from "src/views/agent/agent-preview";
import api from "src/api";
export default {
	provide() {
		return {
			parentValidator: this.$validator,
			getError: this.getError,
		};
	},

	components: {
		FormPlugin,
		FormGroupPlugin,
		BForm,
		BFormGroup,
		BFormInput,
		BButton,
		BFormTextarea,
		BFormFile,
		BFormSelect,
		[ColorPicker.name]: ColorPicker,
		AgentPreview,
	},

	props: {},

	data() {
		return {
			regions: [
				{ value: null, text: "Please select an option" },
				{ value: "us", text: "US" },
				{ value: "europe", text: "Europe" },
				{ value: "asia", text: "Asia" },
			],
			form: {
				name: "My Agent",
				description: "Description of the agent",
				website: "www.mybrand.com",
				email: null,
				phones: [{ value: "" }],
				banner: null,
				logo: null,
				region: null,
				stop: null,
				activate: null,
				banner_path: "Choose Banner..",
				logo_name: "Choose Logo..",
				banner_name: "Choose Banner..",
				rbm_private_key: "",

				color: "#42B3E0",
				terms_of_use: "",
				privacy_policy: "",
				stop_message: "",
			},
			jsonError: false,
			json_name: "Choose Json..",
			formattedPhones: [],
      agentId: null,
		};
	},
	async created() {
		this.$nextTick(async () => {
			const agentId = this.$route.params.id;
			if (agentId) {
        this.agentId = agentId;
				const agent = await api.viewAgent(agentId);
				this.form = agent.data;
				this.formattedPhones = this.form.phones.split(",").map((x) => {
					return { value: x };
				});
				this.json_name = "Rbm Private key";
				this.form.banner_name = "Banner";
				this.form.logo_name = "Logo";
				document.getElementById("card-header").style.backgroundImage =
					"url(" + this.form.banner + ")";
			} else {
				this.addPhone();
			}
		});
		this.$validator.extend(
			"validPhone",
			{
				validate: (value) => {
					return {
						valid: this.validatePhoneForE164(value),
						data: {
							required: true,
							message: "Use the correct format: +12125551212 ",
						},
					};
				},
				getMessage: (field, params, data) => data.message,
			},
			{ computesRequired: true }
		);

		const maxDimensionsRule = {
			getMessage(field, [width, height], data) {
				return (
					(data && data.message) ||
					`The ${field} field must be between ${width} pixels by ${height} pixels. types can be: jpg, jpeg or png`
				);
			},
			validate(files, [width, height]) {
				const validateImage = (file, width, height) => {
					const URL = window.URL || window.webkitURL;
					return new Promise((resolve) => {
						const image = new Image();
						image.onerror = () => resolve({ valid: false });
						image.onload = () =>
							resolve({
								valid:
									image.width <= Number(width) &&
									image.height <= Number(height),
							});

						image.src = URL.createObjectURL(file);
					});
				};
				const list = [];
				for (let i = 0; i < files.length; i++) {
					if (!/\.(jpg|jpeg|png)$/i.test(files[i].name)) {
						return false;
					}
					list.push(files[i]);
				}
				return Promise.all(
					list.map((file) => validateImage(file, width, height))
				);
			},
		};

		this.$validator.extend("maxdimensions", maxDimensionsRule);
	},

	methods: {
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		addPhone() {
			this.formattedPhones.push({
				value: "",
			});
		},
		removePhone(index) {
			this.formattedPhones.splice(index, 1);
		},
		handleImage(event, type) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = (event) => {
				if (type === "logo") {
					this.form.logo = reader.result;
					this.form.logo_name = file.name;
				}
				if (type === "banner") {
					this.form.banner = reader.result;
					this.form.banner_name = file.name;
					document.getElementById(
						"card-header"
					).style.backgroundImage = "url(" + reader.result + ")";
				}
			};

			reader.readAsDataURL(file);
		},
		handleJson(event) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = async (event) => {
				const json = JSON.parse(reader.result);
				this.jsonError = false;
				const mandatoryKeys = [
					"type",
					"project_id",
					"private_key_id",
					"private_key",
					"client_email",
					"client_id",
					"auth_uri",
					"token_uri",
					"auth_provider_x509_cert_url",
					"client_x509_cert_url",
				];
				for (let k in json) {
					if (!mandatoryKeys.includes(k)) {
						this.jsonError = true;
						break;
					}
				}

				if (!this.jsonError) {
					this.$data.json_name = file.name;
					this.form.rbm_private_key = json;
				}
			};

			reader.readAsText(file);
		},
		validatePhoneForE164(phoneNumber) {
			const regEx = /^\+[1-9]\d{10,14}$/;

			return regEx.test(phoneNumber);
		},

		async deleteAgent(id) {
			await api.deleteAgent(id);
		},
		downloadJson() {
			let contentType = "application/json;charset=utf-8;";
			const a = document.createElement("a");
			a.download = this.json_name;
			a.href =
				"data:" +
				contentType +
				"," +
				encodeURIComponent(JSON.stringify(this.form.rbm_private_key));
			a.target = "_blank";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		stringifyPhones() {
			return this.formattedPhones
				.map((p) => {
					return p.value;
				})
				.toString();
		},

		async onSubmit() {
			const valid = await this.$validator.validateAll();
			if (valid) {
				this.form.phones = this.stringifyPhones();

        if(this.agentId) {
          await api.putAgent(this.agentId, this.form);
        }else {
          await api.postAgent(this.form);
        }
				
        this.$router.push({
					name: "AgentHome",
					params: {},
				});
			} else {
				console.log("Not valid?", this.$validator.errors.all());
			}
		},
	},
};
</script>

<style>
.profile-card {
	position: relative;
	overflow: hidden;
	width: 100%;
	text-align: center;
	border: none;
}

.profile-card .card-content {
	width: 100%;
	padding: 15px 25px;
	color: #130a0a;
	background: #ffffff;
	height: 50%;
	border-radius: 0 0 5px 5px;
	position: relative;
	z-index: 9999;
}

.profile-card h2 {
	margin: 0 0 5px;
	font-weight: 600;
	font-size: 25px;
}
.profile-card h2 small {
	display: block;
	font-size: 15px;
	margin-top: 10px;
}

.profile-card i {
	display: inline-block;
	font-size: 16px;
	color: #232323;
	text-align: center;
	border: 1px solid #232323;
	line-height: 30px;
	border-radius: 50%;
	margin: 0 5px;
}
.profile-card .icon-block {
	margin-top: 6%;
	text-align: center;
}
.profile-card .icon-block a {
	text-decoration: none;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.phone {
	margin-top: 2px;
}

.fa-download {
	cursor: pointer;
	color: green;
	padding-left: 1%;
}

.pointer:hover {
	cursor: pointer;
}

.clean {
	border: 0 !important;
}

.green {
	color: green !important;
}

#card-header {
	background: url("http://lorempixel.com/850/280/nature/4/");
	background-size: cover;
	height: 135px;
}

.profile-avatar {
	position: relative;
	width: 100%;
	max-height: 120px;
	margin-top: -11%;
}

.profile-avatar img {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	height: 120px;
	width: 120px;
}

.el-color-picker__panel {
    z-index: 9999!important;
}
</style>