<template>
  <div
    class="row align-items-end my-2"
    :class="[errors.any(scope) ? 'col-with-errors' : '']"
  >
    <div class="text-left col">
      <label>{{ $t("Text") }}</label>
      <fg-input :error="$t(errors.first('text', scope))">
        <el-input
          size="mini"
          v-model="action.text"
          name="text"
          :data-vv-scope="scope"
          v-validate="'required'"
          :maxlength="25"
          show-word-limit
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col">
      <label>{{ $t("URL") }}</label>
      <fg-input :error="$t(errors.first('url', scope))">
        <el-input
          size="mini"
          v-model="action.openUrlAction.url"
          name="url"
          :data-vv-scope="scope"
          v-validate="{ required: true, urlFormat: true }"
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col-auto">
      <el-button size="mini" type="primary" @click="submit">
        {{ $t("Confirm") }}
      </el-button>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { Input, Button } from "element-ui";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
  },

  data() {
    const scope = `openURL-${uniqid()}`;
    return {
      scope,
      action: {
        text: "",
        postbackData: "",
        openUrlAction: {
          url: "",
        },
      },
    };
  },
  props: {
    editing_prop: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    if (this.editing_prop) {
      if (this.editing_prop.action) {
        this.action = this.editing_prop.action;
      }
    } else {
      this.action = {
        text: "",
        postbackData: "",
        openUrlAction: {
          url: "",
        },
      };
    }
    this.$validator.extend("urlFormat", {
      validate: (str) => {
        const prefix = /(http(s?)):\/\//i;
        const url = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/i;

        const prefix_test = prefix.test(str);
        const url_test = url.test(str);

        return {
          valid: prefix_test && url_test,
          data: {
            message: !prefix_test
              ? "URL must start with 'http://' or 'https://' "
              : "The URL is invalid.",
          },
        };
      },
      getMessage: (field, params, data) => data.message,
    });
  },

  methods: {
    async submit() {
      if (await this.$validator.validate(`${this.scope}.*`)) {
        if (this.editing_prop) {
          this.$emit("edit", { action: this.action });
        } else {
          this.$emit("submit", { action: this.action });
        }
        this.clear();
      }
    },

    clear() {
      this.action = {
        text: "",
        postbackData: "",
        openUrlAction: {
          url: "",
        },
      };
      this.$validator.reset(this.scope);
    },
  },
};
</script>
