<template>
    <div>
        <div class="form-group has-label">
            <label>{{ $t(label) }}</label>
            <el-select size="large" 
                :name="name"
                @input="$emit('input', $event)"
                :error="getError(name)"
                :value="local"
                v-validate="disabled ? null : 'required'"
                :disabled="disabled"
                :placeholder="''">
                <el-option
                    v-for="option in options"
                    :value="option.id"
                    :label="$t(option.name)"
                    :key="option.name">
                </el-option>
            </el-select>
        </div>

        <div v-show="errors.has(name)" class="text-danger invalid-feedback" style="display: block;">
            {{ errors.first(name) }}
        </div>
    </div>    
</template>

<script>
export default {
    inject: ["getError", "parentValidator"],

    name: "CSelect",

    props: {
        value: null,
        name: { type: String, required: true },
        label: { type: String, required: true },
        options: { type: Array },
        disabled: { type: Boolean, default: false },
    },

    computed: {
        local() {
            return this.value ? this.value : null;
        }
    },

    created() {
        this.$validator = this.parentValidator;
    },
}
</script>