<template>
  <div>
    <div class="card segmentation">
      <div class="p-2 d-flex align-items-center">
        <div style="min-width: 300px">
          <el-input
            size="medium"
            :placeholder="$t('Segment name')"
            v-model="segmentation.name"
            :disabled="disabled"
            clearable
            @input="inputValidateName"
            :class="errorName !== '' ? 'border-red' : ''"
          ></el-input>
        </div>

        <p class="mb-0 ml-2 small color-red">{{ $t(errorName) }}</p>
      </div>

      <ul class="p-0 mb-0">
        <template v-for="(pipeline, index) in segmentation.stages">
          <row-segmentation
            :index="index"
            :pipeline="pipeline"
            :segmentationId="segmentation.id"
            :pipelineLength="segmentation.stages.length"
            :services="services"
            :campaigns="campaigns"
            :subcategories="formatSubcategories"
            :rulesSegmentation="rulesSegmentation"
            :disabled="disabled"
            :statusErrors="getStatusErrors(index).status"
            :validateActive="validateActive"
            :activeLoading="activeLoading"
            :includesAudience="includesAudience(index)"
            @dataChange="dataChange($event, index)"
            @valueParamsChange="valueParamsChange($event, index)"
            @deletePipeline="deletePipeline($event)"
            @setPosition="setPosition($event)"
            @setTargetIndex="setTargetIndex($event)"
            @updateTotal="updateTotal($event, index)"
            @runPipeline="runSegmentations"
          />
        </template>
        <li v-if="!disabled">
          <el-button
            style="width: 100%;"
            type="text"
            @click="addPipeline"
            class="font-weight-bold"
            ><i class="fa fa-plus"></i> {{ $t("Add filter") }}</el-button
          >
        </li>
      </ul>
    </div>

    <div class="flex">
      <div>
        <p class="mb-0">{{ $t("Potential users") }}</p>
        <p class="mb-0 h5" v-if="!disabled">
          {{ partialUsers | formatNumber }}
          <!-- {{ partialUsers | formatNumber }} / {{ totalUsers | formatNumber }} -->
        </p>
        <p class="mb-0 h5" v-else>
          {{
            segmentation.last_run_result
              ? segmentation.last_run_result
              : 0 | formatNumber
          }}
        </p>
      </div>

      <div class="mb-1">
        <template v-for="(btn, key) in buttonsActions">
          <p-button
            v-if="btn.vif"
            class="my-0"
            :class="btn.class"
            :type="btn.type"
            style="min-width: 150px;"
            :disabled="btn.disabled"
            @click="btn.action(key !== 0, key === 0)"
            >{{ $t(btn.text) }}</p-button
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import sio from "@/sio";
import api from "src/api";
import { mapMutations } from "vuex";
import { clone } from "lodash";
import { Button, Input, RadioGroup, RadioButton } from "element-ui";
import RowSegmentation from "src/app-components/segmentation/RowSegmentation";
import UploadSegmentation from "src/app-components/segmentation/UploadSegmentation";
export default {
  name: "SegmentationForm",
  components: {
    "row-segmentation": RowSegmentation,
    "upload-segmentation": UploadSegmentation,
    [Button.name]: Button,
    [Input.name]: Input,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },

  data() {
    return {
      initialName: "",
      errorName: "",

      audience: "Audience exist",
      segmentation: {
        name: "",
        stages: [
          {
            action: "ADD",
            type: null,
            params: {},
          },
        ],
      },

      targetIndex: null,

      services: null,
      campaigns: null,
      subcategories: null,
      rulesSegmentation: null,

      filtersCampaigns: {
        target_status_id: 6,
        fecha_desde: "",
        fecha_hasta: "",
      },

      validations: [],
      validateActive: false,

      runTimeout: null,

      activeLoading: false,

      partialUsers: 0,
      totalUsers: 0,
      runSegmentation: false,

      validateNameTO: null,
    };
  },

  created() {
    //Listening to events
    sio.onAny((event, obj) => {
      console.log("socket obj segmentation", obj);
      if (obj.metadata) {
        if (obj.metadata.pipelineId === this.segmentation.id) {
          if(obj.metadata.result) {
            console.log("partialUsers", obj.metadata.result);
            this.partialUsers = obj.metadata.result;
            this.runSegmentation = false;
          }
        }
      }
    });

    if (this.dataView.id) {
      this.getSegmentation(this.dataView.id);
    }

    this.setTopNavbarTitle("Create a segment");

    // Pido las campañas, servicios y categorías
    this.getDataApi();
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    runSegmentations() {
      setTimeout(() => {
        if (!this.hasErrors) {
          this.activeLoading = !this.activeLoading;
          this.runSegmentation = true;
          api.runSegmentations(this.segmentation.id);
          console.log("runSegmentations");

          this.partialUsers = 0;
          this.totalUsers = 0;
        } else {
          this.validateActive = true;
        }
      }, 100);
    },

    async validateNameSegmentation(name) {
      return await api.validateNameSegmentation(name);
    },

    inputValidateName() {
      clearTimeout(this.validateNameTO);
      this.validateNameTO = setTimeout(() => {
        this.validateName();
      }, 500);
    },

    async validateName() {
      if (this.segmentation.name === "") {
        this.errorName = "Name is required";
        return false;
      }

      if (this.segmentation.name !== this.initialName) {
        let { status, errors } = await this.validateNameSegmentation(
          this.segmentation.name
        );
        console.log(status, errors);
        if (!status) {
          this.errorName = errors;
          return false;
        }
      }

      this.errorName = "";
      return true;
    },

    validate(goIndex = false, run = false) {
      clearTimeout(this.runTimeout);
      this.resetValidations();

      this.segmentation.stages.forEach((pipeline, key) => {
        let validation = {
          status: true,
          errors: [],
        };

        if (!pipeline.type) {
          validation.status = false;
          validation.errors.push("type");
        } else {
          let keys = Object.keys(pipeline.params);
          keys.forEach((param) => {
            if (Array.isArray(pipeline.params[param])) {
              if (pipeline.params[param].length === 0) {
                validation.status = false;
                validation.errors.push(param);
              }
            } else if (!pipeline.params[param]) {
              validation.status = false;
              validation.errors.push(param);
            }
          });
        }
        this.validations.push(validation);
      });

      if (run) {
        this.runSegmentations();
        return;
      }

      this.runTimeout = setTimeout(() => {
        this.saveQuery(goIndex);
      }, 10);
    },

    async saveQuery(goIndex = false) {
      this.validateActive = goIndex;
      const { stages, name } = clone(this.segmentation);

      let validateName =
        this.errorName === "" ? await this.validateName() : false;
      if (!this.hasErrors && validateName) {
        let res = await api.updateSegmentation(this.segmentation.id, {
          stages,
          name,
        });

        if (res.status) {
          this.segmentation.stages = res.data.stages;
          if (goIndex) {
            this.$notify({
              title: this.$t("Congratulations!"),
              message: this.$t(
                "You have successfully edited the segmentation."
              ),
              icon: "fa fa-info",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
              duration: 10000,
            });

            this.$router.push({
              name: `SegmentationIndex`,
            });
          }
        }
      }
    },

    resetValidations() {
      this.validations = [];
      this.validateActive = false;
    },

    getDataApi() {
      this.getCampaigns();
      this.getServices();
      this.getSubcategories();
      this.getRulesSegmentation();
    },

    async getCampaigns() {
      try {
        this.setDatesFiltersCampaigns();
        const res = await api.getFilteredCampaigns(this.filtersCampaigns);
        this.campaigns = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getServices() {
      try {
        const res = await api.getSubscriptionServices();
        this.services = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getSubcategories() {
      try {
        const res = await api.getTemplatesSubcategories();
        this.subcategories = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    
    async getRulesSegmentation() {
      try {
        const res = await api.getRulesSegmentation();
        this.rulesSegmentation = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getSegmentation(id) {
      try {
        const res = await api.getSegmentation(id);
        if (res.status) {
          if (this.dataView.type === "copy") {
            this.segmentation.stages = res.data.stages;
          } else {
            this.segmentation = res.data;
            this.initialName = res.data.name;
          }

          if (this.segmentation.running) {
            this.runSegmentation = true;
          }

          if (this.segmentation.stages.length === 0) {
            this.segmentation.stages = [
              {
                action: "ADD",
                type: null,
                params: {},
              },
            ];
          } else {
            this.validate();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    updateTotal(total, index) {
      this.totalUsers = total;

      if (this.segmentation.stages.length <= 1) {
        this.runSegmentation = false;
      }
    },

    setDatesFiltersCampaigns() {
      let fecha_hasta = new Date();
      let fecha_desde = new Date(fecha_hasta.getTime() - 10368000000); // 10368000000 = 4 meses

      this.filtersCampaigns.fecha_hasta = this.formatDate(fecha_hasta);
      this.filtersCampaigns.fecha_desde = this.formatDate(fecha_desde);
    },

    formatDate(date) {
      let [dd, mm, yyyy, hh, mn] = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        date.getHours(),
        date.getMinutes(),
      ];

      dd = dd < 10 ? "0" + dd : dd;
      mm = mm < 10 ? "0" + mm : mm;

      return `${yyyy}-${mm}-${dd} ${hh}:${mn}`;
    },

    setTargetIndex(value) {
      this.targetIndex = value;
    },

    setPosition(position) {
      if (!position) return false;

      this.resetValidations();
      let pipelinesList = clone(this.segmentation.stages);
      let pipeline = clone(pipelinesList[this.targetIndex]);
      pipelinesList.splice(this.targetIndex, 1);
      pipelinesList.splice(position, 0, pipeline);
      this.segmentation.stages = pipelinesList;
    },

    addPipeline() {
      this.segmentation.stages.push({
        action: "FILTER",
        type: null,
        params: {},
      });
      this.validate();
    },

    deletePipeline(key) {
      if (
        this.segmentation.stages.length > 1 &&
        this.segmentation.stages[key]
      ) {
        this.segmentation.stages.splice(key, 1);
        this.validate();
      }
    },

    dataChange($event, index) {
      if (this.segmentation.stages[index]) {
        this.segmentation.stages[index][$event.data] = $event.value;
        if ($event.data === "type") {
          this.segmentation.stages[index].params = {};
        }
      }

      this.validate();
    },

    valueParamsChange($event, index) {
      if (this.segmentation.stages[index]) {
        this.segmentation.stages[index].params[$event.param] = $event.value;
      }

      this.validate();
    },

    getStatusErrors(index) {
      if (this.validations[index]) {
        return this.validations[index];
      }

      return {
        status: true,
        errors: [],
      };
    },

    backIndex() {
      this.$router.push({ name: `SegmentationIndex` });
    },

    includesAudience(index) {
      if(index === 0) return true;
      let item = this.segmentation.stages.findIndex(row => row.type !== "AUDIENCE_FROM_FILE");

      if(item !== -1) {
        if(item < index) {
          return false;
        }
      }
      return true;
    },
  },

  computed: {
    hasErrors() {
      let hasErrors = false;

      this.validations.forEach((row) => {
        if (!row.status) {
          hasErrors = true;
        }
      });

      return hasErrors;
    },

    dataView() {
      let type = "";

      switch (this.$route.name) {
        case "SegmentationInfo":
          type = "info";
          break;
        case "SegmentationEdit":
          type = "edit";
          break;
        case "SegmentationCopy":
          type = "copy";
          break;
      }

      return {
        type,
        id: this.$route.params.id,
      };
    },

    disabled() {
      return this.dataView.type === "info";
    },

    buttonsActions() {
      return [
        {
          text: "Run segmentation",
          class: "mr-2",
          action: this.validate,
          type: "success",
          disabled: this.runSegmentation,
          vif: !this.disabled,
        },
        {
          text: "Cancel",
          class: "mr-2",
          action: this.backIndex,
          type: "",
          disabled: false,
          vif: true,
        },
        {
          text: "Save",
          class: "",
          action: this.validate,
          type: "info",
          disabled: false,
          vif: !this.disabled,
        },
      ];
    },

    formatSubcategories() {
      if (!this.subcategories) return this.subcategories;

      return this.subcategories.map((subcategory) => {
        let res = {
          id: subcategory.id,
          name: subcategory.name,
        };

        if (subcategory.category) {
          res.name += ` (${subcategory.category.name})`;
        }

        return res;
      });
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* justify-content: flex-end; */
}
.segmentation {
  overflow: hidden;
}

.color-red {
  color: #e24a5a;
}
</style>

<style>
.segmentation ul li {
  list-style: none;
}

.segmentation .el-input__inner:disabled {
  background: white !important;
  color: #606266;
}

.segmentation button {
  outline: none;
}

.border-red > * {
  border-color: #e24a5a !important;
}

.segmentation label {
  margin-bottom: 0;
}
</style>
