import { clone } from "lodash";
import { EventBus } from "src/eventBus.js";
import { marked } from "marked";
import TitleBox from "src/app-components/chatbot/elements/TitleBox";
import CreateElementPopover from "src/app-components/chatbot/elements/CreateElementPopover";
const ChatbotComponentsMixin = {
  components: {
    "title-box": TitleBox,
    "create-element-popover": CreateElementPopover,
  },

  data() {
    return {
      dataNode: {
        connections: [],
        name: "",
        root: false,
      },

      numberNodeID: null,
    };
  },

  created() {
    this.dataWatch.forEach((w) => {
      this.$watch(w, () => {
        this.updateData();
      });
    });

    if (this.dataNode.options) {
      if (this.dataNode.options[0]) {
        if (this.dataNode.options[0].id === null) {
          this.dataNode.options[0].id = Date.now();
          this.updateData();
        }
      }
    }
  },

  mounted() {
    setTimeout(() => {
      if(!this.dataNode.name) {
        this.dataNode.name = `Node ${this.nodeID.slice(5)}`;
      }
      this.numberNodeID = this.nodeID.slice(5);
      this.updateData();
    }, 1);

    EventBus.$on("updateDataComponent", ({ id, data }) => {
      if (this.nodeID === `node-${id}`) {
        this.dataNode = { ...data.data };
      }
    });
    
    EventBus.$on("mouseoverPopover", (id) => {
      if(id == this.numberNodeID) {
        this.statusSelected();
      }
    });
    
    EventBus.$on("mouseoutPopover", (id) => {
      if(id == this.numberNodeID) {
        this.statusSelected(false);
      }
    });
  },

  watch: {
    viewFallbackNode() {
      if (!this.dataNode.options) return;

      this.viewFallbackNode ? this.showNodeFallback() : this.hideNodeFallback();
    },
  },

  methods: {
    updateData() {
      console.log("updateData", this.dataNode);
      setTimeout(() => {
        EventBus.$emit("changeNodeData", {
          id: this.nodeID,
          data: this.dataNode,
        });
      }, 1);
    },
    
    setNewName(name) {
      console.log("setNewName", name)
      this.dataNode.name = `${name}`;
      this.updateData();
    },

    textMarked(string) {
      if (!string) return "";

      return marked.parse(string);
    },

    activeOptions() {
      EventBus.$emit("activeOptions", this.numberNodeID);
    },

    showNodeFallback() {
      EventBus.$emit("showNodeFallback", this.nodeID.slice(5));
    },

    hideNodeFallback() {
      EventBus.$emit("hideNodeFallback", this.nodeID.slice(5));
    },

    updateConnectionNodes() {
      EventBus.$emit("updateConnectionNodes", this.nodeID.slice(5));
    },

    statusSelected(set = true) {
      const node = document.querySelector(`#${this.nodeID}`);
      if(node) {
        if(set) {
          node.classList.add("selected");
          return;
        }

        if(node.classList.contains("selected")) {
          node.classList.remove("selected");
        }
      }
    },
  },

  computed: {
    nodeID() {
      return this.$el.parentElement.parentElement.id;
    },

    dataWatch() {
      return [
        // "dataNode",
        "dataNode.connections",
      ];
    },

    firstMessage() {
      if (this.dataNode) {
        if (this.dataNode.messages) {
          if (this.dataNode.messages.length) {
            if (this.dataNode.messages[0].text) {
              return this.dataNode.messages[0];
            }
          }
        }
      }

      return null;
    },

    viewFallbackNode() {
      if (!this.dataNode.options) return false;

      let fallback = this.dataNode.options[this.dataNode.options.length - 1];

      return fallback.message === "";
    },

    outputFallback() {
      if (this.dataNode.options) {
        let output = null;
        let count = 1;
        this.dataNode.options.forEach(opt => {
          if(opt.type === "fallback") {
            output = `output_${count}`;
          }

          if(opt.type !== "call" && opt.type !== "url") count++;
        })

        return output ? output : null;
      }
      return null;
    }
  },
};

export default ChatbotComponentsMixin;
