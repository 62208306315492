<template>
  <div :class="[classContainer, mode ? mode : '']" class="json-editor"></div>
</template>

<script>
  import JSONEditor from "jsoneditor";
  import "jsoneditor/dist/jsoneditor.css";
  export default {
    name: "JsonEditor",
    // props: ["classDiv", "json", "mode"],

    props: {
      classDiv: {
        default: "",
      },
      json: {
        default: null,
      },
      mode: {
        default: "view",
      },
    },

    data() {
      return {
        editor: null,
      };
    },

    mounted() {
      if (this.mode === "view") {
        this.setEditor();
      }else {
        setTimeout(() => {
          if (this.editor === null) {
            this.setEditor();
          }
        }, 250)
      }
    },

    watch: {
      json() {
        console.log("cambie json")
        if (this.editor === null) {
          this.setEditor();
        }
      },
    },

    methods: {
      setEditor() {
        console.log("setEditor")
        const container = document.querySelector(`.${this.classContainer}`);
        const options = {
          mode: "code",
          search: false,
          mainMenuBar: false,
          language: "es",
          onError: (error) => {
            console.error(error);
          },
          onEditable: (node) => {
            if(this.mode !== "code") return false;

            return true;
          },
          onChange: () => {
            try {
              this.$emit("updateJson", this.editor.get());
            } catch (error) {}
          },
        };
        // this.editor = new JSONEditor(container, options);
        this.editor = new JSONEditor(container, options, this.json);

        // this.editor.set(this.json);
      },
    },

    computed: {
      classContainer() {
        return `json-editor${this.classDiv ? this.classDiv : ""}`;
      },
    },

    beforeDestroy() {
      this.editor.destroy();
    },
  };
</script>
<style scoped>
  .json-editor {
    width: 100%;
    height: 350px;
  }
</style>
<style>
  .json-editor .jsoneditor {
    border-color: #dcdfe6;
  }
</style>
