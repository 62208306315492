<template>
    <modal :show="show" headerClasses="justify-content-center" @close="onCancel">
        <h4 slot="header" class="title title-up">{{ $t('Test template') }}</h4>
        <fg-input :error="getError('reason')" :label="$t('Msisdn list')" labelClasses="text-center w-100">
            <textarea name="reason" v-validate="'required|msisdnList'" class="form-control" :placeholder="$t('Enter one or multiple MSISDNs separated by comma or one below the other')" v-model="reason"></textarea>
        </fg-input>
        <template slot="footer">
            <button type="button" class="btn btn-dark" @click="onCancel"><i class="fa fa-ban"></i> {{ $t('Cancel') }}</button>
            <button type="button" class="btn btn-success mr-3" @click="onSave"><i class="fa fa-check"></i> {{ $t('Send') }}</button>
        </template>
    </modal>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";
    import { Validator } from 'vee-validate';
    
    export default {
        name: 'test-product-modal',

        props: {
            show: Boolean,
        },

        components: {
            Modal,
        },

        created () {
            Validator.extend('msisdnList', this.msisdnListValidator);
        },

        data() {
            const self = this;
            return {
                reason: "",
                msisdnListValidator: {
                    getMessage(field, args) {
                        return self.$t(`You can only use digits, plus, comma and newline characters`);
                    },
                    validate(value, params) {
                        const result = /^[0-9,+\n\r]*$/.test(value)
                        return result;
                    }
                }
            }
        },

        watch: {
            show () {
                if (this.show) {
                    this.reason = "";
                    this.$validator.reset()
                }
            }
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            async onSave() {
                const valid = await this.$validator.validateAll()
                const parsedMsisdnList = this.splitMsisdnList();
                if (valid) this.$emit("modal-save", parsedMsisdnList);
            },

            onCancel() {
                this.$emit("modal-cancel");
            },

            splitMsisdnList () {
                return this[this.onField()].split(/[,\n\r]+/).filter(m => m != "");
            },

            onField() {
                return "reason";
            }
        }
    }
</script>

<style scoped>
</style>