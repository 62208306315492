<template>
  <li
    :data-index="index"
    class="p-2 row-segmentation"
    :class="!statusErrors && validateActive ? 'has-errors' : ''"
  >
    <div style="max-width: 105px;" class="mr-2">
      <el-select
        size="large"
        :value="pipeline.action"
        :disabled="disabled"
        @input="handleDataChange($event, 'action')"
        :placeholder="$t('Select Action')"
      >
        <el-option
          v-for="option in optionsActions"
          :value="option.id"
          :label="$t(option.name)"
          :key="option.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="w-50 mr-2">
      <select-group-segmentation
        :action="pipeline.action"
        :disabled="disabled"
        :value="pipeline.type"
        :includesAudience="includesAudience"
        @input="handleDataChange($event, 'type')"
        @valueParamsChange="valueParamsChange($event)"
      />
    </div>

    <!-- PARAMETERS -->
    <div class="w-100 mr-2">
      <div v-if="!resetParams" class="params">
        <template v-if="this.paramsView.add">
          <upload-segmentation
            @updateFileData="updateFileData($event)"
            :value="pipeline.params"
            :disabled="disabled"
            :index="index"
          />
        </template>
        <template v-for="component in paramsComponent">
          <template v-if="component.type === 'select'">
            <select-segmentation
              v-if="component.paramsView"
              :options="component.options"
              :disabled="disabled"
              :param="component.param"
              :placeholder="component.placeholder"
              :value="component.value"
              :multiple="component.multiple"
              :default="component.default"
              @valueParamsChange="valueParamsChange($event)"
            />
          </template>
          <template v-if="component.type === 'input'">
            <input-number-segmentation
              v-if="component.paramsView"
              :param="component.param"
              :placeholder="component.placeholder"
              :disabled="disabled"
              :value="component.value"
              @valueParamsChange="valueParamsChange($event)"
            />
          </template>
        </template>
      </div>
    </div>
    <!-- END PARAMETERS -->

      <!-- style="min-width: 80px;" -->
    <div
      :style="`min-width: ${partialResult && !disabled ? '150' : '80'}px;`"
      class="d-flex align-items-center"
    >
      <el-progress
        v-if="progress > 0"
        type="circle"
        :percentage="progress"
        :width="40"
        :stroke-width="4"
        :color="colors"
      ></el-progress>

      <spinner v-if="relativeProgress !== null && progress <= 0">
        <a-counter
          :delay="5000"
          :value="relativeProgress"
          :formatter="formatRelativeProgress"
        />
      </spinner>

      <p-button
        size="sm"
        icon
        class="mb-2 ml-2"
        @click="deletePipeline(index)"
        :disabled="index === 0"
        v-if="!disabled"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('Delete')"
          placement="top"
        >
          <i class="fa fa-close"></i>
        </el-tooltip>
      </p-button>
      <span v-if="partialResult" class="partial-result">{{ partialResult | formatNumber}}</span> 
    </div>
  </li>
</template>

<script>
import sio from "@/sio";
import { Button, Progress } from "element-ui";
import SelectSegmentation from "src/app-components/segmentation/SelectSegmentation";
import InputNumberSegmentation from "src/app-components/segmentation/InputNumberSegmentation";
import UploadSegmentation from "src/app-components/segmentation/UploadSegmentation";
import SelectGroupSegmentation from "src/app-components/segmentation/SelectGroupSegmentation";
import Spinner from "src/components/UIComponents/Spinner";
import AnimatedCounter from "src/app-components/reports-components/AnimatedCounter";

export default {
  name: "RowSegmentation",
  props: [
    "pipeline",
    "index",
    "pipelineLength",
    "services",
    "campaigns",
    "subcategories",
    "statusErrors",
    "validateActive",
    "disabled",
    "activeLoading",
    "segmentationId",
    "rulesSegmentation",
    "includesAudience",
  ],
  components: {
    [Button.name]: Button,
    [Progress.name]: Progress,
    "select-segmentation": SelectSegmentation,
    "input-number-segmentation": InputNumberSegmentation,
    "upload-segmentation": UploadSegmentation,
    "select-group-segmentation": SelectGroupSegmentation,
    "spinner": Spinner,
    [AnimatedCounter.name]: AnimatedCounter,
  },

  data() {
    return {
      targetPosition: null,
      resetParams: false,
      activeDragButton: false,
      loading: false,
      initQuery: false,
      status: true,
      progress: 0,
      relativeProgress: null,
      colors: [
        { color: "#2BA9CD", percentage: 100 },
        { color: "#44C47D", percentage: 100 },
      ],
      partialResult: null,
    };
  },

  created() {
    /* if(this.disabled) {
      console.log(this.index, this.pipeline);
      if(this.pipeline) {
        if(this.pipeline.pipeline_result) {
          this.partialResult = this.pipeline.pipeline_result;
        }
      }
    } */

    //Listening to events
    sio.onAny((event, obj) => {
      if (obj.metadata) {
        if (obj.metadata.stageId === this.pipeline.id) {
          if (typeof obj.metadata.result === 'number') {
            console.log(`result ${this.index}`, obj.metadata.result);
            this.loading = false;
            this.updatedTotal(obj.metadata.result);
            this.progress = 100;
            this.relativeProgress = null;
          }
          if (obj.metadata.progress) {
            let progress = Math.round(obj.metadata.progress);
            this.progress = progress <= 100 ? progress : 99;
          }
          
          if(obj.metadata.relativeProgress) {
            console.log(`relativeProgress ${this.index}`, obj.metadata.relativeProgress)
            this.relativeProgress = obj.metadata.relativeProgress;
          }
          
          if(obj.metadata.partialResult) {
            console.log(`partialResult ${this.index}`, obj.metadata.partialResult)
            this.partialResult = obj.metadata.partialResult;
          }
        }
      }
    });
  },
  /* created() {
    //Listening to events
    sio.onAny((event, obj) => {
      if (obj.metadata) {
        if (obj.metadata.stageId === this.pipeline.id) {
          this.loading = false;
          // if (obj.metadata.result || obj.metadata.result === 0) {
          if (typeof obj.metadata.result === 'number') {
            console.log(`result ${this.index}`, obj.metadata.result);
            this.updatedTotal(obj.metadata.result);
            this.progress = 100;
            this.relativeProgress = null;
          } else if (obj.metadata.progress) {
            // this.progress = Math.round(obj.metadata.progress);
            let progress = Math.round(obj.metadata.progress);
            console.log(`progress ${this.index}`)
            this.progress = progress <= 100 ? progress : 99;
          }else if(obj.metadata.relativeProgress) {
            this.relativeProgress = obj.metadata.relativeProgress;
            console.log("relativeProgress", obj.metadata.relativeProgress)
          }else if(typeof obj.metadata.partialResult === 'number') {
            console.log("partialResult", obj.metadata.partialResult)
            this.partialResult = obj.metadata.partialResult;
          }
        }
      }
    });
  }, */

  watch: {
    loading() {
      this.initQuery = true;
      if (this.loading) {
        this.progress = 0;
      }
    },

    activeLoading() {
      this.loading = true;
    },

    pipeline() {
      if(this.disabled) {
        console.log(this.index, this.pipeline);
        if(this.pipeline.pipeline_result) {
          this.partialResult = this.pipeline.pipeline_result;
        }
      }
    }
  },

  methods: {
    updatedTotal(result) {
      if (this.pipeline.action === "ADD") {
        this.$emit("updateTotal", result);
      }
    },

    focusDragButton(bool) {
      this.activeDragButton = bool;
    },

    drop() {
      this.$emit("setPosition", this.targetPosition);
    },

    dragstart(e) {
      if (!this.activeDragButton) {
        e.preventDefault();
        return;
      }
      this.$emit("setTargetIndex", parseInt(this.index));
    },

    dragover() {}, // necesario para que funcione el drop

    dragleave(e) {
      let index = e.target.getAttribute("data-index");
      if (index) {
        this.targetPosition = parseInt(index);
      }
    },

    handleDataChange(value, data) {
      if (this.pipeline[data] === value) return;

      this.$emit("dataChange", {
        value,
        data,
      });

      if (data === "type") {
        this.resetParams = true;
      }

      // Reseteo los componentes de los parametros
      setTimeout(() => {
        this.resetParams = false;
      }, 0);
    },

    updateFileData(data) {
      let path = {
        param: "path",
        value: data.path ? data.path : null,
      };

      let name = {
        param: "name",
        value: data.name ? data.name : "",
      };

      this.valueParamsChange(path);
      this.valueParamsChange(name);
      // this.$emit("runPipeline");
    },

    deletePipeline() {
      if(this.index === 0) return;
      this.$emit("deletePipeline", this.index);
    },

    valueParamsChange($event) {
      this.$emit("valueParamsChange", $event);
    },

    formatRelativeProgress(value){
      if(value < 1000){
        return value;
      }
      if(value < 100000){
        return (value / 1000).toFixed(1) + "K";
      }
      if(value < 1000000){ // 100K - 999K
        return (value / 1000).toFixed(0) + "K";
      }
      if(value < 10000000){ // 1M - 10 millones
        return (value / 1000000).toFixed(2) + "M";
      }

      // > 10M
      return (value / 1000000).toFixed(1) + "M";
    },

    capitalize(string) {
      if(!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
  },

  computed: {
    paramsView() {
      let params = {}; // campaignId, count, days, operator, serviceId, quality, activity, affinity, subcategories

      switch (this.pipeline.type) {
        case "AUDIENCE_FROM_FILE":
          params.add = true;
          break;
        case "AUDIENCE_FROM_CAMPAIGN":
        case "ACTIVES_IN_CAMPAIGN":
        case "ACCEPTANCE_IN_CAMPAIGN":
        case "DELIVERIES_IN_CAMPAIGN":
        case "SENT_IN_CAMPAIGN":
        case "SENT_PING_IN_CAMPAIGN":
          params.campaignId = true;
          break;
        case "REJECTED_IN_CAMPAIGN":
          params.campaignIdReject = true;
          break;
        case "ACTIVES_IN_LAST_DAYS":
          params.days = true;
          break;
        case "SUBSCRIBED_TO_SERVICE":
          params.serviceId = true;
          break;
        case "SUBSCRIPTION_COUNT":
        case "DELIVERIES_IN_CURRENT_MONTH":
          params.operator = true;
          params.count = true;
          break;
        case "ACCEPTANCE_IN_LAST_DAYS":
        case "REJECTED_IN_LAST_DAYS":
        case "DELIVERIES_IN_LAST_DAYS":
        case "SENT_IN_LAST_DAYS":
          params.operator = true;
          params.days = true;
          params.count = true;
          break;
        case "USER_QUALITY":
          params.quality = true;
          params.days = true;
          break;
        case "ACTIVITY":
          params.activity = true;
          break;
        case "AFFINITY":
          params.affinity = true;
          params.subcategories = true;
          break;
      }
      return params;
    },

    paramsComponent() {
      return [
        {
          type: "select",
          paramsView: this.paramsView.quality,
          default: null,
          options: this.optionsQuality,
          param: "quality",
          placeholder: "Select the user quality",
          value: this.pipeline.params.quality,
          multiple: false,
        },
        {
          type: "select",
          paramsView: this.paramsView.operator,
          default: ">=",
          options: this.optionsOperator,
          param: "operator",
          placeholder: "Select the operator",
          value: this.pipeline.params.operator,
          multiple: false,
        },
        {
          type: "input",
          paramsView: this.paramsView.count,
          param: "count",
          placeholder: "Select the quantity",
          value: this.pipeline.params.count,
        },
        {
          type: "select",
          paramsView: this.paramsView.activity,
          default: null,
          options: this.optionsActivity,
          param: "activity",
          placeholder: "Select the user activity",
          value: this.pipeline.params.activity,
          multiple: false,
        },
        {
          type: "select",
          paramsView: this.paramsView.affinity,
          default: null,
          options: this.optionsAffinity,
          param: "affinity",
          placeholder: "Select the user affinity",
          value: this.pipeline.params.affinity,
          multiple: false,
        },
        {
          type: "select",
          paramsView: this.paramsView.subcategories,
          default: null,
          options: this.subcategories, //TODO: cambiar por las subcategorias
          param: "subcategories",
          placeholder: "Select subcategory",
          value: this.pipeline.params.subcategories,
          multiple: true,
        },
        {
          type: "select",
          paramsView: this.paramsView.days,
          default: 120,
          options: this.optionsDays,
          param: "days",
          placeholder: "Select the number of days",
          value: this.pipeline.params.days,
          multiple: false,
        },
        {
          type: "select",
          paramsView: this.paramsView.serviceId,
          default: null,
          options: this.services,
          param: "serviceId",
          placeholder: "Select subscription services",
          value: this.pipeline.params.serviceId,
          multiple: true,
        },
        {
          type: "select",
          paramsView: this.paramsView.campaignId,
          default: null,
          options: this.campaigns,
          param: "campaignId",
          placeholder: "Select campaign",
          value: this.pipeline.params.campaignId,
          multiple: true,
        },
        {
          type: "select",
          paramsView: this.paramsView.campaignIdReject,
          default: null,
          options: this.campaigns,
          param: "campaignId",
          placeholder: "Select campaign",
          value: this.pipeline.params.campaignId,
          multiple: false,
        },
      ];
    },

    optionsActions() {
      if (this.index === 0) {
        return [
          {
            id: "ADD",
            name: "Include",
          },
        ];
      }

      return [
        {
          id: "FILTER",
          name: "Exclude",
        },
        {
          id: "LET",
          name: "Select",
        },
        {
          id: "ADD",
          name: "Include",
        },
      ];
    },

    optionsDays() {
      return [
        {
          id: 1,
          name: "1 Day",
        },
        {
          id: 7,
          name: "1 Week",
        },
        {
          id: 14,
          name: "2 Weeks",
        },
        {
          id: 30,
          name: "1 Month",
        },
        {
          id: 60,
          name: "2 Months",
        },
        {
          id: 90,
          name: "3 Months",
        },
        {
          id: 120,
          name: "4 Months",
        },
      ];
    },

    optionsOperator() {
      return [
        {
          id: ">",
          name: "Greater than",
        },
        {
          id: ">=",
          name: "Greater than or equal",
        },
        {
          id: "=",
          name: "Equal",
        },
        {
          id: "<=",
          name: "Less than or equal",
        },
        {
          id: "<",
          name: "Less than",
        },
      ];
    },

    optionsQuality() {
      if(!this.rulesSegmentation) return [];

      let quality = this.rulesSegmentation.filter(
        (segmentation) => segmentation.query_type_id === 2
      );

      return quality.map(el => {
        let name = el.min === null && el.max === null 
          ? el.name 
          : `${el.name} (${el.min}% - ${el.max}%)`;
          
        return {
          id: el.id,
          name: this.capitalize(name),
        };
      });
    },

    optionsActivity() {
      return [
        { name: "High" },
        { name: "Mid" },
        { name: "Low" },
      ];
    },

    optionsAffinity() {
      if(!this.rulesSegmentation) return [];

      let quality = this.rulesSegmentation.filter(
        (segmentation) => segmentation.query_type_id === 1
      );

      return quality.map(el => {
        let name = el.min === null && el.max === null 
          ? el.name 
          : `${el.name} (${el.min}% - ${el.max}%)`;

        return {
          id: el.id,
          name: this.capitalize(name),
        };
      });
    },

    queryStatus() {
      let queryStatus = {
        icon: null,
        style: null,
      };

      if (!this.initQuery) return queryStatus;

      if (this.loading) {
        queryStatus = {
          icon: "el-icon-loading",
          style: "color: black;",
        };
      } else {
        if (this.status) {
          queryStatus = {
            icon: "el-icon-check",
            style: "color: #44C47D;",
          };
        } else {
          queryStatus = {
            icon: "el-icon-close",
            style: "color: red;",
          };
        }
      }

      return queryStatus;
    },
  },
};
</script>

<style>
.row-segmentation .el-progress--circle .el-progress__text {
  font-size: 10px !important;
}

.row-segmentation .opacity-0 {
  opacity: 0;
}

.row-segmentation .partial-result {
  font-size: 12px;
  margin-left: 10px;
}

.row-segmentation .state-query {
  cursor: default;
  font-size: 18px;
}

.row-segmentation .btn-move {
  cursor: move;
  font-size: 18px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: #aeacaa;
}

.row-segmentation .btn-move i:last-child {
  margin-left: -2px;
}

.row-segmentation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background linear 0.2s;
  border-bottom: 1px solid rgb(220, 223, 230);
}

.row-segmentation.has-errors {
  background: rgba(255, 0, 0, 0.3);
}

.row-segmentation .params {
  display: flex;
  align-items: center;
}

.row-segmentation .w-50 {
  max-width: 50%;
}

.row-segmentation .w-100 {
  width: 100%;
}
.row-segmentation .w-50 > *,
.row-segmentation .w-100 > * {
  width: 100%;
}
</style>
