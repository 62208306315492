import { render, staticRenderFns } from "./Pivot.vue?vue&type=template&id=358bca41&scoped=true"
import script from "./Pivot.vue?vue&type=script&lang=js"
export * from "./Pivot.vue?vue&type=script&lang=js"
import style0 from "./Pivot.vue?vue&type=style&index=0&id=358bca41&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358bca41",
  null
  
)

export default component.exports