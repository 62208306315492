<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5>{{ $t("Basic campaign configuration") }}</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <fg-input
          maxlength="60"
          name="campaign_name"
          :label="$t('Campaign name')"
          v-validate="'required'"
          v-model="model.name"
          type="text"
          :disabled="!allowSubmit"
          :error="getError('campaign_name')"
        ></fg-input>
        <label
          style="position: absolute; right: 20px; bottom: -12px"
          :class="{ 'text-danger': campaignNameLength > 60 }"
          >{{ campaignNameLength + " / 60" }}</label
        >
      </div>

      <div class="col-md-2" v-if="$can('SetGapTime', 'PerformanceCampaigns')">
        <GapTime
          v-model="model.gap_time"
          :name="`performance-gap-time`"
          :required="true"
          :min-minutes="20"
        />
          <!-- :disabled="!allowSubmit" -->
        </div>

      <div
        class="col-md-2"
        v-if="$can('SetSampleSize', 'PerformanceCampaigns')"
      >
        <div class="form-group has-label">
          <t-label
            :label="$t('Sample')"
            tooltip="% of the base that the platform will use to measure the KPIs of each template and then apply the optimized strategy over the rest"
          ></t-label>
          <el-select
            size="large"
            placeholder="Single Select"
            v-model="model.percent"
            :disabled="!allowSubmit"
          >
            <el-option
              v-for="option in percents"
              :value="option.value"
              :label="option.label"
              :key="option.label"
              :error="getError('percent')"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="col-md-5">
        <date-programmable
          v-model="dateProgrammed"
          :requiredEndtime="true"
          :disabled="!allowSubmit"
        ></date-programmable>
      </div>
    </div>
    <div class="col-md-12">
      <TabRetry
        @modelUpdated="onRetryModelUpdated"
        :parent-campaign-status="1"
        :parent-campaign="{}"
        :entity="initialRetryData"
        :parentCampaign="initialCampaignData ? initialCampaignData : model"
      />
    </div>

    <div class="col-md-12">
      <TabCapConfiguration
        :parentCampaign="initialCampaignData ? initialCampaignData : model"
        @modelUpdated="onCapModelUpdated"
        @onCapTypeUpdated="onCapTypeUpdated"
      />
    </div>

    <component
      v-if="configurationComponent"
      :is="configurationComponent"
      :bookmarks="bookmarks"
      @updated="onChildUpdated"
    ></component>
  </div>
</template>

<script>
import Label from "src/app-components/Label";
import api from "src/api";
import { cloneDeep } from "lodash";
import { Button } from "src/components/UIComponents";
import AbovePercent from "./Configuration/AbovePercent";
import BestOne from "./Configuration/BestOne";
import FixedShare from "./Configuration/FixedShare";
import DateProgrammable from "src/app-components/form-elements/DateProgrammable";
import { Select, Option } from "element-ui";
//import PerformanceGapTime from "src/app-components/form-elements/PerformanceGapTime";
import GapTime from "src/app-components/form-elements/GapTimeText";
import { defaultMixin } from "src/app-components/FormMixin";
import TabRetry from "src/app-components/form-tabs/TabRetry.vue";
import TabCapConfiguration from "src/app-components/form-tabs/TabCapConfiguration.vue";
import DateProgrammedMixin from "src/app-components/DateProgrammedMixin";
export default {
  props: {
    wzModel: Object,
    allowSubmit: Boolean,
    initialRetryData: {
      default: null,
    },
    initialCampaignData: {
      type: Object,
      default: null,
    },
  },

  $_veeValidate: {
    validator: "new",
  },

  provide() {
    return {
      parentValidator: this.$validator,
      getError: this.getError,
    };
  },

  mixins: [defaultMixin, DateProgrammedMixin("model")],

  components: {
    "t-label": Label,
    GapTime,
    AbovePercent,
    BestOne,
    [Button.name]: Button,
    FixedShare,
    DateProgrammable,
    [Option.name]: Option,
    [Select.name]: Select,
    TabRetry,
    TabCapConfiguration,
  },

  data() {
    return {
      percents: [
        { label: "5 %", value: 5 },
        { label: "10 %", value: 10 },
        { label: "15 %", value: 15 },
        { label: "20 %", value: 20 },
        { label: "25 %", value: 25 },
        { label: "30 %", value: 30 },
        { label: "35 %", value: 35 },
        { label: "40 %", value: 40 },
        { label: "45 %", value: 45 },
        { label: "50 %", value: 50 },
      ],
      configurationComponent: null,
      model: {
        name: null,
        gap_time: {
          "time-gap": {
            variable_id: 84,
            value: "01:00",
          },
        },
        percent: 10,
        manual_start: null,
        end_datetime: null,
        start_datetime: null,
        retries: [],
        bookmarks: [],
      },
    };
  },

  computed: {
    campaignNameLength() {
      return this.model.name ? this.model.name.length : 0;
    },
  },

  watch: {
    wzModel() {
      //console.log("WATCH FOURSTEP");
      this.model.bookmarks = this.wzModel.bookmarks;
      this.model.name = this.wzModel.name;
      this.model.start_datetime = this.wzModel.start_datetime;
      this.model.end_datetime = this.wzModel.end_datetime;
      this.model.manual_start = this.wzModel.manual_start;

      let percent = this.wzModel.variables.find(v => v.variable_id == 83);
      let gap_time = this.wzModel.variables.find(v => v.variable_id == 84);
      let cap_type = this.wzModel.variables.find(v => v.variable_id == 114);
      
      if(percent) { this.model.percent = percent.value }
      if(gap_time) { this.model.gap_time["time-gap"].value = gap_time.value }
      if(cap_type) { this.model.cap_type = cap_type.value }
      
      /* if (this.wzModel.variables) {
        this.model.percent = this.wzModel.variables.find(
          (v) => v.variable_id == 83
        ).value;
        this.model.gap_time["time-gap"].value = this.wzModel.variables.find(
          (v) => v.variable_id == 84
        ).value;
        this.model.cap_type = this.wzModel.variables.find(
          (v) => v.variable_id == 114
        ).value;
      } */
    },

    performanceCampaignType: {
      handler: "loadConfigurationComponent",
    },

    /*
    'model.gap_time': {
      handler () {
        console.log(JSON.stringify(this.model.gap_time, null, 2));
      },
      deep: true
    }
*/
  },

  methods: {
    onChildUpdated(data) {
      this.model = { ...this.model, ...data };
    },
    onRetryModelUpdated($event) {
      this.$set(this.model, "retries", cloneDeep($event));
    },

    onCapModelUpdated($event) {
      this.$set(this.model, "bookmarks", cloneDeep($event));
    },
    
    onCapTypeUpdated($event) {
      this.$set(this.model, "cap_type", cloneDeep($event));
    },

    async loadConfigurationComponent(value) {
      const performanceCampaignType = await api.getPerformaceCampaignType(
        value
      );
      this.configurationComponent =
        performanceCampaignType.data.configuration_component;
    },

    validate() {
      this.$validator.validateAll().then((res) => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
  },
};
</script>
