<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "Campaign",

    extends: Report,

    created() {
      this.setTableCols();
        
        this.customTableKeyCols = [
            { prop: "Campaign", label: "Campaign" },
        ];

        this.emitAllowedScenariosFilter((scenario) => {
            // Not Lauch browser and not ping
            return scenario.scenario.sat_call_to_action_id != 1 && scenario.scenario.sat_call_to_action_id != null;
        });
    },

    watch: {
      tableData() {
        this.setTableCols();
      }
    },
    
    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "Campaign";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
                "campaign_id ASC",
                "second_action_id ASC"
            ]
        },

        /**
         * @Override
         */
        tableRowClassName({row, rowIndex}) {
            if(!row.Campaign) {
                return 'success-row';
            }
            
            return '';
        },

        setTableCols() {
          this.tableCols = [
            { label: "Campaign", hasLabel: true,  minWidth: 250 },
            { prop: "Audience", label: "Audience", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Sent_Ping", label: "Sent Ping", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives", label: "Actives", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Actives_Rate", label: "%Active", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Sent", label: "Sent", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivered", label: "Delivered", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Delivery_Rate", label: "%DLV", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Acceptances", label: "Acceptances", minWidth: 400, formatter: this.formatterNumber, minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Acceptance_Rate", label: "%AR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Conversion_Rate", label: "%CR", formatter: this.formatterPercentage, exportFormatter: Number, excelFormat: excelFormatPercentage },
            { prop: "Blacklisted", label: "Blacklisted", formatter: this.formatterNumber,  minWidth: 120, exportFormatter: Number, excelFormat: excelFormatNumber },
            { prop: "Revenue", label: "Revenue", formatter: this.formatterNumber, exportFormatter: Number, excelFormat: excelFormatNumber },
          ];

          if(this.hasValidAcceptances()) {
            this.tableCols.splice(10, 0, { 
              prop: "Valid_acceptances", 
              label: "Subscribed", 
              formatter: this.formatterNumber, 
              exportFormatter: Number, 
              excelFormat: excelFormatNumber 
            })
            this.tableCols.splice(11, 0, { 
              prop: "Subscription_rate", 
              label: "%SR", 
              formatter: this.formatterPercentage, 
              exportFormatter: Number, 
              excelFormat: excelFormatPercentage 
            })
          }
        },

        hasValidAcceptances() {
          let res = false;
          if(this.tableData) {
            this.tableData.forEach(value => {
              if(value.Valid_acceptances) res = true;
            })
          }

          return res;
        },

        async getData(query) {
            query.ql.where["campaign_type NOT IN"] = ["PING", "TEST", "EVENT"];
            query.ql.where["scenario_id <>"] = 11; // NOT ping
            const response = await api.getCampaignPivotPerformance(query);
            this.tableData = response.data
                //.filter(e => this.filterTypeRollup(e))
                .filter(row => (
                    (row.CampaignId !== null && row.saId !== null) ||
                    (row.CampaignId === null && row.saId === null)
                ))
                .map(row => {
                    if(row.CampaignId === null){
                        row.Campaign = null;
                    }

                    if(row.saId > 0){
                        row.Campaign += " - SA";
                        //parche para SENT's no registrados
                        if(row.Sent < row.Delivered){
                            row.Sent = row.Delivered;
                            row.Delivery_Rate = 1.0;
                        }
                        row.Audience = row.Sent;
                    }

                    row.Subscription_rate = row.Valid_acceptances ? row.Valid_acceptances / row.Delivered : 0

                    return row;
                });
        },

        filterTypeRollup (data) {
            if (this.tableKeyCols.every(tkc => data[tkc.prop] !== null) && data.Type == null) {
                return false
            }
            return true
        },
    }
}
</script>