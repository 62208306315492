<template>
   <div class="form-group has-label">
      <label>{{ label }}</label>
      <el-select :disabled="disabled"
         size="large"
         :name="name"
         :multiple="multiple"
         :value="pValue"
         @input="onChange"
      >
         <el-option v-for="option in options"
            :value="option[keyField]"
            :label="option[textField]"
            :key="option[keyField]"
         ></el-option>
      </el-select>
   </div>
</template>

<script>
export default {
   name: "fg-select",
   components: {
   },
   props: {
      name: String,
      label: String,
      options: Array,
      keyField: {
         type: String,
         default: "id"
      },
      textField: {
         type: String,
         default: "name"
      },
      value: [ Number, Object, Array ],
      disabled: {
         type: Boolean,
         default: false
      },
      multiple: {
         type: Boolean,
         default: false
      },
   },
   data(){
      return {
         pValue: null
      };
   },
   watch: {
      value: function(newVal, oldVal){
         console.debug("fg-select value changed: ", newVal);
         if(Array.isArray(newVal)){
            this.pValue = newVal.map(val => {
               if(typeof val === 'object' && val !== null){
                  return val[this.keyField];
               }

               return val;
            });
            
         } else if(typeof newVal === 'object' && newVal !== null){
            this.pValue = newVal[this.keyField];
         } else {
            this.pValue = newVal;
         }
         console.debug("new pValue: ", this.pValue);
      }
   },
   methods: {
      onChange(val) {
         let emitData = null;
         if(Array.isArray(val)){
            emitData = val.map(
               key => this.options.find(
                  el => el[this.keyField] === key
               )
            );
         } else {
            emitData = this.options.find(el => el[this.keyField] === val);
         }
         this.$emit("input", emitData);
      }
   },
}
</script>

<style scoped>

</style>
