<template>
  <div
    class="row align-items-end my-2"
    :class="[errors.any(scope) ? 'col-with-errors' : '']"
  >
    <div class="text-left col-6 col-md-4">
      <label>{{ $t("Text") }}</label>
      <fg-input :error="errors.first('text', scope)">
        <el-input
          size="mini"
          v-model="action.text"
          name="text"
          :data-vv-scope="scope"
          v-validate="'required'"
          :maxlength="25"
          show-word-limit
        ></el-input>
      </fg-input>
    </div>
    <div class="text-left col-6 col-md-4">
      <label>{{ $t("Map pin Label") }}</label>
      <fg-input :error="''">
        <el-input size="mini" v-model="action.viewLocationAction.label"></el-input>
      </fg-input>
    </div>
    <div class="col-12 col-md-4">
      <el-switch
        v-model="queryMode"
        active-color="#13ce66"
        inactive-color="#17a2b8"
        active-text="Query"
        inactive-text="Coordinates"
      >
      </el-switch>
    </div>
    <div class="text-left col col-md" v-show="queryMode">
      <label>{{ $t("Query") }}</label>
      <fg-input :error="errors.first('query', scope)">
        <el-input
          size="mini"
          v-model="action.viewLocationAction.query"
          name="query"
          :data-vv-scope="scope"
          v-validate="{ required: queryMode }"
        ></el-input>
      </fg-input>
    </div>
    <div class="text-left col col-md" v-show="!queryMode">
      <label>{{ $t("Latitude") }}</label>
      <fg-input :error="errors.first('latitude', scope)">
        <el-input
          size="mini"
          v-model="action.viewLocationAction.latLong.latitude"
          name="latitude"
          :data-vv-scope="scope"
          v-validate="{ required: !queryMode, decimal: true, between: ['-90.0', '90.0'] }"
        ></el-input>
      </fg-input>
    </div>
    <div class="text-left col col-md" v-show="!queryMode">
      <label>{{ $t("Longitude") }}</label>
      <fg-input :error="errors.first('longitude', scope)">
        <el-input
          size="mini"
          v-model="action.viewLocationAction.latLong.longitude"
          name="longitude"
          :data-vv-scope="scope"
          v-validate="{
            required: !queryMode,
            decimal: true,
            between: ['-180.0', '180.0'],
          }"
        ></el-input>
      </fg-input>
    </div>
    <div class="text-left col-auto">
      <el-button size="mini" type="primary" @click="submit">{{
        $t("Confirm")
      }}</el-button>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { Input, Button, Switch } from "element-ui";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    [Switch.name]: Switch,
  },
  props: {
    editing_prop: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const scope = `location-${uniqid()}`;
    return {
      scope,
      queryMode: false,
      action: {
        text: "",
        postbackData: "",
        viewLocationAction: {
          latLong: {
            latitude: null,
            longitude: null,
          },
          label: "",
          query: "",
        },
      },
    };
  },

  created() {
    if (this.editing_prop) {
      if (this.editing_prop.action) {
        this.action = this.editing_prop.action;
        if (this.action.viewLocationAction.query) {
          this.queryMode = true;
          this.action.viewLocationAction.latLong = {
            latitude: null,
            longitude: null,
          };
        }
      }
    } else {
      this.action = {
        text: "",
        postbackData: "",
        viewLocationAction: {
          latLong: {
            latitude: null,
            longitude: null,
          },
          label: "",
          query: "",
        },
      };
    }
  },

  methods: {
    async submit() {
      if (await this.$validator.validate(`${this.scope}.*`)) {
        if (this.queryMode) {
          delete this.action.viewLocationAction.latLong;
        } else {
          delete this.action.viewLocationAction.query;
        }
        if (this.editing_prop) {
          this.$emit("edit", { action: this.action });
        } else {
          this.$emit("submit", { action: this.action });
        }
        this.clear();
      }
    },

    clear() {
      this.action = {
        text: "",
        postbackData: "",
        viewLocationAction: {
          latLong: {
            latitude: null,
            longitude: null,
          },
          label: "",
          query: "",
        },
      };
      this.$validator.reset(this.scope);
    },
  },
};
</script>
