<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <fg-input
          :ref="name"
          :label="$t(this.label)"
          :value="local"
          :name="name"
          @input="emitData"
          :placeholder="$t(this.placeholder)"
          :error="getError(name)"
          :disabled="!isEditable"
          type="text"
          v-validate="{ required: required }"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ["getError", "parentValidator"],

    props: {
      label: {
        default: "",
      },
      placeholder: {
        default: false,
      },

      value: null,
      variable_id: null,
      name: null,

      required: {
        type: Boolean,
        default: true,
      },

      isEditable: {
        type: Boolean,
        default: true,
      },
    },

    created() {
      this.$validator = this.parentValidator;
    },

    methods: {
      emitData(value) {
        this.$emit('input', {
          variable_id: this.variable_id,
          value
        })
      },
    },
    
    computed: {
      local() {
        return this.value ? this.value.value : null;
      },
    },
  };
</script>
