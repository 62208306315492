<template>
  <div v-if="messages" class="carousel-messages">
    <el-button
      class="float-button"
      :class="btn"
      :icon="`el-icon-${btn}`"
      circle
      size="mini"
      @click="changeItem(btn)"
      v-for="btn in btnDirections"
      :key="btn"
    ></el-button>
    
    <RichcardMessage
      :key="key"
      :message="message"
      @sendNewMessage="sendNewMessage($event)"
      v-for="(message, key) in messages"
      v-show="key === itemSelected"
    />
  </div>
</template>

<script>
  import { Button } from "element-ui";
  import RichcardMessage from "src/app-components/chatbot/conversations/RichcardMessage";
  export default {
    name: "CarouselMessage",
    props: ["messages"],

    components: {
      RichcardMessage,
      [Button.name]: Button,
    },

    data() {
      return {
        itemSelected: 0,
      };
    },

    methods: {
      sendNewMessage(message) {
        this.$emit("sendNewMessage", message);
      },

      changeItem(direction) {
        if(this.messages.length) {
          if(direction === "right") {
            if(this.messages.length === this.itemSelected + 1 || this.itemSelected >= this.messages.length) {
              this.itemSelected = 0;
            }else {
              this.itemSelected++;
            }
          }else {
            if(this.itemSelected <= 0) {
              if(this.messages.length > 0) {
                this.itemSelected = this.messages.length - 1;
              }
            }else {
              this.itemSelected--;
            }
          }
        }
      },
    },

    computed: {
      btnDirections() {
        return ["back", "right"];
      }
    }
  };
</script>

<style scoped>
  .carousel-messages {
    position: relative;
  }
  .float-button {
    position: absolute;
    top: 50%;
  }

  .float-button.back {
    left: -14px;
  }
  .float-button.right {
    right: -14px;
  }
</style>
