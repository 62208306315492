<template>
  <div>
  <Form @submit="onSave">
    <p>{{ $t("Define which MSISDNs are allowed to be targeted for testing purposes (enter one below the other). They will not be affected by any contact rule.") }}</p>
    <fg-input :error="getError(onField())" 
      :label="$t('Msisdn list')" 
      labelClasses="text-center w-100">
        <textarea :name="onField()" 
          rows="8"
          class="form-control" 
          style="max-height:none;"
          v-validate="'required|msisdnList'"
          :placeholder="$t('Enter one or multiple MSISDNs separated by comma or one below the other')" 
          v-model="msisdnList"></textarea>
    </fg-input>
  </Form>
  </div>
</template>

<script>
  import Form from "src/app-components/Form"
  import TestProductModal from "src/app-components/modal-components/TestProductModal"
  import api from "src/api";

  export default {
    extends: TestProductModal,
    components: {
      Form
    },
    provide() {
      return {
          parentValidator: this.$validator,
      };
    },

    created() {
        this.fetchTestingLines();  
    },

    methods: {
      async fetchTestingLines() {
        const res = await api.getAccountTestingLines();
        if(res.status === true && res.data.lines.length > 0) {
          this.msisdnList = res.data.lines.reduce((acc, value) => {
            return acc + value + '\n';
          }, "");
        }
      },

      /**
       * @Override
       */
      async onSave () {
        if(this.msisdnList !== null) {
          const lines = this.formatLines();
          const res = await api.createAccountTestingLines({
            lines: lines
          });
          
          if(res.status === true) {
            this.$notify({
              message: this.$t("Whitelist uploaded succesfully!"),
              type: 'success'
            });
          }
        }
      },

      formatLines() {
        const rows = this.splitMsisdnList();
        return rows;
      },

      /**
       * @Override
       */
      onField() {
        return "msisdnList";
      }
    },
    data () {
      return {
        msisdnList: null
      }
    }
  };
</script>
<style>
</style>