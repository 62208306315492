<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!--
        <div class="card-header">
          <h4 class="card-title">{{ $t(title) }}</h4>
        </div>
        -->
        <div class="card-body">
            <tabbed-form :save-button-disabled="tabbedFormSaveButtonDisabled" :initial-value="initialTab" @submit="onSubmit()" @error-tabs="onErrorTabs($event)" :tabs="getTabs()">
                <tab-pane :title="$t('Configuration')" id="configuration" :hasError="errorTabs.configuration">
                  <div class="row">
                    <div class="col-md-12">
                      <tab-event-config :entity="initialCampaignData"
                        @modelUpdated="onCampaignModelUpdated"
                        @removeVariables="onRemoveVariables"
                        v-bind="campaignProps"
                        :disabled="disabled"
                      />
                    </div>
                  </div>
                </tab-pane>

                <tab-pane :title="$t('Template')" id="bookmark" :hasError="errorTabs.bookmark">
                  <div class="row">
                    <div class="col-md-12">
                      <tab-bookmark :entity="initialBookmarkData"
                        :hide-bookmark-info="hideBookmarkInfo"
                        :for-event="true"
                        :hideCommercialModel="bookmarkProps.hideCommercialModel"
                        :hidePrice="bookmarkProps.hidePrice"
                        :commercialModel="commercialModel"
                        :price="price"
                        :isEditable="bookmarkProps.isEditable"
                        :campaign-started="campaignStarted"
                        @updateCategory="onUpdateCategory"
                        @updateSubCategory="onUpdateSubCategory"
                        @modelUpdated="onBookmarkModelUpdated"
                        @creativesSelected="onCreativesSelected"
                        :validateMinSelectedCreativities="true"
                        :onlyOneCreativeSelected="true"
                        @updateCreativitiesTotal="onUpdateCreativitiesTotal"
                        :allowAddCreative="bookmarkProps.isEditable" />
                    </div>
                  </div>
                </tab-pane>


                <tab-pane v-if="$can('HiddenVariables', 'Campaigns')" :title="$t('Hidden configuration')" id="hiddenConfiguration" :hasError="errorTabs.hiddenConfiguration">
                  <div class="row">
                    <div class="col-md-12">
                      <TabHiddenConfiguration
                        :scenario-id="accountScenarioTypeId"
                        @modelUpdated="onHiddenConfigurationUpdated"
                        :entity="form"
                        :module="module"
                       />
                    </div>
                  </div>
                </tab-pane>
            </tabbed-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";

import { cloneDeep, merge } from 'lodash';

import { defaultMixin } from "src/app-components/FormMixin";
import TabbedFormMixin from 'src/app-components/TabbedFormMixin';
import SecondActionMixin from "src/app-components/SecondActionMixin"


import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
import TabbedForm from 'src/app-components/TabbedForm';
import TabBookmark from "src/app-components/form-tabs/TabBookmark.vue";
import TabEventConfig from "src/app-components/form-tabs/TabApiEventConfiguration.vue";
import TabRetry from "src/app-components/form-tabs/TabRetry.vue";
import TabHiddenConfiguration from "src/app-components/form-tabs/TabHiddenConfiguration.vue";
import TabTargetUploader from "src/app-components/form-tabs/TabTargetUploader.vue";
import SecondActionEnabled from "src/app-components/form-elements/SecondActionEnabled"
import SecondActionConfiguration from "src/app-components/SecondActionConfiguration";

export default {
    mixins: [
        defaultMixin,
        SecondActionMixin("form"),
        TabbedFormMixin
    ],

    provide() {
        return {
            parentValidator: this.$validator,
            getError: this.getError,
            activateCreatives: this.activateCreatives
        };
    },

    components: {
        SecondActionEnabled,
        SecondActionConfiguration,
        [TabPane.name]: TabPane,
        TabbedForm,
        TabBookmark,
        "tab-event-config": TabEventConfig,
        TabTargetUploader,
        TabRetry,
        TabHiddenConfiguration
    },

    props: {
        module:{
          type: String,
          default: "event"
        },

        title: {
          type: String,
          required: false,
          default: "Create a new campaign from scratch"
        },

        initialBookmarkData: {
            type: Object,
            required: false,
            default: () => ({})
        },

        bookmarkProps: {
          type: Object,
          required: false,
          default: () => ({ isEditable: true, hideCommercialModel: false, hidePrice: false })
        },

        initialCampaignData: {
            type: Object,
            required: false
        },

        initialTargetData: {
            type: Array,
            required: false
        },

        initialSActiontData: {
            type: Array,
            required: false,
            default: () => []
        },

        initialRetryData: {
            type: Object,
            required: false
        },

        initialTab: String,

        disabled: {
            type: Boolean,
            default: false
        },
        
        hideBookmarkInfo: {
          type: Boolean,
          default: false
        }
    },

    watch: {
        'form.bookmark.account_scenario_type_id': function(newVal, oldVal){
          this.form.account_scenario_type_id = newVal
        },

        isSaEnabled(newValue) {
          if(newValue === false) {
            this.resetSecondActions();
          }
        },
    },

    computed: {
        selectedChannelIsSat () {
          return this.form.bookmark.channel_id == 1;
        },

        commercialModel() {
          return this.form.commercial_model_id;
        },

        price() {
          return this.form.price;
        },

        hasId() {
            return (typeof this.form.id !== "undefined"); 
        },

        accountScenarioTypeId() {
            return this.form.account_scenario_type_id
        },

        isSaEnabled() {
            if('second-action-enabled' in this.form.variables) {
                return  this.form.variables["second-action-enabled"].value;
            }

            return false;
        },

        campaignProps() {
            const props = {
              ...this.campaignDefaults
            }

            return props;
        },

        campaignStarted () {
          if (!this.initialCampaignData) return false
          return ![22, 15].includes(this.initialCampaignData.status_id)
        }
    },

    data() {
        return {
            retryDefaults: {
                allowConfigureSample: false,
            },

            campaignDefaults: {
                showGapTime: false,
                showAmountUsers: false,
                sizeAmountUsers: 0
            },
            
            form: {
              "status_id": 1,
              "category_id": 1,
              "subcategory_id": 1,
              "name": "",
              "description": "",
              "keyword": "",
              "deleted": 0,
              "started": 0,
              "priority": 0,
              "start_datetime": null,
              "end_datetime": null,
              "account_scenario_type_id": null,
              "templateCreatives": [
                /*
                {
                  "bookmark_id": 8,
                  "bookmark_creative_hash": "sa-rasa"
                }
                */
              ],
              "variables": []
            }
        }
    },

    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },

        activateCreatives() {
          if(typeof this.initialCampaignData !== "undefined" 
            && typeof this.initialCampaignData.templateCreatives !== "undefined"
            && typeof this.initialCampaignData.templateCreatives[0] !== "undefined") {
            return [
              {
                "bookmark_perf_text_hash":this.initialCampaignData.templateCreatives[0].bookmark_creative_hash
              }
            ];
          }

          return [];
        },

        getTabs() {
            return {
                bookmark: false,
                configuration: false,
                hiddenConfiguration: false,
            }
        },

        onRemoveVariables() {
          this.unsetVariables('active-cap');
          this.unsetVariables('cap-period');
          this.unsetVariables('cap-goal');
        },

        unsetVariables(name) {
          let index = this.form.variables.findIndex(v => {
            if(v.variable){
              return v.variable.name == name;
            }
            return false;
          });

          this.form.variables.splice(index, 1);
        },

        onUpdateCategory($event) {
            this.form.category_id = $event
        },

        onUpdateSubCategory($event) {
            this.form.subcategory_id = $event
        },

        onCreativesSelected($event) {
            // Set visible GAP and AMOUNT USERS
            if($event.length > 1) {
                this.$set(this.campaignDefaults, "showGapTime", true)
                this.$set(this.campaignDefaults, "showAmountUsers", true)
                this.$set(this.retryDefaults, "allowConfigureSample", true)
            }else{
                this.$set(this.campaignDefaults, "showGapTime", false)
                this.$set(this.campaignDefaults, "showAmountUsers", false)
                this.$set(this.retryDefaults, "allowConfigureSample", false)
            }

            const perfText = $event.map( e => ({
              "bookmark_id": this.initialBookmarkData.id,
              "bookmark_creative_hash": e
            }));
            this.$set(this.form, "templateCreatives", perfText)
        },

        onBookmarkModelUpdated($event) {
          this.$set(this.form, "bookmark", $event);
        },

        onCampaignModelUpdated($event) {
          const data = this.mergeForm($event);
          this.form = { ...this.form, ...data }
          //console.log(JSON.stringify(this.form, null, 2))
        },

        onRetryModelUpdated($event) {
            this.$set(this.form, "children", cloneDeep($event));
            //console.log(this.form);
        },

        onTargetUpdated($event) {
          this.$set(this.form, "files", $event);
        },

        onHiddenConfigurationUpdated ($event) {
          this.form.variables = merge(this.form.variables, $event);
          //console.log(JSON.stringify($event, null, 2))
        },

        onUpdateTotalValidEntries($event) {
            this.campaignDefaults.sizeAmountUsers = $event;
        },

        onUpdateCreativitiesTotal($event) {},

        onSubmit() {
            const form = cloneDeep(this.form)

            form.variables = Object.keys(form.variables).map(key => {
              return form.variables[key]
            })

            this.$emit("submit", form);
        },
    }
}
</script>

<style>

</style>
