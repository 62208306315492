<template>
    <div>
        <form-segmentation-list @submit="onSubmit"/>
    </div>
</template>
<script>
    import FormSegmentationList from "../../app-components/FormSegmentationList";
    import _ from 'lodash'

    export default {
        components: {
            FormSegmentationList,
        },

        methods: {
            async onSubmit(data) {
                /*
                data = {
                    ...data,
                    rules: data.rules.map(e => _.omit(e, 'id'))
                };
                await api.createSegmentationList(data);
                this.$router.push({ name: `IndexSegmentationTool` });
                */
            }
        }
    }
</script>