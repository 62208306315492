<template>
  <div>
    <tabs pills type="primary" icons centered
      tab-content-classes="tab-space"
      :hideSingleTabNav="true"
    >
      <tab-pane id="campaigns" key="campaigns">
        <span slot="title">
          <i class="nc-icon nc-user-run"></i>
          {{ $t("Campaigns") }}
        </span>
        <CrmCampaigns></CrmCampaigns>
      </tab-pane>

      <tab-pane id="events" key="events" v-if="events.length">
        <span slot="title">
          <i class="fa fa-rss"></i>
          {{ $t("Events") }}
        </span>
        <CrmEvents :events="events"></CrmEvents>
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import api from "src/api";
import { Tabs, TabPane } from "src/components/UIComponents";
import { mapActions } from "vuex";

import CrmCampaigns from "src/app-components/crm/campaigns/CrmCampaigns";
import CrmEvents from "src/app-components/crm/events/CrmEvents";

export default {
  components: {
    Tabs,
    TabPane,
    CrmCampaigns,
    CrmEvents,
  },

  data() {
    return {
      events: [],
    };
  },

  created() {
    this.getEvents();
  },

  async mounted() {
    this.setTopNavbarTitle(this.$t("CRM"));
  },

  methods: {
    ...mapActions(["setTopNavbarTitle"]),

    async getEvents() {
      // Obtiene todos los eventos
      const res = await api.getApiEvents();
      this.events = res.data;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.vertical-btn) {
  border-radius: unset;
}

:deep(td ul) {
  padding-left: 10px;
}

:deep(.badge) {
  font-size: 1em;
}

:deep(.badge + .badge) {
  margin-left: 5px;
}

:deep(.titulo) {
  /* position: absolute;
        bottom: -12px;
        left: 0; */
  font-size: 0.8rem !important;
  margin-left: 0.5em;
  margin-top: 0.4em;
  background-color: #cecece;
  color: black;
  padding: 1px 6px;
  border-radius: 15px;
}
</style>
