<template>
  <div class="form-group has-label" v-if="visible">
    <label>{{ label }}</label>
    <el-select
      :value="local.value"
      @input="onInput($event)"
      size="large"
      :name="this.vname"
      :clearable="false"
      v-validate="required ? 'required' : ''"
      :disabled="!isEditable"
      :placeholder="''"
    >
      <el-option
        v-for="(label, key) in options"
        :value="key"
        :label="$t(label)"
        :key="key"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep, tap, set } from "lodash";

export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
    vname: {
      type: String,
      required: true,
    },
    vid: {
      type: Number,
      required: true,
    },
    defaultValue: {
      type: String,
    },
    value: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => [],
    },
    variables: {
      type: Array,
    },
  },

  computed: {
    local() {
      let defaultValue = {
        variable_id: this.vid,
        value: "",
        variable: {
          name: this.vname,
        },
      };
      if (!this.variables) return defaultValue;

      let value = this.variables.find((variable) => {
        if (variable.variable) {
          return variable.variable.name === this.vname;
        }
      });
      return !value ? defaultValue : value;
    },
  },

  methods: {
    onInput(value) {
      let input = cloneDeep(this.local);

      input = {
        ...input,
        value,
      };
      this.$emit("input", input);
    },
  },
};
</script>

<style></style>
