<style>
</style>

<template>
  <label>
    {{label}}
    <el-tooltip :content="tooltip" :open-delay="300" placement="top">
      <i class="nc-icon nc-alert-circle-i"></i>
    </el-tooltip>
  </label>
</template>

<script>
import { Tooltip } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip
  },
  props: {
    label: String,
    tooltip: String
  }
};
</script>