<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fg-input :label="$t(label)"
                    v-model="timeGap"
                    type="text"
                    :disabled="disabled"
                    v-mask="mask"
                    :name="name"
                    @blur="onBlur"
                    placeholder="00:00"
                    v-validate="required ? `required${validations}` : null"
                    :error="getError(name)" />
            </div>
        </div>
    </div>
</template>

<script>
import { TimeSelect } from 'element-ui'
import { cloneDeep, tap, set } from "lodash";
import GapTime from "src/app-components/form-elements/GapTime"

export default {
    extends: GapTime,

    data() {
        return {
            mask: "##:##"
        }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: "Gap time"
        }
    },

    watch: {
        timeGap () {
            console.log("WATCH TIME GAP")
            this.onInput('[time-gap].value', this.timeGap)
        }
    },

    methods: {
        onBlur() {
            if(this.timeGap) {
                let out = '';
                const len = this.timeGap.length;

                if(len == 1) {
                    out = '00:0' + this.timeGap;
                }else if(len == 2) {
                    out = '00:' + this.timeGap;
                }else if(len == 3) {
                    out = this.timeGap + '00';
                }else if(len == 4) {
                    out = this.timeGap + '0';
                }else{
                    out = this.timeGap;
                }

                this.timeGap = out;
            }
        }
    }
}
</script>

<style>

</style>
