<template>
  <div>
    <img v-if="image.src" :src="image.src" :alt="image.alt" />
    <p v-if="message.title" class="font-weight-bold mt-2">
      {{ message.title }}
    </p>
    <p v-if="message.text" class="my-1">
      {{ message.text }}
    </p>

    <template v-if="message.suggestions">
      <el-button
        size="mini"
        :type="suggestionsMultiple ? 'text' : ''"
        :class="suggestionsMultiple ? 'richcard-suggestions-buttons m-0' : ''"
        v-for="(suggestion, key) in message.suggestions"
        :key="key"
        @click="actionSuggestion(suggestion)"
      >
        {{ suggestion.label }}
      </el-button>
    </template>
  </div>
</template>

<script>
  // import { marked } from "marked";
  import { Button } from "element-ui";
  export default {
    name: "RichcardMessage",
    props: ["message"],

    components: {
      [Button.name]: Button,
    },

    data() {
      return {};
    },

    methods: {
      actionSuggestion(suggestion) {
        if (suggestion.href) {
          window.open(suggestion.href, "_blank");
        } else {
          this.$emit("sendNewMessage", suggestion.label);
        }
      },
    },

    computed: {
      suggestionsMultiple() {
        if (!this.message.suggestions) return false;

        return this.message.suggestions.length > 1;
      },

      image() {
        if (this.message.img) {
          if (typeof this.message.img === "string") {
            return {
              src: this.message.img,
              alt: "",
            };
          }

          return this.message.img;
        }

        return {
          src: "",
          alt: "",
        }
      },
    },
  };
</script>

<style>
  .richcard-suggestions-buttons {
    color: #eed;
    display: block;
  }
  
  .richcard-suggestions-buttons:hover {
    color: white;
  }
  
  .drawflow .richcard-suggestions-buttons {
    color: #409EFF;
  }
  
  .drawflow .richcard-suggestions-buttons:hover {
    color: #66b1ff;
  }
</style>
