<template>
  <div>
    <div v-if="!isConfigurable">
      <div>
        <i class="fa fa-info-circle" />
        {{ $t(notConfigurableMessage) }}
      </div>
    </div>
    <div v-else>
      <input
        type="hidden"
        v-validate="requiredBookmark ? 'required' : ''"
        name="selected_bookmark_size"
      />
      <div class="row" v-if="getError('selected_bookmark_size')">
        <div class="col-md-12">
          <div class="alert alert-danger">{{ $t('Please select a template') }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" v-if="scenario">
          <AcceptFlow
            :saEnabled="true"
            id="accept-flow"
            :entity="acceptEntity"
            :campaignBookmarkId="bookmark.id"
            :selected-creative="selectedCreatives[0]"
            @modelUpdated="onModelUpdated"
            :actions="actions.acceptance"
            :screens="[{ id: 'LAST_SCREEN', name: 'The last screen' }]"
            :triggers="[{ id: 'ACCEPTANCE', name: 'Accept' }]"
          />
        </div>

        <div class="col-md-12" v-if="scenario">
          <RejectFlow
            :saEnabled="true"
            id="reject-flow"
            :entity="rejectionEntity"
            :campaignBookmarkId="bookmark.id"
            :selected-creative="selectedCreatives[0]"
            @modelUpdated="onModelUpdated"
            :actions="actions.rejected"
            :screens="rejectScreens"
            :triggers="[{ id: 'REJECTION', name: 'Reject' }]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AcceptFlow from "src/app-components/second-action/AcceptFlow";
import RejectFlow from "src/app-components/second-action/RejectFlow";
import { updatableForm } from "src/app-components/FormMixin";
import api from "src/api";

const entityFilter = (entity, s, def) => {
  const e = entity.filter((e) => {
    return e.screen_event == s;
  });

  if (e.length > 0) {
    return e[0];
  }

  return null;
};

const supportedFlows = [5, 6, 16, 17, 18, 19, 100];

export default {
  name: "second-action-configuration",

  inject: ["getError", "parentValidator"],

  components: {
    [AcceptFlow.name]: AcceptFlow,
    [RejectFlow.name]: RejectFlow,
  },

  mixins: [updatableForm],

  props: {
    entity: {
      type: Array,
      required: false,
      default: () => [],
    },

    bookmark: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    selectedCreatives: {
      type: Array,
      default: [],
    },
  },

  mounted() {
    if(this.isConfigurable){
        this.fetchRejectScreens();
      }
  },

  watch: {
    entity: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.patchModel(nv);
      },
    },

    scenario: function(newVal, oldVal){
      if(newVal !== oldVal && this.isConfigurable){
        this.fetchRejectScreens();
      }
    },

    selectedCreatives: function (newVal) {
      if(Array.isArray(newVal) && newVal.length === 1 && this.isConfigurable){
        this.fetchRejectScreens();
      }
    },
  },

  created() {
    this.$validator = this.parentValidator;
  },

  /*asyncComputed: {
    async rejectScreens() {
      console.log('saraza', this.scenario)
      const ct = await api.getScenarioTypeFlow(this.bookmark.account_scenario_type_id);
      const screens = ct.data.filter((c) => {
        return c.display == true;
      });

      return screens;
    },
  },*/

  computed: {
    scenario() {
      if(this.bookmark){
        if(this.bookmark.account_scenario) {
          return this.bookmark.account_scenario.scenario_type_id;
        }
      }

      return null;
    },

    bookmarkName(){
      if(this.bookmark){
        return this.bookmark.name;
      }

      return null;
    },

    isConfigurable() {
      if(!this.bookmark.account_scenario) return false;

      return (
        this.bookmark
        && this.selectedCreatives.length == 1
        && supportedFlows.includes(this.bookmark.account_scenario.scenario_type_id)
      )
    },

    notConfigurableMessage() {
      if (!supportedFlows.includes(this.scenario)) {
        return "The scenario-flow doesn't support second-action";
      }

      if (this.selectedCreatives.length > 1) {
        return "Second-Action is not available when more than 1 creative is selected";
      }

      if (this.selectedCreatives.length == 0) {
        return "Second-Action is available when only one creative is selected";
      }

      return null;
    },

    requiredBookmark() {
      const hasAcceptances = this.form.filter(
        (e) => e.active == true && e.trigger == "ACCEPTANCE" && e.product != null
      );

      if (hasAcceptances.length > 0) {
        const ok = "bookmark" in hasAcceptances[0].product;
        return !ok;
      }

      return false;
    },

    acceptEntity() {
      return this.formatEntity("ACCEPTANCE");
      // return entityFilter(this.entity, "ACCEPTANCE");
    },

    rejectionEntity() {
      return this.formatEntity("REJECTION");
      // return entityFilter(this.entity, "REJECTION");
    },
  },

  data() {
    return {
      showModal: false,
      actions: {
        acceptance: [{ id: "CROSS_SELLING", name: "Offer another template" }],
        rejected: [
          { id: "REPEAT_SCREEN", name: "Repeat screen" },
          { id: "CROSS_SELLING", name: "Offer another template" },
          { id: "CHANGE_CREATIVE", name: "Change creative" },
        ],
      },
      form: [],
      rejectScreens: [],
    };
  },

  methods: {
    onModelUpdated($event) {
      if ($event.active === false) {
        this.form = this.form.filter((flow) => flow.trigger !== $event.trigger);
      } else {
        const arr = [$event];
        const form = this.form.filter((flow) => flow.trigger !== $event.trigger);

        this.form = [...form, ...arr];
      }
    },
    async fetchRejectScreens(){
      const flow = await api.getScenarioTypeFlow(this.bookmark.account_scenario_type_id);
      this.rejectScreens = flow.data.filter((c) => {
        return c.display == true;
      });
    },

    formatEntity(filter = "ACCEPTANCE") {
      let data = entityFilter(this.entity, filter);
      if(data) {
        data.active = true;
        data.trigger = data.screen_event;
        data.screen = data.screen_id === null ? 'LAST_SCREEN' : data.screen_id; 

        delete data.screen_event;
        delete data.screen_id;
      }
      return data;
    }
  },
};
</script>

<style>
.modal-route {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.modal-route .modal-content {
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 95%;
  height: 90% !important;
  max-height: calc(100vh - 210px);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f4f3ef;
}
</style>
