<template>
  <div>
      <div class="row">
          <div class="col-md-3">
              <Services v-model="form.service_name" 
                :disabled="processed || working" />
          </div>

          <div class="col-md-2">
              <label> {{ $t("Available users") }} </label>
              <fg-input name="count"
                :value="count"
                type="text"
                :disabled="true"
              ></fg-input>
          </div>

          <div class="col-md-2">
              <label> {{ $t("Desired amount") }} </label>
              <fg-input name="size"
                  v-model="form.size"
                  v-validate="`required|min_value:1|max_value:${count}`"
                  type="number"
                  :disabled="processed || working"
                  :error="getError(`size`)"
              ></fg-input>
          </div>

          <div class="col-md-3 align-self-end">
            <p-button v-if="!processed && !working" type="dark" @click="onAdd">{{ $t("Add") }}</p-button>
            <FileDownloadButton v-if="processed" size="default" :id="fileId || 0" />
          </div>
      </div>
      <div v-if="working">
          <div class="row justify-content-center align-items-center mt-5 mb-5">
              <div class="col-auto">
                  <Spinner :display="true" />
              </div>
              <div class="col-auto">
                  {{ $t("Processing list...") }}
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import api from "src/api";
import Services from "src/app-components/form-elements/Services";
import Spinner from "src/app-components/Spinner";
import _ from 'lodash'
import FileDownloadButton from "src/app-components/buttons-components/FileDownload"

export default {
  //inject: ["getError", "parentValidator"],
  props: {
    processed: Boolean,
    index: Number,
    crmListId: Number,
    entity: Object
  },

  components: {
    Services,
    Spinner,
    FileDownloadButton
  },

  provide() { // Pass to Mixin
      return {
          parentValidator: this.$validator,
          getError: this.getError,
      };
  },

  created() {
    //this.$validator = this.parentValidator;
  },

  computed: {
    count() {
      return this.isNew ? this.users : this.form.file._count;
    },

    isNew() {
        if(typeof this.form.id === "undefined") {
          return true;
        }
        
        return false
    },
  },

  asyncComputed: {
      async users() {
        if(this.form.service_name === null) {
          return null
        }
        
        const x = await api.getProductAssignmentCalculate(this.crmListId, this.form);
        return x.data.count;
      }
  },

  data() {
    return {
        form: {
          count: null,
          service_name: null,
          size: null
        },
        working: false,
        fileId: null
    };
  },

  watch: {
    entity: {
      handler () {
        this.form = _.cloneDeep(this.entity);
        this.fileId = this.entity.account_user_file_id;
      },
      immediate: true
    }
  },

  methods: {
    async onAdd() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      this.working = true;
      let res = await api.createSegmentationListPipeline(this.crmListId, {
        ...this.form,
        count: undefined,
        id: undefined
      })
      res = await this.waitForListToBeProcessed(res.data.id)
      this.working = false;
      this.fileId = res.data.account_user_file_id;
      this.$emit("added", this.index);
    },

    async waitForListToBeProcessed (id) {
        let segmentationList;
        do {
            await this.sleep(1000);
            segmentationList = await api.getSegmentationListPipeline(id);
            console.log("Current status " + segmentationList.data.status);
        } while (segmentationList.data.status != "GATHERING_INFORMATION_READY");
        return segmentationList;
    },

    sleep(ms) {
        return new Promise(function(resolve) {
            setTimeout(resolve, ms);
        });
    },

    getError(fieldName) {
        return this.errors.first(fieldName);
    },


  }
};
</script>

<style>
</style>
