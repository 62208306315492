<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t('Scenario / Campaign flow') }}</label>
        <el-select
          size="large"
          v-validate="noValidation ? null : 'required'"
          name="scenario"
          :clearable="clearable"
          :value="local"
          @input="$emit('input', $event)"
          :disabled="disabled"
          @change="notifyChange">

          <el-option
            v-for="option in getScenarioByChannel(channel, scenarios)"
            :value="option.scenario.id"
            :label="$t(option.alias ? option.alias : option.scenario.name)"
            :key="option.name" />
        </el-select>
      </div>

      <div
        v-show="errors.has('scenario')"
        class="text-danger invalid-feedback"
        style="display: block;"
      >{{ errors.first('scenario') }}</div>
    </div>
  </div>
</template>

<script>
import Scenarios from 'src/app-components/form-elements/Scenarios';
import { Select, Option } from "element-ui";

export default {
    extends: Scenarios
};
</script>