<script>
  import TextOptin from "./TextOptin";

  export default {
    extends: TextOptin,

    data() {
      return {
        maxLengthInitial: 30
      };
    },
  };
</script>