<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fg-input :label="$t(this.label)"
                    :value="local"
                    name="product_name"
                    @input="$emit('input', $event)"
                    :placeholder="$t(this.placeholder)"
                    v-validate="{ required: required, uniqueBookmarkName: isUnique }"
                    :error="getError('product_name')"
                    :disabled="! isEditable"
                    type="text" />
            </div>
        </div>
    </div>
</template>

<script>
    import api from "src/api";

    export default {
        inject: ["getError", "parentValidator"],

        props: {
            label: {
                default:'Template name'
            } ,
            placeholder: {
                default:false,
            } ,

            value: null,

            isUnique: {
                type: Boolean,
                default: true
            },
            
            required: {
                type: Boolean,
                default: true
            },

            isEditable: {
                type: Boolean,
                default: true
            }
        },

        created() {
            this.$validator = this.parentValidator;

            const isUnique = (value) => {
                return api.validateBookmarkName({ name: value }).then((response) => {
                    return {
                        valid: response.status,
                        data: {
                            message: response.status ? "" : response.errors[Object.keys(response.errors).shift()]
                        }
                    };
                });
            };

            this.$validator.extend("uniqueBookmarkName", {
                validate: isUnique,
                getMessage: (field, params, data) => data.message
            });
        },

        computed: {
            local() {
                return this.value ? this.value : null;
            },
        },
    }
</script>
