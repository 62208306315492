<template>
    <div>
        <div class="row mb-1">
            <div class="col-12 col-md text-left">
                <h6>{{ $t("Carousel Card configuration") }}</h6>
            </div>
            <div class="col-auto">
                <span class="badge badge-outline">{{ `${contentMessage.richCard.carouselCard.cardContents.length} / 10` }}</span>
                <el-button
                    size="mini"
                    type="success"
                    class="py-1 px-1"
                    circle plain
                    @click="add"
                    :disabled="!canAdd"
                    icon="el-icon-plus"
                    v-if="isEditable"
                ></el-button>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-12 col-md-6">
                <label class="w-100 text-left">{{ $t("Carousel card width") }}</label>
                <el-select
                    v-model="contentMessage.richCard.carouselCard.cardWidth"
                    :placeholder="$t('Select orientation')"
                    class="w-100"
                    size="mini"
                    :disabled="!isEditable"
                >
                    <el-option
                        v-for="option in CARD_WIDTH"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="col">
                <el-input type="hidden" v-model="contentMessage.richCard.carouselCard.cardContents.length" name="card-contents" v-validate="'required|min_value:2'"></el-input>
            </div>
        </div>
        <label class="w-100 text-left">
            <i class="fa fa-slideshare mr-1" aria-hidden="true"></i>
            {{ $t("Available cards") }}
            <span class="text-danger float-right" v-if="errors.has(`card-contents`)">{{$t('You need at least 2 cards.')}}</span>
        </label>

        <el-collapse v-model="activeNames">
            <draggable v-model="contentMessage.richCard.carouselCard.cardContents" :disabled="!isEditable">
            
                <el-collapse-item
                    v-for="(card, index) in contentMessage.richCard.carouselCard.cardContents"
                    :key="index"
                    :name="index"
                >
                    <template slot="title">

                        <el-link :underline="false" type="danger" class="py-1 px-1 remove-btn" 
                        :title="!canRemove ? $t(`Can't delete. You need at least 2 cards.`) : $t('Delete')"
                        :disabled="!isEditable" 
                        @click.stop="remove(index)">
                            <i class="fa fa-trash text-white" aria-hidden="true"></i>
                        </el-link>

                        <i class="fa fa-sort mr-3" aria-hidden="true"></i>
                        <p class="m-0 text-truncate">{{card.title || $t('Unnamed') }}</p>
                    </template>
                    <CardContent :isEditable="isEditable" v-model="contentMessage.richCard.carouselCard.cardContents[index]" :index="index" :maxImgSize="1" :maxVideoSize="5"></CardContent>
                </el-collapse-item>

            </draggable>
        </el-collapse>
    </div>
</template>

<script>
import { Collapse, CollapseItem, Button, Link, Icon, Input } from "element-ui";
import { MessageBox } from 'element-ui';
import draggable from 'vuedraggable'

import CardContent from "src/app-components/rcs/CardContent";
import VModelMixin from "src/mixins/v-model-mixin.js";

export default {
    name: "CarouselCard",

    props:{
        isEditable: {
            type: Boolean,
            default: true
        }
    },

    inject: {
        parentValidator: "parentValidator",
    },

    components: {
        draggable,
        CardContent,
        [Icon.name]: Icon,
        [Link.name]: Link,
        [Input.name]: Input,
        [Button.name]: Button,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },

    mixins: [
        VModelMixin("contentMessage")
    ],

    data() {
        const SCOPE = "carousel";
        const CARD_WIDTH = [
            // { label: "Unspecified", value: "CARD_WIDTH_UNSPECIFIED" },
            { label: "Small", value: "SMALL" },
            { label: "Medium", value: "MEDIUM" },
        ];
        return {
            CARD_WIDTH,
            SCOPE,
            activeNames: [0],
            contentMessage: {
                richCard: {
                    carouselCard: {
                        cardWidth: "MEDIUM",
                        cardContents: [
                            {
                                title: "",
                                description: "",
                                // media: {
                                //     height: "SHORT",
                                //     contentInfo: {
                                //         fileUrl: "",
                                //         thumbnailUrl: "",
                                //         forceRefresh: false,
                                //     },
                                // },
                                suggestions: [],
                            }
                        ],
                    },
                },
            },
        };
    },

    computed: {
        canAdd(){
            return this.contentMessage.richCard.carouselCard.cardContents.length < 10
        },
        canRemove(){
            return this.contentMessage.richCard.carouselCard.cardContents.length > 2
        }
    },

    created() {
        this.$validator = this.parentValidator;
    },

    methods: {

        newContent(){
            return {
                title: "",
                description: "",
                // media: {
                //     height: "SHORT",
                //     contentInfo: {
                //         fileUrl: "",
                //         thumbnailUrl: "",
                //         forceRefresh: false,
                //     },
                // },
                suggestions: [],
            }
        },
        add() {
            // let length = this.contentMessage.richCard.carouselCard.cardContents.length;

            // this.$set(this.contentMessage.richCard.carouselCard.cardContents, length, this.newContent());
            this.contentMessage.richCard.carouselCard.cardContents.push(this.newContent());
        },

        remove(index){
            if (index > -1) {
                MessageBox.confirm(this.$t('This will delete the card permanently from the carousel. Continue ?') , this.$t('Warning'), {
                    confirmButtonText: this.$t('Confirm'),
                    cancelButtonText: this.$t('Cancel'),
                    type: 'warning'
                }).then( () => {
                    this.contentMessage.richCard.carouselCard.cardContents.splice(index, 1)
                }).catch( () => null )
            }
        }
    },
};
</script>

<style lang="scss" scoped>
:deep(.el-collapse-item__header) {
    background-color: rgb(75, 140, 194);
    color: white !important;
    height: 30px;
    line-height: 30px;
    cursor: grab;

    &:hover {
        .remove-btn{
            padding: 0px 15px !important;
            // opacity: 1;
            max-width: 100%;
            margin-right: 1em;
        }
    }
}

.remove-btn{
    padding: 0px !important;
    height: 100%;
    background-color: var(--danger);
    overflow: hidden;
    // opacity: 0;
    max-width: 0px;
    margin: 0;
    margin-right: 15px;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

    &.is-disabled{
        background-color: var(--gray);
    }
}
</style>