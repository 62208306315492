<template>
  <p-button type="success" size="sm" icon @click="onClick">
    <el-tooltip class="item" effect="dark" :content="$t('Stats')" placement="top">
      <i class="nc-icon nc-chart-bar-32"></i>
    </el-tooltip>
  </p-button>
</template>

<script>
import Constants from "src/views/campaigns/constants";

export default {
  props: {
    campaignData: Object,
  },

  computed: {
    targetReport() {
      const type =
        (this.campaignData.firstParent && this.campaignData.firstParent.type) ||
        this.campaignData.type;
      if (this.campaignData.channel.id === 6) return 15; //15 is RcsCampaignReport
      if (this.targetReportIsConsolidatedCampaign(type)) {
        return 8;
      } else if (this.targetReportIsSatMultimedia(type)) {
        return 9;
      } else if (this.targetReportIsPing(type)) {
        return 5;
      } else if (this.targetReportIsOta(type)) {
        // definir numero;
        return 20;
      } else {
        return null;
      }
    },
    isLaunchBrowserScenario() {
      return Constants.ScenarioTypesWithLaunchBrowser.includes(
        this.campaignData.accountScenario.scenario_type_id
      );
    },
  },

  watch: {
    targetReport() {
      console.log("Target report: " + this.targetReport);
    },
  },

  methods: {
    onClick() {
      if (this.targetReport != null) {
        console.log('active')
        this.$router.push({
          name: `CampaignPivot`,
          params: {
            initialReport: this.targetReport,
            campaignId: this.campaignData.id,
            firstParentId:
              this.campaignData.firstParent && this.campaignData.firstParent.id,
            channelId: this.campaignData.channel.id,
          },
        });
      }
    },
    targetReportIsConsolidatedCampaign(type) {
      return ["SINGLE", "PERFORMANCE"].includes(type) && !this.isLaunchBrowserScenario;
    },
    targetReportIsPing(type) {
      return type == "PING";
    },
    targetReportIsOta(type) {
      return type == "OTA";
    },
    targetReportIsSatMultimedia(type) {
      return ["SINGLE", "PERFORMANCE"].includes(type) && this.isLaunchBrowserScenario;
    },
  },
};
</script>
