<template>
  <div>
    <div class="row" v-show="scenario">
      <div class="col-md-12">
        <!-- <h6 class="pull-left" style="font-size: 1rem;">{{ $t(title) }}</h6> -->
        <SectionHeader :text="title" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <input
          type="hidden"
          name="addCreativity"
          v-validate.continues="'addCreativity'"
        />
        <div class="col-12 alert alert-danger" v-if="getError('addCreativity')">
          {{ errors.first("addCreativity") }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <input
          type="hidden"
          name="minCreativesSelected"
          v-validate.continues="{
            minCreativesSelected: validateMinSelectedCreativities,
          }"
        />
        <div
          class="col-12 alert alert-danger p-1 animated fadeIn"
          v-if="
            !getError('addCreativity') && errors.has('minCreativesSelected')
          "
        >
          <span>{{ getError("minCreativesSelected") }}</span>
        </div>
      </div>
    </div>

    <div class="row" v-show="scenario">
      <div class="col-lg-3 mt-3">
        <create-creativity-configuration
          v-if="allowAdd"
          @modal-save="onModalSave($event)"
          :scenarioID="scenarioID"
          :disabled="disabled"
          :entity="editEntity"
          class="pull-left"
        ></create-creativity-configuration>
      </div>

      <div class="table-responsive overflow-auto" v-if="form.length > 0">
        <el-table
          class="table-striped"
          :key="tableRenderId"
          :data="form"
          :height="tableHeight"
          style="width: 100%"
        >
          <el-table-column width="1px"></el-table-column>

          <template v-if="$asyncComputed.adserverVariables.success">
            <el-table-column
              align="center"
              v-for="(header, index) in headers"
              :key="index"
            >
              <template slot="header" slot-scope="scope">
                <div class="text-center">
                  {{
                    adserverVariables
                      .filter((variable) => variable.name === header)
                      .shift().tooltip
                  }}
                </div>
              </template>
              <template slot-scope="props" >
                <!-- {{ undefined === props.row.variables[index] ? '' : props.row.variables[index].value }} -->
                {{
                  props.row.variables
                    .filter( (t) => {return t.variable.name == header} )
                    .shift().value
                }}
              </template>
            </el-table-column>
          </template>

          <el-table-column width="100" align="center">
            <template slot="header" slot-scope="scope">
              <div class="text-center">{{ $t("MT/MO") }}</div>
            </template>
            <template slot-scope="props">
              <CreativityConfigurationMt :row="props.row"
                                :scenarioID="scenarioID"
                                :vars="vars" />
            </template>
          </el-table-column>

          <el-table-column width="100" align="center">
            <template slot="header" slot-scope="scope">
              <div class="text-center">{{ $t("Status") }}</div>
            </template>
            <template slot-scope="props">
              <i
                :class="
                  'fa fa-' +
                    getIconByStatus(
                      props.row.status ? props.row.status : 'PENDING'
                    )
                "
                :title="props.row.status ? props.row.status : 'PENDING'"
              ></i>
            </template>
          </el-table-column>

          <el-table-column align="center" width="220">
            <template slot="header" slot-scope="scope">
              <div class="text-center">{{ $t("Actions") }}</div>
            </template>
            <template slot-scope="props">
              <div class="row justify-content-center">
                <div
                  class="col-auto"
                  v-if="
                    config.actions.includes('ALLOW_EDIT_CREATIVITY') ||
                      config.actions.includes('ALLOW_DELETE_CREATIVITY')
                  "
                >
                  <p-button
                    v-if="config.actions.includes('ALLOW_EDIT_CREATIVITY')"
                    type="warning"
                    class="btn-sm"
                    @click.native="onEditCreativity(props.$index)"
                    :disabled="props.row.status === 'APPROVED'"
                    title="Edit this row"
                  >
                    <i class="nc-icon nc-ruler-pencil"></i>
                  </p-button>

                  <p-button
                    v-if="config.actions.includes('ALLOW_DELETE_CREATIVITY')"
                    type="danger"
                    class="btn-sm"
                    @click.native="onRemoveCreativity(props.$index)"
                    :disabled="props.row.status === 'APPROVED'"
                    title="Remove this row"
                  >
                    <i class="nc-icon nc-basket"></i>
                  </p-button>
                  
                  <p-button
                    v-if="$can('Approve', 'Bookmarks') && props.row.id && changeStatusCreatives"
                    type="success"
                    class="btn-sm"
                    @click.native="updateStatus(props.row, 'APPROVED')"
                    :title="$t('Approve creative')"
                  >
                    <i class="icon icon-like-7"></i>
                  </p-button>
                  <p-button
                    v-if="$can('Reject', 'Bookmarks') && props.row.id && changeStatusCreatives"
                    type="danger"
                    class="btn-sm"
                    @click.native="openRejectCreativityModal(props.row)"
                    :title="$t('Reject creative')"
                  >
                    <i class="icon icon-thumb-down"></i>
                  </p-button>

                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="config.actions.includes('ALLOW_SELECT_CREATIVITY')"
          >
            <template slot="header" slot-scope="scope">
              <div class="text-center">{{ $t("Selected") }}</div>
            </template>
            <template slot-scope="props">
              <div
                class="row justify-content-center col-auto select-creative"
                v-if="props.row.status !== 'REJECTED'"
              >
                <el-checkbox-group
                  @input="selectedCreativesChanged"
                  :value="selectedCreatives"
                  fill="#6bd098"
                  title="Use this creativity in the campaign"
                  size="mini"
                >
                  <el-checkbox
                    :label="props.row.hash"
                    :disabled="
                      disableCreativeSelection ||
                      arePlaceholdersInUse(props.row.hash)
                        ? true
                        : disabled
                    "
                    :key="props.row.hash"
                  >
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </template>
          </el-table-column>
          
          <el-table-column>
            <template slot="header" slot-scope="scope">
              <div class="text-center">{{ $t("Reason reject") }}</div>
            </template>
            <template slot-scope="props">
              <span
                class="row justify-content-center col-auto select-creative"
              >
                {{props.row.reason ? props.row.reason : "-"}}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <reject-creativity-configuration
      :show="showRejectModal"
      @modal-cancel="rejectCreativityModalCancel"
      @modal-save="rejectCreativityModalAccept"
    >
    </reject-creativity-configuration>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import api from "src/api";

import SectionHeader from "src/app-components/form-elements/SectionHeader";
import CreativityConfigurationMt from "src/app-components/CreativityConfigurationMt";
import { updatableForm } from "src/app-components/FormMixin";
import { Checkbox, CheckboxGroup } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { cloneDeep, tap, set, isEmpty } from "lodash";
import PSwitch from "src/components/UIComponents/Switch";
import CreateCreativityConfiguration from "src/app-components/modal-components/CreateCreativityConfiguration";

import RejectCreativityConfiguration from "src/app-components/modal-components/RejectCreativityConfiguration";

export default {
  name: "creativity-configuration",

  mixins: [updatableForm],

  inject: {
    getError: "getError",
    parentValidator: "parentValidator",
    activateCreatives: {
      from: "activateCreatives",
      default: function() {
        return function() {
          return [];
        };
      },
    },

    configCreativities: {
      default() {
        return () => ({
          actions: [
            "ALLOW_EDIT_CREATIVITY",
            "ALLOW_DELETE_CREATIVITY",
            "ALLOW_SELECT_CREATIVITY",
          ],
        });
      },
    },
  },

  props: {
    allowAdd: {
      type: Boolean,
      default: true,
    },

    allowSelectCreatives: {
      type: Boolean,
      default: false,
    },

    creativitiesEnabled: {
      type: Boolean,
      default: true,
    },

    scenario: Number,

    entity: {
      type: Array,
      required: false,
    },

    tableHeight: {
      type: String,
      default: "500",
    },

    minCreativities: {
      type: Number,
      default: 2,
    },

    validateMinSelectedCreativities: {
      type: Boolean,
      required: false,
    },

    title: {
      type: String,
      default: "Creativities",
    },

    vars: {
      type: Array,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    onlyOneCreativeSelected: {
      type: Boolean,
      default: false,
    },

    disableCreativeSelection: {
      type: Boolean,
      default: false,
    },
    isSatMultimedia: {
      typw: Boolean,
      default: false
    },
  },

  components: {
    SectionHeader,
    CreativityConfigurationMt,
    RejectCreativityConfiguration,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    PSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [CreateCreativityConfiguration.name]: CreateCreativityConfiguration,
  },

  created() {
    this.$validator = this.parentValidator;

    this.$validator.extend("oneChecked", {
      validate: () => this.switches.some((option) => option === true),
    });

    // ToDo: Make this better (?)
    this.$validator.extend("addCreativity", {
      validate: () => {
        return !(
          this.scenarioID &&
          this.creativitiesEnabled &&
          this.form.length < this.minCreativities
        );
      },
    });

    // At least one creativity
    this.$validator.extend("minCreativesSelected", {
      validate: () => {
        return !(
          this.scenarioID &&
          this.creativitiesEnabled &&
          this.selectedCreatives < 1
        );
      },
      getMessage: () => this.$t("Please, select at least one creativity"),
    });

    // this.$validator.extend('oneCreativityWithPlaceholders', {
    //     validate: () => {
    //         return this.selectedCreativesWithPHS < 2;
    //     },
    //     getMessage: () => `${this.$t('You can only select one creativity with placeholders')}.`,
    //     computesRequired: true,
    // });
  },

  asyncComputed: {
    adserverVariables: {
      default: () => [],
      async get() {
        const vars = await api.getVariables();
        return vars.data;
      },
    },
  },

  computed: {
    interventions() {
      return this.selectedCreatives;
    },

    config() {
      return this.configCreativities();
    },

    scenarioID() {
      return this.scenario;
    },

    headers() {
      let res = [];
      let obj = isEmpty(this.lastCreativityAdded)
        ? this.form[0]
        : this.lastCreativityAdded;
      if (obj.variables) {
        obj.variables.forEach((elem) => {
          if (elem.variable) {
            if (elem.variable.name) {
              res.push(elem.variable.name);
            }
          }
        });
      }

      const columnOrder = (column) => {
        if (column == "text-msg-opt-in-1") return 1;
        if (column == "text-msg-opt-in-2") return 2;
        if (column == "menu-title") return 3;
        if (column == "menu-accept") return 4;
        if (column == "menu-denied") return 5;
        return Infinity;
      };

      const sortFn = (a, b) => {
        if (columnOrder(a) < columnOrder(b)) {
          return -1;
        }
        if (columnOrder(a) > columnOrder(b)) {
          return 1;
        }
        return 0;
      };

      return res.sort(sortFn);
    },

    creativities_withPHS_MAP() {
      const PHS = this.form
        .map((ph) => {
          let values = [];
          ph.variables.forEach((item) => {
            if (typeof item.value == "string") values.push(item.value);
          });

          return {
            hash: ph.hash,
            values: values,
          };

          /* const keys = Object.keys(ph);
          let values = [];
          keys.forEach((key) => {
            if (typeof ph[key].value == "string") values.push(ph[key].value);
          });
          return {
            hash: ph[keys[0]].hash,
            values: values,
          }; */
        })
        .filter((ph) => {
          for (let i = 0; i < ph.values.length; i++) {
            let detected = false;
            ph.values[i].replace(/\{(\w+)\}/g, (match, capture) => {
              if (capture.toLowerCase() !== "msisdn" && !detected)
                detected = true;
            });
            return detected;
          }
        });

      return PHS;
    },

    selectedCreativesWithPHS() {
      return this.creativities_withPHS_MAP.filter((c) => {
        return this.selectedCreatives.includes(c.hash);
      }).length;
    },

    appUser() {
      return this.$store.getters.appUser;
    },

    changeStatusCreatives() {
      return this.$route.name === "EditBookmark";
    }
  },

  watch: {
    selectedCreatives: {
      immediate: true,
      deep: true,
      handler: function(nv, ov) {
        this.onCreativesSelected();
      },
    },
    tableHeight() {
      this.tableRenderId++;
    },
  },

  data() {
    return {
      alreadyCalculatedMts: [],
      selectedCreatives: [],
      lastSelectedCreatives: [],
      form: [],
      editEntity: this.getEditEntity(),
      switches: [],
      lastCreativityAdded: {},
      tableRenderId: 1,

      showRejectModal: false,
      creativityToReject: null,
    };
  },

  methods: {
    async updateStatus({id, bookmark_id}, status, reason = null) {
      const res = await api.updateCreativity(bookmark_id, id, {
        status,
        reason,
      });

      if(!res.status) return;

      const index = this.form.findIndex(creative => creative.id === id);

      if(index !== -1) {
        this.form[index].status = status;

        if(status === "APPROVED") {
          this.form[index].reason = null;
        }else if(status === "REJECTED" && reason) {
          this.form[index].reason = reason;
        }
      }


      this.rejectCreativityModalCancel();
    },

    openRejectCreativityModal(creative) {
      this.creativityToReject = {...creative};
      this.showRejectModal = true;
    },

    rejectCreativityModalAccept(reason) {
      this.updateStatus(
        this.creativityToReject,
        "REJECTED",
        reason
      );
    },

    rejectCreativityModalCancel() {
      this.showRejectModal = false;
      this.creativityToReject = null;
    },

    afterModelPatched() {
      //console.log("AFTER MODEL PATCHED")
      const actives = this.activateCreatives();
      this.selectedCreatives = actives.map((c) => c.bookmark_perf_text_hash);
    },

    arePlaceholdersInUse(hash) {
      return (
        !this.selectedCreatives.includes(hash) &&
        this.creativities_withPHS_MAP.filter((c) => c.hash == hash).length >
          0 &&
        this.selectedCreativesWithPHS > 0
      );
    },

    onCreativesSelected() {
      this.$emit("creativesSelected", cloneDeep(this.selectedCreatives));
    },

    clear() {
      this.form = [];
    },

    onEditCreativity(index) {
      this.setEditEntity(index, this.form[index]);
    },

    onRemoveCreativity(index) {
      this.$delete(this.switches, index);
      this.$delete(this.form, index);
      const length = this.form.length > 0 ? this.form.length - 1 : 0;
      this.lastCreativityAdded = this.form[length];
    },

    onSwitchChange(index, value) {
      this.form.map((creativity, key) => {
        this.switches[key] = key === index ? value : false;
        Object.keys(creativity).map((creativityKey) => {
          this.$set(
            creativity[creativityKey],
            "default",
            key === index ? value : false
          );
        });
      });
    },

    onModalSave(value) {
      if (this.editEntity.index === null || this.editEntity.index === undefined) {
        this.switches.push(false);
        const hash = uniqid("cr-"); // Keep unique hash for each creative entry
        let newCreative = {
          hash,
          status: this.$can('Approve', 'Bookmarks') ? "APPROVED" : "PENDING",
          // default: false,
          variables: [...value.variables],
        };

        this.form.push(newCreative);
        this.lastCreativityAdded = newCreative;
      } else {
        let index = value.index;
        delete value.index;
        let form = [...this.form];
        form[index] = { ...value };
        // this.form[index] = { ...value };

        if(form[index].status === "REJECTED") {
          form[index].status = this.$can('Approve', 'Bookmarks') ? "APPROVED" : "PENDING";
          // this.form[index].status = this.$can('Approve', 'Bookmarks') ? "APPROVED" : "PENDING";

          if(form[index].status === "APPROVED") {
            form[index].reason = null;
            // this.form[index].reason = null;
          }
        }


        this.$set(this.form, "creatives", form);
        this.form = [...form];
        this.lastCreativityAdded = value;

        this.editEntity.index = null;
      }
    },

    getIconByStatus(status) {
      return status === "APPROVED"
        ? "check"
        : status === "PENDING"
        ? "clock-o"
        : "times";
    },

    getEditEntity() {
      return {};
      /* return {
                    id: null,
                    entity: {}
                }; */
    },

    setEditEntity(index, entity) {
      this.editEntity = { index, ...entity };
    },

    selectedCreativesChanged(value) {
      this.lastSelectedCreatives = this.selectedCreatives;
      if (this.onlyOneCreativeSelected) {
        this.selectedCreatives = value.filter((v) => {
          return !this.lastSelectedCreatives.includes(v);
        });
      } else {
        this.selectedCreatives = value;
      }
    },
  },
};
</script>

<style scoped>
.alert.alert-danger {
  background-color: #ffc0a4;
  border: 1px solid #ef8157;
  color: #6f615b;
}

.marginBottom {
  margin-bottom: 100px;
}

.overflow-auto {
  overflow: auto;
}
</style>
