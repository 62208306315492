<template>
    <div>
      <BlackoutSlider :value="value.mon" @input="val => handleInput('mon', val)" day="MON" />
      <BlackoutSlider :value="value.tue" @input="val => handleInput('tue', val)" day="TUE" />
      <BlackoutSlider :value="value.wed" @input="val => handleInput('wed', val)" day="WED" />
      <BlackoutSlider :value="value.thu" @input="val => handleInput('thu', val)" day="THU" />
      <BlackoutSlider :value="value.fri" @input="val => handleInput('fri', val)" day="FRI" />
      <BlackoutSlider :value="value.sat" @input="val => handleInput('sat', val)" day="SAT" />
      <BlackoutSlider :value="value.sun" @input="val => handleInput('sun', val)" day="SUN" />
    </div>
</template>

<script>
  import BlackoutSlider from "src/app-components/form-elements/BlackoutSlider"

  export default {
    components: {
      BlackoutSlider
    },
    props: {
        value: Object
    },
    methods: {
        handleInput (day, val) {
            this.$emit('input', {
                ...this.value,
                [day]: val
            });
        }
    }
  };
</script>
<style>
</style>