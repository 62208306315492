<script>
import api from "src/api";
import Report from "./Report";
import {excelFormatPercentage, excelFormatNumber} from './excel_formats.js'

export default {
    name: "TimBrasilByCreatives",

    extends: Report,

    created() {
        this.tableCols = [
            {
                prop: "CampaignGroup",
                label: "ID"
            },
            {
                prop: "KW",
                label: "KW"
            },
            {
                prop: "Shortcode",
                label: "Shortcode"
            },
            {
                prop: "ScenarioType",
                label: "Scenario Type"
            },
            {
                prop: "Subcategory",
                label: "Subcategory"
            },
            {
                prop: "FirstScreen",
                label: "First Screen",
                width: 400,
                exportFormatter: this.formatterCreatives
            },
            {
                prop: "Audience",
                label: "Audience",
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Sent_Ping",
                label: "Sent Ping",
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Actives", 
                label: "Actives",
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Actives_Rate",
                label: "%Active",
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {
                prop: "Delivered",
                label: "Delivered",
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Delivery_Rate",
                label: "%DLV",
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {
                prop: "Acceptances",
                label: "Acceptances",
                minWidth: 400,
                formatter: this.formatterNumber,
                minWidth: 120,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Conversion_Rate",
                label: "%CR",
                formatter: this.formatterPercentage,
                exportFormatter: Number,
                excelFormat: excelFormatPercentage
            },
            {
                prop: "Blacklisted",
                label: "Blacklisted",
                formatter: this.formatterNumber,
                exportFormatter: Number,
                excelFormat: excelFormatNumber
            },
            {
                prop: "Requester",
                label: "Requester"
            },
        ];
        this.customTableKeyCols = [
        ];
    },
    
    methods: {
        /**
         * @Override
         */
        filterProp() {
            return "CampaignGroup";
        },

        /**
         * @Override
         */
        tableDimensions() {
            return [
            ]
        },

        tableRowClassName({row, rowIndex}) {
            if(!row.CampaignGroup){
                return 'success-row';
            }  
            
            return '';
        },

        async getData(query) {
            this.$emit("set-up-filters", {
                showGroupBySelect: false
            });
            //console.log("query.ql.where: ", query.ql.where);
            //delete query.ql.where;
            const response = await api.getTimBrasilByCreativesReport(query);
            const data = response.data.map(e => ({
                ...e,
                CampaignGroup: (e.CampaignGroup ? e.CampaignGroup.toString() : "")
            }));

            data.push(this.rollup(data));

            this.tableData = data;

            return {
                isTree: false
            }
        },
    }
}
</script>