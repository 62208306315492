<template>
  <div class="row">
    <div class="col-md-2" v-if="showManualStart">
      <label>{{ $t(switchText) }}</label>
      <br />
      <p-switch
        :disabled="disabled"
        :value="local.manual_start"
        type="primary"
        on-text="ON"
        off-text="OFF"
        @input="resetForm($event)"
      ></p-switch>
    </div>
    <div
      v-bind:class="{
        'col-md-5': showManualStart,
        'col-md-6': !showManualStart,
      }"
    >
      <div class="form-group">
        <label>{{ $t("Start date") }}</label>
        <el-date-picker
          :picker-options="pickerOptionsStart"
          :name="startDateTimeName"
          ref="start_date"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          @input="onDateChange($event, 'start_datetime')"
          :value="local.start_datetime"
          type="datetime"
          :disabled="manualStart || disabled"
          v-validate="!(manualStart || disabled) ? validator.start_date : ''"
        ></el-date-picker>
      </div>

      <div
        v-show="errors.has(startDateTimeName)"
        class="text-danger invalid-feedback"
        style="display: block;"
      >
        {{ errors.first(startDateTimeName) }}
      </div>
    </div>

    <div
      v-bind:class="{
        'col-md-5': showManualStart,
        'col-md-6': !showManualStart,
      }"
    >
      <div class="form-group">
        <label>{{ $t("End date") }}</label>
        <el-date-picker
          :picker-options="pickerOptionsEnd"
          :name="endDateTimeName"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          @input="onDateChange($event, 'end_datetime')"
          :value="local.end_datetime"
          type="datetime"
          :disabled="shouldDisableEndDatetime"
          v-validate="requiredEndtime ? 'required' : ''"
          :error="getError(endDateTimeName)"
        ></el-date-picker>
      </div>
      <div
        v-show="errors.has(endDateTimeName)"
        class="text-danger invalid-feedback"
        style="display: block;"
      >
        {{ errors.first(endDateTimeName) }}
      </div>
    </div>
  </div>
</template>

<script>
import PSwitch from "src/custom-components/UIComponents/Switch.vue";
import { DatePicker } from "element-ui";
import { updatableForm } from "src/app-components/FormMixin";
import moment from "moment";

export default {
  name: "date-programmable",
  mixins: [updatableForm],
  inject: ["getError", "parentValidator"],

  components: {
    PSwitch,
    [DatePicker.name]: DatePicker,
  },

  props: {
    value: {
      type: Object,
    },

    showManualStart: {
      type: Boolean,
      default: true,
      required: false,
    },

    requiredEndtime: {
      type: Boolean,
      default: false,
    },

    startDateTimeName: {
      type: String,
      default: "start_datetime",
    },
    endDateTimeName: {
      type: String,
      default: "end_datetime",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    startAfterDate: {
      type: null,
      default: null,
    },
    autoFillEndtime: {
      type: Boolean,
      default: false,
    },
    switchText: {
      type: String,
      default: "Manual start",
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.$validator = this.parentValidator;

    this.$validator.extend(
      this.startDateRuleName(),
      {
        getMessage: () => {
          return this.$t("Invalid date");
        },
        validate: (data, { campaign_start, end_date }) => {
          const m =
            typeof data !== "string" ? moment(data.value) : moment(data);
          const campaignStartUTC = m.format("YYYY-MM-DD HH:mm:ss");
          const campaignEndUTC = moment
            .tz(end_date, this.$store.getters.appUserTimezone)
            .format("YYYY-MM-DD HH:mm:ss");

          const isFirstStepOk =
            Date.parse(campaignStartUTC) > Date.parse(campaign_start);
          if (isFirstStepOk === false) {
            return false;
          }

          const isSecondStepOk =
            Date.parse(campaignEndUTC) > Date.parse(campaignStartUTC);
          if (end_date !== null && isSecondStepOk === false) {
            return false;
          }

          return true;
        },
      },
      { paramNames: ["campaign_start", "end_date"] }
    );

    this.$validator.extend(
      this.parentStartDateRuleName(),
      {
        getMessage: () => {
          return this.$t("Invalid date");
        },
        validate: (data, { parent_start }) => {
          const m =
            typeof data !== "string" ? moment(data.value) : moment(data);
          const campaignStartUTC = m.format("YYYY-MM-DD HH:mm:ss");

          const isFirstStepOk =
            Date.parse(campaignStartUTC) > Date.parse(parent_start);
          if (isFirstStepOk === false) {
            return false;
          }

          return true;
        },
      },
      { paramNames: ["parent_start"] }
    );

    /* No longer used
    this.$validator.extend(this.endDateRuleName(), {
      validate: (data) => {
        if (typeof data !== 'string') data = data.value;
        const startDate = this.$refs.start_date.value;
        return !(Date.parse(data) < Date.parse(startDate));
      }
    });
*/
  },

  computed: {
    shouldDisableEndDatetime() {
      if (this.disabled) {
        return true;
      }

      if (this.requiredEndtime) {
        return false;
      }

      return !this.requiredEndtime && this.manualStart;
    },

    local() {
      console.log(
        "LOCAL--------------",
        this.value ? this.value : this.createStore()
      );
      return this.value ? this.value : this.createStore();
    },

    manualStart() {
      return this.inverted ? !this.local.manual_start : this.local.manual_start;
    },

    validator() {
      return {
        start_date: {
          required: true,
          [this.startDateRuleName()]: {
            campaign_start: moment()
              .tz(this.$store.getters.appUserTimezone)
              .format("YYYY-MM-DD HH:mm:ss"),
            end_date: this.local.end_datetime ? this.local.end_datetime : null,
          },
          ...(this.startAfterDate && {
            [this.parentStartDateRuleName()]: {
              parent_start: this.parent_start,
            },
          }),
        },
        end_date: {
          required: true,
          //[this.endDateRuleName()]: true
        },
      };
    },
    parent_start() {
      return this.startAfterDate
        ? moment
            .tz(this.startAfterDate, this.$store.getters.appUserTimezone)
            .format("YYYY-MM-DD HH:mm:ss")
        : null;
    },

    pickerOptionsStart() {
      return {
        disabledDate: (time) => {
          if (!this.disabledDates) return false;

          if(this.value.end_datetime) {
            return (
              moment(time.getTime()).add(1, "day") <
              moment().tz(this.$store.getters.appUserTimezone)
              ||
              moment(time.getTime()) >
              moment(this.value.end_datetime).tz(this.$store.getters.appUserTimezone)
            );
          }

          return (
            moment(time.getTime()).add(1, "day") <
            moment().tz(this.$store.getters.appUserTimezone)
          );
        },
      };
    },
    
    pickerOptionsEnd() {
      return {
        disabledDate: (time) => {
          if (!this.disabledDates) return false;

          if(this.value.start_datetime) {
            return (
              moment(time.getTime()).add(1, "day") <=
              moment(this.value.start_datetime).tz(this.$store.getters.appUserTimezone)
            );
          }
          
          return (
            moment(time.getTime()).add(1, "day") <
            moment().tz(this.$store.getters.appUserTimezone)
          );
        },
      };
    },
  },

  methods: {
    startDateRuleName() {
      return `start_date_${this.startDateTimeName}`;
    },

    parentStartDateRuleName() {
      return `parent_start_date_${this.startDateTimeName}`;
    },

    endDateRuleName() {
      return `end_date_${this.endDateTimeName}`;
    },

    createStore() {
      return {
        manual_start: false,
        start_datetime: null,
        end_datetime: null,
      };
    },

    resetForm($event) {
      this.$emit("input", { value: null, prop: "start_datetime" });
      this.$emit("input", { value: null, prop: "end_datetime" });
      this.$emit("input", { value: $event, prop: "manual_start" });
    },

    onDateChange(value, prop) {
      if (!value) {
        this.$emit("input", {
          value: "",
          prop,
        });
        return;
      }

      let valueMoment = moment().tz(this.$store.getters.appUserTimezone);
      valueMoment.set(moment(value).toObject());
      if (prop == "start_datetime" || !this.local.start_datetime) {
        let now = moment().tz(this.$store.getters.appUserTimezone);
        let isBeforeNow = valueMoment.isBefore(now);

        this.$emit("input", {
          value: isBeforeNow
            ? now.add(3, "minutes").format("YYYY-MM-DD HH:mm:ss")
            : valueMoment.format("YYYY-MM-DD HH:mm:ss"),
          prop: prop,
        });
      } else {
        // let isBeforeStart = moment(valueMoment).add(5, "minutes").isBefore(this.local.start_datetime);
        let isBeforeStart = new Date(this.local.start_datetime) > new Date(valueMoment.format("YYYY-MM-DD HH:mm:ss"));

        this.$emit("input", {
          value: isBeforeStart
            ? moment(this.local.start_datetime)
                .add(5, "minutes")
                .format("YYYY-MM-DD HH:mm:ss")
            : valueMoment.format("YYYY-MM-DD HH:mm:ss"),
          prop: prop,
        });
      }

      if (this.autoFillEndtime && prop == "start_datetime") {
        const finishDateTime = moment(value, "YYYY-MM-DD HH:mm:ss")
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format("YYYY-MM-DD HH:mm:ss");
        this.$emit("input", { value: finishDateTime, prop: "end_datetime" });
      }
    },

    /* onDateChange(value, prop) {
      this.$emit('input', { value: value, prop: prop })
      if (this.autoFillEndtime && prop == "start_datetime") {
        const finishDateTime =
              moment(value, "YYYY-MM-DD HH:mm:ss")
                .hours(23)
                .minutes(59)
                .seconds(59)
                .format("YYYY-MM-DD HH:mm:ss")
        this.$emit('input', { value: finishDateTime, prop: "end_datetime" })
      }
    } */
  },
};
</script>
