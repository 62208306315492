<template>
  <span>
    <p-button
      data-toggle="tooltip"
      :disabled="working"
      :type="btype"
      :size="size"
      icon
      @click="handleDownload()"
    >
      <!-- <i v-if="working" class="fa fa-hourglass-end"></i>
      <i v-else class="fa fa-download"></i> -->
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t(tooltip)"
        placement="top"
      >
        <i v-if="working" class="fa fa-hourglass-end"></i>
        <i v-else class="fa fa-download"></i>
      </el-tooltip>
    </p-button>
  </span>
</template>

<script>
  import api from "src/api";
  import FileDownload from "js-file-download";
  import Spinner from "src/app-components/Spinner";

  export default {
    components: {
      Spinner,
    },
    props: {
      id: {
        type: Number,
      },
      size: {
        type: String,
        default: "sm",
      },
      url: {
        type: String,
        default: null,
      },
      filename: {
        type: String,
        default: null,
      },
      // Button type
      btype: {
        type: String,
        default: "success",
      },
      tooltip: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        working: false,
      };
    },

    methods: {
      async handleDownload() {
        this.working = true;

        const response = await api.downloadFile(
          this.url !== null ? this.url : this.id,
          false
        );

        var filename = this.filename
          ? this.filename
          : `target_base_${this.id}.txt`;

        if ("content-disposition" in response.headers) {
          const regex = /filename="(.+)"/;
          const match = response.headers["content-disposition"].match(regex);
          if (match && match.length >= 2) {
            filename = match[1];
          }
        }

        await FileDownload(response.data, filename);

        this.working = false;
      },
    },
  };
</script>
