<template>
<div class="lds-ring" :style="{width: size + 'px'}">
    <slot></slot>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            required: false
        },
        size: {
          type: Number,
          required: false,
          default: 40
        }
    },
}
</script>
<style>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border: 4px solid #e5e9f2;
  border-radius: 50%;
}
.lds-ring span {
    font-size: 10px !important;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    transform: translate(0, -50%);
    color: #606266;
    display: inline-block;
    vertical-align: middle;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 40px;
  height: 40px;
  margin: 0;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2BA9CD transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>