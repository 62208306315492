<template>
    <p-button type="danger" size="sm" icon @click="handleDelete(id)">
        <el-tooltip class="item" effect="dark" :content="$t('Delete')" placement="top">
            <i class="fa fa-trash"></i>
        </el-tooltip>
    </p-button>
</template>

<script>
import api from "src/api"
import swal from "sweetalert2";

export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        campaignType: {
            type: String,
        }
    },

    methods: {
        handleDelete(id) {
            let text = undefined;
            if (this.campaignType == "RETRY") text = this.$t("All descendant retries will be deleted too.");
            swal({
                title: "Delete?",
                text,
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Yes, delete it!",
                buttonsStyling: false,
                allowOutsideClick: false
            }).then(async result => {
                if (result.value) {
                    const res = await api.deleteCampaign(id);
                    if(res.status) {
                        this.$emit('deleted', res);
                    }
                }
            });
        }
    }
}
</script>
