<template>
  <div class="status-connection" style="background: white;">
    <el-table
      :data="connections"
      style="width: 100%;"
      row-key="status"
      default-expand-all
      stripe
    >
      <template v-for="column in tableColumns">
        <el-table-column
          :key="column.prop"
          :label="column.label"
          :width="column.width"
        >
          <template slot-scope="props">
            <span v-if="column.label !== 'ACTIONS'">{{
              props.row[column.prop] | two_decimals | formatNumber
            }}</span>
            <template
              v-for="action in actions"
              v-if="
                column.label === 'ACTIONS' &&
                  props.row.children !== undefined &&
                  checkName(props.row.name)
              "
              ><p-button
                :type="action.type"
                size="sm"
                icon
                :key="action.icon"
                class="mr-1"
                @click="open(action.icon, props.row.id, props.row.name)"
                v-if="isActive(props.row.status) === action.status"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="action.tooltip"
                  placement="top"
                >
                  <i :class="`fa fa-${action.icon}`"></i>
                </el-tooltip>
              </p-button>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { Message, MessageBox } from "element-ui";
import api from "src/api";

export default {
  name: "StatusConnection",

  filters: {
    two_decimals: function(value) {
      if (isNaN(value)) return value;
      return Math.round((value + Number.EPSILON) * 100) / 100;
    },
  },

  data() {
    return {
      connections: [],
      interval: null,

      targetId: null,
      targetName: null,
    };
  },

  created() {
    this.setTopNavbarTitle("Connection status");
    this.getStatusConnections();
  },

  mounted() {
    this.startInterval(false);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    ...mapMutations(["setTopNavbarTitle"]),

    startInterval(start = true) {
      if (start) this.getStatusConnections();

      this.interval = setInterval(() => {
        this.getStatusConnections();
      }, 5000);
    },

    setTarget(id, name) {
      this.targetId = id;
      this.targetName = name;
    },

    unsetTarget() {
      this.targetId = null;
      this.targetName = null;
    },

    checkName(name) {
      return name.includes("rcs") || name.includes("idemia") ? false : true;
    },

    isActive(status) {
      let statusArr = status.split(" ");
      if (statusArr.length === 4) {
        return parseInt(statusArr[1]) < parseInt(statusArr[3]);
      }
      return false;
    },

    open(action, id, name) {
      this.setTarget(id, name);
      switch (action) {
        case "play":
          this.start(id, name);
          break;
        case "pause":
          this.stop(id, name);
          break;
        case "repeat":
          this.restart(id, name);
          break;
        default:
          this.unsetTarget();
          break;
      }
    },

    async start() {
      clearInterval(this.interval);
      this.actionsConnections("start").then((res) => {
        setTimeout(() => {
          this.startInterval();
          if (res.success) {
            Message({
              type: "success",
              message: this.$t("Link started"),
            });
          }
        }, 1000);
      });
    },

    async stop() {
      MessageBox.confirm(
        this.$t(`Are you sure you want to pause the link?`),
        "Info",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          clearInterval(this.interval);
          this.actionsConnections("stop").then((res) => {
            this.startInterval();
            if (res.success) {
              // this.startInterval();
              Message({
                type: "success",
                message: this.$t("Link paused"),
              });
            }
          });
        })
        .catch(() => {});
    },

    async restart() {
      MessageBox.confirm(
        this.$t(`Are you sure you want to reset the link?`),
        "Info",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          clearInterval(this.interval);
          this.actionsConnections("stop").then((res) => {
            if (res.success) {
              this.actionsConnections("start").then((response) => {
                setTimeout(() => {
                  this.startInterval();
                  if (response.success) {
                    Message({
                      type: "success",
                      message: this.$t("Link reset complete"),
                    });
                  }
                }, 1000);
              });
            } else {
              // this.startInterval();
            }
          });
        })
        .catch(() => {});
    },

    async getStatusConnections() {
      await api.getStatusConnections().then((response) => {
        this.connections = response;
      });
    },

    async actionsConnections(action) {
      return await api.actionsConnections(
        this.targetId,
        this.targetName,
        action
      );
    },
  },

  computed: {
    tableColumns() {
      return [
        {
          prop: "name",
          label: "CONNECTION",
          width: 185,
        },
        {
          prop: "status",
          label: "STATUS",
          width: 125,
        },
        {
          prop: "failed",
          label: "FAILED",
          width: 80,
        },
        {
          prop: "queued",
          label: "QUEUED",
          width: 80,
        },
        {
          prop: "received",
          label: "RECEIVED",
          width: 90,
        },
        {
          prop: "sent",
          label: "SENT",
          width: 80,
        },
        {
          prop: "inbound",
          label: "INBOUND",
          width: 90,
        },
        {
          prop: "outbound",
          label: "OUTBOUND",
          width: 90,
        },
        {
          label: "ACTIONS",
          width: 125,
        },
      ];
    },

    actions() {
      return [
        {
          icon: "repeat",
          tooltip: "Restart",
          type: "success",
          status: false,
        },
        {
          icon: "pause",
          tooltip: "Stop",
          type: "warning",
          status: false,
        },
        {
          icon: "play",
          tooltip: "Play",
          type: "success",
          status: true,
        },
      ];
    },
  },
};
</script>

<style>
.status-connection table > thead > tr > th {
  padding-top: 10px !important;
}

/* .status-connection table > tbody:not(.has-gutter) > tr:nth-child(odd) {
  background-color: #fafafa !important;
} */

.status-connection .cell {
  padding: 0;
  text-align: center !important;
}

.status-connection tr th:first-of-type .cell,
.status-connection tr td:first-of-type .cell {
  text-align: left !important;
}

.status-connection th:first-of-type .cell,
.status-connection td:first-of-type .cell {
  padding-left: 10px;
}
</style>
