<template>
  <div class="row">
    <div class="col-md-12">
      <fg-input
        :value="local"
        type="text"
        @input="$emit('input', $event)"
        :name="name"
        :label="$t(label)"
        v-validate="'required|max:30'"
        :error="getError(name)"
      ></fg-input>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["getError", "parentValidator"],
  name: "menu-title",

  props: {
    value: null,
    label: {
      type: String,
      default: "Menu title"
    },
    name: {
      type: String,
      default: "menu-title"
    }
  },

  created() {
    this.$validator = this.parentValidator;
  },

  computed: {
    local() {
      return this.value ? this.value : "";
    }
  },

  data() {
    return {
    };
  },

  methods: {}
};
</script>

<style>
</style>
