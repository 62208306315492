<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t("Com. model") }}</label>
        <el-select
          :value="local"
          @input="$emit('input', $event)"
          size="large"
          :name="name"
          :clearable="true"
          v-validate="required ? 'required' : ''"
          :disabled="!isEditable"
          :placeholder="''"
        >
          <el-option
            v-for="option in commercials"
            :value="option.id"
            :label="option.name"
            :key="option.name"
          ></el-option>
        </el-select>
      </div>

      <div
        v-show="errors.has(name)"
        class="text-danger invalid-feedback"
        style="display: block"
      >
        {{ errors.first(name) }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/api";
import { Select, Option } from "element-ui";

export default {
  inject: ["getError", "parentValidator"],
  name: "commercial-model",

  props: {
    value: null,
    name: {
      type: String,
      default: "commercial",
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },

  async created() {
    this.$validator = this.parentValidator;
    this.getPlatformCommercialModels();
  },

  computed: {
    local() {
      if (isNaN(parseInt(this.value))) return null;

      return this.value ? this.value : null;
    },
  },

  data() {
    return {
      commercials: [],
    };
  },

  methods: {
    async getPlatformCommercialModels() {
      const commercials = await api.getPlatformCommercialModels();
      this.commercials = commercials.data;
    },
  },
};
</script>

<style></style>
