<template>
  <div class="card">
    <div class="card-header">
      <p class="mb-0">{{$t("New Services")}}</p>
    </div>
    <div class="card-body">
      <fg-input
        :error="(getError('name'))"
        :label="$t('Name')"
        type="text"
        name="name"
        @input="inputName($event)"
        v-model="form.name"
        :disabled="loading"
      />
      <div
        class="form-group"
        :class="getError('alias') !== '' ? 'has-danger' : ''"
      >
        <label class="w-100 text-left">{{ $t("Alias") }}</label>
        <input-tag
          class="w-100 form-control"
          :placeholder="$t('Press ENTER for each alias to confirm')"
          v-model="form.alias"
          :read-only="loading"
          @input="clearError('alias')"
        ></input-tag>
        <span
          class="text-danger invalid-feedback"
          :style="getError('alias') !== '' ? 'display: block;' : ''"
        >
          {{ $t(getError("alias")) }}
        </span>
      </div>
    </div>

    <div class="card-footer">
      <button
        @click="validate"
        class="btn btn-info btn-block"
        :disabled="loading"
      >
        {{ $t("Save") }}
      </button>
    </div>
  </div>
</template>

<script>
import InputTag from "vue-input-tag";
import api from "src/api";
import swal from "sweetalert2";
export default {
  name: "NewServices",
  components: {
    InputTag,
  },

  data() {
    return {
      form: {
        name: "",
        alias: [],
      },
      error: {
        name: "",
        alias: "",
      },

      loading: false,
      timeout: null,
    };
  },

  methods: {
    validate() {
      if (this.form.name === "") {
        this.error.name = "Name is required";
      }

      if (this.form.alias.length === 0) {
        this.error.alias = "Alias is required";
      }

      if (this.error.name === "" && this.error.alias === "") {
        this.createSubscriptionServices();
      }
    },

    clearError(item) {
      this.error[item] = "";
    },
    
    clearErrors() {
      this.error = {
        name: "",
        alias: "",
      };
    },

    getError(item) {
      return this.error[item];
    },

    inputName(name) {
      clearTimeout(this.timeout);
      this.clearError("name");
      this.timeout = setTimeout(() => {
        this.validateNameSubscriptionServices(name);
      }, 300);
    },

    async validateNameSubscriptionServices(name) {
      const data = { name };
      let res = await api.validateNameSubscriptionServices(data);
      if (!res.status) {
        this.error.name = this.$t("The service name has already been taken. Please, select another name.");
      }
    },
    
    async createSubscriptionServices() {
      this.loading = true;
      let res = await api.createSubscriptionServices(this.form);
      if (res.status) {
        this.form = {
          name: "",
          alias: [],
        };
        this.$emit("updateServices");
        this.clearErrors();
      }

      this.setAlert(res.status, res.message);
      this.loading = false;
    },

    setAlert(status, message) {
      const icon = status ? "success" : "error";
      const title = status
        ? "Service created successfully"
        : "An error occurred";
      const text = status ? "" : message;

      swal.fire({
        icon,
        title: this.$t(title),
        text,
      });
    },
  },
};
</script>
<style scoped>
.card {
  min-height: 320px;
}
</style>
<style>
.form-group .vue-input-tag-wrapper .new-tag {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.form-group .vue-input-tag-wrapper .input-tag {
  background: #51bcda;
  border-color: #51bcda;
  color: white;
}

.form-group .vue-input-tag-wrapper .input-tag .remove {
  color: white;
}
</style>
