<template>
  <div class="outbound-form card p-4">
    <el-steps :space="200" :active="stepActive" simple>
      <el-step
        :key="key"
        :status="stepStatus(key + 1)"
        :icon="step.icon"
        :title="$t(step.title)"
        v-for="(step, key) in steps"
      ></el-step>
    </el-steps>

    <div v-show="stepActive === 1">
      <div>
        <h2>{{ $t("Campaign name") }}</h2>
        <p>{{ $t("Add a name that will serve as a reference to identify the campaign.") }}</p>
        <el-input
          :class="!validName ? 'invalid-name' : 'mb-4'"
          :placeholder="$t('Enter the name of the campaign')"
          v-model="campaign.name"
          maxlength="50"
          show-word-limit
          size="small"
          @input="validateName"
        ></el-input>
        <p v-if="!validName" class="mt-1 aclaration-name">
          {{ $t("The campaign name has already been taken. Please, select another name.") }}
        </p>
      </div>

      <div class="mt-3">
        <h2>{{ $t("Planning") }}</h2>
        <p>{{ $t("In case you want to automate the campaign, you must enter the date and time of execution.") }}</p>

        <div class="my-2">
          <p class="d-inline-block mr-2">{{ $t("Manual start") }}</p>
          <el-switch size="mini" v-model="campaign.manual"></el-switch>
        </div>

        <el-date-picker
          size="small"
          :disabled="campaign.manual"
          type="datetime"
          placeholder="Start date"
          v-model="campaign.start_date"
          format="dd/MM/yyyy HH:mm"
          value-format="timestamp"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
    </div>

    <div v-show="stepActive === 2">
      <div>
        <h2>{{ $t("Service") }}</h2>
        <p>{{ $t("Choose the service from which you want to send the campaign.") }}</p>

        <el-select v-model="campaign.service_id" :placeholder="$t('Select service')" class="w-100" size="small">
          <el-option
            v-for="service in optionsServices"
            :key="service.value"
            :value="service.value"
            :label="service.label"
          >
          </el-option>
        </el-select>
      </div>

      <div class="mt-3">
        <h2>Template</h2>
        <p>{{ $t("Select the message template you want to send as a campaign.") }}</p>
        <el-select
          v-model="campaign.template_id"
          :placeholder="templates.length ? $t('Select the Template') : $t('This service has no templates created')"
          class="w-100"
          :disabled="templates.length === 0"
          size="small"
        >
          <el-option
            v-for="template in templatesOptions"
            :key="template.name"
            :label="template.name"
            :value="template.id"
          >
          </el-option>
        </el-select>

        <el-button class="mt-2" size="small" @click="viewTemplate = true" :disabled="!templateSelected">
          {{ $t("View template") }}
        </el-button>

        <el-dialog
          class="dialog-template"
          :title="templateSelected ? templateSelected.name : ''"
          :visible.sync="viewTemplate"
          width="320px"
        >
          <view-template :template="templateSelected" v-if="templateSelected" />
        </el-dialog>
      </div>
    </div>

    <div v-show="stepActive === 3" v-if="campaign.channel_id === 8">
      <div>
        <h2>{{ $t("Audience") }}</h2>
        <p>
          {{
            $t(
              "Add a file with the audience, respecting the format and taking into account the considerations at the level of variables within the message. The file must have a first “mobile” column, followed by n columns, one for each variable."
            )
          }}
        </p>

        <div class="d-flex mt-3">
          <file-upload-outbound
            @updateFileData="updateFileData($event)"
            :activeUpload="uploadTargetbase"
            fileType="targetbase"
            :disabled="disabledTargetbase"
            @updateNewFiles="updateNewFiles($event, 'targetbase')"
          />

          <div class="px-2"></div>

          <file-upload-outbound
            @updateFileData="updateFileData($event)"
            :activeUpload="uploadBlacklist"
            fileType="blacklist"
            :disabled="disabledBlacklist"
            @updateNewFiles="updateNewFiles($event, 'blacklist')"
          />
        </div>
      </div>

      <div style="max-width: 350px" v-if="fileTargetbase || fileBlacklist" class="mt-3">
        <p class="font-weight-bold h6 mb-2">{{ $t("Available files") }}</p>
        <div
          class="d-flex justify-content-between align-items-center data-file p-2 mb-2"
          :class="[
            notifyTargetbase ? 'notify' : '',
            !notifyTargetbase && fileTargetbase.processed && !fileTargetbase._count_success ? 'notify error' : ''
          ]"
        >
          <div>
            <p class="mb-0">
              <i class="mr-2" :class="notifyTargetbase ? 'el-icon-success success--text' : 'el-icon-document'"></i>
              <span class="font-weight-bold"
                >{{ notifyTargetbase ? $t("You uploaded the file") : "Target base" }}:</span
              >
              {{ fileTargetbase ? fileTargetbase.name : "-" }} {{ fileTargetbase._count_success ? `(${fileTargetbase._count_success})` : '' }}
            </p>
          </div>
          <div v-show="!notifyTargetbase" v-if="fileTargetbase" class="actions-files">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Uploading file')"
              placement="top"
              v-if="loadingTargetbase"
            >
              <el-button class="p-0 no-pointer" icon="el-icon-loading" type="text"></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Upload file')"
              placement="top"
              v-else-if="!fileTargetbase.path"
            >
              <el-button class="p-0" icon="el-icon-upload" type="text" @click="uploadTB"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="fileTargetbase._count_success ? $t('File processed') : $t('The file does not contain valid numbers')" placement="top" v-else>
              <el-button v-if="fileTargetbase._count_success" class="p-0" icon="el-icon-check" type="text"></el-button>
              <el-button v-else class="p-0" icon="el-icon-close" type="text"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" :content="$t('Delete file')" placement="top">
              <el-button
                :disabled="loadingTargetbase"
                class="p-0"
                icon="el-icon-delete"
                type="text"
                @click="deleteFile(fileTargetbase)"
              ></el-button>
            </el-tooltip>
          </div>
        </div>

        <div
          class="d-flex justify-content-between align-items-center data-file p-2"
          :class="notifyBlacklist ? 'notify' : ''"
        >
          <div>
            <p class="mb-0">
              <i class="mr-2" :class="notifyBlacklist ? 'el-icon-success success--text' : 'el-icon-document'"></i>
              <span class="font-weight-bold">{{ notifyBlacklist ? $t("You uploaded the file") : "Blacklist" }}:</span>
              {{ fileBlacklist ? fileBlacklist.name : "-" }}
            </p>
          </div>
          <div v-show="!notifyBlacklist" v-if="fileBlacklist" class="actions-files">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Uploading file')"
              placement="top"
              v-if="loadingBlacklist"
            >
              <el-button class="p-0 no-pointer" icon="el-icon-loading" type="text"></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('Upload file')"
              placement="top"
              v-else-if="!fileBlacklist.path"
            >
              <el-button class="p-0" icon="el-icon-upload" type="text" @click="uploadBL"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="$t('File processed')" placement="top" v-else>
              <el-button class="p-0" icon="el-icon-check" type="text"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" :content="$t('Delete file')" placement="top">
              <el-button
                :disabled="loadingBlacklist"
                class="p-0"
                icon="el-icon-delete"
                type="text"
                @click="deleteFile(fileBlacklist)"
              ></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div v-show="stepActive === 3" v-if="campaign.channel_id === 7">
      <div>
        <h2>{{ $t("Audience") }}</h2>
        <p>{{ $t("Choose your audience. (Min: 1 - Max: 30)") }}</p>

        <el-input
          class="w-25"
          v-model="campaign.date_range"
          size="small"
          type="number"
          :min="1"
          :max="30"
          @input="audienceGBM = null"
          @blur="checkGbmAudience"
        ></el-input>
      </div>
      <div class="mt-3">
        <span>Audience: {{ audienceGBM !== null ? audienceGBM : "-" }}</span>
        <el-button size="small" class="ml-3" type="primary" @click="calculateAudience" :disabled="loadingAudience">
          <i v-if="loadingAudience" class="el-icon-loading"></i>
          <span v-else>{{ $t("Calculate audience") }}</span>
        </el-button>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-end">
      <el-button size="small" @click="cancel" :disabled="loading">
        {{ $t("Cancel") }}
      </el-button>

      <el-button size="small" @click="stepActive--" :disabled="loading" v-if="stepActive > 1">
        {{ $t("Back") }}
      </el-button>
      <el-button size="small" @click="stepActive++" v-if="stepActive < 3" :disabled="!validateStep" type="primary">
        {{ $t("Continue") }}
      </el-button>

      <el-button
        size="small"
        type="primary"
        @click="handleSave"
        v-if="stepActive === 3"
        :disabled="!validateStep || loading"
      >
        <i class="el-icon-loading" v-if="loading"></i>
        <span v-else>{{ $t("Save") }}</span>
      </el-button>
    </div>
  </div>
</template>

<script>
  import { EventBus } from "src/eventBus";
  import { mapMutations } from "vuex";
  import api from "src/api";
  import {
    Input,
    Button,
    Select,
    Switch,
    Table,
    TableColumn,
    Tabs,
    TabPane,
    Step,
    Steps,
    DatePicker,
    Dialog,
  } from "element-ui";
  import FileDownload from "src/app-components/buttons-components/FileDownload";
  import DateRangePicker from "src/app-components/form-elements/DateRangePicker";

  import ViewTemplate from "src/app-components/broadcast/ViewTemplate";

  import FileUploadOutbound from "src/app-components/broadcast/FileUpload";
  export default {
    name: "BroadcastCampaignForm",

    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Switch.name]: Switch,
      [Table.name]: Table,
      [Tabs.name]: Tabs,
      [Step.name]: Step,
      [Steps.name]: Steps,
      [TabPane.name]: TabPane,
      [TableColumn.name]: TableColumn,

      [DatePicker.name]: DatePicker,
      [Dialog.name]: Dialog,
      "date-range-picker": DateRangePicker,

      "file-upload-outbound": FileUploadOutbound,
      "file-download": FileDownload,
      "view-template": ViewTemplate,
    },

    data() {
      return {
        campaign: {
          name: "",
          service_id: "",
          template_id: null,
          start_date: "",
          manual: true,

          files: [],

          date_range: 30,
          channel_id: null,
        },

        newFiles: {
          targetbase: [],
          blacklist: [],
        },

        services: [],

        loading: false,

        loadingTargetbase: false,
        notifyTargetbase: false,
        uploadTargetbase: false,

        loadingBlacklist: false,
        notifyBlacklist: false,
        uploadBlacklist: false,

        activeTab: "configuration",

        stepActive: 1,

        templates: [],
        viewTemplate: false,

        timerName: null,
        timerFiles: null,
        validName: true,

        audienceGBM: null,
        loadingAudience: false,
      };
    },

    watch: {
      "campaign.manual"() {
        if (this.campaign.manual) {
          this.campaign.start_date = "";
        }
      },

      "campaign.service_id"(newData, oldData) {
        if(oldData) {
          this.campaign.template_id = null;
        }
        this.templates = [];
        this.getChatbotTemplateByService(this.campaign.service_id);
        this.audienceGBM = null;
      },

      getFilesToValidate() {
        if (this.getFilesToValidate.length === 0 && this.loadingTargetbase) {
          this.loadingTargetbase = false;
          this.notifyTargetbase = true;
          setTimeout(() => {
            this.notifyTargetbase = false;
          }, 2500);
        }
      },
    },

    created() {
      this.setTopNavbarTitle(this.$t("Create campaign"));

      this.getServices();
    },

    mounted() {
      if (this.$route.params.id) {
        this.getCampaign(this.$route.params.id);
      } else {
        this.campaign.channel_id = this.$route.name === "BroadcastCampaignsFormWPPCreate" ? 8 : 7;
        if (this.campaign.channel_id === 8) {
          this.setTimerFiles();
        }
      }
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      async getChatbotTemplateByService(id) {
        let res = await api.getChatbotTemplateByService(id);
        this.templates = res.data ? res.data : [];
        this.loading = false;
        // console.log("getChatbotTemplateByService", res);
      },

      async validateName() {
        if (this.timerName) clearTimeout(this.timerName);
        this.timerName = setTimeout(async () => {
          let res = await api.validateNameCampaignOutbound(this.campaign.name);
          if (res.data) {
            this.validName = !res.data.exists;
          }
        }, 350);
      },

      async calculateAudience() {
        this.loadingAudience = true;
        setTimeout(async () => {
          let res = await api.calculateGbmAudience(this.campaign.service_id, this.campaign.date_range);
          this.audienceGBM = res.status ? res.data : 0;

          this.loadingAudience = false;
        }, 1);
      },

      checkGbmAudience() {
        if (this.campaign.date_range === "") {
          this.campaign.date_range = 30;
        } else {
          if (this.campaign.date_range > 30 || this.campaign.date_range < 1) {
            this.campaign.date_range = 30;
          }
        }
      },

      disabledDate(date) {
        return new Date().getTime() > new Date(date).getTime();
      },

      async handleSave() {
        let action = this.$route.params.id ? "editCampaignsOutbound" : "createCampaignsOutbound";
        let message = this.$route.params.id ? "Successfully edited campaign" : "Campaign created successfully";

        this.loading = true;
        setTimeout(async () => {
          try {
            let campaign = {
              ...this.campaign,
              audience: 0,
            };
            if (this.campaign.channel_id === 8) {
              delete campaign.date_range;
              campaign.audience = this.fileTargetbase._count_success;
            }

            let res = await api[action](campaign);
            if (res.status) {
              this.loading = false;
              this.$notify({
                message: this.$t(message),
                type: "success",
              });
              this.cancel();
            }
          } catch (error) {
            console.log("error catch handleSave", error);
            this.$notify({
              message: this.$t("An error occurred"),
              type: "Warning",
            });
            this.loading = false;
          }
        }, 5);
      },

      stepStatus(key) {
        let res = "wait";
        if (key === this.stepActive) return "process";
        if (key < this.stepActive) return "success";

        return res;
      },

      updateNewFiles(data, file_type) {
        data = data.map((file) => {
          return {
            id: "-",
            idComponent: file.id,
            name: file.name,
            progress: false,
            fileTypeComponent: file_type,
            file_type: file_type !== "targetbase" ? `${file_type.toUpperCase()}` : "TARGET_BASE",
          };
        });
        this.newFiles[file_type] = data;

        if (file_type === "blacklist") this.loadingBlacklist = false;
      },

      uploadTB() {
        this.uploadTargetbase = !this.uploadTargetbase;
        this.loadingTargetbase = true;
      },

      uploadBL() {
        this.uploadBlacklist = !this.uploadBlacklist;
        this.loadingBlacklist = true;
      },

      updateFileData(data) {
        this.campaign.files.push(data);
      },

      setTimerFiles() {
        this.timerFiles = setInterval(() => {
          if (this.getFilesToValidate.length) {
            const url = "/uploads/status/" + this.getFilesToValidate.join("-");
            api.execute("get", url).then((res) => {
              if (res) {
                if (res.status && res.data) {
                  res.data.forEach((file) => {
                    if (file.processed) {
                      let index = this.campaign.files.findIndex((f) => f.id === file.id);
                      if (index !== -1) {
                        this.campaign.files.splice(index, 1, file);
                      }
                    }
                  });
                }
              }
            });
          }
        }, 2000);
      },

      cancel() {
        this.$router.push({ name: "BroadcastCampaignsIndex" });
      },

      async getServices() {
        let res = await api.getServices();
        if (res.status) {
          this.services = [...res.data];
        }
      },

      async getCampaign(id) {
        let res = await api.getCampaignOutbound(id);
        if (res.status) {
          this.campaign = {
            ...res.data,
            start_date: res.data.start_date ? new Date(res.data.start_date).getTime() : "",
            manual: res.data.start_date === null,
          };

          if (this.campaign.channel_id === 8) {
            this.setTimerFiles();
          }
        }
      },

      deleteFile(file = null) {
        if (!file) return;

        if (file.fileTypeComponent) {
          EventBus.$emit("delete-file-outbound-campaign", file);
        } else {
          let index = this.campaign.files.findIndex((f) => f.id === file.id);
          if (index !== -1) {
            this.campaign.files.splice(index, 1);
          }
        }
      },
    },

    computed: {
      templatesOptions() {
        return this.templates.filter(template => template.status == 2);
      },

      templateSelected() {
        if (this.campaign)
          if (this.campaign.template_id) {
            return this.templates.find((t) => t.id === this.campaign.template_id);
          }
        return null;
      },

      pickerOptions() {
        return {
          disabledDate: (date) => {
            return this.disabledDate(date);
          },
        };
      },

      steps() {
        return [
          {
            title: "Settings",
            icon: "el-icon-setting",
          },
          {
            title: "Template",
            icon: "el-icon-video-play",
          },
          {
            title: "Audience",
            icon: "el-icon-upload",
          },
        ];
      },

      optionsServices() {
        let services = this.services.filter((service) => service.channel_id === this.campaign.channel_id);

        return services.map((s) => {
          return {
            label: `${s.name} ${s.agent_options ? `(${s.agent_options.phone})` : ""}`,
            value: s.id,
          };
        });
      },

      filesList() {
        return [...this.campaign.files, ...this.newFiles.targetbase, ...this.newFiles.blacklist];
      },

      fileTargetbase() {
        let targetbaseCampaign = this.campaign.files.find((f) => f.file_type === "TARGET_BASE");
        if (targetbaseCampaign) return targetbaseCampaign;

        return this.newFiles.targetbase.length ? this.newFiles.targetbase[0] : null;
      },

      fileBlacklist() {
        let blacklistCampaign = this.campaign.files.find((f) => f.file_type === "BLACKLIST");
        if (blacklistCampaign) return blacklistCampaign;

        return this.newFiles.blacklist.length ? this.newFiles.blacklist[0] : null;
      },

      disabledTargetbase() {
        if (this.newFiles.targetbase.length > 0) return true;
        if (this.campaign.files.findIndex((file) => file.file_type === "TARGET_BASE") !== -1) return true;

        return false;
      },

      disabledBlacklist() {
        if (this.newFiles.blacklist.length > 0) return true;
        if (this.campaign.files.findIndex((file) => file.file_type === "BLACKLIST") !== -1) return true;

        return false;
      },

      validateStep() {
        switch (this.stepActive) {
          case 1:
            if (!this.campaign.name || !this.validName) {
              return false;
            }
            if (!this.campaign.manual) {
              if (!this.campaign.start_date) {
                return false;
              }
            }
            break;
          case 2:
            if (!this.campaign.service_id || !this.campaign.template_id) {
              return false;
            }
            break;
          case 3:
            if (this.campaign.channel_id === 8) {
              let targetbase = false;
              this.campaign.files.forEach((f) => {
                if (f.file_type === "TARGET_BASE") {
                  if (f.processed) {
                    if(f._count_success) {
                      targetbase = true;
                    }
                  }
                }
              });
              return targetbase;
            }else {
              return this.audienceGBM ? true : false;
            }
        }

        return true;
      },

      getFilesToValidate() {
        if (!this.campaign.files) return [];
        return this.campaign.files.filter((file) => !file.processed).map((file) => file.id);
      },
    },
  };
</script>

<style scoped>
  .outbound-form {
    max-width: 1000px;
    margin: 0 auto;
  }

  .outbound-form h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .outbound-form div p {
    font-size: 12px;
    margin-bottom: 10px;
    color: #999;
  }

  .outbound-form div p.aclaration-name {
    color: red;
  }

  .outbound-form .el-icon-check {
    color: #67c23a;
    font-size: 14px;
  }
  .outbound-form .el-icon-close {
    color: red;
    font-size: 14px;
  }

  .outbound-form .el-steps--simple {
    background: transparent;
    padding-top: 0;
    padding-bottom: 25px;
  }

  .outbound-form .data-file {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: all 0.2s linear;
  }

  .outbound-form .data-file.notify {
    background: #ecfdf3;
    border: 1px solid #027a48;
    padding: 9px 8px !important;
  }
  .outbound-form .data-file.notify p {
    color: #027a48;
  }
  .outbound-form .data-file.notify.error {
    background: #fdecec;
    border: 1px solid #7a0202;
    padding: 9px 8px !important;
  }
  .outbound-form .data-file.notify.error p {
    color: #7a0202;
  }

  .outbound-form .data-file .actions-files {
    width: 50px;
  }

  .outbound-form .data-file .actions-files .no-pointer {
    cursor: default;
  }
</style>

<style>
  .outbound-form .el-step.is-simple .el-step__icon {
    font-size: 18px;
  }

  .outbound-form .el-step__icon.is-text {
    border: 0;
  }

  .dialog-template .el-dialog__body {
    padding: 10px;
    padding-top: 0;
  }

  .outbound-form .invalid-name .el-input__inner {
    border-color: red;
    color: red;
  }

  .outbound-form button {
    outline: 0 !important;
  }
</style>
