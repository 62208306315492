<template>
    <div class="float-left">
        <el-button
            :class="[freeContentType == o.component ? 'is-active' : '']"
            type="default"
            size="small"
            @click="change(o.component)"
            v-for="(o, index) in options"
            :key="index"
            :icon="o.icon"
            :disabled="disabled"
            ><span class="ml-2">{{ $t(o.label) }}</span></el-button
        >
    </div>
</template>

<script>
import { Button } from "element-ui";
import VModelMixin from "src/mixins/v-model-mixin.js";

export default {
    components: {
        [Button.name]: Button,
    },

    props:{
        disabled: {
            type: Boolean,
            default: false
        }
    },

    mixins: [VModelMixin("freeContentType")],

    data() {
        return {
            freeContentType: null,
            options: [
                {
                    component: "MediaContent",
                    label: "Media",
                    icon: "fa fa-play",
                },
                {
                    component: "TextContent",
                    label: "Text",
                    icon: "fa fa-file-text-o",
                },
            ],
        };
    },

    methods: {
        change(component) {
            this.freeContentType = this.freeContentType == component ? null : component;
        },
        clear(){
            this.freeContentType = null
        }
    },
};
</script>

<style lang="scss" scoped>
.fa {
    margin-right: 5px;
}
</style>