<template>
  <div >
    <fg-input
      :name="field.name"
      :label="field.tooltip"
      v-validate="field.required ? 'required' : ''"
      :value="value"
      @input="$emit('input', $event)"
      type="number"
      :disabled="disabled"
    ></fg-input>
    <!-- :error="getError(field.name)" -->
  </div>
</template>
<script>
export default {
  //inject: [ 'getError', 'parentValidator' ],

  props: ["placeholder", "label", "name", "value", "field", "disabled"],

  created() {
    //this.$validator = this.parentValidator;
  }
};
</script>