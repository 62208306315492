<template>
  <div>
    <form-product-assignation @submit="onSubmit"/>
  </div>
</template>
<script>
  import api from "src/api";
  import FormProductAssignation from 'src/app-components/FormProductAssignation';

  export default {
    components: {
      FormProductAssignation
    },

    methods: {
      onSubmit(data) {
        api.createProductAssignment(data).then(res => {
          this.$router.push({ path: `/product-assignation/index` });
        })
      }
    }
  }
</script>