<template>
  <div class="outbound-dropzone" :class="disabled ? 'disabled' : ''" :id="fileTypeNameDropzone">
    <p class="description-input">
      {{ $t(`Drop your ${fileType} files`) }}
    </p>
    <file-upload
      v-if="fileType === 'targetbase'"
      class="btn-file"
      :ref="fileTypeName"
      :input-id="`${fileTypeName}-id`"
      v-model="files"
      name="targetbase"
      :directory="false"
      :drop="`#${fileTypeNameDropzone}`"
      :post-action="fileUploadUrl + file_type"
      :headers="uploadHeaders"
      :disabled="disabled"
      @input-file="inputFile"
      @input-filter="checkDroppedFile"
    >
    </file-upload>
    
    <file-upload
      v-else
      class="btn-file"
      :ref="fileTypeName"
      :input-id="`${fileTypeName}-id`"
      v-model="files"
      name="blacklist"
      :directory="false"
      :drop="`#${fileTypeNameDropzone}`"
      :post-action="fileUploadUrl + file_type"
      :headers="uploadHeaders"
      :disabled="disabled"
      @input-file="inputFile"
      @input-filter="checkDroppedFile"
    >
    </file-upload>
  </div>
</template>

<script>
  import api from "src/api";
  import swal from "sweetalert2";
  import InputTag from "vue-input-tag";
  import { EventBus } from "src/eventBus";
  const VueUploadComponent = require("vue-upload-component");

  export default {
    name: "FileUploadOutbound",
    props: {
      value: {
        type: Array,
        default: [],
      },

      disabled: {
        type: Boolean,
        default: false,
      },
      
      fileType: {
        type: String,
        default: "targetbase",
      },

      activeUpload: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      InputTag,
      "file-upload": VueUploadComponent,
    },

    data() {
      return {
        files: [],

        upload: false,

        fileUploadUrl: api.getBaseUrl() + "/uploads?file_type=",
        uploadHeaders: api.getHeaders(),
        allowedFileTypes: ["txt", "csv"],
      };
    },

    watch: {
      files() {
        this.$emit("updateNewFiles", this.files);
      },

      activeUpload() {
        this.startUpload();
      },
    },

    created() {
      if (this.value.length) {
        this.files = [...this.value];
        this.upload = true;
      }
    },

    mounted() {
      this.disabledInputTag();

      EventBus.$on("delete-file-outbound-campaign", (file) => {
        if(file.fileTypeComponent === this.fileType) {
          // console.log(file);
          let index = this.files.findIndex(f => f.id === file.idComponent)
          if(index !== -1) {
            this.files.splice(index, 1);
          }
        }
      });
    },

    methods: {
      inputFile(newFile, oldFile) {
        if (newFile && oldFile && !newFile.active && oldFile.active) {
          if (newFile.xhr && newFile.xhr.status === 200) {
            const accountFile = newFile.response.data;

            this.$refs[this.fileTypeName].update(newFile, {
              accountFile: accountFile,
            });
            if (newFile.response.data) {
              this.upload = true;
              this.updateFileData(newFile.response.data);
            }
          }
        }
      },

      checkDroppedFile: function (newFile, oldFile, prevent) {
        if (!newFile) {
          return;
        }

        if (newFile && !oldFile) {
          // Filter files
          const ext = newFile.name
            .substr(newFile.name.lastIndexOf(".") + 1)
            .toLowerCase();
          if (!this.allowedFileTypes.includes(ext)) {
            swal.fire({
              icon: "error",
              title: "File type not supported",
              text: "Supported types: " + this.allowedFileTypes.join(", "),
            });
            return prevent();
          }
        }
      },

      startUpload() {
        this.$refs[this.fileTypeName].active = true;
        // console.log(this.$refs[this.fileTypeName]);
      },

      disabledInputTag() {
        let input = document.querySelector(
          `#${this.fileTypeNameDropzone}-dropzone`
        );
        if (input) {
          input.setAttribute("disabled", "disabled");
          input.setAttribute("style", "display: none;");
        }
      },

      updateFileData(data) {
        this.$emit("updateFileData", data);
        let index = this.files.findIndex(file => file.name === data.name)
        if(index !== -1) {
          this.files.splice(index, 1);
        }
      },
    },

    computed: {
      filesNames() {
        let names = [];
        this.files.forEach((file) => names.push(file.name));
        return names;
      },

      isDisabled() {
        if (this.files.length === 0 || this.disabled) {
          return true;
        }

        return false;
      },

      fileTypeName() {
        return `outbound-${this.fileType}`;
      },

      fileTypeNameDropzone() {
        return `outbound-${this.fileType}-dropzone`;
      },

      file_type() {
        return this.fileType !== "targetbase"
          ? `${this.fileType.toUpperCase()}`
          : "TARGET_BASE";
      },
    },
  };
</script>
<style scoped>
  .btn-file {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #51bcda;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .outbound-dropzone.disabled {
    opacity: .5;
  }
  
  .outbound-dropzone.disabled .btn-file {
    border-color: #ccc;
  }

  .description-input {
    position: absolute;
    text-align: center;
    width: 100%;
    top: calc(50% - 9px);
    margin-bottom: 0;
    font-size: 12px;
  }

  .outbound-dropzone {
    width: 50%;
    margin: 0;
    padding: 0;
    position: relative;
  }
</style>

<style>
  .btn-file label {
    cursor: pointer;
  }
  
  .outbound-dropzone.disabled .btn-file label {
    cursor: no-drop;
  }
</style>
