<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!--
        <div class="card-header">
          <h4 class="card-title">Create a new ping campaign</h4>
        </div>
        -->

        <div class="card-body">
          <tabbed-form
            :save-button-disabled="tabbedFormSaveButtonDisabled"
            :initial-value="initialTab"
            @submit="onSubmit()"
            @error-tabs="onErrorTabs($event)"
            :tabs="getTabs()"
          >
            <tab-pane
              :title="$t('Configuration')"
              id="configuration"
              :hasError="errorTabs.configuration"
            >
              <div data-vv-scope="configuration">
                <div class="row">
                  <div class="col-md-12">
                    <tab-campaign-configuration-ping
                      :entity="initialCampaignData"
                      :disabled="disabled"
                      @modelUpdated="onModelUpdated"
                    />
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane
              :title="$t('Audience')"
              id="target"
              :hasError="errorTabs.target"
            >
              <div class="row">
                <div class="col-md-12">
                  <tab-target-uploader
                    :entity="initialTargetData"
                    :disabled="disabled"
                    :disable-remove="
                      initialCampaignData
                        ? ![22, 15, 4].includes(initialCampaignData.status_id)
                        : false
                    "
                    @modelUpdated="onTargetUpdated"
                  />
                </div>
              </div>
            </tab-pane>

            <tab-pane
              :title="$t('Retry')"
              id="retry"
              :hasError="errorTabs.retry"
            >
              <div class="row">
                <div class="col-md-12">
                  <TabRetry
                    :entity="initialRetryData"
                    :disabled="disabled"
                    @modelUpdated="onRetryModelUpdated"
                    :parent-campaign-status="
                      initialCampaignData ? initialCampaignData.status_id : 1
                    "
                    :parent-campaign="form"
                  />
                </div>
              </div>
            </tab-pane>

            <tab-pane
              v-if="$can('HiddenVariables', 'Campaigns')"
              :title="$t('Hidden configuration')"
              id="hiddenConfiguration"
              :hasError="errorTabs.hiddenConfiguration"
            >
              <div class="row">
                <div class="col-md-12">
                  <TabHiddenConfiguration
                    :scenario-id="form.account_scenario_type_id"
                    @modelUpdated="onHiddenConfigurationUpdated"
                    :entity="form"
                  />
                </div>
              </div>
            </tab-pane>
          </tabbed-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, merge } from "lodash";

import TabbedFormMixin from "src/app-components/TabbedFormMixin";
import { defaultMixin } from "src/app-components/FormMixin";

import TabPane from "src/custom-components/UIComponents/Tabs/Tab";
import TabbedForm from "src/app-components/TabbedForm";
import TabCampaignConfigurationPing from "src/app-components/form-tabs/TabCampaginConfigurationPing.vue";
import TabTargetUploader from "src/app-components/form-tabs/TabTargetUploader.vue";
import TabRetry from "src/app-components/form-tabs/TabRetry.vue";
import TabHiddenConfiguration from "src/app-components/form-tabs/TabHiddenConfiguration.vue";

export default {
  mixins: [defaultMixin, TabbedFormMixin],

  provide() {
    // Pass to Mixin
    return {
      parentValidator: this.$validator,
      getError: this.getError,
    };
  },

  components: {
    [TabPane.name]: TabPane,
    TabbedForm,
    TabCampaignConfigurationPing,
    TabTargetUploader,
    TabRetry,
    TabHiddenConfiguration,
  },

  props: {
    initialCampaignData: {
      type: Object,
      required: false,
    },

    initialTargetData: {
      type: Array,
      required: false,
    },

    initialRetryData: {
      type: Object,
      required: false,
    },

    initialTab: String,

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        name: null,
        files: [],
        variables: [],
        type: "PING",
      },
    };
  },

  watch: {
    "form.account_scenario_type_id": function(newVal, oldVal){
      this.form.variables = [];
    }
  },

  mounted() {
    this.form.type =
      this.$route.name === "CampaignCreateFromPing" ? "PING" : "OTA";
  },

  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    getTabs() {
      return {
        configuration: false,
        target: false,
        retry: false,
        hiddenConfiguration: false,
      };
    },

    onTargetUpdated($event) {
      this.$set(this.form, "files", $event);
    },

    onModelUpdated($event) {
      this.form = this.mergeForm($event);
    },

    onHiddenConfigurationUpdated($event) {
      this.form.variables = merge(this.form.variables, $event);
    },

    onSubmit() {
      const data = cloneDeep(this.form);
      this.$emit("submit", data);
    },

    onRetryModelUpdated($event) {
      this.$set(this.form, "children", cloneDeep($event));
    },
  },
};
</script>

<style></style>
