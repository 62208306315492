<template>
  <div>
    <form-bookmark
      :title="$t('Update your template')"
      :initialBookmarkData="initialBookmarkData"
      :bookmarkProps="{ isEditable: isEditable }"
      @submit="handleSubmit"
    >
    </form-bookmark>
  </div>
</template>

<script>
import api from "src/api";
import Mixin from "./mixin.js";
import FormBookmark from "src/app-components/FormBookmark";
import { mapMutations } from "vuex";

export default {
  mixins: [Mixin],

  components: {
    "form-bookmark": FormBookmark,
  },

  async mounted() {
    this.setTopNavbarTitle(this.$t("Edit a template"));
    if ("id" in this.$route.params) {
      this.getBookmark(this.$route.params.id);
    }
  },

  data() {
    return {
      initialBookmarkData: {
        creativities: [],
      },
    };
  },

  computed: {
    isEditable() {
      if (this.$can("Edit", "Bookmarks")) {
        return true;
      }

      const status = this.initialBookmarkData.creativities.map((creativity) => {
        const [first, _] = Object.keys(creativity);
        return creativity[first].status;
      });

      return !status.includes("APPROVED");
    },
  },

  methods: {
    async getBookmark(id) {
      const res = await api.getBookmark(id);
      this.initialBookmarkData = res.data;
    },

    handleSubmit(data) {
      const id = data.id;
      api.updateTemplate(id, data).then((res) => {
        this.$router.push("/bookmarks/index");
      });
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>
