<template>
  <div
    class="row align-items-end my-2"
    :class="[errors.any(scope) ? 'col-with-errors' : '']"
  >
    <div class="text-left col-6">
      <label>{{ $t("Text") }}</label>
      <fg-input :error="errors.first('text', scope)">
        <el-input
          size="mini"
          v-model="action.text"
          name="text"
          :data-vv-scope="scope"
          v-validate="'required'"
          :maxlength="25"
          show-word-limit
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col-6">
      <DateRangePicker
        ref="dateTimePicker"
        :enableShortcuts="false"
        :error="errors.first('dateTime', scope)"
        :size="'mini'"
        :inputType="'datetimerange'"
        :row_classes="''"
        :column_classes="''"
        :initial-value="originalDates"
        @date-range-changed="dateFormatter($event)"
      />
      <input
        type="hidden"
        v-validate="'required'"
        name="dateTime"
        :data-vv-scope="scope"
        v-model="dateTime"
      />
    </div>
    <div class="text-left col-4">
      <label>{{ $t("Event title") }}</label>
      <fg-input :error="errors.first('title', scope)">
        <el-input
          size="mini"
          v-model="action.createCalendarEventAction.title"
          name="title"
          :data-vv-scope="scope"
          v-validate="'required'"
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col">
      <label>{{ $t("Event Description") }}</label>
      <fg-input :error="errors.first('description', scope)">
        <el-input
          size="mini"
          v-model="action.createCalendarEventAction.description"
          name="description"
          :data-vv-scope="scope"
          v-validate="'required'"
        >
        </el-input>
      </fg-input>
    </div>
    <div class="text-left col-auto">
      <el-button size="mini" type="primary" @click="submit">{{
        $t("Confirm")
      }}</el-button>
    </div>
  </div>
</template>

<script>
const uniqid = require("uniqid");
import { Input, Button } from "element-ui";
import DateRangePicker from "src/app-components/form-elements/DateRangePicker";
import moment from "moment";

export default {
  components: {
    [Input.name]: Input,
    [Button.name]: Button,
    DateRangePicker,
  },

  data() {
    const scope = `calendar-${uniqid()}`;
    return {
      scope,
      originalDates: [],
      dateTime: null,
      action: {
        text: "",
        postbackData: "",
        createCalendarEventAction: {
          startTime: "",
          endTime: "",
          title: "",
          description: "",
        },
      },
    };
  },

  created() {
    if (this.editing_prop) {
      if (this.editing_prop.action) {
        this.action = this.editing_prop.action;
        this.originalDates = [
          new Date(this.action.createCalendarEventAction.startTime),
          new Date(this.action.createCalendarEventAction.endTime),
        ];
      }
    } else {
      this.action = {
        text: "",
        postbackData: "",
        createCalendarEventAction: {
          startTime: "",
          endTime: "",
          title: "",
          description: "",
        },
      };
    }
  },
  props: {
    editing_prop: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    async submit() {
      if (await this.$validator.validate(`${this.scope}.*`)) {
        if (this.editing_prop) {
          this.$emit("edit", { action: this.action });
        } else {
          this.$emit("submit", { action: this.action });
        }
        this.clear();
      }
    },

    dateFormatter(dates) {
      console.log(dates);
      this.dateTime = dates;

      this.action.createCalendarEventAction.startTime = dates
        ? moment(dates[0]).toISOString()
        : null;
      this.action.createCalendarEventAction.endTime = dates
        ? moment(dates[1]).toISOString()
        : null;
    },

    clear() {
      this.action = {
        text: "",
        postbackData: "",
        createCalendarEventAction: {
          startTime: "",
          endTime: "",
          title: "",
          description: "",
        },
      };
      this.dateTime = null;
      this.$refs.dateTimePicker.setDaterange(null);
      this.$validator.reset(this.scope);
    },
  },
};
</script>
