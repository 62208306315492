<template>
    <modal :show="show" headerClasses="justify-content-center" @close="onCancel">
        <h4 slot="header" class="title title-up">Reject</h4>
        <fg-input :error="getError('reason')" label="Reason" labelClasses="text-center w-100">
            <textarea name="reason" v-validate="'required'" class="form-control" placeholder="Enter a reason..." v-model="reason"></textarea>
        </fg-input>
        <template slot="footer">
            <button type="button" class="btn btn-dark" @click="onCancel"><i class="fa fa-ban"></i> Cancel</button>
            <button type="button" class="btn btn-success mr-3" @click="onSave"><i class="fa fa-check"></i> OK</button>
        </template>
    </modal>
</template>

<script>
    import Modal from "src/custom-components/UIComponents/Modal";

    export default {
        name: 'reject-creativity-configuration',

        props: {
            show: Boolean,
        },

        components: {
            Modal,
        },

        data() {
            return {
                reason: "",
            }
        },

        watch: {
            show () {
                if (this.show) {
                    this.reason = "";
                    this.$validator.reset()
                }
            }
        },

        methods: {
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            async onSave() {
                const valid = await this.$validator.validateAll()
                if (valid) this.$emit("modal-save", this.reason);
            },

            onCancel() {
                this.$emit("modal-cancel");
            },
        }
    }
</script>

<style scoped>
</style>