<template>
  <div class="broadcast-analytics-index">
    <nav class="card p-3 m-2 nav-filters-analytics">
      <div class="d-flex justify-content-center align-items-center flex-wrap flex-lg-nowrap">
        <el-select class="mx-2 w-25 my-1" v-model="filters.types" placeholder="Select" size="small">
          <el-option label="Campaña + API" :value="1"></el-option>
          <el-option label="Campaña" :value="2"></el-option>
          <el-option label="API" :value="3"></el-option>
        </el-select>

        <el-select class="mx-2 w-25 my-1" multiple v-model="filters.services" placeholder="Select service" size="small">
          <el-option
            v-for="service in optionsServices"
            :key="service.value"
            :value="service.value"
            :label="service.label"
          >
          </el-option>
        </el-select>

        <el-select
          class="mx-2 w-25 my-1"
          multiple
          v-model="filters.templates"
          placeholder="Select template"
          size="small"
        >
          <el-option
            v-for="service in optionsServices"
            :key="service.value"
            :value="service.value"
            :label="service.label"
          >
          </el-option>
        </el-select>

        <div class="d-flex justify-content-center align-items-center w-100 my-1 div-date-picker">
          <el-date-picker
            class="mx-2"
            size="small"
            v-model="filters.start_date"
            type="date"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <span>hasta</span>
          <el-date-picker
            :picker-options="pickerOptions"
            class="mx-2"
            size="small"
            v-model="filters.end_date"
            type="date"
            :clearable="false"
          ></el-date-picker>
        </div>

        <el-button class="mx-2 my-1" type="primary" size="small">Apply</el-button>
        <el-button class="mx-2 my-1" size="small" @click="captureAndDownload">Download</el-button>
      </div>
    </nav>

    <div class="analytics-grid p-2">
      <div class="card py-2 px-3">
        <h2>{{ $t("GENERATED CAMPAIGNS") }}</h2>
        <p>13</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("API REQUEST") }}</h2>
        <p>13</p>
      </div>

      <div class="card py-2 px-3">
        <h2>{{ $t("SENT MESSAGES") }}</h2>
        <p>1247</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("RECEIVED MESSAGES") }}</h2>
        <p>873</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("MESSAGES READ") }}</h2>
        <p>355</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("MESSAGES WITH ERROR") }}</h2>
        <p>19</p>
      </div>

      <div class="card py-2 px-3">
        <chart-status />
      </div>
      <div class="card py-2 px-3 d-flex justify-content-center align-items-center">
        <chart-sent />
      </div>

      <div class="card py-2 px-3">
        <h2>{{ $t("MARKETING") }}</h2>
        <p>629</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("UTILITY") }}</h2>
        <p>561</p>
      </div>
      <div class="card py-2 px-3">
        <h2>{{ $t("AUTENTICATION") }}</h2>
        <p>57</p>
      </div>
      <div class="card py-2 px-3">
        <chart-trend />
      </div>
    </div>
  </div>
</template>

<script>
  import { Input, Button, Select, Tooltip, DatePicker } from "element-ui";
  import ChartSent from "src/app-components/broadcast/charts/ChartSent";
  import ChartStatus from "src/app-components/broadcast/charts/ChartStatus";
  import ChartTrend from "src/app-components/broadcast/charts/ChartTrend";
  import api from "src/api";
  import { mapMutations } from "vuex";

  import html2canvas from "html2canvas";

  export default {
    name: "BroadcastAnalyticsIndex",
    components: {
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      [DatePicker.name]: DatePicker,

      "chart-sent": ChartSent,
      "chart-status": ChartStatus,
      "chart-trend": ChartTrend,
    },

    data() {
      return {
        filters: {
          types: 1,
          services: [],
          templates: [],
          start_date: new Date(),
          end_date: new Date(),
        },

        services: [],
        // templates: [],

        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
      };
    },

    created() {
      if(!this.$can('Views', 'Broadcast')) this.$router.push({ name: "DashboardIndex" });
      this.setTopNavbarTitle("Broadcast");

      this.getServices();
    },

    methods: {
      ...mapMutations(["setTopNavbarTitle"]),

      captureAndDownload() {
        const element = document.querySelector(".analytics-grid");
        html2canvas(element).then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          // link.download = "captura.png";
          link.download = `Broadcast Analytics`;
          link.click();
        });
      },

      async getServices() {
        let res = await api.getServices();
        if (res.status) {
          this.services = [...res.data];
        }
      },
    },

    computed: {
      optionsServices() {
        let services = this.services.filter((service) => service.channel_id === 8);

        return services.map((s) => {
          return {
            label: `${s.name} (${s.agent_options.phone})`,
            value: s.id,
          };
        });
      },
    },
  };
</script>

<style scoped>
  .broadcast-analytics-index p,
  .broadcast-analytics-index h2,
  .broadcast-analytics-index .card {
    margin-bottom: 0;
  }

  .broadcast-analytics-index h2 {
    font-size: 16px;
  }

  .broadcast-analytics-index p {
    font-size: 24px;
    font-weight: bold;
  }

  .analytics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    background: #f4f3ef;
  }

  .analytics-grid > div {
    grid-column: 1 / 5;
  }

  @media screen and (min-width: 768px) {
    .analytics-grid > div:nth-child(1) {
      grid-column: 1 / 3;
    }

    .analytics-grid > div:nth-child(2) {
      grid-column: 3 / 5;
    }

    .analytics-grid > div:nth-child(3) {
      grid-column: 1;
    }

    .analytics-grid > div:nth-child(4) {
      grid-column: 2;
    }

    .analytics-grid > div:nth-child(5) {
      grid-column: 3;
    }
    .analytics-grid > div:nth-child(6) {
      grid-column: 4;
    }

    .analytics-grid > div:nth-child(7) {
      grid-column: 1 / 4;
    }
    .analytics-grid > div:nth-child(8) {
      grid-column: 4 / 5;
    }

    .analytics-grid > div:nth-child(9),
    .analytics-grid > div:nth-child(10),
    .analytics-grid > div:nth-child(11) {
      grid-column: 1;
    }

    .analytics-grid > div:nth-child(12) {
      grid-column: 2 / 5;
      grid-row: 4 / 7;
    }
  }

  /* @media screen and (min-width: 768px) { */
  .div-date-picker {
    max-width: 350px;
  }
  /* } */
</style>
<style>
  .apexcharts-menu-item {
    padding: 4px !important;
  }
</style>
