<template>
    <div>
        <div class="row">
            <div class="col-12 text-left">
                <label class="w-100 text-left">{{$t('Text message')}}</label>
                <el-input 
                v-model="form.contentMessage.text" 
                size="mini" 
                type="textarea" 
                :rows="2">
                </el-input>
            </div>
            <div class="col mt-1">
                <el-button class="w-100" type="success" size="mini" plain @click="submit">{{$t('Confirm')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Input } from "element-ui";
import { cloneDeep } from 'lodash'

export default {
    components: {
        [Button.name]: Button,
        [Input.name]: Input,
    },
    data() {
        return {
            form: {
                contentMessage: {
                    text: '',
                    suggestions: []
                }
            }
        }
    },
    methods: {
        submit(){
            if (this.form.contentMessage.text !== '') {
                this.$emit('input', cloneDeep(this.form));
                this.form.contentMessage.text = ''
            }
        }
    },
}
</script>