<template>
    <div>
        <SectionHeader :text="$t('Event Info')" :top-margin="false" />
        <div class="row">
            <div class="col-md-3">
                <event-name v-model="form.name" 
                    :isUnique="!hasId || form.name != originalName"
                    :disabled="false">
                </event-name>
            </div>

            <div class="col-md-4">
                <event-description v-model="form.description" 
                    :disabled="false">
                </event-description>
            </div>

            <div class="col-md-3">
                <label>{{ $t("Requests expires at midnight") }}</label>
                <br>
                <p-switch
                    @input="update($event)"
                    v-model="form.expires"
                    type="primary"
                    off-text="OFF"
                    on-text="ON"
                    ></p-switch>
            </div>
        </div>

        <SectionHeader text="Flight Date" />

        <div class="row">
            <div class="col-md-7">
                <date-programmable
                    :switch-text="$t('Define Active Window')" 
                    :required-endtime="form.manual_start"
                    :auto-fill-endtime="false"
                    v-model="dateProgrammed"
                    :inverted="true"
                ></date-programmable>
            </div>

        </div>

        <SectionHeader :text="$t('Event Cap')" />
        <div class="row">
            <div class="col-md-1">
                <switch-variable
                    vname="active-cap"
                    :vid="109"
                    :label="$t('Active Cap')"

                    :variables="form.variables"
                    @input="setVariable($event)"
                />

            </div>
            <div class="col-md-3">
                <select-variable
                    vname="cap-period"
                    :label="$t('Time cap')"
                    :visible="hasCap" 
                    :vid="110"
                    :options="{ 'daily': 'Daily', 'monthly': 'Monthly' }"

                    :variables="form.variables"
                    @input="setVariable($event)"
                />
                
            </div>

            <numeric-variable
                vname="cap-goal"
                :label="$t('Cap goal')"
                :visible="hasCap"
                :vid="93"
                :min="1"
                ref="cap-goal"
                
                :variables="form.variables"
                @input="setVariable($event)"
            />
            
        </div>

        <!-- <SectionHeader text="Retry" />
        <div class="row">
            <div class="col-auto">
                <ApiEventRetry
                    :active="retryActive"
                    @activeChanged="(val) => { this.retryActive = val }"
                    :retryTime="retryTime"
                    @retryTimeChanged="(val) => { this.retryTime = val }"
                />
            </div>
        </div> -->
    </div>
</template>

<script>
import api from "src/api";

import { updatableForm } from "src/app-components/FormMixin";
import DateProgrammedMixin from "src/app-components/DateProgrammedMixin"

import WithPingEnabled from "src/app-components/form-elements/WithPingEnabled"
import CommercialModel from "src/app-components/form-elements/CommercialModel";
import Price from "src/app-components/form-elements/Price";
import PerformanceGapTime from "src/app-components/form-elements/PerformanceGapTime";
import AmountUsers from "src/app-components/form-elements/AmountUsers";
import EventName from "src/app-components/form-elements/EventName";
import EventDescription from "src/app-components/form-elements/EventDescription";
import DateProgrammable from "src/app-components/form-elements/DateProgrammable";
// import ApiEventRetry from "src/app-components/form-elements/ApiEventRetry";
import NumericVariable from "src/app-components/form-elements/NumericVariable";
import SwitchVariable from "src/app-components/form-elements/SwitchVariable"
import SelectVariable from "src/app-components/form-elements/SelectVariable"
import SectionHeader from "src/app-components/form-elements/SectionHeader";
import { Portal } from 'portal-vue'
import Users from "src/app-components/form-elements/Users";
import PSwitch from "src/components/UIComponents/Switch.vue";

export default {
    inject: {
        parentValidator: 'parentValidator'
    },

    mixins: [
        updatableForm,
        DateProgrammedMixin("form")
    ],

    components: {
        AmountUsers,
        CommercialModel,
        PerformanceGapTime,
        "event-name": EventName,
        [DateProgrammable.name]: DateProgrammable,
        Price,
        WithPingEnabled,
        SwitchVariable,
        SelectVariable,
        NumericVariable,
        SectionHeader,
        Portal,
        Users,
        EventDescription,
        PSwitch,
        // ApiEventRetry
    },

    props: {
        commercialModelId: {
            type: Number,
            required: false,
            default: 0
        },

        price: {
            type: Number,
            required: false,
            default: 0
        },

        showGapTime: {
            type: Boolean,
            default: false
        },

        showAmountUsers: {
            type: Boolean,
            default: false
        },

        sizeAmountUsers: {
            type: Number,
            default: 0
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        this.$validator = this.parentValidator
    },

    computed: {
        hasId() {
            return (typeof this.form.id !== "undefined"); 
        },

        isManualStart() {
            return this.form.manual_start === true;
        },

        isWithPing() {
          if(typeof this.form.variables !== "undefined") {
            let active = this.form.variables.find(variable => {
              if(variable.variable) {
                return variable.variable.name === "active-ping";
              }
            });
            return active ? active.value : false;
          }

          return false;
        },

        hasCap() {
          if(!this.form.variables) return false;

          let index = this.form.variables.findIndex(variable => {
            if(variable.variable) {
              return variable.variable.name === "active-cap";
            }
          });

          if(index !== -1) {
            return this.form.variables[index].value !== "none";
          }

          return false;
        },
    },

    methods : {
        afterModelPatched () {
            this.originalName = this.form.name
        },

        setVariable($event) {
          let { variable_id, value, variable } = $event;

          if(variable.name === 'active-cap' && !value) {
            this.unsetVariables('active-cap');
            this.unsetVariables('cap-period');
            this.unsetVariables('cap-goal');

            this.$emit('removeVariables')
            return;
          }

          if(variable_id) {
            let index = this.form.variables.findIndex(variable => variable.variable_id == variable_id);
            if(index > -1) {
              this.form.variables[index].value = value;
            }else{
              this.form.variables.push($event);
            }
          }
        },

        unsetVariables(name) {
          let index = this.form.variables.findIndex(v => {
            if(v.variable){
              return v.variable.name == name;
            }
            return false;
          });

          this.form.variables.splice(index, 1);
        },
    },

    watch: {
        commercialModelId(nv) {
            this.form.commercial_model_id = nv
        },

        price(nv) {
            this.form.price = nv
        },
    },

    data() {
        return {
            form: {
                variables: [],
                manual_start: null,
                name: null,
                description: null,
                end_datetime: null,
                start_datetime: null,
            },
            originalName: null
        }
    }
}
</script>

<style>

</style>
