<template>
	<div class="modal-content bookmark-modal">
   	<div class="modal-body">
			<div v-if="$store.state.bookmarks === null">
				<loading :active="true" :is-full-page="false"></loading>
			</div>
   		<div v-else class="row">
      		<div class="col-md-4" v-for="bookmark in $store.state.bookmarks" :key="'bookmark-'+bookmark.id">
         		<card-bookmark :bookmark="bookmark" :actions="['select']" v-on:selected="emitSelected">
         			<!-- <template slot-scope="slotProps" slot="action">
            			<p-button
               			@click="emitSelected(bookmark)"
            				type="success"
               			:title="$t('Select bookmark')"
               			icon
               			:outline="true"
               			class="btn-sm"
               			round
            			><i class="fa fa-check"></i>
            		</p-button>
            		</template> -->
         		</card-bookmark>
      		</div>
      	</div>
		</div>
	</div>
</template>

<script>
import api from "src/api";
import CardBookmark from "./CardBookmark";
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
		CardBookmark,
		Loading,
    },

    computed: {
      allBookmarks() {
        return this.bookmarks;
      },
	 },
	 
	props: {
		bus: { type: Object, default: null }
	},

   data() {
      return {
         bookmarks: null
      };
   },

   mounted() {
		this.$store.dispatch("loadBookmarks");
    	/*api.getBookmarks(this.getQuery()).then(response => {
         if (response.data.length > 0) {
            //this.page += 1;
				//this.bookmarks.push(...response.data);
				this.bookmarks = response.data;
         }
      }).catch(err => {
         console.error(err);
      });*/
   },

   methods: {
		/*getQuery(){
			return {
				ql : {
					contain : {
						"AccountScenarioType":{},
						"AccountScenarioType.ScenarioType":{},
						"AccountScenarioType.ScenarioType.SatCallToAction":{},
						"BookmarkVariables":{},
						"BookmarkCallToActionVariable":{},
						"BookmarkCallToActionVariable.ScenarioVariable":{},
						"Advertiser":{},
						"PerfText":{},
						"PlatformCommercialModel":{},
						"PlatformCategory":{},
						"PlatformSubcategory.Svg":{},
						"BookmarkMenuEntries":{}
					},
					where: {
						"Bookmark.archived": false
					},
					order: [
						"FIELD(Bookmark.status, 'PENDING', 'APPROVED', 'REJECTED')",
						"Bookmark.created DESC"
					]
				}
			};
		},*/

		emitSelected(event){
			if(this.bus){
				this.bus.$emit("bookmarkSelected", event);
			}
		}
   }

};
</script>
<style>
.bookmark-modal {
	left: 0 !important;
	padding-top: 0 !important;
	border-radius: 0 !important;
	background-color: #f4f3ef;
}

.bookmark-modal .modal-body {
	padding: 20px;
	min-height: 400px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  background-color: DodgerBlue;
}

/*.flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}*/
</style>