<template>
  <div>
    <form-bookmark
      :templatetoCopyId="copiedTemplateId"
      title="Create a new template"
      @submit="handleSubmit"
      :updateCommercialModel="true"
    ></form-bookmark>
  </div>
</template>

<script>
import api from "src/api";
import Mixin from "./mixin.js";
import FormBookmark from "src/app-components/FormBookmark";
import { mapMutations } from "vuex";

export default {
  name: "TemplatesForm",
  mixins: [Mixin],

  components: {
    FormBookmark,
  },

  data() {
    return {
      copiedTemplateId: null,
    };
  },

  mounted() {
    this.setTopNavbarTitle(this.$t("Create a new template"));
    if (this.$route.params.template_id) {
      this.copiedTemplateId = this.$route.params.template_id;
    }
  },

  methods: {
    handleSubmit(data) {
      api.createTemplate(data).then((res) => {
        this.$router.push("/bookmarks/index");
      });
    },

    ...mapMutations(["setTopNavbarTitle"]),
  },
};
</script>

<style></style>
