<template>
  <div class="card chat">
    <div class="info-user d-flex">
      <el-button
        style="color: white"
        type="text"
        icon="el-icon-back"
        circle
        @click="goBack"
      ></el-button>
      <p class="user-name" v-if="conversation.user">
        {{ displayName }}
      </p>
    </div>
    <div class="messages" id="messagesChat">
      <template v-if="conversation.messages">
        <div
          class="message"
          :class="[hasTail(index), message.messageId, setFrom(message.origin)]"
          v-for="(message, index) in conversation.messages"
          :key="message.messageId"
        >
          <div class="data-message d-flex justify-content-between">
            <span class="mr-2 font-weight-bold">{{
              message.origin === "HUMAN" ? "User" : "Bot"
            }}</span>
            <span>
              {{ formatDate(message.timestamp) }}

              <el-tooltip
                class="item"
                effect="dark"
                :content="statusMessage(message)"
                placement="top"
                v-if="message.origin === 'BOT' && !testMode"
              >
                <span>
                  <i
                    class="el-icon-check font-weight-bold"
                    :class="message.read ? 'read' : ''"
                  ></i>
                  <i
                    class="el-icon-check font-weight-bold ml--6"
                    :class="message.read ? 'read' : ''"
                    v-if="message.delivered"
                  ></i>
                </span>
              </el-tooltip>
            </span>
          </div>

          <div
            v-html="textMarked(message.origin === 'BOT' ? message.text.text : message.text)"
            v-if="message.text && checkOrigin(message.origin)"
          ></div>

          <RichcardMessage
            :message="message.richcard"
            @sendNewMessage="sendNewMessage($event)"
            v-if="message.richcard"
          />

          <CarouselMessage
            :messages="message.carousel"
            @sendNewMessage="sendNewMessage($event)"
            v-if="message.carousel"
          />
        </div>
      </template>
    </div>

    <div class="p-2 new-message-test" v-if="testMode">
      <el-input
        placeholder="Please input"
        size="mini"
        v-model="newMessage"
        @change="sendNewMessage"
      >
        <el-button
          slot="append"
          icon="el-icon-s-promotion"
          @click="sendNewMessage"
        ></el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
  import { marked } from "marked";
  import moment from "moment";
  import { Button, Tooltip, Input } from "element-ui";
  import RichcardMessage from "src/app-components/chatbot/conversations/RichcardMessage";
  import CarouselMessage from "src/app-components/chatbot/conversations/CarouselMessage";
  export default {
    name: "Conversation",
    props: ["editable", "conversation", "testMode"],

    components: {
      [Button.name]: Button,
      [Tooltip.name]: Tooltip,
      [Input.name]: Input,
      RichcardMessage,
      CarouselMessage,
    },

    data() {
      return {
        newMessage: "",
      };
    },

    watch: {
      "conversation.messages"() {
        setTimeout(() => {
          var messagesDiv = document.getElementById("messagesChat");
          messagesDiv.scrollTop = messagesDiv.scrollHeight;
        }, 1);
      },
    },

    computed: {
      displayName() {
        if(this.conversation) {
          if(this.conversation.user) {
            return this.conversation.user.displayName;
          }
        }

        return "";
      }
    },

    methods: {
      checkOrigin(origin) {
        return origin.toUpperCase() === "BOT" || origin.toUpperCase() === "HUMAN";
      },

      formatDate(date) {
        if (date.$date) {
          if (date.$date.$numberLong) {
            return moment(parseInt(date.$date.$numberLong)).format(
              "DD/MM/YY HH:mm"
            );
          }
        }
        return "";
      },

      textMarked(string) {
        if (!string) return "";

        return marked.parse(string);
      },

      hasTail(index) {
        let { messages } = this.conversation;

        if (messages) {
          if (messages[index]) {
            let origin = messages[index].origin;
            if (messages[index + 1]) {
              if (messages[index + 1].origin === origin) {
                return "no-tail";
              }
            }
          }
        }

        return "";
      },

      statusMessage(message) {
        let res = "";
        if (message.delivered) {
          res = `Delivered: ${this.formatDate(message.delivered)}`;
        }

        if (message.read) {
          res += ` - Read: ${this.formatDate(message.read)}`;
        }

        return res;
      },

      setFrom(origin) {
        if (this.testMode) {
          return origin === "HUMAN" ? "from-me" : "from-them";
        } else {
          return origin === "HUMAN" ? "from-them" : "from-me";
        }
      },

      sendNewMessage(message = null) {
        if (!message && !this.newMessage) return;

        let messageData = {
          destination: "BOT",
          message: message ? message : this.newMessage,
          messageId: Math.floor(Math.random() * 10000000000),
          origin: "HUMAN",
          timestamp: {
            $date: {
              $numberLong: moment().valueOf(),
            },
          },
        };

        this.$emit("newMessage", messageData);
        this.newMessage = "";
      },

      goBack() {
        this.$emit("goBack");
      },
    },
  };
</script>

<style scoped>
  .card.chat {
    height: calc(100vh - 200px);
    margin: auto;
    margin-bottom: 0;
    width: 100%;
    max-width: 400px;
    overflow: hidden;
  }
  .card.chat .info-user {
    height: 50px;
    background: #248bf5;
    box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.4);
  }

  .card.chat .new-message-test {
    box-shadow: 1px -4px 8px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px -4px 8px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px -4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .card.chat .info-user p {
    margin-bottom: 0;
    line-height: 50px;
    font-size: 18px;
    padding-right: 15px;
    padding-left: 5px;
    color: #fff;
    font-weight: bold;
  }

  .card.chat .messages {
    height: calc(100vh - 250px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px;

    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
  }

  .card.chat .messages::-webkit-scrollbar {
    width: 4px;
  }

  .card.chat .messages::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }

  .messages .message {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem 0;
    position: relative;
    word-wrap: break-word;
    margin: 0.2rem 0;
    margin-bottom: 0.5rem;
    width: fit-content;
  }

  .messages .message > div {
    padding: 0 0.875rem;
  }

  .messages .message > div:first-child {
    padding-bottom: 0.15rem;
  }
  .messages .message > div:last-child {
    padding-top: 0.15rem;
  }

  .messages .message::before,
  .messages .message::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  .messages .message .data-message {
    font-size: 10px;
  }

  .messages .message.from-me {
    align-self: flex-end;
    background-color: #e5e5ea;
    color: #000;
  }

  .messages .message.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #e5e5ea;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  .messages .message.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  .messages .message.from-them {
    align-items: flex-start;
    background-color: #248bf5;
    color: #fff;
  }

  .messages .message.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #248bf5;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  .messages .message.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  .messages .no-tail {
    margin-bottom: 0.2rem;
  }
  .messages .no-tail::before {
    display: none;
  }

  .messages .message .ml--6 {
    margin-left: -6px;
  }

  .messages .message .read {
    color: #248bf5;
  }
</style>

<style>
  .messages .message {
    font-size: 12px;
  }

  .messages .message p,
  .messages .message ol,
  .messages .message ul {
    margin-bottom: 0;
  }

  .card.chat button:focus {
    outline: 0;
  }
</style>
