<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group has-label">
        <label>{{ $t("Target base") }}</label>
        <el-select
          :disabled="disabled"
          size="large"
          :value="valueFromVariables"
          @input="emitVariables"
          v-validate="'required'"
          :name="name"
          multiple
        >
          <el-option
            v-for="option in filters"
            :value="option.key"
            :label="$t(option.label)"
            :key="option.key"
          ></el-option>
        </el-select>
        <div
          v-show="errors.has(name)"
          class="text-danger invalid-feedback"
          style="display: block;"
        >
          {{ errors.first(name) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "src/custom-components/UIComponents/Inputs/Checkbox";

const PENDING_VALUES = [1, 12, 22, 20, 21, 19, 18, 13, 23];
const UNDELIVERED_VALUES = [7, 2];
const PENDING_VALUES_WITH_PING = [1, 22, 20];
const UNDELIVERED_VALUES_WITH_PING = [7, 2, 14, 19, 13, 21, 12, 23];

export default {
  name: "RetryOptions",
  components: {
    [Checkbox.name]: Checkbox,
  },
  props: {
    value: Object,
    channels: Array,
    name: {
      type: String,
      default: "retryOptions",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withPing: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["getError", "parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  /* data() {
    return {
      filters: [
        { key: "PENDING", label: "Pending" },
        { key: "UNDELIVERED", label: "Undelivered" },
      ],
    };
  }, */
  /* watch: {
    pendingValues: {
      handler() {
        console.log("Pending values " + JSON.stringify(this.pendingValues));
      },
      immediate: true,
    },
    undeliveredValues: {
      handler() {
        console.log(
          "Undelivered values " + JSON.stringify(this.undeliveredValues)
        );
      },
      immediate: true,
    },
  }, */
  computed: {
    filters() {
      let filters = [
        { key: "PENDING", label: "Pending" },
        { key: "UNDELIVERED", label: "Undelivered" }
      ];

      if (this.channels.find((channel) => channel.id == 5)) {
        filters.push({ key: "ACTIVESIM", label: "Active SIM" });
      }

      return filters;
    },

    pendingValues() {
      if (this.withPing) {
        return PENDING_VALUES_WITH_PING;
      } else {
        return PENDING_VALUES;
      }
    },
    undeliveredValues() {
      if (this.withPing) {
        return UNDELIVERED_VALUES_WITH_PING;
      } else {
        return UNDELIVERED_VALUES;
      }
    },
    activeSimValues() {
      return [ 3 ];
    },
    valueFromVariables() {
      let checker = (arr, target) => target.some((v) => arr.includes(v));
      let variables = this.value;
      let options = [];

      let retryStatusesId = variables["retry-statuses-id"];

      if (retryStatusesId) {
        retryStatusesId = retryStatusesId.value
          .split(",")
          .map((n) => Number(n));

        if (checker(retryStatusesId, this.pendingValues)) {
          options = [...options, "PENDING"];
        }
        if (checker(retryStatusesId, this.undeliveredValues)) {
          options = [...options, "UNDELIVERED"];
        }
        if (checker(retryStatusesId, this.activeSimValues)) {
          options = [...options, "ACTIVESIM"];
        }
      }

      return options;
    },
  },
  methods: {
    emitVariables(options) {
      let values = [];
      if (options.includes("PENDING")) {
        values = [...values, this.pendingValues];
      }

      if (options.includes("UNDELIVERED")) {
        values = [...values, this.undeliveredValues];
      }
      
      if (options.includes("ACTIVESIM")) {
        values = [...values, this.activeSimValues];
      }

      const option = {
        "retry-statuses-id": {
          variable_id: 34,
          value: values.join(","),
        },
      };

      this.$emit("input", option);
    },
  },
};
</script>

<style scoped></style>
