<template>
  <div>
    <form-campaign
        :tabbed-form-save-button-disabled="workingSave"
        @submit="onSubmit" 
        :initialBookmarkData="initialBookmarkData"
        :bookmarkProps="{ isEditable: isEditable }"
        :initialCampaignData="initialCampaignData"
        :initialTargetData="initialTargetData"
        :initialRetryData="initialRetryData"
        :initialSActiontData="initialSActionData"
        :initial-tab="$route.params.tab"
        @creativesSelected="onCreativesSelected"
        :enableFallback="true"
        :disabled="disabled">
    </form-campaign>
  </div>
</template>

<script>
import api from "src/api";
import FormCampaign from 'src/app-components/FormCampaign';
import constants from 'src/views/campaigns/constants'
import RetryCrudMixin from 'src/app-components/RetryCrudMixin';
import { mapMutations } from "vuex";
import WorkingMixin from 'src/app-components/WorkingMixin';

export default {
  components: {
    FormCampaign
  },

  mixins: [
    RetryCrudMixin(),
    WorkingMixin("Save")
  ],

  async created() {
      if ("id" in this.$route.params) {
        const response = await api.getCampaign(this.$route.params.id)

        this.initialTargetData   = response.data.files;
        // this.initialBookmarkData = response.data.bookmark
        this.initialBookmarkData = response.data.template;
        this.initialCampaignData = response.data;
        this.initialSActionData  = response.data.second_actions;
        this.initialRetryData  = response.data.children[0];
      }

      /*
        this.processOperationLog(111, {
          retries: [
            { appId: 1 },
            { appId: 2 },
            { appId: 3 },
            { appId: 4 },
            { appId: 5 },
            { appId: 6 },
            { appId: 7 },
          ],
          operationsLog: {
            2: "DELETED",
            1: "DELETED",
            3: "CREATED",
            4: "CREATED",
            5: "CREATED",
            6: "UPDATED",
            7: "UPDATED",
          }
        });
      */
    },

    computed: {
      isEditable() {
        const hasId = ("id" in this.initialBookmarkData);
        return !hasId && !this.disabled;
      },
      disabled () {
        return ! constants.StatusIdCanEditCampaign.includes(this.initialCampaignData.status_id)
      }
    },

    mounted () {
      this.setTopNavbarTitle(this.$t("Edit a campaign"));
    },

    data() {
        return {
            initialBookmarkData: {},
            initialCampaignData: {},
            initialTargetData: [],
            initialSActionData: [],
            initialRetryData: {}
        }
    },

  methods: {
    async onCreativesSelected($event) {
        if(typeof $event !== "undefined") 
          this.$set(this.initialCampaignData, "perf_text", $event)
    },

    async onSubmit($event) {
      try {
        this.setWorkingSave(true)
        await api.updateCampaign($event.id, { ...$event, children: undefined });
        await this.processOperationLog($event.id, $event.children);
        this.$router.push({ path: `/campaigns/planning` });
      } finally {
        this.setWorkingSave(false)
      }
    },

    ...mapMutations(["setTopNavbarTitle"]),
  }
}
</script>

<style>

</style>
