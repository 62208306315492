<template>
  <div :class="getWidth()">
    <div class="form-group">
      <label>{{ $t(field.tooltip) }}</label>
      <textarea
        class="form-control"
        :class="{
          'has-danger': error,
          'has-success': value && !error && focused,
        }"
        rows="3"
        :disabled="disabled"
        :name="field.name"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="focused = true"
        @blur="focused = false"
        v-validate="validations()"
        :maxlength="maxLength"
        ref="textarea"
      ></textarea>
      <div class="row m-0">
        <div class="col" v-if="error">
          <span
            class="text-danger text-left w-100 invalid-feedback"
            style="display: block;"
            >{{ error }}</span
          >
        </div>
        <div class="col">
          <label
            class="w-100 text-right"
            :class="{
              'text-danger': this.sampleText.length > maxLength,
            }"
          >
            {{ this.sampleText.length + " / " + maxLength }}
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p-button
            size="sm"
            class="pull-left btn-ph"
            v-on:click="appendMsisdn"
            type="primary"
          >
            MSISDN
          </p-button>
          <p-button
          v-if="field.scenario_type_id == 99"
          size="sm"
          class="pull-left btn-ph"
          v-on:click="appendUrl"
          type="primary"
          >
          SHORT-URL
          </p-button>
          <p-button
            v-for="ph in placeholders"
            :key="ph.id"
            size="sm"
            type="primary"
            class="pull-left btn-ph"
            v-on:click="appendPlaceholder(ph)"
          >
            {{ ph.name.toUpperCase() }}
          </p-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import TextOptin from "./TextOptin";

  export default {
    extends: TextOptin,

    props:{
      shortUrlLength: {
        type: Number,
        default: 35,
      },
    },

    methods: {
      appendUrl() {
        console.log(this.sampleText.length)
        if (!this.disabled && this.sampleText.length + this.shortUrlLength <= this.maxLength) {
          const value = this.value + "{SHORT-URL}";
          this.$emit("input", value);
        }
        this.$refs.textarea.focus();
      },
    },

    computed:{
      sampleText() {
        if(!this.value) return "";
        return this.value.replace(/\{([\w-]+)\}/g, (match, capture) => {
          console.log(capture)
          const ph = this.placeholdersMAP.get(capture.toLowerCase());
          if (ph) {
            return "".padStart(ph.max_length, "X");
          } else if (capture.toLowerCase() == "msisdn") {
            return "".padStart(13, "X");
          } else if (capture.toLowerCase() == "short-url") {
            return "".padStart(this.shortUrlLength, "X");
          }
          return match;
        });
      },
    },
    data() {
      return {
        maxLengthInitial: 320
      };
    },
  };
</script>