<template>
  <div class="block">
    <p class="mb-0" :class="marginTop ? 'mt-4' : ''">{{title | capitalize}}</p>
    <el-slider :min="min" v-model="value" :marks="marks" range></el-slider>
  </div>
</template>

<script>
import { Slider } from "element-ui";
export default {
  name: "RangeSliders",
  props: {
    title: {
      type: String,
      default: "",
    },
    
    id: {
      type: Number,
    },

    initialValue: {
      type: Array,
      default: () => [0, 100],
    },
    
    marginTop: {
      type: Boolean,
      default: () => true,
    },
    
    min: {
      type: Number,
      default: () => 0,
    },
  },

  components: {
    [Slider.name]: Slider,
  },

  data() {
    return {
      value: [0, 100],

      timeout: null,
    };
  },

  created() {
    this.value = this.initialValue;
  },

  watch: {
    value(value) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("updateValue", {value, title: this.title, id: this.id});
      }, 400);
    },
  },

  computed: {
    marks() {
      let marks = {
        10: "10",
        20: "20",
        30: "30",
        40: "40",
        50: "50",
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        100: "100",
      };
      marks[this.min] = `${this.min}`;
      return marks;
    }
  }
};
</script>
<style></style>