<template>
  <div class="col-12">
    <fg-input :label="$t('Subscription service')" :error="getError('subscription')">
      <el-select
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(243 243 243 / 80%)"
        size="large"
        :placeholder="$t('Select subscription service')"
        :name="'subscription'"
        :value="value"
        filterable
        clearable
        :disabled="!isEditable || loading"
        @input="$emit('input', $event)"
      >
        <el-option
          v-for="service in services"
          :value="service.id"
          :label="service.name"
          :key="service.id"
        ></el-option>
      </el-select>
    </fg-input>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import api from "src/api";

export default {
  name: 'SubscriptionServicesSelect',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  inject: ["getError", "parentValidator"],
  props: ["value", "isEditable"],

  data() {
    return {
      services: [],
      loading: true,
    };
  },

  created() {
    this.$validator = this.parentValidator;
    this.loadServices();
  },

  methods: {
    async loadServices() {
      try {
        const res = await api.getSubscriptionServices();
        this.services = res.data;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
:deep(.el-select .el-loading-spinner) {
  top: 80% !important;
}
</style>
