<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group has-label">
                    <label>{{ $t("Repeat creatives test?") }}</label>
                    <br />
                    <p-switch v-model="saEnabled"
                            type="primary"
                            on-text="ON"
                            off-text="OFF"
                            :disabled="disabled"
                            @input="onInput('[performance-repeat-sampling].value', $event)"
                    ></p-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep, tap, set } from "lodash";
import PSwitch from "src/custom-components/UIComponents/Switch";

export default {
    inject: ["getError", "parentValidator"],

    components: {
        [PSwitch.name]: PSwitch
    },

    props: {
        value: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        local() {
            if (this.value) {
                if (typeof this.value["performance-repeat-sampling"] === "undefined") {
                    this.value["performance-repeat-sampling"] = {
                        variable_id: 90,
                        value: false
                    }
                }

                return this.value;
            }
        },

        saEnabled: {
            get() {
                return ((this.local["performance-repeat-sampling"]) instanceof Object) ? this.local["performance-repeat-sampling"].value : false;
            },

            set(value) {
                this.local["performance-repeat-sampling"].value = value;
            }
        }
    },

    methods: {
        onInput(key, value) {
            this.$emit("input", tap(cloneDeep(this.local), v => set(v, key, value)));
        },
    }
}
</script>