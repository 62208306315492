<template>
    <div class="row">
      <DateRangePicker
          class="col-lg-4"
          :initial-value="initialDateRangeValue"
          @date-range-changed="filters.dateRange = $event"
          :shortcuts="dateShortcuts"
      />
    </div>
</template>

<script>
  import DateRangePicker from "src/app-components/form-elements/DateRangePicker";
  import moment from 'moment';
  import FilterPanelMixin from "src/app-components/FilterPanelMixin";

  const initialDateRangeValue = [ moment().startOf('day'), moment().endOf('day') ];

  export default {
    components: {
      DateRangePicker
    },

    mixins: [
      FilterPanelMixin({
        dateRange: initialDateRangeValue,
      })
    ],

    data () {
        return {
            dateShortcuts: [{
                text: 'Last week',
                onClick(picker) {
                    const start = moment().subtract(1, 'weeks').startOf('isoWeek');
                    const end = moment().subtract(1, 'weeks').endOf('isoWeek');
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last month',
                onClick(picker) {
                    const start = moment().subtract(1, 'months').startOf('month');
                    const end = moment().subtract(1, 'months').endOf('month');
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last 3 months',
                onClick(picker) {
                    const start = moment().subtract(3, 'months').startOf('month');
                    const end = moment().subtract(1, 'months').endOf('month');
                    picker.$emit('pick', [start, end]);
                }
            }],
            initialDateRangeValue
        }
    },

    methods: {
      prepareFilters (filters) {
        return {
          startDate: this.filters.dateRange ? moment(this.filters.dateRange[0]).hour(0).minute(0).second(0).format() : null,
          endDate: this.filters.dateRange ? moment(this.filters.dateRange[1]).hour(23).minute(59).second(59).format() : null,
        };
      },
    },
  };
</script>
<style scoped>
</style>