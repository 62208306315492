import Vue from 'vue';
import api from '@/api';
//import createAuth0Client from '@auth0/auth0-spa-js';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname);

let instance;

const SESSION_STORAGE_KEY = 'dynamo.satpush.session';

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useDynamoAuth = (options) => {
	if (instance) return instance;

	const {
		onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
		redirectUri = window.location.origin,
		store,
		router,
	} = options;

	// The 'instance' is simply a Vue object
	instance = new Vue({
		store,
		router,
		data() {
			return {
				loading: true,
				isAuthenticated: false,
				user: null,
				popupOpen: false,
				error: null,
				token: null,
				selectedAccountId: null,
			};
		},
		methods: {

			async login(username, password) {
				const url = `${process.env.VUE_APP_API_URL}/login`;
				const response = await fetch(url, {
					method: 'POST', // *GET, POST, PUT, DELETE, etc.
					mode: 'cors', // no-cors, *cors, same-origin
					//cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					//credentials: 'same-origin', // include, *same-origin, omit
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					//redirect: 'follow', // manual, *follow, error
					//referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
					body: JSON.stringify({ username, password }),
					// body data type must match "Content-Type" header
				});
				const json = await response.json();
				if (json.status === true && "data" in json && "user" in json.data) {
					const _session = {
						token: json.data.jwt,
						selectedAccountId: json.data.user.account.id,
					};
					localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(_session));
					//TODO: toda la siguiente logica se repite en "created". Ver de llevarlo a una funcion tipo "setupSession"
					this.token = _session.token;
					this.selectedAccountId = _session.selectedAccountId; 
					this.user = json.data.user;
					this.isAuthenticated = true;
					api.setToken(_session.token);
					api.setSelectedAccountId(_session.selectedAccountId);
					this.$store.commit('createSession', json.data.user);
					router.push({ name: "Dashboard" });
				}
				console.log("JSON response:", json);

				return response;
			},

			/** Logs the user out and removes their session on the authorization server */
			logout() {
				localStorage.removeItem(SESSION_STORAGE_KEY);
				this.token = null;
				this.selectedAccountId = null;
				this.user = null;
				this.isAuthenticated = false;
				store.commit("destroySession");
				router.push({name: "Login"});
			},

			async changeAccount(targetAccountId) {
				if (!this.isAuthenticated) return;
				const currentRoute = { ...router.currentRoute };
				router.push({name: "loading"});
				await this.fetchUser(targetAccountId);
				localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({
					token: this.token,
					selectedAccountId: targetAccountId,
				}));

				router.push(currentRoute);
			},

			/**
			 * Fetches the logged user data and stores it this plugin's data and the global store
			 * @returns 
			 */
			async fetchUser(targetAccountId = null) {
				const token = this.token;
				this.loading = true;
				const url = `${process.env.VUE_APP_API_URL}/auth`;
				let userData = null;

				try {
					const response = await fetch(url, {
						headers: {
							Authorization: 'Bearer ' + token,
							"X-Dyn-User-Account": (targetAccountId || this.selectedAccountId),
						}
					});
					const json = await response.json();
					userData = json.data;
					api.setToken(this.token);
					if(targetAccountId){
						this.selectedAccountId = targetAccountId;
						api.setSelectedAccountId(targetAccountId);
					}
					this.user = userData;
					store.commit('createSession', userData);
					this.isAuthenticated = true;

				} catch (error) {

				} finally {
					this.loading = false;
				}

				return userData;
			}
		},
		/** Use this lifecycle method to instantiate the SDK client */
		async created() {
			try {
				console.debug("Loading auth...");
				const _session = JSON.parse(localStorage.getItem(SESSION_STORAGE_KEY) || "null");
				console.debug("Session object: ", _session);
				if (_session) {
					console.debug("Found token in localStorage. Fetching user data...");
					this.token = _session.token;
					this.selectedAccountId = _session.selectedAccountId;
					api.setToken(this.token);
					api.setSelectedAccountId(_session.selectedAccountId);
					await this.fetchUser(_session.selectedAccountId);
				} else {
					console.log("There is no token");
				}
			} catch (err) {
				console.warn(`Error while loading session data: ${err.message}`)
				//localStorage.removeItem(SESSION_STORAGE_KEY);
			} finally {
				console.debug("Finished auth loading");
				this.loading = false;
				store.commit('setLoadingAuth', false);
				if (this.isAuthenticated && router.currentRoute.name === 'Login') {
					router.push({ name: "Dashboard" });
				} else if(!this.isAuthenticated && router.currentRoute.name !== 'Login'){
					router.push({ name: "Login" });
					setTimeout(() => router.push({name: "Login"}), 2000)
				}
			}
		}
	});

	return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
	install(Vue, options) {
		Vue.prototype.$auth = useDynamoAuth(options);
	}
};